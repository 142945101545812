import React from "react";
import styled from "styled-components";
import { Line } from "react-chartjs-2";

import { depthChart } from "charts";

const DepthChart = ({ orderBookAsks, orderBookBids, depth }) => {
  return (
    <DepthChart.Wrapper className="chart-area w-100">
      <Line
        data={depthChart(orderBookAsks, orderBookBids, depth).data}
        options={depthChart(orderBookAsks, orderBookBids, depth).options}
      />
    </DepthChart.Wrapper>
  );
};

DepthChart.Wrapper = styled.div`
  height: 350px;
`;

export default DepthChart;
