import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import * as Sentry from "@sentry/react";
import qs from "qs";

import EditCredentialModal from "./modals/EditCredentialModal";
import { getTopMarkets } from "store/markets/actions";
import {
  fetchCredential,
  clearCredential,
  getCredentialInvestments,
  fetchBalances,
  fetchRebalances,
  fetchCredentialStatistics
} from "store/credentials/actions";
import CredentialSettings from "./compounds/CredentialSettings";
import PagesViewCredentials from "./TabsCredentials/TabsCredentials";
import ExchangeBallances from "./compounds/exchangeBalances/ExchangeBallances";
import CredentialHeader from "./compounds/CredentialHeader";
import Balance from "./compounds/Balance";
import RefreshButton from "components/RefreshButton";
import FallBack from "components/FallBack";
import Error from "components/Error";
import DangerZone from "components/DangerZone";
import Breadcrumbs from "components/Breadcrumbs";
import { setCredentialsPageBreadcrumbs } from "models/enum/breadcrumbs";
import useRequestCancellation from "hooks/useRequestCancellation";
import IPsAddresses from "./compounds/IPsAddresses/IPsAddresses";
import { getExchanges } from "store/exchanges/actions";
import { CREDENTIALS_CONTENT } from "models/constans/credentials/content";

const CredentialPage = ({ match, location }) => {
  const [pageView, setPagView] = useState("portfolio")
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isRebalanceLoading, setIsRebalanceLoading] = useState(false);
  const [isExchangesLoading, setIsExchangesLoading] = useState(false);
  const { useAbortableEffect } = useRequestCancellation();

  const dispatch = useDispatch();

  const data = useSelector((state) => state.credentials.item.data);
  const loading = useSelector((state) => state.credentials.item.loading);
  const error = useSelector((state) => state.credentials.item.error);
  const exchange = useSelector(state => state.exchanges.allExchanges?.items)?.[0];

  useEffect(() => {
    window.analytics.page("/credentials/:id");
  }, []);

  const fetchData = useCallback(
    async (signal) => {
      setIsExchangesLoading(true);
      try {
        const response = await dispatch(fetchCredential(match.params.id, signal));
        if (response && response.exchange) {
          const filterExchanges = qs.stringify({
            filter: {
              code: {
                eq: response.exchange,
              },
            },
          });
          await dispatch(getExchanges(`?${filterExchanges}`, signal));
        }
      } finally {
        setIsExchangesLoading(false);
      }
    },
    [dispatch, match.params.id]
  );

  useAbortableEffect(
    (signal) => {
      fetchData(signal);
      return () => dispatch(clearCredential());
    },
    [match.params.id, dispatch],
    true
  );

  const onHandleRefresh = (filter) => {
    const InvestmentsFilter = qs.stringify({
      filter: filter || { realPercent: { gte: 0 }, balance: { gte: 0 } },
      sort: { by: "realPercent", order: "DESC" }
    });
    const filterBalances = qs.stringify({
      sort: { by: "percent", order: "DESC" },
      limit: 10
    });

    setIsRebalanceLoading(true);
    dispatch(fetchCredentialStatistics(match.params.id, location.search));
    dispatch(getTopMarkets(match.params.id));
    dispatch(getCredentialInvestments(match.params.id, `?${InvestmentsFilter}`));
    dispatch(fetchBalances(match.params.id, `?${filterBalances}`));
    dispatch(fetchRebalances(match.params.id)).finally(() => setIsRebalanceLoading(false));
    return dispatch(fetchCredential(match.params.id));
  };

  if (error) return <Error error={error} dataTestId="credential-error"/>;

  return (
    <>
      <div className="d-none d-md-flex align-items-center justify-content-between">
        <Breadcrumbs
          items={setCredentialsPageBreadcrumbs(data.exchange)}
          loading={loading}
        />
        <RefreshButton
          loading={loading}
          onHandleRefresh={onHandleRefresh}
        />
      </div>
      <CredentialHeader
        status={data.status}
        connected={data.connected}
        id={data.id}
        exchange={data.exchange}
        comment={data.comment}
        createdAt={data.createdAt}
        loading={loading}
        error={data.error}
      />
      <Row className="mb-2">
        <Col xs="12" md="4">
          <Sentry.ErrorBoundary fallback={<FallBack />}>
            <Balance
              id={match.params.id}
              balance={data.balance}
              estimated={data.estimated}
              dailyRevenueBtc={data.dailyRevenueBtc}
              dailyRevenueBtcPercent={data.dailyRevenueBtcPercent}
              loading={loading}
            />
          </Sentry.ErrorBoundary>
          <Sentry.ErrorBoundary fallback={<FallBack />}>
            <IPsAddresses
              loading={isExchangesLoading}
              exchange={exchange}
            />
          </Sentry.ErrorBoundary>
          <Sentry.ErrorBoundary fallback={<FallBack />}>
            <ExchangeBallances
              id={match.params.id}
              estimated={data?.estimated}
            />
          </Sentry.ErrorBoundary>
          <Sentry.ErrorBoundary fallback={<FallBack />}>
            <CredentialSettings
              data={data}
              onSetModalIsOpen={setModalIsOpen}
              loading={loading}
            />
          </Sentry.ErrorBoundary>
          <DangerZone
            id={match?.params.id}
            loading={loading}
            content={CREDENTIALS_CONTENT?.details?.widgets?.dangerZone}
            isDelete
          />
        </Col>
        <Col xs="12" md="8">
          <PagesViewCredentials
            data={data}
            loadingPage={loading}
            id={match.params.id}
            location={location}
            pageView={pageView}
            setPagView={setPagView}
            isRebalanceLoading={isRebalanceLoading}
          />
        </Col>
      </Row>
      <EditCredentialModal
        data={data}
        modalIsOpen={modalIsOpen}
        onSetModalIsOpen={setModalIsOpen}
      />
    </>
  );
};

export default CredentialPage;
