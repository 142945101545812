import React from "react";
import styled from "styled-components";
import { Card, CardBody } from "reactstrap";

import BotProgressBar from "./BotProgressBar";
import { formatterToNull, formatValue, setColorClass } from "helpers/valuesFormatters";
import StatisticsDealsCount from "./StatisticsDealsCount";
import BotHeader from "./BotHeader";

const Bot = ({ bot, index }) => (
  <Bot.StyledCard>
    <BotHeader bot={bot} />
    <CardBody className="p-4">
    <div className="d-flex justify-content-between align-items-center mt-2">
        <Bot.Title>Period</Bot.Title>
        <p className="mb-0 text-white font-weight-500 font-sm">
          {`${bot.period} minutes`}
        </p>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <Bot.Title>Markets</Bot.Title>
        <p className={`mb-0 font-sm font-weight-500 ${bot.marketsCount ? "text-white" : "text-white-35"}`}>
          {bot.marketsCount}
        </p>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <Bot.Title>Total deals</Bot.Title>
        <p className={`mb-0 font-sm font-weight-500 ${bot.countDeals ? "text-white" : "text-white-35"}`}>
          {formatterToNull(bot.countDeals)}
        </p>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <Bot.Title>Running deals</Bot.Title>
        <p className={`font-weight-500 mb-0 font-sm ${bot.runningDeals ? "text-white" : "text-white-35"}`}>
          {bot.runningDeals}
        </p>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <Bot.Title>AVG daily return</Bot.Title>
        <div className="d-flex align-items-center">
          <p className={`${setColorClass(bot.dailyReturn, "text-white-35")} mb-0 font-weight-500 font-sm`}>
            {formatValue(bot.dailyReturn || 0)}
          </p>
          <p className="mb-0 ml-1 text-white-35 font-weight-300 font-sm">{bot.investment.asset}</p>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <Bot.Title>Total return</Bot.Title>
        <div className="d-flex align-items-center">
          <p className={`${setColorClass(bot.totalReturn, "text-white-35")} mb-0 font-weight-500 font-sm`}>
            {formatValue(bot.totalReturn || 0)}
          </p>
          <p className="mb-0 ml-1 font-weight-300 text-white-35 font-sm">
            {bot.investment.asset}
          </p>
        </div>
      </div>
      <StatisticsDealsCount
        profitCount={bot.statistics?.profit?.count}
        profitTitle="Profitable deals"
        lossCount={bot.statistics?.loss?.count}
        lossTitle="Loss deals" />
      <BotProgressBar
        profitPercent={bot.statistics.profit.percent}
        profitAmount={bot.statistics.profit.amount}
        lossPercent={bot.statistics.loss.percent}
        lossAmount={bot.statistics.loss.amount}
        profitTitle="Total profit"
        lossTitle="Total loss"
        index={index} />
    </CardBody>
  </Bot.StyledCard>
);

Bot.StyledCard  = styled(Card)`
  cursor: pointer;
  margin-bottom: 24px !important;
  border-radius: 8px !important;

  &:hover {
    background-color: rgba(46, 49, 72, 1) !important;

    .borderStatus {
      border: 4px solid #363A54;
    }

    .card-header {
      background: #363A54 !important;
    }
  }
`;

Bot.Asset = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 0 8px;
  white-space: nowrap;
`;

Bot.Title = styled.p`
  color: rgba(255, 255, 255, 0.75) !important;
  font-weight: 300;
  margin-bottom: 0;
  font-size: 0.875rem;
`;

Bot.DealsTitle = styled.p`
  color: rgba(255, 255, 255, 0.35) !important;
  font-weight: 300;
  margin-bottom: 0;
  font-size: 12px;
`;

export default Bot;
