import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Table
} from "reactstrap";
import {
  useDispatch,
  useSelector
} from "react-redux";
import React, {
  useEffect,
  useState
} from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import qs from "qs";

import { ReactComponent as MyCredentialsDashboard } from "assets/img/MyCredentialsDashboard.svg";
import CredentialListHeader from "views/credentials/compounds/CredentialListHeader";
import MyCredentialsTablePreloader from "./MyCredentialsTablePreloader";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import PaginationPanel from "components/PaginationPanel";
import { fetchBalances } from "store/credentials/actions";
import { EmptyData } from "../../states/EmptyData";
import { CreateCredentialsModal } from "shared/ui";

const MyCredentialsTable = ({
  exchangeMarketLoading,
  tableRef,
  setId
}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(false);
  const dispatch = useDispatch();

  const { data, loading } = useSelector((state) => state?.credentials?.balances);
  const { handlePaginate } = useFiltersWithRequest(data?.request, fetchBalances);
  const { exchange: exchangeName } = useParams();
  const { quote, base } = useParams();

  useEffect(() => {
    if (!isModalOpen) {
      const filter = qs.stringify({
        filter: {
          exchange: {
            eq: exchangeName
          }
        },
        sort: {by: "balance"}
      });
      dispatch(fetchBalances(`?${filter}&limit=2`));
    }
  }, [exchangeName, isModalOpen, dispatch]);

  useEffect(() => {
    setActiveItem(null);
    setId(null);
  }, [quote, base, setId])

  const handleTdClick = (id) => {
    setId(prevId => prevId === id ? null : id);
    setActiveItem(prevActiveItem => prevActiveItem === id ? null : id);
  };

  if (exchangeMarketLoading) return <MyCredentialsTablePreloader />;

  return (
    <>
      <MyCredentialsTable.StyledCard className="StyledCard" >
        <CardHeader className="pt-4 px-4">
          <Row className="no-gutters justify-content-between">
            <div className="d-flex align-items-center">
              <MyCredentialsDashboard />
              <p className="mb-0 ml-2 text-white font-weight-500 text-nowrap">
                My credentials
              </p>
            </div>
          </Row>
        </CardHeader>
        {
          loading
            ? <MyCredentialsTablePreloader/>
            : data?.items?.length ?
              <CardBody className="p-4">
                <Table className="mb-0">
                  <MyCredentialsTable.Tbody>
                    {data?.items?.map((item) => (
                      <tr key={item.id}>
                        <td onClick={() => handleTdClick(item.id)} >
                          <CredentialListHeader
                            isActive={item.id === activeItem}
                            data={item}
                            index={item.id}
                          />
                        </td>
                      </tr>
                    ))}
                  </MyCredentialsTable.Tbody>
                </Table>
              </CardBody>
              : <EmptyData
                title="You have no credentials at the moment"
                description="Please create some to see more detailed info by spot market"
                buttonText="Connect my account"
                onHandleClick={() => setIsModalOpen((prevState) => !prevState)}
              />
        }
        <div className={data?.total > 10 ? "mt-3" : "mt-0"}>
          <PaginationPanel
            activePage={data?.page}
            totalItemsCount={data?.total}
            totalPagesView={2}
            onChange={(page) => handlePaginate(page)}
            className="m-0"
            elementRef={tableRef}
            isCircle
          />
        </div>
      </MyCredentialsTable.StyledCard>
      <CreateCredentialsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

MyCredentialsTable.StyledCard = styled(Card)`
margin-bottom: 0 !important;
  &.StyledCard {
    border-radius: 12px 12px 0 0  !important;
  }
`

MyCredentialsTable.Tbody = styled.tbody`
  tr {
    td {
      padding: 0 0 20px !important;
      cursor: pointer;

      &:hover {
        > div {
          border: 2px solid var(--info);
          background: linear-gradient(0deg, rgba(47, 128, 237, 0.07) 0%, rgba(47, 128, 237, 0.07) 100%), #2E3148;
        }
      }

      > div {
        border-radius: 10px !important;
      }
    }

    &:last-child {
      td {
        padding-bottom: 0 !important;
      }
    }
  }

  border-radius: 10px !important;
`;

export default MyCredentialsTable;
