import React from "react";
import BacktestWidget from "./backtest/BacktestWidget";

const BacktestTab = ({id, loadingPage}) => {
  return (
    <BacktestWidget
      botId={id}
      loadingPage={loadingPage}
    />
  )
}

export default BacktestTab;
