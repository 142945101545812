import React, { useState } from "react";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import { UncontrolledAlert } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Formik,
  Form,
  Field
} from "formik";
import * as Yup from "yup";

import { resetPassword } from "store/auth/actions";
import { handleResponse } from "helpers/errorsValidator";
import Button from 'components/Button';
import FormDescription from "./compounds/FormDescription";

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("This field is required."),
});

const style = {
  color: "#ec250d",
};

const ForgotPassword = ({ onResetPassword }) => {
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const history = useHistory()

  return(
    <div className="content content h-75 d-flex flex-column justify-content-center">
      <FormDescription
        title="Forgot password?"
        text="No worries, we'll send you reset instructions."
      />
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={async(values, formik) => {
          formik.setSubmitting(true);
          const res = await onResetPassword(values);
          if (res.errors) {
            handleResponse(res, formik.setErrors);
          } else {
            history.push("/change-password", { email: email });
          }

          formik.setSubmitting(false);
        }}
        validationSchema={ResetPasswordSchema}
      >
        {({ errors, isSubmitting, setFieldValue }) => (
          <Form>
            {errors.message &&
              <UncontrolledAlert color="danger" fade={false}>
                {errors.message}
              </UncontrolledAlert>}
              <div className="my-5">
                <label
                  htmlFor="email"
                  className="text-white-75"
                >
                  Email
                </label>
                <Field
                  id="email"
                  type="email"
                  name="email"
                  className={classnames("form-control", {
                    "has-error": submitted && (errors.email || errors.message),
                  })}
                  placeholder="Enter your email"
                  onChange={(e) => {
                    setFieldValue("email", e.target.value);
                    setEmail(e.target.value);
                  }}
                />
              <div className="w-100">
                {submitted && errors.email ?
                  <label style={style}>{errors.email}</label> : null}
              </div>
            </div>
            <Button
              className="w-100 mt-3"
              type="submit"
              onClick={() => setSubmitted(true)}
              color="blue"
              loading={isSubmitting}
            >
              Reset password
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ onResetPassword: resetPassword }, dispatch);

export default connect(null, mapDispatchToProps)(ForgotPassword);
