import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

import Button from "components/Button";
import SidebarNavigation from "./SidebarNavigation";
import useWindowDimensions from "hooks/useWindowDimensions";
import { DASHBOARD_SIDEBAR } from "./navigation";
import { ReactComponent as Logo } from "assets/img/Logo.svg";
import { ReactComponent as LogoMini } from "assets/img/Logo_mini.svg";
import { ReactComponent as Support } from "assets/img/icons/Support.svg";
import { ReactComponent as CloseIcon } from "assets/img/icons/closeIcon.svg";

const DashboardSidebar = ({
  expanded,
  toggleSidebar,
  isMobile
}) => {
  const { width } = useWindowDimensions();

  const handleOnLinkClick = () => {
    if (width <= 992) toggleSidebar(expanded);
  };

  return (
    <DashboardSidebar.Sidebar
      className="sidebar"
      data="blue"
      expanded={expanded}
    >
      <div className="sidebar-wrapper d-flex flex-column py-4">
        <div className={`logo ${expanded ? "d-flex flex-column px-4" : "px-4"}`}>
          <div className="d-flex">
            <NavLink
              to="/dashboard"
              onClick={handleOnLinkClick}
              className={`simple-text logo-normal mb-3 ${expanded ? "" : "text-center"}`}
            >
              {expanded ?
                <Logo />
                :
                <LogoMini />
              }
            </NavLink>

            {isMobile &&
              <Button
                onClick={() => toggleSidebar(expanded)}
                color="blue-outline"
                className="ml-auto mr-0 p-0"
              >
                <CloseIcon />
              </Button>}
          </div>
          <div className="logo-line" />
        </div>
        <SidebarNavigation
          expanded={expanded}
          handleOnLinkClick={handleOnLinkClick}
          toggleSidebar={toggleSidebar}
          isMobile={isMobile}
        />
        <DashboardSidebar.SidebarIconsBar
          expanded={expanded}
          className="mx-auto mt-auto mb-0 d-flex justify-content-between align-items-center"
        >
        {DASHBOARD_SIDEBAR.map((link) => 
          <a
            key={link.href}
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
            className="sidebar-link mt-3 d-flex justify-content-center align-items-center"
          >
            {link.icon}
          </a>
        )}
        </DashboardSidebar.SidebarIconsBar>

        <div className="d-none align-items-center justify-content-center mb-4 support-block">
          <Button
            size="lg"
            className={classNames("btn-simple pt-2 pb-2 border-white", {
              "btn-icon": !expanded,
              "d-flex flex-row align-items-center": expanded
            })}
          >
            <Support />
            {
              expanded && <p className="text-white mb-0 ml-2">Support</p>
            }
          </Button>
        </div>
      </div>
    </DashboardSidebar.Sidebar>
  );
};

DashboardSidebar.Sidebar = styled.div`
  margin-left: 40px !important;
  width: initial !important;
  margin-top: 80px !important;
  

  @media (min-width: 768px) and (max-width: 1199.98px) {
    margin-left: 0 !important;
    margin-top: 0 !important;
    
    &.sidebar {
      width: 320px !important;
    }
  }

  @media (max-width: 767.98px) {
    margin-left: 0 !important;
    margin-top: 0 !important;
    height: 100vh !important;
    width: 320px !important;
  }

  @media (max-width: 1200px) {
    height: 100% !important;
  }

  .logo-line {
    background: rgba(255, 255, 255, 0.35);
    height: 1px !important;
    width: 100%;
  }

  .support-block {
    .btn.btn-icon.btn-lg {
      min-width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &::before {
    left: ${(props) => props.expanded ? "" : "50% !important"};
    content: none !important;
  }
`;

DashboardSidebar.SidebarIconsBar = styled.div`
  display: flex;
  flex-direction: ${(props) => props.expanded ? "row" : "column"};
  justify-content: center;
  gap:12px;

  .sidebar-link {
    height: 28px;
    width: 28px;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .sidebar-link + .sidebar-link {
    margin-top: 0;
  }
`;

DashboardSidebar.Divider = styled.div`
  flex: 1;
`;

export default DashboardSidebar;
