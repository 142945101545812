import React from "react";
import {Card, CardHeader} from "reactstrap";
import SkeletonPreloader from "components/SkeletonPreloader";
import TablePreloader from "components/TablePreloader";

const LastActivitiesPreloader = ({table}) => {

  const renderTable = () => {
    return (
      <TablePreloader
        rowTimes={10}
        theadTimes={5}
        tdHeight={12}
        thHeight={9}
        withCircle={24}
        thWidth={[64,64,64,64,64]}
        tdWidth={[71,113,178,47,111]}
      />
    )
  }

  return (
    table ? renderTable()
      :
      <Card className="overflow-hidden">
        <CardHeader className="mb-4 overflow-hidden">
          <div className="d-flex align-items-center">
            <SkeletonPreloader
              circle
              height={20}
              width={20}
            />
            <SkeletonPreloader
              height={12}
              width={79}
              className="ml-2"
            />
          </div>
        </CardHeader>
        {renderTable()}
      </Card>
  )
}

export default LastActivitiesPreloader;
