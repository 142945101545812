import { get } from "lodash";

import {
  ENABLE_BOT,
  DISABLE_BOT,
  ATTACH_STRATEGY,
  EDIT_BOT,
  UPDATE_BOT_NAME,
  TOGGLE_TICKER_ITEM,
  TOGGLE_TICKER_FILTER,
  ENABLE_BOT_LOADING,
  DISABLE_BOT_LOADING,
  RESET_BOTS,
  RESET_BOT,
  RESET_DAILY_INCOME_CHART,
  REMOVE_CREATED_INDICATOR,
  EDIT_BOT_LOADING,
  EDIT_BOT_ERROR,
  CREATE_BACKTEST,
  RESET_GENETIC,
  UPDATE_GENETIC_ALGORITHM,
  RESET_GENETIC_ALGORITHM,
  RESET_GENETIC_INDIVIDUALS,
  RESET_GENETIC_STRATEGIES,
  SET_CURRENT_BOT,
  UPDATE_BOT_IN_LIST,
  RESET_INDICATORS_SIGNALS,
} from "./constants";
import createAction from "store/thunkFactory";
import { recordWizardBots } from "store/user/actions";

export const getBots = (filter, signal) => createAction("BOTS", (fetch) => {
  return fetch.get(`/app/bots${filter || ""}`, { signal });
});

export const getBot = (id) => createAction("BOT", (fetch) => {
  return fetch.get(`/app/bots/${id}`);
});

export const getBacktests = (filter= "", signal) => createAction("BACKTESTS", (fetch) => {
  return fetch.get(`/app/backtests${filter}`, {signal});
});

export const fetchTechnicalAnalysis = (id, filters) => createAction("TECHNICAL_ANALYSIS", (fetch) => {
  return fetch.get(`/app/bots/${id}/markets/technical-analysis${filters || ""}`);
});

export const fetchTechnicalAnalysisMarket = (id, market) => createAction("TECHNICAL_ANALYSIS_MARKET", (fetch) => {
  return fetch.get(`/app/bots/${id}/markets/${market}/technical-analysis`);
});

export const fetchWizardTickers = () => createAction("BOT_TICKERS", (fetch) => {
  return fetch.get("/app/exchanges/tickers");
});

export const getDailyIncome = (id, filter="") => createAction("DAILY_INCOME_CHART", (fetch) => {
  return fetch.get(`/app/bots/${id}/daily-income?period=${filter}`);
});

export const editBot = (id, body) => async(dispatch, getState, fetch) => {
  try {
    dispatch({ type: EDIT_BOT_LOADING });
    const res = await fetch.patch(`/app/bots/${id}`, body);
    dispatch({ type: EDIT_BOT, payload: res.data.item });
    return res.data;
  } catch (e) {
    dispatch({ type: EDIT_BOT_ERROR });
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const attachStrategies = (botId, strategyId) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.post(`/app/bots/${botId}/strategies/${strategyId}`);
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const enableBot = (id, isList) => async(dispatch, getState, fetch) => {
  try {
    dispatch({ type: ENABLE_BOT_LOADING });
    const res = await fetch.put(`/app/bots/${id}/run`);
    const type = isList ? UPDATE_BOT_IN_LIST : ENABLE_BOT;
    dispatch({ type, payload: res.data.item });
    return res
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const disableBot = (id, isList) => async(dispatch, getState, fetch) => {
  try {
    dispatch({ type: DISABLE_BOT_LOADING });
    const res = await fetch.put(`/app/bots/${id}/stop`);
    const type = isList ? UPDATE_BOT_IN_LIST : DISABLE_BOT;
    dispatch({ type, payload: res.data.item });
    return res
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const createBot = (bot) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/app/bots", bot);
    dispatch(recordWizardBots());
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const attachStrategy = (botId, strategyId) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.post(`/app/bots/${botId}/strategies/${strategyId}`);
    dispatch({ type: ATTACH_STRATEGY, payload: res.data.item });
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const indicatorsSignals = (exchange, base, quote, code, param) => createAction("INDICATORS_SIGNALS", (fetch) => {
  return fetch.post(`app/exchanges/${exchange}/markets/${base}-${quote}/indicators/${code}/signals`, param);
});

export const deleteBot = (id) => async(dispatch, getState, fetch) => {
  try {
    return await fetch.delete(`/app/bots/${id}`);
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const updateName = (id, body) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.patch(`/app/bots/${id}/name`, body);
    dispatch({ type: UPDATE_BOT_NAME, payload: body });
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const createBacktest = (botId, body) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.post(`/app/bots/${botId}/backtests`, body);
    dispatch({ type: CREATE_BACKTEST, payload: res.data.item });
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const getGeneticAlgorithm = (id, signal) => createAction("GENETIC_ALGORITHM", (fetch) => {
  return fetch.get(`/app/genetic-strategies/${id}`, { signal });
});

export const getGeneticIndividuals = (id, filters = "", limit = 5) => createAction("GENETIC_INDIVIDUALS", (fetch) => {
  return fetch.get(`/app/genetic-strategies/${id}/individuals${filters ? filters + "&" : "?"}&limit=${limit}`);
});

export const updateGeneticAlgorithm = (id, body) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.patch(`/app/bots/${id}/genetic-algorithm`, body);
    const type = UPDATE_GENETIC_ALGORITHM;
    dispatch({ type, payload: res.data.item });
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const getBotMarkets = (id) => createAction("BOT_MARKETS", (fetch) => {
  return fetch.get(`/app/bots/${id}/markets`);
});

export const setCurrentBot = (id) => ({
  type: SET_CURRENT_BOT,
  payload: id
});

export const getGeneticStrategies = (exchange, base, quote) => createAction("GENETIC_STRATEGIES", (fetch) => {
  return fetch.get(`/app/exchanges/${exchange}/genetic-strategies/${base}-${quote}`);
});

export const getGeneticStrategiesTable = (id, filter="") => createAction("GENETIC_STRATEGIES_TABLE", (fetch) => {
  return fetch.get(`/app/bots/${id}/genetic-strategies${filter}`);
});

export const toggleTicker = (asset, enabled) => {
  return ({
    type: TOGGLE_TICKER_ITEM,
    payload: { asset, enabled }
  });
};

export const toggleFilter = (state) => {
  return ({
    type: TOGGLE_TICKER_FILTER,
    payload: { state }
  });
};

export const getStrategies = () => createAction("BOT_STRATEGIES", (fetch) => {
  return fetch.get("/app/strategies");
});

export const resetBot = () => ({ type: RESET_BOT });

export const resetBots = () => ({ type: RESET_BOTS });

export const resetGenetic = () => ({ type: RESET_GENETIC });

export const resetIndicatorsSignals = () => ({ type: RESET_INDICATORS_SIGNALS });

export const resetGeneticStrategies = () => ({ type: RESET_GENETIC_STRATEGIES });

export const resetGeneticAlgorithm = () => ({ type: RESET_GENETIC_ALGORITHM });

export const resetGeneticIndividuals = () => ({ type: RESET_GENETIC_INDIVIDUALS });

export const resetDailyIncome = () => ({ type: RESET_DAILY_INCOME_CHART });

export const removeDraftCreatedIndicator = (id) => {
  return ({
    type: REMOVE_CREATED_INDICATOR,
    payload: id
  });
};
