import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import {
  Card,
  CardBody,
  Button
} from "reactstrap";

import {
  getBacktesting,
  resetBacktestingList
} from "store/backtesting/actions";
import PaginationPanel from "components/PaginationPanel";
import BacktestHistoryTable from "./BacktestHistoryTable";
import CreateBacktestModal from "./CreateBacktestModal";

const BacktestHistory = ({
  getBacktesting,
  resetBacktestingList,
  data: {
    items,
    total,
    page
  },
  loading,
  error,
  location,
  id
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const search = queryString.parse(location.search);
    if (search.page) {
      getBacktesting(id, search.page);
    } else {
      getBacktesting(id, 1);
    }
    return () => resetBacktestingList();
  }, [getBacktesting, location.search, id, resetBacktestingList]);

  const handlePaginate = (page) => {
     history.push({
       pathname: `/strategies/${id}`,
       search: `?page=${page}`
     });
   };

  return(
    <>
      <div className="d-flex justify-content-between mb-3">
        <p className="h4 text-white my-auto">
          Backtesting
        </p>
        <Button
          onClick={() => setModalIsOpen(true)}
          className="my-auto"
        >
          Create backtest
        </Button>
      </div>
      <Card>
        <CardBody className="p-0">
          <BacktestHistoryTable
            data={items}
            loading={loading}
            error={error}
          />
        </CardBody>
      </Card>
      <PaginationPanel
        activePage={page}
        totalItemsCount={total}
        onChange={(page) => handlePaginate(page)}
      />
      <CreateBacktestModal
        onSetModalIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        id={id}
      />
    </>
  );
};

BacktestHistory.IconButton = styled.i`
  cursor: pointer;
`;

BacktestHistory.Card = styled.div`
  background: #2e3148 !important;
  box-shadow: none !important;
  border-radius: 0.2857rem;
`;

const mapStateToProps = (state) => ({
  data: state.backtesting.list.data,
  loading: state.backtesting.list.loading,
  error: state.backtesting.list.error,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getBacktesting,
  resetBacktestingList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BacktestHistory);
