import React, { useCallback } from "react";
import { FormGroup } from "reactstrap";

import SelectInput from "../SelectInput";
import { StyledLabel } from "./StyledOrderBook";
import {
  DEPTH_OPTIONS,
  GROUP_OPTIONS,
  WHOLE_GROUP_OPTIONS
} from "./models/filtersOptions";

const Filters = ({
  group,
  setGroup,
  orderBookAsks,
  orderBookBids,
  handleSetDepth,
  depth,
}) => {
  const getGroupOptions = useCallback((orders) => {
    if (!orders[0]?.price) return [];
    if (+orders[0].price > 1) return [
      ...WHOLE_GROUP_OPTIONS.filter((option) => (+orders[0].price / option.value) > 1),
      ...GROUP_OPTIONS
    ];
    const numPositionFrom = Math.floor(Math.log10(orders[0].price)) * -1;
    return GROUP_OPTIONS.map((_, index) => ({
      value: numPositionFrom + index,
      label: 0.1.toFixed(numPositionFrom + index).replace("1", "0").replace(/.$/,"1")
    }));
  }, []);

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex flex-column flex-lg-row w-100">
        <FormGroup className="order-book__filter my-lg-auto d-flex flex-column mr-3 mb-2 w-100">
          <StyledLabel
            htmlFor="group"
            className="mr-2 font-weight-light text-white-35 font-sm"
          >
            Group
          </StyledLabel>
          <SelectInput
            isClearable
            id="group"
            value={group}
            options={getGroupOptions([...orderBookAsks, ...orderBookBids])}
            onChange={(option) => setGroup(option)}
          />
        </FormGroup>
        <FormGroup className="order-book__filter my-auto d-flex flex-column w-100">
          <StyledLabel
            htmlFor="depth"
            className="mr-2 font-weight-light text-white-35 font-sm"
          >
            Depth
          </StyledLabel>
          <SelectInput
            id="depth"
            value={{label: depth}}
            options={DEPTH_OPTIONS}
            onChange={(option) => handleSetDepth(option?.value)}
          />
        </FormGroup>
      </div>
    </div>
  );
};

export default Filters;
