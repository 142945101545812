import styled from "styled-components";

const TickerWrapper = styled.div`
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: 2rem;
    background-color: #242534;
    padding-left: 0;
    box-sizing: content-box;

    .ticker {
      display: inline-block;
      height: 2rem;
      white-space: nowrap;
      box-sizing: content-box;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-name: ticker;
      animation-duration: ${({ isPreloader }) => isPreloader ? "8s" : "2360s"};

      &__item {
        display: inline-block;
        color: white;
      }
    }
  }
`;

export default TickerWrapper;
