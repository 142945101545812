import React from "react";
import {investedPercentColors} from "helpers/investedPercentColors";
import classNames from "classnames";
import {Button, CardBody} from "reactstrap";
import {ReactComponent as Plus} from "assets/img/icons/plus.svg";
import {ReactComponent as RevenueNegative} from "assets/img/icons/revenueNegative.svg";
import {ReactComponent as RevenueUp} from "assets/img/icons/revenueUp.svg";
import {ReactComponent as RevenueZero} from "assets/img/icons/revenueZero.svg";
import alertIcon from "assets/img/icons/alert.svg";
import styled from "styled-components";
import {useHistory} from "react-router-dom";

const PortfolioBody = ({investment}) => {
  const history = useHistory();
  const handleAddBotsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    history.push({
      pathname:`/bots/create`,
      state: {investment: investment}
    });
  };

  return (
    <CardBody>
      {
        +investment.balance.amount ?
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <div className="d-flex flex-column flex-sm-row flex-lg-column align-items-sm-center align-items-lg-start justify-content-between mb-2 mb-lg-0">
              <p className="mb-0 font-weight-300 font-sm text-white-35">
                Available
              </p>
              <div className="d-flex align-items-center">
                <p className="mb-0 text-white font-weight-500">
                  {investment.balance.available.total}
                </p>
                <p className="mb-0 ml-1 font-weight-300 text-white-35">
                  {investment.balance.available.currency}
                </p>
              </div>
            </div>

            <div className="d-flex flex-column flex-sm-row flex-lg-column align-items-sm-center align-items-lg-start justify-content-between mb-2 mb-lg-0">
              <div className="d-flex align-items-center">
                <p className="mb-0 font-weight-300 mr-2 font-sm text-white-35">
                  Invested
                </p>
                {
                  investment.balance.invested.percent > 0 &&
                  <p className={`${investedPercentColors(investment.balance.invested.percent)} mb-0 text-nowrap font-sm`}>
                    {`${investment.balance.invested.percent || 0} %`}
                  </p>
                }
              </div>
              <div className="d-flex align-items-center mb-2 mb-md-0">
                <p className="mb-0 font-weight-500 text-white">
                  {investment.balance.invested.amount}
                </p>
                <p className="mb-0 ml-1 text-white-35">
                  {investment.balance.invested.currency}
                </p>
              </div>
            </div>

            <div className="d-flex flex-column flex-sm-row flex-lg-column align-items-sm-center align-items-lg-start justify-content-between mb-2 mb-lg-0">
              <p className="mb-0 font-weight-300 font-sm text-white-35">
                Estimated
              </p>
              <div className="d-flex align-items-center">
                <p className="mb-0 text-white font-weight-500">
                  {investment.balance.estimated.total}
                </p>
                <p className="ml-1 mb-0 font-weight-300 text-white-35">
                  {investment.balance.estimated.currency}
                </p>
              </div>
            </div>

            <div className="d-flex flex-column flex-sm-row flex-lg-column align-items-sm-center align-items-lg-start justify-content-between mb-2 mb-lg-0">
              <p className="mb-0 font-weight-300 font-sm text-white-35">
                Daily revenue
              </p>
              <div className="d-flex align-items-center">
                {!investment.dailyRevenuePercent && <RevenueZero/>}
                {investment.dailyRevenuePercent > 0 ? <RevenueUp/> : null}
                {investment.dailyRevenuePercent < 0 ? <RevenueNegative/> : null}
                <p className={classNames("my-auto ml-1 font-weight-500", {
                  "text-success": investment.dailyRevenuePercent > 0,
                  "text-danger": investment.dailyRevenuePercent < 0,
                  "text-white-35": !investment.dailyRevenuePercent
                })}
                >
                  {`${investment.dailyRevenuePercent?.toString()?.replace("-", "") || "0"} %`}
                </p>
              </div>
            </div>

            <div className="d-flex flex-column flex-sm-row flex-lg-column align-items-sm-center align-items-lg-start justify-content-between mb-2 mb-lg-0">
              {
                investment.botsCount !== 0 ?
                  <>
                    <p className="mb-0 font-weight-300 font-sm text-white-35">
                      Bots
                    </p>
                    <p className="mb-0 font-weight-500 text-white">{investment.botsCount}</p>
                  </>
                  :
                  <>
                    <p className="font-sm text-white-35">
                      Bots
                    </p>
                    <PortfolioBody.Add>
                      <Button
                        color="info"
                        onClick={(e) => handleAddBotsClick(e)}
                        className="p-0 m-0"
                      >
                        <Plus style={{width: "11px", height: "11px", margin: "4px 10px"}}/>
                      </Button>
                    </PortfolioBody.Add>
                  </>
              }
            </div>
          </div>
          :
          <PortfolioBody.Zero>
            <img src={alertIcon} alt=""/>
            <p className="mb-0 text-white font-sm">
              The balances of all assets in this investment are equal to zero
            </p>
          </PortfolioBody.Zero>
      }
    </CardBody>
  )
}

PortfolioBody.Add = styled.div`
  &:hover {
    opacity: .5;
  }
`;

PortfolioBody.Zero = styled.div`
  display: flex;
  align-items: center;
  background: var(--dark-slate);
  padding: 8px 17px;
  border-radius: 8px;
`;

export default PortfolioBody;
