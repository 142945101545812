import React from "react";

const RateDisplay = ({ 
  rate, 
  className
}) => {
  return (
    <p className={`d-none d-md-block position-absolute font-sm font-weight-500 text-white mt-2 mb-0 ${className}`}>
      {rate}
    </p>
  );
};

export default RateDisplay;