import React from "react";
import { CardHeader } from "reactstrap";
import PropTypes from "prop-types";

import { ReactComponent as OrderIcon } from "assets/img/icons/order-icon.svg"

const OpenOrderHeader = ({ 
  orderNumber, 
  className="px-4"
}) => (
  <CardHeader className={`d-flex align-items-center p-0 position-relative py-3 ${className}`}>
    <OrderIcon />
    <h4 className="font-sm text-white font-weight-500 ml-2 mb-0">
      Open order
    </h4>
    <span className="font-sm text-white-35 font-weight-300 ml-3">
      #{orderNumber}
    </span>
  </CardHeader>
);

OpenOrderHeader.propTypes = {
  orderNumber: PropTypes.string,
};

export default OpenOrderHeader;