import React from "react";
import {Card, CardFooter, CardHeader} from "reactstrap";
import SkeletonPreloader from "components/SkeletonPreloader";

const ProfileAvatarPreloader = ({avatars}) => {
  return (
    <Card className="card-user p-4">
      <CardHeader className="d-flex justify-content-center px-2 pt-0 mb-4">
        {
          !avatars.original &&
          <SkeletonPreloader
            height={161}
            width={161}
            circle={true}
          />
        }
      </CardHeader>
      <CardFooter className="text-center p-0">
        <div className="d-flex">
          <div className="d-flex flex-column w-25">
            <SkeletonPreloader
              height={16}
              width={16}
              count={2}
              circle={true}
              className="my-auto"
            />
          </div>
          <div className="d-flex flex-column w-75">
            <SkeletonPreloader
              height={12}
              count={2}
              className="my-auto"
            />
          </div>
        </div>
      </CardFooter>
    </Card>
  )
}

export default ProfileAvatarPreloader;
