import React from "react";
import styled from "styled-components";
import {CRITERIA_FIELDS} from "views/bots/models/criteriaConfiguration";
import _ from "lodash";
import CriterionTooltips from "components/BotSettingsWidget/CriterionTooltips";

const CriterionItem = ({criterion}) => {
  const maxPower = 10

  const criterionName = CRITERIA_FIELDS.find(item => item.code === criterion.code).name

  return (
    <div className="d-flex flex-md-column flex-lg-row align-items-lg-center justify-content-between mb-2">
      <div className="d-flex flex-row align-items-center">
        <p className="font-sm mb-0 text-white-75 text-nowrap">
          {criterionName}
        </p>
        <CriterionTooltips code={criterion.code} />
      </div>
      <div className="d-flex align-items-center">
        {
          _.times(criterion.power, (e) => <CriterionItem.Badge color={"blue"} key={e}/>)
        }
        {
          _.times((maxPower - criterion.power), (e) => <CriterionItem.Badge color={"gray"} key={e}/>)
        }
      </div>
    </div>
  );
}

CriterionItem.Badge = styled.div`
  width: 10px;
  height: 14px;
  border-radius: 1px;
  margin-right: 2px;
  background: ${props => props.color === "blue" ? "var(--info)" : "rgba(255, 255, 255, 0.1)"};

  :first-child {
    border-radius: 2.5px 1px 1px 2.5px;
  }

  :last-child {
    margin-right: 0;
    border-radius: 1px 2.5px 2.5px 1px;
  }
`

export default CriterionItem;
