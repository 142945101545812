import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader
} from "reactstrap";

import StyledIconWrapper from "./StyledIconWrapper";
import { ReactComponent as Deals } from "assets/img/icons/Deals.svg";
import DealsSummaryPreloader from "../preloaders/DealsSummaryPreloader";
import DealsLifeTime from "./DealsLifeTime";
import DealsProgressBar from "./DealsProgressBar";

const DealsSummaryWidget = ({ summary={}, loading }) => {
  if (loading) return <DealsSummaryPreloader />;

  return (
    <Card className="w-100 card-border">
      <CardHeader className="p-4 d-flex justify-content-between">
        <div className="d-flex w-100">
          <StyledIconWrapper>
            <Deals
              height="24px"
              width="24px"
            />
          </StyledIconWrapper>
          <div className="d-flex flex-column my-auto ml-4">
            <p className="h2 m-0 text-white">
              {summary.countDeals || 0}
            </p>
            <label className="mb-0 text-white-35">
              Deals count
            </label>
          </div>
        </div>
        <div className="d-flex w-50 flex-column justify-content-end mb-2">
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0 text-success font-weight-500">
              {summary.countProfitableDeals || 0}
            </p>
            <p className="mb-0 text-danger font-weight-500">
              {summary.countLosingDeals || 0}
            </p>
          </div>
          <DealsProgressBar
            countLosingDeals={summary.countLosingDeals}
            countProfitableDeals={summary.countProfitableDeals}
          />
        </div>
      </CardHeader>
      <hr className="m-0 mx-4" />
      <CardBody className="px-4 pt-4 pb-3">
        <div className="d-flex justify-content-between">
          <p className="font-sm text-white-35">
            Largest profit
          </p>
          <p className="m-0">
            {
              summary.largestWinning ?
              <>
                <span className="font-weight-500 mr-1 text-white">
                  {summary.largestWinning}
                </span>
                <span className="font-weight-300 text-white-35">
                  {summary.totalProfit?.asset}
                </span>
              </> : " - "
            }
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="font-sm text-white-35">
            Largest loss
          </p>
          <p className="m-0">
            {
              summary.largestLosing ?
              <>
                <span className="font-weight-500 mr-1 text-white">
                  {summary.largestLosing}
                </span>
                <span className="font-weight-300 text-white-35">
                  {summary.totalProfit?.asset}
                </span>
              </> : " - "
            }
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="font-sm text-white-35">
            Average profit trade
          </p>
          <p className="m-0">
            {
              summary.averageProfitTrade ?
              <>
                <span className="font-weight-500 mr-1 text-white">
                  {summary.averageProfitTrade}
                </span>
                <span className="font-weight-300 text-white-35">
                  {summary.totalProfit?.asset}
                </span>
              </> : " - "
            }
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="font-sm text-white-35">
            Average loss trade
          </p>
          <p className="m-0">
            {
              summary.averageLossTrade ?
              <>
                <span className="font-weight-500 mr-1 text-white">
                  {summary.averageLossTrade}
                </span>
                <span className="font-weight-300 text-white-35">
                  {summary.totalProfit?.asset}
                </span>
              </> : " - "
            }
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="font-sm text-white-35">
            Average NET profit
          </p>
          <p className="m-0">
            {
              summary.averageNetProfit ?
              <>
                <span className="font-weight-500 mr-1 text-white">
                  {summary.averageNetProfit}
                </span>
                <span className="font-weight-300 text-white-35">
                  {summary.totalProfit?.asset}
                </span>
              </> : " - "
            }
          </p>
        </div>
      </CardBody>
      <CardFooter className="px-4 pt-0 pb-4">
        <DealsLifeTime summary={summary} />
      </CardFooter>
    </Card>
  );
};

export default DealsSummaryWidget;
