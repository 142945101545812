import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader
} from "reactstrap";

import { ReactComponent as BookOpen } from "assets/img/icons/book-open.svg";
import TimeLineItem from "components/TimeLineItem";
import StyledSubCard from "./StyledSubCard";
import TimeLinePreloader from "../preloaders/TimeLinePreloader";
import { replaceDurations } from "helpers/replaceDurations";

const TimeLog = ({ history=[], lifetime="", loading }) => {
  if (loading) return <TimeLinePreloader />;

  return (
    <Card>
      <CardHeader className="px-4 pt-4 pb-0">
        <div className="d-flex align-items-center">
          <BookOpen/>
          <p className="mb-0 ml-2 text-white font-weight-500">
            Time log
          </p>
        </div>
      </CardHeader>
      <CardBody className="p-4 pr-md-0 pr-lg-4 pl-md-2 pl-lg-4">
        <div className="d-flex flex-column">
          {
            history.map((item, index, arr) =>
              <TimeLineItem
                key={item.name}
                value={{
                  start: item.start,
                  end: item.end,
                  duration: replaceDurations(item.duration)
                }}
                position={!index ? "start" : ""}
                stepName={item.name}
                lastItem={index === arr.length - 1}
                nextItem={item[index + 1]}
              />
            )
          }
        </div>
      </CardBody>
      {
        lifetime ?
        <CardFooter className="d-flex justify-content-center pb-4">
          <StyledSubCard className="d-flex px-3 py-2">
            <p className="text-white-35 m-0">
              Duration:
            </p>
            <p className="mb-0 ml-1 text-white">
              {replaceDurations(lifetime)}
            </p>
          </StyledSubCard>
        </CardFooter> : null
      }
    </Card>
  );
};

export default TimeLog;
