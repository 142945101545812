import React from 'react';
import {Card,CardBody} from "reactstrap";
import {MarketInfoItem} from "views/exchanges/compounds/StyledCardBody";
import StyledPreloader from "components/StyledPreloader";
import _ from "lodash";
const HeaderPreloader = () => {
  return (
    <Card>
      <CardBody className="d-flex flex-column flex-md-row justify-content-md-between ">
        <div className="d-flex">
          <StyledPreloader
            circle
            height={57}
            width={57}
            className="mr-3"
          />
          <div className="d-flex flex-column my-auto mr-3">
            <StyledPreloader
              height={18}
              width={137}
              className="mb-2"
            />
            <StyledPreloader
              height={11}
              width={73}
            />
          </div>
        </div>
        <div className="d-flex mt-3 mt-md-0 ml-md-4 overflow-hidden">
          {_.times(5, (e) =>
            <MarketInfoItem className="d-flex flex-column my-auto mr-3" key={e}>
              <StyledPreloader
                height={11}
                width={40}
                className="mb-2"
              />
              <StyledPreloader
                height={16}
                width={72}
              />
            </MarketInfoItem>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default HeaderPreloader;
