import createAction from "../thunkFactory";

export const getLanguages = (signal) => createAction("LANGUAGES", (fetch) => {
  return fetch.get("/app/labels/languages", { signal })
})

export const getCurrencies = (signal) => createAction("CURRENCIES", (fetch) => {
  return fetch.get("/app/labels/currencies", { signal })
})

export const getСountries = (signal) => createAction("COUNTRIES", (fetch) => {
  return fetch.get("/app/labels/countries", { signal })
})
