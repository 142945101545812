import React, {
  useEffect,
  useState
} from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";

import Icon from "components/Icon";
import PercentBadge from "components/PercentBadge";
import CurrentExchangePreloader from "views/channels/preloaders/CurrentExchangePreloader";
import {
  firstLetterUpper,
  formatterToNull
} from "helpers/valuesFormatters";
import { getMarketTicker } from "store/exchanges/actions";
import { currentExchange } from "store/exchanges/selectors";

const CurrentExchange = ({
  selectedExchange,
  base,
  quote
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const exchange = useSelector(currentExchange);

  const isAllParametersEntered = selectedExchange && base && quote;

  useEffect(() => {
    if (isAllParametersEntered) {
      setIsLoading(true);
      const timerId = setTimeout(() => {
        dispatch(getMarketTicker(selectedExchange, `${base}-${quote}`))
          .then(() => {
            setIsLoading(false);
          })
      }, 700);
      return () => {
        setIsLoading(false);
        clearTimeout(timerId);
      };
    }
  }, [selectedExchange, dispatch, base, quote, isAllParametersEntered]);

  if (Array.isArray(exchange) && !isLoading) {
    return (
      <CurrentExchange.Wrap className="d-flex justify-content-between align-items-center my-4">
        <span className="text-danger mb-0 font-weight-500 mx-1 mb-0 font-sm">
          Market {base.toUpperCase()}-{quote.toUpperCase()} is not exists on {firstLetterUpper(selectedExchange)} exchange
        </span>
      </CurrentExchange.Wrap>
    );
  };

  return (
    <CurrentExchange.Wrap className="d-flex justify-content-between align-items-center my-4">
      {
        isLoading
          ? <CurrentExchangePreloader />
          : isAllParametersEntered
            ? <>
              <div>
                <Icon
                  type="exchanges"
                  size="thumb"
                  code={selectedExchange}
                  className="mr-1 my-0"
                />
                <span className="mb-0 font-weight-500 mx-1 mb-0 text-white font-sm">
                  {formatterToNull(exchange?.close)}
                </span>
                <span className="mr-2 mb-0 font-weight-300 font-sm">
                  {`${base.toUpperCase()} / ${quote.toUpperCase()}`}
                </span>
              </div>
              <PercentBadge value={exchange?.dailyChange} />
            </>
            : <span className="text-white-75 mb-0 font-weight-500 mx-1 mb-0 font-sm">
              Please select exchange and market
            </span>
      }
    </CurrentExchange.Wrap>
  );
};

CurrentExchange.propTypes = {
  selectedExchange: PropTypes.string,
  base: PropTypes.string,
  quote: PropTypes.string,
};

CurrentExchange.Wrap = styled.div`
  border-radius: 6px;
  border: 1px solid var(--Backgrounds-and-shades-2, #363A54);
  padding: 0.625rem;
  padding-left: 0.75rem;
`;

export default CurrentExchange;