import { GET_CONFIGURATIONS } from "./constants";

// eslint-disable-next-line complexity
export default function indicatorsConfiguration(state = [], action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CONFIGURATIONS:
      return [...payload].sort((a, b) => b.free - a.free);
    default:
      return state;
  }
}
