/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback} from "react";
import styled from "styled-components";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";

import Icon from "components/Icon";
import {setResetBotWizard} from "store/bots/wizard/actions";
import MarketLabelStep from "./MarketLabelStep";
import LookupCoins from "components/LookupCoins";
import {ReactComponent as SuccessIcon} from "assets/img/icons/successfully.svg";

const Wizard = ({
  steps,
  finishButtonClick,
  isSubmit,
  currentStep,
  setCurrentStep,
  highestStep,
  setHighestStep
}) => {
  const dispatch = useDispatch();

  const {investment} = useSelector((state) => state.botWizard);

  const handleHighestStep = (key) => {
    const currentHighestStep = key > highestStep ? key : highestStep;
    setHighestStep(currentHighestStep);
    if (highestStep > 2) setHighestStep(2);
  };

  const handleGoNextStep = useCallback(() => {
    const key = currentStep + 1;
    if (key !== steps.length) setCurrentStep(key);
    handleHighestStep(key);
  }, [steps, currentStep, setCurrentStep]);

  const handleGoBack = useCallback(() => {
    const key = currentStep - 1;
    if (currentStep !== 0) setCurrentStep(currentStep - 1);
    handleHighestStep(key);
  }, [currentStep, setCurrentStep]);

  const setStepItemClass = (index) => {
    if ((currentStep !== index && index < highestStep && currentStep !== 0) || isSubmit) {
      return "success-step"
    } else if (currentStep === index) return "info-step"
    return "muted-step"
  };

  const setBorderClass = (index) => {
    if ((currentStep !== index && index < highestStep && currentStep !== 0) || isSubmit) {
      return "success-step-border"
    } else if (currentStep === index) return "info-step-border"
    return "muted-step-border"
  };

  const handleGoBackFromHeader = (index) => {
    if(index === 0) dispatch(setResetBotWizard());
    if ((currentStep !== index && index < highestStep && currentStep !== 0) || isSubmit) {
      if (currentStep === 2 && index === 0) {
        setCurrentStep(currentStep - 2);
        return handleHighestStep(2);
      }
      return handleGoBack();
    }
  }

  return (
    <>
      <div className="d-flex flex-column flex-lg-row justify-content-between mb-4">
        {
          steps.map((step, index) =>
            <Wizard.Item
              currentStep={currentStep}
              highestStep={highestStep}
              isSubmit={isSubmit}
              index={index}
              key={step.name}
              onClick={() => handleGoBackFromHeader(index)}
            >
              <div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                <div className="d-flex align-items-center">
                  <Wizard.StepItem className={setStepItemClass(index)}>
                    {
                      (currentStep !== index && index < highestStep && currentStep !== 0) || isSubmit
                        ? <SuccessIcon width={16} height={11}/>
                        : index + 1
                    }
                  </Wizard.StepItem>
                  <p className="wizard-name font-weight-500 text-nowrap ml-3">
                    {step.name}
                  </p>
                </div>
                {
                  investment?.asset && currentStep !== 0 &&
                  <Wizard.Label className="label-step-1">
                    <p className="mb-0 mr-1 text-white font-weight-500 font-md">
                      {investment?.asset}
                    </p>
                    <p className="mb-0 text-white-35 font-weight-300 text-nowrap mr-1">
                      <LookupCoins coin={investment?.asset}/>
                    </p>
                    <div className="position-relative d-flex align-items-center">
                      <Icon
                        type="exchanges"
                        size="thumb"
                        code={investment?.credentials?.exchange}
                      />
                      <Icon
                        size="thumb"
                        code={investment?.asset}
                        className="position-absolute with-border"
                        style={{left: "12px", top: "-2px", width: "24px"}}
                      />
                    </div>
                  </Wizard.Label>
                }
                {
                  investment?.asset && currentStep === 2 &&
                  <Wizard.Label className="label-step-2">
                    <MarketLabelStep />
                  </Wizard.Label>
                }
              </div>
              <div className={`wizard-item__line w-100 mt-3 ${setBorderClass(index)}`}/>
            </Wizard.Item>
          )
        }
      </div>
      <div className="mt-2">
        {
          steps.map((step, index) =>
            <Wizard.StepWrapper
              key={step.name}
              className={classNames("d-none", {
                "d-block": currentStep === index
              })}
            >
              <step.component
                confirm={currentStep + 1 === steps.length ? finishButtonClick : () => {}}
                goNextStep={handleGoNextStep}
                goBack={currentStep === 0 ? () => {} : handleGoBack}
                {...step.props}
              />
            </Wizard.StepWrapper>
          )}
      </div>
    </>
  );
};

Wizard.Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  line-height: 26px;

  .wizard-name {
    font-size: 22px;
    margin-bottom: 0;
    color: #FFFFFF;

  }

  .wizard-item__line {
    height: 4px;
    border-radius: 4px;
  }

  .success-step-border {
    background: #1FAC46;
    box-shadow: 0 0 20px 2px rgba(31, 172, 70, 0.2);
  }

  .info-step-border {
    background: #1A6EDF;
  }

  .muted-step-border {
    background: #122E54;
  }

  .label-step-1 {
    margin-right: 12px;
    img {
      &:first-child {
        z-index: 1;
      }
    }
    .with-border {
      border: 2px solid #1D1E2A;
      box-sizing: content-box;
      border-radius: 50px;
    }
  }

  &:not(:nth-child(2)) {
    .label-step-2 {
      display: none !important;
    }
  }

  &:not(:first-child) {
    .label-step-1 {
      display: none !important;
    }
  }

  &:hover {
    background: ${({currentStep, index, highestStep, isSubmit}) => (
            currentStep !== index && index < highestStep && currentStep !== 0) || isSubmit
            ? "var(--dark-slate)" : "inherit"
    };
    cursor: ${({currentStep, index, highestStep, isSubmit}) => (
            currentStep !== index && index < highestStep && currentStep !== 0) || isSubmit
            ? "pointer" : "default"
    };
    border-radius: 8px;

    .wizard-name {
      text-decoration: ${({currentStep, index, highestStep, isSubmit}) => (
              currentStep !== index && index < highestStep && currentStep !== 0) || isSubmit
              ? "underline" : "none"
      };
      color: ${({currentStep, index, highestStep, isSubmit}) => (
              currentStep !== index && index < highestStep && currentStep !== 0) || isSubmit
              ? "rgba(255,255,255, 0.75)" : "#fff"
      };
    }
  }
`;

Wizard.Label = styled.div`
  display: flex;
  align-items: center;
  line-height: 19px;
`;

Wizard.StepItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 500;

  &.success-step {
    background: #1FAC46;
    box-shadow: 0 0 20px 2px rgba(31, 172, 70, 0.2);
    color: #fff;
    svg {
      path {
        stroke: #ffffff;
      }
    }
  }

  &.info-step {
    background: rgba(29, 140, 248);
    color: #fff;
  }

  &.muted-step {
    background: #122E54;
    color: #fff;
  }
`;

Wizard.StepWrapper = styled.div`
  padding: 0;
  margin: 0;
  background: var(--dark-slate);
  border-radius: 16px !important;
  overflow: hidden !important;
`;

export default Wizard;
