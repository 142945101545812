import styled from "styled-components";

export const StyledBotTypes = styled.div`
gap: 12px;

& .type-item {
  padding: 1.25rem 1.5rem;
  cursor: pointer;
  min-height: 166px;
  border: 2px solid #363A54;
  background: #2C2F44;
  border-radius: 10px;
  transition: all 0.3s;

  &:hover {
    border: 2px solid var(--dusk-steel);
    background: #363A54;

    & svg {
      path {
        fill: #4A4E73;
      }
    }
  }

  &.active {
    border: 2px solid var(--info);
    background: #193E6F;
    box-shadow: 0px 4px 28px 0px rgba(6, 64, 126, 0.40);

    & svg {
      path {
        fill: #19519B;
      }

      & rect:first-child, & rect:nth-child(2) {
        fill: #19519B;
      }

      & rect {
        fill: #1A6EDF;
        stroke: #193E6F;
      }
    }
  }

  & svg {
    position: absolute;
    right: 14.5px;
    top: 14.5px;

    &.template-icon {
      top: 2px;
      right: 12px;
    }
  }
}
`;