import React, {useCallback, useLayoutEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Card, Modal} from "reactstrap";
import styled from "styled-components";
import qs from "qs";

import {fetchBalances} from "store/credentials/actions";
import ExchangeHeaderModal from "../compounds/exchangeBalances/ExchangeHeaderModal";
import ExchangeTableModal from "../compounds/exchangeBalances/ExchangeTableModal";

const ExchangesBalancesModal = ({balances, isToggle, isModalOpen, id, estimated}) => {
  const dispatch = useDispatch();

  const {items, request} = useSelector((state) => state.credentials.balances.data);
  const loading = useSelector((state) => state.credentials.balances.loading);

  useLayoutEffect(() => {
    const filter = qs.stringify({
      sort: {by: "percent", order: "DESC"},
      limit: 100
    });
    dispatch(fetchBalances(id, `?${filter}`));
  }, [dispatch, id]);

  const handleSorting = (value) => {
    const filter = qs.stringify({
      sort: value,
      filter: request?.filter || [],
      limit: 100
    });

    dispatch(fetchBalances(id, `?${filter}`));
  }

  const handleSearch = useCallback((value) => {
    const filter = qs.stringify({
      sort: request?.sort || [],
      filter: {asset: {like: value}},
      limit: 100
    });

    dispatch(fetchBalances(id, `?${filter}`));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ExchangesBalancesModal.Modal isOpen={isModalOpen} toggle={isToggle} modalClassName="modal-black">
      <Card className="m-0 p-0">
        <ExchangeHeaderModal
          isToggle={isToggle}
          balances={balances}
          handleSearch={handleSearch}
          estimated={estimated}
        />

        <ExchangeTableModal
          items={items}
          request={request}
          loading={loading}
          handleSorting={handleSorting}
        />
      </Card>
    </ExchangesBalancesModal.Modal>
  );
};

ExchangesBalancesModal.Modal = styled(Modal)`
  @media (min-width: 576px) {
    margin: 0 auto !important;
  }

  @media (min-width: 992px) {
    max-width: fit-content !important;
    .modal-content {
      width: 903px;
    }
  }

  height: inherit;
  max-width: 100% !important;

  .card {
    background: rgba(42, 44, 66, 1);
  }

  .modal-content {
    border-radius: 16px !important;
    overflow: hidden;
    margin: 0 !important;
  }

  .pointer {
    cursor: pointer;
  }
`;

export default ExchangesBalancesModal;
