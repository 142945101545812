export const REBALANCE_TABLE_HEAD = [
  { name: "investment", label: "Coin", isSortable: true },
  { name: "total", label: "Total", isSortable: true },
  { name: "percent", label: "Percent", isSortable: true },
  { name: "createdAt", label: "Date", isSortable: true },
  { name: "status", label: "Status", isSortable: true, className: "w-100 justify-content-end" },
];

export const REBALANCE_STATUS_OPTIONS = [
  { value: "completed", label: "Completed", color: "#5FD3BC" },
  { value: "open", label: "Open", color: "var(--info)" },
  { value: "failed", label: "Failed", color: "#FF8D72" },
];

export const STATUS_COLORS = {
  open: "var(--info)",
  failed: "#FF8D72",
  completed: "#5FD3BC",
  cancelled:"#FFFFFF59",
  pending_cancel:"#FFFFFF59",
};
