import { AGREEMENT_STATUS_OPTIONS, DEAL_STATUS_OPTIONS } from "models/filters";
import { getAgreements } from "store/agreements/actions";
import { selectAgreements } from "store/agreements/selectors";
import { fetchDeals } from "store/deals/actions";
import { dealsListSelector } from "store/deals/selectors";

const getDealDataMapping = (item) => ({
  invested: item?.invested?.amount,
  code: item?.bot?.investment?.credentials?.exchange,
  profit: item?.profit?.amount,
  profitPercent: item?.profit?.percent,
});

const getAgreementDataMapping = (item) => ({
  invested: item?.invested,
  code: item?.investment?.credentials?.exchange,
  profit: item?.profit,
  profitPercent: item?.profitPercent,
});

const TABLE_TYPE = {
  deals: {
    dataMapping: getDealDataMapping,
    action: fetchDeals,
    selector: dealsListSelector,
    empty: {
      title: "No completed deals found",
      descriptionBefore: "To see them displayed here, please ensure that your bots are enabled, or just keep calm if they are already working"
    },
    header: {
      title: "Last deals completed",
      linkTo: "/deals",
      linkText: "All deals",
      path: "/deals"
    },
    filters:{
      status: DEAL_STATUS_OPTIONS,
    }
  },
  agreements: {
    dataMapping: getAgreementDataMapping,
    action: getAgreements,
    selector: selectAgreements,
    empty: {
      title: "No completed agreements found",
      descriptionBefore: "To see agreements here, subscribe to a channel and accept a signal. This will open an agreement for you."
    },
    header: {
      title: "Signal agreements",
      linkTo: "/signals",
      linkText: "All signals",
      path: "/agreements"
    },
    filters:{
      status: AGREEMENT_STATUS_OPTIONS,
    }
  }
};

export default TABLE_TYPE