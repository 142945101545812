import React from "react";
import styled from "styled-components";
import { ReactComponent as Refresh } from "assets/img/icons/refresh-2.svg";

const RefreshTickers = ({isOpen, onHandleRefresh, loading, onClick}) => {
  if (!isOpen) {
    return (
      <RefreshWrapper onClick={onClick}>
        <Text>No tickers</Text>
        <IconRefresh loading={loading}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              onHandleRefresh()
            }}
            className="d-flex align-items-center"
          >
            <svg id="refreshButton" version="1.0" width="19px" height="19px" viewBox="0 0 128 128">
              <g>
                <path fill="#ffffff" d="M109.25 55.5h-36l12-12a29.54 29.54 0 0 0-49.53 12H18.75A46.04 46.04 0 0 1 96.9 31.84l12.35-12.34v36zm-90.5 17h36l-12 12a29.54 29.54 0 0 0 49.53-12h16.97A46.04 46.04 0 0 1 31.1 96.16L18.74 108.5v-36z"/>
                <animateTransform begin="refreshButton.click" attributeName="transform" fill="freeze" type="rotate" from="0 64 64" to="360 64 64" dur="720ms" repeatCount="0"/>
              </g>
            </svg>
          </div>
        </IconRefresh>
      </RefreshWrapper>
    );
  }

    return (
      <IconRefresh
        onClick={() => onHandleRefresh()}
        className="d-flex align-items-center mb-2 ml-2"
        loading={loading}
      >
        <Refresh width={16} height={16} className={loading && "image"}/>
      </IconRefresh>
    )
};

const RefreshWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 16px;
  gap: 87px;
  border: 1px solid #ff8d72;
  border-radius: 8px;
`;

const Text = styled.div`
  color: var(--white);
  font-size: 14px;
  line-height: 16px;
`;

const IconRefresh = styled.div`
  svg {
    cursor: pointer;

    path {
      stroke: ${(props) => props.loading ? "rgb(29, 140, 248, 0.3)" : "rgb(29, 140, 248)"};
    }
    &:hover {
      path {
        stroke: rgb(29, 140, 248, 0.75) !important;
      }
      opacity: 0.5;
    }
  }

  .image {
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
  }
  @-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform:rotate(360deg);
    }
  }

`;

export default RefreshTickers;
