import React from "react";
import styled from "styled-components";

import SkeletonPreloader from "components/SkeletonPreloader";

const NotificationItemPreloader = () => {
  return (
    <NotificationItemPreloader.Box>
      <NotificationItemPreloader.Skelet>
        <SkeletonPreloader
          height={8}
          className="my-auto"
          style={{background: "linear-gradient(90deg, #535462 0%, #535462 50%, rgba(83, 84, 98, 0) 100%)"}}
        />
      </NotificationItemPreloader.Skelet>
      <NotificationItemPreloader.Skelet>
        <SkeletonPreloader
          height={8}
          className="my-auto"
          style={{background: "linear-gradient(90deg, #535462 0%, rgba(83, 84, 98, 0) 75%)"}}
        />
      </NotificationItemPreloader.Skelet>
      <NotificationItemPreloader.Skelet>
        <SkeletonPreloader
          height={10}
          className="my-auto"
          style={{background: "linear-gradient(90deg, #535462 0%, rgba(83, 84, 98, 0) 50%)"}}
        />
      </NotificationItemPreloader.Skelet>
    </NotificationItemPreloader.Box>
  );
}

NotificationItemPreloader.Box = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 8px solid rgba(29, 30, 42, 0.75);
  border-radius: 8px;
  opacity: 0.1;
  height: 62px;
  margin-top: 0.5rem;
  margin-left: 16px;
  padding-right: 16px;
`;

NotificationItemPreloader.Skelet = styled.div`
  margin-left: 16px;
`;

export default NotificationItemPreloader;
