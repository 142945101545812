import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  CardHeader
} from "reactstrap";

import SkeletonPreloader from "components/SkeletonPreloader";

const TradeRequestPreloader = ({
  count, 
  className
}) => {

  const renderRequest = (count, className) => {
    return (
      <div className="d-flex flex-column w-100">
        {
          _.times(count, (e) =>
            <Card
              key={e}
              className={className}
            >
              <CardHeader className="d-flex flex-wrap flex-md-nowrap p-4">
                <div className="d-flex align-items-center mb-3 mb-md-0">
                  <SkeletonPreloader
                    height={32}
                    width={50}
                    className="mr-3"
                  />
                  <div className="d-flex mt-3 mt-md-0">
                    {
                      _.times(3, (e) =>
                        <div key={e} className="d-flex flex-column mr-2 mr-md-4">
                          <SkeletonPreloader
                            className="mb-1"
                            height={14}
                            width={77}
                          />
                          <SkeletonPreloader
                            height={16}
                            width={87}
                          />
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className="d-flex ml-auto mr-0">
                  <SkeletonPreloader
                    className="mr-2"
                    height={22}
                    width={77}
                  />
                  <SkeletonPreloader
                    height={20}
                    width={97}
                  />
                  <SkeletonPreloader
                    circle
                    height={24}
                    width={24}
                  />
                </div>
              </CardHeader>
              <CardBody
                style={{ background: "rgba(148, 158, 255, 0.06)", padding: "24px" }}
                className="d-flex flex-column flex-md-row"
              >
                {
                  _.times(5, (e) =>
                    <div key={e} className="d-flex flex-column mb-3 mb-md-0 mr-4">
                      <SkeletonPreloader
                        height={14}
                        width={70}
                      />
                      <SkeletonPreloader
                        height={16}
                        width={47}
                        className="mt-2"
                      />
                    </div>
                  )
                }
                <div className="d-flex flex-column ml-auto mr-0">
                  <SkeletonPreloader
                    height={14}
                    width={47}
                  />
                  <SkeletonPreloader
                    height={16}
                    width={74}
                    className="mt-2"
                  />
                </div>
              </CardBody>
            </Card>
          )
        }
      </div>
    );
  };

  return (
    <>
      <SkeletonPreloader
        className="mb-3"
        height={20}
        width={20}
      />
      <SkeletonPreloader
        className="mb-3 ml-1"
        height={20}
        width={137}
      />
      <SkeletonPreloader
        className="mb-3 ml-1"
        height={20}
        width={215}
      />
      {renderRequest(count, className)}
    </>
  );

};

TradeRequestPreloader.propTypes = {
  count: PropTypes.number,
  className: PropTypes.string
};

export default TradeRequestPreloader;