import React, {useState} from "react";
import { bindActionCreators } from "redux";
import {connect} from "react-redux";
import styled from "styled-components";
import classNames from "classnames";
import {indicatorsConfiguration} from "views/strategies/wizard/indicatorsConfiguration";
import { ReactComponent as TrashIcon } from "assets/img/icons/trash-indicator-icon.svg";
import { ReactComponent as SettingsIcon } from "assets/img/icons/settings-indicator-icon.svg";
import { ReactComponent as Information } from "assets/img/icons/information.svg";
import IndicatorsModal from "../../indicators/IndicatorsModal";
import {editDraftIndicator, removeDraftIndicator} from "store/strategies/actions";
import {Tooltip} from "reactstrap";
import {removeDraftCreatedIndicator} from "store/bots/actions";

const Indicator = ({
  indicator,
  removeDraftIndicator,
  editDraftIndicator,
  removeDraftCreatedIndicator,
}) => {
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [isShow, setIsShow] = useState(false)
  const [currentType, setCurrentType] = useState(indicator?.type || false)
  const [currentRequired, setCurrentRequired] = useState(indicator?.required || false)

  const indicatorName = indicatorsConfiguration.filter(({value})=> value === indicator.code)[0]

  const handleRequiredSignals = (required) => {
    setCurrentRequired(required)
    editDraftIndicator({
      id: indicator.id,
      required,
    })
  }

  const handleCurrentType = (type) => {
    setCurrentType(type)
    editDraftIndicator({
      id: indicator.id,
      type
    })
  }

  const toggleShow = () => setIsShow(!isShow);

  return(
    <>
      <Indicator.Card
        required={currentRequired}
        className="d-block mb-2 d-flex flex-column flex-md-row align-items-center position-relative"
      >
        <div className="d-flex">
          <div className="px-0 mr-3">
            <div
              className="position-relative necessary-signal mr-0"
              id={`indicator-${indicator.id}`}
              onClick={() => handleRequiredSignals(!currentRequired)}
            >
              <Information />
            </div>
            <Tooltip
              target={`indicator-${indicator.id}`}
              placement="top-start"
              isOpen={isShow}
              toggle={toggleShow}
              className="text-nowrap"
            >
              Necessary indicator for trade request
            </Tooltip>
          </div>
          <div className="signals d-flex flex-row px-0 mr-3">
            <Indicator.BadgeGroup className="d-flex">
              <Indicator.Badge className={classNames("pt-1 pb-1 pl-2 pr-2 my-auto", {
                  "bg-success": currentType === "buy"
                })}
                onClick={() => handleCurrentType('buy')}
              >
                <p className={classNames("h5 my-auto font-weight-bold", {
                    "dark": currentType === "buy",
                    "text-white-35": currentType !== "buy"
                  })}
                >
                  BUY
                </p>
              </Indicator.Badge>
              <Indicator.Badge className={classNames("pt-1 pb-1 pl-2 pr-2 my-auto font-weight-bold", {
                  "bg-danger": currentType === "sell"
                })}
              onClick={() => handleCurrentType('sell')}
              >
                <p className={classNames("h5 my-auto", {
                    "dark": currentType === "sell",
                    "text-white-35": currentType !== "sell"
                  })}
                >
                  SELL
                </p>
              </Indicator.Badge>
              <Indicator.Badge className={classNames("pt-1 pb-1 pl-2 pr-2 my-auto font-weight-bold", {
                  "bg-warning": currentType === "both"
                })}
              onClick={() => handleCurrentType('both')}
              >
                <p className={classNames("h5 my-auto", {
                    "dark": currentType === "both",
                    "text-white-35": currentType !== "both"
                  })}
                >
                  BOTH
                </p>
              </Indicator.Badge>
            </Indicator.BadgeGroup>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row mt-3 mt-md-0 align-items-center px-0 text-white align-items-center">
          <p className="text-white font-weight-500 mb-0 font-sm">
            {indicatorName && indicatorName.label}
          </p>
          <p className="mb-0 ml-md-4 text-white-35 font-weight-400 font-sm text-nowrap">
            {Object.values(indicator.settings || {}).length > 0 && `${Object.values(indicator.settings).join(", ")}`}
          </p>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-end mt-3 mt-md-0 ml-md-auto px-0">
          <div
            className="settings-indicator-icon mr-3"
            onClick={() => setEditModalIsOpen(true)}
          >
            <SettingsIcon />
          </div>
          <div
            className="trash-indicator-icon ml-0"
            onClick={() => {
              removeDraftIndicator(indicator.id)
              removeDraftCreatedIndicator(indicator.id)
            }}
          >
            <TrashIcon />
          </div>
        </div>
      </Indicator.Card>
      {
        editModalIsOpen ?
        <IndicatorsModal
          setCurrentRequired={setCurrentRequired}
          setCurrentType={setCurrentType}
          modalIsOpen={editModalIsOpen}
          onSetModalIsOpen={setEditModalIsOpen}
          editingIndicator={indicator}
          editing
          draft
        /> : null
      }
    </>
  );
};

Indicator.Card = styled.div`
  background: #2e3148;
  border-radius: 8px;
  padding: 12px 24px;
  cursor: pointer;
  height: auto;



  .label {
    color: #FFFFFF59;
  }

  .necessary-signal {
    width: 24px;
    svg {
      path {
        stroke: ${props => props.required ? 'var(--warning)' : 'rgba(255, 255, 255, 0.35)'};
        transition: .3s all ease-in-out;
      }
    }
    :hover {
      svg {
        transition: .3s all ease-in-out;
        path {
          fill: white;
          stroke: ${props => props.required ? 'var(--warning)' : 'rgba(255, 255, 255, 0.35)'};
          stroke-opacity: ${props => props.required ? 0.35 : 1};
        }
      }
    }
  }

  .signals {
    max-width: 122px;
  }

  .settings-indicator-icon,
  .trash-indicator-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    width: 24px;
    height: 24px;

    :hover {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 4px;
    }
  }

  .settings-indicator-icon {
    :hover {
      svg {
        path {
          stroke: var(--white);
          stroke-opacity: 1;
        }
      }
    }
  }

  .trash-indicator-icon {
    margin-left: 10px;
    :hover {
      svg {
        path {
          stroke: var(--white);
          stroke-opacity: 0.75;
        }
      }
    }
  }
`;

Indicator.Badge = styled.span`
  background: #27293c;
  border-radius: 4px;
  height: 20px;

  :hover {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
    transition: .3s all ease-in-out;
  }

  p {
    font-size: 10px;
  }

  p.dark {
    color: #1D1E2A;
  }

  p.muted {
    color: #A3A3A3;
  }
`;

Indicator.BadgeGroup = styled.div`
  background: #27293c !important;
  border-radius: 6px;
  height: 26px;
  padding: 3px;
`;

const mapDispatchToProps = (dispatch) => bindActionCreators({
  removeDraftIndicator,
  removeDraftCreatedIndicator,
  editDraftIndicator
}, dispatch);

export default connect(null, mapDispatchToProps)(Indicator);
