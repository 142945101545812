import React from "react";
import styled from "styled-components";
import {Card, CardBody, CardHeader} from "reactstrap";
import _ from "lodash";

import useWindowDimensions from "hooks/useWindowDimensions";
import SkeletonPreloader from "components/SkeletonPreloader";

const MyCredentialsTablePreloader = () => {

  const {width} = useWindowDimensions();

  const renderTable = () => {
    return (
      <CardBody className="p-4 d-flex flex-column">
        {
          _.times(2, (e) => (
            <MyCredentialsTablePreloader.Wrapper
              key={e}
              className="d-flex align-items-center justify-content-between p-4 mb-2"
            >
              <div className="d-flex">
                <div className="d-flex position-relative w-auto">
                  <SkeletonPreloader
                    circle
                    height={57}
                    width={57}
                  />
                  <MyCredentialsTablePreloader.SmallCircle
                    circle
                    height={23}
                    width={23}
                  />
                </div>
                <div className="d-flex flex-column">
                  <SkeletonPreloader
                    height={20}
                    width={78}
                    className="ml-3"
                  />
                  <SkeletonPreloader
                    height={20}
                    width={150}
                    className="ml-3 mt-3"
                  />
                </div>
              </div>
              {
                (width > 400) &&
                <div className="d-flex">
                  <SkeletonPreloader
                    height={20}
                    width={30}
                  />
                  <div className="d-flex flex-column align-items-end">
                    <SkeletonPreloader
                      height={20}
                      width={50}
                      className="ml-2"
                    />
                    <SkeletonPreloader
                      height={20}
                      width={50}
                      className="ml-2 mt-3"
                    />
                  </div>
                </div>
              }
            </MyCredentialsTablePreloader.Wrapper>
          ))
        }
      </CardBody>
    );
  };

  return (
    <MyCredentialsTablePreloader.StyledCard  className="StyledCard, mb-0, overflow-hidden">
      <CardHeader className="pt-4 px-4 overflow-hidden">
        <div className="d-flex align-items-center">
          <SkeletonPreloader
            circle
            height={20}
            width={20}
          />
          <SkeletonPreloader
            height={12}
            width={79}
            className="ml-2"
          />
        </div>
      </CardHeader>
      {renderTable()}
    </MyCredentialsTablePreloader.StyledCard >);
};

MyCredentialsTablePreloader.StyledCard = styled(Card)`
  &.StyledCard {
    border-radius: 12px 12px 0 0  !important;
  }
`;

MyCredentialsTablePreloader.Wrapper = styled.div`
  border-radius: 10px;
  background: var(--BG-2nd-block, #2E3148);
`;

MyCredentialsTablePreloader.SmallCircle = styled(SkeletonPreloader)`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export default MyCredentialsTablePreloader;
