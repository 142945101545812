import React from "react";
import RealRange from "./RealRange";
import PlannedRange from "./PlannedRange";

const AllocationRebalancing = ({values, realPercent, plannedPercent, counter, index, setFieldValue, isEdited, enabled, initialPlannedCount}) => {

  if (!initialPlannedCount) {
    return (
      <RealRange
        values={values}
        setFieldValue={setFieldValue}
        index={index}
        isEdited={isEdited}
        realPercent={realPercent}
        enabled={enabled}
      />
    )
  }

  return (
    <PlannedRange
      values={values}
      setFieldValue={setFieldValue}
      index={index}
      isEdited={isEdited}
      realPercent={realPercent}
      counter={counter}
      plannedPercent={plannedPercent}
      enabled={enabled}
    />
  )
}

export default AllocationRebalancing;