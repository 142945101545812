import React, { useState } from "react";
import styled from "styled-components";

import Icon from "components/Icon";
import SkeletonPreloader from "components/SkeletonPreloader";

const iconSizes = {
  smallSize: 16,
  thumb: 24,
  botSize: 32,
  xxs: 40,
  botPage: 45,
  xs: 56,
  s: 100,
  md: 200,
  lg: 500,
  xl: 1000
};

const BigOptionIcon = ({
  isCustomLogo,
  icon,
  logo
}) => {
  const [isLoaded, setIsLoaded] = useState(true);

  if (isCustomLogo) {
    return (
      <>
        <SkeletonPreloader
          borderRadius="4px"
          height={iconSizes[icon.size]}
          width={iconSizes[icon.size]}
          className={`d-${isLoaded ? "block" : "none"} my-auto ml-1`}
        />
        <BigOptionIcon.Wrap
          size={iconSizes[icon.size]}
          className={`d-${!isLoaded ? "block" : "none"} my-auto ml-1`}
          onLoad={() => setIsLoaded(false)}
        >
          <img
            src={logo}
            alt="logo_options"
            className="logo-img"
          />
        </BigOptionIcon.Wrap>
      </>    
    );
  } else{
    return (
    
      <span className="d-inline-block">
      <Icon
        code={icon.code}
        size={icon.size}
        type={icon.type}
        className="ml-1"
      />
    </span>
    )
  }
};

BigOptionIcon.Wrap = styled.span`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  overflow: hidden;

  .logo-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    margin: 0 auto;
  }
`;

export default BigOptionIcon;