import React from "react";
import {times} from "lodash";
import {
  CardBody,
  Card,
  CardHeader
} from "reactstrap";

import SkeletonPreloader from "components/SkeletonPreloader";

const ChannelDescriptionPreload = () => (
  <Card>
    <CardHeader className="p-4" id="preloader">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-row align-items-center ">
          <SkeletonPreloader
            height={20}
            width={20}
          />
          <SkeletonPreloader
            height={19}
            width={66}
            className="ml-2 mb-0"
          />

        </div>
        <SkeletonPreloader
          height={22}
          width={42}
        />
      </div>
    </CardHeader>
    <CardBody className="mt-4 mr-4 pr-3">
      {
        times(7, (e) =>
            <SkeletonPreloader
              height={16}
              key={e}
            />
        )
      }
    </CardBody>
  </Card>
)

export default ChannelDescriptionPreload;