import { combineReducers } from "redux";

import withDataLoading from "store/withDataLoading";
import { UPDATE_INVESTMENT_SETTINGS } from "./constants";

const initialState = { data: {}, loading: false, error: false };

const item = (state=initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_INVESTMENT_SETTINGS:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
        },
      };

    default:
      return state;
  }
};

export default combineReducers({
  item: withDataLoading(item, "INVESTMENT"),
  list: withDataLoading(null, "INVESTMENTS"),
  botsStatistics: withDataLoading(null, "BOTS_STATISTICS"),
  InvestmentStatistics: withDataLoading(null, "INVESTMENT_STATISTICS")
});
