import React, {
  useRef,
  useState,
  useEffect
} from "react";

import { 
  FormGroupSymbolStyled, 
  FormGroupWrapperStyled 
} from "./index.styles";

const FormGroupWithSymbols = ({
  children,
  symbolRight,
  symbolLeft,
  symbolRightColor,
  symbolLeftColor,
  className = "",
  classNameSymbolL = "",
  customPaddingLeft,
  customPaddingRight
}) => {
  const symbolLeftRef = useRef(null);
  const symbolRightRef = useRef(null);
  const [rightSymbolWidth, setRightSymbolWidth] = useState(0);
  const [leftSymbolWidth, setLeftSymbolWidth] = useState(0);

  useEffect(() => {
    if (symbolLeft) setLeftSymbolWidth(symbolLeftRef.current?.clientWidth);
    if (symbolRight) setRightSymbolWidth(symbolRightRef.current?.clientWidth);
  }, [symbolLeft, symbolRight]);

  return (
    <FormGroupWrapperStyled
      className={`position-relative ${className}`}
      leftSymbolWidth={leftSymbolWidth}
      rightSymbolWidth={rightSymbolWidth}
      customPaddingLeft={customPaddingLeft}
      customPaddingRight={customPaddingRight}
      data-testid="form-group-with-symbols-wrapper"
    >
      {
        symbolLeft ?
          <FormGroupSymbolStyled
            data-testid="form-group-with-symbol-left"
            className={`units-wrapper symbol-left ${classNameSymbolL}`}
            ref={symbolLeftRef}
            color={symbolLeftColor}
          >
            {symbolLeft}
          </FormGroupSymbolStyled> : null
      }
      {children}
      {
        symbolRight ?
          <FormGroupSymbolStyled
            data-testid="form-group-with-symbol-right"
            className="units-wrapper symbol-right"
            ref={symbolRightRef}
            color={symbolRightColor}
          >
            {symbolRight}
          </FormGroupSymbolStyled> : null
      }
    </FormGroupWrapperStyled>
  );
};

export default FormGroupWithSymbols;
