import React, { useState } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { Button } from "reactstrap";

import { defaultImages } from "../utils/defaultImages";
import Dropzone from "react-dropzone";

const Images = ({
  setFieldValue,
  values,
}) => {
  const [filter, setFilter] = useState("default");
  const [image, setImage] = useState(null);

  const handleAddFile = (files) => {
    setFieldValue("file", files[0]);
    setImage(URL.createObjectURL(files[0]));
  };

  return(
    <>
      <div className="d-flex mb-3">
        <Images.FilterTab className={classNames("d-flex justify-content-center", {
            active: filter === "default"
          })}
          onClick={() => setFilter("default")}
        >
          <p className={classNames("text-center my-auto", {
              "text-muted": filter !== "default",
              "text-white font-weight-bold": filter === "default",
            })}
          >
            Default image
          </p>
        </Images.FilterTab>
        <Images.FilterTab className={classNames("d-flex justify-content-center", {
            active: filter === "custom"
          })}
          onClick={() => setFilter("custom")}
        >
          <p className={classNames("text-center my-auto", {
              "text-muted": filter !== "custom",
              "text-white font-weight-bold": filter === "custom"
            })}
          >
            Upload image
          </p>
        </Images.FilterTab>
      </div>
      {
        filter === "default" ?
        <Images.ImagesWrapper>
          {
            Object.keys(defaultImages).map((key) =>
              <Images.DefaultAvatar
                bgImg={defaultImages[key]}
                key={key}
                onClick={() => setFieldValue("defaultImage", +key)}
                className={classNames("m-2", {
                  active: +key === values.defaultImage
                })}
              />
            )
          }
        </Images.ImagesWrapper>
        :
        <>
          {
            image ?
            <Images.Peview image={image}>
              <div className="d-flex flex-column justify-content-end pull-right h-100">
                <Button
                  size="sm"
                  color="default"
                  onClick={() => {
                    setImage(null);
                    setFieldValue("file", "");
                  }}
                  className="m-3 btn-icon"
                >
                  <i className="tim-icons icon-trash-simple mb-1" />
                </Button>
              </div>
            </Images.Peview>
            :
            <Dropzone
              onDrop={handleAddFile}
            >
              {({ getRootProps, getInputProps }) => (
                <Images.DropZone
                  {...getRootProps({ className: "dropzone d-flex flex-column justify-content-center w-100" })}
                >
                  <input {...getInputProps()} />
                  <p className="mb-3 text-center">
                    Please drop your image here or click to upload
                  </p>
                  <p className="h5 text-muted text-center">
                    Supported files
                  </p>
                  <p className="text-muted text-center">
                    .jpg, .jpeg, .png, .gif
                  </p>
                </Images.DropZone>
              )}
            </Dropzone>
          }
        </>
      }
    </>
  );
};

Images.FilterTab = styled.div`
  width: 100px;
  height: 46px;
  background: #1d1e2a;
  border-radius: 4px;
  cursor: pointer;
  p {
    font-size: 12px !important;
  }

  &.active {
    background: linear-gradient(103.01deg, #2091FF 0%, #004DC1 100%);
  }
`;

Images.ImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  justify-content: space-between;

  @media (min-width: 768px) and (max-width: 1199.98px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 767.98px) {
    display: flex;
    flex-direction: column;
  }
`;

Images.DefaultAvatar = styled.div`
  height: 72px;
  cursor: pointer
  background: url(${(props) => props.bgImg}) no-repeat center/cover;

  &.active {
    background:
      linear-gradient(0deg, rgba(63, 203, 176, 0.35),
      rgba(63, 203, 176, 0.35)),
      url(${(props) => props.bgImg}) no-repeat center/cover;
    border: 1px solid #3fcbb0;
  }
`;

Images.DropZone = styled.div`
  height: 200px;
  background: rgba(29, 30, 42, 0.6);
  border: 1px dashed rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
`;

Images.Peview = styled.div`
  background-image: ${(props) => `url(${props.image});`}
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  overflow: hidden;
`;

export default Images;
