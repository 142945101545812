import React from "react";
import classNames from "classnames";
import {formatterToNull} from "helpers/valuesFormatters";
import moment from "moment/moment";
import styled from "styled-components";

const MarketTradeRow = ({item}) => {
  return (
    <tr>
      <td>
        <p className={classNames("text-uppercase mb-0 font-weight-500", {
          "text-success": item.type === "buy",
          "text-danger": item.type === "sell"
        })}>
          {item.type}
        </p>
      </td>

      <td>
        <StyledAmount amount={0}>
          {item.orderNumber}
        </StyledAmount>
      </td>

      <td>
        <StyledAmount amount={0}>
          {formatterToNull(item.tradeId)}
        </StyledAmount>
      </td>

      <td>
        <div className="d-flex">
          <StyledAmount amount={1}>
            {item.market.base}
          </StyledAmount>
          <StyledAmount amount={0} className="mx-1">/</StyledAmount>
          <StyledAmount amount={1}>
            {item.market.quote}
          </StyledAmount>
        </div>
      </td>

      <td>
        <div className="d-flex">
          <StyledAmount amount={item.amount.amount}>
            {formatterToNull(item.amount.amount)}
          </StyledAmount>
          <p className="text-white-35 ml-1 mb-0">
            {item.amount.currency}
          </p>
        </div>
      </td>

      <td>
        <StyledAmount amount={item.rate}>
          {formatterToNull(item.rate)}
        </StyledAmount>
      </td>

      <td>
        <div className="d-flex">
          <StyledAmount amount={item.quantity.amount}>
            {formatterToNull(item.quantity.amount)}
          </StyledAmount>
          <p className="text-white-35 ml-1 mb-0">
            {item.quantity.currency}
          </p>
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center">
          <StyledAmount amount={item.fee.amount}>
            {formatterToNull(item.fee.amount)}
          </StyledAmount>
          <p className="text-white-35 ml-1 mb-0">
            {item.fee.currency}
          </p>
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center">
          <StyledAmount amount={item.fee.percent}>
            {formatterToNull(item.fee.percent)}
          </StyledAmount>
          <p className="mb-0 text-white-35 ml-1">%</p>
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center">
          <p className="mb-0 text-white">
            {moment(item.date).format("DD.MM.YY")}
          </p>
          <p className="text-white-35 ml-1 mb-0">
            {moment(item.date).format("HH:mm")}
          </p>
        </div>
      </td>
    </tr>
  )
}

const StyledAmount = styled.p`
  color: ${(props) => props.amount > 0 ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.35)"} !important;
  margin-bottom: 0;
  white-space: nowrap;
`;

export default MarketTradeRow;