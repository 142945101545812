import React from "react";
import styled from "styled-components";
import _ from "lodash";
import {
  CardBody,
  Card,
  CardHeader
} from "reactstrap";

import SkeletonPreloader from "components/SkeletonPreloader";
import { ReactComponent as BotListChartPreload } from "assets/img/BotListChartPreload.svg";

const BotsListPreload = () => {
  return(
    <BotsListPreload.BotsListWrapper>
      {
        _.times(9, (e) =>
          <Card key={e} className="overflow-hidden">
            <CardHeader className="p-4" style={{background: "#2E3148"}}>
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center mr-4 position-relative">
                    <SkeletonPreloader
                      circle={true}
                      height={36}
                      width={36}
                    />
                    <SkeletonPreloader
                      circle={true}
                      height={36}
                      width={36}
                      style={{position: "absolute", left: "80%"}}
                    />
                  </div>
                  <SkeletonPreloader
                    height={17}
                    width={143}
                    className="ml-4"
                  />
                </div>
                <SkeletonPreloader
                  circle={true}
                  height={29}
                  width={29}
                />
              </div>
            </CardHeader>
            <CardBody className="p-4">
              <div className="d-flex justify-content-between">
                <SkeletonPreloader
                  height={12}
                  width={83}
                />
                <SkeletonPreloader
                  height={19}
                  width={74}
                />
              </div>
              <div className="mt-3 d-flex justify-content-between">
                <SkeletonPreloader
                  height={12}
                  width={83}
                />
                <SkeletonPreloader
                  height={12}
                  width={74}
                />
              </div>
              <div className="mt-3 d-flex justify-content-between">
                <SkeletonPreloader
                  height={12}
                  width={83}
                />
                <SkeletonPreloader
                  height={12}
                  width={74}
                />
              </div>
              <div className="mt-3 d-flex justify-content-between">
                <SkeletonPreloader
                  height={12}
                  width={83}
                />
                <SkeletonPreloader
                  height={12}
                  width={61}
                />
              </div>
              <div className="mt-3 d-flex justify-content-between">
                <SkeletonPreloader
                  height={12}
                  width={83}
                />
                <SkeletonPreloader
                  height={12}
                  width={74}
                />
              </div>
              <div className="mt-3 d-flex justify-content-between">
                <SkeletonPreloader
                  height={12}
                  width={83}
                />
                <SkeletonPreloader
                  height={12}
                  width={74}
                />
              </div>
              <div className="mt-3 d-flex justify-content-between">
                <SkeletonPreloader
                  height={12}
                  width={83}
                />
                <SkeletonPreloader
                  height={12}
                  width={122}
                />
              </div>
              <div className="mt-3 d-flex justify-content-between">
                <SkeletonPreloader
                  height={12}
                  width={83}
                />
                <SkeletonPreloader
                  height={12}
                  width={122}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between mt-4">
                <div className="d-flex align-items-center">
                  <BotListChartPreload/>
                  <div className="d-flex flex-column ml-2">
                    <SkeletonPreloader
                      height={16}
                      width={48}
                    />
                    <SkeletonPreloader
                      height={11}
                      width={88}
                      className="mt-2"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <BotListChartPreload/>
                  <div className="d-flex flex-column ml-2">
                    <SkeletonPreloader
                      height={16}
                      width={48}
                    />
                    <SkeletonPreloader
                      height={11}
                      width={62}
                      className="mt-2"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-4">
                <SkeletonPreloader
                  height={14}
                  width={100}
                />
                <SkeletonPreloader
                  height={14}
                  width={100}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between mt-3">
                <SkeletonPreloader
                  height={14}
                  className="mr-1"
                />
                <SkeletonPreloader
                  height={14}
                  className="ml-1"
                />
              </div>
              <div className="d-flex align-items-center justify-content-between mt-3">
                <SkeletonPreloader
                  height={12}
                  width={67}
                />
                <SkeletonPreloader
                  height={12}
                  width={67}
                />
              </div>
            </CardBody>
          </Card>
        )
      }
    </BotsListPreload.BotsListWrapper>
  );
};

BotsListPreload.BotsListWrapper = styled.div`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;

  @media (min-width: 768px) and (max-width: 1199.98px) {
    display: grid;
    grid-template-columns: 48% 48%;
    justify-content: space-between;
  }

  @media (max-width: 767.98px) {
    display: flex;
    flex-direction: column;
  }
`;

export default BotsListPreload;
