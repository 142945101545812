import React, {useState} from "react";
import { Card, Col, Row } from "reactstrap";
import { useSelector} from "react-redux";
import styled from "styled-components";
import {
  TelegramShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share";

import Filters from "./Filters";
import ReferralStatisticsChart from "./ReferralStatisticsChart";
import ReferralDoughnutChart from "./ReferralChartDoughnut";
import Hint from "components/Hint";
import ReferralSystemTable from "./ReferralSystemTable";
import {ReactComponent as FacebookIcon} from "assets/img/icons/facebook.svg";
import {ReactComponent as TwitterIcon} from "assets/img/icons/XIcon.svg";
import {ReactComponent as TelegramIcon} from "assets/img/icons/telegram.svg";
import { ReactComponent as Empty } from "assets/img/empty-state-planet2.svg";
import {ReactComponent as ReferralStatistics} from "assets/img/icons/ReferralStatistics.svg";
import {ReactComponent as TotalReferrals} from "assets/img/icons/totalReferrals.svg";
import Copy from "components/svg/Copy";

const ReferralData = ({
  referral={},
  chartData,
  setHoverCurrentBg,
  hoverCurrentBg,
  setHoverredElemId,
  hoverredElemId,
  labels,
  statisticsData,
  maxAmount,
}) => {

  const referralCode = useSelector(state => state.me.data.referralCode);
  const referralLink = `https://app.darkbot.io?ref=${referralCode}`;

  const [isCopied, setIsCopied] = useState("");

  const handleMouseEnter = (index) => setHoverredElemId(index);
  const handleMouseLeave = () => setHoverredElemId(null);

  const handleCopy = (e) => {
    e.stopPropagation();
    e.preventDefault();
    navigator.clipboard.writeText(referralLink).then(() => {
      setIsCopied("Copied!");
      setTimeout(() => {
        setIsCopied("");
      }, 1000);
    });
  };

  return (
    <Row>
      <Col xs="12" md="4">
        <Card>
          <ReferralData.Text className="d-flex align-items-start flex-column m-4">
            <h4 className="font-weight-500 text-white mb-3">
              Referral link
            </h4>
            <p className="font-sm font-weight-400 mb-0 text-white-75">
              You can easily copy your unique referral link and share it with your friends.
            </p>
          </ReferralData.Text>
          <ReferralData.Copy className="d-flex justify-content-between mx-4">
            <p className="font-sm font-weight-400 mb-0 text-white-75">
              Your referral link
            </p>
            <div className="d-flex align-items-center">
              {
                isCopied &&
                <Hint className="mb-1">
                  <p className="text-dark mt-1">{isCopied}</p>
                </Hint>
              }
              <button className="btn btn-reset mt-0 mb-2 pr-2 ml-2" onClick={handleCopy}>
                <Copy/>
              </button>
            </div>
          </ReferralData.Copy>
          <ReferralData.Link className="mx-4 mb-4">
            <p className="text-white">
              {referralLink}
            </p>
          </ReferralData.Link>
          <div className="d-flex flex-column mx-4 mb-4">
            <p className="text-white">Share in social</p>
            <div className="d-flex">
              <ReferralData.ShareWrapper className="mr-2">
                <TelegramShareButton
                  url={`${referralLink}`}
                  title="Join Darkbot.io through my referral link and start trading crypto with ease and security!"
                >
                  <TelegramIcon/>
                </TelegramShareButton>
              </ReferralData.ShareWrapper>
              <ReferralData.ShareWrapper className="mr-2">
                <TwitterShareButton
                  url={`${referralLink}`}
                  title="Join Darkbot.io through my referral link and start trading crypto with ease and security!"
                >
                  <TwitterIcon/>
                </TwitterShareButton>
              </ReferralData.ShareWrapper>
              <ReferralData.ShareWrapper className="mr-2">
                <FacebookShareButton
                  url={`${referralLink}`}
                  hashtag={"#darkbot #referralLink"}
                >
                <FacebookIcon/>
                </FacebookShareButton>
              </ReferralData.ShareWrapper>
            </div>
          </div>
        </Card>
        {
          referral?.items?.length ?
            <ReferralDoughnutChart
              chartData={chartData}
              setHoverCurrentBg={setHoverCurrentBg}
              hoverCurrentBg={hoverCurrentBg}
              hoverredElemId={hoverredElemId}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              total={referral?.total}
            /> :
            <Card className="mt-4">
              <div className="mt-4 mx-4 mb-5 d-flex justify-content-between align-items-center">
                <h4 className="font-weight-500 text-white mb-0">
                  Total referrals
                </h4>
                <h1 className="text-info m-0">{referral?.total}</h1>
              </div>
              <div className="d-flex align-items-center flex-column mx-4 mb-5">
                <div className="mb-4">
                  <TotalReferrals/>
                </div>
                <ReferralData.Text className="d-flex align-items-center flex-column">
                  <h2 className="font-weight-500 text-center text-white mb-1">
                    You have no refferals yet
                  </h2>
                  <p className="text-center text-white-75">
                    Here will be dispayed a pie chart of people who
                    bought one of our plans and those who have
                    registered using your referral link
                  </p>
                </ReferralData.Text>
              </div>
            </Card>
        }
      </Col>
      <Col xs="12" md="8">
        <Card>
          <div className="mt-4 ml-4">
            <h4 className="font-weight-500 text-white">
              Statistics
            </h4>
          </div>
          {
            referral?.items?.length ?
            <ReferralStatisticsChart
              statisticsData={statisticsData}
              labels={labels}
              maxAmount={maxAmount}
            />
            :
            <ReferralData.ReferralStatistics className="d-flex align-items-center flex-column">
              <div className="mb-4">
                <ReferralStatistics/>
              </div>
              <ReferralData.Text>
                <h2 className="text-center font-weight-500 text-white mb-1">
                  You have no referral statistic yet
                </h2>
                <p className="text-center text-white-75">
                  Here will be displayed a graph with people statistics who
                  have registered using your referral link
                </p>
              </ReferralData.Text>
            </ReferralData.ReferralStatistics>
          }
        </Card>
        <Card>
          <div className="d-md-flex justify-content-md-between align-items-center mt-4 mx-4 mb-2">
            <h4 className="font-weight-500 text-white m-0">
              Registered users
            </h4>
            <div className="mt-sm-2 mt-md-0">
              <Filters referrals={referral}/>
            </div>
          </div>
          {
            referral?.items?.length ? <ReferralSystemTable referrals={referral}/>
            :
            <ReferralData.RegisteredUsers className="d-flex align-items-center flex-column">
              <div className="mb-4">
                <Empty/>
              </div>
              <ReferralData.Text>
                <h2 className="text-center font-weight-500 text-white mb-1">
                  You have no refferals yet
                </h2>
                <p className="text-center text-white-75">
                  Here will be dispayed a table for each of your referrals who
                  have registered using your referral link
                </p>
              </ReferralData.Text>
          </ReferralData.RegisteredUsers>
          }
        </Card>
      </Col>
    </Row>
  );
}

ReferralData.Text = styled.div`
  & p {
    line-height: 175%;
  }
`;

ReferralData.ShareWrapper = styled.div`
  border-radius: 8px;
  background: #2E3148;
  padding: 10px;
  cursor: pointer;

  & svg {
    width: 18px;
    height: 18px;
  }

  &:hover {
    background: #363A54;
  }
`;

ReferralData.Copy = styled.div`
  margin-bottom: 0.75rem;
`;

ReferralData.Link = styled.div`
  background: #2E3148;
  border-radius: 8px;
  & p {
    margin: 11px 1rem !important;
  }
`;

ReferralData.ReferralStatistics = styled.div`
  @media (max-width: 992px){
    margin: 75px;
  }
  margin: 75px 188px;
`;

ReferralData.RegisteredUsers = styled.div`
  @media (max-width: 992px){
    margin: 75px;
  }
  margin: 68px 188px;
`;

export default ReferralData;
