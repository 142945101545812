import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import ChannelLogo from "components/ChannelLogo";
import { SignalChannelBlockPropTypes } from "../signalPropTypes";
import { ReactComponent as ProfileAdd } from "assets/img/icons/profile-add.svg";
import { ReactComponent as ProfileTick } from "assets/img/icons/profile-tick.svg";

const SignalChannelBlock = ({
  channelId,
  name,
  isSubscribed,
  totalSubscribers,
  avatar,
  loading
}) => {
  return (
    <div className="d-flex justify-content-center justify-content-md-normal mt-4 mt-md-0">
      <div className="d-flex flex-column mr-3">
        <div className="d-flex">
          {
            isSubscribed
              ? <ProfileTick className="my-auto" />
              : <ProfileAdd className="my-auto" />
          }
          <Link to={`/channels/${channelId}`}>
            <SignalChannelBlock.Name className="font-weight-500 mb-0 text-white w-100">
              {name}
            </SignalChannelBlock.Name>
          </Link>
        </div>
        <p className="font-sm text-white-75 ml-auto mr-0 mb-0">
          {totalSubscribers} Subscribers
        </p>
      </div>
      <ChannelLogo
        logo={avatar}
        size={40}
        loading={loading}
      />
    </div>
  );
};

SignalChannelBlock.Name = styled.p`
  margin-left:0.3755rem;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

SignalChannelBlock.propTypes = SignalChannelBlockPropTypes;

export default SignalChannelBlock;