import React, { useState } from "react";
import {
  Card,
  CardBody,
} from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import IdWithCopy from "components/IdWithCopy";
import LineWithError from "shared/ui/LineWithError";
import { ReactComponent as BacktestIcon } from "assets/img/icons/backtest.svg";
import moment from "moment";
import HeaderPreloader from "../preloaders/HeaderPreloader";
import Button from "components/Button";
import { ReactComponent as Trash } from "assets/img/icons/trash.svg";
import { ReactComponent as Clock } from "assets/img/icons/clock.svg";
import { deleteBacktest } from "store/backtesting/actions";
import { addSnackBar } from "store/snackbar/actions";
import BacktestStatusBadge from "components/BacktestStatusBadge";

const BacktestHeader = ({ id, data={}, loading, botId }) => {
  const [isDeleteProcessing, setIsDeleteProcessing] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDelete = async() => {
    setIsDeleteProcessing(true);
    const res = await dispatch(deleteBacktest(id));
    setIsDeleteProcessing(false);
    if (res.errors) {
      return dispatch(addSnackBar("danger", res.message));
    } else {
      dispatch(addSnackBar("success", res.message));
      return history.push(`/bots/${botId}`)
    }
  };

  if (loading) return <HeaderPreloader />;

  return (
    <Card style={{borderRadius: "8px"}}>
      <CardBody className="p-4">
        <div className="d-flex flex-column flex-md-row justify-content-between">
          <div className="d-flex mb-3 mb-md-0">
            <BacktestIcon
              width={48}
              height={48}
              className="mr-3"
            />
            <div className="d-flex flex-column justify-content-between">
              <h1 className="text-white mb-0 my-auto my-md-0">
                Backtest
              </h1>
              <IdWithCopy
                id={id}
                className="d-none d-md-flex"
              />
            </div>
          </div>
          <div className="d-flex justify-content-between justify-content-md-start">
            <div className="d-flex flex-column justify-content-between">
              <div className="d-flex justify-content-start justify-content-md-end">
                <BacktestStatusBadge status={data.status} />
              </div>
              <TimeWrapper className="text-white opacity-35 font-xs">
                <Clock
                  className="icon-stroke-gray mr-1"
                  aria-hidden="true"
                />
                {
                  data.history?.[0]?.start ?
                  <p  className="d-flex mb-0">
                    {moment(data.history?.[0]?.start).format("DD.MM.YYYY HH:mm")}
                  </p> : null
                }
              </TimeWrapper>
            </div>
            <Button
              outline
              color="gray"
              className="p-3 h-100 ml-3"
              onClick={handleDelete}
              loading={isDeleteProcessing}
            >
              <Trash />
            </Button>
          </div>
        </div>
      </CardBody>
      {
        data.error && <LineWithError errorMessage={data.error}/>
      }
    </Card>
  );
};

const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  color: rgba(255, 255, 255, 0.35);
`;

export default BacktestHeader;
