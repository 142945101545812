import React from "react";

import SkeletonPreloader from "components/SkeletonPreloader";
import {WrapperIPsAddresses } from "../compounds/IPsAddresses/IPsAddresses.styles";

const IPsAddressesPreloader = () => (
  <WrapperIPsAddresses
    className="mb-4" 
    data-testid="IPsAddresses-preloader"
    >
    <div className="d-flex align-items-center mb-3">
      <SkeletonPreloader
        height={16}
        className="mr-3"
      />
      <SkeletonPreloader
        circle={true}
        height={22}
        width={22}
      />
    </div>
    <div className="d-flex align-items-center">
      <div className="mr-3">
        <SkeletonPreloader
          count={2}
          height={12}
          width={99}
          className="d-flex flex-column mt-3"
        />
      </div>
      <div>
        <SkeletonPreloader
        borderRadius={8}
          count={2}
          width={17}
          height={17}
          className="d-flex flex-column mt-3"
        />
      </div>
    </div>
  </WrapperIPsAddresses>
);

export default IPsAddressesPreloader;
