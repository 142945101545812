import React from "react";
import { times } from "lodash";

import TickerWrapper from "./TickerWrapper";
import SkeletonPreloader from "components/SkeletonPreloader";

const MovingTikerLinePreloader = () => {
  return (
    <TickerWrapper
      className="d-flex"
      isPreloader
    >
      <div className="ticker-wrap">
        <div className="ticker w-100 d-flex">
          {
            times(10, (e) =>
              <div
                className="ticker__item px-3 d-flex h-100"
                key={e}
              >
                <SkeletonPreloader
                  width={16}
                  height={16}
                  circle
                  className="mr-2 my-auto d-block"
                />
                <SkeletonPreloader
                  width={160}
                  height={13}
                  className="mr-2 my-auto d-block"
                />
                <SkeletonPreloader
                  width={16}
                  height={10}
                  className="mr-2 my-auto d-block"
                />
                <span
                  className="ml-3 d-block my-auto"
                  style={{ color: "rgba(255, 255, 255, 0.1)"}}
                >
                  |
                </span>
              </div>
            )
          }
        </div>
      </div>
    </TickerWrapper>
  );
};

export default MovingTikerLinePreloader;
