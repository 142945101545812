import React from "react";
import { 
  Card, 
  CardBody
} from "reactstrap";
import PropTypes from "prop-types";

import CustomScrollbars from "components/CustomScrollbars";
import SortTableHead from "components/SortTableHead";
import StyledTable from "components/StyledTable";
import TablePreloader from "components/TablePreloader";
import SignalTableRow from "./SignalTableRow";
import { SIGNAL_DETAIL_HEAD } from "models/signal/tables";

const SignalTableBody = ({
  handleSorting,
  sort,
  loading,
  data
}) => {
  return (
    <CardBody className="p-0">
      <CustomScrollbars
        autoHideTimeout={500}
        autoHideDuration={500}
        autoHeight
        autoHeightMax={Number.MAX_VALUE}
      >
        {
          loading
          ? <Card className="pt-3 px-4 mb-0 overflow-hidden">
              <TablePreloader
                thHeight={16}
                thWidth={[55, 45, 60, 75, 55]}
                tdWidth={[20, 80, 50, 115, 65]}
                tdHeight={16}
                rowTimes={5}
              />
            </Card>
            : <StyledTable className="mb-0" >
              <SortTableHead
                handleSorting={handleSorting}
                sort={sort}
                theadItems={SIGNAL_DETAIL_HEAD}
              />
              <tbody className="font-sm">
                {
                  data?.targets?.map((item, index) => {
                    return (
                      <SignalTableRow
                        key={item.id}
                        target={`T${index+1}`}
                        buyFrom={item?.rate}
                        completedAt={item?.reachedAt}
                        status={item?.status}
                        percent={item?.percent}
                      />
                    )
                  }
                  )
                }
              </tbody>
            </StyledTable>
        }
      </CustomScrollbars>
    </CardBody>
  );
};

SignalTableBody.propTypes = {
  handleSorting: PropTypes.func,
};

export default SignalTableBody;