import React from "react";
import styled from "styled-components";
import _ from "lodash";
import {
  Card
} from "reactstrap";

import SkeletonPreloader from "components/SkeletonPreloader";

const StrategyTags = ({ tags = [], loading }) => {
  return (
    <StrategyTags.Card>
      <h4 className="mb-1">Tags</h4>
      <div className="d-flex flex-wrap">
        {
          loading ?
          _.times(7, (e) =>
            <StrategyTags.ButtonPreLoader
              key={e}
              className="d-flex p-2 mr-2 mb-2 justify-content-center"
              style={{ width: "61px" }}
            >
              <SkeletonPreloader
                width={41}
                height={12}
              />
            </StrategyTags.ButtonPreLoader>
          )
          :
          tags.map((tag) => <StrategyTags.Badge key={tag}>{tag}</StrategyTags.Badge>)
        }
      </div>
      {
        loading &&
        <StrategyTags.ButtonPreLoader
          className="d-flex justify-content-center mt-2 p-2"
          style={{ width: "111px" }}
        >
          <SkeletonPreloader
            width={79}
            height={12}
          />
        </StrategyTags.ButtonPreLoader>
      }
    </StrategyTags.Card>
  )
};

StrategyTags.Card = styled(Card)`
  padding: 24px;
`;

StrategyTags.Badge = styled.div`
  height: 32px;
  padding: 8px 10px;
  background: #2E3148;
  border-radius: 4px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-right: 16px;
  margin-top: 12px;
  color: #FFFFFF;
`;

StrategyTags.ButtonPreLoader = styled.div`
  background: rgba(83, 84, 98, 0.2);
  border-radius: 4px;
`;

export default StrategyTags;
