import React from "react";
import styled from "styled-components";
import CustomScrollbars from "components/CustomScrollbars";
import StyledTable from "components/StyledTable";
import SortTableHead from "components/SortTableHead";
import {BACKTEST_TABLE_HEAD} from "models/bot/tables";
import BacktestTableRow from "./BacktestTableRow";
import BacktestsEmptyState from "./BacktestsEmptyState";

const BacktestsTable = ({items, request, handleSorting}) => {

  if (!items?.length) return <BacktestsEmptyState/>

  return (
    <CustomScrollbars
      autoHideTimeout={500}
      autoHideDuration={500}
      autoHeight
      autoHeightMax={Number.MAX_VALUE}
    >
      <StyledTable className="mb-0" paddingsThead="1rem">
        <SortTableHead
          handleSorting={handleSorting}
          sort={request?.sort}
          theadItems={BACKTEST_TABLE_HEAD}
        />
        <BacktestTableRow.TableBody className="font-sm">
        {
          items?.map((item) => <BacktestTableRow item={item} key={item.id}/>)
        }
        </BacktestTableRow.TableBody>
      </StyledTable>
    </CustomScrollbars>
  )
}

BacktestTableRow.TableBody = styled.tbody`
  &:last-child {
    border-bottom: 1px solid #2E3148;
  }
`;

export default BacktestsTable;
