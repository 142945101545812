import React, { useEffect } from "react";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  Button
} from "reactstrap";
import qs from "qs";

import Filters from "./compounds/Filters";
import PaginationPanel from "components/PaginationPanel";
import {
  fetchStrategies,
  resetStrategies
} from "store/strategies/actions";
import Error from "components/Error";
import StrategyDetails from "components/StrategyDetails";
import ListLoading from "./compounds/ListLoading";

const StrategiesList = ({
  fetchStrategies,
  data: {
    items=[],
    total,
    page
  },
  resetStrategies,
  location,
  loading,
  error
}) => {
  const history = useHistory();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    fetchStrategies(location.search, 6);
    return () => resetStrategies();
  }, [location.search, resetStrategies, fetchStrategies]);

  const handlePaginate = (page) => {
    history.push({
      pathname: "/strategies",
      search: qs.stringify({ ...search, page })
    });
  };

  return(
    <>
      <div className="d-flex justify-content-between">
        <h1 className="mb-0">
          Strategies
        </h1>
        <Button
          color="info"
          tag={Link}
          to="/strategies/create"
          className="pull-right"
        >
          Create
        </Button>
      </div>
      <Card className="mt-4">
        <CardBody>
          <Filters
            location={location}
            loading={loading}
          />
        </CardBody>
      </Card>
      { loading && <ListLoading /> }
      {
        error ?
        <Error error={error} />
        :
        <StrategiesList.StrategiesWrapper>
          {
            items.map((strategy) =>
              <div
                key={strategy.uuid}
                style={{ cursor: "pointer" }}
                onClick={() => history.push(`strategies/${strategy.uuid}`)}
              >
                <StrategyDetails
                  strategy={strategy}
                  image={true}
                  showName={true}
                  link={true}
                />
              </div>
            )
          }
        </StrategiesList.StrategiesWrapper>
      }
      <PaginationPanel
        activePage={page}
        totalItemsCount={total}
        onChange={(page) => handlePaginate(page)}
      />
    </>
  );
};

StrategiesList.StrategiesWrapper = styled.div`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;

  @media (min-width: 768px) and (max-width: 1199.98px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 767.98px) {
    display: flex;
    flex-direction: column;
  }
`;

const mapStateToProps = (state) => ({
  data: state.strategies.list.data,
  loading: state.strategies.list.loading,
  error: state.strategies.list.error,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchStrategies,
    resetStrategies
  }, dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(StrategiesList);
