import React from "react";
import styled from "styled-components";

import Icon from "components/Icon";

const DoubleIconsMarket = ({ base, quote }) => {
  return (
    <Wrapper className="d-flex">
      <Icon
        type="cryptocurrency"
        code={base}
        className="mt-1 my-auto"
      />
      <Icon
        type="cryptocurrency"
        className="my-auto mr-2 position-relative"
        code={quote}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & img:first-child {
    z-index: 1;
  }

  & img, & svg {
    width: 35px !important;
  }

  & img:last-child {
    right: 10%;
    top: 0;

    @media (max-width: 576px) {
      right: 5%;
    }
  }
`;

export default DoubleIconsMarket;
