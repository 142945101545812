import {
  FlexColumn,
  Footer,
  Header,
  Main
} from "../CenterColumn";
import SkeletonPreloader from "components/SkeletonPreloader";

export const CenterColumnPreloader = ({items}) =>
  <FlexColumn items={items}>
  <Header>
    <SkeletonPreloader
      height={16}
      width={80}
    />
  </Header>
  <Main>
    <SkeletonPreloader
      height={16}
      width={60}
    />
  </Main>
  <Footer>
    <SkeletonPreloader
      height={16}
      width={40}
    />
  </Footer>
</FlexColumn>;
