import React from "react";
import _ from "lodash";
import SkeletonPreloader from "components/SkeletonPreloader";
import { Card, CardBody, CardHeader } from "reactstrap";

const PortfolioPreloaders = () => {
  return(
    <div className="d-flex flex-column w-100">
      {
        _.times(7, (e) =>
          <Card key={e}>
            <CardHeader style={{background: "var(--dark-slate)", padding: "24px"}} className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <SkeletonPreloader
                  circle={true}
                  height={24}
                  width={24}
                  className="mr-3"
                />
                <SkeletonPreloader
                  height={16}
                  width={137}
                />
              </div>
              <div className="d-flex align-items-center">
                <SkeletonPreloader
                  height={16}
                  width={50}
                  className="mr-3"
                />
                <SkeletonPreloader
                  height={9}
                  width={368}
                  className="d-none d-md-block"
                />
              </div>
            </CardHeader>
            <CardBody
              style={{background: "rgba(148, 158, 255, 0.06)", padding: "24px"}}
              className="d-flex flex-column flex-md-row justify-content-between"
            >
              {
                _.times(3, (e) =>
                  <div key={e} className="d-flex flex-column mb-3 mb-md-0">
                    <SkeletonPreloader
                      height={9}
                      width={56}
                    />
                    <SkeletonPreloader
                      height={18}
                      width={137}
                      className="mt-2"
                    />
                  </div>
                )
              }
              <div className="d-flex flex-column mb-3 mb-md-0">
                <SkeletonPreloader
                  height={9}
                  width={47}
                />
                <SkeletonPreloader
                  height={18}
                  width={88}
                  className="mt-2"
                />
              </div>
              <div className="d-flex flex-column">
                <SkeletonPreloader
                  height={9}
                  width={30}
                />
                <SkeletonPreloader
                  height={18}
                  width={30}
                  className="mt-2"
                />
              </div>
            </CardBody>
          </Card>
        )
      }
    </div>
  );
};

export default PortfolioPreloaders;
