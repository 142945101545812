import React from "react";
import SkeletonPreloader from "components/SkeletonPreloader";
import styled from "styled-components";
import {ReactComponent as Rectangle} from "assets/img/icons/Vector.svg";
import {ReactComponent as Balance} from "assets/img/icons/balanceLoad.svg";

const NavigationPreloader = () => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100 px-4 pt-4 pt-xl-3">
      <div className="d-flex align-items-center">
        <SkeletonPreloader width={20} height={20} borderRadius="6px"/>
        <NavigationPreloader.Preloader className="d-none d-xl-flex ml-4 align-items-center">
          <SkeletonPreloader
            width={20}
            height={20}
            circle
            className="mr-2"
          />
          <SkeletonPreloader
            width={119}
            height={13}
          />
        </NavigationPreloader.Preloader>

        <div className="d-none d-sm-flex d-lg-none align-items-center ml-4">
          <Balance/>
          <div className="d-flex flex-column ml-3">
            <SkeletonPreloader
              width={43}
              height={8}
            />
            <SkeletonPreloader
              width={102}
              height={12}
              className="mt-2"
            />
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center">
        <div className="d-none d-md-flex align-items-center mr-5 mr-lg-0">
          <Balance/>
          <div className="d-flex flex-column ml-3">
            <SkeletonPreloader
              width={43}
              height={8}
            />
            <SkeletonPreloader
              width={102}
              height={12}
              className="mt-2"
            />
          </div>
        </div>
        <div className="d-none d-lg-flex align-items-center mx-4 px-4">
          <SkeletonPreloader
            width={20}
            height={20}
            circle
          />
          <div className="d-flex flex-column ml-3">
            <SkeletonPreloader
              width={102}
              height={12}
            />
            <SkeletonPreloader
              width={43}
              height={12}
              className="mt-2"
            />
          </div>
        </div>

        <div className="d-flex align-items-center">
          <SkeletonPreloader
            width={20}
            height={20}
            circle
          />
          <SkeletonPreloader
            width={20}
            height={20}
            circle
            className="mx-4"
          />
          <div className="d-flex align-items-center">
            <SkeletonPreloader
              width={32}
              height={32}
              circle
              className="mr-1"
            />
            <Rectangle/>
          </div>
        </div>
      </div>
    </div>
  );
};

NavigationPreloader.Preloader = styled.div`
  border-radius: 6px;
  padding: 6px 18px;
  background: #242534;
  height: 40px;
  width: 236px;
`;

export default NavigationPreloader;
