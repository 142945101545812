import React from "react";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import { Card, CardHeader, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import "chartjs-adapter-moment";
import { useSelector } from "react-redux";

import chartConfig from ".";
import { ReactComponent as Trade } from "assets/img/icons/trade.svg";

const TradeHistoryChartStatistics = ({tradesChart}) => {

  const id = useSelector((state) => state.credentials.item.data.id);
  const {chartData} = chartConfig({data: tradesChart});

  return (
    <TradeHistoryChartStatistics.ChartCard className="card-chart">
      <TradeHistoryChartStatistics.CardHeader className="p-4">
        <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
          <div className="d-flex align-items-center mb-2 mb-md-0">
            <Trade/>
            <h4 className="ml-2 mb-0 text-white font-weight-500">Trade History</h4>
          </div>
          <Link to={`/credentials/${id}/trade-history`} className="link-info">
             Show all
          </Link>
        </div>
      </TradeHistoryChartStatistics.CardHeader>
      <CardBody className="pb-4">
        <div className="chart-area" style={{height: "260px"}}>
          <Line data={chartData.config} options={chartData.options}/>
        </div>
      </CardBody>
    </TradeHistoryChartStatistics.ChartCard>
  )
};

TradeHistoryChartStatistics.ChartCard = styled(Card)`
  margin-bottom: 32px !important;
  border-radius: 8px !important;
  min-height: 325px;
`;

TradeHistoryChartStatistics.CardHeader = styled(CardHeader)`
  svg {
    width:17px;
    height:13px;
    & path {
      stroke: var(--info);
    }
  }
`;


export default TradeHistoryChartStatistics;
