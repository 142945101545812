import React, { useEffect, useState } from "react";
import { Form } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Col,
  Row,
  FormGroup,
  Button
} from "reactstrap";

import SelectInput from "components/SelectInput";
import {
  getMarkets,
  getPeriods
} from "store/exchanges/actions";
import ExchangesSelect from "components/ExchangesSelect";
import { beforeToday } from "helpers/DatepickerValidation";
import DateField from "components/DateField";
/* eslint-disable complexity */

const style = {
  color: "#ec250d",
};

const SignalsCheck = ({
  getMarkets,
  getPeriods,
  values,
  setFieldValue,
  markets=[],
  periods=[],
  errors
}) => {
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (values.exchange) {
      getMarkets(values.exchange);
      getPeriods(values.exchange);
    }
  }, [getMarkets, getPeriods, values.exchange]);

  return(
    <Form>
      <Row>
        <Col xs="4">
          <FormGroup>
            <ExchangesSelect
              placeholder="Exchange"
              name="exchange"
              onChange={({ value }) => setFieldValue("exchange", value)}
            />
            <Col md="12">
              {validated && errors.exchange ?
                <label style={style}>{errors.exchange}</label> : null}
            </Col>
          </FormGroup>
        </Col>
        <Col xs="4">
          <FormGroup>
            <SelectInput
              placeholder="Market"
              onChange={({ value }) => setFieldValue("pair", value)}
              options={markets.map((market) => ({ label: market, value: market }))}
            />
            <Col md="12">
              {validated && errors.pair ?
                <label style={style}>{errors.pair}</label> : null}
            </Col>
          </FormGroup>
        </Col>
        <Col xs="4">
          <FormGroup>
            <SelectInput
              placeholder="Period"
              onChange={({ value }) => setFieldValue("period", value)}
              options={periods.map((period) => ({ label: period, value: period }))}
            />
            <Col md="12">
              {validated && errors.period ?
                <label style={style}>{errors.period}</label> : null}
            </Col>
          </FormGroup>
        </Col>
        <Col xs="6">
          <DateField
            onChange={(value) => setFieldValue("from", value)}
            dateFormat="YYYY-MM-DD"
            isValidDate={beforeToday}
            inputProps={{
              className: "form-control",
              name: "from",
              placeholder: "Period from",
              value: values.from,
              readOnly: true,
            }}
          />
          <Col md="12">
            {validated && errors.from ?
              <label style={style}>{errors.from}</label> : null}
          </Col>
        </Col>
        <Col xs="6">
          <DateField
            onChange={(value) => setFieldValue("to", value)}
            dateFormat="YYYY-MM-DD"
            isValidDate={(current) => current.isAfter(values.from) && beforeToday(current)}
            inputProps={{
              className: "form-control",
              name: "to",
              placeholder: "Period to",
              value: values.to,
              readOnly: true,
            }}
          />
          <Col md="12">
            {validated && errors.to ?
              <label style={style}>{errors.to}</label> : null}
          </Col>
        </Col>
      </Row>
      <Button
        color="info"
        type="submit"
        size="sm"
        className="mt-0"
        onClick={() => setValidated(true)}
      >
        Check
      </Button>
    </Form>
  );
};


const mapStateToProps = (state) => ({
  markets: state.exchanges.markets,
  periods: state.exchanges.periods
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getMarkets,
  getPeriods
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SignalsCheck);
