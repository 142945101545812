import React from "react";
import classNames from "classnames";
import OrdersProgressBar from "components/OrdersProgressBar";
import {formatterToNull} from "helpers/valuesFormatters";
import {capitalize} from "lodash";
import moment from "moment/moment";
import styled from "styled-components";

const MarketOrderRow = ({item}) => {
  return (
    <tr>
      <td>
        <p className={classNames("text-uppercase mb-0 font-weight-500", {
          "text-success": item.type === "BUY" || item.type === "buy",
          "text-danger": item.type === "SELL" || item.type === "sell"
        })}>
          {item.type}
        </p>
      </td>

      <td>
        <MarketOrderRow.Currency>
          {item.orderNumber}
        </MarketOrderRow.Currency>
      </td>

      <td>
        <div className="d-flex align-content-end text-nowrap">
          <p className="text-white mb-0 font-weight-500">
            {item.market.base}
          </p>
          <MarketOrderRow.Currency className="mr-1">/</MarketOrderRow.Currency>
          <p className="text-white mb-0 font-weight-500">
            {item.market.quote}
          </p>
        </div>
      </td>

      <td>
        <MarketOrderRow.Amount amount={item.rate}>
          {item.rate}
        </MarketOrderRow.Amount>
      </td>

      <td>
        <div className="d-flex align-items-center">
          <OrdersProgressBar filledPercent={item.filledPercent}/>
          <p className="mb-0 font-sm font-weight-500 text-white-35">
            {`${item.filledPercent || 0}%`}
          </p>
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center">
          <MarketOrderRow.Amount amount={item.total.amount}>
            {item.total.amount}
          </MarketOrderRow.Amount>
          <MarketOrderRow.Currency>
            {item.total.currency}
          </MarketOrderRow.Currency>
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center">
          <MarketOrderRow.Amount amount={Number(item.executedAmount.amount)}>
            {item.executedAmount.amount || "0"}
          </MarketOrderRow.Amount>
          <MarketOrderRow.Currency>
            {item.executedAmount.currency}
          </MarketOrderRow.Currency>
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center">
          <MarketOrderRow.Amount amount={Number(item.executedQuantity.amount)}>
            {item.executedQuantity.amount || "0"}
          </MarketOrderRow.Amount>
          <MarketOrderRow.Currency>
            {item.executedQuantity.currency}
          </MarketOrderRow.Currency>
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center">
          <MarketOrderRow.Amount amount={Number(item.fee.amount)}>
            {item.fee.amount || "0"}
          </MarketOrderRow.Amount>
          <MarketOrderRow.Currency>
            {item.fee.currency}
          </MarketOrderRow.Currency>
        </div>
      </td>

      <td>
        <MarketOrderRow.Amount
          amount={item.tradesCount}>
          {formatterToNull(item.tradesCount)}
        </MarketOrderRow.Amount>
      </td>

      <td>
        <div className="d-flex align-items-center">
          <MarketOrderRow.Amount amount={1}>
            {item.exchangeStatus}
          </MarketOrderRow.Amount>
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center">
          <p className={classNames("mb-0 font-weight-500", {
            "text-success": item.status === "completed",
            "text-white-35": item.status === "cancelled",
            "text-info": item.status === "open"

          })}>
            {capitalize(item.status)}
          </p>
        </div>
      </td>

      <td>
        <p className="mb-0 text-white font-weight-300 text-nowrap">
          {moment(item.createdAt).format("DD.MM.YY HH:mm")}
        </p>
      </td>
    </tr>
  )
}

MarketOrderRow.Amount = styled.p`
  margin-bottom: 0;
  color: ${(props) => props.amount > 0 ? "#FFFFFF" : "rgba(255, 255, 255, 0.35)"} !important;
  font-weight: 500;
`;

MarketOrderRow.Currency = styled.p`
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.35) !important;
  font-weight: 300;
  margin-left: 4px;
`;

export default MarketOrderRow;