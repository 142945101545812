import React from "react";

import StyledTable from "components/StyledTable";
import { formatterToNull } from "helpers/valuesFormatters";
import CustomScrollbars from "components/CustomScrollbars";
import SortTableHead from "components/SortTableHead";
import { TABLE_HEAD } from "../models/deals";
import PercentBadge from "components/PercentBadge";
import { ReactComponent as ArrowUp } from "assets/img/icons/arrow-up-right.svg";
import { ReactComponent as ArrowDown } from "assets/img/icons/arrow-down-right.svg";
import { ReactComponent as Arrows } from "assets/img/icons/arrowswapvertical.svg";
import { ReactComponent as MinusIcon } from "assets/img/icons/minusIcon.svg";


const DealsTable = ({ 
  items = [], 
  handleSorting, 
  sort
}) => {
  return (
    <CustomScrollbars
      autoHideTimeout={500}
      autoHideDuration={500}
      autoHeight
      autoHeightMax={Number.MAX_VALUE}
    >
      <StyledTable className="mb-0">
        <SortTableHead
          handleSorting={handleSorting}
          sort={sort}
          theadItems={[
            {
              name: "result",
              label: "",
              icon: <Arrows />,
              isSortable: false
            },
            ...TABLE_HEAD
          ]}
        />
        <tbody className="font-sm">
          {
            items.map((item) =>
              <tr
                key={item.id}
                className="cursor-pointer"
              >
                <td>
                  {item.status === "cancelled"
                    ?
                    <MinusIcon/>
                    :
                    item.result === "profit" ?
                      <ArrowUp />
                      :
                      <ArrowDown />
                  }
                </td>
                <td>
                <div className="d-flex">
                  <p className="m-0 text-nowrap">
                    <span className="text-white mr-1">
                      {formatterToNull(item.invested?.amount)}
                    </span>
                    <span className="text-white-35 mr-2">
                      {item.invested?.asset}
                    </span>
                  </p>
                  <PercentBadge
                    value={item.invested?.percent}
                    isColorSpectrum
                    showZeroValue
                  />
                </div>
              </td>
              <td>
                <span className="text-white mr-1">
                  {formatterToNull(item.purchased?.amount)}
                </span>
                <span className="text-white-35">
                  {item.purchased?.asset}
                </span>
              </td>
              <td>
                <p className="m-0 text-nowrap">
                  <span className="text-white mr-1">
                    {formatterToNull(item.returns?.amount)}
                  </span>
                  <span className="text-white-35 mr-2">
                    {item.returns?.asset}
                  </span>
                  <PercentBadge value={item.returns?.percent} />
                </p>
              </td>
              <td>
                <span className="text-white mr-1 text-nowrap font-weight-500">
                  {item.duration}
                </span>
              </td>
            </tr>
            )
          }
        </tbody>
      </StyledTable>
    </CustomScrollbars>
  );
};

export default DealsTable;
