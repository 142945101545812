import React, { useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Remove } from "assets/img/icons/remove.svg";
import { useDispatch } from "react-redux";
import { removeSnackBar } from "../store/snackbar/actions";

const SnackBarMessage = ({type, message, id}) => {

  const dispatch = useDispatch();

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(removeSnackBar(id));
    }, 5000);
    return () => clearTimeout(timeout);
  }, [dispatch, id]);

  return (
      <SnackBarMessage.Messages type={type}>
        <p className="mb-0 mr-5 font-sm">
          {message}
        </p>
        <p className="close" onClick={() => dispatch(removeSnackBar(id))}>
          <Remove />
        </p>
      </SnackBarMessage.Messages>
  )
}

SnackBarMessage.Messages = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.type === "success" ? "rgba(0, 191, 154, 1)" : "rgba(255, 159, 137, 1)"};
  padding: 17px 20px;
  margin-bottom: 0;
  border-radius: 6px;


  p {
    color: #1D1E2A !important;
    white-space: normal;
    word-wrap: anywhere;
  }

  span {
    font-weight: 700;
    color: rgba(0, 0, 0, 1) !important;
  }

  .close {
    cursor: pointer;
    margin-bottom: 0;
    svg {
      path {
        stroke: black;
      }
    }
  }

`;

export default React.memo(SnackBarMessage);
