import React from "react";
import { FormGroup } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { DelayInput } from "react-delay-input";
import classNames from "classnames";

import Button from "components/Button";
import Loading from "components/Loading";
import { ReactComponent as Warning } from "assets/img/icons/Warning.svg";

import { ConfirmationDeleteDescribe, ConfirmationDeleteError } from "./index.styles";

const ConfirmationDeleteModal = ({
  modalIsOpen,
  load,
  content,
  handleDelete,
  handleClose,
  handleChange,
  errorMessage,
  isSubmitted,
  correctError
}) => {
  if (modalIsOpen) {
    return (
      <ReactBSAlert
        data-testid="danger-zone-confirmation-delete-wrap"
        type="custom"
        style={{ background: "var(--dark-slate)", borderRadius: "8px", width: "384px" }}
        onConfirm={handleDelete}
        onCancel={handleClose}
        showCancel={false}
        showConfirm={false}
      >
        <div className="d-flex flex-column">
          <h3
            data-testid="danger-zone-confirmation-delete-title"
            className="mb-0 text-white font-weight-500 mb-2"
          >
            Delete {content.actions.delete.name}
          </h3>
          <ConfirmationDeleteDescribe
            data-testid="danger-zone-confirmation-delete-subtitle"
            className="mb-0 text-left text-white-75 font-sm mt-2"
          >
            {content.actions.delete.title}
          </ConfirmationDeleteDescribe>
          <FormGroup className="form-input m-0">
            <DelayInput
              data-testid="danger-zone-confirmation-delete-delay-input"
              autoComplete="off"
              delayTimeout={700}
              type="text"
              name="DELETE"
              className={classNames("text-white form-control mt-4", {
                "has-error": isSubmitted && correctError
              })}
              placeholder="DELETE"
              onChange={handleChange}
            />
          </FormGroup>
          {
            isSubmitted && correctError ?
              <label
                className="d-flex mt-1 text-danger font-weight-300"
                data-testid="danger-zone-confirmation-correct-error"
              >
                {correctError}
              </label> : null
          }
          {
            errorMessage &&
            <ConfirmationDeleteError data-testid="danger-zone-confirmation-delete-error-wrap">
              <Warning width="50px" data-testid="danger-zone-confirmation-delete-error-icon" />
              <p
                className="ml-4 mb-0 text-white"
                data-testid="danger-zone-confirmation-delete-error-msg"
              >
                {errorMessage}
              </p>
            </ConfirmationDeleteError>
          }
          <div className="d-flex justify-content-between">
            <Button
              data-testid="danger-zone-confirmation-delete-btn-cancel"
              color="gray"
              disabled={load}
              onClick={handleClose}
              className="mt-4"
            >
              Cancel
            </Button>
            {
              load ?
                <div
                  className="d-flex justify-content-center mt-4"
                  style={{ background: "rgba(237, 95, 95, 0.7)", borderRadius: "6px", padding: "7px 50px" }}
                >
                  <Loading size={21} color="#FFFFFF" />
                </div>
                :
                <Button
                  data-testid="danger-zone-confirmation-delete-btn-delete"
                  color="red"
                  disabled={load}
                  onClick={handleDelete}
                  className="mt-4"
                >
                  Delete
                </Button>
            }
          </div>
        </div>
      </ReactBSAlert>
    )
  }
};

export default ConfirmationDeleteModal;