import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { ReactComponent as Rocket } from "assets/img/icons/rocket_signal.svg";
import { ReactComponent as Flag } from "assets/img/icons/flag_signal.svg";
import RateDisplay from "./RateDisplay";

const SignalProgressStep = ({
  color,
  isActive,
  isPoint,
  point,
  index,
  rates,
  isLast
}) => {
  return (
    <>
      <SignalProgressStep.Step
        background={color}
        isActive={isActive}
        className="mb-3"
      >
        {
          rates && (
            <>
              {index === 0 && <RateDisplay rate={rates?.from} />}
              {index === 1 && (
                <>
                  <RateDisplay rate={rates?.from} />
                  <RateDisplay rate={rates?.to} className="right-0" />
                </>
              )}
              {isLast && <RateDisplay rate={rates?.to} className="right-0" />}
            </>
          )
        }
        {isPoint && (
          <SignalProgressStep.IconWrapper
            progress={point?.position?.percent}
            fill={point?.color}
            index={index}
          >
            <span className="position-absolute font-sm font-weight-500">
              {point?.label}
            </span>
            {
              point?.icon === "rocket"
                ? <Rocket />
                : <Flag />
            }
          </SignalProgressStep.IconWrapper>
        )}
      </SignalProgressStep.Step>
    </>
  );
};

SignalProgressStep.IconWrapper = styled.div`
  position: absolute;
  top: -30px;
  left: ${(props) => props.progress}%;
  transform: translateX(-50%);
  white-space: nowrap;

  rect {
    fill: var(--${(props) => props.fill === "grey" ? "grey, var(--dusk-steel)" : props.fill});
  }

  span {
    right: ${(props) => (props.index === 0 ? '0' : '30px')};
    left: ${(props) => (props.index === 0 ? '30px' : 'auto')};
    color: var(--${(props) => props.fill});
    opacity: ${(props) => (props.fill === "grey" ? 0 : 1)};
  }
`;

SignalProgressStep.Step = styled.span`
  position: relative;
  flex: 1;
  height: 6px;
  border-radius: 2px;
  background: var(--${(props) => props.background});
  opacity: ${(props) => (props.isActive ? 1 : 0.3)};
`;

SignalProgressStep.propTypes = {
  color: PropTypes.string,
  isActive: PropTypes.bool,
  isPoint: PropTypes.bool,
  index: PropTypes.number
};

export default SignalProgressStep;
