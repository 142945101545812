import React from "react";
import styled from "styled-components";
import {
  Nav,
} from "reactstrap";

import { SIDEBAR_NAV_ITEMS } from "./navigation";
import NavigationItem from "./NavigationItem";

const SidebarNavigation = ({ 
  expanded, 
  handleOnLinkClick, 
  toggleSidebar,
  isMobile
}) => {
  const detectedCategoryVisual = (title, expanded) => {
    return (
      title !== 'main' && (
        <SidebarNavigation.CategoryText expanded={expanded}
          className="text-white">
          {
            expanded
              ? title
              : <div className="logo__line" />
          }
        </SidebarNavigation.CategoryText>
      )
    )
  }

  return (
    <SidebarNavigation.Wrapper expanded={expanded}>
      <Nav vertical>
        {Object.entries(SIDEBAR_NAV_ITEMS).flatMap(([category, items]) => (
          <React.Fragment key={category}>
            {detectedCategoryVisual(category, expanded)}
            {
              items.map((navItem) => (
                <NavigationItem
                  key={navItem.name}
                  navItem={navItem}
                  handleOnLinkClick={handleOnLinkClick}
                  expanded={expanded}
                  toggleSidebar={toggleSidebar}
                  isMobile={isMobile}
                />
              )
            )}
          </React.Fragment>
        ))}
      </Nav>
    </SidebarNavigation.Wrapper>
  );
};

SidebarNavigation.CategoryText = styled.span`
  font-size: 10px;
  font-weight: 700;
  margin-top:1.5rem;
  text-transform: uppercase;
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  ${(props) => (props.expanded ? 'align-items:left; margin-left:32px':'align-items:center')}

  .logo__line {
    background: rgba(255, 255, 255, 0.35);
    height: 1px !important;
    width: 1.5rem;
    margin-bottom:0.75rem
  }
`;

SidebarNavigation.Wrapper = styled.div`
  ul {
    margin-top: 24px !important;

    li {
      display: flex;
      align-items: center;
      margin: 0.5rem 1rem !important;
      position: relative;

      &:hover {
          background: rgba(255, 255, 255, 0.1);
          opacity: 1;
          border-radius: 6px;
          cursor: pointer;

          .nav-link {
            opacity: 1;
          }
      }

      a {
        margin-bottom: 0 !important;
        position: relative;
      }
    }
  }

  .active {
    &:before {
      content: "";
      background: #FFFFFF;
      width: 4px;
      position: absolute;
      height: calc(100% - 1rem);
      left: -1rem;
      top: 0.5rem;
      border-radius: 0 10px 10px 0;
      z-index: 5;
    }
  }
`;


export default SidebarNavigation;
