import React, { 
  useEffect, 
  useLayoutEffect,
  useState
} from "react";
import { 
  useDispatch,
  useSelector
} from "react-redux";
import {
  Col,
  Row
} from "reactstrap";
import { useHistory } from "react-router-dom";
import qs from "qs";

import BotDetailsHeader from "./compounds/BotDetailsHeader";
import Error from "components/Error";
import DangerZone from "components/DangerZone";
import Investment from "components/Investment";
import Credential from "components/Credential";
import RefreshButton from "components/RefreshButton";
import BotSettingsWidget from "components/BotSettingsWidget";
import TradeSettings from "components/TradeSettingsWidget";
import Breadcrumbs from "components/Breadcrumbs";
import PagesView from "./pagesView/PagesView";
import { selectBot } from "store/bots/selectors";
import {
  getBot,
  resetBot,
  getGeneticStrategiesTable,
  fetchTechnicalAnalysis,
  getBacktests
} from "store/bots/actions";
import {setBotPageBreadcrumbs} from "models/enum/breadcrumbs";
import { BOTS_CONTENT } from "models/constans/bots/content";

const BotPage = ({
  match,
  location
}) => {
  const [pageView, setPagView] = useState("deals");

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    loading,
    error,
    data
  } = useSelector(selectBot);

  const tradeSettingsData = data?.type === "template" ? data?.strategy?.tradeSettings : data?.tradeSettings;

  const handleToEditBot = () => history.push(`/bots/${data.id}/configure`);

  useEffect(() => {
    window.analytics.page("/bots/:id");
  }, [])

  useLayoutEffect(() => {
    dispatch(getBot(match.params.id));
    return () => dispatch(resetBot());
  }, [dispatch, match.params.id], true);

  const handleRefresh = () => {
    dispatch(getBot(match.params.id));
    if (pageView === "machineLearning") dispatch(getGeneticStrategiesTable(match.params.id));
    if (pageView === "technicalAnalysis") dispatch(fetchTechnicalAnalysis(match.params.id, "?limit=20"));
    if (pageView === "backtest") {
      const queryString = qs.stringify({filter: {"backtest.bot": {eq: match.params.id}}, limit: 10});
      dispatch(getBacktests(`?${queryString}`));
    }
  };

  if (error) return <Error error={error} />;

  return(
    <>
      <div className="d-none d-md-flex align-items-center justify-content-between">
        <Breadcrumbs
          items={setBotPageBreadcrumbs(
            data?.investment?.credentials?.exchange,
            data?.investment?.credentials?.id,
            data?.investment?.id,
            data?.investment?.asset,
            data?.name,
            "",
            false
          )}
          loading={loading}
        />

        <RefreshButton
          loading={loading}
          onHandleRefresh={handleRefresh}
        />
      </div>
      <BotDetailsHeader
        data={data}
        id={match.params.id}
        loading={loading}
      />
      <Row>
        <Col
          xs="12"
          md="4"
          className="no-gutters"
        >
          <BotSettingsWidget
            data={data}
            loading={loading}
            onEditButtonClick={handleToEditBot}
          />
          <TradeSettings
            isGenetic={data?.type === "genetic"}
            isAllCoins={data?.allCoins}
            bot={data}
            isBotLoading={loading}
            tradeSettings={tradeSettingsData}
            exchange={data?.investment?.credentials?.exchange}
          />
          <Investment
            investment={data?.investment}
            loading={loading}
            runningDeals={data?.runningDeals}
            showDealsCount
          />
          {
            data?.investment?.credentials &&
            <Credential credential={data.investment.credentials}/>
          }
          <DangerZone
            id={match?.params.id}
            loading={loading}
            content={BOTS_CONTENT?.details?.widgets?.dangerZone}
            isDelete
          />
        </Col>

        <Col xs="12" md="8">
          <PagesView
            data={data}
            loadingPage={loading}
            id={match.params.id}
            location={location}
            pageView={pageView}
            setPagView={setPagView}
          />
        </Col>
      </Row>
    </>
  );
};

export default BotPage;
