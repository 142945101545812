import SkeletonPreloader from "components/SkeletonPreloader";
import React from "react";

const CurrentExchangePreloader = () => (
  <>
    <div>
      <SkeletonPreloader
        width={24}
        height={24}
        circle
        className="mr-2"
      />
      <SkeletonPreloader
        width={60}
        height={17}
      />
      <SkeletonPreloader
        width={87}
        height={17}
      />
    </div>
    <SkeletonPreloader
      width={55}
      height={24}
    />
  </>
);

export default CurrentExchangePreloader;