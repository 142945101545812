import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  Card,
  CardBody,
  CardHeader
} from "reactstrap";
import {
  useDispatch,
  useSelector
} from "react-redux";
import qs from "qs";

import useWindowDimensions from "hooks/useWindowDimensions";
import { MyCredentialsBalancesPreloader } from "./MyCredentialsBalancesPreloader/MyCredentialsBalancesPreloader";
import { CenterColumn } from "./CenterColumn";
import { SideColumn } from "./SideColumn";
import { getBalance, fetchBalances } from "store/credentials/actions";
import { selectBalance } from "store/credentials/selectors";

export const MyCredentialBalances = ({ id, quote, base}) => {

  const dispatch = useDispatch();
  const { data, loading } = useSelector(selectBalance);
  const { width } = useWindowDimensions();
  const mobileWidth = width > 769;

  const quoteData = data?.items?.filter(({ asset }) => asset === quote)?.[0];
  const baseData = data?.items?.filter(({ asset }) => asset === base)?.[0];
  const { exchange: exchangeName } = useParams();

  useEffect(() => {
    if(!id){
      const filter = qs.stringify({
        filter: {
          exchange: {
            eq: exchangeName
          }
        }
      });
      dispatch(fetchBalances(`?${filter}&limit=2`));
    }
  }, [dispatch, quote, base, exchangeName, id]);

  useEffect(() => {
    const filters = qs.stringify({
      limit: 100
    })
    dispatch(getBalance(id, `?${filters}`));
  }, [id, dispatch]);

  return (
    loading ?
      <MyCredentialsBalancesPreloader /> :
      <StyledCard className="StyledCard">
        <StyledCardHeader className="p-0 mb-3">
          <p className="mb-0 pt-0 text-white font-weight-500 text-nowrap"> Balances </p>
        </StyledCardHeader>
        <StyledCardBody>
          <Flex className="w-100" gap="16px" content="space-between">
            <SideColumn asset={quote} />
            <Flex gap="16px">
              <CenterColumn
                header="On orders"
                main={quoteData?.onOrders?.amount ?? "0"}
                footer={quote}
              />
              <CenterColumn
                header="Available"
                main={quoteData?.available?.amount ?? "0"}
                footer={quote}
              />
            </Flex>
          </Flex>
          <Separator />
          <Flex className="w-100" gap="16px" content="space-between">
            <Flex gap="16px" className="one">
              <CenterColumn
                header={`${mobileWidth ? "Available" : "On orders"}`}
                main={`${mobileWidth ? baseData?.available?.amount : baseData?.onOrders?.amount}`}
                footer={base}
              />
              <CenterColumn
                header={`${mobileWidth ? "On orders" : "Available"}`}
                main={`${mobileWidth ? baseData?.onOrders?.amount : baseData?.available?.amount}`}
                footer={base}
              />
            </Flex>
            <SideColumn
              asset={base}
              side="left"
              className="two"
            />
          </Flex>
        </StyledCardBody>
      </StyledCard>
  );
};

export const StyledCard = styled(Card)`
  padding: 32px 24px;
  margin-bottom: 0 !important;

  &.StyledCard {
    border-radius: 0 !important;
  }
`;

export const StyledCardHeader = styled(CardHeader)`
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    justify-content: start;
  }
`;

export const StyledCardBody = styled(CardBody)`
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const Separator = styled.div`
    width: 1px;
    height: 40px;
    margin: 0 32px;
    background: rgba(255, 255, 255, 0.10);

    @media (max-width: 768px) {
        width: 100%;
        height: 1px;
        margin: 16px 0;
    }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ items }) => items};
  gap: ${({ gap }) => gap};
  justify-content: ${({ content }) => content};

  @media (max-width: 768px) {
    &.one {
      order: 1;
    }

    &.two {
      order: 2;
    }
  }`;
