import React from "react";
import {
  CardHeader,
  Card
} from "reactstrap";

import StyledPreloader from "components/StyledPreloader";
import SkeletonPreloader from "components/SkeletonPreloader";
import TablePreloader from "components/TablePreloader";

const DealsPreloader = () => {
  return (
    <Card className="overflow-hidden">
      <CardHeader className="p-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <SkeletonPreloader
              circle={true}
              height={20}
              width={20}
            />
            <SkeletonPreloader
              height={13}
              width={100}
              className="ml-2"
            />
          </div>
          <div className="d-flex align-items-center justify-content-end w-25">
            <StyledPreloader style={{ borderRadius: "6px", height: "38px" }} />
          </div>
        </div>
      </CardHeader>

      <TablePreloader
        tdWidth={[35,97,129,97,97,129]}
        tdHeight={13}
        rowTimes={5}
        withCircle={24}
      />
    </Card>
  );
};

export default DealsPreloader;
