import React from "react";
import Slider from "rc-slider";

const CustomSlider = ({ ...props }) => {
  return (
    <Slider
      dotStyle={{
          border: "none",
          backgroundColor: "var(--info)",
          bottom: 0,
          width: 24,
          height: 24,
      }}
      railStyle={{
        backgroundColor: "#363A54",
        height: 8
      }}
      trackStyle={{
        backgroundColor: "#1A6EDF",
        height: 8
      }}
      handleStyle={{
        border: "none",
        backgroundColor: "var(--info)",
        opacity: 1,
        width: 24,
        height: 24,
        bottom: "-50%"
      }}
      {...props}
    />
  );
}

export default CustomSlider;
