import React from "react";
import styled from "styled-components";
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";

import { generateRefCode } from "store/user/actions";
import {ReactComponent as ReferralUfo} from "assets/img/icons/ReferralUfo.svg"
import {ReactComponent as ReferralWood} from "assets/img/icons/ReferralWood.svg"

const ReferralEmptyState = () => {

  const dispatch = useDispatch();
  const generateCode = () => dispatch(generateRefCode())

  return (
    <ReferralEmptyState.Wrapper
      className="d-flex flex-column align-items-center"
    >
      <div className="position-relative m-auto">
        <ReferralEmptyState.Wood>
          <ReferralWood/>
        </ReferralEmptyState.Wood>
        <ReferralEmptyState.Ufo className="position-absolute">
          <ReferralUfo/>
        </ReferralEmptyState.Ufo>
      </div>
        <ReferralEmptyState.Text className="d-flex flex-column align-items-center">
          <h1 className="mb-2">
            Your referral link has not yet been generated!
          </h1>
          <h3 className="text-center text-white-35 font-weight-300 m-0">
            Generate a link and receive money from each purchase
            <br/>
            of a person who registers using your link
          </h3>
        </ReferralEmptyState.Text>
          <Button
            className="btn-blue m-0"
            onClick={generateCode}
          >
            Generate referral link
          </Button>
    </ReferralEmptyState.Wrapper>
  );
}

ReferralEmptyState.Wrapper = styled.div`
  margin-top: 115px;
`;

ReferralEmptyState.Ufo = styled.div`
  & svg {
    top: 0.77px;
    left: 88px;
    width: 148px;
    height: 146.55px;
  }
`;

ReferralEmptyState.Wood = styled.div`
  & svg {
    width: 266.32px;
    height: 128.41px;
  }
`;

ReferralEmptyState.Text = styled.div`
  margin: 40px 0;
  & h3 {
    line-height: 175%;
  }
`;

export default ReferralEmptyState;
