import React from "react";

import Icon from "components/Icon";
import StatusIndicator from "../../../components/StatusIndicator"
import { SignalBlockPropTypes } from "../signalPropTypes";

const SignalBlock = ({
  createdAt,
  status,
  base,
  quote,
  exchange,
}) => {
  return (
    <div className="d-flex  flex-column">
      <div className="d-flex flex-wrap justify-content-center justify-content-md-normal align-items-start align-items-lg-center">
        <Icon
          type="exchanges"
          size="xxs"
          code={exchange}
          className="my-auto"
        />
        <div className="ml-3">
          <div className="d-flex align-items-center mb-2">
            <p className="font-weight-500 mb-0 text-white">
              {base} / {quote}
            </p>
            <StatusIndicator status={status} />
          </div>
          <p className="text-white-35 font-sm mb-0">
            Published {createdAt}
          </p>
        </div>
      </div>
    </div>
  );
};

SignalBlock.propTypes = SignalBlockPropTypes;

export default SignalBlock;