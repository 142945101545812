export const parseQueryString = (filters, operands = []) => {
  if (operands) {
    const params = Object.keys(filters).map((filter, index) =>
      `&filter[${[filter === 'credentials' ? filter + '.exchange' : filter]}][${operands[index]}]=${filters[filter]}`
    );

    return params.join("")
  }

  const params = Object.keys(filters).map((filter) =>
    (filters[filter] && filter !== "page") ?
    `&filter[${[filter]}]=${filters[filter]}`
    :
    ""
  );
  return params.join("");
};

export const delay = (setLoading, ms) => new Promise(
  (resolve) => {
    setLoading(true)
    setTimeout(resolve, ms)
  }
);
