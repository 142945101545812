import React from "react";
import styled from "styled-components";
import qs from "qs";
import { useHistory, useLocation } from "react-router-dom";

import CustomScrollbars from "components/CustomScrollbars";
import {formatValue, setColorClass} from "helpers/valuesFormatters";
import StyledTable from "components/StyledTable";
import SortTableHead from "components/SortTableHead";
import {STATISTICS_TABLE_HEAD} from "models/credentials/enum/tables";

const MarketTable = ({data = []}) => {
  const location = useLocation();
  const history = useHistory();

  const { filter, sort } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const handleSorting = (value) => {
    history.push({
      search: qs.stringify({filter: filter, sort: {...sort, ...value}}).replace(/[^=&]+=(?:&|$)/g, ""),
    });
  };

  return (
    <CustomScrollbars
      autoHideTimeout={500}
      autoHideDuration={500}
      autoHeight
      autoHeightMax={Number.MAX_VALUE}
    >
      <StyledTable className="mb-0" paddingsThead="1rem">
        <SortTableHead
          handleSorting={handleSorting}
          sort={sort}
          theadItems={STATISTICS_TABLE_HEAD}
        />
        <tbody className="font-sm">
          {
            data.map((item) =>
              <tr key={item.id}>
                <td>
                  <div className="d-flex">
                    <MarketTable.Amount amount={1}>{item.market.base}</MarketTable.Amount>
                    <MarketTable.Amount amount={0} className="mx-1">/</MarketTable.Amount>
                    <MarketTable.Amount amount={1}>{item.market.quote}</MarketTable.Amount>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center text-nowrap">
                    <p className={`mb-0 ${setColorClass(item.totalIn.amount, "text-white-35")}`}>
                      {formatValue(item.totalIn.amount)}
                    </p>
                    <p className="mb-0 ml-1 text-white-35 font-weight-300">{item.totalIn.currency}</p>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center text-nowrap">
                    <p className={`mb-0 ${setColorClass(item.totalOut.amount, "text-white-35")}`}>
                      {formatValue(item.totalOut.amount)}
                    </p>
                    <p className="mb-0 ml-1 text-white-35 font-weight-300">{item.totalOut.currency}</p>
                  </div>
                </td>
                <td>
                  <MarketTable.Amount amount={item.totalOrders}>{item.totalOrders}</MarketTable.Amount>
                </td>
                <td>
                  <MarketTable.Amount amount={item.totalTrades}>{item.totalTrades}</MarketTable.Amount>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <MarketTable.Amount amount={item.totalFeeBuy.amount}>{item.totalFeeBuy.amount}</MarketTable.Amount>
                    <p className="mb-0 ml-1 text-white-35 font-weight-300">{item.totalFeeBuy.currency}</p>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <MarketTable.Amount amount={item.totalFeeSell.amount}>{item.totalFeeSell.amount}</MarketTable.Amount>
                    <p className="mb-0 ml-1 text-white-35 font-weight-300">{item.totalFeeSell.currency}</p>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <MarketTable.Amount amount={item.sumTotalSell.amount}>{item.sumTotalSell.amount}</MarketTable.Amount>
                    <p className="mb-0 ml-1 text-white-35 font-weight-300">{item.sumTotalSell.currency}</p>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center text-nowrap">
                    <MarketTable.Amount amount={item.sumTotalBuy.amount}>{item.sumTotalBuy.amount}</MarketTable.Amount>
                    <p className="mb-0 ml-1 text-white-35 font-weight-300">{item.sumTotalBuy.currency}</p>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center text-nowrap">
                    <MarketTable.Amount amount={item.sumAmountSell.amount}>{item.sumAmountSell.amount}</MarketTable.Amount>
                    <p className="mb-0 ml-1 text-white-35 font-weight-300">{item.sumAmountSell.currency}</p>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <MarketTable.Amount amount={item.sumAmountBuy.amount}>{item.sumAmountBuy.amount}</MarketTable.Amount>
                    <p className="mb-0 ml-1 text-white-35 font-weight-300">{item.sumAmountBuy.currency}</p>
                  </div>
                </td>
              </tr>
            )
          }
        </tbody>
      </StyledTable>
    </CustomScrollbars>
  )
};

MarketTable.Amount = styled.p`
  color: ${(props) => props.amount > 0 ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.35)"} !important;
  margin-bottom: 0;
  white-space: nowrap;
`;

export default MarketTable;
