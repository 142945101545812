import React from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

const CircleChart = ({ steps }) => {

  return (
    <CircleChart.Wrap className="d-flex">
      {
        steps?.map((step) => (
          <svg key={uuidv4()} xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
            <circle cx="4.66669" cy="4" r="4" fill={`var(--${step.color})`} opacity={step.active ? "1" : "0.3"} />
          </svg>
        ))
      }
    </CircleChart.Wrap>
  );
};

CircleChart.Wrap = styled.div`
  gap: 6px;
`;

export default CircleChart;
