import {Tooltip} from "reactstrap";
import React, {useState} from "react";

const MarketBotsTooltips = ({index, profit, loss}) => {
  const [isShow, setIsShow] = useState(false);

  const toggleShow = () => setIsShow(prevState => !prevState);

  return (
    <Tooltip
      target={`multi-${index}`}
      placement="top-center"
      isOpen={isShow}
      toggle={toggleShow}
    >
      <div>
        <p className="mb-0 text-dark font-xs font-weight-500">
          {profit.percent || 0} % <span className="font-weight-300">profitable</span>
        </p>
        <p className="mb-0 text-dark font-xs font-weight-500">
          {loss.percent || 0} % <span className="font-weight-300">loss</span>
        </p>
      </div>
    </Tooltip>
  )
}

export default MarketBotsTooltips;