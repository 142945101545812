import React from "react";
import ReactDatetime from "react-datetime";
import styled from "styled-components";
import { FormGroup } from "reactstrap";
import moment from "moment";

import { ReactComponent as ClearIndicatorIcon } from "assets/img/icons/clear.svg";
import { ReactComponent as Calendars } from "assets/img/icons/calendars.svg";

const DateField = ({
  onChange,
  inputProps,
  dateFormat,
  className,
  closeOnSelect=true,
  ...props
}) => {
  const handleOnChange = (value) => {
    if (!value?._d) return onChange("");
    onChange(moment(value._d).format(dateFormat));
  };

  const handleClearValue = () => handleOnChange("");

  return (
    <FormGroup className={`mb-0 ${className}`}>
      <ReactDatetime
        inputProps={inputProps}
        onChange={handleOnChange}
        dateFormat={dateFormat}
        closeOnSelect={closeOnSelect}
        {...props}
      />
      <DateField.IconWrapper>
        {
          inputProps.value ?
          <button
            className="m-0 p-0"
            onClick={handleClearValue}
          >
            <ClearIndicatorIcon />
          </button>
          :
          <Calendars/>
        }
      </DateField.IconWrapper>
    </FormGroup>
  );
};

DateField.IconWrapper = styled.div`
  position: absolute;
  right: 4%;
  top: 45%;
  transform: translateY(-50%);

  svg {
    path {
      fill: #A3A3A3;
    }
  }

  button {
    border: none;
    background: transparent;

    &:hover {
      opacity: 0.5;
    }
  }
`;

export default DateField;
