export const getCurrentPrice = (plan, paymentInterval) => {
  if (!plan || !plan.prices) return null;

  const currentPrice = plan.prices.find(price => price.interval === paymentInterval);
  if (!currentPrice) return null;

  const currencySymbol = currentPrice.currency?.toUpperCase() === "USD" ? "$" : currentPrice.currency;
  const amount = paymentInterval === "year" ? currentPrice.amount / 12 : currentPrice.amount;

  return `${currencySymbol}${amount.toFixed(2)}`;
};
