import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import classnames from "classnames";

import IndividualsItem from "../../../../genetic/compouds/IndividualsItem";

const GSTableRow = ({ item, idxRow }) => {
  const history = useHistory();

  const statistics = item?.geneticAlgorithm?.population?.statistic;

  const individualsCount = statistics && Object.values(statistics).reduce((acc, elem) => elem + acc, 0);
  const individualsLength = statistics && Object.keys(statistics).filter(elem => statistics[elem] > 0).length;

  return (
    <tr
      className="cursor-pointer"
      onClick={() => history.push(`/genetic-algorithm/${item.id}`)}
    >
      <td>
        <p className="mb-0 text-white font-weight-500 text-nowrap">
          {item.market.pair?.replace("-", " / ")}
        </p>
      </td>

      <td>
        <p className={classnames("mb-0 font-weight-500", {
          "text-white": !item.geneticAlgorithm.disabled,
          "text-white-35": item.geneticAlgorithm.disabled
        })}>
          {item.geneticAlgorithm.disabled ? "Disabled" : "Enabled"}
        </p>
      </td>

      <td>
        <p className="mb-0 text-white font-weight-500">
          {item.geneticAlgorithm.generations || "-"}
        </p>
      </td>

      <td>
        <GSTableRow.Status>
          <p className={classnames("mb-0 font-weight-500 text-capitalize", {
            "text-success": item.geneticAlgorithm.status === "evolution",
            "text-danger": item.geneticAlgorithm.status === "terminated",
            "text-white": item.geneticAlgorithm.status === "new",
          })}>
            {item.geneticAlgorithm.status}
          </p>
        </GSTableRow.Status>
      </td>

      <td>
        <div className="w-100 d-flex align-items-center">
          {
            statistics ? Object.keys(statistics)
              .filter((elem) => statistics[elem] > 0)
              .sort((item1, item2) => statistics[item2] - statistics[item1])
              .map((elem) =>
                <IndividualsItem
                  key={elem}
                  title={elem}
                  idxRow={idxRow}
                  value={statistics[elem]}
                  individualsLength={individualsLength}
                  individualsCount={individualsCount}
                  height="12px"
                  withSpacing
                />
              ) : "-"
          }
        </div>
      </td>
    </tr>
  )
}

GSTableRow.Status = styled.div`
  background: rgba(255, 255, 255, 0.03);
  border-radius: 6px;
  padding: 3.5px 10px;
  width: max-content;
  p {
    line-height: 16px;
  }
`;

export default GSTableRow;
