import React from "react";
import { Progress } from "reactstrap";

const DealsProgressBar = ({ countLosingDeals=0, countProfitableDeals=0 }) => {
  return (
    <Progress
      multi
      style={{
        width: "100%",
        height: "6x",
        background: "none",
        boxShadow: "none",
        justifyContent: "space-between"
      }}
    >
      <Progress
        bar
        color="success"
        value={!+countLosingDeals && !+countProfitableDeals ? 50 : +countProfitableDeals}
        style={{
          width: "100%",
          cursor: "pointer",
          marginRight: !+countLosingDeals && +countProfitableDeals ? 0 : "0.125rem"
        }}
        max={+countLosingDeals + +countProfitableDeals || 50}
      />
      <Progress
        bar
        color="danger"
        value={!+countLosingDeals && !+countProfitableDeals ? 50 : +countLosingDeals}
        style={{
          width: "100%",
          cursor: "pointer",
          marginLeft: +countLosingDeals && !+countProfitableDeals ? 0 : "0.125rem"
        }}
        max={+countLosingDeals + +countProfitableDeals || 50}
      />
    </Progress>
  );
};

export default DealsProgressBar;
