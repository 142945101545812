import React from "react";
import _ from "lodash";
import {Card, CardHeader, Col} from "reactstrap";

import SkeletonPreloader from "components/SkeletonPreloader";
import {HeaderRow} from "../../styled/StyledConditions";

const IndicatorsPreloader = () => {
  return (
    <Card className="mb-4">
      <CardHeader className="d-flex flex-column px-4 pt-4 pb-2">
        <div className="d-flex align-items-center mb-4">
          <SkeletonPreloader height={20} width={20} circle/>
          <SkeletonPreloader height={14} width={162} className="ml-2"/>
        </div>
        <HeaderRow className="no-gutters p-0">
          <Col md="6" xs="5">
            <SkeletonPreloader height={10} width={32}/>
          </Col>
          <Col md="2" xs="2" className="mr-2 mr-md-0">
            <SkeletonPreloader height={10} width={48}/>
          </Col>
          <Col md="2" xs="2" className="mr-2 mr-md-0">
            <SkeletonPreloader height={10} width={48}/>
          </Col>
          <Col md="2" xs="2" className="mr-2 mr-md-0">
            <SkeletonPreloader height={10} width={48}/>
          </Col>
        </HeaderRow>
      </CardHeader>
      {_.times(3, (e) =>
        <HeaderRow className="no-gutters px-4 py-3" style={{borderTop: "1px solid #2E3148"}} key={e}>
          <Col md="6" xs="6">
            <SkeletonPreloader height={12} width={122}/>
          </Col>
          <Col md="2" xs="3">
            <SkeletonPreloader height={12} width={62}/>
          </Col>
          <Col md="2" xs="3">
            <SkeletonPreloader height={12} width={65}/>
          </Col>
          <Col md="2" className="d-none d-md-block">
            <SkeletonPreloader height={12} width={128}/>
          </Col>
        </HeaderRow>
      )}
    </Card>
  )
}

export default IndicatorsPreloader;
