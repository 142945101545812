import React from "react";
import { useSelector } from "react-redux";

import {
  botsSelector,
  credentialsSelector,
  investmentsSelector,
  dealsSelector
} from "store/dashboard/selectors";
import BotsResults from "./compounds/BotsResults";
import DealsResults from "./compounds/DealsResults";
import CredentialsResults from "./compounds/CredentialsResults";
import InvestmentsResult from "./compounds/InvestmentsResult";

const SearchResults = ({ onSetModalSearch }) => {
  const botsResult = useSelector(botsSelector);
  const credentialsResult = useSelector(credentialsSelector);
  const investmentsResult = useSelector(investmentsSelector);
  const dealsResult = useSelector(dealsSelector);

  return (
    <>
      <BotsResults
        onSetModalSearch={onSetModalSearch}
        botsResult={botsResult}
      />
      <DealsResults
        onSetModalSearch={onSetModalSearch}
        dealsResult={dealsResult}
      />
      <CredentialsResults
        onSetModalSearch={onSetModalSearch}
        credentialsResult={credentialsResult}
      />
      <InvestmentsResult
        onSetModalSearch={onSetModalSearch}
        investmentsResult={investmentsResult}
      />
    </>
  );
};

export default SearchResults;
