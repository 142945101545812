import React from "react";
import styled from "styled-components";
import _ from "lodash";
import {
  CardBody,
  Card,
  CardHeader
} from "reactstrap";

import SkeletonPreloader from "components/SkeletonPreloader";

const ListLoading = () => {
  return(
    <ListLoading.StrategiesWrapper>
      {
        _.times(6, (e) =>
          <Card key={e}>
            <ListLoading.CardHeader className="p-0" />
            <CardBody>
              <div className="w-100 d-flex flex-column">
                <SkeletonPreloader
                  count={12}
                  className="mt-2 mb-2"
                />
              </div>
            </CardBody>
          </Card>
        )
      }
    </ListLoading.StrategiesWrapper>
  );
};

ListLoading.StrategiesWrapper = styled.div`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;

  @media (min-width: 768px) and (max-width: 1199.98px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 767.98px) {
    display: flex;
    flex-direction: column;
  }
`;

ListLoading.CardHeader = styled(CardHeader)`
  background: #252738 !important;
  height: 105px;
`;


export default ListLoading;
