import SkeletonPreloader from "components/SkeletonPreloader";
import React from "react";

import {
  ColumnFlex,
  Footer,
  Header
} from "../SideColumn";

export const SideColumnPreloader = ({side}) => {
  return (side === "left" ?
    <ColumnFlex gap="16px" items="center">
      <ColumnFlex direction="column" content="center" items="end" className="two">
        <Header>
          <SkeletonPreloader
            height={16}
            width={40}
          />
        </Header>
        <Footer>
          <SkeletonPreloader
            height={16}
            width={79}
          />
        </Footer>
      </ColumnFlex>
      <SkeletonPreloader
        circle
        height={40}
        width={40}
      />
    </ColumnFlex> :
    <ColumnFlex gap="16px" items="center">
      <SkeletonPreloader
        circle
        height={40}
        width={40}
      />
      <ColumnFlex direction="column" content="center" className="two">
        <Header>
          <SkeletonPreloader
            height={16}
            width={40}
          />
        </Header>
        <Footer>
          <SkeletonPreloader
            height={16}
            width={79}
          />
        </Footer>
      </ColumnFlex>
    </ColumnFlex>);
};
