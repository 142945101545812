import React from "react";
import { Label } from "reactstrap";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  Field,
  useFormikContext
} from "formik";

import ExchangesSelect from "components/ExchangesSelect";
import styled from "styled-components";

const ExchangeInputBlock = ({
  isSubmitted,
  errors,
  isHasChannels
}) => {
  const { setFieldValue } = useFormikContext();
  return (
    <div className="d-block d-md-flex align-items-end justify-content-between mt-3 mb-0">
      <ExchangeInputBlock.SelectWrap className="mb-4 mb-md-0">
        <Label className="text-white-75 font-weight-400 mb-2">Exchange</Label>
        <ExchangesSelect
          isDisabled={isHasChannels}
          withAllExchanges
          placeholder=" "
          name="exchange"
          hasError={isSubmitted && errors?.exchange}
          width="100%"
          onChange={({ value }) => setFieldValue("exchange", value)}
        />
        {isSubmitted && errors?.exchange &&
          <p className="text-danger font-sm font-weight-300 m-0 position-absolute">
            {errors?.exchange}
          </p>}
      </ExchangeInputBlock.SelectWrap>
      <div className="w-100 ml-0 ml-md-3">
        <Label
          htmlFor="market.base"
          className="text-white-75 font-weight-400 mb-2"
        >
          Market
        </Label>
        <div className="d-flex align-items-center">
          <div>
            <Field
              type="string"
              name="market.base"
              placeholder="BTC"
              disabled={isHasChannels}
              onChange={(e) => setFieldValue("market.base", e.target.value)}
              className={classNames("form-control", {
                "has-error": isSubmitted && errors?.market?.base
              }, "mb-0 text-white")}
            />
            {isSubmitted && errors?.market?.base &&
              <p className="text-danger font-sm font-weight-300 m-0 position-absolute">
                {errors?.market?.base}
              </p>}
          </div>
          <p className="font-md font-weight-400 mx-2 mb-0">/</p>
          <div>
            <Field
              type="string"
              name="market.quote"
              placeholder="USDT"
              disabled={isHasChannels}
              onChange={(e) => setFieldValue("market.quote", e.target.value)}
              className={classNames("form-control", {
                "has-error": isSubmitted && errors?.market?.quote
              }, "mb-0 text-white")}
            />
            {isSubmitted && errors?.market?.quote &&
              <p className="text-danger font-sm font-weight-300 m-0 position-absolute">
                {errors?.market?.quote}
              </p>}
          </div>
        </div>
      </div>
    </div>
  );
};

ExchangeInputBlock.SelectWrap = styled.div`
  width: 54%;
  
  @media (max-width: 768px){
    width: 100%;
  }
`;

ExchangeInputBlock.propTypes = {
  isSubmitted: PropTypes.bool,
  isHasChannels: PropTypes.bool,
  errors: PropTypes.shape({
    buyFrom: PropTypes.string,
    buyTo: PropTypes.string,
    stopLoss: PropTypes.string,
  }),
};

export default ExchangeInputBlock;