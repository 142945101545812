import React from "react";
import styled from "styled-components";
import { DelayInput } from "react-delay-input";
import {
  Col,
  FormGroup,
  Row
} from "reactstrap";

import DateField from "components/DateField";
import { beforeToday } from "helpers/DatepickerValidation";

const NotificationsSearch = ({
  search,
  createdAt,
  onSetCreatedAt,
  onSetSearch
}) => {
  return (
    <div className="d-flex flex-column flex-lg-row pl-3 pr-3 pt-2">
      <Col xs="12" lg="6" className="px-0 pr-lg-3">
        <FormGroup>
          <DelayInput
            delayTimeout={700}
            minLength={1}
            name="message"
            value={search.message && search.message.like ? search.message.like : ""}
            onChange={({ target }) => onSetSearch({ message: { like: target.value } })}
            className="form-control light font-xs"
            placeholder="Search"
          />
        </FormGroup>
      </Col>
      <Row className="no-gutters mb-3 w-100">
        <Col xs="12" sm="6" className="px-0 pr-sm-2">
          <DateField
            onChange={(value) => onSetCreatedAt({lte: "", gte: value})}
            isValidDate={beforeToday}
            dateFormat="YYYY-MM-DD"
            inputProps={{
              style: {
                padding: "8px 10px"
              },
              className: "form-control light font-xs w-100",
              name: "createdFrom",
              placeholder: "From",
              readOnly: true,
              value: createdAt.gte || ""
            }}
          />
        </Col>
        <Col xs="12" sm="6" className="px-0 pl-sm-2 mt-2 mt-sm-0">
          <DateField
            isValidDate={beforeToday}
            onChange={(value) => onSetCreatedAt({...createdAt, lte: value})}
            dateFormat="YYYY-MM-DD"
            inputProps={{
              style: {
                padding: "8px 10px"
              },
              className: "form-control light font-xs w-100",
              name: "createdTo",
              placeholder: "To",
              readOnly: true,
              value: createdAt.lte || ""
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

NotificationsSearch.Calendar = styled.div`
  position: absolute;
  top: 20%;
  right: 4%;

  svg {
    path {
      fill: #A3A3A3;
    }
  }
`;

export default NotificationsSearch;
