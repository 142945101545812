import React from "react";
import {
  Card,
  CardBody,
  CardHeader
} from "reactstrap";
import { times } from "lodash";

import SkeletonPreloader from "components/SkeletonPreloader";

const LastBacktestPreloader = () => {
  return (
    <Card>
      <CardHeader className="p-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <SkeletonPreloader
              circle
              height={24}
              width={24}
            />
            <SkeletonPreloader
              height={12}
              width={55}
              className="my-auto ml-2"
            />
          </div>
          <SkeletonPreloader
            height={8}
            width={36}
            className="my-auto"
          />
        </div>
      </CardHeader>
      <CardBody className="pt-0 px-4 pb-4">
        <div className="d-flex justify-content-between">
          <SkeletonPreloader
            height={8}
            width={50}
            className="my-auto"
          />
          <SkeletonPreloader
            circle
            height={24}
            width={24}
          />
        </div>
        <hr />
        {
          times(6, (e) =>
            <div
              className={`d-flex justify-content-between ${e ? 'mt-2' : ''}`}
              key={e}
            >
              <SkeletonPreloader
                height={8}
                width={75}
              />
              <SkeletonPreloader
                className="my-auto"
                height={12}
                width={40}
              />
            </div>
          )
        }
      </CardBody>
    </Card>
  );
};

export default LastBacktestPreloader;
