import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import Icon from "components/Icon";
import PercentBadge from "components/PercentBadge";
import { replaceDurations } from "helpers/replaceDurations";
import {
  formatterToNull,
  setColorClass
} from "helpers/valuesFormatters";

const LastActivitiesTable = ({
  item,
  itemPath,
  invested,
  code,
  profit,
  profitPercent
}) => {
  const history = useHistory();

  const handleClick = (event, id) => {
    if (event.button === 0) history.push(`${itemPath}/${id}`);
    if (event.button === 1) window.open(`${itemPath}/${id}`, "_blank");
  };

  return (
    <tr
      key={item.id}
      onMouseDown={(e) => handleClick(e, item.id)}
      className="cursor-pointer"
    >
      <td>
        <div className="d-flex flex-row align-items-center">
          <Icon
            type="exchanges"
            size="thumb"
            className="mr-3"
            code={code}
          />
          <div className="d-flex align-items-center">
            <p className="mb-0 text-white font-weight-500">
              {item?.market?.base}
            </p>
            <span className="mb-0 ml-1 text-white-35 font-weight-300">/</span>
            <p className="mb-0 ml-1 text-white-35 font-weight-500">
              {item?.market?.quote}
            </p>
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <p className={`mb-0 font-weight-500 ${!invested ? "text-white-35" : "text-white"
            }`}
          >
            {formatterToNull(invested)}
          </p>
          <p className="mb-0 ml-1 text-white-35 font-weight-light">{item.market?.quote || ''}</p>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center text-nowrap">
          <p className={`${setColorClass(profit, "text-white-35")} mb-0 font-weight-500`}>
            {formatterToNull(profit)}
          </p>
          <p className="mb-0 ml-1 mr-1 text-white-35 font-weight-light">
            {item.profit?.asset || item.market?.quote}
          </p>
          <PercentBadge
            value={profitPercent}
          />
        </div>
      </td>
      <td>
        <p className="mb-0 text-white text-nowrap font-weight-500">
          {replaceDurations(item?.duration) || "0m"}
        </p>
      </td>
      <td width={50}>
        <p className="mb-0 text-white font-weight-500 text-nowrap">
          {item?.completedAt ? moment(item?.completedAt).format("DD.MM.YY HH:mm") : "-"}
        </p>
      </td>
    </tr>
  );
};

export default LastActivitiesTable;
