import React from "react";
import PropTypes from "prop-types";

import useDynamicSVGImport from 'hooks/useDynamicSVGImport';
import { ReactComponent as Default } from "assets/img/icons/default.svg";
import StyledPreloader from "components/StyledPreloader";

const iconSizes = {
  smallSize: 16,
  thumb: 24,
  botSize: 32,
  xxs: 40,
  botPage: 45,
  xs: 56,
  s: 100,
  md: 200,
  lg: 500,
  xl: 1000
}

const Icon = ({ code='', size, type, id, ...props}) => {
  const { error, loading, SvgIcon } = useDynamicSVGImport(code.toLocaleLowerCase(), type, {});

  const getSize = (size) => iconSizes[size] || "xs";

  const getStyle = (size) => ({ height: getSize(size), maxWidth: getSize(size) });

  if (loading) return (
    <StyledPreloader
      circle
      height={getSize()}
      width={getSize()}
      {...props}
    />
  );

  if (SvgIcon) return (
    <img
      src={SvgIcon}
      alt="..."
      style={getStyle(size)}
      {...props}
    />
  );

  if (error) return (
    <Default
      style={getStyle(size)}
      role="img"
      {...props}
    />
  );
}

Icon.propTypes = {
  size: PropTypes.oneOf([
    "smallSize", "thumb", "botSize", "xxs", "botPage", "xs", "s", "md", "lg", "lg"
  ]).isRequired,
  code: PropTypes.string.isRequired,
};

export default Icon;
