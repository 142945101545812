import styled from "styled-components";

export const ConfirmationDeleteError = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 16px 24px;
  background: rgba(237, 95, 95, 0.1);
  border: 1px solid #ED5F5F;
  border-radius: 6px;

  & p {
    word-wrap: anywhere;
  }
`;

export const ConfirmationDeleteDescribe = styled.p`
  white-space: pre-line;
`;