import { Label } from "reactstrap";
import styled from "styled-components";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

const ChannelLogoForm = ({ isSubmitted, content }) => {
  const { setFieldValue, values, errors } = useFormikContext();
  return (
    <>
      <Label className="text-white-75 font-size-lg mb-1">
        {content.label}
      </Label>
      <div className="d-flex align-items-center">
        <input
          className="d-none"
          id="file"
          type="file"
          name="file"
          onChange={(event) => {
            setFieldValue("file", event.currentTarget.files[0]);
          }}
        />
        {
          values?.file?.name
            ?
            <>
              <p className="font-sm text-truncate mb-0 ml-auto mr-3">
                {values?.file?.name}
              </p>
              <ChannelLogoForm.FileLabel
                className="mb-0 px-3 py-2 text-white  mr-0"
                htmlFor="file"
                tabIndex={0}
              >
                {content.buttons.change}
              </ChannelLogoForm.FileLabel>
            </>
            :
            <>
              <span className="d-block font-sm font-weight-500 text-white-35">
                {content.informMsg}
              </span>
              <ChannelLogoForm.FileLabel
                htmlFor="file"
                className="mb-0 px-3 py-2 text-white ml-auto mr-0 text-nowrap"
                tabIndex={0}
              >
                {content.buttons.select}
              </ChannelLogoForm.FileLabel>
            </>
        }
      </div>
      {
        isSubmitted && errors?.file &&
        <p className="text-danger mb-3 font-sm font-weight-300 text-right">
          {errors?.file}
        </p>
      }
    </>
  );
};

ChannelLogoForm.FileLabel = styled.label`
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.10);
  cursor: pointer;
  transition: .3s all;

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.05);
    background: rgba(255, 255, 255, 0.05);
`;

ChannelLogoForm.propTypes = {
  isSubmitted: PropTypes.bool
};

export default ChannelLogoForm;