import React, { useEffect, useState } from "react";
import { Form } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { indicatorsConfiguration } from "views/strategies/wizard/indicatorsConfiguration";
import IndicatorFormFooter from "./IndicatorFormFooter";
import { IndicatorFormWrapper } from "./Indicator.styles";
import IndicatorFormLeftBlock from "./IndicatorFormLeftBlock";
import IndicatorFormRightBlock from "./IndicatorFormRightBlock";
import CandlestickChart from "components/CandlestickChart";
import { selectindicatorsSignals } from "store/bots/selectors";
import { indicatorsSignals } from "store/bots/actions";
import IndicatorSetting from "./IndicatorSetting";

/* eslint-disable complexity */
/* eslint-disable react/style-prop-object */

const IndicatorForm = ({
  configurations = [],
  onSetIndicatorType,
  currentConfiguration,
  onHandleResetChart,
  values,
  setFieldValue,
  errors,
  isSubmitting,
  editing,
  indicatorType,
  setErrors,
  subscription,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState(null);

  const wizardMarkets = useSelector((state) => state.botWizard);
  const { data, loading, error } = useSelector(selectindicatorsSignals);

  const dispatch = useDispatch();

  useEffect(() => {
    if (indicatorType && selectedMarket) {
      const param = {
        period: 30,
        ...values,
      };
      dispatch(
        indicatorsSignals(
          wizardMarkets?.investment?.credentials?.exchange,
          selectedMarket,
          wizardMarkets?.investment?.asset,
          indicatorType,
          param
        )
      );
    }
  }, [dispatch, indicatorType, selectedMarket, wizardMarkets, values]);

  if (!indicatorType && editing) return;

  return (
    <IndicatorFormWrapper className="w-100 p-3 p-lg-sm">
      <Form>
        <div className="d-flex flex-column flex-md-row">
          <IndicatorFormLeftBlock
            editing={editing}
            indicatorType={indicatorType}
            indicatorsConfiguration={indicatorsConfiguration}
            configurations={configurations}
            subscription={subscription}
            onSetIndicatorType={onSetIndicatorType}
            setSubmitted={setSubmitted}
            submitted={submitted}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            setSelectedMarket={setSelectedMarket}
            wizardMarkets={wizardMarkets}
          />
          <div className="line mx-sm" />
          <IndicatorFormRightBlock
            values={values}
            setFieldValue={setFieldValue}
            currentConfiguration={currentConfiguration}
            indicatorsConfiguration={indicatorsConfiguration}
            submitted={submitted}
            configurations={configurations}
            subscription={subscription}
            onSetIndicatorType={onSetIndicatorType}
            setSubmitted={setSubmitted}
            errors={errors}
            indicatorType={indicatorType}
            editing={editing}
          />
        </div>
        <IndicatorSetting
          indicatorType={indicatorType}
          currentConfiguration={currentConfiguration}
          values={values}
          setFieldValue={setFieldValue}
          submitted={submitted}
          errors={errors}
        />
      </Form>
      <CandlestickChart
        loading={loading}
        error={error}
        candlestickChart={data?.candlestickChart}
        indicatorSignals={data?.indicatorSignals}
      />
      <IndicatorFormFooter
        submitted={submitted}
        errors={errors}
        setErrors={setErrors}
        onHandleResetChart={onHandleResetChart}
        indicatorType={indicatorType}
        isSubmitting={isSubmitting}
        setSubmitted={setSubmitted}
        editing={editing}
      />
    </IndicatorFormWrapper>
  );
};

export default IndicatorForm;
