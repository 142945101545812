import React from "react";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody
} from "reactstrap";

import SkeletonPreloader from "components/SkeletonPreloader";
import { balanceHistory } from "charts";
import { ReactComponent as BalanceIcon } from "assets/img/icons/orders.svg";
import { ReactComponent as DashboardBalanceChartPreload } from "assets/img/DashboardBalanceChartPreload.svg";

const BalanceChartWidget = ({
  data={},
  loading,
  currency=""
}) => {
  if (loading) return (
    <StyledChartCard className="p-4">
      <div className="d-flex align-items-center mb-4">
        <SkeletonPreloader
          circle={true}
          height={18}
          width={18}
        />
        <SkeletonPreloader
          height={12}
          width={111}
          className="ml-2"
        />
      </div>
      <DashboardBalanceChartPreload width="100%" height="100%" />
    </StyledChartCard>
  );

  return (
    <StyledChartCard
      className="card-chart"
    >
      <CardHeader className="px-4 pt-4 pb-0">
        <div className="d-flex align-items-center">
          <StyledIcon />
          <p className="mb-0 ml-2 text-white font-weight-500">
            Balance history
          </p>
        </div>
      </CardHeader>
      <CardBody className="pt-3 pb-3 px-3">
        {
          !(loading || Object.keys(data).length) ?
          <StyledEmptyText>
            <p className="font-weight-500 text-white">
              There’s not enough data to display
            </p>
            <p className="font-sm text-white-75 mb-0">
              As soon as we will have ability to show
              your balance history, you`ll see it displayed here
            </p>
          </StyledEmptyText>
          : null
        }
        {
          loading ?
          <DashboardBalanceChartPreload width="100%" height="100%" />
          :
          <div className="chart-area h-100">
            <Line
              data={balanceHistory(data, "day", currency).data}
              options={balanceHistory(data, "day", currency).options}
            />
          </div>
        }
      </CardBody>
    </StyledChartCard>
  );
};

const StyledChartCard = styled(Card)`
  padding: 0;
  margin-bottom: 24px !important;
  border-radius: 8px !important;
  height: 338px;
`;

BalanceChartWidget.PreloaderContainer = styled.div`
  min-height: 262px;
`;

const StyledIcon = styled(BalanceIcon)`
  path {
    stroke: var(--info);
  }
`;

const StyledEmptyText = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default BalanceChartWidget;
