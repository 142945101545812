import React from "react";
import styled from "styled-components";
import { Button } from "reactstrap";

import BurgerMenuButton from "./BurgerMenuButton";
import { ReactComponent as ToggleIcon } from "assets/img/icons/hamburgerArrow.svg";

const SidebarToggle = ({ 
  expanded,
  toggleSidebar,
  className
}) => {
  return (
    <>
      <div className={`navbar-wrapper ${className}`}>
        <ToggleButton
          className="minimize-sidebar btn-just-icon"
          color="link"
          id="tooltip209599"
          onClick={toggleSidebar.bind(null, expanded)}
        >
          <ExpandIcon expanded={expanded} />
        </ToggleButton>
      </div>
      <BurgerMenuButton
        expanded={expanded}
        onClick={toggleSidebar.bind(null, expanded)}
      />
    </>
  );
};

const ExpandIcon = styled(ToggleIcon)`
  transition: .5s ease-out;
  ${(props) => !props.expanded ? "transform: rotate(3.142rad)" : ""}
`;

const ToggleButton = styled(Button)`
  background: transparent !important;
  &.btn:hover, &.btn:active, &.btn:focus, &.btn:active:focus, &.btn:active:hover {
    background: transparent !important;
    box-shadow: none !important;
  }
  &:hover {
    & svg path {
      stroke: rgba(255, 255, 255, 0.35);
    }
  }
`;

export default SidebarToggle;
