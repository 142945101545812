import React, { useState } from "react";

import SortTableHead from "components/SortTableHead";
import CustomScrollbars from "components/CustomScrollbars";
import StyledTable from "components/StyledTable";
import PaginationPanel from "components/PaginationPanel";
import ActiveMarketsTableRow from "./ActiveMarketsTableRow";
import {ACTIVE_MARKETS_HEAD} from "models/bot/tables";

const ActiveMarketsTable = ({
  items,
  request,
  handleSorting,
  total,
  page,
  handlePaginate,
  tableRef
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  if (!items?.length) return;

  return (
    <>
      <CustomScrollbars
        autoHideTimeout={500}
        autoHideDuration={500}
        autoHeight
        autoHeightMax={Number.MAX_VALUE}
      >
        <StyledTable
          className="mb-0"
          paddingsThead=" 0 1rem 1rem"
        >
          <SortTableHead
            handleSorting={handleSorting}
            sort={request?.sort}
            theadItems={ACTIVE_MARKETS_HEAD}
          />
          <tbody className="font-sm">
          {
            items?.map((item) =>
              <ActiveMarketsTableRow
                item={item}
                key={item.market.pair}
                setIsDisabled={setIsDisabled}
                isDisabled={isDisabled}
              />)
          }
          </tbody>
        </StyledTable>
      </CustomScrollbars>
      <div className={total > 10 ? "mt-3" : "mt-0"}>
        <PaginationPanel
          activePage={page}
          totalItemsCount={total}
          totalPagesView={request?.limit}
          onChange={(page) => handlePaginate(page)}
          className="m-0"
          elementRef={tableRef}
        />
      </div>
    </>
  );
};

export default ActiveMarketsTable;
