import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Card,
  Tooltip
} from "reactstrap";

import HeaderPreLoader from "components/HeaderPreLoader";
import IdWithCopy from "components/IdWithCopy";
import StatusIndicator from "components/StatusIndicator";
import DoubleIconsMarket from "components/DoubleIconsMarket";
import { ReactComponent as Clock } from "assets/img/icons/clock.svg";
import LineWithError from "shared/ui/LineWithError";

const AgreementsDetailsHeader = ({
  loading,
  id,
  status,
  error,
  createdAt,
  base,
  quote
}) => {
  const [tooltip, setTooltip] = useState(false);
  const toggle = () => setTooltip(prevState => !prevState);

  if (loading) return <HeaderPreLoader />;
  return (
    <Card >
      <div className="d-flex flex-column flex-md-row justify-content-between p-4">
        <div className="d-flex align-items-center">
          <DoubleIconsMarket
            base={base}
            quote={quote}
          />
          <div className="ml-3">
            <h1 className="mb-0 text-white">
              {base}{" "}
              <span className="text-white-35">
                / {quote}
              </span>
            </h1>
            <IdWithCopy
              id={id}
              className="d-none d-md-flex"
            />
          </div>
        </div>
        <div className="mt-3 mt-md-0 text-left text-md-right">
          <StatusIndicator
            status={status}
            margin="0"
            className="py-1"
          />
          <div className="d-flex align-items-center">
            <Clock
              id="createdAt"
              className="icon-stroke-gray mt-2 mr-1"
              aria-hidden="true"
              cursor={status === "pending"?"pointer":"auto"}
            />
            <p className="text-white-35 font-sm mt-2 mb-0">
              {moment(createdAt).format("DD.MM.YYYY HH:mm")}
            </p>
          </div>
          {
            status === "pending"
              ? <Tooltip
                placement="top"
                isOpen={tooltip}
                target="createdAt"
                toggle={toggle}
              >
                This is the date when this signal was accepted.
              </Tooltip>
              : null
          }
        </div>
      </div>
      <LineWithError errorMessage={error}/>
    </Card>
  );
};

AgreementsDetailsHeader.propTypes = {
  loading: PropTypes.bool,
  id: PropTypes.string,
  status: PropTypes.string,
  createdAt: PropTypes.string,
  base: PropTypes.string,
  quote: PropTypes.string
};

export default AgreementsDetailsHeader;
