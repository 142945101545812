import { Card } from "reactstrap";
import styled from "styled-components";

export const StyledWrapper = styled(Card)`
  .order-book__filter {
    width: 209px !important;
  }

  @media (max-width: 576px) {
    .order-book__filter {
      width: 100% !important;
    }
  }
`;

export const StyledHeader = styled.div`
  padding: 0.3rem 0.75rem;
  display: grid;
  grid-template-columns: ${(props) => props.withSum ? "1fr 1fr 1fr 1fr" : "1fr 1fr 1fr"};

  & :last-child {
    display: ${(props) => props.withSum ? "block" : "none"};
  }

  @media (max-width: 576px) {
    display: ${(props) => props.type === "asks" ? "none" : "grid"}
    grid-template-columns: 1fr 1fr 1fr !important;

    & :last-child {
      display: none;
    }
  }

  @media (min-width: 1920px) {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;

    & :last-child {
      display: block;
    }
  }
`;

export const StyledLabel = styled.label`
  font-size: 0.875rem !important;
  margin-bottom: 6px;
`;

export const StyledList = styled.div`
  padding: 0.3rem 0.75rem;
  overflow: hidden;
  cursor: pointer;
  line-height: 19px;
  background: #2A2C42;
  display: grid;
  grid-template-columns: ${(props) => props.withSum ? "1fr 1fr 1fr 1fr" : "1fr 1fr 1fr"};

  & .sum-cell {
    display: ${(props) => props.withSum ? "block" : "none"};
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr 1fr 1fr !important;

    & .sum-cell {
      display: none !important;
    }
  }

  @media (min-width: 1920px) {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;

    & .sum-cell {
      display: block !important;
    }
  }

  &.bids {
    color: #FFF;

    & .price-bids {
      color: #3FCBB0;
    }

    &:hover {
      background-color: #3FCBB0;
      color: #1D1E2A;
      font-weight: 500;

      & .price-bids {
        color: #1D1E2A;
      }
    }
  }

  &.asks {
    color: #FFF;

    & .price-asks {
      color: #ED5F5F;
    }

    &:hover {
      background-color: #ED5F5F;
      color: #FFF;
      font-weight: 500;

      & .price-asks {
        color: #FFF
      }
    }
  }

  & span:first-child {
    flex: 0.5;
  }

  & span {
    flex: 1;
  }
`;

export const StyledProgressBar = styled.div`
  position: absolute;
  ${
    (props) => props.type === "bids" ?
    "left: 100%;"
    :
    "right: 100%"
  }

  @media (max-width: 576px) {
    left: 100%;
  }

  top: 0;
  bottom: 0;
  width: 100%;
  will-change: transform;
  transform: translateX(${(props) => props.percent }%);
  background-color: ${(props) => props.type === "bids" ? "rgba(63, 203, 176, 0.11)" : "rgba(237, 95, 95, 0.11)"};
`;
