import React from "react";
import {
  ModalBody,
  Modal
} from "reactstrap";

import CreateBacktestForm from "../forms/CreateBacktestForm";

const CreateBacktestModal = ({
  onSetModalIsOpen,
  modalIsOpen,
  id
}) => {
  return(
    <Modal
      isOpen={modalIsOpen}
      toggle={() => onSetModalIsOpen(!modalIsOpen)}
      modalClassName="modal-black"
    >
      <ModalBody>
        <p className="mt-2 font-weight-bold text-center h3 modal-title">
          Run new backtest
        </p>
        <CreateBacktestForm
          onSetModalIsOpen={onSetModalIsOpen}
          id={id}
        />
      </ModalBody>
    </Modal>
  );
};

export default CreateBacktestModal;
