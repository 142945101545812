import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as Plus } from "assets/img/icons/plus.svg";
import { ReactComponent as Minus } from "assets/img/icons/minus.svg";

const CounterInput = ({ onChange, min=0, max=10, value=0, className, ...props}) => {
  const [counterValue, setCounterValue] = useState(value);

  const handleIncreasePower = () => {
    setCounterValue(prevState => {
      if (prevState === max) return prevState;
      onChange(prevState + 1);
      return prevState + 1;
    });
  };

  const handleDecreasePower = () => {
    setCounterValue(prevState => {
      if (prevState === min) return prevState;
      onChange(prevState - 1);
      return prevState - 1;
    });
  };

  return (
    <StyledCounter
      className={`d-inline-block ${className}`}
      {...props}
    >
      <div className="d-flex align-items-center">
        <button
          className="btn btn-reset px-2 d-inline-block"
          onClick={handleDecreasePower}
          aria-label="minus"
        >
          <Minus width={11} height={11} />
        </button>
        <p className="mb-0 text-white font-md px-2">
          {counterValue}
        </p>
        <button
          className="btn btn-reset px-2"
          onClick={handleIncreasePower}
          aria-label="plus"
        >
          <Plus width={11} height={11}/>
        </button>
      </div>
    </StyledCounter>
  );
};

const StyledCounter = styled.div`
  background: #1C1C1E;
  border-radius: 8px;
  margin-left: auto;

  & > div {
    height: 35px;
  }

  .line {
    color: rgba(43, 43, 44, 0.6);
    border-radius: 2px;
  }

  input {
    background: rgba(148, 158, 255, 0.06);
    border: 1px solid #3E4D7A;
    border-radius: 2px;
  }

  button {
    max-width: 34px;
  }

  svg path {
    fill: var(--info);
  }

  p {
    line-height: 22px;
    border: 1px solid rgba(43, 43, 44, 0.6);
    border-width: 0 1px;
  }
`;

export default CounterInput;
