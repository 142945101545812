import React, {
  useEffect,
  useState
} from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import styled from "styled-components";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col
} from "reactstrap";

import Error from "components/Error";
import {
  getNotificatinsCodes,
  toggleNotification,
  enableAllNotification,
} from "store/notificationsCodes/actions";
import { notificationsSelector } from "store/notificationsCodes/selectors";
import CustomScrollbars from "components/CustomScrollbars";
import NotificationsItem from "./NotificationsItem";
import UserNotificationsPreloader from "../preloaders/UserNotificationsPreloader";
import NotificationNav from "./NotificationNav";
import { addSnackBar } from "store/snackbar/actions";
import Button from "components/Button";

const NotificationTypes = ({ language }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeType, setActiveType] = useState("deals");
  const [messageId, setMessageId] = useState("");

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.notificationsCodes.loading);
  const disabled = useSelector((state) => state.notificationsCodes.disabled);
  const error = useSelector((state) => state.notificationsCodes.error);

  const TradeRequestNotifications = useSelector((state) => notificationsSelector(state, language, "trade-request"));
  const DealsNotifications = useSelector((state) => notificationsSelector(state, language, "deal"));
  const StrategiesNotifications = useSelector((state) => notificationsSelector(state, language, "strategy"));
  const CredentialsNotifications = useSelector((state) => notificationsSelector(state, language, "credentials"));
  const SecurityNotifications = useSelector((state) => notificationsSelector(state, language, "security"));
  const TrailingNotifications = useSelector((state) => notificationsSelector(state, language, "trailing"));
  const BacktestNotifications = useSelector((state) => notificationsSelector(state, language, "backtest"));
  const SignalNotifications = useSelector((state) => notificationsSelector(state, language, "signal"));

  const types = {
    deals: DealsNotifications,
    tradeRequest: TradeRequestNotifications,
    strategies: StrategiesNotifications,
    credentials: CredentialsNotifications,
    security: SecurityNotifications,
    trailing: TrailingNotifications,
    backtests: BacktestNotifications,
    signal: SignalNotifications
  };

  const getNavItems = () => {
    return Object.keys(types)
      .filter((key) => types[key]?.length)
      .map((key) => ({ label: key.replace(/([a-z0-9])([A-Z])/g, '$1 $2'), name: key }));
  };

  useEffect(() => {
    dispatch(getNotificatinsCodes());
  }, [dispatch]);

  const handleToggle = async () => {
    setIsLoading(true);
    const res = await dispatch(enableAllNotification());
    if (res.errors) {
      setIsLoading(false);
      return dispatch(addSnackBar("error", res.message));
    } else {
      setIsLoading(false);
      return dispatch(addSnackBar("success", res.message));
    }
  };

  if (loading) return <UserNotificationsPreloader />;

  return (
    <Card>
      <CardHeader className="d-flex flex-column flex-sm-row justify-content-between p-4">
        <h2 className="mb-0 font-weight-500 text-white text-nowrap">
          Notification types
        </h2>
        <Button
          className="mt-2 mt-sm-0 px-sm"
          onClick={handleToggle}
          disabled={isLoading}
        >
          Enable all messages
        </Button>
      </CardHeader>

      <CardBody className="p-0">
        <Row className="no-gutters p-md-4">
          <Col xs="12" sm="3">
            <NotificationNav
              activeType={activeType}
              setActiveType={setActiveType}
              navItems={getNavItems()}
            />
          </Col>
          <Col xs="12" sm="9">
            <NotificationTypes.NotificationsList className="w-100">
              <CustomScrollbars
                autoHide
                autoHideTimeout={500}
                autoHideDuration={200}
                style={{ height: "556px" }}
              >
                {error ? <Error error={error} /> : null}
                {
                  types[activeType] && types[activeType].map((item) =>
                    <NotificationsItem
                      item={item}
                      key={item.message}
                      onToggleNotification={toggleNotification}
                      disabled={disabled}
                      messageId={messageId}
                      setMessageId={setMessageId}
                      isLoading={isLoading}
                    />
                  )
                }
              </CustomScrollbars>
            </NotificationTypes.NotificationsList>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

NotificationTypes.NotificationsList = styled.div`
  background: #2E3148;
  border-radius: 8px;
`;

export default NotificationTypes;
