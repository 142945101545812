export const GET_ME_LOADING = "GET_ME_LOADING";
export const GET_ME_SUCCESS = "GET_ME_SUCCESS";
export const GET_ME_FAILURE = "GET_ME_FAILURE";
export const RESET_ME = "RESET_ME";
export const GET_EMAIL = "GET_EMAIL";
export const GET_AVATAR = "GET_AVATAR";
export const GET_AVATAR_LOADING = "GET_AVATAR_LOADING";
export const GET_AVATAR_SUCCESS = "GET_AVATAR_SUCCESS";
export const GET_AVATAR_FAILURE = "GET_DELETE_AVATAR_SUCCESS";
export const VIEW_NOTIFICATION = "VIEW_NOTIFICATION";
export const DISCONNECT_TELEGRAM = "DISCONNECT_TELEGRAM";
export const CONNECT_TELEGRAM = "CONNECT_TELEGRAM";
export const VIEW_ALL_NOTIFICATIONS = "VIEW_ALL_NOTIFICATIONS";
export const SET_UNREAD_NOTIFICATIONS = "SET_UNREAD_NOTIFICATIONS";
export const UPDATE_BETA_SETTINGS = "UPDATE_BETA_SETTINGS";
export const CONNECT_GOOGLE_ACCOUNT = "CONNECT_GOOGLE_ACCOUNT";
export const DISCONNECT_GOOGLE_ACCOUNT = "DISCONNECT_GOOGLE_ACCOUNT";
export const SET_BALANCE = "SET_BALANCE";
export const SET_EMAIL = "SET_EMAIL";
export const DELETE_AVATAR_SUCCESS = "DELETE_AVATAR_SUCCESS";
export const DELETE_AVATAR_LOADING = "DELETE_AVATAR_LOADING";
export const SKIP_WIZARD = "SKIP_WIZARD";
export const RECORD_CREDENTIALS_TO_WIZARD = "RECORD_CREDENTIALS_TO_WIZARD";
export const RECORD_BOTS_TO_WIZARD = "RECORD_BOTS_TO_WIZARD";
export const UPDATE_WIZARD = "UPDATE_WIZARD";
export const GENERATE_REF_CODE = "GENERATE_REF_CODE";
export const GET_USERS_REFERRALS_LOADING = "GET_USERS_REFERRALS_LOADING";
export const GET_USERS_REFERRALS = "GET_USERS_REFERRALS";
export const GET_REFERRALS_STATISTICS = "GET_REFERRALS_STATISTICS";
export const GET_REFERRALS_STATISTICS_LOADING = "GET_REFERRALS_STATISTICS_LOADING";
