import React from "react";
import styled from "styled-components";

import Loading from "./Loading";

const PageOverlay = () => {
  return (
    <Overlay className="d-flex flex-column justify-content-center align-items-center">
      <Loading
        color="var(--info)"
        size={64}
      />
      <p className="h1 font-weight-500 mt-3 mb-0">
        Please wait...
      </p>
      <Text className="text-white-75 text-center">
        {"We're redirecting you to the integrated Stripe® billing page"}
      </Text>
    </Overlay>
  );
};

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(11, 13, 25, 0.9);
  z-index: 9999;
  backdrop-filter: blur(5.5px);
`;

const Text = styled.p`
  width: 217px;
  margin-top: 2rem;
`;

export default PageOverlay;
