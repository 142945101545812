import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const NewTabLinkButton = ({
  url,
  className="",
  children
}) => {
  const linkRef = useRef(null);
  
  if (!url) return; 

  const handleClick = () => {
    if (linkRef.current) {
      linkRef.current.click();
    }
  };

  return (
    <Link
      data-testid="new-tab-link"
      onClick={handleClick}
      ref={linkRef}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Open in new tab"
      className={className}
      >
      {children}
    </Link>
  );
};

NewTabLinkButton.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const Link = styled.a`
  &:hover{
    opacity: 0.5;
  }
`;

export default NewTabLinkButton;
