import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Button,
  CardBody,
  Card
} from "reactstrap";
import { getCurrentImageSrc, initialStrategyImage } from "../utils/defaultImages";
import Loading from "components/Loading";
import ReactBSAlert from "react-bootstrap-sweetalert";
import SkeletonPreloader from "components/SkeletonPreloader";

const StrategyImageEdit = ({
  strategy,
  postStrategyImage,
  deleteStrategyImage,
  loading
}) => {

  const [strategyImage, setStrategyImage] = useState(initialStrategyImage);
  const [isUploading, setIsUploading] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    if (strategy.images) {
      setStrategyImage(getCurrentImageSrc(strategy));
    }
  }, [strategy]);

  const handleInputFileChange = async (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage && strategy.uuid) {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", selectedImage, selectedImage.name);
      const res = await postStrategyImage(strategy.uuid, formData);
      setAlert(
        <ReactBSAlert
          success={!res.errors}
          warning={res.errors}
          title={res.message}
          onConfirm={() => setAlert(null)}
          confirmBtnBsStyle="success"
          confirmBtnText="OK"
        />);
      if (res.errors) {
        setIsUploading(false);
      };
    };
  };

  const handleDeleteImageClick = async () => {
    setIsUploading(true);
    const res = await deleteStrategyImage(strategy.uuid);
    setAlert(
      <ReactBSAlert
        success={!res.errors}
        warning={res.errors}
        title={res.message}
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="success"
        confirmBtnText="OK"
      />);
    if (res.errors) {
      setIsUploading(false);
    };
  };

  if (loading) {
    return (
      <Card style={{ height: "191px" }}>
        <CardBody className="d-flex justify-content-center flex-column">
          <div className="d-flex justify-content-center">
            <StrategyImageEdit.ButtonPreLoader
              className="p-2 d-flex justify-content-center flex-column"
            >
              <SkeletonPreloader
                circle={true}
                height={18}
                width={18}
              />
            </StrategyImageEdit.ButtonPreLoader>
            <StrategyImageEdit.ButtonPreLoader
              className="p-2 d-flex justify-content-center flex-column ml-2"
            >
              <SkeletonPreloader
                circle={true}
                height={18}
                width={18}
              />
            </StrategyImageEdit.ButtonPreLoader>
            <StrategyImageEdit.ButtonPreLoader
              className="p-2 d-flex justify-content-center flex-column ml-2"
            >
              <SkeletonPreloader
                height={12}
                width={82}
              />
            </StrategyImageEdit.ButtonPreLoader>
          </div>
        </CardBody>
      </Card>
    );
  }

  return (
    <StrategyImageEdit.Card>
      <StrategyImageEdit.Image
        src={strategyImage.image}
        onLoad={() => setIsUploading(false)}
        alt="strategy logo"
      />
    {isUploading ?
        <StrategyImageEdit.LoadingContainer className="d-flex justify-content-center flex-column">
          <Loading />
        </StrategyImageEdit.LoadingContainer> :
        <StrategyImageEdit.Wrapper>
          <label htmlFor="input-file" className="btn btn-default btn-sm">
            <i className="tim-icons icon-cloud-upload-94" />
            <StrategyImageEdit.FileInput
              onChange={handleInputFileChange}
              value={""}
              type="file"
              name="file"
              id="input-file" />
          </label>
          {!strategyImage.isDefault &&
            <Button
              size="sm"
              color="default"
              onClick={handleDeleteImageClick}
            >
              <i className="tim-icons icon-trash-simple" />
            </Button>}
        </StrategyImageEdit.Wrapper>
      }
      {alert}
    </StrategyImageEdit.Card>
  );
};

StrategyImageEdit.Card = styled.div`
  position: relative;
  height: 191px;
  margin-bottom: 30px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1);
  &:hover :nth-child(2) {
    visibility: visible;
  }
}
`;

StrategyImageEdit.Image = styled.img`
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

StrategyImageEdit.LoadingContainer = styled.div`
  background-color: rgba(39, 41, 61, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

StrategyImageEdit.Wrapper = styled.div`
  position: absolute;
  z-index: 10;
  visibility: hidden;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;

StrategyImageEdit.FileInput = styled.input`
  display: none;
`;

StrategyImageEdit.ButtonPreLoader = styled.div`
  background: rgba(83, 84, 98, 0.2);
  border-radius: 4px;
`;

export default StrategyImageEdit;
