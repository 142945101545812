import React from "react";
import { Card } from "reactstrap";
import SkeletonPreloader from "components/SkeletonPreloader";

const BetaFeaturesPreloader = () => {
  return (
    <Card className="p-3">
      <div>
        <SkeletonPreloader
          height={20}
          width={150}
          className="mb-3"
        />
        <SkeletonPreloader
          height={12}
          width={300}
          count={2}
        />
      </div>
    </Card>
  );
};
export default BetaFeaturesPreloader;
