import React, { useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

import ChannelSubscriptionForm from "./ChannelSubscriptionForm";
import { addSnackBar } from "store/snackbar/actions";

const validationSchema = Yup.object().shape({
  volume: Yup.number().required('This field is required'),
  maximum: Yup.number().required('This field is required')
});

const CreateChannelSetSettings = ({
  channelId,
  handleToggleModal,
  handleRefresh,
  isList,
  onSubscribeChannel
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsSubmitted(true);

    const subscriptionSettings = {
      ...values,
      accept: values.accept ? "automatic" : "manual"
    };

    const res = await dispatch(onSubscribeChannel(channelId, isList, subscriptionSettings));

    if (!res.errors) {
      dispatch(addSnackBar("success", res.message));
      handleToggleModal();
      handleRefresh();
    }
    setErrorMessage(res.message);
    setErrors(res.message);
    setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        volume: 5,
        maximum: 10,
        accept: "automatic"
      }}
      onSubmit={handleSubmit}
    >
      {() =>
        <ChannelSubscriptionForm
          isSubmitted={isSubmitted}
          setErrorMessage={setErrorMessage}
          errorMessage={errorMessage}
          handleToggleModal={handleToggleModal}
          setIsSubmitted={setIsSubmitted}
          titleSubmitButton="Subscribe"
        />
      }
    </Formik>
  );
};

CreateChannelSetSettings.propTypes = {
  channelId: PropTypes.string,
  handleToggleModal: PropTypes.func,
  handleRefresh: PropTypes.func,
};

export default CreateChannelSetSettings;