import createAction from "store/thunkFactory";

import {
  RESET_MARKETS,
  RESET_TOP_MARKETS,
  RESET_AVAILABLE_MARKETS,
} from "./constants";

export const getMarkets = (exchange, signal) => createAction("MARKETS", (fetch) => {
  return fetch.get(`/app/exchanges/${exchange}/markets`, { signal });
});

export const getTopMarkets = (id, filters, signal) => createAction("TOP_MARKETS", (fetch) => {
  return fetch.get(`/app/credentials/${id}/top-markets${filters || ""}`, { signal });
});

export const getAvailableMarkets = (id, filters) => createAction("AVAILABLE_MARKETS", (fetch) => {
  return fetch.get(`/app/investments/${id}/available-markets${filters || ""}`);
});

export const resetMarkets = () => ({ type: RESET_MARKETS });

export const resetTopMarkets = () => ({ type: RESET_TOP_MARKETS });

export const resetAvailable = () => ({ type: RESET_AVAILABLE_MARKETS });
