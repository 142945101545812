export const TABLE_HEAD = [
  { name: "fitnessScore", label: "Fitness score", isSortable: true },
  { name: "status", label: "Status", isSortable: true },
  { name: "mutated", label: "Mutated", isSortable: true },
  { name: "genesis", label: "Genesis", isSortable: true },
  { name: "cloned", label: "Cloned", isSortable: true },
  { name: "position", label: "Position", isSortable: true },
  { name: "selected", label: "Selected", isSortable: true },
  { name: "computedAt", label: "Computed at", isSortable: true },
];

export const EMPTY_SEARCH_RESULT_TEXT = {
  title: "No individuals here yet",
  desc: "We have not found anything by your request, please adjust your filters."
};

export const EMPTY_STATE_TEXT = {
  title: "No individuals here yet",
  desc: "You’ll see them displayed here as soon as this Genetic Algorithm will have some individuals"
};
