/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, 
  useLayoutEffect,
  useState
} from "react";
import styled from "styled-components";
import {useDispatch} from "react-redux";

import Investments from "./wizard/Investments/Investments";
import Markets from "./wizard/Markets/Markets";
import Wizard from "./wizard/Wizard";
import {paginationScrollUp} from "helpers/paginationScrollUp";
import Configuration from "./wizard/Configuration";
import {
  setBotName,
  setInvestment,
  setResetBotWizard
} from "store/bots/wizard/actions";

const CreateBot = ({ location }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [highestStep, setHighestStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [credentialId, setCredentialId] = useState("");

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    return () => {
      dispatch(setBotName(""));
      dispatch(setResetBotWizard());
      dispatch(setInvestment({}))
    };
  }, []);

  useEffect(() => {
    paginationScrollUp();
  }, [currentStep]);

  useEffect(() => {
    window.analytics.page("/bots/create");
  }, []);

  return (
    <CreateBot.Wrapper>
      <div className="my-4">
        <h1 className="font-weight-500 mb-2 text-white">Create a bot</h1>
        <p className="mb-0 text-white-35 font-md">Create your bot and connect it to your credentials for our system to start the auto-trading</p>
      </div>
        <Wizard
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          highestStep={highestStep}
          setHighestStep={setHighestStep}
          isSubmit={isSubmit}
          steps={[
            {
              component: Investments,
              name: "Credentials",
              finishedText: true,
              props: {
                location,
                setCredentialId
              }
            },
            {
              component: Markets,
              name: "Market",
              finishedText: true,
              props: {
                location,
                setLoading,
                currentStep,
                credentialId,
              }
            },
            {
              component: Configuration,
              name: "Configuration",
              finishedText: true,
              props: {
                setIsSubmit,
                currentStep,
                loadingWizard: loading,
              }
            },
          ]}
        />
    </CreateBot.Wrapper>
  );
};

CreateBot.Wrapper = styled.div`
  .create-bot__intro {
    h3 {
      font-size: 1.5rem;
    }
  }
  padding: 0 0 0 10px;
`

export default CreateBot;
