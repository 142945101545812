import React from "react";

import ErrorAlert from "components/ErrorAlert";
import FinishBotModal from "./FinishBotModal";

const WizardErrors = ({
  errors={},
  status={},
  alertOpen,
  redirectPath="/",
  onSetAlertOpen,
  setIsEdit,
  isEdit,
  setErrors
}) => {
  return (
    <>
      <ErrorAlert
        fade={false}
        toggle={() => setErrors({ ...errors, message: "" })}
        isOpen={!!errors.message}
      >
        {errors.message}
      </ErrorAlert>
      {
        alertOpen &&
          <FinishBotModal
            onSetAlertOpen={onSetAlertOpen}
            title={status.message}
            setIsEdit={setIsEdit}
            isEdit={isEdit}
            alertOpen={alertOpen}
            redirectPath={redirectPath}
          />
      }
    </>
  );
};

export default WizardErrors;
