import React from "react";
import styled from "styled-components";
import empty from "assets/img/orders-table.svg"
import { CardBody } from "reactstrap";

const MarketBotsEmpty = () => {
  return (
    <MarketBotsEmpty.Card>
      <img src={empty} alt=""/>
      <h3 className="my-2 text-white">You have no market bots at the moment</h3>
      <p className="text-white-75 mb-3 font-sm">Please create some to see them displayed here</p>
    </MarketBotsEmpty.Card>
  )
}

MarketBotsEmpty.Card = styled(CardBody)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #2E3148 !important;
  border-radius: 0 0 8px 8px !important;
  img {
    width: 129px;
  }
`;

export default MarketBotsEmpty;
