import React, { useState } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

import LineWithError from "shared/ui/LineWithError";
import DealBody from "./DealBody";
import DealOpenTrade from "../DealOpenTrade";
import TradeRequestsDetailsModal from "shared/ui/TradeRequests/TradeRequestsDetailsModal";

import { DealCardStyled } from "./index.styles";

const Deal = ({
  data,
  index,
  content
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (data?.bot?.investment) {
    return (
      <>
        <DealCardStyled
          className={classnames("mb-2", {
            "ready": data.status === "ready",
            "running": data.status === "running",
            "completed": data.status === "completed",
            "cancelled": data.status === "cancelled",
          })}
          status={data.bot.investment.credentials.status}
          error={data.error}
          data-testid="deal-card"
        >
          <Link
            to={`/deals/${data.id}`}
            key={data.id}
          >
            <DealBody
              data={data}
              index={index}
              content={content}
            />
          </Link>
          <DealOpenTrade
            setIsModalOpen={setIsModalOpen}
            content={content.openTrade}
            data={data?.openTradeRequest}
          />
        </DealCardStyled>
        {
          data.error &&
          <div
            className="font-sm position-relative"
            style={{ top: "-8px" }}
          >
            <LineWithError
              errorMessage={data?.error}
              dataTestId="deal-card"
            />
          </div>
        }
        {
          isModalOpen &&
          <TradeRequestsDetailsModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            modalOpenItem={data?.openTradeRequest}
            isManual={data?.forceStarted}
          />
        }
      </>
    );
  }
};

export default Deal;
