import React, { useState } from "react";
import { CardHeader } from "reactstrap";

import Checkbox from "components/Checkbox";
import Button from "components/Button";
import { ReactComponent as Allocation } from "assets/img/AssetAllocation.svg";
import { CreateCredentialsModal } from "shared/ui";

const AllocationHeader = (
  {
    assets,
    filteredAssets,
    hideEmpty,
    setHideEmpty,
    setHoverredElemId,
    hoverredElemId,
    oneOfEmpty,
    content
  }
) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleHideEmpty = () => {
    setHideEmpty(prevState => !prevState);
    setHoverredElemId(hoverredElemId === null ? -1 : null);
  };

  return (
    <>
      <CardHeader className="d-flex flex-md-column flex-lg-row align-items-lg-center justify-content-between p-4 pb-4">
        <div className="d-flex flex-wrap">
          <div className="d-flex align-items-center mr-2 mb-1 mb-lg-0">
            <Allocation />
            <p className="mb-0 ml-2 text-white font-weight-500 text-nowrap">
              {content.title}
            </p>
          </div>
          {
            (filteredAssets.length > 0 || assets.length > 0) && oneOfEmpty &&
              <Checkbox
                name="hideEmpty"
                value={hideEmpty}
                checked={hideEmpty}
                onChange={() => handleHideEmpty()}
                labelLeft={
                  <span className="mr-2 d-block font-weight-300 text-white-75">
                    {content.checkbox}
                  </span>
                }
              />
          }
        </div>
        <Button
          className="px-3 mt-2 mt-lg-0"
          color="blue"
          onClick={() => setIsModalOpen(true)}>
          {content.button}
        </Button>
      </CardHeader>
      <CreateCredentialsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  )
}

export default AllocationHeader;
