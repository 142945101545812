export const translatePP = (value="") => {
  if (value === "PP") return 0;
  return value?.replace("S", "-")?.replace("R", "");
};

export const getPP = (values) => {
  const pivotPoint = { ...values };
  for (const key of Object.keys(pivotPoint)) {
    pivotPoint[key] = translatePP(pivotPoint[key]);
  }
  return pivotPoint;
};