import React from "react";
import styled from "styled-components";
import ordersTable from "assets/img/orders-table.svg";
import { Table } from "reactstrap";

const EmptyTables = ({thead, title, description, emptyFilter}) => {
  return (
    <>
      <EmptyTables.Table>
        {
          thead ?
          <EmptyTables.TableHeader className="d-none d-md-block">
            <tr>
              {
                thead.map((th) => {
                  return (
                    <th key={th}>
                      <EmptyTables.Titles>{th}</EmptyTables.Titles>
                    </th>
                  )
                })
              }
            </tr>
          </EmptyTables.TableHeader> : null
        }
      </EmptyTables.Table>
      <EmptyTables.EmptyBox className="d-flex flex-column align-items-center">
        <div>
          <img src={ordersTable} alt=""/>
        </div>
        {
          emptyFilter !== null ?
            <>
              <h3 className="mb-0 text-white font-weight-500 mt-3 mx-4 mx-md-0">No results for your search</h3>
              <EmptyTables.Description className="mx-4 mx-md-0">
                You may want to try adjusting your filters or checking the data you enter.
              </EmptyTables.Description>
            </>
            :
            <>
              <h3 className="mb-0 text-white text-center font-weight-500 mt-3 mx-4 mx-md-0">
                {title}
              </h3>
              <EmptyTables.Description className="text-center mx-4 mx-md-0">
                {description}
              </EmptyTables.Description>
            </>
        }
      </EmptyTables.EmptyBox>
    </>
  )
};

EmptyTables.Table = styled(Table)`
  margin-bottom: 0 !important;
  background: #2E3148;
  border-radius: 8px;
`;

EmptyTables.TableHeader = styled.thead`
  background: #2E3148;
  border-radius: 4px;

  th {
    text-transform: capitalize !important;
    padding: 24px 7px !important;
  }

  th:first-child {
    border-radius: 8px 0 0 0 !important;
    padding-left: 24px !important;
  }
  th:last-child {
    border-radius: 0 8px 0 0 !important;
  }
`;

EmptyTables.EmptyBox = styled.div`
  background-color: var(--dark-slate);
  border-radius: 0 0 8px 8px;
  width: 100%;
  padding: 155px 0;
`;

EmptyTables.Description = styled.p`
  color: rgba(255,255,255, 0.75) !important;
  font-size: 14px;
  margin: 8px 0 0 0;
`;

EmptyTables.Titles = styled.p`
  color: rgba(255, 255, 255, 0.35) !important;
  font-size: 1rem !important;
  font-weight: 300 !important;
  margin-bottom: 0;
  padding: 0;
  white-space: nowrap;
`;

export default EmptyTables;
