import React from "react";
import styled from "styled-components";
import { FieldArray } from "formik";
import PropTypes from "prop-types";

import Button from "components/Button";
import TargetSignal from "./TargetSignal";
import { ReactComponent as AddIcon } from "assets/img/icons/add-icon-border-none.svg"
import { calcTargetPercent } from "../utils";

const TargetInputBlock = ({
  targets,
  buyTo,
  errors,
  isSubmitted,
  isHasChannels
}) => (
  <>
    <p className="font-weight-500 mt-4 mb-2">Targets</p>
    <FieldArray name="targets">
      {arrayHelpers => (
        <>
          {targets && targets.length > 0 ? (
            targets.map((_, index) => (
              <TargetSignal
                key={index}
                label={`T${index + 1}`}
                name={`targets[${index}]`}
                isSubmitted={isSubmitted}
                errors={errors}
                arrayHelpers={arrayHelpers}
                value={[index]}
                index={index}
                isHasChannels={isHasChannels}
                targetPercent={calcTargetPercent(buyTo, targets[index].rate)}
              />
            ))
          ) : null}
          {targets.length < 5 && (
            <TargetInputBlock.Button
              type="button"
              outline
              addHover={!isHasChannels}
              disabled={isHasChannels}
              className="col-10 col-md-11"
              onClick={() => arrayHelpers.push("")}
            >
              <AddIcon />
              <span className="font-md text-white-35 font-weight-400 ml-2">
                Add more
              </span>
            </TargetInputBlock.Button>
          )}
        </>
      )}
    </FieldArray>
  </>
);

TargetInputBlock.Button = styled(Button)`
  &.gray-outline{
    border: 1px dashed var(--white-10, rgba(255, 255, 255, 0.10)) !important;
  }

  ${props => props.addHover && `
    &:hover {
      span {
        color: var(--info) !important;
      }

      svg {
        path{
          stroke: var(--info);
          stroke-opacity:1;
        }
      }
    }`
  }
`;

TargetInputBlock.propTypes = {
  targets: PropTypes.arrayOf(PropTypes.number),
  buyTo: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  errors: PropTypes.shape({
    buyFrom: PropTypes.string,
    buyTo: PropTypes.string,
    stopLoss: PropTypes.string,
  }),
  isSubmitted: PropTypes.bool,
  isHasChannels: PropTypes.bool
};

export default TargetInputBlock;