import SkeletonPreloader from "components/SkeletonPreloader";

import styled from "styled-components";
import { Card } from "reactstrap";

const SpotWalletPreloader = () => {
  return (
    <div className="d-flex">
      <SpotWalletPreloader.Card className="d-flex mr-2 flex-row align-items-center">
        <SkeletonPreloader
          circle
          height={40}
          width={40}
          className="m-2"
        />
        <SkeletonPreloader
          height={10}
          width={100}
        />
      </SpotWalletPreloader.Card>
      <SpotWalletPreloader.Card className="d-flex mr-2 flex-row align-items-center">
        <SkeletonPreloader
          circle
          height={40}
          width={40}
          className="m-2"
        />
        <SkeletonPreloader
          height={10}
          width={100}
        />
      </SpotWalletPreloader.Card>
      <SpotWalletPreloader.Card className="d-flex flex-row align-items-center">
        <SkeletonPreloader
          circle
          height={40}
          width={40}
          className="m-2"
        />
        <SkeletonPreloader
          height={10}
          width={100}
        />
      </SpotWalletPreloader.Card>
    </div>
  )
}

SpotWalletPreloader.Card = styled(Card)`
  background: #363A54 !important;
`;

export default SpotWalletPreloader;
