import illustration1 from "assets/img/strategiesDefault/illustration4_1.png";
import illustration2 from "assets/img/strategiesDefault/illustration4_2.png";
import illustration3 from "assets/img/strategiesDefault/illustration4_3.png";
import illustration4 from "assets/img/strategiesDefault/illustration4_4.png";
import illustration5 from "assets/img/strategiesDefault/illustration4_5.png";

export const defaultImages = {
  1: illustration1,
  2: illustration2,
  3: illustration3,
  4: illustration4,
  5: illustration5
};

export const initialStrategyImage = {
  image: "",
  isDefault: true
};

export const getCurrentImageSrc = (strategy) =>
  strategy.images.original !== null ?
  {
    image: strategy.images.original,
    isDefault: false
  } :
  {
    image: defaultImages[strategy.images.default],
    isDefault: true
  };
