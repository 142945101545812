import { get } from "lodash";
import {
  GET_DEPOSIT_ADDRESS,
  GET_DEPOSIT_AND_WITHDRAWAL_HISTORY
 } from "./constants";

export const getDepositAddress = () => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.get("/app/deposit/address");
    dispatch({ type: GET_DEPOSIT_ADDRESS, payload: res.data });
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const fetchHistory = () => (dispatch, getState, fetch) => {
  try {
    const res =
    [
      {
        "uuid": 1213,
        "type": "Withdrawal",
        "amount": "$ 456",
        "address": "Lorem",
        "txid": "txid",
        "status": "Lorem",
        "createdAt": "12.08.2019 23:00"
      },
      {
        "uuid": 1323,
        "type": "Withdrawal",
        "amount": "$ 486",
        "address": "Lorem",
        "txid": "txid",
        "status": "Lorem",
        "createdAt": "12.08.2019 23:00"
      }
    ];

    dispatch({ type: GET_DEPOSIT_AND_WITHDRAWAL_HISTORY, payload: res });
  } catch (e) {
    return get(e, "response.data", { message: e.message });
  }
};
