import React from "react";

import Emoji from "components/Emoji";
import { Row } from "./StyledFeatures";

const FeaturesTableRow = ({ feature }) => {
  const getPlanFeatureValue = (plan) => {
    if (!feature[plan]) return <i className="tim-icons icon-simple-remove text-danger" />;
    if (typeof feature[plan] === "boolean") return <i className="tim-icons icon-check-2 text-success" />;
    return (
      <p className="h3 text-white font-weight-400 m-0">
        {feature[plan]}
      </p>
    );
  };

  return (
    <Row className={`py-3${feature.cuttingEdge ? " cuttingEdge" : ""}`}>
      <p className="h3 text-white font-weight-400 m-0">
        {feature.name}
        {
          feature.cuttingEdge ?
          <Emoji
            symbol="⚡️"
            size={18}
            className="ml-2"
          /> : null
        }
      </p>
      <div className="text-center">
        {getPlanFeatureValue("free")}
      </div>
      <div className="text-center">
        {getPlanFeatureValue("standard")}
      </div>
      <div className="text-center">
        {getPlanFeatureValue("premium")}
      </div>
    </Row>
  );
};

export default FeaturesTableRow;
