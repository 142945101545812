import React from "react";
import { Card } from "reactstrap";
import PropTypes from "prop-types";

import SignalTableHeader from "./SignalTableHeader";
import SignalTableBody from "./SignalTableBody";
import SignalProgressBar from "views/signals/compounds/SignalProgressBar";

const SignalProgress = ({ 
  loading, 
  data
}) => (
  <Card className="pt-3">
    <SignalTableHeader
      loading={loading}
    />
    <SignalProgressBar
      loading={loading}
      chart={data?.signal?.chart || []}
    />
    <SignalTableBody
      loading={loading}
      data={data}
    />
  </Card>
);

SignalProgress.propTypes = {
  loading: PropTypes.bool,
};

export default SignalProgress;