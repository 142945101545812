import React, { useState, useEffect, useCallback } from "react";
import { Card, CardBody } from "reactstrap";
import { useDispatch } from "react-redux";

import Header from "./Header";
import TradeSettings from "./TradeSettings";
import BotMarketsSelect from "components/BotMarketsSelect";
import { getGeneticStrategies, resetGeneticStrategies } from "store/bots/actions";
import TradeSettingsWidgetPreloader from "./preloaders/TradeSettingsWidgetPreloader";

const TradeSettingsWidget = ({
  isGenetic,
  isAllCoins,
  bot={},
  tradeSettings,
  isBotLoading,
  displayMarketsSelect=true,
  exchange
}) => {
  const [market, setMarket] = useState();
  const dispatch = useDispatch();

  const handleSelectMarket = useCallback(({ value }) => {
    const [base, quote] = value?.split("-");

    setMarket(value);
    dispatch(getGeneticStrategies(exchange, base, quote));
  }, [dispatch, exchange]);

  useEffect(() => {
    return () => dispatch(resetGeneticStrategies());
  }, [isAllCoins, isGenetic, bot, handleSelectMarket, tradeSettings, dispatch]);

  if (isBotLoading) return <TradeSettingsWidgetPreloader />;

  return (
    <Card>
      <Header isGenetic={isGenetic} />
      <CardBody className="px-4 pb-4 pt-0">
        {
          displayMarketsSelect && isGenetic ?
          <BotMarketsSelect
            id={bot.id}
            onChange={handleSelectMarket}
            className="mb-3"
          />
          :
          null
        }
        <TradeSettings
          isGenetic={isGenetic}
          isAllCoins={isAllCoins}
          botId={bot.id}
          tradeSettings={tradeSettings}
          market={market}
        />
      </CardBody>
    </Card>
  );
};

export default TradeSettingsWidget;
