import React from "react";
import SkeletonPreloader from "../../../components/SkeletonPreloader";
import {Card} from "reactstrap";

const TradeHistoryOpenOrdersPreloader = () => {
  return (
    <div className="d-flex justify-content-between">
      <Card className="h-75 p-4 mr-2">
        <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="d-flex mb-3 align-items-center">
                <SkeletonPreloader
                  width={111}
                  height={14}
                />
              </div>
              <div className="d-flex flex-column">
                <SkeletonPreloader
                  count={2}
                  height={14}
                  width={99}
                  className="mt-2"
                />
              </div>
            </div>
          </div>
        </Card>
        <Card className="h-75 p-4 mr-2">
          <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="d-flex mb-3 align-items-center">
                  <SkeletonPreloader
                    width={111}
                    height={14}
                  />
                </div>
                <div className="d-flex flex-column">
                  <SkeletonPreloader
                    count={2}
                    height={14}
                    width={99}
                    className="mt-2"
                  />
                </div>
              </div>
            </div>
        </Card>
      <Card className="h-75 p-4 mr-2">
        <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="d-flex mb-3 align-items-center">
                <SkeletonPreloader
                  width={111}
                  height={14}
                />
              </div>
              <div className="d-flex flex-column">
                <SkeletonPreloader
                  count={2}
                  height={14}
                  width={99}
                  className="mt-2"
                />
              </div>
            </div>
          </div>
        </Card>
        <Card className="h-75 p-4 mr-2">
          <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="d-flex mb-3 align-items-center">
                  <SkeletonPreloader
                    width={111}
                    height={14}
                  />
                </div>
                <div className="d-flex flex-column">
                  <SkeletonPreloader
                    count={2}
                    height={14}
                    width={99}
                    className="mt-2"
                  />
                </div>
              </div>
            </div>
        </Card>
      </div>
  )
}

export default TradeHistoryOpenOrdersPreloader;
