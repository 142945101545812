import React from "react";
import {
  Card,
  CardHeader
} from "reactstrap";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as AirDrop } from "assets/img/airdrop.svg";
import SignalDetailsWidgetPreload from "views/signals/preloaders/SignalDetailsWidgetPreload";
import StatusIndicator from "components/StatusIndicator";
import SignalWidgetBody from "./SignalWidgetBody";

const SignalWidget = ({
  loading,
  openFrom,
  openTo,
  stopLoss,
  status,
  base,
  quote,
  id
}) => {
  const history = useHistory();
  if (loading) return <SignalDetailsWidgetPreload />
  return (
    <SignalWidget.Card 
      className="p-4"
      onClick={()=>history.push(`/signals/${id}`)}
    >
      <CardHeader className="d-flex justify-content-between p-0">
        <div className="d-flex flex-row align-items-center ">
          <AirDrop 
            width="20px" 
            height="20px"
          />
          <p className="ml-2 mb-0 text-white font-weight-500">
            {base}
            <span className="text-white-35 font-weight-300">
              {" "}/ {quote}
            </span>
          </p>
        </div>
        <StatusIndicator
          status={status}
        />
      </CardHeader>
      <SignalWidgetBody
        openFrom={openFrom}
        openTo={openTo}
        stopLoss={stopLoss}
      />
    </SignalWidget.Card>
  );
};

SignalWidget.Card = styled(Card)`
  cursor:pointer;
  
  &:hover {
    background-color: rgba(46, 49, 72, 1) !important;
  }
`;

SignalWidget.propType = {
  loading: PropTypes.bool,
  openFrom: PropTypes.number,
  openTo: PropTypes.number,
  stopLoss: PropTypes.number,
  quote: PropTypes.string,
  id: PropTypes.string
};

export default SignalWidget;