import {Card, Col, Row} from "reactstrap";
import _ from "lodash";
import styled from "styled-components";

import ReferralSystemTablePreloader from "./ReferralSystemTablePreloader";
import { ReactComponent as CredentialChartPreload } from "assets/img/CredentialChartPreload.svg"
import SkeletonPreloader from "components/SkeletonPreloader";
import { ReactComponent as ChartPreloader } from "assets/img/BalanceChartPreload.svg";

const ReferralSystemPreloader = () => {
  return (
    <Row>
      <Col xs="12" md="4">
        <Card className="p-4">
          <SkeletonPreloader
            width={111}
            height={16}
            className="mb-4"
          />
          <div className="d-flex flex-column mb-4 w-100">
            <SkeletonPreloader
              width={170}
              height={12}
              className="mb-1"
            />
            <SkeletonPreloader
              width={170}
              height={12}
            />
          </div>
          <SkeletonPreloader
            width={105}
            height={12}
          />
          <ReferralSystemPreloader.Wrapper className="mt-3">
            <SkeletonPreloader
              width={250}
              height={11}
              className="ml-2"
            />
          </ReferralSystemPreloader.Wrapper>
        </Card>
        <Card className="px-4 pt-4">
          <div className="d-flex justify-content-between mb-4">
            <SkeletonPreloader
              height={12}
              width={111}
            />
            <SkeletonPreloader
              height={12}
              width={111}
              className="ml-2"
            />
          </div>
          <ChartPreloader width="100%"/>
          {
            _.times(2, (e) =>
              <div
                className="d-flex align-items-center justify-content-between mb-4"
                key={e}
              >
                <div className="d-flex align-items-center">
                  <SkeletonPreloader
                    circle={true}
                    height={12}
                    width={12}
                  />
                  <SkeletonPreloader
                    height={10}
                    width={55}
                    className="ml-2"
                  />
                </div>
                <SkeletonPreloader
                  height={10}
                  width={55}
                />
              </div>
            )
          }
        </Card>
      </Col>
      <Col xs="12" md="8">
        <Card className="p-4">
          <ReferralSystemPreloader.ChartWrapper>
            <CredentialChartPreload className="h-100" />
          </ReferralSystemPreloader.ChartWrapper>
        </Card>
        <ReferralSystemTablePreloader/>
      </Col>
    </Row>
  )
};

ReferralSystemPreloader.Wrapper = styled.div`
  background: #2E3148;
  border-radius: 8px;
`;

ReferralSystemPreloader.ChartWrapper = styled.div`
  margin: 86px 24px 24px;
`;

export default ReferralSystemPreloader;
