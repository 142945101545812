import React from "react";
import styled from "styled-components";

const InfoReminder =({ 
  text, 
  className
}) => {
  return (
    <InfoReminder.Reminder className={`d-flex align-items-center ${className}`}>
      <p className="font-sm font-weight-400 mb-0">
        {text}
      </p>
    </InfoReminder.Reminder>
  );
};

InfoReminder.Reminder = styled.div`
  padding: 0.625rem 1rem;
  background: rgba(249, 136, 108, 0.20);
  border-radius: 8px;
`;

export default InfoReminder;