import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classNames from "classnames";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  Col,
  Button,
  Card,
  CardBody,
  FormGroup
} from "reactstrap";

import { ReactComponent as StrategyLogo } from "assets/img/icons/strategyLogo.svg";
import { updateName } from "store/strategies/actions";
import { ReactComponent as Edit } from "assets/img/icons/edit.svg";
import useField from "hooks/useField";
/* eslint-disable complexity */

const style = {
  color: "#ec250d",
};

const StrategyHeader = ({
  uuid,
  name,
  updateName,
  createdAt
}) => {

  const [isEditing, setIsEditing] = useState(false);
  const [resModal, setResModal] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nameValue, setNameValue] = useState({ name });
  const [fieldProps, errors] = useField(nameValue, setNameValue);

  const handleUpdateName = async (uuid) => {
    if (nameValue.name !== name) {
      setIsSubmitting(true);
      const res = await updateName(uuid, nameValue);
      setResModal(
        <ReactBSAlert
          warning={res.errors}
          success={!res.errors}
          title={res.message}
          onConfirm={() => setResModal(null)}
          confirmBtnBsStyle={res.errors ? "warning" : "success"}
          btnSize=""
        />
      );
      setIsEditing(false);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex">
              <StrategyLogo className="mr-2" />
              <div className="flex-column my-auto">
                <div className="flex-column">
                  {
                    isEditing ?
                      <div className="d-flex">
                        <FormGroup className={classNames("my-auto", {
                          "has-danger": errors.name,
                          "has-success": !errors.name,
                        })}
                        >
                          <input
                            className="form-control"
                            name="name"
                            value={nameValue.name}
                            {...fieldProps}
                          />
                          <Col md="12">
                            {errors.name ?
                              <label style={style}>{errors.name}</label> : null}
                          </Col>
                        </FormGroup>
                        <Button
                          size="sm"
                          className="btn-icon ml-2"
                          onClick={() => setIsEditing(false)}
                        >
                          <i className="tim-icons icon-simple-remove" aria-hidden="true" />
                        </Button>
                        <Button
                          disabled={errors.name || isSubmitting}
                          color="info"
                          size="sm"
                          className="btn-icon ml-2"
                          onClick={() => handleUpdateName(uuid)}
                        >
                          <i className="tim-icons icon-check-2" aria-hidden="true" />
                        </Button>
                      </div>
                      :
                      <div className="d-flex">
                        <p className="h4 m-0">{name}</p>{" "}
                        <Edit
                          onClick={() => setIsEditing(!isEditing)}
                          style={{ cursor: "pointer" }}
                          className="ml-2"
                        />
                      </div>
                  }
                  <p className="text-muted">
                    {uuid}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-end mb-2">
              <p className="text-muted">
                <i className="fa fa-clock-o" aria-hidden="true" />{" "}
                {createdAt}
              </p>
            </div>
          </div>
        </CardBody>
      </Card>
      {resModal}
    </>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateName
}, dispatch);

export default connect(null, mapDispatchToProps)(StrategyHeader);
