import React from "react";
import {
  Col,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation
} from "react-router-dom";

import BacktestHeader from "./compounds/BacktestHeader";
import BacktestSettings from "./compounds/BacktestSettings";
import BacktestBalance from "./compounds/BacktestBalance";
import TimeLog from "./compounds/TimeLog";
import BotSettingsWidget from "components/BotSettingsWidget";
import BacktestDeals from "./compounds/BacktestDeals";
import BalanceChartWidget from "./chart/BalanceChartWidget";
import SummaryWidget from "./compounds/SummaryWidget";
import { getBacktest, resetBacktest } from "store/backtesting/actions";
import { fetchBacktestDeals } from "store/deals/actions";
import { backtestSelector } from "store/backtesting/selectors";
import DealsSummaryWidget from "./compounds/DealsSummaryWidget";
import RefreshButton from "components/RefreshButton";
import BestTradeSettings from "components/BestTradeSettings";
import {setGAPageBreadcrumbs} from "models/enum/breadcrumbs";
import Breadcrumbs from "components/Breadcrumbs";
import Error from "components/Error";
import useRequestCancellation from "hooks/useRequestCancellation";

const BacktestPage = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(backtestSelector);
  const { search } = useLocation();
  const { useAbortableEffect } = useRequestCancellation();

  const handleRefresh = () => {
    dispatch(fetchBacktestDeals(match.params.id, search))
    return dispatch(getBacktest(match.params.id));
  };

  useAbortableEffect((signal) => {
    dispatch(getBacktest(match.params.id, signal));
    return () => dispatch(resetBacktest());
  }, [dispatch, match.params.id, true], true);

  const redirectToBotConfigure = (event) => {
    event.stopPropagation();
    event.preventDefault();
    history.push(`/bots/${data?.bot?.id}/configure`, { backtest: match.params.id });
  };

  if (error) return <Error error={error} />;

  return (
    <div>
      <Row>
        <Col xs="12">
          <div className="d-none d-md-flex justify-content-between">
            <Breadcrumbs
              items={setGAPageBreadcrumbs(
                data?.bot?.investment?.credentials?.exchange,
                data?.market?.quote,
                data?.bot?.investment?.id,
                data?.market?.base,
                data?.market?.pair,
                data?.bot?.name,
                "Backtest"
              )}
              loading={loading}
            />

            <RefreshButton
              loading={loading}
              onHandleRefresh={handleRefresh}
            />
          </div>
          <BacktestHeader
            id={match.params.id}
            data={data}
            loading={loading}
            botId={data.bot?.id}
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs="12"
          md="4"
          className="no-gutters"
        >
          <BacktestSettings
            exchange={data.exchange}
            market={data.market}
            period={data.period}
            periods={data.periods}
            loading={loading}
          />
          <BacktestBalance
            balance={data.balance}
            summary={data.summary}
            loading={loading}
          />
          <TimeLog
            history={data.history}
            lifetime={data.statistics?.lifetime}
            loading={loading}
          />
          <BotSettingsWidget
            data={data.bot}
            loading={loading}
            defaultHeader={false}
            pathTo={`/bots/${data.bot?.id}`}
            onEditButtonClick={redirectToBotConfigure}
          />
        </Col>
        <Col
          xs="12"
          md="8"
        >
          <BestTradeSettings
            data={data}
            loading={loading}
            title="Trade settings"
          />
          <div className="d-flex flex-column flex-lg-row">
            <SummaryWidget
              summary={data.summary}
              loading={loading}
            />
            <DealsSummaryWidget
              summary={data.summary}
              loading={loading}
            />
          </div>
          <BalanceChartWidget
            data={data.balance?.history}
            loading={loading}
          />
          <BacktestDeals />
        </Col>
      </Row>
    </div>
  );
};

export default BacktestPage;
