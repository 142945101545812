import React, {useEffect} from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  Card,
  CardBody
} from "reactstrap";

import Account from "./Account";
import Notifications from "./Notifications";
import ReferralSystem from "./ReferralSystem";

const ProfileSettings = () => {

  useEffect(() => {
    window.analytics.page("/user");
  }, []);

  return (
    <>
      <h2>Profile settings</h2>
      <Card>
        <CardBody className="p-0 d-flex">
          <ProfileSettings.TabLink
            to="/user/profile"
            className="d-flex flex-column justify-content-center px-4 py-3 text-white-35 h4 mb-0"
            activeClassName="active-tab"
          >
            Account
          </ProfileSettings.TabLink>
          <ProfileSettings.TabLink
            to="/user/notifications"
            className="d-flex flex-column justify-content-center px-4 py-3 text-white-35 h4 mb-0"
            activeClassName="active-tab"
          >
            Notifications
          </ProfileSettings.TabLink>
          <ProfileSettings.TabLink
            to="/user/referral"
            className="d-flex flex-column justify-content-center px-4 py-3 text-white-35 h4 mb-0"
            activeClassName="active-tab"
          >
            Referral system
          </ProfileSettings.TabLink>
        </CardBody>
      </Card>
      <Switch>
        <Route exact path="/user/profile" component={Account} />
        <Route exact path="/user/notifications" component={Notifications} />
        <Route exact path="/user/referral" component={ReferralSystem} />
      </Switch>
    </>
  );
};

ProfileSettings.TabLink = styled(NavLink)`
  &:first-child {
    border-radius: 0.2857rem 0 0 0.2857em;
  }

  &:hover {
    background: #393B53;
    color: #fff !important;
  }
  &.active-tab {
    position: relative;
    color: #fff !important;
    display: flex;

    &:before {
      content: "";
      background: var(--info);
      bottom: 0;
      left: 20%;
      width: 60%;
      position: absolute;
      height: 4px;
      border-radius: 19px 19px 0 0;
    }
  }
`;

export default ProfileSettings;
