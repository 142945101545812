import React, {useEffect, useState} from "react";
import {Col, Label} from "reactstrap";
import {PIVOT_TIMEFRAME_FIELDS, PIVOT_TYPE_FIELDS} from "models/bot/wizard/enum/filters";
import {capitalize} from "lodash";
import SelectInput from "components/SelectInput";
import Checkbox from "components/Checkbox";
import PivotPointsRange from "components/PivotPointsRange";

const PivotPointsForm = ({values, setFieldValue, submitted, errors, botData}) => {
  const [minMaxRange, setMinMaxRange] = useState(5);
  const [checkedSupport, setCheckedSupport] = useState(values?.pivotPoint?.hasSupport);
  const [checkedResistance, setCheckedResistance] = useState(values?.pivotPoint?.hasResistance);

  const setMin = (value) => {
    if (value === "camarilla" || value === "classic" || value === "woodie") {
      return setMinMaxRange(4)
    }
    if (value === "demark") return setMinMaxRange(1)
    if (value === "fibonacci") return setMinMaxRange(3)
    if (value === "traditional") return setMinMaxRange(5)
    return setMinMaxRange(5)
  }

  const hasSupport = () => {
    if (checkedSupport) setFieldValue("pivotPoint", {...values.pivotPoint, hasSupport: false, supportBottom: null, supportTop: null});
    if (!checkedSupport) setFieldValue("pivotPoint", {...values.pivotPoint, hasSupport: true, supportBottom: -1, supportTop: 0});
    setCheckedSupport(!checkedSupport);
  };

  const hasResistance = () => {
    if (checkedResistance) setFieldValue("pivotPoint", {...values.pivotPoint, hasResistance: false, resistanceBottom: null, resistanceTop: null});
    if (!checkedResistance) setFieldValue("pivotPoint", {...values.pivotPoint, hasResistance: true, resistanceBottom: 0, resistanceTop: 1});
    setCheckedResistance(!checkedResistance);
  };

  const handlePPRanges = (value) => {
    if (checkedSupport && checkedResistance) {
      return setFieldValue("pivotPoint", {
        ...values.pivotPoint,
        supportBottom: value[0],
        supportTop: value[1],
        resistanceBottom: value[2],
        resistanceTop: value[3]
      })
    }

    if (checkedSupport) return setFieldValue("pivotPoint", {
      ...values.pivotPoint, supportBottom: value[0], supportTop: value[1]
    })
    if (checkedResistance) return setFieldValue("pivotPoint", {
      ...values.pivotPoint, resistanceBottom: value[2], resistanceTop: value[3]
    })
  }

  useEffect(() => {
    if (botData?.tradeSettings?.pivotPoint) setMin(botData?.tradeSettings?.pivotPoint.calculation)
  }, [botData?.tradeSettings?.pivotPoint])

  return (
    <div className="d-flex flex-column flex-md-row pb-4">
      <Col
        className="pl-4 d-flex align-items-end"
        md="5"
        xs="12"
      >
        <div className="mr-2 w-100">
          <Label className="mb-1 text-white-75">Type</Label>
          <SelectInput
            defaultValue={{label: "Traditional", value: "traditional"}}
            value={{label: capitalize(values?.pivotPoint?.calculation), value: values?.pivotPoint?.calculation}}
            options={PIVOT_TYPE_FIELDS}
            isClearable={false}
            isSearchable={false}
            hasError={submitted && errors.type}
            placeholder=''
            name="type"
            onChange={({value}) => {
              setFieldValue("pivotPoint", {
                ...values.pivotPoint,
                supportBottom: -1,
                supportTop: 0,
                resistanceBottom: 0,
                resistanceTop: 1,
                calculation: value
              });
              setMin(value);
            }}
          />
        </div>

        <div className="w-100">
          <Label className="mb-1 text-white-75">Timeframe</Label>
          <SelectInput
            defaultValue={{label: "Day", value: "day"}}
            value={{label: capitalize(values?.pivotPoint?.timeframe), value: values?.pivotPoint?.timeframe}}
            options={PIVOT_TIMEFRAME_FIELDS}
            isClearable={false}
            isSearchable={false}
            hasError={submitted && errors.type}
            placeholder=''
            name="type"
            onChange={({value}) => setFieldValue("pivotPoint", {...values.pivotPoint, timeframe: value})}
          />
        </div>
      </Col>
      <Col
        className="pr-4 pt-3 pb-3 pl-4 pt-md-0 pb-md-0"
        md="7"
        xs="12"
      >
        <div className="d-flex align-items-sm-center justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <Checkbox checked={checkedSupport} onChange={hasSupport} />
            <p className="mb-0 ml-2 font-weight-500 text-white">SUPPORT LEVELS</p>
          </div>
          <div className="d-flex align-items-center">
            <p className="mb-0 mr-2 font-weight-500 text-white text-right">RESISTANCE LEVELS</p>
            <Checkbox checked={checkedResistance} onChange={hasResistance}/>
          </div>
        </div>
        <PivotPointsRange
          checkedResistance={checkedResistance}
          values={values}
          handlePPRanges={handlePPRanges}
          minMaxRange={minMaxRange}
          checkedSupport={checkedSupport}
        />
      </Col>
    </div>
  )
}

export default PivotPointsForm;
