import React from "react";
import { Range } from "rc-slider";

const PlannedRange = ({
  values,
  realPercent,
  plannedPercent,
  index,
  setFieldValue,
  isEdited,
  enabled
}) => {
  const onChange = (e) => {
    if (!isEdited || !enabled) return;

    const [, initial, current] = e;

    const newPlanned = (initial < plannedPercent) && (current <= plannedPercent?.toFixed(0))
      ? initial
      : current;

    setFieldValue(`credentials[${index}]`, {
        ...values.credentials[index],
        plannedPercent: newPlanned,
        counter: newPlanned - plannedPercent
      }
    );
  };

  const setTrackBg = () => {
    if (isEdited) return values?.credentials[index]?.plannedPercent > plannedPercent
        ? "#3FCBB0" : "#ED5F5F";

    return realPercent > plannedPercent ? "#3FCBB0" : "#ED5F5F";
  };

  return (
    <Range
      min={0}
      max={100}
      step={1}
      count={2}
      value={
        isEdited
          ? [0, plannedPercent, values?.credentials[index]?.plannedPercent]
          : [0, realPercent, realPercent + (plannedPercent - realPercent)]
      }
      railStyle={{
        backgroundColor: "#1D1E2A",
        height: 8,
        borderRadius: "50px",
      }}
      trackStyle={[
        {
          background: "#848EFA",
          height: 8,
          borderRadius: ((plannedPercent === 0) || (realPercent === 0)) ? "50px" : "50px 0 0 50px",
          overflow: "hidden"
        },
        {
          background: setTrackBg(),
          height: 8,
          borderRadius: !plannedPercent || (!isEdited && realPercent < 1) ? "50px" : "0 50px 50px 0",
          display: enabled ? "block" : "none"
        }
      ]}
      handleStyle={[
        {
          display: "none",
          border: "none",
          boxShadow: "none",
          backgroundColor: "#5E6392",
          opacity: 1,
          width: 16,
          height: 16,
          zIndex: 2,
        },
        {
          display: "none",
          border: "none",
          boxShadow: "none",
          backgroundColor: "#5E6392",
          opacity: 1,
          width: 16,
          height: 16,
          zIndex: 2,
        },
        {
          display: isEdited && enabled ? "block" : "none",
          border: "none",
          boxShadow: "none",
          backgroundColor: "#5E6392",
          opacity: 1,
          width: 16,
          height: 16,
          zIndex: 2,
        }
      ]}
      onChange={onChange}
    />
  );
};

export default PlannedRange;
