import React from "react";

const SignalParameters = ({ signal }) => {
  return (
    <div className="d-flex d-md-none justify-content-between flex-wrap mt-4">
      <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2 mr-4">
        <p className="mb-0 text-white-75">
          SL
        </p>
        <p className="mb-0 font-weight-500 text-white">
          {signal?.stopLoss}
        </p>
      </div>
      <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2 mr-4">
        <p className="mb-0 text-white-75">
          BF
        </p>
        <p className="mb-0 font-weight-500 text-white">
          {signal?.buyFrom}
        </p>
      </div>
      <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2 mr-4">
        <p className="mb-0 text-white-75">
          BT
        </p>
        <p className="mb-0 font-weight-500 text-white">
          {signal?.buyTo}
        </p>
      </div>
      {
        signal?.targets?.map((item, index) => {
          return (
            <div
              key={item.id}
              className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2 mr-4">
              <p className="mb-0 text-white-75">
                T{index + 1}
              </p>
              <p className="mb-0 font-weight-500 text-white">
                {item?.rate}
              </p>
            </div>
          )
        })
      }
    </div>
  );
};

export default SignalParameters;