import React, {useEffect, useLayoutEffect, useMemo} from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
import { useParams } from "react-router-dom";

import BotsWidgetEmptyState from "./BotsWidgetEmptyState";
import BotsWidgetHeader from "./BotsWidgetHeader";
import TablePreloader from "components/TablePreloader";
import CustomScrollbars from "components/CustomScrollbars";
import {fetchDealsRunning} from "store/deals/actions";
import BotsTableRow from "./botsTableRow";
import { getCredentialsBots } from "store/credentials/actions";
import { StyledCard } from "../MyCredentials/MyCredentialBalances/MyCredentialBalances";
import SortTableHead from "components/SortTableHead";
import { EXCHANGE_BOTS_HEADER } from "models/bot/tables";
import StyledTable from "components/StyledTable";

const BotsWidget = ({id, quote, base}) => {

  const dispatch = useDispatch();
  const { exchange: exchangeName } = useParams();

  const data = useSelector(state => state.credentials.bots.data);
  const loading = useSelector(state => state.credentials.bots.loading);
  const {items} = useSelector(state => state.deals.running.data);
  const loadingDeals = useSelector(state => state.deals.running.loading);

  const botsId = useMemo(() => data?.items?.map(item => item.id), [data]);

  useEffect(() => {
    const filter = qs.stringify({
      filter: {
        "credentials.exchange": {eq: exchangeName},
        "market": {like: [quote, base]}
      },
      sort: {
        by: "name"
      }
    });
    dispatch(getCredentialsBots(id, quote, base, `?${filter}`));
  }, [id, quote, base, dispatch, exchangeName])

  useLayoutEffect(() => {
    if(botsId?.length){
      const filter = qs.stringify({
        filter: {
          bot: {in: botsId},
          status: {in: ["ready", "running"]},
          "market": {in: [`${base}-${quote}`]}
        },
      });
      dispatch(fetchDealsRunning(`?${filter}`));
  }
  }, [botsId, base, quote, dispatch]);

  const onHandleRefresh = () => {
    const botsFilter = qs.stringify({
      filter: {
        "credentials.exchange": {eq: exchangeName},
        "market": {like: [quote, base]}
      }
    });
    dispatch(getCredentialsBots(id, quote, base, `?${botsFilter}`));
  };

  if (!loadingDeals && !loading && !data?.items?.length){
    return (
      <BotsWidget.StyledCard className="StyledCard">
        <BotsWidgetHeader
          loading={loading}
          onHandleRefresh={onHandleRefresh}
        />
        <BotsWidgetEmptyState
          title={"You have no bots at the moment"}
          description={"Please create some to see more detailed info by spot market"}
        />
      </BotsWidget.StyledCard>
    )
  }

  return (
    <BotsWidget.StyledCard className="StyledCard mb-0">
      <BotsWidgetHeader
        loading={loading}
        onHandleRefresh={onHandleRefresh}
      />
      {
        (loading || loadingDeals) ?
          <TablePreloader
            thHeight={12}
            thWidth={[44, 70, 68, 70]}
            tdWidth={[65, 90, 90, 100]}
            tdHeight={14}
            rowTimes={5}
            withCircle={20}
          />
          :
          <CustomScrollbars
            autoHideTimeout={500}
            autoHideDuration={500}
            autoHeight
            autoHeightMax={Number.MAX_VALUE}
          >
            <StyledTable>
              <SortTableHead
                theadItems={EXCHANGE_BOTS_HEADER}
              />
              <tbody className="font-sm">
                {data?.items?.map((item, index) => {
                  return (
                    <BotsTableRow
                      key={item.id}
                      item={item}
                      index={index}
                      deals={items}
                      quote={quote}
                      base={base}
                    />
                  )
                })}
              </tbody>
            </StyledTable>
          </CustomScrollbars>
        }
      </BotsWidget.StyledCard>
  )
}

BotsWidget.StyledCard = styled(StyledCard)`
  padding: 0px;
  color: white;
`;

export default BotsWidget;
