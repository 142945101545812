import React from "react";

import {DEALS_HISTORY_TABLE_HEAD} from "models/enum/tables";
import SortTableHead from "components/SortTableHead";
import StyledTable from "components/StyledTable";
import DealRow from "./DealRow";
import CustomScrollbars from "components/CustomScrollbars";

const DealHistoryTable = ({items, sort, filter, total, handleSorting}) => {
  return (
    <CustomScrollbars
      autoHideTimeout={500}
      autoHideDuration={500}
      autoHeight
      autoHeightMax={Number.MAX_VALUE}
    >
      <StyledTable className="mb-0" paddingsThead="1rem">
        <SortTableHead
          handleSorting={handleSorting}
          sort={sort}
          theadItems={[
            ...DEALS_HISTORY_TABLE_HEAD,
            filter?.status?.eq === "completed"
              ? {name: "completedAt", label: "Completed at", isSortable: true}
              : {name: "cancelledAt", label: "Cancelled at", isSortable: true}
          ]}
        />
        <tbody className="font-sm">
        {
          items.map((item) =>
            <DealRow
              item={item}
              key={item.id}
              total={total}
            />
          )
        }
        </tbody>
      </StyledTable>
    </CustomScrollbars>
  );
};

export default DealHistoryTable;
