import React from "react";
import styled from "styled-components";
import { Table } from "reactstrap";
import classNames from "classnames";
import _ from "lodash";
import { useHistory } from "react-router-dom";

import Icon from "components/Icon";
import BacktestHistoryTablePreLoad from "./BacktestHistoryTablePreLoad";
import Error from "components/Error";

const BacktestHistoryTable = ({ data=[], loading, error }) => {
  const history = useHistory();

  if (loading) {
    return <BacktestHistoryTablePreLoad />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return(
    <Table>
      <BacktestHistoryTable.TableHeader>
        <tr>
          <th className="text-white-35 pl-4">Market</th>
          <th className="text-white-35">Initial balance</th>
          <th className="text-white-35">Final balance</th>
          <th className="text-white-35">Status</th>
        </tr>
      </BacktestHistoryTable.TableHeader>
      <tbody>
        {data.map((item) =>
          <BacktestHistoryTable.ClickableTr
            key={item.uuid}
            onClick={() => history.push(`/backtest/${item.uuid}`)}
          >
            <td className="pl-4">
              {
                item.market ?
                <div className="d-flex">
                  <Icon
                    type="exchanges"
                    size="xs"
                    code={item.exchange}
                  />
                  <p className="my-auto font-weight-bold">
                    {item.market.base + "-" + item.market.quote}
                  </p>
                </div> : null
              }
            </td>
            <td>
              {
                item.balance && item.balance.initial &&
                item.balance.initial.amount ?
                <div className="d-flex flex-column">
                  <p className="text-white my-auto">
                    {item.balance.initial.amount + " " + item.balance.initial.asset}
                  </p>
                </div> : null
              }
            </td>
            <td>
              {
                item.balance && item.balance.final &&
                item.balance.final.amount ?
                <div className="d-flex flex-column">
                  <p className="text-white my-auto">
                    {item.balance.final.amount + " " + item.balance.final.asset}
                  </p>
                  {
                    item.balance.final.percent ?
                    <p className="text-white-35 h5 my-auto">
                      {item.balance.final.percent + " %"}
                    </p> : null
                  }
                </div> : null
              }
            </td>
            <td>
              <div className="d-flex">
                <p className={classNames("my-auto", {
                  "text-info": item.status === "preparing" || item.status === "prepared",
                  "text-white": item.status === "new",
                  "text-success": item.status === "completed",
                  "text-primary": item.status === "running",
                })}>
                  <i
                    className="fa fa-circle mr-1"
                    aria-hidden="true"
                    style={{ fontSize: "12px" }}
                  />{" "}
                  {_.startCase(_.toLower(item.status))}
                </p>
              </div>
            </td>
          </BacktestHistoryTable.ClickableTr>
        )}
      </tbody>
    </Table>
  );
};

BacktestHistoryTable.TableHeader = styled.thead`
  background: #2e3148;
`;

BacktestHistoryTable.ClickableTr = styled.tr`
  cursor: pointer;

  &:hover {
    background: #2E3148;
  }
`;

export default BacktestHistoryTable;
