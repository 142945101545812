import { login, logout } from "auth";
import { get } from "lodash";

export const loginUser = (user) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/auth/login", user);
    login(res.data.token, res.data.refreshToken);
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const googleAuth = (data) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/auth/google", data);
    login(res.data.token, res.data.refreshToken);
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const register = (user) => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/auth/register", user);
    login(res.data.token, res.data.refreshToken);
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const Userlogout = (user) => async (dispatch, getState, fetch) => {
  try {
    logout();
    await fetch.post("/auth/logout", user);
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const resetPassword = (body) => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/public/password/forgot", body);
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const recoverPassword = (body) => async (dispatch, getState, fetch) => {
  try {
    await fetch.post("/public/password/recover", body);
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const confirmEmail = (secret) => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.patch("/public/callback/email/confirm", {secret: secret});
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};
