import React from "react";
import {
  Col, 
  Label
} from "reactstrap";
import {useFormikContext} from "formik";

import FormGroupWithSymbols from "components/FormGroupWithSymbols";
import NumberInput from "components/NumberInput";
import SelectInput from "components/SelectInput";
import {EXECUTION_TYPE_FIELDS} from "./tradeSettingsConfiguration";

const TradeSettingsFilters = ({submitted, botData}) => {
  const {values, errors, setFieldValue} = useFormikContext();

  const renderField = (fieldName, label) => {
    const errorsFields = `tradeSettings.${fieldName}`;
    return (
      <>
        <Label
          className="mb-1 text-white-75"
          htmlFor={fieldName}
        >
          {label}
        </Label>
        <FormGroupWithSymbols symbolRight="%">
          <NumberInput
            id={fieldName}
            value={values?.[fieldName]}
            className={`form-control text-white ${submitted && (errors[fieldName] || errors[errorsFields]) ? "has-error" : ""}`}
            name={fieldName}
            onChange={(value) => setFieldValue(`${fieldName}`, value)}
            autoComplete="off"
          />
        </FormGroupWithSymbols>
        {
          submitted && (errors[fieldName] || errors[errorsFields]) ?
            <p className="mb-0 font-sm text-danger">
              {
                errors[fieldName]
              }
            </p>
            : null
        }
      </>
    );
  };

  return (
    <>
      <div className="d-flex flex-column flex-md-row mb-3">
        <Col
          md="6"
          xs="12"
          className="px-0 pr-md-4"
        >
          {renderField("volume", "Initial volume size")}
        </Col>
        <Col
          md="6"
          xs="12"
          className="px-0 pr-md-1"
        >
          {renderField("trailingSell", "Trailing Sell")}
        </Col>
      </div>
      <div className="d-flex flex-column flex-md-row mb-3">
        <Col
          md="6"
          xs="12"
          className="px-0 pr-md-4"
        >
          {renderField("profit", "Take profit")}
        </Col>
        <Col
          md="6"
          xs="12"
          className="px-0 pr-md-1"
        >
          {renderField("trailingBuy", "Trailing Buy")}
        </Col>
      </div>
      <div className="pb-4 d-flex flex-column flex-md-row mb-3">
        <Col
          md="6"
          xs="12"
          className="px-0 pr-md-4"
        >
          {renderField("stopLossPercent", "Stop loss")}
        </Col>
        <Col
          md="6"
          xs="12"
          className="px-0"
        >
          <Label className="mb-1 text-white-75">Execution type</Label>
          <SelectInput
            defaultValue={EXECUTION_TYPE_FIELDS.find(({value}) =>
              value === botData?.tradeSettings?.executionType) || {label: "Passive limit", value: "passive"}
            }
            options={EXECUTION_TYPE_FIELDS}
            isClearable={false}
            isSearchable={false}
            hasError={submitted && errors.type}
            placeholder=''
            name="type"
            onChange={({value}) => setFieldValue("type", value)}
          />
        </Col>
      </div>
    </>
  );
};

export default TradeSettingsFilters;
