import React from "react";
import {Label} from "reactstrap";
import FormGroupWithSymbols from "components/FormGroupWithSymbols";
import {DelayInput} from "react-delay-input";
import {useDispatch, useSelector} from "react-redux";
import {setMinMaxChange} from "store/bots/wizard/actions";
import MinMaxRange from "./MinMaxRange";

const FilterMinMaxChange = () => {
  const dispatch = useDispatch();

  const {minimumDailyChange, maximumDailyChange} = useSelector((state) => state.botWizard);

  const validation = (minimumDailyChange !== null && maximumDailyChange !== null) &&
    (minimumDailyChange >= maximumDailyChange || (minimumDailyChange === 0 && maximumDailyChange === 0));

  const handleChangeFrom = (value) => {
    if (!value) return dispatch(setMinMaxChange([null, maximumDailyChange]));
    if (value < 0 && maximumDailyChange > 0) return dispatch(setMinMaxChange([+value, maximumDailyChange]));
    dispatch(setMinMaxChange([+value, maximumDailyChange]));
  }

  const handleChangeTo = (value) => {
    if (!value) return dispatch(setMinMaxChange([minimumDailyChange, null]));
    if (minimumDailyChange < 0 && value > 0) return dispatch(setMinMaxChange([minimumDailyChange, +value]));
    dispatch(setMinMaxChange([minimumDailyChange, +value]));
  }

  return (
    <>
      <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
        <Label className="text-white-75 mb-2 mb-sm-0">
          Minimum & maximum 24h change
        </Label>
        <div className="d-flex align-items-center p-0">
          <span className="text-white-75 mb-0 mr-3 font-sm">From</span>
          <FormGroupWithSymbols
            symbolRight={<span className="text-white-35 mb-0 font-weight-300">%</span>}
          >
            <DelayInput
              autoComplete="off"
              delayTimeout={700}
              type="number"
              name="from"
              className={`form-control ${validation ? "error" : ""}`}
              style={{width: "88px"}}
              value={minimumDailyChange}
              onChange={({target}) => handleChangeFrom(target.value)}
            />
          </FormGroupWithSymbols>
          <span className="text-white-75 mb-0 mx-2 font-sm">to</span>
          <FormGroupWithSymbols
            symbolRight={<span className="text-white-35 mb-0 font-weight-300">%</span>}
          >
            <DelayInput
              autoComplete="off"
              delayTimeout={700}
              type="number"
              name="to"
              className={`form-control ${validation ? "error" : ""}`}
              style={{width: "88px"}}
              value={maximumDailyChange}
              onChange={({target}) => handleChangeTo(target.value)}
            />
          </FormGroupWithSymbols>
        </div>
      </div>
      {
        validation ?
          <p className="mb-0 mt-2 text-danger font-sm d-flex justify-content-end">
            {`* Value "From" shouldn't be greater or equal than value "to"'`}
          </p> : null
      }
      <div className="mt-3">

        <MinMaxRange
          minimumDailyChange={minimumDailyChange}
          maximumDailyChange={maximumDailyChange}
          validation={validation}
        />

        <div className="d-flex align-items-center justify-content-between mt-2">
          <p className="mb-0 text-white-35 font-sm">-100%</p>
          <p className="mb-0 text-white-35 font-sm">0%</p>
          <p className="mb-0 text-white-35 font-sm">+100%</p>
        </div>
      </div>
    </>
  )
}

export default React.memo(FilterMinMaxChange);
