import { convertOrderBook, updateOrders } from "../utils";

export const serializeTicker = (data) => ({
  symbol: data.s,
  open: data.o,
  high: data.h,
  low: data.l,
  close: data.c,
  quantity: data.Q,
  amount: data.Q * data.c,
  tradeCount: data.n,
  dailyChange: data.P,
});

const serializeTrade = (data) => ({
  type: data.m ? "buy" : "sell",
  quantity: data.q,
  amount: data.q * data.p,
  price: data.p,
  createTime: data.T
});

export const handleBinanceMessage = (
  message,
  setTicker,
  setOrderBook,
  setTrades,
  handleLastIdCheck,
  depth,
  lastUpdateId
) => {

  if (message.e === "24hrTicker") {
    const newTicker = serializeTicker(message);
    setTicker((prevTicker) => {
      if ((!prevTicker || prevTicker.symbol) !== newTicker.symbol) {
        return [newTicker];
      }
      return prevTicker;
    });
  }

  if (message.e === "depthUpdate" && message.u >= lastUpdateId + 1) {
    const asks = message.a.length ? convertOrderBook(message.a) : [];
    const bids = message.b.length ? convertOrderBook(message.b) : [];

    setOrderBook((prev) => ({
      asks: updateOrders(prev.asks, asks, "asks").splice(0, depth),
      bids: updateOrders(prev.bids, bids, "bids").splice(0, depth),
    }));
  }

  if (message.e === "trade") {
    setTrades((prev) => ({ ...prev, data: [serializeTrade(message), ...prev.data].splice(0, 10) }));
  }
};
