import React from "react";
import { CardBody } from "reactstrap";
import PropTypes from "prop-types";
import styled from "styled-components";

import SkeletonPreloader from "components/SkeletonPreloader";
import SignalProgressStep from "./SignalProgressStep";

const SignalProgressBar = ({ 
  loading, 
  chart, 
  className="p-0 px-4 pt-4 mb-3"
}) => {
  const { point, steps } = chart;

  if (loading) {
    return (
      <CardBody className="p-0 px-4 mb-3">
        <SkeletonPreloader
          className="w-100"
          borderRadius="2px"
          height={7}
        />
      </CardBody>
    )
  }

  return (
    <ProgressBarContainer className={className}>
      {steps?.map((step, index) => (
        <SignalProgressStep
          key={step.name}
          color={step.color}
          isActive={step.active}
          isPoint={step.name === point?.position?.step}
          point={point}
          index={index}
          rates={step?.rates}
          isLast={index > 1}
        />
      ))}
    </ProgressBarContainer>
  );
};

SignalProgressBar.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string
};

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export default SignalProgressBar;
