import React from "react";
import {FormGroup} from "reactstrap";
import styled from "styled-components";
import SelectInput from "components/SelectInput";
import Button from "components/Button";

const MartingaleHeader = ({allCoins, availableItems, excludedCoins, coins, setInputMarket, calculate, total}) => {
  return (
    <MartingaleHeader.Wrapper
      className="d-flex flex-column flex-md-row align-items-lg-center justify-content-md-between py-3 pl-4 pr-3"
    >
      <div className="d-flex flex-column flex-lg-row align-items-lg-center w-100">
        <FormGroup className="mb-0 markets">
          <SelectInput
            options={[
              ...allCoins ? (availableItems?.filter((item) => excludedCoins?.every(coin => item.market.base !== coin))?.map((item) => {
                    return {value: item.market.base, label: item.market.base}
                  }) || [])
                : coins?.map((item) => {
                  return {value: item, label: item}
                })
            ]}
            isClearable={false}
            isSearchable={false}
            placeholder="Markets"
            name="markets"
            onChange={({value}) => setInputMarket(value)}
            isDisabled={!+total}
            defaultValue={
              allCoins ?
                availableItems?.filter((item) => excludedCoins?.every(coin => item.market.base !== coin))
                  ?.map((item) => {
                    return {value: item.market.base, label: item.market.base}
                  })[0]
                : {value: coins[0], label: coins[0]}
            }
          />
        </FormGroup>
        <p className="mb-0 mt-2 mt-lg-0 ml-lg-3 text-white-75">
          Choose a market to display martingale calculation for
        </p>
      </div>
      <Button
        className="mt-2 mt-md-0"
        color="blue"
        type="button"
        onClick={calculate}
        disabled={!+total}
      >
        Calculate
      </Button>
    </MartingaleHeader.Wrapper>
  )
}

MartingaleHeader.Wrapper = styled.div`
  @media (max-width: 768px) {
    .markets {
      width: 100% !important;
    }
  }

  .markets {
    width: 184px;
  }
`;

export default MartingaleHeader;