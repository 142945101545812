import React from "react";
import {useSelector} from "react-redux";
import {Row} from "reactstrap";

import Error from "components/Error";
import NotificationTypes from "./compounds/NotificationTypes";

const Notifications = () => {
  const user = useSelector((state) => state.me.data);
  const loading = useSelector((state) => state.me.loading);
  const error = useSelector((state) => state.me.error);

  if (error) return <Error error={error}/>;

  return (
    <Row className="m-0">
      <NotificationTypes
        language={user.language}
        loading={loading}
      />
    </Row>
  );
};

export default Notifications;
