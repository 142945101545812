import React from "react";
import Slider from "rc-slider";
import {Label} from "reactstrap";
import {DelayInput} from "react-delay-input";
import {useDispatch, useSelector} from "react-redux";

import FormGroupWithSymbols from "components/FormGroupWithSymbols";
import {setPriceDifference} from "store/bots/wizard/actions";
import {priceDifferenceSteps} from "./SlidersStyling/priceDifferenceStyling";

const FilterPriceDifference = () => {
  const dispatch = useDispatch();
  const { markPriceDifference, investment } = useSelector((state) => state.botWizard);
  const stepsStyle = priceDifferenceSteps(markPriceDifference);

  const setPriceMark = (value) => {
    if (!value) return dispatch(setPriceDifference(null));
    if (value > 20) return dispatch(setPriceDifference(20));
    if (value < 0) return dispatch(setPriceDifference(0));
    return dispatch(setPriceDifference(value));
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between pt-2">
        <Label className="text-white-75 mb-0">Mark price difference</Label>
        <FormGroupWithSymbols
          symbolRight={<span className="text-white-35 mb-0 font-weight-300">%</span>}
        >
          <DelayInput
            disabled={investment?.credentials?.exchange === "hitbtc"}
            autoComplete="off"
            delayTimeout={700}
            type="number"
            name="priceDifference"
            className="form-control"
            style={{width: "88px"}}
            value={markPriceDifference}
            onChange={({ target }) => setPriceMark(target.value)}
          />
        </FormGroupWithSymbols>
      </div>
      <div className="pt-3">
        <Slider
          min={0}
          max={20}
          startPoint={0}
          step={1}
          disabled={investment?.credentials?.exchange === "hitbtc"}
          onChange={(percent) => dispatch(setPriceDifference(percent))}
          value={markPriceDifference || 0}
          railStyle={{
            backgroundColor: "#363A54",
            height: 8
          }}
          trackStyle={{
            backgroundColor: "#0AAF95",
            height: 8
          }}
          handleStyle={{
            border: "none",
            backgroundColor: markPriceDifference ? "#0AAF95" : "#9C9FBF",
            opacity: 1,
            width: 22,
            height: 22,
            bottom: "-50%"
          }}
          marks={{...stepsStyle}}
          dotStyle={{
            border: "none",
            borderRadius: 2,
            height: '10px',
            margin: "0 0 -3px -2px",
            width: '4px',
            background: "var(--dark-slate)"
          }}
        />
        <div className="d-flex align-items-center justify-content-between mt-2">
          <p className="mb-0 text-white-35 font-sm">0%</p>
          <p className="mb-0 text-white-35 font-sm">+20%</p>
        </div>
      </div>
    </>
  )
}

export default React.memo(FilterPriceDifference);
