import React, {useState} from "react";
import styled from "styled-components";
import {Tooltip} from "reactstrap";
import {capitalize} from "lodash";

const ColorfulBar = (
  {
    itemsLength,
    itemsWithValueLength,
    title,
    value,
    index,
    height,
    withSpacing = false,
    tooltipText,
    color,
    ...props
  }) => {
  const [isShow, setIsShow] = useState(false);
  const toggleTooltip = () => setIsShow(prevState => !prevState);

  const width = (value / itemsWithValueLength) * 100;

  return (
    <ColorfulBar.Wrapper
      id={`${title}-${index}`}
      width={width}
      height={height}
      itemsWithValueLength={itemsWithValueLength}
      color={color}
      className={`${withSpacing ? "" : "mr-1"} cursor-pointer `}
      {...props}
    >
      <Tooltip
        placement={"top"}
        target={`${title}-${index}`}
        isOpen={isShow}
        toggle={toggleTooltip}
      >
        <div className="d-flex flex-row align-items-center">
          <p className="font-xs font-weight-500 mb-0 text-dark mr-1">
            {`${value} ${capitalize(title)}`}
          </p>
          <span className="font-xs font-weight-400 text-dark">
            {tooltipText}
          </span>
        </div>
      </Tooltip>
    </ColorfulBar.Wrapper>
  )
}

ColorfulBar.Wrapper = styled.div`
  height: ${props => props.height ? props.height : "16px"};
  width: ${props => props.width + "%"};
  border-radius: ${props => props.itemsWithValueLength <= 1 && "5px"} !important;
  background: ${props => props.color};

  :first-child {
    border-radius: 5px 0 0 5px;
  }

  :last-child {
    border-radius: 0 5px 5px 0;
    margin-right: 0;
  }
`;

export default ColorfulBar;
