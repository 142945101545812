import React from "react";
import { Card } from "reactstrap";
import SkeletonPreloader from "components/SkeletonPreloader";

const VolumesWidgetPreloader = () => {
  return (
    <Card style={{padding: "24px"}}>
      <div className="d-flex align-items-center mb-3">
        <SkeletonPreloader
          circle={true}
          height={16}
          width={16}
        />
        <SkeletonPreloader
          width={81}
          height={12}
          className="ml-2"
        />
      </div>

      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex flex-column">
          <SkeletonPreloader
            count={3}
            height={12}
            width={81}
            className="mt-2 mb-2"
          />
        </div>
        <div className="d-flex flex-column">
          <SkeletonPreloader
            count={3}
            height={12}
            width={121}
            className="mt-2 mb-2"
          />
        </div>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex flex-column">
          <SkeletonPreloader
            count={3}
            height={12}
            width={81}
            className="mt-2 mb-2"
          />
        </div>
        <div className="d-flex flex-column">
          <SkeletonPreloader
            count={3}
            height={12}
            width={121}
            className="mt-2 mb-2"
          />
        </div>
      </div>

      <span style={{borderTop: "1px dashed rgba(255, 255, 255, 0.1)"}}/>

      <div
        className="d-flex mt-3"
        style={{
          background: "linear-gradient(90deg, rgba(83, 84, 98, 0.3) 0%, rgba(83, 84, 98, 0) 100%)",
          borderRadius: "8px",
          padding: "9px 24px"
        }}
      >
        <SkeletonPreloader
          circle={true}
          height={24}
          width={24}
        />
        <div className="d-flex flex-column ml-3">
          <SkeletonPreloader
            height={8}
            width={41}
          />
          <SkeletonPreloader
            height={12}
            width={121}
            className="mt-2"
          />
        </div>
      </div>
    </Card>
  )
}

export default VolumesWidgetPreloader;
