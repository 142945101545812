import React from "react";
import PropTypes from "prop-types";

import {ReactComponent as  Empty} from "assets/img/empty-state-planet2.svg";
import { stringOrNull } from "views/channels/channelPropTypes";

const SignalsEmptyState = ({
  title, 
  description
}) => {
  return (
    <div className="d-flex flex-column align-items-center p-5 text-center mb-4">
      <Empty/>
      <p className="mb-1 mt-4 pt-3 text-white font-weight-500">{title}</p>
      <p className="mb-0 text-white-75 font-sm font-weight-300">{description}</p>
    </div>
  )
};

SignalsEmptyState.propType = {
  title: PropTypes.string,
  description: stringOrNull
};

export default SignalsEmptyState;