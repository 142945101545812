import React from "react";
import { CardFooter } from "reactstrap";
import PropTypes from "prop-types";

import Button from "components/Button";

const CreateTargetFooter = ({
  isSubmitting,
  handleToggleModal,
  setIsSubmitted,
}) => {
  return (
    <CardFooter className="p-0 mt-5 pt-2">
      <div className="d-flex justify-content-between">
        <Button
          type="button"
          onClick={handleToggleModal}
          outline
        >
          Cancel
        </Button>
        <Button
          loading={isSubmitting}
          disabled={isSubmitting}
          color="blue"
          type="submit"
          onClick={() => setIsSubmitted(true)}
        >
          Create
        </Button>
      </div>
    </CardFooter>
  );
};

CreateTargetFooter.propTypes = {
  isSubmitting: PropTypes.bool,
  handleToggleModal: PropTypes.func,
  setIsSubmitted: PropTypes.func,
};

export default CreateTargetFooter;