import React from "react";
import {
  CardBody,
  FormGroup
} from "reactstrap";

import SelectInput from "components/SelectInput";
import {
  AGREEMENT_TR_SIDE,
  AGREEMENT_TR_STATUS_OPTIONS
} from "models/filters";

const FiltersTradeRequest = ({
  loading,
  onHandleFilter,
  filter
}) => {
  return (
    <CardBody className="d-flex flex-column flex-md-row justify-content-between p-0 mb-3">
      <FormGroup className="mb-0 w-25 mr-2 mb-2 mb-md-0">
        <SelectInput
          isClearable
          placeholder="Side"
          name="tr.type"
          width="100%"
          value={AGREEMENT_TR_SIDE.find(({ value }) => value === filter?.["tr.type"]?.eq)}
          onChange={(option) => { onHandleFilter({ "tr.type": { eq: option?.value } }) }}
          options={AGREEMENT_TR_SIDE}
          isDisabled={loading}
        />
      </FormGroup>
      <FormGroup className="mb-0 w-75 mr-2 mb-2 mb-md-0">
        <SelectInput
          isClearable
          placeholder="Status"
          name="tr.status"
          width="100%"
          value={AGREEMENT_TR_STATUS_OPTIONS.find((o) => o.value === filter?.["tr.status"]?.eq)}
          onChange={(option) => onHandleFilter(
            {
              "tr.status": option
                ? { eq: option?.value }
                : { neq: 'open' }
            }
          )}
          options={AGREEMENT_TR_STATUS_OPTIONS}
          isDisabled={loading}
        />
      </FormGroup>
    </CardBody>
  );
};

export default FiltersTradeRequest;