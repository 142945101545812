import { ADD_MESSAGE, REMOVE_MESSAGE } from "./constants";
import { v4 } from "uuid";

const initialState = []

const snackBar = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_MESSAGE:
      return [
        ...state,
        {
          type: payload.type,
          message: payload.message,
          id: v4(),
        }
      ];

    case REMOVE_MESSAGE:
      return state.filter((notification) => notification.id !== payload.id);

    default:
      return state;
  }
};

export default snackBar;
