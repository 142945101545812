import React from "react";

import { Progress } from "reactstrap";
import NotificationInfoBlock from "components/NotificationInfoBlock";

const DealsProgress = ({ maxDeals, runningDeals }) => {
  return (
    <div className="d-flex flex-column mt-4">
      <div className={`d-flex justify-content-between align-items-center ${maxDeals? "mb-2" : ""}`}>
        <p className="text-white font-sm m-0">
          Running Deals
        </p>
        <p className={`font-weight-500 ${maxDeals === runningDeals ? "text-warning" : "text-success"} m-0`}>
          {`${runningDeals}${maxDeals ? '/' + maxDeals : ''}`}
        </p>
      </div>
      {
        maxDeals ?
        <Progress
          style={{
            width: "100%",
            height: "6x",
            background: "#363A54",
            boxShadow: "none",
          }}
          max={maxDeals}
          value={runningDeals}
          color={maxDeals === runningDeals ? "warning" : "success"}
        /> : null
      }
      {
        maxDeals === runningDeals ?
        <NotificationInfoBlock style={{ marginTop: "11px" }}>
          <p className="font-sm text-white text-center m-0">
            Maximum running deals limit reached
          </p>
        </NotificationInfoBlock>
        : null
      }
    </div>
  );
}

export default DealsProgress;
