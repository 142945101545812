import React from "react";
import { createRoot } from 'react-dom/client';

import App from "views/App";
import { authenticate } from "auth.js";

import "assets/css/fontawesome-all.css";
import "assets/css/nucleo-icons.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.2.0";
import "assets/demo/demo.css";
import "react-notification-alert/dist/animate.css";
import "rc-slider/assets/index.css";
import "react-circular-progressbar/dist/styles.css";
import 'react-virtualized/styles.css';

(async() => {
  const token = localStorage.getItem("token");

  if (token) {
    await authenticate(token);
  }

  const container = document.getElementById("root");
  const root = createRoot(container);
  root.render(<App />);
})();
