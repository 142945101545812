import React, { useEffect, useState } from "react";
import Button from "components/Button";
import {
  FormGroup,
  Label,
} from "reactstrap";
import {
  Formik,
  Form,
  Field
} from "formik";
import styled from "styled-components";
import { handleResponse } from "helpers/errorsValidator";
import { login } from "auth";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import FormGroupWithSymbols from "components/FormGroupWithSymbols";
import { addSnackBar } from "store/snackbar/actions";
import {handleEmailSchema, handleInitEmailValues} from "../compounds/userConfiguration";

const ChangeEmailForm = ({
  changeEmail,
  emailForChange,
  confirmEmail,
  onResendEmail,
  loading,
  setIsChangeEmail,
  onCancelEmail,
  setNewEmail,
  newEmail,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);

  const initialValues = handleInitEmailValues()

  const dispatch = useDispatch()

  useEffect(() => {
    if (emailForChange) {
      setNewEmail({email: emailForChange});
    }
  }, [emailForChange, setNewEmail]);

  const handleResend = async (setFieldValue ,e) => {
    e.preventDefault();
    const res = await dispatch(onResendEmail());
    if (res.message) {
      dispatch(addSnackBar("success", res.message));
      setIsChangeEmail(true);
    }
    else {
      setIsChangeEmail(false);
    }
    setFieldValue("secret", "");
  }

  const handleCancel = async (setFieldValue, setSubmitting, errors) => {
    setSubmitting(false)
    setIsCancelLoading(true)
    const res = await dispatch(onCancelEmail())
    setSubmitting(true)
    dispatch(addSnackBar("success", res.message));
    setIsCancelLoading(false)
    delete errors?.email
    setNewEmail({email: "", id: null});
    setFieldValue("email", "")
    setFieldValue("secret", "")
    setIsChangeEmail(false)
    return null;
  }

  const renderError = (errors) => {
    return submitted && errors ?
      <p className="mb-0 font-weight-300 text-danger mb-3 font-sm">
        {errors.email || errors.secret || errors.message}
      </p>
      : null
  }

  if (!loading) {
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={handleEmailSchema(newEmail.email)}
        onSubmit={async(values, formik) => {
          formik.setSubmitting(true);
          setSubmitted(true)
          const res = newEmail.email ? await dispatch(confirmEmail(values)) : await dispatch(changeEmail({email: values.email}));
          if (res.errors) {
            handleResponse(res, formik.setErrors);
          }
          if (res.token || res.refreshToken) {
            setIsChangeEmail(false)
            await login(res.token, res.refreshToken)
            formik.setStatus({ message: res.message });
            dispatch(addSnackBar("success", res.message))
            setNewEmail({ email: res.email.new, id: res.request });
            formik.setFieldValue("secret", null)
            formik.setFieldValue("email", null)
          }
          if (res.request) {
            setNewEmail({ email: res.email.new, id: res.request });
            dispatch(addSnackBar("success", res.message))
          }
          else {
            formik.setStatus({ message: res.message });
            setNewEmail({ email: res.email.new, id: res.request });
          }
          setSubmitted(false)
          formik.setSubmitting(false);
        }}
      >
        {({errors, isSubmitting, setFieldValue, setSubmitting }) => (
          <>
            <Form>
              <ChangeEmailForm.Wrapper>
                <h4 className="font-weight-500 text-white">Change your email</h4>
                <p className='text-white-75 font-sm mb-4'>
                  {
                    newEmail.email ? `The code has been successfully sent to ${newEmail.email} Please enter this code here to verify your e-mail` :
                    "Please enter your new e-mail address below."
                  }
                </p>
                <Label className="text-white-75 font-sm">E-mail</Label>
                {
                  newEmail.email ?
                  <FormGroupWithSymbols
                    symbolRight={
                      <ChangeEmailForm.Link
                        href="/#"
                        onClick={(e) => handleResend(setFieldValue, e)}
                        className="link-info"
                      >
                        Resend
                      </ChangeEmailForm.Link>
                    }
                  >
                    <Field
                      placeholder="Type in here..."
                      disabled={isSubmitting}
                      type="code"
                      name="secret"
                      className={classnames("form-control p-0 mb-2", {
                        "has-error": submitted && (errors.secret || errors.message)
                      })}
                    />
                  </FormGroupWithSymbols> :
                  <FormGroup>
                    <Field
                      className={classnames("form-control mb-2", {
                        "has-error": submitted && errors.email
                      })}
                      type="email"
                      name="email"
                      placeholder="Type in..."
                    />
                  </FormGroup>
                }
                {renderError(errors)}
              </ChangeEmailForm.Wrapper>
              <div className="mt-3">
                <Button
                  color="blue"
                  loading={isSubmitting}
                  className="pull-right w-100 mb-2 mt-1"
                  onClick={() => setSubmitted(true)}
                  type="submit"
                >
                  Change
                </Button>
              </div>
            </Form>
            <Button
              color="gray"
              loading={isCancelLoading}
              className="pull-right w-100"
              onClick={() => handleCancel(setFieldValue, setSubmitting, errors)}
              type="reset"
            >
              Cancel
            </Button>
          </>
        )}
      </Formik>
    );
  }
};

ChangeEmailForm.Wrapper = styled.div`
  .resend {
    text-decoration: underline;
  }
`

ChangeEmailForm.Link = styled.a`
  width: inherit;

  &:hover {
    text-decoration: underline;
    color: rgba(47, 128, 237, 0.8) !important;
  }
`;

export default ChangeEmailForm;
