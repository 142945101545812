import React, { useState } from "react";
import { Card } from "reactstrap";
import PropTypes from "prop-types";

import SignalTableHeader from "./SignalTableHeader";
import SignalTableBody from "./SignalTableBody";
import CreateTargetsModal from "views/signals/modals/CreateTargetsModal";
import SignalProgressBar from "views/signals/compounds/SignalProgressBar";

const SignalProgress = ({
  loading, 
  data
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleToggleModal = () => setIsModalOpen((prev) => !prev);

  return (
    <>
      <Card className="pt-3">
        <SignalTableHeader 
          loading={loading}
        />
        <SignalProgressBar
          loading={loading}
          chart={data?.chart || []}
        />
        <SignalTableBody
          loading={loading}
          data={data}
        />
      </Card>
      <CreateTargetsModal
        isModalOpen={isModalOpen}
        handleToggleModal={handleToggleModal}
      />
    </>
  );
};

SignalProgress.propTypes = {
  loading: PropTypes.bool,
};

export default SignalProgress;