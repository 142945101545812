import React from "react";
import styled from "styled-components";
import {
  Card,
  CardBody,
  CardHeader
} from "reactstrap";

import { ReactComponent as BalanceIcon } from "assets/img/icons/BalanceIcon.svg";
import BalancePreloader from "../preloaders/BalancePreloader";
import PercentBadge from "components/PercentBadge";

const BacktestBalance = ({ balance={}, summary={}, loading }) => {
  if (loading) return <BalancePreloader />;

  return (
    <Card>
      <CardHeader className="px-4 pt-4 pb-0">
        <div className="d-flex align-items-center">
          <StyledIcon />
          <p className="mb-0 ml-2 text-white font-weight-500">
            Balance
          </p>
        </div>
      </CardHeader>
      <CardBody className="p-4">
        {
          balance.final?.amount ?
          <>
              <p className="mb-0 font-sm text-white-35">
                Final
              </p>
              <div className="d-flex flex-wrap flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between text-nowrap">
                <p className="text-white h2 mb-2 mb-sm-0 mb-md-2 mb-lg-0 mr-2">
                  {balance.final?.amount}
                  <span className="text-white-35 ml-1 font-weight-300">
                    {balance.final?.asset}
                  </span>
                </p>
                <PercentBadge
                  value={balance.final?.percent}
                  isDefault={false}
                  style={{width: "fit-content"}}
                />
              </div>
            <hr />
          </> : null
        }
        <div className="d-flex justify-content-between">
          <p className="font-sm text-white-75 font-sm">
            Initial
          </p>
          <p>
            <span className="text-white font-weight-500 mr-1">
              {balance.initial?.amount}
            </span>
            <span className="text-white-35 font-weight-300">
              {balance.initial?.asset}
            </span>
          </p>
        </div>
        {
          balance.maximum?.amount ?
          <div className="d-flex justify-content-between">
            <p className="font-sm text-white-75 font-sm">
              Maximum
            </p>
            <p>
              <span className="text-white font-weight-500 mr-1">
                {balance.maximum?.amount}
              </span>
              <span className="text-white-35 font-weight-300">
                {balance.maximum?.asset}
              </span>
            </p>
          </div> : null
        }
        {
          balance.minimum?.amount ?
          <div className="d-flex justify-content-between">
            <p className="font-sm text-white-75 font-sm">
              Minimum
            </p>
            <p>
              <span className="text-white font-weight-500 mr-1">
                {balance.minimum?.amount}
              </span>
              <span className="text-white-35 font-weight-300">
                {balance.minimum?.asset}
              </span>
            </p>
          </div> : null
        }
        {
          summary.totalProfit?.amount ?
          <div className="d-flex justify-content-between">
            <p className="font-sm text-white-75 font-sm">
              Total Return
            </p>
            <p>
              <span className="text-white font-weight-500 ml-2 mr-1">
                {summary.totalProfit?.amount}
              </span>
              <span className="text-white-35 font-weight-300">
                {summary.totalProfit?.asset}
              </span>
            </p>
          </div> : null
        }
      </CardBody>
    </Card>
  );
};

const StyledIcon = styled(BalanceIcon)`
  & path {
    stroke: #007bff;
  }
`;

export default BacktestBalance;
