import React, {useState} from "react";
import {Col, Tooltip} from "reactstrap";
import styled from "styled-components";
import {ReactComponent as Help} from "assets/img/icons/Help.svg";
import SkeletonPreloader from "components/SkeletonPreloader";

const ManageAllocationTitles = ({initialPlannedCount, loading, enabled}) => {
  const [tooltipOpenInvested, setTooltipOpenInvested] = useState(false);
  const [tooltipOpenAllocation, setTooltipOpenAllocation] = useState(false);

  const toggleInvested = () => setTooltipOpenInvested(!tooltipOpenInvested);
  const toggleAllocation = () => setTooltipOpenAllocation(!tooltipOpenAllocation);

  if (loading) {
    return (
      <div className="d-flex justify-content-around mb-2">
        <SkeletonPreloader height={12} width={37} count={5}/>
      </div>
    )
  }

  return (
    <div className="d-none d-md-flex text-nowrap mx-4 px-2 mb-2">
      <Col md="6" className="d-flex flex-column flex-sm-row align-items-sm-center p-0">
        <div className="d-flex align-items-center text-nowrap col-sm-5 p-0">
          <p className="mb-0 text-white-35 font-weight-300 font-sm pl-4">
            Coin
          </p>
        </div>

        <div className="d-flex align-items-center col-md-6 px-0">
          <p className="mb-0 ml-4 pl-1 text-white-35 font-weight-300 font-sm">
            Available & Invested
          </p>
          <div className="d-flex ml-1" id="invested">
            <Help/>
          </div>
          <Tooltip
            placement="top"
            isOpen={tooltipOpenInvested}
            target="invested"
            toggle={toggleInvested}
          >
            Sum of available and invested balances
          </Tooltip>
        </div>
      </Col>

      <Col md="6" className="d-flex flex-column flex-sm-row align-items-center mt-3 mt-md-0 p-0">
        <Col md="6" className="p-0">
          <div className="d-flex align-items-center">
            <p className="mb-0 text-white-35 font-weight-300 font-sm">
              Allocation
            </p>
            <div className="d-flex ml-1" id="allocation">
              <Help/>
            </div>
            <Tooltip
              placement="top"
              isOpen={tooltipOpenAllocation}
              target="allocation"
              toggle={toggleAllocation}
            >
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <ManageAllocationTitles.Circle color="#6C78F9" className="mr-2"/>
                  Real percent
                </div>

                {
                  initialPlannedCount && enabled ?
                    <div className="d-flex align-items-center">
                      <div className="d-flex mr-1 position-relative">
                        <ManageAllocationTitles.Circle color="#3FCBB0"/>
                        <ManageAllocationTitles.Circle
                          color="#ED5F5F"
                          className="position-absolute"
                          style={{top: "-1px", left: "5px"}}
                          border
                        />
                      </div>
                      <p className="mb-0 ml-1 text-dark">
                        Planned percent
                      </p>
                    </div> : null
                }
              </div>
            </Tooltip>
          </div>
        </Col>

        <Col md="5" lg="5" className="d-flex justify-content-between">
          <Col sm="2">
            <p className="mb-0 text-white-35 font-weight-300 font-sm">
              {initialPlannedCount ? "Planned %" : "Real %"}
            </p>
          </Col>

          <Col sm="3">
            <p className="mb-0 text-white-35 font-weight-300 font-sm">Result</p>
          </Col>
        </Col>
      </Col>
    </div>
  )
}


ManageAllocationTitles.Circle = styled.div`
  display: block;
  width: ${({border}) => border ? "9.5px" : "8px"};
  height: ${({border}) => border ? "9.5px" : "8px"};
  border-radius: 50px;
  background: ${({color}) => color};
  border: ${({border}) => border ? "1px solid white" : "none"};
`;

export default ManageAllocationTitles;