import React from 'react';
import {
  Card,
  CardBody,
  CardHeader
} from "reactstrap";
import { times } from "lodash";

import SkeletonPreloader from "components/SkeletonPreloader";

const TimeLinePreloader = () => {
  return (
    <Card>
      <CardHeader className="px-4 pt-4 pb-0">
        <div className="d-flex align-items-center">
          <SkeletonPreloader
            height={20}
            width={20}
            circle
          />
          <SkeletonPreloader
            height={16}
            width={103}
            className="ml-2"
          />
        </div>
      </CardHeader>
      <CardBody className="p-4">
        <div className="d-flex justify-content-between mb-3">
          <SkeletonPreloader
            height={10}
            width={57}
          />
          <SkeletonPreloader
            height={12}
            width={129}
          />
        </div>
        {
          times(3, (e) =>
            <div
              className="d-flex justify-content-between mb-3"
              key={e}
            >
              <div className="d-flex align-items-center">
                <SkeletonPreloader
                  height={10}
                  width={57}
                />
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex flex-column mb-4">
                  <SkeletonPreloader
                    height={8}
                    width={39}
                    className="mb-1"
                  />
                  <SkeletonPreloader
                    height={12}
                    width={129}
                  />
                </div>
                <div className="d-flex flex-column">
                  <SkeletonPreloader
                    height={8}
                    width={39}
                    className="mb-1"
                  />
                  <SkeletonPreloader
                    height={12}
                    width={129}
                  />
                </div>
              </div>
            </div>
          )
        }
        <div className="d-flex justify-content-between mb-3">
          <SkeletonPreloader
            height={10}
            width={57}
          />
          <SkeletonPreloader
            height={12}
            width={129}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default TimeLinePreloader;
