import React from "react";

import Loading from "components/Loading";
import { CandlestickChartWrap } from "../index.styles";

const CandlestickChartPreloader = () => {
  return (
    <CandlestickChartWrap className="d-flex flex-column align-items-center justify-content-center bg-transparent">
      <Loading color="var(--blue)" />
      <p className="text-white font-weight-500 mt-3">
        Loading Signals...
      </p>
    </CandlestickChartWrap>
  );
};

export default CandlestickChartPreloader;
