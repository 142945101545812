import React, { useState } from "react";
import styled from "styled-components";
import { Tooltip } from "reactstrap";
import { ReactComponent as Message } from "assets/img/icons/message.svg";

const AllocationCommentsTooltip = ({ index, comments }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <AllocationCommentsTooltip.Icon>
        <Message id={`comment-${index}`}/>
      </AllocationCommentsTooltip.Icon>
      <Tooltip placement="top" isOpen={tooltipOpen} target={`comment-${index}`} toggle={toggle}>
        {comments}
      </Tooltip>
    </>
  )
}

AllocationCommentsTooltip.Icon = styled.div`
  svg {
    &:focus,
    &:active {
      outline: none;
    }
  }
`;

export default AllocationCommentsTooltip;
