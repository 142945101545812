import React from "react";
import {Table} from "reactstrap";
import _ from "lodash";
import SkeletonPreloader from "components/SkeletonPreloader";

const ExchangeTablePreload = () => {
  return (
    <Table className="mb-0 mx-4">
      <thead>
        <tr>
          {_.times(5, (e) =>
            <th key={e}>
              <SkeletonPreloader width={48} height={12}/>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {_.times(7, (e) =>
          <tr key={e}>
            <td>
              <div className="d-flex align-items-center">
                <SkeletonPreloader width={32} height={32} circle/>
                <SkeletonPreloader width={48} height={14} className="ml-2"/>
              </div>
            </td>
            <td>
              <SkeletonPreloader width={120} height={14}/>
            </td>
            <td>
              <SkeletonPreloader width={112} height={14}/>
            </td>
            <td>
              <SkeletonPreloader width={118} height={14}/>
            </td>
            <td>
              <SkeletonPreloader width={83} height={14}/>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

export default ExchangeTablePreload;