import React from "react";
import styled from "styled-components";

import Icon from "components/Icon";

const BadgeWithIcon = ({
  iconType="exchanges",
  iconSize="thumb",
  iconCode,
  isDark=true,
  text,
  className
}) => {
  return (
    <BadgeWithIcon.Badge
      className="d-flex justify-content-center align-items-center pl-1 pr-2 py-1"
      isDark={isDark}
    >
      <Icon
        size={iconSize}
        type={iconType}
        code={iconCode}
      />
      <p className={`ml-1 m-0 my-auto ${className}`}>
        {text}
      </p>
    </BadgeWithIcon.Badge>
  );
};

BadgeWithIcon.Badge = styled.div`
  background: ${(props) => props.isDark ? "#363A54" : "rgba(29, 30, 42, 0.05)"};
  display: flex;
  justify-content: center;
  border-radius: 100px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  position: relative;

  p {
    line-height: 1.17;
    color: ${(props) => props.isDark ? "#fff" : "#1D1E2A"};
    font-weight: 500;
    text-transform: capitalize;
  }
`;

export default BadgeWithIcon;
