import React from "react";
import classnames from "classnames";
import { capitalize } from "lodash";
import moment from "moment";
import { Badge } from "reactstrap";

import { REQUESTS_ORDERS_TABLE_HEAD } from "models/deal/enum/tables";
import { formatterToNull } from "helpers/valuesFormatters";
import SortTableHead from "components/SortTableHead";
import StyledTable from "components/StyledTable";
import OrdersProgressBar from "components/OrdersProgressBar";
import TablePreloader from "components/TablePreloader";
import {
  TableAmount,
  TableCurrency,
  TableStatus
} from "components/StyledTradeTableComponents";

const OrdersTable = ({
  data,
  sort,
  handleSort,
  ordersRequestsLoading
}) => {

  if (ordersRequestsLoading) {
    return <TablePreloader
      rowTimes={5}
      tdHeight={14}
      tdWidth={[32, 85, 39, 53, 77, 77, 52, 105, 81, 73, 93]}
    />
  }

  return (
    <StyledTable
      className="mb-0"
      paddingsThead="0.5rem 1rem"
      backgroundThead="#2A2C42"
    >
      <SortTableHead
        handleSorting={handleSort}
        sort={sort}
        theadItems={REQUESTS_ORDERS_TABLE_HEAD}
      />
      <tbody className="font-sm">
        {
          data?.items?.map((item) =>
            <tr key={item.id}>
              <td>
                <Badge
                  style={{
                    background: item?.type === "BUY" ? "#3FCBB0" : "#ED5F5F",
                    color: "#FFFFFF",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "16px",
                    display: "block",
                    margin: "0 2rem 0 0"
                  }}
                >
                  {item?.type?.slice(0, 1)}
                </Badge>
              </td>
              <td>
                <p className="mb-0 text-white font-weight-300">
                  {formatterToNull(item.orderNumber)}
                </p>
              </td>
              <td>
                <TableAmount amount={item.rate}>
                  {formatterToNull(item.rate)}
                </TableAmount>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <TableAmount amount={item.total.amount}>
                    {formatterToNull(item.total.amount)}
                  </TableAmount>
                  <TableCurrency>{item.total.currency}</TableCurrency>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <OrdersProgressBar filledPercent={item.filledPercent} />
                  <p className="mb-0 font-sm font-weight-500 text-white-35">
                    {`${item.filledPercent || 0}%`}
                  </p>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <TableAmount amount={item.executedAmount.amount}>
                    {formatterToNull(item.executedAmount.amount)}
                  </TableAmount>
                  <TableCurrency>
                    {item.executedAmount.currency}
                  </TableCurrency>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <TableAmount amount={item.executedQuantity.amount}>
                    {formatterToNull(item.executedQuantity.amount)}
                  </TableAmount>
                  <TableCurrency>
                    {item.executedQuantity.currency}
                  </TableCurrency>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <TableAmount amount={item.fee.amount}>
                    {formatterToNull(item.fee.amount)}
                  </TableAmount>
                  <TableCurrency>
                    {item.fee.currency}
                  </TableCurrency>
                </div>
              </td>
              <td>
                <TableAmount amount={item.tradesCount}>
                  {formatterToNull(item.tradesCount)}
                </TableAmount>
              </td>
              <td>
                <p className="text-white m-0 font-weight-500">
                  {formatterToNull(item.exchangeStatus)}
                </p>
              </td>
              <td>
                <TableStatus>
                  <p className={classnames("mb-0 font-sm", {
                    "text-success": item.status === "completed",
                    "text-white-35": item.status === "cancelled" || item.status === "pending_cancel",
                    "text-info": item.status === "open",
                  })}>{capitalize(item.status)?.replace("_", " ")}</p>
                </TableStatus>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <TableCurrency>
                    {moment(item.createdAt).format("DD.MM.YY")}
                  </TableCurrency>
                  <TableAmount amount={1} className="ml-1">
                    {moment(item.createdAt).format("HH:mm")}
                  </TableAmount>
                </div>
              </td>
            </tr>
          )
        }
      </tbody>
    </StyledTable>
  )
};

export default OrdersTable;
