import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Progress } from "reactstrap";

import { formatterToNull, formatValue, setColorClass } from "helpers/valuesFormatters";
import BotsListToggle from "../BotsListToggle";
import MultiProgress from "../MultiProgress";
import MarketBotsTooltips from "./MarketBotsTooltips";
import BotErrorTooltip from "./BotErrorTooltip";
import BotNameWithIcon from "components/BotNameWithIcon";
import LineWithError from '../../shared/ui/LineWithError';

const BotsTableRow = ({ item, id, index }) => {

  const history = useHistory();

  const handleToBot = (event, botId) => {
    event.stopPropagation();
    history.push({ pathname: `/bots/${botId}/configure`, state: { investment: id } });
  };

  return (
    <BotsTableRow.Wrapp className="font-sm">
      <BotsTableRow.Row
        key={item.id}
        onClick={() => history.push(`/bots/${item.id}`)}
        onMouseDown={(e) => e.button === 1 && window.open(`/bots/${item.id}`, "_blank")}
        className="cursor-pointer bg-transparent"
      >
        <td className="d-flex align-items-center border-0 bg-transparent">
          <BotsTableRow.NameWrapper>
            <BotNameWithIcon
              bot={item}
              fontClassName="font-sm"
            />
          </BotsTableRow.NameWrapper>
            <BotErrorTooltip error={item.error} index={index} />
        </td>

        <td>
          <p className="mb-0 text-white font-sm font-weight-500">
            {formatterToNull(item.marketsCount)}
          </p>
        </td>

        <td>
          {
            !item.statistics.profit.percent && !item.statistics.loss.percent
              ? <p className="mb-0 font-sm text-white-35">No deals yet</p>
              : <>
                <MultiProgress height="12px" style={{ width: "93px" }} id={`multi-${index}`}>
                  <Progress
                    bar
                    className="text-success"
                    color="success"
                    value={+item.statistics.profit.percent}
                  />
                  <Progress
                    bar
                    className="text-danger"
                    color="danger"
                    value={+item.statistics.loss.percent}
                  />
                </MultiProgress>
                <MarketBotsTooltips
                  index={index}
                  loss={item.statistics.loss}
                  profit={item.statistics.profit}
                />
              </>
          }
        </td>

        <td>
          <div className="d-flex align-items-center font-sm text-nowrap">
            <p className={`${setColorClass(item.totalReturn, "text-white-35")} mb-0 font-weight-500 font-sm`}>
              {formatValue(item.totalReturn || 0)}
            </p>
            <p className="mb-0 ml-1 font-sm text-white-35 font-weight-300">{item.investment.asset}</p>
          </div>
        </td>

        <td>
          <p className="mb-0 text-white font-sm font-weight-500">{formatterToNull(item.period)} min</p>
        </td>

        <td>
          <p className="mb-0 text-white font-sm font-weight-500">
            {formatterToNull(item.countDeals)}
          </p>
        </td>

        <td>
          <div onClick={(e) => e.stopPropagation()}>
            <BotsListToggle
              id={item.id}
              status={item.status}
              isList
            />
          </div>
        </td>

        <td>
          <p className="link-info" onClick={(e) => handleToBot(e, item.id)}>Edit</p>
        </td>

      </BotsTableRow.Row>

      {item?.error && <BotsTableRow.Row className="bg-transparent">
        <td colSpan={8}
          className="pt-0"
        >
          <LineWithError
            radius="4px"
            errorMessage={item?.error}
          />
        </td>
      </BotsTableRow.Row>
      }
    </BotsTableRow.Wrapp>
  )
}

BotsTableRow.Row = styled.tr`
  &:last-child {
    border-bottom: 1px solid #2E3148;
  };
`;

BotsTableRow.NameWrapper = styled.div`
  width: 95%;
`;

BotsTableRow.Wrapp = styled.tbody`
  border-top:none !important;
  cursor:pointer;
    &:hover{
      background:#2E3148 !important;
    }
`;

export default BotsTableRow;
