import React from "react";
import { Chart as ReactChart } from "react-chartjs-2";
import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  TimeScale,
  Tooltip,
  registerables
} from "chart.js";
import { CandlestickController, CandlestickElement } from "chartjs-chart-financial";
import moment from "moment";
import "chartjs-adapter-moment";
import zoomPlugin from "chartjs-plugin-zoom";

import { candlestickChartOptions } from "./options"
import LineWithInform from "components/LineWithInform";
import SupportContactNotification from "components/SupportContactNotification";
import CandlestickChartPreloader from "./preloaders/CandlestickChartPreloader";

import { CandlestickChartWrap } from "./index.styles";

ChartJS.register(
  ...registerables,
  CandlestickController,
  CandlestickElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  Tooltip,
  zoomPlugin
);

const CandlestickChart = ({
  candlestickChart,
  indicatorSignals,
  loading,
  error
}) => {
  if (loading) return <CandlestickChartPreloader/>;

  if (error) return (
    <SupportContactNotification
      infoMessage="System error detected. Please contact support via email:"
      linkText="hello@darkbot.io"
      redirectTo="mailto:hello@darkbot.io"
    />
  );

  if (!candlestickChart && !loading) return (
    <LineWithInform
      infoMessage="Please select indicators to check signals and display a chart with relevant buy and sell points for the selected market. Click the button 'Check Signals' to transfer the parameters and retrieve the actual points for the chart."
    />
  );


  const { candles } = candlestickChart;
  const { signals } = indicatorSignals;

  const chartData = {
    datasets: [{
      label: "Price",
      data: Object.keys(candles).map(key => {
        const candle = candles[key];
        return {
          x: moment(candle.startTime, "YYYY-MM-DD HH:mm:ss"),
          o: candle.open,
          h: candle.high,
          l: candle.low,
          c: candle.close,
          signal: signals[key]
        };
      }),
      borderColor: "black",
      backgroundColor: context => {
        const dataPoint = context.raw;
        return dataPoint.signal === "buy" ? "rgba(0, 255, 0, 0.5)" :
          dataPoint.signal === "sell" ? "rgba(255, 0, 0, 0.5)" :
            "rgba(0, 0, 0, 0)";
      },
      borderWidth: 1,
      barThickness: 7
    }]
  };

  return (
    <CandlestickChartWrap data-testid="candlestick-chart-wrap">
      <ReactChart
        type="candlestick"
        data={chartData}
        options={{ ...candlestickChartOptions }}
      />
    </CandlestickChartWrap>
  );
};

export default CandlestickChart;
