import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {ReactComponent as Chart} from "assets/img/icons/presentionchart.svg";
import {ReactComponent as ProfitableDeals} from "assets/img/icons/ProfitableDeals.svg";
import {ReactComponent as LossDeals} from "assets/img/icons/LossDeals.svg";
import {formatValue, setColorClass} from "helpers/valuesFormatters";
import DealsStatisticsWidgetPreload from "../../preloaders/DealsStatisticsWidgetPreload";
import BotProgressBar from "../../compounds/BotProgressBar";
import moment from "moment";

const GeneralMetrics = ({data = {}, loadingPage}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (loading || loadingPage) return <DealsStatisticsWidgetPreload/>;

  return (
    <Card className="mb-4">
      <CardHeader className="d-flex align-items-center m-0 px-4 pt-4">
        <Chart/>
        <p className="mb-0 ml-2 text-white font-weight-500 font-md">General metrics</p>
      </CardHeader>
      <CardBody className="p-4">
        <Row className="no-gutters">
          <Col xs="12" lg="8" className="pr-lg-4">
            <div className="d-flex align-items-center justify-content-between" style={{gap: "8px"}}>
              <GeneralMetrics.Deals>
                <div>
                  <h1 className="mb-0 text-white font-weight-500">
                    {data.statistics?.profit?.count}
                  </h1>
                  <p className="mb-0 mt-1 text-white-75 font-sm">Profit deals</p>
                </div>
                <ProfitableDeals/>
              </GeneralMetrics.Deals>
              <GeneralMetrics.Deals>
                <div>
                  <h1 className="mb-0 text-white font-weight-500">
                    {data.statistics?.loss?.count}
                  </h1>
                  <p className="mb-0 mt-1 text-white-75 font-sm">Loss deals</p>
                </div>
                <LossDeals/>
              </GeneralMetrics.Deals>
            </div>
            <BotProgressBar
              profitPercent={data.statistics?.profit?.percent}
              profitAmount={data.totalProfit}
              lossPercent={data.statistics?.loss?.percent}
              lossAmount={data.totalLoss}
              profitTitle="Total profit"
              lossTitle="Total loss"
            />
          </Col>

          <Col
            xs="12" lg="4"
            className="d-flex flex-column justify-content-between flex-sm-row flex-lg-column mt-3 pl-lg-3 mt-lg-0 "
          >
            <div>
              <p className="mb-0 text-white-75 font-sm">Total return</p>
              <div className="d-flex align-items-center mt-2 text-nowrap">
                <h3 className={`mb-0 font-weight-500 ${setColorClass(data.totalReturn, "text-white-35")}`}>
                  {formatValue(data.totalReturn || 0)}
                </h3>
                <h3 className="mb-0 text-white-35 font-weight-300 ml-1">{data.investment?.asset}</h3>
              </div>
              <p className="mb-0 mt-1 text-white-35 font-xs">From {moment(data.createdAt).format("DD MMMM YYYY")}</p>
            </div>

            <div className="mt-2 mt-sm-0">
              <p className="mb-0 text-white-75 font-sm">Daily return</p>
              <div className="d-flex align-items-center mt-2 text-nowrap">
                <h3 className={`mb-0 font-weight-500 ${setColorClass(data.dailyReturn, "text-white-35")}`}>
                  {formatValue(data.dailyReturn || 0)}
                </h3>
                <h3 className="mb-0 text-white-35 font-weight-300 ml-1">{data.investment?.asset}</h3>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
};

GeneralMetrics.Deals = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #2E3148;
  border-radius: 8px;
  padding: 12px 20px;
  width: 100%;
`;

export default GeneralMetrics;
