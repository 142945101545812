import React from "react";
import { Card } from "reactstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";

import SignalBlock from "./SignalBlock";
import SignalParameters from "./SignalParameters";
import SignalChannelBlock from "./SignalChannelBlock";
import SignalProgressBar from "./SignalProgressBar";
import MyAgreements from "./MyAgreements";
import {
  SignalBlockPropTypes,
  SignalChannelBlockPropTypes
} from "../signalPropTypes";

const Signal = ({
  loading,
  signal,
  id,
  createdAt,
  channelId,
  status,
  base,
  quote,
  exchange,
  channelName,
  channelTotalSubscribers,
  isSubscribed,
  channelAvatar,
  agreements,
  chart
}) => {
  return (
    <>
      <Link
        to={`signals/${id}`}
        key={id}
      >
        <Signal.Card className="d-flex flex-column  p-4 mb-0">
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <SignalBlock
              createdAt={createdAt}
              status={status}
              base={base}
              quote={quote}
              exchange={exchange}
              chart={chart}
            />
            <SignalChannelBlock
              channelId={channelId}
              name={channelName}
              avatar={channelAvatar}
              isSubscribed={isSubscribed}
              totalSubscribers={channelTotalSubscribers}
              loading={loading}
            />
          </div>
          <Signal.ChartWrap className="mt-5">
            <SignalProgressBar
              signal={signal}
              chart={chart}
              className=""
            />
            <SignalParameters signal={signal}/>
          </Signal.ChartWrap>
        </Signal.Card>
      </Link>
      <MyAgreements
        signalId={id}
        agreements={agreements}
        loading={loading}
        status={status}
      />
    </>
  );
};

Signal.Card = styled(Card)`

  &.card {
    border-radius: 0.5rem 0.5rem 0 0 !important;
  }

  &:hover {
    background: #363A54;
  }
`;

Signal.ChartWrap = styled.div`
  min-width:50%;

  @media(max-width: 768px){
    min-width:75%;
  }
`;

Signal.propTypes = {
  ...SignalChannelBlockPropTypes,
  ...SignalBlockPropTypes
};

export default Signal;