import React, { useState } from "react";
import styled from "styled-components";
import classNames from "classnames";
import AttachedIndicators from "./AttachedIndicators";
import BacktestHistory from "./BacktestHistory";
import BotsList from "components/MarketBots/BotsList";

const AdditionalData = ({
  strategy,
  loading,
  location,
  id
}) => {
  const [tab, setTab] = useState("Indicators");

  return(
    <>
      <div className="d-flex">
        <AdditionalData.Tab
          onClick={() => setTab("Indicators")}
          className={classNames("p-3 mr-2 mb-3", {
            regular: tab !== "Indicators",
            active: tab === "Indicators"
          })}
        >
          Indicators
        </AdditionalData.Tab>
        <AdditionalData.Tab
          onClick={() => setTab("Bots")}
          className={classNames("p-3 mr-2 mb-3", {
            regular: tab !== "Bots",
            active: tab === "Bots"
          })}
        >
          Attached bots
        </AdditionalData.Tab>
        <AdditionalData.Tab
          onClick={() => setTab("Backtest history")}
          className={classNames("p-3 mr-2 mb-3", {
            regular: tab !== "Backtest history",
            active: tab === "Backtest history"
          })}
        >
          Backtest history
        </AdditionalData.Tab>
      </div>
      {
        tab === "Indicators" ?
        <AttachedIndicators
          indicators={strategy.indicators}
          loading={loading}
          id={id}
        /> : null
      }
      {
        tab === "Bots" ?
        <BotsList
          id={id}
          location={location}
          filter="strategy"
        /> : null
      }
      {
        tab === "Backtest history" ?
        <BacktestHistory
          location={location}
          id={id}
        /> : null
      }
    </>
  );
};

AdditionalData.Tab = styled.div`
  border-radius: 4px;
  cursor: pointer;
  color: #fff;

  &.regular {
    background: var(--dark-slate);
  }

  &.active {
    background: #2E3148;
  }
`;

export default AdditionalData;
