import React from "react";

import {
  formatterToNull,
  setColorClass
} from "helpers/valuesFormatters";
import {
  numberOrNull,
  stringOrNull
} from "views/channels/channelPropTypes";
import PercentBadge from "components/PercentBadge";

const MyAgreementsBody = ({
  quote,
  base,
  invested,
  purchased,
  received,
  sold,
  unsold,
  profit,
  profitPercent,
  isList
}) => (
  <div className={` ${isList || "justify-content-between"} d-flex mt-4 flex-wrap`}>
    <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2 mr-4">
      <p className="mb-0 text-white-75">
        Invested
      </p>
      <p className="mb-0 font-weight-500 text-white">
        {formatterToNull(invested)}{" "}
        <span className="text-white-35 font-weight-300 ">
          {quote}
        </span>
      </p>
    </div>
    <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2 mr-4">
      <p className="mb-0 text-white-75">
        Purchased
      </p>
      <p className="mb-0 font-weight-500 text-white">
        {formatterToNull(purchased)}{" "}
        <span className="text-white-35 font-weight-300 ">
          {base}
        </span>
      </p>
    </div>
    <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2 mr-4">
      <p className="mb-0 text-white-75">
        Received
      </p>
      <p className="mb-0 font-weight-500 text-white">
        {formatterToNull(received)}{" "}
        <span className="text-white-35 font-weight-300 ">
          {quote}
        </span>
      </p>
    </div>
    <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2 mr-4">
      <p className="mb-0 text-white-75">
        Sold
      </p>
      <p className="mb-0 font-weight-500 text-white">
        {formatterToNull(sold)}{" "}
        <span className="text-white-35 font-weight-300 ">
          {base}
        </span>
      </p>
    </div>
    <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2">
      <p className="mb-0 text-white-75">
        Unsold
      </p>
      <p className="mb-0 font-weight-500 text-white">
        {formatterToNull(unsold)}{" "}
        <span className="text-white-35 font-weight-300 ">
          {base}
        </span>
      </p>
    </div>
    {
      isList && <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2 ml-auto mr-0">
        <p className="mb-0 text-white-75">
          Profit {" "}
          <PercentBadge
            value={profitPercent}
            showZeroValue
          />
        </p>
        <p className={`${setColorClass(profit)} mb-0 font-weight-500`}>
          {formatterToNull(profit)}{" "}
          <span className="text-white-35 font-weight-300 ">
            {quote}
          </span>
        </p>
      </div>
    }
  </div>
);

MyAgreementsBody.propTypes = {
  quote: stringOrNull,
  invested: numberOrNull,
  purchased: numberOrNull,
  received: numberOrNull,
  sold: numberOrNull,
  unsold: numberOrNull,
  profit: numberOrNull,
  profitPercent: numberOrNull
};

export default MyAgreementsBody;