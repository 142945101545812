import React from "react";
import {CardHeader} from "reactstrap";
import {ReactComponent as Title} from "assets/img/icons/receiptsearch.svg";
import RefreshButton from "components/RefreshButton";

const MarketHeader = ({handleRefresh, currentCredId, loadingOrders, loadingTrades}) => {
  return (
    <CardHeader className="d-flex align-items-center justify-content-between px-4 pt-4 pb-3">
      <div className="d-flex align-items-center">
        <Title/>
        <p className="mb-0 ml-2 font-weight-500 text-white">
          Market details
        </p>
      </div>
      {
        currentCredId &&
        <RefreshButton
          onHandleRefresh={handleRefresh}
          width={16}
          height={16}
          withHoverBg={false}
          loading={loadingOrders || loadingTrades}
        />
      }
    </CardHeader>
  )
}

export default MarketHeader;