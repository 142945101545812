import React from "react";
import styled from "styled-components";
import { Card, CardHeader } from "reactstrap";

import UpgradeToPremium from "components/UpgradeToPremium";
import { ReactComponent as Repeat } from "assets/img/icons/repeat.svg";

const RebalanceHistoryFreeSubscription = ({title, text}) => {
  return (
    <Card>
      <CardHeader className="d-flex flex-column flex-md-row justify-content-between py-3 pr-3 pl-4">
        <div className="d-flex my-auto">
          <Repeat />
          <StyledTitle className="font-weight-500 ml-2 text-white">
            Rebalance History
          </StyledTitle>
        </div>
      </CardHeader>
      <div className="d-flex align-items-center flex-column">
        <UpgradeToPremium
          title={title}
          text={text}
          />
      </div>
    </Card>
  )
};

const StyledTitle = styled.p`
  line-height: 18.75px;
`;

export default RebalanceHistoryFreeSubscription;
