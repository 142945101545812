import React from "react";
import styled from "styled-components";
import { CardHeader } from "reactstrap";

import { ReactComponent as Ellipse } from "assets/img/icons/ellipse.svg";
import EditButton from "components/EditButton";
import Icon from "components/Icon";

const HeaderWithType = ({ data={}, onEditButtonClick }) => {
  return (
    <StyledHeader className="py-3 px-4">
      <div className="d-flex justify-content-between">
        <div className="d-flex w-75">
          <div className="d-flex position-relative">
            <span
              className={`d-inline-block mr-3 align-items-center ${data.type === "genetic" ? "genetic-icon" : "custom-icon"}`}
            >
              <Icon
                size="botSize"
                code={`${data.type}-bot`}
                type="icons"
              />
            </span>
          </div>
          <Name className="market text-nowrap h3 my-auto text-white">
            {data.name}
          </Name>
          <span className="d-inline-block">
            <BotEllipse
              status={data?.status}
              className="mt-1"
            />
          </span>
        </div>
        <EditButton
          className="my-auto"
          onClick={onEditButtonClick}
        />
      </div>
    </StyledHeader>
  );
};

const Name = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledHeader = styled(CardHeader)`
  background: #2E3148 !important;
  cursor: pointer;

  & .market p {
    font-size: 1.125rem;
  }

  & .custom-icon svg {
    & rect {
      fill: rgba(47, 128, 237, 0.2);
    }

    & path {
      stroke: #5F9EF1;
    }
  }

  & .genetic-icon svg {
    & rect {
      fill: rgba(106, 72, 255, 0.2);
    }

    & path {
      stroke: #B09EFF;
    }
  }
`;

const BotEllipse = styled(Ellipse)`
  width: 8px;
  height: 8px;
  margin-left: 6px;

  & circle {
    fill: ${(props) => props.status === "running" ? "#5FD3BC" : "#777988"};
  }
`;
export default HeaderWithType;
