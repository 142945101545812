/* eslint-disable complexity */
/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Button from "reactstrap-button-loader";
import {
  FormGroup,
  UncontrolledAlert,
  Label
} from "reactstrap";
import {
  Formik,
  Form
} from "formik";
import * as Yup from "yup";

import FormGroupWithSymbols from "components/FormGroupWithSymbols";
import {
  getPeriods,
  getMarkets
} from "store/exchanges/actions";
import { resetMarkets } from "store/markets/actions";
import { createBacktest } from "store/backtesting/actions";
import SelectInput from "components/SelectInput";
import { handleResponse } from "helpers/errorsValidator";
import ExchangesSelect from "components/ExchangesSelect";
import NumberInput from "components/NumberInput";
import { beforeToday } from "helpers/DatepickerValidation";
import DateField from "components/DateField";

const settingsSchema = Yup.object().shape({
  exchange: Yup.string()
    .required("This field is required."),
  market: Yup.string()
    .required("This field is required."),
  initialBalance: Yup.string()
    .nullable()
    .required("This field is required."),
  period: Yup.string()
    .required("This field is required."),
  start: Yup.string()
    .required("This field is required."),
  end: Yup.string()
    .required("This field is required.")
});

const style = {
  color: "#ec250d",
};

const SettingsForm = ({
  id,
  onSetModalIsOpen,
  getMarkets,
  resetMarkets,
  getPeriods,
  createBacktest,
  periods=[],
  markets=[]
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [exchange, setExchange] = useState("");

  useEffect(() => {
    if (exchange) {
      getPeriods(exchange);
      getMarkets(exchange);
    }
    return () => resetMarkets();
  }, [exchange, getPeriods, getMarkets, resetMarkets]);

  return(
    <Formik
      initialValues={{
        exchange: "",
        start: "",
        end: "",
        period: "",
        market: "",
        initialBalance: "",
        strategy: id
      }}
      validationSchema={settingsSchema}
      onSubmit={async(values, formik) => {
        const pair = values.market.split("-");
        const res = await createBacktest({
          ...values,
          market: {
            base: pair[0],
            quote: pair[1],
          }
        });
        if (res.errors) {
          formik.setSubmitting(false);
          handleResponse(res, formik.setErrors);
        } else {
          onSetModalIsOpen(false);
        }
      }}
    >
      {({ values, errors, setFieldValue, isSubmitting, status }) => (
        <Form>
          {errors.message &&
            <UncontrolledAlert color="danger" fade={false}>
              {errors.message}
            </UncontrolledAlert>}
          <div className="justify-content-center">
            <div className="pl-3 pr-3">
              <div className="w-100">
                <Label className="text-muted">
                  Exchange
                </Label>
                <FormGroup>
                  <ExchangesSelect
                    placeholder="Exchange"
                    name="exchange"
                    value={values.exchange}
                    onChange={({ value }) => {
                      setFieldValue("exchange", value);
                      setExchange(value);
                    }}
                  />
                  <div className="w-100">
                    {errors.exchange && submitted ?
                      <label style={style}>{errors.exchange}</label> : null}
                  </div>
                </FormGroup>
              </div>
              <div className="w-100">
                <Label className="text-muted">
                  Market
                </Label>
                <FormGroup>
                  <SelectInput
                    placeholder="Market"
                    onChange={({ value }) => setFieldValue("market", value)}
                    options={markets.map((market) => ({ label: market, value: market }))}
                  />
                  <div className="w-100">
                    {submitted && errors.market ?
                      <label style={style}>{errors.market}</label> : null}
                  </div>
                </FormGroup>
              </div>
              <div className="w-100">
                <Label className="text-muted">
                  Initial balance
                </Label>
                <FormGroupWithSymbols
                  symbolRight={values.market.split("-")[1] || ""}
                >
                  <NumberInput
                    min={0}
                    value={values.initialBalance}
                    className={errors.initialBalance && submitted ? "has-error" : ""}
                    name="initialBalance"
                    onChange={(value) => setFieldValue("initialBalance", value)}
                  />
                  <div className="w-100">
                    {errors.initialBalance && submitted ?
                      <label style={style}>{errors.initialBalance}</label> : null}
                  </div>
                </FormGroupWithSymbols>
              </div>
              <div className="w-100">
                <Label className="text-muted">
                  Candlestick period
                </Label>
                <FormGroup>
                  <SelectInput
                    className="react-select info"
                    classNamePrefix="react-select"
                    name="period"
                    value={{ label: values.period }}
                    isSearchable={true}
                    onChange={({ value }) => setFieldValue("period", value)}
                    options={
                      periods.map((period) => {
                        return { value: period, label: period };
                    })}
                  />
                  <div className="w-100">
                    {submitted && errors.period ?
                      <label style={style}>{errors.period}</label> : null}
                  </div>
                </FormGroup>
              </div>
              <Label className="text-muted">
                Select backtest period
              </Label>
              <div className="d-flex justify-content-between w-100">
                <DateField
                  className={errors.start && submitted ? "has-danger" : "has-success"}
                  onChange={(value) => {
                    setFieldValue("end", "");
                    setFieldValue("start", value);
                  }}
                  dateFormat="YYYY-MM-DD hh:mm:ss"
                  isValidDate={beforeToday}
                  inputProps={{
                    className: "form-control",
                    name: "start",
                    placeholder: "Start date",
                    value: values.start || "",
                    readOnly: true,
                  }}
                />
                <div className="w-100">
                  {submitted && errors.start ?
                    <label style={style}>{errors.start}</label> : null}
                </div>
                <DateField
                  className={errors.end && submitted ? "has-danger" : "has-success"}
                  onChange={(value) => setFieldValue("end", value)}
                  dateFormat="YYYY-MM-DD hh:mm:ss"
                  isValidDate={(current) => current.isAfter(values.start) && beforeToday(current)}
                  inputProps={{
                    className: "form-control",
                    name: "end",
                    placeholder: "End date",
                    value: values.end || "",
                    readOnly: true,
                  }}
                />
                <div className="w-100">
                  {submitted && errors.end ?
                    <label style={style}>{errors.end}</label> : null}
                </div>
              </div>
              <div className="w-100 d-flex justify-content-between mt-5">
                <Button
                  color="default"
                  size="lg"
                  onClick={() => onSetModalIsOpen(false)}
                >
                  Close
                </Button>
                <Button
                  color="info"
                  type="submit"
                  size="lg"
                  disabled={isSubmitting}
                  onClick={() => setSubmitted(true)}
                  loading={isSubmitting}
                >
                  Run backtest
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  markets: state.exchanges.markets,
  periods: state.exchanges.periods
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getPeriods,
  getMarkets,
  resetMarkets,
  createBacktest
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SettingsForm);
