import React, {useLayoutEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardBody, CardHeader, Card} from "reactstrap";
import qs from "qs";
import {fetchDeals} from "store/deals/actions";

import Error from "components/Error";
import DealsFilters from "./DealsFilters";
import PaginationPanel from "components/PaginationPanel";
import {ReactComponent as Rocket} from "assets/img/icons/rocket-outline-2.svg";
import EmptyDealsHistory from "./EmptyDealsHistory";
import DealHistoryTable from "./DealHistoryTable";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import TablePreloader from "components/TablePreloader";
import DealsFiltersPreloader from "views/bots/preloaders/DealsFiltersPreloader";

const DealsHistory = ({id, loadingPage}) => {
  const {items, total, page, request} = useSelector((state) => state.deals.list.data);
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.deals.list.loading);
  const error = useSelector((state) => state.deals.list.error);

  const {
    handleFilter,
    handleSorting,
    handlePaginate,
    filters
  } = useFiltersWithRequest(request, fetchDeals);

  useLayoutEffect(() => {
    const queryString = qs.stringify({
      filter: { status: {eq: "completed"}, bot: {eq: id}},
      sort: {by: "completedAt", order: "DESC"}
    });
    dispatch(fetchDeals(`?${queryString}`));
  }, [dispatch, id]);

  return (
    <Card className="mb-4">
      <div ref={wrapperRef}>
        {
          loadingPage ?
          <DealsFiltersPreloader />
          :
          <CardHeader className="d-flex flex-column flex-md-row justify-content-between align-items-center py-3 px-4">
            <div className="d-flex align-items-center mr-4 mb-3 mb-md-0">
              <span className="d-inline-block">
                <Rocket />
              </span>
              <p className="mb-0 text-white font-weight-500 text-nowrap ml-2">
                Deals history
              </p>
            </div>
            <DealsFilters
              onHandleFilter={handleFilter}
              filter={request?.filter || filters}
              loadingPage={loadingPage}
              loading={loading}
              id={id}
            />
          </CardHeader>
        }
        <CardBody className="p-0 overflow-hidden" style={{borderRadius: "0 0 0.5rem 0.5rem"}}>
          {error && !(loading || loadingPage) ? <Error error={error}/> : null}
          {
            loading || loadingPage
            ? <TablePreloader
                thHeight={11}
                thWidth={[44,74,68,37,37,82]}
                tdWidth={[80,144,144,41,32,93]}
                tdHeight={14}
                rowTimes={10}
              />
            : null
          }
          {
            !loadingPage && items?.length ?
              <DealHistoryTable
                items={items}
                sort={request?.sort}
                filter={request?.filter || filters}
                handleSorting={handleSorting}
                id={id}
                total={total}
              />
              : null
          }
          {!items?.length && !loading && !loadingPage && <EmptyDealsHistory/>}
          <div className={`${total > 10 ? "pt-3" : ""}`}>
            <PaginationPanel
              activePage={page}
              totalItemsCount={total}
              onChange={(page) => handlePaginate(page)}
              elementRef={wrapperRef}
            />
          </div>
        </CardBody>
      </div>
    </Card>
  );
};

export default DealsHistory;
