import React from "react";
import styled from "styled-components";
import ConditionsMarketTable from "./ConditionsMarketTable";
import SkeletonPreloader from "components/SkeletonPreloader";
import ConditionsMarketPreloader from "./ConditionsMarketPreloader";

const ConditionsMarket = ({conditions, loadingPage, analysisMarketLoading, dates, botData}) => {

  const {
    minimumTrades, marketDirection, volume, minimumDailyChange, maximumDailyChange, markPriceDifference, spreadPercent
  } = botData;

  const botConditions = minimumTrades || marketDirection || volume || minimumDailyChange ||
    maximumDailyChange || markPriceDifference || spreadPercent;

  return (
    <>
      <ConditionsMarket.Header className="d-flex align-items-center justify-content-between py-2 px-4">
        {
          loadingPage || analysisMarketLoading ?
            <div className="d-flex align-items-center justify-content-between py-2 w-100">
              <SkeletonPreloader width={247} height={12}/>
              <SkeletonPreloader width={105} height={9}/>
            </div>
            :
            <>
              <ConditionsMarket.Title>
                Conditions to start the deal
              </ConditionsMarket.Title>

              {
                conditions?.length ?
                  <p className="mb-0 text-white-35 font-sm">
                    {`Checked ${dates}`}
                  </p>
                  : null
              }
            </>
        }
      </ConditionsMarket.Header>

      {
        loadingPage || analysisMarketLoading
          ? <ConditionsMarketPreloader/>
          : <ConditionsMarketTable conditions={conditions} botConditions={botConditions}/>
      }
    </>
  )
}

ConditionsMarket.Header = styled.div`
  background: rgba(46, 49, 72, 1);
`;

ConditionsMarket.Title = styled.p`
  color: rgba(125, 129, 171, 1) !important;
  font-weight: 500;
  margin-bottom: 0;
  text-transform: uppercase;
`;

export default ConditionsMarket;