import React from "react";
import { FormGroup } from "reactstrap";
import SelectInput from "components/SelectInput";

import {STATUS_OPTIONS} from "models/deal/enum/tables";

const RequestsFiltersOrders = ({handleFilter}) => {
  return (
    <FormGroup className="mb-0">
      <SelectInput
        isClearable
        placeholder="Status"
        name="status"
        options={STATUS_OPTIONS}
        onChange={(option) => handleFilter({
          status: {eq: option?.value || {}}
        })}
      />
    </FormGroup>
  )
}

export default RequestsFiltersOrders;
