import { get } from "lodash";
import {
  GET_ALL_EXCHANGES,
  GET_USER_EXCHANGES,
  GET_MARKETS,
  GET_PERIODS,
  GET_ORDER_BOOK,
  GET_MARKET_TICKER
} from "./constants";

export const getExchanges = (filter, signal) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.get(`/app/exchanges${filter || ""}`, { signal });
    dispatch({ type: GET_ALL_EXCHANGES, payload: res.data });
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const getUserExchanges = () => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.get("/app/user/exchanges");
    dispatch({ type: GET_USER_EXCHANGES, payload: res.data });
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const getPeriods = (exchange) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.get(`/app/exchanges/${exchange}/charts/periods`);
    dispatch({ type: GET_PERIODS, payload: res.data });
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const getMarkets = (exchange) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.get(`/app/exchanges/${exchange}/markets`);
    dispatch({ type: GET_MARKETS, payload: res.data });
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const convertCurrency = (exchange, from, to, amount) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.get(`/app/exchanges/${exchange}/convert?from=${from}&to=${to}&amount=${amount}`);
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const getOrderBook = (exchange, market) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.get(`/app/exchanges/${exchange}/markets/${market}/order-book`);
    dispatch({ type: GET_ORDER_BOOK, payload: res.data });
  } catch (e) {
    return e.response.data;
  }
};

export const getMarketTicker = (exchange, market) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.get(`/app/exchanges/${exchange}/markets/${market}/ticker`);
    dispatch({ type: GET_MARKET_TICKER, payload: res.data });
  } catch (e) {
    return e.response.data;
  }
};

export const getMarketLastTrades = (exchange, base, quote) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.get(`/app/exchanges/${exchange}/markets/${base}-${quote}/trades`);
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};
