import React, {useEffect, useState} from "react";
import {FormGroup} from "reactstrap";
import {SIDE_OPTIONS, STATUS_OPTIONS} from "models/deal/enum/tables";
import SelectInput from "components/SelectInput";
import SkeletonPreloader from "components/SkeletonPreloader";
import {capitalize} from "lodash";

const OrdersFilters = ({onHandleFilter, itemsLoading, loadingPage, filter}) => {
  const [statusFilter, setStatusFilter] = useState({})

  useEffect(() => {
    if (filter) {
      setStatusFilter(filter?.status)
    }
  }, [filter]);

  if (loadingPage) {
    return (
      <div className="d-flex align-items-center">
        <SkeletonPreloader height={38} width={145} borderRadius="8px" className="mr-2"/>
        <SkeletonPreloader height={38} width={145} borderRadius="8px"/>
      </div>
    )
  }

  return (
    <div className="d-flex align-items-center flex-wrap">
      <FormGroup className="statistic-filter mb-0 mr-2 mt-2 mt-sm-0" style={{width: "145px"}}>
        <SelectInput
          isClearable
          placeholder="Side"
          name="type"
          options={SIDE_OPTIONS}
          onChange={(option) => onHandleFilter({
            type: {eq: option?.value}
          })}
          isLoading={loadingPage}
          isDisabled={itemsLoading}
        />
      </FormGroup>
      <FormGroup className="statistic-filter mb-0 mt-2 mt-sm-0" style={{width: "145px"}}>
        <SelectInput
          isMulti
          isClearable
          placeholder="Status"
          name="status"
          options={STATUS_OPTIONS}
          value={statusFilter?.in?.map((item) => ({
            label: capitalize(item),
            value: item,
            color: STATUS_OPTIONS.filter((s) => s.value === item && s.color)
          }))}
          onChange={(option) => {
            if (!option?.length) setStatusFilter({});
            onHandleFilter({
              status: {in: option ? option.map((e) => e.value) : ""}
            });
          }}
          isLoading={loadingPage}
          isDisabled={itemsLoading}
        />
      </FormGroup>
    </div>
  )
}

export default OrdersFilters;
