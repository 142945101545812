import React from 'react';
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import {ReactComponent as BreadCrumb} from "assets/img/icons/breadRight.svg";
import BreadcrumbsPreLoader from "../BreadcrumbsPreloader";

const Breadcrumbs = ({ 
  items, 
  loading,
  className=""
}) => {
  const history = useHistory();

  if (loading) return <BreadcrumbsPreLoader repeat={items?.length} />;

  return (
    <Nav 
    data-testid="breadcrumbs-component"
    className={`mb-3 ${className}`}>
      <ul className="d-flex m-0 p-0">
        {items.map((breadcrumb) =>
          <li
            key={breadcrumb.label}
            className="d-flex align-items-center"
          >
            <Link
              data-testid="breadcrumbs-link-component"
              onClick={() => breadcrumb.path && history.push(breadcrumb.path)}
              className="mb-0 font-sm"
              path={breadcrumb.path}
            >
              {breadcrumb.label}
            </Link>
            <BreadCrumb/>
          </li>
        )}
      </ul>
    </Nav>
  );
};

const Nav = styled.nav`
  svg {
    margin: 0 16px;
  }

  li {
    :last-child {
      svg {
        display: none !important;
      }
    }
  }
`;

const Link = styled.p`
  color: ${({path}) => path ? "rgba(255,255,255, 0.35)" : "#fff"};

  &:hover {
    color: #fff;
    cursor: ${({path}) => path ? "pointer" : "default"};
    transition: 0.2s;
  }
`;

export default Breadcrumbs;
