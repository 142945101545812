import React from "react";
import classNames from "classnames";

import CustomSlider from "components/CustomSlider";
import FormGroupWithSymbols from "components/FormGroupWithSymbols";
import NumberInput from "components/NumberInput";

const GAconfigField = ({
  value=0,
  onChange,
  label="",
  unit="",
  className,
  max=100,
  min=0,
  defaultValue=0,
  desc="",
  error,
  ...props
}) => {
  return (
    <div
      className={`d-flex flex-column w-100 ${className}`}
      {...props}
    >
      <div className={classNames("d-flex align-items-center justify-content-between", {
        "mb-3": !error
      })}
      >
        <p className="mb-0 font-weight-500">
          {label}
        </p>
        <FormGroupWithSymbols
          symbolRight={unit}
          className="my-auto"
        >
          <NumberInput
            type="int"
            value={value || defaultValue}
            min={min}
            max={max}
            style={{width: "71px"}}
            onChange={(value) => onChange(value)}
            className={classNames("form-control", {
              "has-error": error
            })}
          />
        </FormGroupWithSymbols>
      </div>

      {error ?
        <label className="text-danger mb-0 text-right text-nowrap">
          {error}
        </label>
        : null
      }

      <CustomSlider
        max={max}
        startPoint={0}
        step={1}
        defaultValue={defaultValue}
        value={value || defaultValue}
        onChange={(percent) => onChange(percent)}
      />
      {
        desc ?
          <p className="mb-0 mt-2 font-sm text-white-35">
            {desc}
          </p> : null
      }
    </div>
  );
};

export default GAconfigField;
