import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Modal } from "reactstrap";

import Button from "components/Button";
import StayAtHome from "assets/img/mainWizard/stay-at-home.png";

const ChannelsModal = ({
  isInfoRead,
  toggleModal
}) => (
  <ChannelsModal.Modal isOpen={!isInfoRead}>
    <img
      src={StayAtHome}
      className="img-fluid d-lg-none "
      alt="Stay at home"
    />
    <ChannelsModal.Title className="mt-4 mt-lg-0 w-75 mb-0 text-white font-weight-600">
      Welcome to the Signals trading within the Darkbot ecosystem!
    </ChannelsModal.Title>
    <p className="font-md text-white-75 font-weight-400 m-0 my-4 w-50"> 
      Here, you can effortlessly subscribe to various channels and automatically receive signals. Darkbot will autonomously execute buy and sell orders based on signal parameters. Additionally, you have the ability to create your own channels and broadcast signals across any markets for your subscribers. Dive into the world of trading signals with Darkbot!
    </p>
    <ChannelsModal.Button
      color="blue"
      onClick={toggleModal}
      className="mb-0 align-self-center align-self-lg-start"
    >
      Great, thanks!
    </ChannelsModal.Button>
    <img
      src={StayAtHome}
      className="d-none float-end position-absolute bottom-0 right-0 d-lg-block "
      alt="Stay at home"
      width="45%"
    />
  </ChannelsModal.Modal>
);

ChannelsModal.Modal = styled(Modal)`

  @media (min-width: 576px) {
    max-width: fit-content !important;
  }

  .modal-content {
    background: var(--dark-slate) !important;
    border-radius:20px !important;
    max-width: 948px;
    padding: 9%;
  }

  @media (max-width: 768px) {
    height:100vh;
    margin:0 !important;
    bottom: 5vh;
    
    .modal-content {
      border-radius:0 !important;

      p {
        width:100% !important;
      }
    }
  }

  @media (max-width: 576px) {
    bottom: 5.15vh;
  }
`;

ChannelsModal.Title = styled.p`
  max-width: 560px;
  font-size: 40px;
`;

ChannelsModal.Button = styled(Button)`
  margin-top: 4.5vw;
  width: 153.4px;
  height: 48px !important;

  @media (max-width: 768px) {
      width: 100%;
    }
`;

ChannelsModal.propTypes = {
  isInfoRead: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired
};

export default ChannelsModal;