import React, {useEffect, useLayoutEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Card} from "reactstrap";
import qs from "qs";
import {STATISTICS_ORDERS_TABLE_HEAD} from "models/deal/enum/tables";
import {getOrders} from "store/deals/actions";
import {ReactComponent as Empty} from "assets/img/empty-state-planet2.svg";
import OrdersTableItem from "./OrdersTableItem";
import SortTableHead from "components/SortTableHead";
import CustomScrollbars from "components/CustomScrollbars";
import PaginationPanel from "components/PaginationPanel";
import StyledTable from "components/StyledTable";
import {selectDealsOrders} from "store/deals/selectors";
import {fetchOpenOrders} from "store/credentials/actions";
import TableHeader from "./TableHeader";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import TablePreloader from "components/TablePreloader";
import { EmptyStateCustom } from "shared/ui";

const OrdersHistory = ({id, credId, market, loadingPage}) => {
  const [dealOnly, setDealOnly] = useState(true);
  const [ordersData, setOrdersData] = useState({});
  const [ordersLoading, setOrdersLoading] = useState(true);
  const wrapperRef = useRef();

  const dispatch = useDispatch();

  const {loading, data} = useSelector(selectDealsOrders);
  const orders = useSelector((state) => state.credentials.openOrders.data);
  const loadingOrders = useSelector((state) => state.credentials.openOrders.loading);

  const requestFunc = dealOnly
    ? { func: getOrders, param: id}
    : { func: fetchOpenOrders, param: credId};

  const { handleFilter, handleSorting, handlePaginate } = useFiltersWithRequest(ordersData?.request, requestFunc.func, requestFunc.param);

  useLayoutEffect(() => {
    const filters = qs.stringify({
      filter: {status: {in: ["open", "completed"]}},
      sort: {by: "createdAt", order: "DESC"}
    });
    dispatch(getOrders(id, `?${filters}`));
  }, [dispatch, id]);

  useLayoutEffect(() => {
    if (dealOnly) {
      setOrdersData(data);
      return setOrdersLoading(loading);
    }
    setOrdersData(orders);
    setOrdersLoading(loadingOrders);
  }, [data, loading, dealOnly, orders, loadingOrders]);

  useEffect(() => {
    if (loadingPage) setDealOnly(true);
  }, [loadingPage]);

  const handleDealOnly = (check) => {
    if (check) {
      const filters = qs.stringify({
        filter: {...ordersData?.request?.filter, market: {}},
        sort: {by: "createdAt", order: "DESC"}
      });
      return dispatch(getOrders(id, `?${filters}`))
    }

    const filterAllOrders = qs.stringify({
      filter: {...ordersData?.request?.filter, market: {eq: market?.pair}},
      sort: {by: "createdAt", order: "DESC"}
    });
    dispatch(fetchOpenOrders(credId, `?${filterAllOrders}`));
  };

  const handleRefresh = () => {
    const filters = qs.stringify({
      filter: ordersData?.request?.filter,
      sort: ordersData?.request?.sort,
    });
    if (dealOnly) return dispatch(getOrders(id, `?${filters}`));
    dispatch(fetchOpenOrders(credId, `?${filters}`));
  };

  const renderEmptyState = (title, desc) => {
    return (
      <EmptyStateCustom
        height={364}
        Img={Empty}
        title={title}
        description={desc}
        className="px-4"
        backgroundColor="var(--dark-slate)"
      />
    );
  }

  if (!ordersData?.items?.length && !ordersLoading && !Object.keys(ordersData?.request?.filter || {})?.length) {
    return renderEmptyState("You have no orders at the moment", "");
  }

  return (
    <div ref={wrapperRef}>
      <Card className="mb-4">
        <TableHeader
          loadingPage={loadingPage}
          itemsLoading={ordersLoading}
          total={ordersData?.total}
          filter={ordersData?.request?.filter}
          handleRefresh={handleRefresh}
          handleFilter={handleFilter}
          dealOnly={dealOnly}
          setDealOnly={setDealOnly}
          handleDealOnly={handleDealOnly}
          title="Orders"
        />
        {(ordersLoading || loadingPage) &&
          <div className="overflow-hidden">
            <TablePreloader
              rowTimes={5}
              tdHeight={14}
              tdWidth={[32, 85, 39, 53, 77, 77, 52, 105, 81, 73, 93]}
            />
          </div>
        }
        {
          !ordersData?.items?.length && !ordersLoading && !loadingPage &&
          renderEmptyState("No results for your search", "Please adjust your filters, we have not found anything by your request.")
        }
        {
          ordersData?.items?.length && !ordersLoading && !loadingPage ?
            <CustomScrollbars
              autoHideTimeout={500}
              autoHideDuration={500}
              autoHeight
              autoHeightMax={Number.MAX_VALUE}
            >
              <StyledTable className="mb-0">
                <>
                  <SortTableHead
                    handleSorting={handleSorting}
                    sort={ordersData?.request?.sort}
                    theadItems={STATISTICS_ORDERS_TABLE_HEAD}
                  />

                  <tbody className="font-sm">
                  {
                    ordersData?.items?.map((order, index) =>
                      <OrdersTableItem
                        order={order}
                        key={order.id}
                        index={index}/>
                    )
                  }
                  </tbody>
                </>
              </StyledTable>
            </CustomScrollbars>
            : null
        }
        {
          !loadingPage &&
          <div className={ordersData?.total < 10 ? "" : "pt-3"}>
            <PaginationPanel
              activePage={ordersData?.page}
              totalItemsCount={ordersData?.total}
              onChange={(page) => handlePaginate(page)}
              elementRef={wrapperRef}
            />
          </div>
        }
      </Card>
    </div>
  );
};

export default OrdersHistory;
