const styledSliderMarks = {
  display: "block",
  width: "6px",
  height: "8px",
  borderRadius: 2,
  margin: "-13px 0 0 0",
}

export const priceDifferenceSteps = (priceDifference) => {
  const steps = {};

  for (let i = 2; i <= 18; i++) {
    if (i % 2) continue;
    steps[i] = {style: priceDifference > i && {...styledSliderMarks, background: "#0AAF95"}, label: " "}
  }

  return steps
}