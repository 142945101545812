import React, { useState } from "react";
import moment from "moment";
import { capitalize } from "lodash";
import {
  Badge,
  CardHeader,
  Tooltip
} from "reactstrap";

import TradeRequestGoal from "../../TradeRequestGoal";
import { formatterToNull } from "helpers/valuesFormatters";

import {
  TradeRequestAmount,
  TradeRequestCurrency,
  TradeRequestIcon,
  TradeRequestTitle
} from "components/StyledTradeRequestComponents";
import { handleStatusData } from "models/constans/ui/tradeRequests/content";

const TradeRequestCardHeader = ({
  data,
  goal
}) => {
  const [tooltip, setTooltip] = useState(false);

  const toggle = () => setTooltip(!tooltip);
  const statusData = handleStatusData(data);

  const renderStatus = (status) => {
    const IconStatus = statusData[status]?.Icon
    return statusData[status] && (
      <>
        <p className="mb-0 text-white font-weight-500 mr-2 font-sm">
          {moment(data?.createdAt).format("DD.MM.YY HH:mm")}
        </p>
        <TradeRequestIcon>
          <IconStatus
            width={20}
            height={20}
            id={`status-${data?.id}`}
          />
        </TradeRequestIcon>
        <Tooltip
          placement="top"
          isOpen={tooltip}
          target={`status-${data?.id}`}
          toggle={toggle}
        >
          {capitalize(status)}
        </Tooltip>
      </>
    )
  };

  return (
    <CardHeader className="d-flex flex-wrap flex-md-nowrap py-3 px-4">
      <div className="d-flex align-items-center flex-wrap flex-md-nowrap mb-3 mb-md-0">
        <Badge
          style={{
            background: data?.type === "buy" ? "#3FCBB0" : "#ED5F5F",
            color: "#FFFFFF",
            fontWeight: "500",
            fontSize: "14px",
            margin: "0 24px 0 0",
            lineHeight: "16px",
            display: "block"
          }}
        >
          {data?.type?.slice(0, 1)}
        </Badge>
        <div className="d-flex flex-wrap flex-md-nowrap  justify-content-between mt-2 mt-md-0">
          <div className="d-flex flex-column px-3 mr-4 px-md-0">
            <TradeRequestTitle>
              Initial price
            </TradeRequestTitle>
            <TradeRequestAmount amount={data?.price?.amount}>
              {formatterToNull(data?.price?.amount)}
            </TradeRequestAmount>
          </div>
          <div className="d-flex flex-column px-3 mr-0 mr-md-4 px-md-0">
            <TradeRequestTitle>
              Boundary price
            </TradeRequestTitle>
            <TradeRequestAmount amount={data?.boundaryRate}>
              {formatterToNull(data?.boundaryRate)}
            </TradeRequestAmount>
          </div>
          <div className="d-flex flex-column mr-4 px-3 px-md-0 mt-1 mt-md-0">
            <TradeRequestTitle>
              Planned amount
            </TradeRequestTitle>
            <div className="d-flex align-items-center">
              <TradeRequestAmount amount={data?.plannedAmount?.amount}>
                {formatterToNull(data?.plannedAmount?.amount)}
              </TradeRequestAmount>
              <TradeRequestCurrency>
                {data?.plannedAmount?.asset}
              </TradeRequestCurrency>
            </div>
          </div>
          <div className="d-flex flex-column px-3 px-md-0 mt-1 mt-md-0">
            <TradeRequestTitle>
              Planned quantity
            </TradeRequestTitle>
            <div className="d-flex align-items-center">
              <TradeRequestAmount amount={data?.plannedQuantity?.amount}>{
                formatterToNull(data?.plannedQuantity?.amount)}
              </TradeRequestAmount>
              <TradeRequestCurrency>
                {data?.plannedQuantity?.asset}
              </TradeRequestCurrency>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center px-3 px-md-0 ml-0 ml-md-auto mr-auto mr-md-0">
        <TradeRequestGoal goal={goal} />
        {renderStatus(data?.status)}
      </div>
    </CardHeader>
  );
};

export default TradeRequestCardHeader;