import React, { useState } from "react";
import styled from "styled-components";
import { Badge } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";

import IndicatorsModal from "./IndicatorsModal";

const Indicator = ({
  indicator={},
  strategyId,
  editable,
  canRemove,
  handleDelete,
  onSetSuccessMessage
}) => {
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);

  return(
    <>
      <Indicator.Card className="w-100 mb-3 pr-3 pl-3 pt-2 pb-2">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <i
              className={`tim-icons icon-alert-circle-exc mr-2 my-auto ${indicator.required ? "text-warning" : "text-white-35"}`}
            />
            <Badge
              className="pl-3 pr-3 my-auto"
              color={indicator.type === "buy" ? "success" : indicator.type === "sell" ? "danger" : "warning"}
            >
              {indicator.type}
            </Badge>
            <p className="ml-2 my-auto">
              {
                indicator.details && indicator.details.name ?
                indicator.details.name :
                indicator.name || ""
              }{" "}
            </p>
          </div>
          <div className="d-flex">
            {editable ?
              <Indicator.IconButton
                className="fa fa-cog text-white my-auto mr-2"
                onClick={() => setEditModalIsOpen(true)}
              /> : null}
            {canRemove ?
              <Indicator.IconButton
                className="tim-icons icon-trash-simple text-danger my-auto"
                onClick={() => setDeleteModalIsOpen(true)}
              /> : null}
        </div>
      </div>
      </Indicator.Card>
      {
        deleteModalIsOpen &&
        <ReactBSAlert
          warning
          title="Delete indicator"
          onCancel={() => setDeleteModalIsOpen(false)}
          onConfirm={() => {
            setDeleteModalIsOpen(false);
            handleDelete(strategyId, indicator.uuid);
          }}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Delete"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          Are you sure want to delete that indicator?
        </ReactBSAlert>
      }
      {
        editable && editModalIsOpen ?
        <IndicatorsModal
          modalIsOpen={editModalIsOpen}
          onSetModalIsOpen={setEditModalIsOpen}
          strategyId={strategyId}
          editingIndicator={indicator}
          onSetSuccessMessage={onSetSuccessMessage}
          editing
        /> : null
      }
    </>
  );
};

Indicator.IconButton = styled.i`
  cursor: pointer;
`;

Indicator.Card = styled.div`
  background: #2e3148 !important;
  box-shadow: none !important;
  border-radius: 0.2857rem;
`;

export default Indicator;
