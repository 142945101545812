import React from "react";
import PropTypes from "prop-types";
import { Label } from "reactstrap";
import classNames from "classnames";

import NumberInput from "components/NumberInput";

const ParameterInputBlock = ({
  isSubmitted,
  errors,
  setFieldValue,
  isHasChannels,
  values
}) => {
  return (
    <div className="d-flex justify-content-between my-3">
      <div className="w-50">
        <Label
          htmlFor="buyFrom"
          className="text-white-75 font-weight-400 mb-2"
        >
          Open from
        </Label>
        <NumberInput
          name="buyFrom"
          value={values?.buyFrom}
          disabled={isHasChannels}
          onChange={(e) => setFieldValue("buyFrom", e)}
          className={classNames("form-control", {
            "has-error": isSubmitted && errors?.buyFrom
          }, "mb-0")}
        />
        {isSubmitted && errors?.buyFrom &&
          <p className="text-danger font-sm font-weight-300 m-0">
            {errors?.buyFrom}
          </p>}
      </div>
      <div className="w-50 mx-3">
        <Label
          htmlFor="buyTo"
          className="text-white-75 font-weight-400 mb-2"
        >
          Open to
        </Label>
        <NumberInput
          name="buyTo"
          value={values?.buyTo}
          disabled={isHasChannels}
          onChange={(e) => setFieldValue("buyTo", e)}
          className={classNames("form-control", {
            "has-error": isSubmitted && errors?.buyTo
          }, "mb-0")}
        />
        {isSubmitted && errors?.buyTo &&
          <p className="text-danger font-sm font-weight-300 m-0">
            {errors?.buyTo}
          </p>}
      </div>
      <div className="w-50">
        <Label
          htmlFor="stopLoss"
          className="text-white-75 font-weight-400 mb-2"
        >
          Stop loss
        </Label>
        <NumberInput
          name="stopLoss"
          value={values?.stopLoss}
          disabled={isHasChannels}
          onChange={(e) => setFieldValue("stopLoss", e)}
          className={classNames("form-control", {
            "has-error": isSubmitted && errors?.stopLoss
          }, "mb-0")}
        />
        {isSubmitted && errors?.stopLoss &&
          <p className="text-danger font-sm font-weight-300 m-0">
            {errors?.stopLoss}
          </p>}
      </div>
    </div>
  );
};

ParameterInputBlock.propTypes = {
  isSubmitted: PropTypes.bool,
  errors: PropTypes.shape({
    buyFrom: PropTypes.string,
    buyTo: PropTypes.string,
    stopLoss: PropTypes.string,
  }),
  setFieldValue: PropTypes.func
};

export default ParameterInputBlock;