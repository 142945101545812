import React from "react";
import styled from "styled-components";
import orders from "assets/img/orders-table.svg";

const EmptyFiltersListingState = () => {
  return (
    <EmptyFiltersListingState.Wrapper>
      <img src={orders} alt=""/>
      <h3 className="mb-0 text-white font-weight-500 mt-4">
        No results for your search
      </h3>

      <p className="mb-0 mt-2 text-center text-white-75 px-4">
        You may want to try adjusting your filters or checking the data you enter.
      </p>
    </EmptyFiltersListingState.Wrapper>
  )
};

EmptyFiltersListingState.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--dark-slate);
  border-radius: 0 0 8px 8px;
  padding: 175px 0;
`;

export default EmptyFiltersListingState;
