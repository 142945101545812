import React from "react";
import { CardHeader } from "reactstrap";
import PropTypes from "prop-types";

import Button from "components/Button";
import { ReactComponent as Rocket } from "assets/img/icons/rocket-outline.svg";
import SkeletonPreloader from "components/SkeletonPreloader";

const SignalTableHeader = ({
  loading,
  handleToggleModal
}) => {
  return (
    <CardHeader className="p-0 px-4 d-flex justify-content-between mb-3">
      {loading ? (
        <>
          <SkeletonPreloader
            height={20}
            width={20}
          />
          <SkeletonPreloader
            height={24}
            width={136}
            className="ml-2 mb-0"
          />
        </>
      ) : (
        <>
          <div className="d-flex align-items-center p-0">
            <Rocket />
            <p className="m-0 ml-2 text-white font-weight-500">
              Signal progress
            </p>
          </div>
          <Button
            color="blue"
            className="px-4 d-none"
            outline
            loading={loading}
            disabled={loading}
            onClick={handleToggleModal}
          >
            Create New Target
          </Button>
        </>
      )
      }
    </CardHeader>
  );
};

SignalTableHeader.propTypes = {
  loading: PropTypes.bool,
  handleToggleModal: PropTypes.func
};

export default SignalTableHeader;
