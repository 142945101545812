import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  useDispatch,
  useSelector
} from "react-redux";
import {
  Col,
  Row
} from "reactstrap";

import Error from "components/Error";
import Breadcrumbs from "components/Breadcrumbs";
import RefreshButton from "components/RefreshButton";
import MyAgreementsDetails from "./compounds/MyAgreementsDetails";
import SignalDetailsHeader from "./compounds/SignalDetailsHeader";
import Channel from "components/ChannelWidget";
import MarketChart from "./pageView/MarketChart/MarketChart";
import SignalDetails from "./widgets/SignalDetails";
import ChannelSubscrWidget from "views/channels/widgets/ChannelSubscriptionWidget";
import { setSignalBreadcrumbs } from "models/enum/breadcrumbs";
import useRequestCancellation from "hooks/useRequestCancellation";
import { signalItem } from "store/signals/selectors";
import {
  getSignal,
  resetSignals,
  subscribeChannelSignal
} from "store/signals/action";
import SignalProgress from "./pageView/SignalProgress/SignalProgress";

const SignalPage = ({ match }) => {
  const dispatch = useDispatch();
  const { useAbortableEffect } = useRequestCancellation();
  const { loading, error, data } = useSelector(signalItem);

  useAbortableEffect((signal) => {
    dispatch(getSignal(match.params.id, signal));
    return () => dispatch(resetSignals());
  }, [match.params.id, dispatch]);

  const onHandleRefresh = () => {
    dispatch(getSignal(match.params.id));
  };

  useEffect(() => {
    window.analytics.page("/signnals/:id");
  });

  if (error) return <Error error={error} />
  return (
    <>
      <div className="d-none d-md-flex aling-items-center justify-content-between">
        <Breadcrumbs
          key={data?.id}
          items={setSignalBreadcrumbs(
            data?.channel?.name,
            data?.channel?.id
          )}
          loading={loading}
        />
        <RefreshButton
          onHandleRefresh={onHandleRefresh}
          loading={loading}
        />
      </div>
      <SignalDetailsHeader
        loading={loading}
        id={data?.id}
        status={data?.status}
        createdAt={data?.createdAt}
        base={data?.market?.base}
        quote={data?.market?.quote}
      />
      <Row>
        <Col
          md="4"
          className="no-gutters"
        >
          <SignalDetails
            loading={loading}
            openFrom={data?.buyFrom}
            openTo={data?.buyTo}
            stopLoss={data?.stopLoss}
          />
          <Link
            key={data?.channel?.id}
            to={`/channels/${data?.channel?.id}`}
          >
            <Channel
              handleRefresh={onHandleRefresh}
              onSubscribeChannel={subscribeChannelSignal}
              loading={loading}
              channel={data?.channel}
            />
          </Link>
          {
            data?.channel?.subscribed
              ? <ChannelSubscrWidget
                loading={loading}
                channelId={data?.channel?.id}
                maxximun={data?.channel?.subscriberResponse?.maximum}
                volume={data?.channel?.subscriberResponse?.volume}
                accept={data?.channel?.subscriberResponse?.accept}
                handleRefresh={onHandleRefresh}
              />
              : null
          }
        </Col>
        <Col
          md="8"
          className="no-gutters"
        >
          <MyAgreementsDetails
            signalId={data?.id}
            status={data?.status}
            agreements={data?.agreements}
            loading={loading}
          />
          <MarketChart
            exchange={data?.exchange}
            loadingPage={loading}
            base={data?.market?.base}
            quote={data?.market?.quote}
          />
          <SignalProgress
            loading={loading}
            data={data}
          />
        </Col>
      </Row>
    </>
  );
};

export default SignalPage;