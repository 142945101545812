import axios from "axios";
import { API_URL } from "env-create-react-app";

const fetchWithHeaders = ({dispatch, getState}) => (next) => (action) => {
  const token = localStorage.getItem("token");
  const options = {
    baseURL: API_URL,
    headers: {
      "Authorization": token ? `Bearer ${token}` : ""
    },
  };
  const fetch = axios.create(options);

  if (typeof action === "function") {
    return action(dispatch, getState, fetch);
  }

  next(action);
};

export default fetchWithHeaders;
