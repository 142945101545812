import React from "react";
import {Card, CardHeader} from "reactstrap";
import styled from "styled-components";

import LineWithError from "shared/ui/LineWithError";
import { ReactComponent as Icon } from "assets/img/icons/gridIcon.svg";
import GenerationWidgetPreloader from "../preloaders/GenerationWidgetPreloader";
import { replaceDurations } from "helpers/replaceDurations";

const GenerationWidget = ({
  data={},
  loading,
  lifetime
}) => {
  if (loading) return <GenerationWidgetPreloader />

  return (
    <Card className="mr-2 mb-4">
      <GenerationWidget.Header className="p-4 d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
        <div className="d-flex flex-row align-items-center mb-2 mb-sm-0">
          <Icon className="mr-2"/>
          <p className="font-md my-auto mr-2 mr-lg-3 font-weight-500 text-white">
            Generation
          </p>
          <p className="text-info font-weight-500 my-auto">
            #{data?.generation}
          </p>
        </div>

        <div className="d-flex flex-column flex-sm-row align-items-sm-center text-nowrap">
          <p className="text-white mb-2 mb-sm-0 font-weight-500 font-sm my-md-auto">
            <span className="font-weight-300 font-sm text-white-35 pr-1">
              Created at
            </span>
            {data?.createdAt}
          </p>
          <GenerationWidget.DurationBadge className="d-inline-block ml-sm-3 ml-md-2 ml-lg-3 px-2 py-1 text-white font-weight-500">
            {replaceDurations(lifetime)}
          </GenerationWidget.DurationBadge>
        </div>
      </GenerationWidget.Header>
      {
        data?.error
          ? <LineWithError errorMessage={data?.error}/>
          : null
      }
    </Card>
  )
}

GenerationWidget.Header = styled(CardHeader)`
  p {
    line-height: 16px;
  }
  span {
    font-size: 18px;
  }
`;

GenerationWidget.DurationBadge = styled.span`
  width: fit-content;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 4px;
  line-height: 19px;
  white-space: nowrap;
`;

export default GenerationWidget;
