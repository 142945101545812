import React from "react";
import {ReactComponent as Empty} from "assets/img/empty-state-planet2.svg";

const MarketsTableEmpty = () => {
  return (
    <div className="d-flex flex-column align-items-center px-5 my-5 py-3">
      <Empty/>
      <p className="mb-0 mt-4 pt-3 text-white font-weight-500">No results for your search</p>
      <p className="mb-0 mt-1 text-white-75 font-sm">
        Oops, we couldn't find any results for your search.
        Please try again with a different keyword or refine your search criteria.
      </p>
    </div>
  )
}

export default MarketsTableEmpty;