import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import OverviewTab from "./Overview/OverviewTab";
import MarketTab from "./Market/MarketTab";
import HistoryTab from "./History/HistoryTab";
import SkeletonPreloader from "components/SkeletonPreloader";
import { selectMarketInformation } from "store/deals/selectors";

const PagesView = ({
  data,
  loadingPage,
  id,
  pageView,
  setPagView,
  onHandleRefresh
}) => {
  const pages = ["overview", "market", "history"];

  const marketInformation = useSelector(selectMarketInformation);
  const isMarketHasError = marketInformation?.error && !marketInformation?.loading

  return (
    <>
      {
        loadingPage || marketInformation?.loading
          ? <div className="mb-4">
            <div className="d-flex align-items-center">
              <SkeletonPreloader height={11} width={66} />
              <SkeletonPreloader height={11} width={66} className="mx-4" />
              <SkeletonPreloader height={11} width={66} />
            </div>
            <SkeletonPreloader height={3} width={66} />
          </div>
          :
          <PagesView.Page>
            {
              pages.map((page) => {
                if (isMarketHasError && page === "market") return null;
                return (
                  <div key={page}>
                    <PagesView.Tab onClick={() => setPagView(page)} current={pageView} tab={page}>
                      {page}
                    </PagesView.Tab>
                    <span className={`${pageView === page ? "active" : ""}`} />
                  </div>
                )
              }
              )
            }
          </PagesView.Page>
      }

      {
        pageView === "overview"
        &&
        <OverviewTab
          id={id}
          data={data}
          loadingPage={loadingPage}
          onHandleRefresh={onHandleRefresh}
        />
      }
      {
        pageView === "market"
        &&
        <MarketTab
          data={data}
          loadingPage={loadingPage}
          marketInformation={marketInformation}
        />
      }
      {
        pageView === "history"
        &&
        <HistoryTab
          id={id}
          credId={data?.bot?.investment?.credentials?.id}
          market={data?.market}
          loadingPage={loadingPage}
        />
      }
    </>
  )
}

PagesView.Page = styled.div`
  display: flex;
  margin-bottom: 24px;

  div {
    &:nth-child(2) {
      margin: 0 24px;
    }

    span {
      &.active {
        display: block;
        border-bottom: 3px solid var(--info);
        border-radius: 2px;
      }
    }
  }
`;

PagesView.Tab = styled.p`
  cursor: pointer;
  margin-bottom: 8px;
  font-weight: 500;
  color: ${({ current, tab }) => current === tab ? "#fff" : "rgba(255,255,255, .35)"};
  text-transform: capitalize;

  &:hover {
    color: #fff;
  }
`;

export default PagesView;
