import React, {useState} from "react";
import {useDispatch} from "react-redux";
import styled from "styled-components";
import empty from "assets/img/empty-state-planet2.svg";
import {fetchTradeRequests, forceRunDeal} from "store/deals/actions";
import {addSnackBar} from "store/snackbar/actions";
import Button from "components/Button";
import {Card} from "reactstrap";

const EmptyRequestsList = ({
  title,
  beforeDescription,
  afterDescription,
  idDeal,
  actions={}
}) => {
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();

  const handleForceRun = async () => {
    setLoad(true);
    const res = await dispatch(forceRunDeal(idDeal));
    if (!res?.errors) {
      dispatch(addSnackBar("success", res.message));
      setLoad(false);
      dispatch(fetchTradeRequests(idDeal));
    }
    if (res?.errors) {
      dispatch(addSnackBar("error", res.message));
      setLoad(false);
    }
  };

  return (
    <EmptyRequestsList.Card>
      <img src={empty} alt=""/>
      <EmptyRequestsList.Title>
        {title}
      </EmptyRequestsList.Title>
      <EmptyRequestsList.Description>
        {beforeDescription}
        <span className="ml-1 mr-1 font-weight-500 text-success">
          BUY
        </span>
        or
        <span className="ml-1 mr-1 font-weight-500 text-danger">
          SELL
        </span>
        {afterDescription}
      </EmptyRequestsList.Description>
      {
        actions?.run ?
        <Button
          disabled={load}
          color="success"
          onClick={handleForceRun}
          loading={load}
          className="text-dark"
          size="middle"
        >
          Force run
        </Button> : null
      }
    </EmptyRequestsList.Card>
  )
}

EmptyRequestsList.Card = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 80px;
  border-radius: 8px !important;

  svg {
    rect {
      fill-opacity: .6;
    }
  }
`;

EmptyRequestsList.Title = styled.p`
  margin: 40px 0 0 0;
  color: #FFFFFF !important;
  font-weight: 500;
`;

EmptyRequestsList.Description = styled.p`
  margin-bottom: 40px;
  color: rgba(255, 255, 255, 0.75) !important;
  font-size: 14px;
`;

EmptyRequestsList.RunBtn = styled.div`
  button {
    color: #1D1E2A;
    background: #5FD3BC;
    padding: 12px 32px;
    margin: 0;
  }
  .btn:focus, .btn:active, .btn:hover, .btn:active:focus, .btn:active:hover {
    background: rgb(0, 242, 195, 0.5) !important;
    color: #1D1E2A !important;
  }
`;

EmptyRequestsList.Loader = styled.div`
  display: flex;
  justify-content: center;
  background: rgb(0, 242, 195, .35);
  border-radius: 8px;
  height: 43px;
  width: 123px;
`;

export default EmptyRequestsList;
