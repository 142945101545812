import React, { useEffect } from "react";
import {
  Col,
  Row
} from "reactstrap";
import {
  useDispatch,
  useSelector
} from "react-redux";
import qs from "qs";
import { Link } from "react-router-dom";

import Breadcrumbs from "components/Breadcrumbs";
import { setAgreementsBreadcrumbs } from "models/enum/breadcrumbs";
import RefreshButton from "components/RefreshButton";
import AgreementsDetailsHeader from "./compounds/AgreementsDetailsHeader";
import VolumesWidget from "./compounds/VolumesWidget";
import AvailableBalance from "./compounds/AvailableBalance";
import StatisticWidget from "./compounds/StatisticWidget";
import useRequestCancellation from "hooks/useRequestCancellation";
import {
  fetchRounds,
  getAgreement,
  resetAgreement
} from "store/agreements/actions";
import { selectAgreement } from "store/agreements/selectors";
import SignalWidget from "./compounds/SignalWidget";
import Error from "components/Error";
import CredentialWidgetPreload from "views/deals/ui/preloaders/CredentialWidgetPreload";
import Credential from "components/Credential";
import OverviewTab from "./pagesView/Overview/OverviewTab";
import Channel from "components/ChannelWidget";
import DangerZone from "components/DangerZone";
import { AGREEMENTS_CONTENT } from "models/constans/agreements/content";

const AgreementsPage = ({ match }) => {
  const { useAbortableEffect } = useRequestCancellation();
  const dispatch = useDispatch();

  const { data, loading, error } = useSelector(selectAgreement);

  useAbortableEffect((signal) => {
    dispatch(getAgreement(match?.params?.id, signal));
    return (() => dispatch(resetAgreement()));

  }, [dispatch, getAgreement, match?.params?.id], true);

  const onHandleRefresh = () => {
    const initialFilterCompleted = qs.stringify({
      filter: {
        "tr.status": {
          eq: 'completed'
        }
      }
    });
    dispatch(fetchRounds(match?.params?.id, `?${initialFilterCompleted}`));
    dispatch(getAgreement(match?.params?.id));
  };

  useEffect(() => {
    window.analytics.page("/agreements/:id");
  });

  if (error) return <Error error={error} />;
  return (
    <>
      <div className="d-none d-md-flex aling-items-center justify-content-between">
        <Breadcrumbs
          items={setAgreementsBreadcrumbs(
            data?.investment?.credentials?.exchange,
            data?.investment?.credentials?.id,
            data?.investment?.asset,
            data?.investment?.id,
          )}
          loading={loading}
        />
        <RefreshButton
          onHandleRefresh={onHandleRefresh}
          loading={loading}
        />
      </div>
      <AgreementsDetailsHeader
        id={data?.id}
        status={data?.status}
        error={data?.error}
        createdAt={data?.createdAt}
        base={data?.market?.base}
        quote={data?.market?.quote}
        loading={loading}
      />
      <Row>
        <Col
          md="4"
        >
          <VolumesWidget
            investedAmount={data?.invested}
            investedAsset={data?.market?.quote}
            purchasedAmount={data?.purchased}
            purchasedAsset={data?.market?.base}
            percent={data?.profitPercent}
            invested={data?.investment}
            purchased={data?.purchased}
            avgBuyPrice={data?.avgBuyPrice}
            avgSellPrice={data?.avgSellPrice}
            sold={data?.sold}
            unsold={data?.unsold}
            received={data?.received}
            profit={data?.profit}
            currentState={data?.currentState}
            status={data?.status}
            assessedValue={data?.assessedValue}
            loading={loading}
          />
          <AvailableBalance
            balances={data?.balances}
          />
          {
            data?.status !== "pending" ?
              <StatisticWidget
                data={data}
                loading={loading}
              />
              : null
          }
          <SignalWidget
            loading={loading}
            status={data?.signal?.status}
            base={data?.market?.base}
            quote={data?.market?.quote}
            openFrom={data?.signal?.buyFrom}
            openTo={data?.signal?.buyTo}
            stopLoss={data?.signal?.stopLoss}
            id={data?.signal?.id}
          />
          <Link
            key={data?.signal?.channel?.id}
            to={`/channels/${data?.signal?.channel?.id}`}
          >
            <Channel
              handleRefresh={onHandleRefresh}
              loading={loading}
              channel={data?.signal?.channel}
            />
          </Link>
          {loading && <CredentialWidgetPreload />}
          {
            data?.investment?.credentials &&
            <Credential
              credential={data?.investment?.credentials}
              loading={loading}
            />
          }
          <DangerZone
            id={match?.params.id}
            loading={loading}
            actions={data?.actions}
            onHandleRefresh={onHandleRefresh}
            content={AGREEMENTS_CONTENT?.details?.widgets?.dangerZone}
          />
        </Col>
        <Col md="8">
          <OverviewTab
            loadingPage={loading}
            id={match?.params?.id}
            data={data}
            onHandleRefresh={onHandleRefresh}
          />
        </Col>
      </Row>
    </>
  );
};

export default AgreementsPage;