import React from "react";
import styled from "styled-components";

const HelpIcon = ({
  className="cursor-pointer",
  ...props
}) => (
  <HelpIcon.Icon
    className={`far fa-question-circle ${className}`}
    {...props}
  />
);

HelpIcon.Icon = styled.i`
  font-size: 26px;
  cursor: pointer;
`;

export default HelpIcon;
