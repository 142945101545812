import React from "react";
import TradeStatisticChart from "../../charts/TradeStatisticChart";
import GeneralMetrics from "./GeneralMetrics";

const StatisticsTab = ({data, id, loadingPage}) => {
  return (
    <>
      <GeneralMetrics
        data={data}
        loadingPage={loadingPage}
      />

      <TradeStatisticChart
        id={id}
        loadingPage={loadingPage}
      />
    </>
  )
}

export default StatisticsTab;