import React, { useState } from "react";
import { Tooltip } from "reactstrap";

import { ReactComponent as Help } from "assets/img/icons/Help.svg";

const CriterionTooltips = ({ code="", idPrefix="", className="" }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const handle = (codes) => {
    switch (codes) {
      case "balance":
        return "The highest value of the final balance"
      case "average_profit_trade":
        return "The highest value of the average profit trade"
      case "profit_factor":
        return "The highest value of the profit factor"
      case "profitable_deals":
        return "The highest value of the profitable deals"
      case "expected_payoff":
        return "The highest value of the expected payoff"
      case "drawdown":
        return "The drawdown level is taken into account: Balance/Equity drawdown"
      case "recovery_factor":
        return "Value of the recovery factor"
      default:
        return null
    };
  };

  return (
    <>
      <span className="d-block">
        <Help
          className={`ml-1 mr-2 ${className}`}
          style={{cursor: "pointer"}}
          id={`${idPrefix}${code}`}
        />
      </span>
      <Tooltip placement="top" isOpen={tooltipOpen} target={`${idPrefix}${code}`} toggle={toggle}>
        {() => handle(code)}
      </Tooltip>
    </>
  )
}

export default CriterionTooltips;
