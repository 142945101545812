import React from "react";
import styled from "styled-components";

import SelectInput from "components/SelectInput";
import { REBALANCE_STATUS_OPTIONS } from "../../models";

const RebalanceFilter = ({ onHandleFilter, filter={} }) => {
  return(
    <RebalanceFilter.Wrapper className="mt-2 mt-md-0">
      <SelectInput
        isClearable
        className="mb-0 w-100"
        placeholder="Status"
        value={REBALANCE_STATUS_OPTIONS.find((option) => option.value === filter.status)}
        name="status"
        onChange={(option) => onHandleFilter({ status: { eq: option?.value } })}
        options={REBALANCE_STATUS_OPTIONS}
      />
    </RebalanceFilter.Wrapper>
  );
};

RebalanceFilter.Wrapper = styled.div`
  width: 33%;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export default RebalanceFilter;
