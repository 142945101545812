export const STATISTICS_ORDERS_TABLE_HEAD = [
  { name: "type", label: "Side", isSortable: true },
  { name: "id", label: "ID", isSortable: true },
  { name: "rate", label: "Price", isSortable: true },
  { name: "", label: "Filled", isSortable: false },
  { name: "total", label: "Total", isSortable: false },
  { name: "executedAmount", label: "Executed amount", isSortable: true },
  { name: "executedQuantity", label: "Executed quantity", isSortable: true },
  { name: "fee", label: "Fee", isSortable: true },
  { name: "tradesCount", label: "Trades", isSortable: true },
  { name: "status", label: "Status", isSortable: true },
  { name: "createdAt", label: "Time", isSortable: true },
];

export const STATISTICS_TRADES_TABLE_HEAD = [
  { name: "type", label: "Side", isSortable: true },
  { name: "orderNumber", label: "Order ID", isSortable: true },
  { name: "rate", label: "Price", isSortable: true },
  { name: "amount", label: "Amount", isSortable: true },
  { name: "quantity", label: "Quantity", isSortable: true },
  { name: "feeAmount", label: "Fee", isSortable: true },
  { name: "tradeType", label: "TradeType", isSortable: true },
  { name: "matchRole", label: "MatchRole", isSortable: true },
  { name: "createdAt", label: "Time", isSortable: true },
  { name: "tradeId", label: "ID", isSortable: true },
];

export const SIDE_OPTIONS = [
  { value: "buy", label: "Buy", color: "#3FCBB0" },
  { value: "sell", label: "Sell", color: "#EA4848" },
];

export const STATUS_OPTIONS = [
  { value: "open", label: "Open", color: "var(--info)"  },
  { value: "completed", label: "Completed", color: "#3FCBB0"  },
  { value: "cancelled", label: "Cancelled", color: "rgba(255, 255, 255, 0.35)"  },
];

export const REQUESTS_ORDERS_TABLE_HEAD = [
  {name: "type", label: "Side", isSortable: true},
  { name: "orderNumber", label: "ID", isSortable: true },
  { name: "rate", label: "Price", isSortable: true },
  { name: "total", label: "Total", isSortable: false },
  { name: "", label: "Filled", isSortable: false },
  { name: "executedAmount", label: "Executed amount", isSortable: true },
  { name: "executedQuantity", label: "Executed quantity", isSortable: true },
  { name: "fee", label: "Fee", isSortable: true },
  { name: "tradesCount", label: "Trades", isSortable: true },
  { name: "exchangeStatus", label: "Exchange Status", isSortable: true },
  { name: "status", label: "Status", isSortable: true },
  { name: "createdAt", label: "Time", isSortable: true },
];

export const REQUESTS_TRADES_TABLE_HEAD = [
  {name: "type", label: "Side", isSortable: true},
  {name: "market", label: "Market", isSortable: true},
  {name: "tradeId", label: "ID", isSortable: true},
  {name: "orderNumber", label: "Order ID", isSortable: true},
  {name: "matchRole", label: "Role", isSortable: true},
  {name: "tradeType", label: "Type", isSortable: true},
  {name: "rate", label: "Price", isSortable: true},
  {name: "amount", label: "Amount", isSortable: true},
  {name: "quantity", label: "Quantity", isSortable: true},
  {name: "feeAmount", label: "Fee", isSortable: true},
  {name: "createdAt", label: "Time", isSortable: true},
];

export const CONDITIONS_TABLE = [
  {name: "Name", label: "Name", isSortable: false},
  {name: "Expected", label: "Expected", isSortable: false},
  {name: "Fulfilled", label: "Fulfilled", isSortable: false},
];
