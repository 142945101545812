import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";

import { getReferralStatistics, getUsersReferrals } from "store/user/actions";
import ReferralSystemPreloader from "./preloaders/ReferralSystemPreloader";
import ReferralEmptyState from "./compounds/ReferralEmptyState";
import ReferralData from "./compounds/ReferralData";

const ReferralSystem = () => {

  const dispatch = useDispatch();

  const [data, setData] = useState();
  const [chartData, setChartData] = useState(null);
  const [hoverCurrentBg, setHoverCurrentBg] = useState(-1);
  const [hoverredElemId, setHoverredElemId] = useState(null);

  const referralCode = useSelector(state => state.me.data.referralCode);
  const referral = useSelector(state =>  state.me.data.referral);
  const statisticsLoading = useSelector(state => state.me.statisticsLoading);
  const meLoading = useSelector(state => state.me.loading)

  useEffect(() => {
    dispatch(getReferralStatistics()).then((res) => {
      const colors = {
        confirmed: "#0084F8",
        unconfirmed: "#E14ECA"
      };
      const unconfirmed = res.total - res.confirmed;
      res.unconfirmed = unconfirmed;
      const backgroundColors = [colors.confirmed, colors.unconfirmed]
      const data = [res.confirmed, res.unconfirmed];
      const index = data.findIndex((item) => item);
      const labels = Object.keys(res.statistics.total).slice(-7);
      const statisticsData = Object.values(res.statistics?.total).slice(-7);
      const maxAmount = res.total + 1;
      setData({labels, statisticsData, maxAmount})
      setChartData({ labels: ["Confirmed", "Unconfirmed"], data, backgroundColors });
      setHoverredElemId(index);
    });
  }, [dispatch]);

  useEffect(() => {
    if (referralCode) {
      dispatch(getUsersReferrals());
    }
  }, [dispatch, referralCode]);

  if(!referralCode && !meLoading) return <ReferralEmptyState/>;

  if(!referralCode || !referral || !data || statisticsLoading) return <ReferralSystemPreloader/>;

  return (
    <ReferralData
      referral={referral}
      chartData={chartData}
      setHoverCurrentBg={setHoverCurrentBg}
      hoverCurrentBg={hoverCurrentBg}
      setHoverredElemId={setHoverredElemId}
      hoverredElemId={hoverredElemId}
      statisticsData={data?.statisticsData}
      labels={data?.labels}
      maxAmount={data?.maxAmount}
    />
  )
}

export default ReferralSystem;
