import React from "react";
import SkeletonPreloader from "components/SkeletonPreloader";
import {Table} from "reactstrap";
import _ from "lodash";

const MartingaleTablePreloader = () => {
  return (
    <div className="overflow-hidden">
      <div className="d-flex align-items-center px-4 py-3">
        <SkeletonPreloader
          width={184}
          height={38}
          className="mr-3"
          borderRadius="6px"
        />
        <SkeletonPreloader width={326} height={10}/>
      </div>

      <Table>
        <thead>
        <tr className="">
          {
            _.times(8, (e) => <th key={e} className="px-4"><SkeletonPreloader width={46} height={10}/></th>)
          }
        </tr>
        </thead>
        <tbody>
        {
          _.times(5, (e) =>
            <tr key={e} style={{borderTop: "1px solid #2E3148"}}>
              <td className="px-4">
                <SkeletonPreloader width={20} height={14}/>
              </td>
              <td className="px-4">
                <SkeletonPreloader width={95} height={14}/>
              </td>
              <td className="px-4">
                <SkeletonPreloader width={118} height={14}/>
              </td>
              <td className="px-4">
                <SkeletonPreloader width={147} height={14}/>
              </td>
              <td className="px-4">
                <SkeletonPreloader width={123} height={14}/>
              </td>
              <td className="px-4">
                <SkeletonPreloader width={147} height={14}/>
              </td>
              <td className="px-4">
                <SkeletonPreloader width={157} height={14}/>
              </td>
              <td className="px-4">
                <SkeletonPreloader width={66} height={14}/>
              </td>
            </tr>
          )
        }
        </tbody>
      </Table>
    </div>
  )
}

export default MartingaleTablePreloader;