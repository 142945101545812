import React from "react";
import styled from "styled-components";
import { Card } from "reactstrap";
import { useSelector } from "react-redux";

import SpotWalletPreloader from "../preloaders/SpotWalletPreloader";

const SpotWalletWidget = ({balances, amount, currency, btc, loadingPage}) => {

  const loading = useSelector(state => state.credentials.balances.loading);

  if(loading || !amount || !currency || !btc || loadingPage) return <SpotWalletPreloader/>;

  return (
    <SpotWalletWidget.Card className="d-flex flex-row">
      <SpotWalletWidget.Wrapper className="mb-0 mr-2">
        <div
          className="d-flex flex-fill"
          style={{padding: "12px 24px"}}
          >
          <h2 className="mb-0 text-white align-self-center">
            {balances} Coins
          </h2>
        </div>
      </SpotWalletWidget.Wrapper>
      <SpotWalletWidget.Wrapper className="mr-2 mb-0">
        <div
          className="d-flex justify-content-start"
          style={{padding: "12px 16px"}}
          >
          <div className="d-flex align-items-start flex-column">
            <p className="font-weight-300 text-white-75 mb-0">
              Total
            </p>
            <div className="d-flex mt-1 flex-sm-column flex-lg-row">
              <h1 className="mb-0 text-white font-weight-700">
                {btc}
              </h1>
              <h1 className="font-weight-300 text-white-35 mb-0 ml-sm-0 ml-lg-1">
                BTC
              </h1>
            </div>
          </div>
        </div>
      </SpotWalletWidget.Wrapper>
      <SpotWalletWidget.Wrapper className="mb-0">
      <div
        className="d-flex justify-content-start"
        style={{padding: "12px 16px"}}
        >
        <div className="d-flex align-items-start flex-column">
          <p className="font-weight-300 text-white-75 mb-0">
            Estimated
          </p>
          <div className="d-flex mt-1">
            <h1 className="mb-0 text-white font-weight-700">
              {amount}
            </h1>
            <h1
              className="font-weight-300 text-white-35 mb-0 ml-1">
              {currency}
            </h1>
          </div>
        </div>
      </div>
      </SpotWalletWidget.Wrapper>
    </SpotWalletWidget.Card>
  );
}

SpotWalletWidget.Card = styled(Card)`
  margin-bottom: 2rem !important;
`;

SpotWalletWidget.Wrapper = styled(Card)`
  background: #363A54 !important;
  margin-bottom: 0px;
  & h1{
    font-size: 19px;
  }
  & h2{
    font-size: 24px;
  }
  & p {
    font-size: 14px;
    line-height: normal;
  }
`;

export default SpotWalletWidget;
