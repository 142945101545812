import React from "react";
import SkeletonPreloader from "components/SkeletonPreloader";
import { Card } from "reactstrap";

const AllocationPreloader = () => {
  return (
    <Card style={{padding: "24px"}}>
      <div className="d-flex align-items-center">
        <SkeletonPreloader
          circle={true}
          height={16}
          width={16}
        />
        <SkeletonPreloader
          height={12}
          width={60}
          className="ml-2"
        />
      </div>
      <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
        <SkeletonPreloader
          height={10}
          width={56}
        />
        <SkeletonPreloader
          height={10}
          width={50}
        />
      </div>
      <SkeletonPreloader
        height={6}
        width="100%"
      />
    </Card>
  )
}

export default AllocationPreloader;
