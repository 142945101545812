import React from "react";
import SkeletonPreloader from "components/SkeletonPreloader";
import _ from "lodash";

const SettingsPreloader = () => {
  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <SkeletonPreloader
          circle={true}
          width={20}
          height={20}
          className="mr-2"
        />
        <SkeletonPreloader
          width={103}
          height={16}
        />
      </div>
      <div className="d-flex justify-content-between mt-4">
        <SkeletonPreloader
          width={81}
          height={10}
        />
        <SkeletonPreloader
          width={61}
          height={14}
        />
      </div>
      {
        _.times(3, (e) => {
          return (
            <div className="d-flex justify-content-between mt-3" key={e}>
              <SkeletonPreloader
                width={81}
                height={10}
              />
              <SkeletonPreloader
                width={121}
                height={14}
              />
            </div>
          )
        })
      }
    </>
  )
}

export default SettingsPreloader;
