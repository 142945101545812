import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import PercentBadge from "components/PercentBadge";
import {
  formatterToNull,
  setColorClass
} from "helpers/valuesFormatters";

const MyAgreementsRow = ({ agreement }) => {
  const history = useHistory();
  return (
    <MyAgreementsRow.Row
      onClick={() => history.push(`/agreements/${agreement?.id}`)}
      onMouseDown={(e) => e.button === 1 && window.open(`/agreements/${agreement?.id}`, "_blank")}
    >
      <td >
        <p className="mb-0 text-white-75">
          Invested
        </p>
        <p className="mb-0 font-weight-500 text-white text-nowrap">
          {formatterToNull(agreement?.invested)}{" "}
          <span className="text-white-35 font-weight-300 ">
            {agreement?.market?.quote}
          </span>
        </p>
      </td>
      <td >
        <p className="mb-0 text-white-75">
          Purchased
        </p>
        <p className="mb-0 font-weight-500 text-white text-nowrap">
          {formatterToNull(agreement?.purchased)}{" "}
          <span className="text-white-35 font-weight-300 ">
            {agreement?.market?.base}
          </span>
        </p>
      </td>
      <td >
        <p className="mb-0 text-white-75">
          Received
        </p>
        <p className="mb-0 font-weight-500 text-white text-nowrap">
          {formatterToNull(agreement?.received)}{" "}
          <span className="text-white-35 font-weight-300 ">
            {agreement?.market?.quote}
          </span>
        </p>
      </td>
      <td>
        <p className="mb-0 text-white-75">
          Sold
        </p>
        <p className="mb-0 font-weight-500 text-white text-nowrap">
          {formatterToNull(agreement?.sold)}{" "}
          <span className="text-white-35 font-weight-300 ">
            {agreement?.market?.base}
          </span>
        </p>
      </td>
      <td>
        <p className="mb-0 text-white-75">
          Unsold
        </p>
        <p className="mb-0 font-weight-500 text-white text-nowrap">
          {formatterToNull(agreement?.unsold)}{" "}
          <span className="text-white-35 font-weight-300 ">
            {agreement?.market?.base}
          </span>
        </p>
      </td>
      <td colSpan={3}>
        <p className="mb-0 text-white-75">
          Profit {" "}
          <PercentBadge
            value={agreement?.profitPercent}
            showZeroValue
          />
        </p>
        <p className={`${setColorClass(agreement?.profit)} mb-0 font-weight-500`}>
          {formatterToNull(agreement?.profit)}{" "}
          <span className="text-white-35 font-weight-300 ">
            {agreement?.market?.quote}
          </span>
        </p>
      </td>
    </MyAgreementsRow.Row>
  )
};

MyAgreementsRow.Row = styled.tr`
  background: #2E3148 !important;
  cursor: pointer;

  &:hover {
    background: #363A54 !important;
  }
`;

export default MyAgreementsRow;