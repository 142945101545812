import React from "react";
import styled from "styled-components";
import * as Sentry from "@sentry/react";
import { useSelector } from "react-redux";

import FallBack from "components/FallBack";
import SkeletonPreloader from "components/SkeletonPreloader";
import PortfolioSection from "../compounds/portfolio/PortfolioSection";
import StatisticsBalance from "../charts/StatisticsBalance";
import TradeHistoryOpenOrdersSection from "./TradeHistoryAndOpenOrdersSection";
import TradeHistoryChartStatistics from "../charts/tradeHistoryChart";
import OrdersWidgetChart from "../charts/OrdersChart";
import RebalanceHistory from "../compounds/RebalanceHistory";
import MarketStatisticsTabsCredentials from "./marketStatisticsTabsCredentials";
import SpotWalletPagesView from "./SpotWalletTabsCredentials";

const pages = {
  portfolio: "Main portfolio",
  balances: "Balances",
  statistics: "Statistics",
  rebalanceHistory: "Rebalance History"
};

const PagesViewCredentials = ({
  data,
  loadingPage,
  id,
  location,
  pageView,
  setPagView,
  isRebalanceLoading
}) => {
  const balances = useSelector((state) => state.credentials.balances.data)

  return (
    <>
      {
        loadingPage
        ? <div className="mb-4">
            <div className="d-flex align-items-center">
              <SkeletonPreloader height={11} width={66}/>
              <SkeletonPreloader height={11} width={66} className="mx-4"/>
              <SkeletonPreloader height={11} width={66}/>
              <SkeletonPreloader height={11} width={66} className="mx-4"/>
            </div>
            <SkeletonPreloader height={3} width={66}/>
        </div>
        :
          <PagesViewCredentials.Page className="d-flex justify-content-center justify-content-md-start flex-wrap">
          {
            Object.entries(pages).map((page) => {
              return(
                <div
                  className="mb-3 mb-md-0"
                  key={page[0]}
                >
                  <PagesViewCredentials.Tab
                    onClick={() => setPagView(page[0])}
                    current={pageView}
                    tab={page[0]}
                    className="text-nowrap mb-2"
                  >
                    {page[1]}
                  </PagesViewCredentials.Tab>
                  <span className={`${(pageView === page[0]) ? "active" : ""}`}/>
                </div>
              );
            })
          }
        </PagesViewCredentials.Page>
      }
      {
        pageView === "portfolio" &&
        <Sentry.ErrorBoundary fallback={<FallBack/>}>
          <PortfolioSection
            id={id}
            location={location}
            rebalancingDifference={data.rebalancingDifference}
            rebalance={data.rebalance}
            loadingPage={loadingPage}
          />
        </Sentry.ErrorBoundary>
      }
      {
        pageView === "balances" &&
          <>
            <Sentry.ErrorBoundary fallback={<FallBack />}>
              <StatisticsBalance
                id={id}
                currency="BTC"
                loadingPage={loadingPage}
              />
            </Sentry.ErrorBoundary>
            <Sentry.ErrorBoundary fallback={<FallBack/>}>
              <SpotWalletPagesView
                id={id}
                balances={balances}
                request={balances?.request}
                estimated={data?.estimated}
                data={data}
                loadingPage={loadingPage}
              />
            </Sentry.ErrorBoundary>
          </>
      }
      {
        pageView === "statistics" &&
        <>
        <Sentry.ErrorBoundary fallback={<FallBack/>}>
          <TradeHistoryOpenOrdersSection
            data={data}
            loading={loadingPage}
          />
        </Sentry.ErrorBoundary>
        <Sentry.ErrorBoundary fallback={<FallBack/>}>
          <TradeHistoryChartStatistics
            tradesChart={data.tradesChart}
          />
        </Sentry.ErrorBoundary>
        <Sentry.ErrorBoundary fallback={<FallBack/>}>
          <OrdersWidgetChart
            openOrdersChart={data.openOrdersChart}
          />
        </Sentry.ErrorBoundary>
        <Sentry.ErrorBoundary fallback={<FallBack/>}>
          <MarketStatisticsTabsCredentials
            id={id}
            loadingPage={loadingPage}
          />
        </Sentry.ErrorBoundary>
      </>
      }
      {
        (pageView === "rebalanceHistory") &&
          <Sentry.ErrorBoundary fallback={<FallBack/>}>
            <RebalanceHistory
              id={id}
              loadingPage={loadingPage}
              isRebalanceLoading={isRebalanceLoading}
            />
          </Sentry.ErrorBoundary>
      }
    </>
  )
};

PagesViewCredentials.Tab = styled.p`
  cursor: pointer;
  font-weight: 500;
  color: ${({current, tab}) => current === tab ? "#fff" : "rgba(255,255,255, .35)"};

  &:hover {
    color: #FFF;
  }
`;

PagesViewCredentials.Page = styled.div`
  display: flex;
  margin-bottom: 24px;
  div {
    &:nth-child(2) {
      margin: 0 24px;
    }

    &:nth-child(4) {
      margin: 0 24px;
    }

    &:nth-child(6) {
      margin: 0 24px;
    }
  span {
    &.active {
      display: block;
      border-bottom: 3px solid var(--info);
      border-radius: 2px;
    }
  }
`;

export default PagesViewCredentials;
