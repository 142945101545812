import React from "react";
import styled from "styled-components";
import { ClipLoader } from "react-spinners";
import classnames from "classnames";

const Button = ({
  disabled,
  loading,
  color = "gray",
  children,
  outline,
  cancel,
  className,
  onClick,
  size,
  labelText,
  ...props
}) => {

  if (cancel) {
    return <Button.Cancel
      loading={loading}
      color={color}
      className={classnames(`${className} transparent-outline`, {
        "opacity-3": disabled || loading,
      })}
      onClick={onClick}
      {...props}
      data-testid="cancel"
    >
      {
        loading ? <ClipLoader color="var(--info)" size={16} loading={true} /> :
          <div className="d-flex align-items-center justify-content-center" data-testid="div">
            <svg width="32" height="32" viewBox="5 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 6.5L13.5 13.5M6.5 13.5L13.5 6.5"
                stroke={
                  (color === "red" && "rgba(237, 95, 95, 1)") ||
                  (color === "gray" && "rgba(255, 255, 255, 0.35)")
                }
              />
            </svg>
          </div>
      }
    </Button.Cancel>
  }

  const setColorWithOutline = (bgc) => (outline && color === bgc);
  const setColorWithoutOutline = (bgc) => (!outline && color === bgc);

  return <Button.Styled
    loading={loading}
    labelText={labelText}
    color={color}
    outline={outline}
    onClick={onClick}
    disabled={disabled}
    className={
      classnames(`${className} transparent-outline bg-none font-sm font-weight-500 text-white`, {
        "opacity-3": disabled || loading,
        "red-outline": setColorWithOutline("red"),
        "gray-outline": setColorWithOutline("gray"),
        "blue-outline": setColorWithOutline("blue"),
        "success-outline": setColorWithOutline("success"),
        "btn-red": setColorWithoutOutline("red"),
        "btn-gray": setColorWithoutOutline("gray"),
        "btn-blue": setColorWithoutOutline("blue"),
        "btn-green": setColorWithoutOutline("success"),
        "small": size === "small",
        "middle": size === "middle",
        "big": size === "big",
      })}
    {...props}
  >
    {
      loading ?
        <div className="loading-wrapper position-relative d-flex align-items-center justify-content-center">
          <ClipLoader color="white" size={21} loading className="position-absolute top-0"/>
               <p className="mb-0">
                {children}
              </p>

        </div>
        :
        children
    }
  </Button.Styled>
};

Button.Styled = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 32px;
  line-height: 16px;
  border-radius: 6px;
  transition: all 0.2s ease-in;
  cursor: ${props => props.disabled ? "default" : "pointer"} !important;
  .loading-wrapper {
    height: 21px;

    div {
      position: absolute;
      top: 0;
    }
    p {
      opacity: 0;
    }
  }
  &.small {
    white-space: nowrap;
    width: 63px !important;
    height: 32px !important;
  }
  &.middle {
    white-space: nowrap;
    width: 95px !important;
    height: 40px !important;
  }
  &.big {
    white-space: nowrap;
    width: 154px !important;
    height: 40px !important;
  }
  &:hover {
    border: 1px solid transparent !important;
    background-color: ${(props) =>
    (props.color === "red" && !props.disabled && !props.loading && "rgba(186, 74, 74, 1)") ||
    (props.color === "gray" && !props.disabled && !props.loading && "rgba(255, 255, 255, 0.05)") ||
    (props.color === "blue" && !props.disabled && !props.loading && "rgba(25, 81, 155, 1)") ||
    (props.color === "success" && !props.disabled && !props.loading && "rgba(46, 168, 145, 1)")
  } !important;
  }
  &:active {
    border: 1px solid transparent !important;
    background-color: ${(props) =>
    (props.color === "red" && !props.disabled && !props.loading && "rgba(115, 55, 55, 1)") ||
    (props.color === "gray" && !props.disabled && !props.loading && "rgba(255, 255, 255, 0.02)") ||
    (props.color === "blue" && !props.disabled && !props.loading && "rgba(25, 62, 111, 1)") ||
    (props.color === "success" && !props.disabled && !props.loading && "rgba(37, 136, 117, 1)")
  } !important;
  }

  ${({ labelText }) => labelText ? `
    &:after {
      content: "${labelText}";
      position: absolute;
      font-size: 0.625rem;
      border-radius: 5px;
      right: 0;
      top: 0;
      background: #1FAC46;
      padding: 0.125rem 0.25rem;
      border: 3px solid var(--dark-slate);
      right: -12px;
      top: -11px;
    }
  ` : ''}
`

Button.Cancel = styled(Button.Styled)`
  width: 32px;
  height: 32px;
  padding: ${(props) => props.loading ? 0 : "11px "} !important;
  background-color: ${(props) =>
    props.loading ? "rgba(255, 255, 255, 0.1)" : "inherit"
  };

  &:hover {
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 0.1) !important;
    svg {
      path {
        stroke: ${(props) =>
    props.color === "gray" ? "rgba(255, 255, 255, 0.75)" : ""
  } !important;
      }
    }
  }
`

export default Button;
