import React from "react";
import styled from "styled-components";

const EditBotHeader = () => {
  return (
    <StyledHeader className="d-flex flex-column align-items-sm-center flex-sm-row">
      <h1 className=" mb-0 font-weight-500 text-white">
        Edit Bot Configuration
      </h1>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  justify-content: space-between;
  background: #2E3148;
  padding: 24px 32px;
`;

export default EditBotHeader;
