import React, {
  useEffect,
  useLayoutEffect,
  useState
} from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "components/Button";
import Signal from "./compounds/Signal";
import Filters from "./compounds/Filters";
import PaginationPanel from "components/PaginationPanel";
import EmptyStateWithBtnLink from "components/EmptyStateWithBtnLink";
import CreateSignalModal from "views/channels/modals/CreateSignalModal";
import SignalsListPreloader from "./preloaders/SignalsListPreloader";
import { EmptyFiltersListingState } from "shared/ui";
import useRequestCancellation from "hooks/useRequestCancellation";
import { selectMeData } from "store/user/selectors";
import { signalsList } from "store/signals/selectors";
import {
  getSignals,
  getSignalsMarkets,
  resetSignals
} from "store/signals/action";
import useFilterAndSorting from "hooks/useFilterAndSorting";
import usePagination from "hooks/usePagination";

const SignalsList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { location, push } = useHistory();
  const dispatch = useDispatch();

  const {
    loading,
    error,
    data: {
      items,
      total,
      page
    }
  } = useSelector(signalsList);
  const { id } = useSelector(selectMeData);

  const { useAbortableEffect } = useRequestCancellation();
  const handleToggleModal = () => setIsModalOpen((prev) => !prev);
  const {handlePaginate} = usePagination("/signals");
  const {
    handleFilter,
    filter,
  } = useFilterAndSorting("/signals", getSignals);
  
  const exchange = filter?.exchange?.in?.[0];

  useAbortableEffect((signal) => {
    const queryString = `${location.search}${location.search ? "&" : "?"}limit=10`;
    dispatch(getSignals(queryString, signal));
    return () => dispatch(resetSignals());
  }, [location.search, dispatch], true);
  
  useLayoutEffect(() => {
    if (exchange) {
      dispatch(getSignalsMarkets(exchange));
    }
  }, [dispatch, exchange]);

  const renderState = () => {
    if (loading || !id) return <SignalsListPreloader />;
    if (location?.search?.includes("filter") && !items?.length) return <EmptyFiltersListingState />
    if (!items?.length || error) {
      return (
        <EmptyStateWithBtnLink
          title="There are no signals"
          description="In order to see signals, you need to either subscribe to the channel or create a signal in your channel"
          onHandleClick={()=> push("/channels")}
          buttonText="Go to the channel list"
        />
      )
    }
  };

  useEffect(() => {
    window.analytics.page("/signals")
  });

  return (
    <>
      <div className="list-page-header d-flex align-items-center mt-4 justify-content-between">
        <h1 className="mb-0 text-white font-weight-500">
          Signals
        </h1>
        {
          !location.search?.includes("filter")
            ?
            <Button
              color="blue"
              className="m-0 mb-sm-2"
              onClick={(handleToggleModal)}
              disabled={isModalOpen}
            >
              Create new
            </Button>
            : null
        }
      </div>
      <Filters
        userId={id}
        filters={filter}
        handleFilter={handleFilter}
        exchange={exchange}
      />
      {renderState()}
      {items?.length && items?.map((signal) =>
        <Signal
          key={signal.id}
          loading={loading}
          signal={signal}
          id={signal.id}
          createdAt={signal?.createdAt}
          status={signal?.status}
          base={signal?.market?.base}
          quote={signal?.market?.quote}
          exchange={signal?.exchange}
          channelId={signal?.channel?.id}
          channelName={signal?.channel?.name}
          channelTotalSubscribers={signal?.channel?.totalSubscribers}
          isSubscribed={signal?.channel?.subscribed}
          channelAvatar={signal?.channel?.logos?.small}
          agreements={signal?.agreements}
          chart={signal?.chart}
        />
      )}

      <PaginationPanel
        activePage={page}
        totalItemsCount={total}
        totalPagesView={10}
        onChange={handlePaginate}
      />
      <CreateSignalModal
        handleToggleModal={handleToggleModal}
        isModalOpen={isModalOpen}
      />
    </>
  );
};

export default SignalsList;