import React from "react";
import styled from "styled-components";

const SubCard = ({children, ...props}) => {
  return (
    <SubCard.Wrapper {...props}>
      {children}
    </SubCard.Wrapper>
  )
}

SubCard.Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  padding: 3.5px 10px;
  max-height: 23px;
  width: fit-content;
  p {
    line-height: 16.41px !important;
  }
`

export default SubCard;
