import React, {useCallback} from "react";
import styled from "styled-components";
import {Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import GoogleLogin from "react-google-login";
import {GOOGLE_OAUTH_CLIENT_ID} from "env-create-react-app";
import Button from "components/Button";
import {connectGoogleAccount} from "store/user/actions";
import {addSnackBar} from "store/snackbar/actions";
import {CONNECT_GOOGLE_ACCOUNT} from "store/user/constants";
import {useDispatch} from "react-redux";
import SubCard from "components/SubCard";

const GoogleAccountForm = ({setProcessing}) => {
  const dispatch = useDispatch()

  const responseGoogleSuccess = useCallback(async(response) => {
    const body = {
      googleId: response.googleId,
      profileObj: {
        email: response.profileObj.email,
        familyName: response.profileObj.familyName,
        givenName: response.profileObj.givenName,
        imageUrl: response.profileObj.imageUrl
      },
      token: response.tokenObj.id_token
    };
    const res = await dispatch(connectGoogleAccount(body));
    if (res.errors || res.status === 400) {
      dispatch(addSnackBar("error", res.data.message))
    } else {
      dispatch(addSnackBar("success", res.message))
      dispatch({type: CONNECT_GOOGLE_ACCOUNT, payload: body.profileObj})
    }
    setProcessing(false);
  }, [setProcessing, dispatch]);

  const responseGoogleFailure = useCallback(() => {
    setProcessing(false);
  }, [setProcessing]);

  return (
    <Card className="p-2">
      <GoogleAccountForm.Header className="d-flex flex-row align-items-center">
        <p className="h4 mb-0 mr-1 text-white">Google account</p>
        <SubCard>
          <p className="mb-0 font-weight-500 text-danger font-sm">Unauthorized</p>
        </SubCard>
      </GoogleAccountForm.Header>
      <CardBody className="pb-0">
        <p className="text-white-75 font-sm">
          You can log in using your Google account. To do this, click on the button below.
        </p>
      </CardBody>
      <CardFooter>
        <GoogleLogin
          clientId={GOOGLE_OAUTH_CLIENT_ID}
          render={(renderProps) => (
            <Button
              color="blue"
              onClick={() => {
                setProcessing(true);
                renderProps.onClick();
              }}
            >
              <i className="mr-1 fab fa-google" />
              Connect account
            </Button>
          )}
          onSuccess={responseGoogleSuccess}
          onFailure={responseGoogleFailure}
          cookiePolicy={"single_host_origin"}
        />
      </CardFooter>
    </Card>
  )
}

GoogleAccountForm.Header = styled(CardHeader)`
  p {
    line-height: 19px;
  }
`

export default GoogleAccountForm;
