import createAction from "store/thunkFactory";
import { get } from "lodash";

import {
  SUBSCRIBE_CHANNEL,
  RESET_CHANNELS,
  RESET_CHANNEL,
  CREATE_CHANNEL,
  UPDATE_CHANNEL_INFORM,
  SUBSCRIBE_CHANNEL_ITEM,
  UNSUBSCRIBE_CHANNEL_ITEM,
  CLOSE_CHANNEL,
  PUT_SUBSCRIPTION_SETTINGS,
  RESTORE_CHANNEL,
  UPLOAD_CHANNEL_LOGO,
  RESET_CHANNEL_LOGO
} from "./constants";

export const getChannels = (filter, signal) => createAction("CHANNELS", (fetch) => {
  return fetch.get(`/app/channels${filter || ""}`, { signal });
});

export const getChannel = (id, signal) => createAction("CHANNEL", (fetch) => {
  return fetch.get(`/app/channels/${id}`, { signal });
});

export const updateInfo = (id, body) => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.patch(`/app/channels/${id}`, body);
    dispatch({ type: UPDATE_CHANNEL_INFORM, payload: res.data.item });
    return res.data;
  } catch (error) {
    return get(error, "response.data", { errors: [], message: error.message });
  }
};

export const createChannel = (channel, limit) => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/app/channels", channel);
    dispatch({ type: CREATE_CHANNEL, payload: {channel: res.data.item, limit}});
    return res.data;
  } catch (error) {
    return get(error, "response.data", { errors: [], message: error.message });
  }
};

export const subscribeChannel = (id, isItem, body) => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post(`/app/channels/${id}/subscribe`, body);
    const type = isItem ? SUBSCRIBE_CHANNEL_ITEM : SUBSCRIBE_CHANNEL
    dispatch({ type, payload: { id } });
    return res.data;
  } catch (error) {
    return get(error, "response.data", { errors: [], message: error.message });
  }
}

export const unsubscribeChannel = (id) => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post(`/app/channels/${id}/unsubscribe`);
    dispatch({ type: UNSUBSCRIBE_CHANNEL_ITEM, payload: { id } });
    return res.data;
  } catch (error) {
    return get(error, "response.data", { errors: [], message: error.message });
  }
}

export const resetChannels = () => ({ type: RESET_CHANNELS });

export const resetChannel = () => ({ type: RESET_CHANNEL });

export const closeChannel = (id) => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post(`/app/channels/${id}/close`);
    dispatch({type: CLOSE_CHANNEL, payload:res.data.item});
    return res.data;
  } catch (error) {
    return get(error, "response.data", {errors: [], message: error.message})
  }
};

export const restoreChannel = (id) => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post(`/app/channels/${id}/restore`);
    dispatch({type: RESTORE_CHANNEL, payload: res.data.item});
    return res.data;
  } catch (error) {
    return get(error, "response.data", {errors: [], message: error.message})
  }
};

export const updateSubscriptionSettings = (id, body) => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.put(`/app/channels/${id}/subscription-settings`, body);
    dispatch({ type: PUT_SUBSCRIPTION_SETTINGS, payload: res.data.item });
    return res.data;
  } catch (error) {
    return get(error, "response.data", { errors: [], message: error.message });
  }
};

export const uploadChannelLogo = (channelId, body) => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post(`/app/${channelId}/logo`, body);
    dispatch({ type: UPLOAD_CHANNEL_LOGO, payload: res.data.logos});
    return res.data;
  } catch (error) {
    return get(error, "response.data", { errors: [], message: error.message });
  }
};

export const resetChannelLogo = (channelId) => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.delete(`/app/${channelId}/logo`);
    dispatch({ type: RESET_CHANNEL_LOGO, payload: res.data.item });
    return res.data;
  } catch (error) {
    return get(error, "response.data", { errors: [], message: error.message });
  }
};