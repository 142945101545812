import React from "react";
import styled from "styled-components";
import { Card } from "reactstrap";
import { ReactComponent as SettingIcon } from "assets/img/icons/setting-2.svg";
import moment from "moment";
import SettingsPreloader from "../preloaders/SettingsPreloader";

const Settings = ({data={}, loading}) => {
  if (loading) {
    return (
      <Settings.Card>
        <SettingsPreloader/>
      </Settings.Card>
    );
  }

  return (
    <Settings.Card>
      <div className="d-flex align-items-center mb-3">
        <SettingIcon/>
        <Settings.Setting>Settings</Settings.Setting>
      </div>

      <Settings.Row className="d-flex flex-md-column flex-lg-row align-items-lg-center">
        <Settings.Title>Percent</Settings.Title>
        <Settings.Amount amount={data.percent}>
          {`${data.percent} %`}
        </Settings.Amount>
      </Settings.Row>

      <Settings.Row className="d-flex flex-md-column flex-lg-row align-items-lg-center">
        <Settings.Title>Total</Settings.Title>
        <div className="d-flex">
          <Settings.Amount amount={data.total}>
            {data.total}
          </Settings.Amount>
          <p className="mb-0 ml-1 font-weight-300 text-white-35">
            {data.investment?.asset}
          </p>
        </div>
      </Settings.Row>

      {
        data.failedAt &&
        <Settings.Row className="d-flex flex-md-column flex-lg-row align-items-lg-center">
          <Settings.Title>Failed at</Settings.Title>
          <Settings.Amount amount={1}>
            {moment(data?.failedAt).format("DD.MM.YYYY HH:mm:ss")}
          </Settings.Amount>
        </Settings.Row>
      }

      {
        data.completedAt &&
        <Settings.Row className="d-flex flex-md-column flex-lg-row align-items-lg-center">
          <Settings.Title>Completed at</Settings.Title>
          <Settings.Amount amount={1}>
            {moment(data?.completedAt).format("DD.MM.YYYY HH:mm:ss")}
          </Settings.Amount>
        </Settings.Row>
      }
    </Settings.Card>
  )
}

Settings.Card = styled(Card)`
  padding: 24px !important;
  margin-bottom: 24px !important;
  border-radius: 8px !important;
`;

Settings.Setting = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF !important;
  margin: 0 0 0 8px;
`;

Settings.Row = styled.div`
  justify-content: space-between;
  white-space: nowrap;
  margin-top: 8px;
`;

Settings.Title = styled.p`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75) !important;
  margin: 0;
`;

Settings.Amount = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.amount > 0 ? "rgba(255, 255, 255)" : "rgba(255, 255, 255, 0.35)"} !important;
  margin: 0;
`;

export default Settings;
