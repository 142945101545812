import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { Col } from "reactstrap";

import SkeletonPreloader from "components/SkeletonPreloader";

const LoginHistoryPreLoad = () => {
  return(
    <>
      <LoginHistoryPreLoad.Header className="p-3">
        <div className="d-flex">
          <Col xs="4">
            <SkeletonPreloader
              height={13}
            />
          </Col>
          <Col xs="4">
            <SkeletonPreloader
              height={13}
            />
          </Col>
          <Col xs="2">
            <SkeletonPreloader
              height={13}
            />
          </Col>
          <Col xs="2">
            <SkeletonPreloader
              height={13}
            />
          </Col>
        </div>
      </LoginHistoryPreLoad.Header>
      {
        _.times(10, (e) =>
          <LoginHistoryPreLoad.Item
            className="p-3"
            key={e}
          >
            <div className="d-flex">
              <Col xs="4">
                <SkeletonPreloader
                  height={13}
                />
              </Col>
              <Col xs="4">
                <SkeletonPreloader
                  height={13}
                />
              </Col>
              <Col xs="2">
                <SkeletonPreloader
                  height={13}
                />
              </Col>
              <Col xs="2">
                <SkeletonPreloader
                  height={13}
                />
              </Col>
            </div>
          </LoginHistoryPreLoad.Item>
        )
      }
    </>
  );
};

LoginHistoryPreLoad.Header = styled.div`
  background: #2E3148;
  border-bottom: 1px solid #1D1E2A !important;
  border-radius: 8px 8px 0 0;
`;

LoginHistoryPreLoad.Item = styled.div`
  background: var(--dark-slate);
  border-bottom: 1px solid #1D1E2A !important;
  &:last-child {
    border-radius: 0 0 8px 8px;
  }
`;

export default LoginHistoryPreLoad;
