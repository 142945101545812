import React, {
  useEffect,
  useState
} from "react";
import styled from "styled-components";
import {
  useDispatch,
  useSelector
} from "react-redux";
import { ClipLoader } from "react-spinners";
import { Col } from "reactstrap";

import {
  Footer,
  Body
} from "../compounds/Layout";
import Button from "components/Button";
import { exchangesSelector } from "store/exchanges/selectors";
import { getExchanges } from "store/exchanges/actions";
import binance from "assets/img/mainWizard/binance.svg";
import poloniex from "assets/img/mainWizard/poloniex.svg";
import hitbtc from "assets/img/mainWizard/hitbtc.svg";
import binanceus from "assets/img/mainWizard/binanceus.svg";
import bitfinex from "assets/img/mainWizard/bitfinex.svg";
import { ReactComponent as Export } from "assets/img/icons/export.svg";
import { ReactComponent as ArrowLeft } from "assets/img/icons/arrow-left.svg";

const ExchangeStep = ({
  currentStep,
  setCurrentStep,
  handleScrollUp,
  setIsCurrentStepValid,
  handleMoveToNextStep,
  exchange,
  setExchange,
  exchangeName,
  websiteLink,
  setExchangeName,
  setWebsiteLink,
  isCurrentStepValid
}) => {
  const exchanges = useSelector(exchangesSelector);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const exchangesLogos = {
    binance,
    poloniex,
    hitbtc,
    binanceus,
    bitfinex
  };

  useEffect(() => {
    if (!error && !exchanges?.length) {
      setIsLoading(true);
      dispatch(getExchanges())
        .catch((e) => setError(e))
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, exchanges?.length, error]);

  const handleSetExchange = ({ code, website, name, ips}) => {
    setExchange({code, ips});
    setExchangeName(name);
    setWebsiteLink(website);
  };

  const handleStepBack = () => {
    handleScrollUp();
    setCurrentStep(currentStep - 1);
  }

  useEffect(() => {
    if (exchange?.code) setIsCurrentStepValid(true);
    return () => setIsCurrentStepValid(false);
  }, [exchange?.code, setIsCurrentStepValid]);

  return (
    <>
      <Body className="d-flex flex-column">
        <div className="d-flex flex-column">
          <Col xs={12} md={7} className="p-0">
            <h1 className="mb-2">Do you have an account on one of these exchanges?</h1>
            <p>
              Connect your trading accounts easily and effortlessly!
              Select your exchange to link to your Darkbot account
              for automated trading.
            </p>
          </Col>
          {
            isLoading ?
              <div className="d-flex justify-content-center my-5">
                <ClipLoader
                  size={35}
                  color="var(--info)"
                />
              </div>
              : null
          }
          {isLoading || exchanges?.length
            ?
            <ExchangesList className="mt-4">
              {exchanges?.map((item) => (
                <ExchangeItem
                  key={item.code}
                  onClick={() => handleSetExchange(item)}
                  className={exchange?.code === item.code ? "active" : ""}
                >
                  <img src={exchangesLogos[item.code]} alt={`${item.code} logo`} />
                </ExchangeItem>
              ))}
            </ExchangesList>
            : <p className="mt-4">
              System error detected. Please contact support via email:{" "}
              <a href="mailto:hello@darkbot.io">hello@darkbot.io</a>
            </p>
          }
        </div>
      </Body>
      <Footer className="w-100 d-flex flex-column-reverse flex-md-row justify-content-between">
        <StyledButton onClick={() => handleStepBack()}>
          <ArrowLeft className="mr-2 d-inline-block d-md-none" />
          Back
        </StyledButton>
        <div className="d-flex align-items-md-center flex-column-reverse flex-md-row">
          {
            exchangeName ?
              <a
                href={websiteLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-center text-info hover-underline mr-md-4 my-4 my-md-0"
              >
                <Export className="mr-2 mb-1" />
                Sign up on {exchangeName}
              </a> : null
          }
          <Button
            color="blue"
            disabled={!isCurrentStepValid}
            onClick={handleMoveToNextStep}
          >
            <span className="d-none d-md-inline">
              Connect
            </span>
            <span className="d-inline d-md-none">
              Connect my {exchangeName} account
            </span>
          </Button>
        </div>
      </Footer>
    </>
  );
};

const ExchangesList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ExchangeItem = styled.div`
  border-radius: 8px;
  border: 2px solid rgba(29, 30, 42, 0.10);
  background: #FFF;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
  }

  &:hover {
    border: 2px solid rgba(29, 30, 42, 0.10);
    background: rgba(29, 30, 42, 0.03);
  }

  &.active {
    border: 2px solid var(--info);
    background: rgba(47, 128, 237, 0.10);
  }
`;

const StyledButton = styled.button`
  border: 1px solid rgba(29, 30, 42, 0.10);
  background: transparent;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--dark);
  height: 40px;
  padding: 0 32px;
  border-radius: 6px;

  &:hover {
    background: rgba(29,30,42,0.10);
  }

  @media (max-width: 768px) {
    border: none;
    line-height: 1.4;
  }
`;

export default ExchangeStep;
