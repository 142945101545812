import React from "react";
import styled from "styled-components";
import classNames from "classnames";

import Icon from "components/Icon";
import Checkbox from "components/Checkbox";
import { 
  getColor, 
  getCursor,
  getIsChecked
} from "./utils";

const Option = ({
  isDark,
  label,
  secondLabel,
  icon,
  color,
  isMulti,
  selectedValues=[],
  value,
  isDisabled,
  menuIsOpen,
  disabledOptionIcon
}) => {
  const getRestSelected = () => {
    if (!isMulti) return null;
    if (selectedValues.length > 1 && !menuIsOpen) return (
      <Badge className="text-white text-nowrap">
        {`+ ${selectedValues.length - 1}`}
      </Badge>
    );
    return null;
  };

  return (
    <StyledOption className="input-select pr-1">
      <div className="input-select__single-value d-flex justify-content-between d-inline-block">
        <div className="d-flex align-items-center w-100 text-truncate">
          {
            !icon || (label === "All" || label === "") ?
            null
            :
            <span className="d-inline-block">
              <StyledIcon
                code={icon.code}
                size="smallSize"
                type={icon.type}
              />
            </span>
          }
          <Label
            className={classNames("option-value m-0 text-nowrap", {
              "ml-2": !!icon
            })}
            color={getColor(isDisabled, color, isDark)}
            cursor={getCursor(isDisabled)}
          >
            {label}
          </Label>
          {
            secondLabel ?
            <SubLabel className="ml-1 mb-0 text-white-35 text-truncate">
              {secondLabel}
            </SubLabel> : null
          }
          {getRestSelected()}
        </div>
        {
          isMulti ?
          <Checkbox
            checked={getIsChecked(selectedValues, value)}
            type="checkbox"
            className="position-relative my-auto pl-3"
            readOnly
          /> : null
        }
        {isDisabled && disabledOptionIcon}
      </div>
    </StyledOption>
  )
}

const Label = styled.span`
  cursor: ${(props) => props.cursor} !important;
  color: ${(props) => props.color};
`;

const StyledIcon = styled(Icon)`
  padding-left: 2px;
`;

const SubLabel = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledOption = styled.div`
  .input-select__single-value {
    margin: 0 !important;
    padding: 0 !important;
  }
`

const Badge = styled.span`
  background: var(--dusk-steel);
  padding: 2px 8px;
  border-radius: 32px;
  margin-left: 0.2rem;
`;

export default Option;
