import React from "react";
import { Card } from "reactstrap";
import { ReactComponent as TradeChartPreload } from "assets/img/TradeChartPreload.svg";
import SkeletonPreloader from "components/SkeletonPreloader";

const TradeStatisticsChartPreload = () => {
  return (
    <Card style={{padding: "24px"}}>
      <div className="d-flex align-items-center mb-4">
        <SkeletonPreloader
          circle={true}
          height={20}
          width={20}
          className="mr-2"
        />
        <SkeletonPreloader
          height={13}
          width={100}
        />
      </div>
      <TradeChartPreload width="100%"/>
    </Card>
  )
}

export default TradeStatisticsChartPreload;
