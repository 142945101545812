import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";

const useFilterAndSorting = (pathname, callBeforeFunc) => {
  const location = useLocation();
  const history = useHistory();
  const { filter, sort } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const handleFilter = (value) => {
    if (callBeforeFunc && typeof callBeforeFunc === "function") callBeforeFunc();
    history.push({
      pathname,
      search: qs.stringify({ filter: { ...filter, ...value }, sort: sort, page: 1 }).replace(/[^=&]+=(?:&|$)/g, "")
    });
  };

  const handleSorting = (value) => {
    if (callBeforeFunc && typeof callBeforeFunc === "function") callBeforeFunc();
    history.push({
      pathname,
      search: qs.stringify({filter: filter, sort: {...sort, ...value}}).replace(/[^=&]+=(?:&|$)/g, "")
    });
  };

  const resetFilter = () => {
    return history.push({ search: "" });
  };

  return { handleFilter, handleSorting, resetFilter, filter, sort };
};

export default useFilterAndSorting;
