import React, { useState } from "react";
import { Tooltip } from "reactstrap";

import {
  AccordionContentStyled,
  AccordionHeaderStyled,
  AccordionIndicatorIcon,
  AccordionWrapperStyled,
} from "./index.styles";

const Accordion = ({ title, className, disable, tooltipText, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  if (!title) return;

  const toggleAccordion = () => {
    if (disable) return;
    setIsOpen((prev) => !prev);
  };

  const toggleTooltip = () => setIsTooltipOpen((prev) => !prev);
  return (
    <AccordionWrapperStyled
      data-testid="accordion-wrapper"
      className={className}
    >
      <AccordionHeaderStyled
        id="accordion"
        data-testid="accordion-wrapper-header"
        disable={disable}
        className="d-flex justify-content-between align-items-center"
        onClick={toggleAccordion}
      >
        <p
          className="text-white font-sm mb-0"
          data-testid="accordion-wrapper-header-title"
        >
          {title}
        </p>
        <AccordionIndicatorIcon
          isOpen={isOpen}
          data-testid="accordion-wrapper-header-icon"
        />
      </AccordionHeaderStyled>

      {disable ? (
        <Tooltip
          target="accordion"
          placement="top"
          isOpen={isTooltipOpen}
          toggle={toggleTooltip}
        >
          {tooltipText}
        </Tooltip>
      ) : null}
      {isOpen ? (
        <AccordionContentStyled data-testid="accordion-wrapper-content">
          {children}
        </AccordionContentStyled>
      ) : null}
    </AccordionWrapperStyled>
  );
};

export default Accordion;
