import React from "react";
import styled from "styled-components";

const StepHeader = () => {
  return (
    <StyledHeader className="d-flex flex-column align-items-sm-center flex-sm-row">
      <div>
        <p className="mb-1 font-sm text-white-35">
          Step 3 of 3
        </p>
        <h1 className="h3 mb-0 font-weight-500 text-white">
          Select bot configuration
        </h1>
      </div>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  justify-content: space-between;
  background: #2E3148;
  padding: 24px 32px;
`;

export default StepHeader;
