import React from 'react';
const Emoji = ({ label, symbol, size=16, ...props }) => (
  <span
    className="emoji"
    role="img"
    aria-label={label || ""}
    aria-hidden={label ? "false" : "true"}
    style={{ fontSize: `${size}px`}}
    {...props}
  >
    {symbol}
  </span>
);
export default Emoji;