import React from "react";
import moment from "moment";
import { Badge } from "reactstrap";

import { REQUESTS_TRADES_TABLE_HEAD } from "models/deal/enum/tables";
import { formatterToNull } from "helpers/valuesFormatters";
import SortTableHead from "components/SortTableHead";
import StyledTable from "components/StyledTable";
import TablePreloader from "components/TablePreloader";
import {
  TableAmount,
  TableCurrency
} from "components/StyledTradeTableComponents";

const TradesTable = ({
  data,
  sort,
  handleSort,
  tradesRequestsLoading
}) => {

  if (tradesRequestsLoading) {
    return <TablePreloader
      rowTimes={5}
      tdHeight={14}
      tdWidth={[32, 85, 39, 53, 77, 77, 52, 105, 81, 73, 93]}
    />
  }

  return (
    <StyledTable
      className="mb-0"
      paddingsThead="0.5rem 1rem"
      backgroundThead="#2A2C42"
    >
      <SortTableHead
        handleSorting={handleSort}
        sort={sort}
        theadItems={REQUESTS_TRADES_TABLE_HEAD}
      />
      <tbody className="font-sm">
        {
          data?.items?.map((item) =>
            <tr
              key={item.tradeId}
              className="cursor-pointer"
            >
              <td>
                <Badge
                  style={{
                    background: item?.type === "buy" ? "#3FCBB0" : "#ED5F5F",
                    color: "#FFFFFF",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "16px",
                    display: "block",
                    margin: "0 2rem 0 0"
                  }}
                >
                  {item?.type?.slice(0, 1)}
                </Badge>
              </td>
              <td>
                <div className="d-flex align-items-center text-nowrap">
                  <TableAmount amount={1}>
                    {formatterToNull(item.market.base)}
                  </TableAmount>
                  <TableCurrency className="mx-1">/</TableCurrency>
                  <TableAmount amount={1}>
                    {formatterToNull(item.market.quote)}
                  </TableAmount>
                </div>
              </td>
              <td><TableAmount amount={item.tradeId}>
                {formatterToNull(item.tradeId)}
              </TableAmount>
              </td>
              <td>
                <TableAmount amount={item.orderNumber}>
                  {formatterToNull(item.orderNumber)}
                </TableAmount>
              </td>
              <td>
                <p className="mb-0 text-white">
                  {item.matchRole || "-"}
                </p>
              </td>
              <td>
                <p className="mb-0 text-white">
                  {item.tradeType || "-"}
                </p>
              </td>
              <td>
                <TableAmount amount={item.rate}>
                  {formatterToNull(item.rate)}
                </TableAmount>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <TableAmount amount={item.amount.amount}>
                    {formatterToNull(item.amount.amount)}
                  </TableAmount>
                  <TableCurrency>{item.amount.currency}</TableCurrency>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <TableAmount amount={item.quantity.amount}>
                    {formatterToNull(item.quantity.amount)}
                  </TableAmount>
                  <TableCurrency>
                    {item.quantity.currency}
                  </TableCurrency>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <TableAmount amount={item.fee.amount}>
                    {formatterToNull(item.fee.amount)}
                  </TableAmount>
                  <TableCurrency>
                    {item.fee.currency}
                  </TableCurrency>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <TableCurrency>
                    {moment(item.createdAt).format("DD.MM.YY")}
                  </TableCurrency>
                  <TableAmount amount={1} className="ml-1">
                    {moment(item.createdAt).format("HH:mm")}
                  </TableAmount>
                </div>
              </td>
            </tr>
          )
        }
      </tbody>
    </StyledTable>
  )
};

export default TradesTable;
