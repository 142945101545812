import React from "react";
import { Card } from "reactstrap";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import PropTypes from "prop-types";
import styled from "styled-components";

import TradingWithTickerPreloader from "views/signals/preloaders/TradingWithTickerPreloader";
import MarketChartTicker from "./MarketChartTicker";

const TradingWithTicker = ({
  exchange,
  ticker,
  loadingPage,
  isTradesLoading,
  quote,
  base
}) => {
  if (loadingPage || isTradesLoading || !ticker) return <TradingWithTickerPreloader/>;
  return (
    <Card>
      <MarketChartTicker
        exchange={exchange}
        ticker={ticker}
        quote={quote}
        base={base}
      />

      <TradingWithTicker.Trading className="mb-0 p-1">
        {
          exchange &&
          <TradingViewWidget
            autosize
            symbol={`${exchange.toUpperCase()}:${base}${quote}`}
            hide_side_toolbar={false}
            theme={Themes.DARK}
            locale="en"
            showSymbolLogo
            interval={30}
          />
        }
      </TradingWithTicker.Trading>
    </Card>
  );
};

TradingWithTicker.Trading = styled(Card)`
  height: 431px;
  margin-bottom: 0 !important;
  padding: 0 4px 4px 4px;
`;

TradingViewWidget.propTypes = {
  exchange: PropTypes.string,
  ticker: PropTypes.string,
  loadingPage: PropTypes.bool,
  isTradesLoading: PropTypes.bool,
  quote: PropTypes.string,
  base: PropTypes.string
};

export default TradingWithTicker;
