import React, {useLayoutEffect, useState} from "react";
import {Card} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {clearCredentialsData, fetchCredentials, fetchOpenOrders, fetchTradeHistory} from "store/credentials/actions";
import styled from "styled-components";
import qs from "qs";

import MarketHeader from "./MarketHeader";
import MarketFilters from "./MarketFilters";
import MarketTables from "./MarketTables";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";

const MarketDetails = ({base, quote, exchange}) => {
  const [tableView, setTableViw] = useState(null);
  const [currentCredId, setCurrentCredId] = useState(null);
  const [sideFilter, setSideFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [exchangeFilter, setExchangeFilter] = useState(null);

  const dispatch = useDispatch();

  const items = useSelector((state) => state.credentials.list.data.items);
  const loadingCredentials = useSelector((state) => state.credentials.list.loading);
  const orders = useSelector((state) => state.credentials.openOrders.data);
  const loadingOrders = useSelector((state) => state.credentials.openOrders.loading);
  const trades = useSelector((state) => state.credentials.tradeHistory.data);
  const loadingTrades = useSelector((state) => state.credentials.tradeHistory.loading);

  const requests = tableView === "orders" ? orders?.request : trades?.request;
  const callFunc = tableView === "orders" ? fetchOpenOrders : fetchTradeHistory;

  const { handleFilter, handleSorting, handlePaginate } = useFiltersWithRequest(requests, callFunc, currentCredId);

  useLayoutEffect(() => {
    const filters = qs.stringify({
      filter: {exchange: {eq: exchange}},
      limit: 100
    });
    dispatch(fetchCredentials(`?${filters}`));
    setSideFilter("");
    setStatusFilter("");
    setCurrentCredId(null);
    setTableViw(null);
    return () => dispatch(clearCredentialsData());
  }, [dispatch, base, quote, exchange]);

  useLayoutEffect(() => {
    if (!currentCredId) return;
    const filters = qs.stringify({
      filter: {market: {eq: `${base}-${quote}`}},
      sort: tableView === "orders" ? {by: "createdAt", order: "DESC"} : {by: "date", order: "DESC"},
      page: 1
    });
    if (tableView === "orders") dispatch(fetchOpenOrders(currentCredId, `?${filters}`));
    if (tableView === "trades") dispatch(fetchTradeHistory(currentCredId, `?${filters}`));
    setSideFilter("");
    setStatusFilter("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableView]);

  const handleRefresh = () => {
    const filters = qs.stringify({
      filter: {...requests.filter, market: {eq: `${base}-${quote}`}},
      page: 1
    });
    if (tableView === "orders") return dispatch(fetchOpenOrders(currentCredId, `?${filters}`));
    dispatch(fetchTradeHistory(currentCredId, `?${filters}`));
    setSideFilter("");
  };

  const handleCredential = (id) => {
    setCurrentCredId(id);
    setTableViw("trades");
  };

  return (
    <MarketDetails.Card>
      <MarketHeader
        handleRefresh={handleRefresh}
        currentCredId={currentCredId}
        loadingTrades={loadingTrades}
        loadingOrders={loadingOrders}
      />
      <MarketFilters
        tableView={tableView}
        setTableViw={setTableViw}
        handleFilter={handleFilter}
        credentials={items}
        currentCredId={currentCredId}
        handleCredential={handleCredential}
        loadingTrades={loadingTrades}
        loadingOrders={loadingOrders}
        sideFilter={sideFilter}
        setSideFilter={setSideFilter}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        loadingCredentials={loadingCredentials}
        exchangeFilter={exchangeFilter}
        setExchangeFilter={setExchangeFilter}
      />

      <MarketTables
        tableView={tableView}
        handleSort={handleSorting}
        handlePagination={handlePaginate}
        orders={orders}
        trades={trades}
        loadingTrades={loadingTrades}
        loadingOrders={loadingOrders}
        loadingCredentials={loadingCredentials}
        currentCredId={currentCredId}
      />
    </MarketDetails.Card>
  )
}

MarketDetails.Card = styled(Card)`
  border-radius: 12px !important;
`;

export default React.memo(MarketDetails);