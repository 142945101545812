import styled from "styled-components";

const StyledFilterButtons = styled.div`
  display: flex;
  height: 25px;

  .btn {
    border: 1px solid rgb(47, 128, 237);
    background: inherit;
    color: rgb(47, 128, 237);
    font-size: 12px;
    padding: 0.188rem 1.25rem;
    font-weight: 400;
    margin: 0 !important;
    border-radius: 0;
    &:first-child {
      border-radius: 6px 0 0 6px;
    }
    &:last-child {
      border-radius: 0 6px 6px 0;
    }
    &:hover {
      border: 1px solid rgb(47, 128, 237) !important;
      background: rgb(47, 128, 237) !important;
      color: #FFFFFF !important;
    }
    &:active, &:focus {
      border: 1px solid rgb(47, 128, 237) !important;
      background: rgb(47, 128, 237) !important;
      color: #FFFFFF !important;
    }
  }
`;

export default StyledFilterButtons;
