import React from "react";
import styled from "styled-components";
import {
  Col
} from "reactstrap";
import { Link } from "react-router-dom";

import NotFoundImage from "assets/img/NotFoundImage.png";

const NotFound = () => (
  <div className="wrapper wrapper-full-page d-flex flex-column justify-content-center">
    <div className="content">
      <NotFound.Wrapper>
        <Col xs="4">
          <NotFound.Image
            src={NotFoundImage}
            alt="..."
          />
        </Col>
      </NotFound.Wrapper>
      <div className="w-100 text-center">
        <Link
          to="/"
          className="nav-link text-primary"
        >
          Back to
          Dashboard <i className="tim-icons icon-minimal-right" />
        </Link>
      </div>
    </div>
  </div>
);

NotFound.Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

NotFound.Image = styled.img`
  max-width: 100%;
  width :100%;
  height: auto;
`;

export default NotFound;
