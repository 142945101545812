import React from "react";
import { useHistory } from "react-router-dom";

import cardPayment from "assets/img/card-payment-illustration.svg";
import Button from "components/Button";

const MaximizeProfits = ({ setSkipped }) => {
  const history = useHistory();

  const handleSkip = (e) => {
    e.preventDefault();
    setSkipped(true)
  };

  return (
    <div className="d-flex flex-column flex-md-row justify-content-between">
      <div className="d-flex flex-column justify-content-center">
        <p className="h1 mb-2 text-white text-center text-md-left">
          {"Unlock our platform's full potential with a 7-day trial"}
        </p>
        <p className="font-sm text-white-75 mb-4 text-center text-md-left">
          Get hands-on experience with our powerful tools and features to<br className="d-none d-lg-inline" />
          enhance your trading journey. Take the first step towards financial<br className="d-none d-lg-inline" />
          {"success. Don't miss out - start your trial today!"}
        </p>
        <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-start mb-4 mb-md-0">
          <div className="d-flex flex-column mb-3 mb-md-0">
            <Button
              color="blue"
              onClick={() => history.push("/billing")}
            >
              Try Premium for Free
            </Button>
            <p className="ml-md-4 text-white-35 font-sm mt-2 text-center text-md-left">
              No credit card required
            </p>
          </div>
          <a
            className="mt-lg-2 ml-md-4 mt-md-2 text-info font-sm text-center"
            href="/#"
            onClick={handleSkip}
          >
            {"I'll do this later"}
          </a>
        </div>
      </div>
      <img
        src={cardPayment}
        alt="Payment"
      />
    </div>
  );
};

export default MaximizeProfits;
