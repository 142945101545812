import React, {useEffect} from "react";
import styled from "styled-components";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import { Col, Row, Card, CardBody } from "reactstrap";

import ExchangeMarketHeader from "./compounds/ExchangeMarketHeader";
import LastTrades from "components/LastTrades";
import OrderBook from "components/OrderBook";
import Error from "components/Error";
import MarketDetails from "./compounds/marketDetails/MarketDetails";
import Breadcrumbs from "components/Breadcrumbs";
import {setExchangePageBreadcrumbs} from "models/enum/breadcrumbs";
import useExchangeWS from "exchanges/useExchangeWS";
import MarketInformation from "../../components/MarketInformation";
import MyCredentialsBlock from "./MyCredentials/MyCredentialsBlock";

const ExchangeMarketPage = ({
  match: {
    params: {
      exchange,
      base,
      quote
    }
  }
}) => {
  const {
    orderBook,
    isWebsocketOpen,
    setGroup,
    group,
    depth,
    handleSetDepth,
    trades,
    ticker,
    error
  } = useExchangeWS(exchange, ["orderBook", "ticker", "trades"], [`${base}_${quote}`], true);

  useEffect(() => {
    window.analytics.page("/exchanges/:exchange/markets/:quote/:base");
  }, []);

  if (error) return <Error error={`Error: ${error}`} />;

  return(
    <>
      <Row>
        <Col md="12" className="d-none d-md-flex">
          <Breadcrumbs
            items={setExchangePageBreadcrumbs(exchange, quote, base)}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <ExchangeMarketHeader
            ticker={ticker[0]}
            isWebsocketOpen={isWebsocketOpen}
            base={base}
            quote={quote}
            exchange={exchange}
          />
        </Col>
      </Row>
      <Row>
        <Col md="8">
          <ExchangeMarketPage.ChartWrapper>
            <CardBody>
              <TradingViewWidget
                autosize
                symbol={`${exchange.toUpperCase()}:${base}${quote}`}
                hide_side_toolbar={false}
                theme={Themes.DARK}
                locale="en"
                showSymbolLogo
              />
            </CardBody>
          </ExchangeMarketPage.ChartWrapper>
        </Col>
        <Col md="4" className="mt-4 mt-md-0">
          <LastTrades
            lastTrade={trades.data}
            error={trades.error?.message}
            isTradesLoading={trades.isLoading}
            withCurrenciesInHeader
            market={{ base, quote }}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="12" >
          <MarketInformation
            exchange={exchange}
            base={base}
            quote={quote}
            loadingPage={false}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <OrderBook
            orderBookAsks={orderBook.asks}
            orderBookBids={orderBook.bids}
            spread={orderBook.spread}
            spreadPercent={orderBook.spreadPercent}
            isWebsocketOpen={isWebsocketOpen}
            handleSetDepth={handleSetDepth}
            depth={depth}
            base={base}
            quote={quote}
            setGroup={setGroup}
            group={group}
            withSpread
            withSum
          />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <MyCredentialsBlock
            exchangeMarketLoading={trades.isLoading}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <MarketDetails
            base={base}
            quote={quote}
            exchange={exchange}
          />
        </Col>
      </Row>
    </>
  );
};

ExchangeMarketPage.ChartWrapper = styled(Card)`
  height: 100%;
  min-height: 571px;
`;

export default ExchangeMarketPage;
