import React from "react";
import styled from "styled-components";
import {Col, FormGroup, Row} from "reactstrap";
import SelectInput from "components/SelectInput";
import {
  INDIVIDUALS_STATUS_OPTIONS,
  INDIVIDUALS_GENESIS_OPTIONS,
} from "models/filters";

const IndividualsFilters = ({
  onHandleFilter,
  filter,
  ...props
}) => {

  return (
    <IndividualsFilters.Wrapper {...props}>
      <Row>
        <Col
          xs="6"
          md="3"
          className="pr-2"
        >
          <FormGroup>
            <SelectInput
              isClearable
              className="mb-0 w-100"
              placeholder="Status"
              name="status"
              options={INDIVIDUALS_STATUS_OPTIONS}
              onChange={(option) => onHandleFilter({ status: { eq: option?.value } })}
            />
          </FormGroup>
        </Col>
        <Col
          xs="6"
          md="3"
          className="pl-0"
        >
          <FormGroup>
            <SelectInput
              isClearable
              className="mb-0 w-100"
              placeholder="Genesis"
              name="genesis"
              options={INDIVIDUALS_GENESIS_OPTIONS}
              onChange={(option) => onHandleFilter({ genesis: { eq: option?.value } })}
            />
          </FormGroup>
        </Col>
      </Row>
    </IndividualsFilters.Wrapper>
  );
};

IndividualsFilters.Wrapper = styled.div`
`

export default IndividualsFilters;
