import React from "react";
import styled from "styled-components";
import { Card, CardBody } from "reactstrap";
import SkeletonPreloader from "components/SkeletonPreloader";

const GeneticHeaderPreloader = () => {
  return (
    <GeneticHeaderPreloader.Card className="h-auto">
      <CardBody className="p-4">
        <div className="d-flex align-items-md-center justify-content-between">
          <div className="d-flex flex-column flex-md-row align-items-center">
            <SkeletonPreloader
              circle={true}
              height={37}
              width={37}
            />
            <div className="d-flex justify-content-md-center flex-column ml-3 mt-3 mt-md-0">
              <SkeletonPreloader
                height={20}
                width={142}
              />
              <SkeletonPreloader
                height={12}
                width={255}
                className="mt-2"
              />
            </div>
          </div>
          <div className="d-none d-md-flex align-items-end flex-column">
            <SkeletonPreloader
              height={23}
              width={48}
              borderRadius={"6px"}
            />
            <SkeletonPreloader
              height={10}
              width={104}
              className="mt-3"
            />
          </div>
        </div>
      </CardBody>
    </GeneticHeaderPreloader.Card>
  )
}

GeneticHeaderPreloader.Card = styled(Card)`
 height: 105px`

export default GeneticHeaderPreloader;
