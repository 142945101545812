import { combineReducers } from "redux";

import withDataLoading from "store/withDataLoading";
import { 
  ACCEPT_SIGNAL,
  ACCEPT_SIGNAL_ACTIVE,
  ACCEPT_SIGNAL_ITEM,
  CREATE_ACTIVE_SIGNAL, 
  CREATE_SIGNAL, 
  SUBSCRIBE_CHANNEL_SIGNAL
} from "./constants";

const initialState = {
  loading: false,
  data: {},
  error: null,
};

const createSignal = (state, payload) => ({
  ...state,
  data: {
    ...state.data,
    items: [payload, ...(state.data?.items || [])],
    total: state.data.total + 1
  },
});

const item = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_SIGNAL:
      return {
        ...state,
        data: {
          ...state.data,
          channel:{
            ...state.data.channel,
            ...payload
          }
        }
      };

    case SUBSCRIBE_CHANNEL_SIGNAL:
      return {
        ...state,
        data: {
          ...state.data,
          channel:{
            ...state.data.channel,
            subscribed: true,
            totalSubscribers: state.data.totalSubscribers + 1
          }
        }
      };
      
      case ACCEPT_SIGNAL_ITEM:
        return {
          ...state,
          data: {
            ...state.data,
            agreements: payload.agreements
          }
        };
    
    default:
      return state;
  }
};

const list = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_SIGNAL:
      return createSignal(state, payload);

    case ACCEPT_SIGNAL:
      return {
        ...state,
        data:{
          ...state.data,
          items: state.data.items.map(signal => 
              signal.id === payload.signalId
              ? {
                ...signal,
                agreements: payload.agreements
              }
              : signal
            )
        }
      };
    
    default:
      return state;
  }
};

const activeList = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_ACTIVE_SIGNAL:
      return createSignal(state, payload)
      
    case ACCEPT_SIGNAL_ACTIVE:
    return {
     ...state,
        data:{
          ...state.data,
          items: state.data.items.map(signal => 
              signal.id === payload.signalId
              ? {
                ...signal,
                agreements: payload.agreements
              }
              : signal
            )
        }
    };
    default:
      return state;
  }
};

export default combineReducers({
  list: withDataLoading(list, "SIGNALS"),
  item: withDataLoading(item, "SIGNAL"),
  completedList: withDataLoading(null, "COMPLETED_SIGNALS"),
  activeList: withDataLoading(activeList, "ACTIVE_SIGNALS"),
  signalsMarets: withDataLoading(null, "SIGNALS_MARKETS"),
})