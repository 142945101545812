import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Button from "components/Button";
import Label from "components/Label";
import { ReactComponent as TickInCircle } from "assets/img/icons/tickcircle.svg"

const ChannelFooter = ({
  subscribed,
  handleToggleModal
}) => {
  return (
    <div className="position-absolute bottom-0 start-0 mb-4">
      {
        subscribed
          ?
          <Label
            size="big"
            labelText="label"
          >
            <TickInCircle /> Subscribed
          </Label>
          :
          <ChannelFooter.Button
            color="blue"
            onClick={(e) => {
              e.preventDefault()
              handleToggleModal()
            }}
          >
            Subscribe
          </ChannelFooter.Button>
      }
    </div>
  );
};

ChannelFooter.Button = styled(Button)`
  width: 111px;
`;

ChannelFooter.propTypes = {
  subscribed: PropTypes.bool,
  onSubscribeChannel: PropTypes.func,
  handleToggleModal: PropTypes.func
};

export default ChannelFooter;
