import React from "react";
import styled from "styled-components";
import Pagination from "react-js-pagination";
import classNames from "classnames";

const PaginationPanel = ({
  activePage,
  totalItemsCount,
  totalPagesView = 10,
  onChange,
  pages,
  isCircle,
  elementRef
}) => {

  const handleChange = (page) => {
    onChange(page);
    if (elementRef?.current) elementRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  if (totalItemsCount > totalPagesView) {
    return (
      <PaginationPanel.PaginationWrapper>
        <Pagination
          activePage={activePage}
          totalItemsCount={totalItemsCount}
          pageRangeDisplayed={pages}
          onChange={handleChange}
          itemClass={classNames("page-item font-sm", {
            "circle-page-item d-block my-auto circle-minimal-icons": isCircle
          })}
          linkClass="page-link"
          firstPageText={<i aria-hidden={true} className="tim-icons icon-minimal-left" />}
          lastPageText={<i aria-hidden={true} className="tim-icons icon-minimal-right"/>}
          hideNavigation={true}
          itemsCountPerPage={totalPagesView}
        />
      </PaginationPanel.PaginationWrapper>
    );
  }
  return null;
};

PaginationPanel.PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;

  .circle-page-item:first-child {
    margin-right: 1rem;
  }

  .circle-page-item:last-child {
    margin-left: 0;
  }

  .circle-page-item:not(:first-child):not(:last-child) {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    margin-right: 1rem;
    cursor: pointer;
    &.active {
      background: var(--info);
    }
    & .page-link {
      display: none;
    }
  }

  .page-link:hover {
    background-color: #3C3F5D !important;
  }

  .icon-minimal-left {
    padding: 5px 7px 5px 6px;
  }

  .icon-minimal-right {
    padding: 5px 6px 5px 7px;
  }

  .disabled {
    .page-link {
      background-color: transparent !important;
    }
    .icon-minimal-left, .icon-minimal-right {
      background-color: transparent !important;
      border: 1px solid #2E3148;
      border-radius: 2px;
    }
  }

  .circle-minimal-icons {
    .icon-minimal-left, .icon-minimal-right {
      background-color: #2E3148;
      border-radius: 2px;
      &:hover {
        background: rgba(50, 52, 78, 1);
      }
    }
    .page-link:hover, .page-link:active, .page-link:focus {
      background-color: transparent !important;
    }
  }
`;

export default PaginationPanel;
