import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import Button from "components/Button";
import {
  completeOpenDeal,
  forceRunDeal
} from "store/deals/actions";
import { addSnackBar } from "store/snackbar/actions";
import { replaceDurations } from "helpers/replaceDurations";
import {
  formatterToNull,
  formatValue,
  setColorClass
} from "helpers/valuesFormatters";
import PercentBadge from "components/PercentBadge";

const RunningDealsTableRow = ({
  item,
  total
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleForceComplete = async (event, idDeal) => {
    event.stopPropagation();
    setIsSubmitted(true);
    if (!item.actions.complete) return;
    const res = await dispatch(completeOpenDeal(idDeal, "running"));
    setIsSubmitted(false);
    return setResponseMessages(res);
  };

  const handleForceRun = async (event, idDeal) => {
    event.stopPropagation();
    setIsSubmitted(true);
    if (!item.actions.run) return;
    const res = await dispatch(forceRunDeal(idDeal, "running"));
    setIsSubmitted(false);
    return setResponseMessages(res);
  };

  const setResponseMessages = (res) => {
    if(res?.item?.error && !res?.errors) {
      dispatch(addSnackBar("error", res.item.error))
    }else if (!res?.errors) {
      dispatch(addSnackBar("success", res.message));
    }
    if (res?.errors) {
      dispatch(addSnackBar("error", res.message));
    };
  };

  const getRunningDealCellValue = () => {
    if (!item?.actions.complete && item?.status === "running") return <p className="text-white-35">Completing</p>;
    if (!item?.actions.run && item?.status === "ready") return <p className="text-white-35">Running</p>
    if (item?.status === "running" && item?.actions.complete) {
      return (
        <Button
          outline
          size="middle"
          color="red"
          className="text-white"
          loading={isSubmitted}
          onClick={(e) => handleForceComplete(e, item?.id)}
        >
          Complete
        </Button>
      )
    } else if (item?.status === "ready" && item?.actions.run) {
      return (
        <Button
          outline
          size="middle"
          onClick={(e) => handleForceRun(e, item?.id)}
          color="success"
          loading={isSubmitted}
          className="text-white"
        >
          Run
        </Button>
      )
    }
  };

  return (
    <RunningDealsTableRow.Row
      onClick={() => history.push(`/deals/${item.id}`)}
      total={total}
    >
      <RunningDealsTableRow.StyledCell status={item.status}>
        <div className="d-flex align-items-center text-nowrap">
          <p className="mb-0 ml-3 text-white font-weight-500">
            {item.assets.base}
          </p>
          <p className="mb-0 ml-1 text-white-35 font-weight-300">
            {`/ ${item.assets.quote}`}
          </p>
        </div>
      </RunningDealsTableRow.StyledCell>

      <td>
        <div className="d-flex align-items-center text-nowrap">
          <p className="mb-0 text-white font-weight-500">
            {formatterToNull(item.invested.amount)}
          </p>
          <p className="mb-0 ml-1 mr-2 text-white-35 font-weight-300">
            {item.invested.asset}
          </p>
          <PercentBadge
            value={item.invested.percent}
            showZeroValue
            isColorSpectrum
          />
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center text-nowrap">
          <p className={`mb-0 font-weight-500 ${setColorClass(item.currentState.amount, "text-white-35")}`}>
            {formatValue(item.currentState.amount)}
          </p>
          <p className="mb-0 ml-1 mr-2 text-white-35 font-weight-300">
            {item.currentState.currency || item.market.quote}
          </p>
          <PercentBadge value={item.currentState.percent} showZeroValue />
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center justify-content-between text-nowrap">
          <p className="mb-0 mr-3 text-white font-weight-500">
            {replaceDurations(item.duration)}
          </p>
        </div>
      </td>

      <td>
        {getRunningDealCellValue()}
      </td>
    </RunningDealsTableRow.Row>
  )
}

RunningDealsTableRow.Row = styled.tr`
  cursor: pointer;

  &:last-child {
    border-bottom: ${({ total }) => total > 10 ? "1px solid #2E3148" : "none"};
  }
`;

RunningDealsTableRow.StyledCell = styled.td`
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    background: ${({ status }) => status === "running" ? "#DB53C7" : "var(--info)"};
  }
`;

export default RunningDealsTableRow;
