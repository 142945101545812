import React, { useState } from "react";
import { Progress, Tooltip } from "reactstrap";

const BotProgressBar = ({profitPercent, profitTitle, profitAmount, lossPercent, lossTitle, lossAmount, index}) => {
  const [tooltipProfitOpen, setTooltipProfitOpen] = useState(false);
  const [tooltipLossOpen, setTooltipLossOpen] = useState(false);
  const toggleProfit = () => setTooltipProfitOpen(!tooltipProfitOpen);
  const toggleLoss = () => setTooltipLossOpen(!tooltipLossOpen);

  const lossPercentage = lossPercent?.replace("-","") || 0;
  const profitPercentage = profitPercent?.replace("-","") || 0;

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mt-3">
        <p className={`mb-0 font-weight-500 ${!profitPercent ? "text-white-35" : "text-success"}`}>
          {+profitAmount || "0.00000000"}
        </p>
        <p className={`mb-0 font-weight-500 ${!lossPercent ? "text-white-35" : 'text-danger'}`}>
          {
            +lossAmount
              ? lossAmount?.toString().replace("-", "")
              : "0.00000000"
          }
        </p>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-1">
        {
          (profitPercent === null && lossPercent === null) ||
          (+profitPercent + +lossPercent === 0)
            ?
            <Progress
              multi
              style={{
                width: "100%",
                height: "12px",
                background: "none",
                boxShadow: "none",
                justifyContent: "space-between"
              }}
            >
              <Progress
                bar
                color="white"
                value={50}
                max={100}
                style={{marginRight: "4px", borderRadius: "4px", width: "100%", cursor: "pointer", opacity: ".1"}}
              />
              <Progress
                bar
                color="white"
                value={50}
                max={100}
                style={{marginRight: "4px", borderRadius: "4px", width: "100%", cursor: "pointer", opacity: ".1"}}
              />
            </Progress>
            :
            <Progress
              multi
              style={{
                width: "100%",
                height: "12px",
                background: "none",
                boxShadow: "none",
                justifyContent: "space-between"
              }}
            >
              <Progress
                bar
                color="success"
                value={+profitPercentage}
                max={+profitPercentage + +lossPercentage}
                style={{marginRight: "4px", borderRadius: "4px", width: "100%", cursor: "pointer"}}
                id={`totalLoss${index}`}
              />
              <Tooltip placement="top" isOpen={tooltipLossOpen} target={`totalLoss${index}`}
                       toggle={toggleLoss}>
                {`${profitPercent} %`}
              </Tooltip>
              <Progress
                bar
                color="danger"
                value={+lossPercentage}
                max={+profitPercentage + +lossPercentage}
                style={{borderRadius: "4px", width: "100%", cursor: "pointer"}}
                id={`totalProfit${index}`}
              />
              <Tooltip placement="top" isOpen={tooltipProfitOpen} target={`totalProfit${index}`} toggle={toggleProfit}>
                {`${lossPercent} %`}
              </Tooltip>
            </Progress>
        }
      </div>
      <div className="d-flex justify-content-between align-items-center mt-1">
        <p className="mb-0 text-white-35 font-weight-300 font-sm">
          {profitTitle}
        </p>
        <p className="mb-0 text-white-35 font-weight-300 font-sm">
          {lossTitle}
        </p>
      </div>
    </>
  )
}

export default BotProgressBar;
