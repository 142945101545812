import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import qs from "qs";
import queryString from "query-string";

import charts from "assets/img/icons/charts.svg";
import BalanceStatisticsChart from "./charts/BalanceStatisticsChart";
import Allocation from "./compounds/Allocation";
import Balances from "./compounds/Balances";
import Settings from "./compounds/Settings";
import Header from "./compounds/Header";
import HeaderPreLoader from "components/HeaderPreLoader";
import RefreshButton from "components/RefreshButton";
import Credential from "components/Credential";
import BotsList from "components/MarketBots/BotsList";
import Error from "components/Error";
import BalancesPreloader from "./preloaders/BalancesPreloader";
import SettingsPreloader from "./preloaders/SettingsPreloader";
import CredentialWidgetPreloader from "./preloaders/CredentialWidgetPreloader";
import LoaderWidget from "./preloaders/LoaderWidget";
import { getBots } from "store/bots/actions";
import { getInvestment, resetInvestment } from "store/investment/actions";
import { setCredentialsBreadcrumbs } from "models/enum/breadcrumbs";
import Breadcrumbs from "components/Breadcrumbs";
import useRequestCancellation from "hooks/useRequestCancellation";
import { getCoinName } from "exchanges/utils";

const InvestmentPage = ({ match, location }) => {
  const dispatch = useDispatch();

  const investment = useSelector((state) => state.investment.item.data);
  const loading = useSelector((state) => state.investment.item.loading);
  const error = useSelector((state) => state.investment.item.error);
  const subscription = useSelector((state) => state.me.data.subscription);
  const { useAbortableEffect } = useRequestCancellation();
  const coinSymbol = investment.asset;
  const coinName = getCoinName(coinSymbol);

  useAbortableEffect(
    (signal) => {
      dispatch(getInvestment(match.params.id, signal));
      return () => dispatch(resetInvestment());
    },
    [match.params.id, dispatch],
    true
  );

  useEffect(() => {
    window.analytics.page("/investments/:id");
  }, []);

  const onHandleRefresh = () => {
    const initialFilter = qs.stringify({
      filter: { investment: { eq: match.params.id } },
      sort: { by: "createdAt", order: "ASC" },
      ...queryString.parse(location.search),
    });
    dispatch(getBots(`?${initialFilter}`));
    return dispatch(getInvestment(match.params.id));
  };

  if (error) return <Error error={error} />;

  return (
    <>
      <div className="d-none d-md-flex align-items-center justify-content-between">
        <Breadcrumbs
          items={setCredentialsBreadcrumbs(
            investment?.credentials?.exchange,
            investment?.credentials?.id,
            investment?.asset
          )}
          loading={loading}
        />

        <RefreshButton loading={loading} onHandleRefresh={onHandleRefresh} />
      </div>
      <LoaderWidget loading={loading} loadingComponent={<HeaderPreLoader />}>
        <Header
          balance={investment.balance?.amount}
          coinName={coinName}
          coinSymbol={coinSymbol}
          asset={investment.asset}
          id={investment.id}
          subscription={subscription}
          connected={investment?.credentials?.connected}
          error={investment.error}
        />
      </LoaderWidget>
      <Row>
        <Col xs="12" md="5" lg="4">
          <LoaderWidget
            loading={loading}
            loadingComponent={<BalancesPreloader />}
          >
            {investment?.balance && (
              <Balances
                balance={investment.balance}
                dailyRevenuePercent={investment.dailyRevenuePercent}
              />
            )}
          </LoaderWidget>
          <Allocation
            loading={loading}
            plannedPercent={investment.plannedPercent}
            realPercent={investment.realPercent}
            subscription={subscription}
            rebalancingDifference={
              investment?.credentials?.rebalancingDifference
            }
            rebalance={investment?.credentials?.rebalance}
            credentialId={investment?.credentials?.id}
            onHandleRefresh={onHandleRefresh}
          />
          <LoaderWidget
            loading={loading}
            loadingComponent={<SettingsPreloader />}
          >
            <Settings
              id={match.params.id}
              drawdown={investment.maximumDrawdown}
              maxDeals={investment.maxDeals}
              version={investment.version}
              runningDeals={investment.statistics?.deals?.running}
            />
          </LoaderWidget>
          <LoaderWidget
            loading={loading}
            loadingComponent={<CredentialWidgetPreloader />}
          >
            {investment?.credentials && (
              <Credential credential={investment?.credentials} />
            )}
          </LoaderWidget>
        </Col>
        <Col xs="12" md="7" lg="8">
          <BalanceStatisticsChart
            id={match.params.id}
            charts={charts}
            pageLoading={loading}
          />
          <BotsList
            id={match.params.id}
            location={location}
            freeMarkets={investment.freeMarkets}
            loadingPage={loading}
            investment={investment}
            balance={investment.balance?.amount}
            coinName={coinName}
            coinSymbol={coinSymbol}
          />
        </Col>
      </Row>
    </>
  );
};

export default InvestmentPage;
