import { createSelector } from "reselect";

const configurations = (state) => state.indicatorsConfiguration;

export const currentConfigurationSelector = (strategy) => createSelector(
  [configurations],
  (configurations) => {
    if (strategy && configurations.length) return configurations.find((c) => c.code === strategy)
    return {
      code: "",
      type: "",
      settings: {},
      isDisabled: ""
    };
  }
);
