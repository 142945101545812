import React, { useState } from "react";
import moment from "moment";
import { capitalize } from "lodash";
import {
  Badge,
  CardHeader,
  Tooltip
} from "reactstrap";

import { handleStatusData } from "models/constans/ui/tradeRequests/content";
import { TradeRequestIcon } from "components/StyledTradeRequestComponents";
import { formatterToNull } from "helpers/valuesFormatters";

import {
  DealAmountStyled,
  DealCurrencyStyled,
  DealTitleStyled
} from "../../Deal/index.styles";

const DealOpenTradeHeader = ({ data }) => {
  const [tooltip, setTooltip] = useState(false);

  const toggle = () => setTooltip(!tooltip);
  const statusData = handleStatusData(data);

  const renderStatus = (status) => {
    const IconStatus = statusData[status]?.Icon
    return statusData[status] && (
      <>
        <p className="mb-0 text-white font-weight-500 mr-2 font-sm">
          {moment(data?.createdAt).format("DD.MM.YY HH:mm")}
        </p>
        <TradeRequestIcon>
          <IconStatus
            data-testid="deal-open-trade-icon-status"
            width={20}
            height={20}
            id={`status-${data?.id}`}
          />
        </TradeRequestIcon>
        <Tooltip
          placement="top"
          isOpen={tooltip}
          target={`status-${data?.id}`}
          toggle={toggle}
        >
          {capitalize(status)}
        </Tooltip>
      </>
    )
  };
  return (
    <CardHeader className="d-flex flex-wrap flex-md-nowrap px-0">

      <div className="d-flex align-items-center flex-wrap flex-md-nowrap mb-3 mb-md-0">
        <Badge
          data-testid="deal-open-trade-badge"
          style={{
            background: data?.type === "buy" ? "#3FCBB0" : "#ED5F5F",
            color: "#FFFFFF",
            fontWeight: "500",
            fontSize: "14px",
            margin: "0 24px 0 0",
            lineHeight: "16px",
            display: "block"
          }}
        >
          {data?.type?.slice(0, 1)}
        </Badge>
          <div className="d-flex flex-column px-3 mr-4 px-md-0">
            <DealTitleStyled data-testid="deal-open-trade-initial-price">
              Initial price
            </DealTitleStyled>
            <DealAmountStyled
              amount={data?.price?.amount}
              data-testid="deal-open-trade-initial-amount"
            >
              {formatterToNull(data?.price?.amount)}
            </DealAmountStyled>
          </div>
          <div className="d-flex flex-column px-3 mr-0 mr-md-4 px-md-0">
            <DealTitleStyled data-testid="deal-open-trade-boundary-price">
              Boundary price
            </DealTitleStyled>
            <DealAmountStyled
              data-testid="deal-open-trade-boundary-value"
              amount={data?.boundaryRate}
            >
              {formatterToNull(data?.boundaryRate)}
            </DealAmountStyled>
          </div>
          <div className="d-flex flex-column mr-4 px-3 px-md-0 mt-1 mt-md-0">
            <DealTitleStyled data-testid="deal-open-trade-planned">
              Planned amount
            </DealTitleStyled>
            <div className="d-flex align-items-center">
              <DealAmountStyled
                data-testid="deal-open-trade-planned-amount"
                className=" mr-1"
                amount={data?.plannedAmount?.amount}
              >
                {formatterToNull(data?.plannedAmount?.amount)}
              </DealAmountStyled>
              <DealCurrencyStyled data-testid="deal-open-trade-planned-asset">
                {data?.plannedAmount?.asset}
              </DealCurrencyStyled>
            </div>
          </div>
          <div className="d-flex flex-column px-3 px-md-0 mt-1 mt-md-0">
            <DealTitleStyled data-testid="deal-open-trade-planned-quatity">
              Planned quantity
            </DealTitleStyled>
            <div className="d-flex align-items-center">
              <DealAmountStyled
                data-testid="deal-open-trade-planned-quatity-amount"
                className=" mr-1"
                amount={data?.plannedQuantity?.amount}
              >
                {formatterToNull(data?.plannedQuantity?.amount)}
              </DealAmountStyled>
              <DealCurrencyStyled data-testid="deal-open-trade-planned-quatity-asset">
                {data?.plannedQuantity?.asset}
              </DealCurrencyStyled>
            </div>
          </div>
      </div>
      <div className="d-flex align-items-center px-3 px-md-0 ml-0 ml-md-auto mr-auto mr-md-0">
        {renderStatus(data?.status)}
      </div>
    </CardHeader>
  );
};

export default DealOpenTradeHeader;