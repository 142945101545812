import React from "react";
import styled from "styled-components";

const Callout = ({ children, type, className, ...props }) => {
  return(
    <Callout.CalloutWrapper
      className={`${className ? className + " " : ""}${type}`}
      {...props}
    >
      {children}
    </Callout.CalloutWrapper>
  );
};

Callout.CalloutWrapper = styled.div`
  padding: 10px 20px;
  border: 1px solid #eee;
  border-left-width: 8px;
  border-radius: 8px;

  &.error {
    border-left-color: #fd5d93;
    background: #fff;
  }

  &.warning {
    border-left-color: #ff8d72;
    background: rgba(240, 173, 78, 0.06);
  }

  &.success {
    border-left-color: #ff8d72;
    background: rgba(240, 173, 78, 0.06);
  }

  &.info {
    border-left-color: #1d8cf8;
    background: rgba(47, 128, 237, 0.05);
  }

  button {
    visibility: hidden;
  }

  &:hover {
    button {
      visibility: visible;
    }
  }
`;

export default Callout;
