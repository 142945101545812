import React, { 
  useLayoutEffect,
  useState 
} from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";

import SupportContactNotification from "components/SupportContactNotification";
import Button from "components/Button";
import MarketsTable from "./MarketsTable";
import Filters from "./Filters";
import MarketMessageModal from "./MarketMessageModal";
import MarketsHeader from "./MarketsHeader";
import { setResetBotWizard } from "store/bots/wizard/actions";
import { getAvailableMarkets } from "store/markets/actions";

const Markets = ({
  goBack,
  goNextStep,
  currentStep,
  credentialId
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();

  const {
    data,
    loading,
    error
  } = useSelector((state) => state.availableMarkets);
  const {
    excludedCoins,
    coins,
    allCoins,
    investment,
    volume,
    minimumTrades,
    spreadPercent,
    minimumDailyChange,
    maximumDailyChange,
    period
  } = useSelector((state) => state.botWizard);

  const validationMinMax = (minimumDailyChange !== null && maximumDailyChange !== null) &&
    (minimumDailyChange >= maximumDailyChange || (minimumDailyChange === 0 && maximumDailyChange === 0));

  const handleGoBack = () => {
    dispatch(setResetBotWizard());
    goBack();
  };

  useLayoutEffect(() => {
    if(!currentStep) setSearchValue("");
    if (currentStep === 1) dispatch(getAvailableMarkets(investment.id, "?limit=10"));
  },[dispatch, investment.id, currentStep]);

  const handleNextStep = () => goNextStep();

  return (
    <>
      <MarketsHeader
        currentStep={currentStep}
        exchange={investment?.credentials?.exchange}
        selectedInvestment={investment}
        period={period}
      />
      {
        error 
        ?<div className="mx-4 my-3">
            <SupportContactNotification
              infoMessage="System error detected. Please contact support via email:"
              linkText="hello@darkbot.io"
              redirectTo="mailto:hello@darkbot.io"
            />
          </div>
          :<>
            <MarketsTable
              data={data}
              loading={loading}
              credentialId={credentialId}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setIsOpenModal={setIsOpenModal}
            />

            <Filters
              selectedInvestment={investment}
              currentStep={currentStep}
            />
          </>
      }

      <div className="d-flex flex-column flex-sm-row justify-content-between px-4 pb-4">
        <Button
          color="gray"
          outline
          onClick={handleGoBack}
          className="pull-right"
        >
          Back - Credentials
        </Button>
        <Button
          disabled={
            (!allCoins && !coins.length) ||
            (allCoins && excludedCoins?.length === data?.total) ||
            minimumTrades === 0 || volume === 0 || spreadPercent === 0 ||
            validationMinMax
          }
          color="blue"
          type="submit"
          className="pull-right mt-2 mt-sm-0"
          onClick={handleNextStep}
        >
          Next - Trade settings
        </Button>
      </div>

      <MarketMessageModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        allCoins={allCoins}
        excludedCoins={excludedCoins}
        coins={coins}
      />
    </>
  );
};

export default Markets;
