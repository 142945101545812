import React from "react";
import { Line } from "react-chartjs-2";
import { ScaleLoader } from "react-spinners";

import { drawSignalsChart } from "charts";

const SignalsChart = ({
  chart={},
  loading,
  error
}) => {
  if (loading) {
    return (
      <div
        className="chart-area d-flex flex-column justify-content-center text-center w-100"
        style={{ height: "300px" }}
      >
        <ScaleLoader
          height={40}
          width={7}
          color="#252738"
          className="text-center"
        />
      </div>
    );
  }

  if (error) {
    return (
      <div
        className="chart-area d-flex flex-column justify-content-center text-center w-100"
        style={{ height: "300px" }}
      >
        <p className="text-danger">{error}</p>
      </div>
    );
  }

  return(
    <div className="chart-area" style={{ height: "300px" }}>
      {
        chart.line ?
        <Line
          data={drawSignalsChart(chart.line).data}
          options={drawSignalsChart(chart.line).options}
        />
        :
        <div
          className="d-flex flex-column justify-content-center text-center h-100 w-100"
        >
          <p className="h4 text-white-35">
            You need to fill out the form at the
            top to see a graph of signal statistics
            and click on the “check” button
          </p>
        </div>
      }
    </div>
  );
};

export default SignalsChart;
