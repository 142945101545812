  import React from "react";
  import styled from "styled-components";
  import { Card } from "reactstrap";
  import { Link } from "react-router-dom";

  import Button from "components/Button";
  import { ReactComponent as ListingError } from "assets/img/emptyDataListing.svg";

  const EmptyStateWithBtnLink = ({
    title, 
    description,
    buttonText,
    onHandleClick,
    linkText,
    redirectTo
  }) => {
    return (
      <EmptyStateWithBtnLink.Card>
        <ListingError 
          role="img" 
          aria-label="empty state image"
        />
        <EmptyStateWithBtnLink.Title>{title}</EmptyStateWithBtnLink.Title>
        <EmptyStateWithBtnLink.Description>{description}</EmptyStateWithBtnLink.Description>
        {
          buttonText &&
          <Button 
            className="mt-4"
            color="blue"
            onClick={onHandleClick}
          >
            {buttonText}
          </Button>
        }
        {
          linkText &&
          <EmptyStateWithBtnLink.Link 
            className="mt-4 text-white"
            to={redirectTo}
          >
            {linkText}
          </EmptyStateWithBtnLink.Link>
        }
      </EmptyStateWithBtnLink.Card>
    )
  }

  EmptyStateWithBtnLink.Card = styled(Card)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px !important;
    margin: 0 auto;
    padding: 140px 0 125px 0;
    text-align: center;
  `;

  EmptyStateWithBtnLink.Title = styled.p`
    margin: 40px 0 8px 0;
    color: #FFFFFF !important;
    font-weight: 500;
    font-size: 18px;
  `;

  EmptyStateWithBtnLink.Description = styled.p`
    margin: 0;
    color: rgba(255, 255, 255, 0.75) !important;
    font-weight: 400;
    font-size: 14px;
    white-space: pre-line;
  `;

  EmptyStateWithBtnLink.Link = styled(Link)`
      text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  `;

  export default EmptyStateWithBtnLink;
