import {
  GET_EMAIL,
  VIEW_NOTIFICATION,
  VIEW_ALL_NOTIFICATIONS,
  DISCONNECT_TELEGRAM,
  CONNECT_TELEGRAM,
  SET_UNREAD_NOTIFICATIONS,
  UPDATE_BETA_SETTINGS,
  CONNECT_GOOGLE_ACCOUNT,
  SET_BALANCE,
  DISCONNECT_GOOGLE_ACCOUNT,
  GET_AVATAR_LOADING,
  GET_AVATAR_SUCCESS,
  GET_AVATAR,
  GET_AVATAR_FAILURE,
  SET_EMAIL,
  GET_ME_SUCCESS,
  DELETE_AVATAR_SUCCESS,
  DELETE_AVATAR_LOADING,
  SKIP_WIZARD,
  RECORD_CREDENTIALS_TO_WIZARD,
  RECORD_BOTS_TO_WIZARD,
  UPDATE_WIZARD,
  GET_USERS_REFERRALS_LOADING,
  GET_USERS_REFERRALS,
  RESET_ME,
  GET_REFERRALS_STATISTICS,
  GET_REFERRALS_STATISTICS_LOADING
} from "./constants";

export default function me(state = { data: {}, loading: false, error: false, isAvatarLoading: false, checkEmail: "", isReferralsLoading: false, statisticsLoading: false}, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ME_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
        }
      };
    case RESET_ME:
      console.log(1)
      return {
        ...state,
        data: {}
      };
    case VIEW_NOTIFICATION:
      return {
        ...state,
        data: {
          ...state.data,
          unreadNotifications: payload.metadata.unread,
        }
      };

    case VIEW_ALL_NOTIFICATIONS:
      return {
        ...state,
        data: {
          ...state.data,
          unreadNotifications: 0
        }
      };

    case GET_AVATAR:
      return {
        ...state,
        isAvatarLoading: false
      };

    case GET_AVATAR_LOADING:
      return {
        ...state,
        isAvatarLoading: true
      };

    case GET_AVATAR_SUCCESS:
      return {
        ...state,
        isAvatarLoading: false,
        data: {
          ...state.data,
          avatars: payload
        },
      };

    case GET_AVATAR_FAILURE:
      return {
        ...state,
        isAvatarLoading: false,
        data: {
          ...state.data,
          avatars: {}
        },
      };

    case DELETE_AVATAR_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          avatars: {}
        },
        isAvatarLoading: false
    };

    case DELETE_AVATAR_LOADING:
      return {
        ...state,
        isAvatarLoading: true,
      };

    case DISCONNECT_TELEGRAM:
      return {
        ...state,
        data: {
          ...state.data,
          telegram: false
        },
      };

    case CONNECT_TELEGRAM:
      return {
        ...state,
        data: {
          ...state.data,
          telegram: true
        },
      };

    case GET_EMAIL:
      return {
        ...state,
        checkEmail: { ...payload }
      };

    case SET_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        data: {
          ...state.data,
          unreadNotifications: payload
        }
      };

    case UPDATE_BETA_SETTINGS:
      return {
        ...state,
        data: {
          ...state.data,
          isBetaEnabled: payload
        }
      };

    case CONNECT_GOOGLE_ACCOUNT:
      return {
        ...state,
        data: {
          ...state.data,
          googleProfile: payload
        }
      };

    case DISCONNECT_GOOGLE_ACCOUNT:
      return {
        ...state,
        data: {
          ...state.data,
          googleProfile: null
        }
      };

    case SET_BALANCE:
      return {
        ...state,
        data: {
          ...state.data,
          holdings: {
            ...state.data.holdings,
            currency: payload
          }
        }
      };

    case SET_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          username: payload
        }
      };

    case SKIP_WIZARD:
      return {
        ...state,
        data: {
          ...state.data,
          wizard: {
            ...(state.data?.wizard || {}),
            skipped: true
          }
        }
      }

    case RECORD_CREDENTIALS_TO_WIZARD:
      return {
        ...state,
        data: {
          ...state.data,
          wizard: {
            ...(state.data?.wizard || {}),
            credentials: true
          }
        }
      }

    case RECORD_BOTS_TO_WIZARD:
      return {
        ...state,
        data: {
          ...state.data,
          wizard: {
            ...(state.data?.wizard || {}),
            bots: true
          }
        }
      };

    case UPDATE_WIZARD:
      return {
        ...state,
        data: {
          ...state.data,
          wizard: {
            ...state.data.wizard,
            ...payload
          }
        }
      };

      case GET_USERS_REFERRALS_LOADING:
        return {
          ...state,
          isReferralsLoading: true
        };

      case GET_USERS_REFERRALS:
        return {
          ...state,
          isReferralsLoading: false,
          data: {
            ...state.data,
            referral: {...payload}
          }
        };

      case GET_REFERRALS_STATISTICS_LOADING:
        return {
          ...state,
          statisticsLoading: true
        };

      case GET_REFERRALS_STATISTICS:
        return {
          ...state,
          statisticsLoading: false,
        };

    default:
      return state;
  }
}
