import React from "react";

import { replaceDurations } from "helpers/replaceDurations";
import { formatterToNull } from "helpers/valuesFormatters";
import OpenOrder from "shared/ui/OpenOrder";

import {
  TradeRequestAmount,
  TradeRequestBody,
  TradeRequestCurrency,
  TradeRequestTitle
} from "components/StyledTradeRequestComponents";

const TradeRequestCardBody = ({
  data,
  handleOpenModal,
  goal
}) => {
  return (
    <TradeRequestBody
      className="p-0"
      onClick={() => handleOpenModal({...data, goal})}
    >
      <div className="w-100 d-flex align-items-center flex-wrap justify-content-between py-3 px-4">
        <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2">
          <TradeRequestTitle>
            Executed amount
          </TradeRequestTitle>
          <div className="d-flex align-items-center">
            <TradeRequestAmount
              amount={data?.executedAmount?.amount}
            >
              {formatterToNull(data?.executedAmount?.amount)}
            </TradeRequestAmount>
            <TradeRequestCurrency>
              {data?.executedAmount?.asset}
            </TradeRequestCurrency>
          </div>
        </div>
        <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2">
          <TradeRequestTitle>
            Executed quantity
          </TradeRequestTitle>
          <div className="d-flex align-items-center">
            <TradeRequestAmount
              amount={data?.executedQuantity?.amount}
            >
              {formatterToNull(data?.executedQuantity?.amount)}
            </TradeRequestAmount>
            <TradeRequestCurrency>
              {data?.executedQuantity?.asset}
            </TradeRequestCurrency>
          </div>
        </div>
        <div className="d-flex flex-column px-2 px-md-0 mb-md-0 mb-2">
          <TradeRequestTitle>
            Fee
          </TradeRequestTitle>
          <div className="d-flex align-items-center">
            <TradeRequestAmount amount={data?.fee?.amount}>
              {formatterToNull(data?.fee?.amount)}
            </TradeRequestAmount>
            <TradeRequestCurrency>
              {data?.fee?.asset}
            </TradeRequestCurrency>
          </div>
        </div>
        <div className="d-flex flex-column px-2 px-md-0 mb-md-0 mb-2">
          <TradeRequestTitle>
            Average price
          </TradeRequestTitle>
          <div className="d-flex align-items-center">
            <TradeRequestAmount amount={data?.averageRate}>
              {formatterToNull(data?.averageRate)}
            </TradeRequestAmount>
          </div>
        </div>
        <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2">
          <TradeRequestTitle>
            Trades
          </TradeRequestTitle>
          <TradeRequestAmount amount={data?.tradesCount}>
            {formatterToNull(data?.tradesCount)}
          </TradeRequestAmount>
        </div>
        <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2">
          <TradeRequestTitle>
            Orders
          </TradeRequestTitle>
          <TradeRequestAmount amount={data?.ordersCount}>
            {formatterToNull(data?.ordersCount)}
          </TradeRequestAmount>
        </div>
        <div className="d-flex flex-column align-items-end text-nowrap px-2 px-md-0 mb-md-0 mb-2">
          <TradeRequestTitle>
            Duration
          </TradeRequestTitle>
          <p className={`mb-0 font-sm font-weight-500 ${data?.lifetime ? "text-white" : "text-white-35"}`}>
            {replaceDurations(data?.lifetime)}
          </p>
        </div>
      </div>
      {data?.openOrderRequest
        ? <OpenOrder
          openOrderRequest={data?.openOrderRequest}
        />
        : null}
    </TradeRequestBody>
  );
};

export default TradeRequestCardBody;