export const CRITERIA_FIELDS = [
  {
    active: false,
    name: 'Balance',
    code: 'balance',
    description: 'The highest value of the final balance',
    power: 0,
  },
  {
    active: false,
    name: 'Average profit trade',
    code: 'average_profit_trade',
    description: 'The highest value of the average profit trade',
    power: 0,
  },
  {
    active: false,
    name: 'Profit Factor',
    code: 'profit_factor',
    description: 'The highest value of the profit factor',
    power: 0
  },
  {
    active: false,
    name: 'Profitable Deals',
    code: 'profitable_deals',
    description: 'The highest value of the profitable deals',
    power: 0
  },
  {
    active: false,
    name: 'Expected Payoff',
    code: 'expected_payoff',
    description: 'The highest value of the expected payoff',
    power: 0
  },
  {
    active: false,
    name: 'Drawdown',
    code: 'drawdown',
    description: 'The drawdown level is taken into account: Balance/Equity drawdown',
    power: 0
  },
  {
    active: false,
    name: 'Recovery Factor',
    code: 'recovery_factor',
    description: 'Value of the recovery factor',
    power: 0
  }
];

export const CRITERIA_DESCRIPTIONS = {
  balance: {
    name: 'Balance',
    description: 'The highest value of the final balance',
  },
  average_profit_trade: {
    name: 'Average profit trade',
    description: 'The highest value of the average profit trade',
  },
  profit_factor: {
    name: 'Profit Factor',
    description: 'The highest value of the profit factor',
  },
  profitable_deals: {
    name: 'Profitable Deals',
    description: 'The highest value of the profitable deals',
  },
  expected_payoff: {
    name: 'Expected Payoff',
    description: 'The highest value of the expected payoff',
  },
  drawdown: {
    name: 'Drawdown',
    description: 'The drawdown level is taken into account: Balance/Equity drawdown',
  },
  recovery_factor: {
    name: 'Recovery Factor',
    description: 'Value of the recovery factor',
  }
};
