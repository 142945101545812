import React from 'react';
import {
  Card,
  CardBody,
  CardHeader
} from "reactstrap";
import { times } from "lodash";

import SkeletonPreloader from "components/SkeletonPreloader";

const SettingsPreloader = () => {
  return (
    <Card>
      <CardHeader className="px-4 pt-4 pb-0">
        <div className="d-flex align-items-center">
          <SkeletonPreloader
            height={20}
            width={20}
            circle
          />
          <SkeletonPreloader
            height={16}
            width={103}
            className="ml-2"
          />
        </div>
      </CardHeader>
      <CardBody className="p-4">
        <div className="d-flex justify-content-between mb-3">
          <SkeletonPreloader
            height={10}
            width={81}
          />
          <SkeletonPreloader
            height={14}
            width={61}
          />
        </div>
        {
          times(3, (e) =>
            <div
              className="d-flex justify-content-between mb-3"
              key={e}
            >
              <SkeletonPreloader
                height={10}
                width={81}
              />
              <SkeletonPreloader
                height={14}
                width={121}
              />
            </div>
          )
        }
      </CardBody>
    </Card>
  );
};

export default SettingsPreloader;
