export const replaceDurations = ( duration ) => {
  if (typeof duration === "string" || duration instanceof String) {
    return duration
      .replace("months", "m")
      .replace("month", "m")
      .replace("weeks", "w")
      .replace("week", "w")
      .replace("days", "d")
      .replace("day", "d")
      .replace("hours", "h")
      .replace("hour", "h")
      .replace("minutes", "m")
      .replace("minute", "m")
      .replace("seconds", "s")
      .replace("second", "s")
      .replaceAll(" ", "")
      .replaceAll(",", " ")
  }
  return "0m";
};
