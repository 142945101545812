import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody
} from "reactstrap";

import Button from 'components/Button';
import illustration from 'assets/img/mainWizard/stay-at-home.png';

const SubscriptionExpired = () => {
  const history = useHistory();

  return (
    <StyledCard>
      <StyledCardBody className="d-flex flex-column flex-md-row position-relative">
        <StyledButton cancel />
        <div className="d-flex flex-column flex-md-row justify-content-between w-100">
          <div className="d-flex flex-column justify-content-center">
            <p className="h1 mb-2 text-white">
              Ooh, your subscription has expired!
            </p>
            <p className="font-sm text-white-75 mb-4">
              {"Don't worry, you can easily renew it to continue enjoying our premium"}<br />
              features and benefits. Stay ahead with our services - renew your<br />
              subscription now and keep your journey going!
            </p>
            <Button
              color="blue"
              onClick={() => history.push("billing")}
              className="ml-0 mr-auto mb-4 mb-md-0"
            >
              Renew my subscription
            </Button>
          </div>
          <img
            src={illustration}
            alt="Crypto investment"
            className="mx-auto ml-md-auto mr-md-0"
          />
        </div>
      </StyledCardBody>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  @media (min-width: 1200px) {
    box-shadow: 10px 8px 4px 0px rgba(47, 128, 237, 0.40) !important;
  }
`;

const StyledCardBody = styled(CardBody)`
  padding: 2rem 1.25rem !important;

  @media (min-width: 1200px) {
    padding: 1.25rem 6.563rem 1.25rem 3rem !important;
  }

  & img {
    width: 286px;
  }
`;

const StyledButton = styled(Button)`
  position: absolute;
  right: 8px;
  top: 8px;
`;

export default SubscriptionExpired;
