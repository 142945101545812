import React from "react";
import { Row, Col } from "reactstrap";

import PlanCard from "./PlanCard";
import { freePlan } from "../models";
import styled from "styled-components";

const PlansCards = ({ 
  plans=[], 
  paymentInterval, 
  hasTrial
}) => {

  return (
    <PlansCards.Row className="no-gutters">
      <Col
        xs="12"
        md="4"
        className="pr-2"
      >
        <PlanCard
          plan={freePlan}
          paymentInterval={paymentInterval}
        />
      </Col>
      {
        plans.map((plan) =>
          <Col
            xs="12"
            md="4"
            key={plan.id}
          >
            <PlanCard
              plan={plan}
              paymentInterval={paymentInterval}
              hasTrial={hasTrial}
            />
          </Col>
        )
      }
    </PlansCards.Row>
  );
};

PlansCards.Row = styled(Row)`
  div {
    &:nth-child(2) {
      padding: 0 8px;
    }
    &:last-child {
      padding-left: 8px !important;
    }
  }
`;

export default PlansCards;
