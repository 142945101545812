import React from "react";
import styled from "styled-components";
import { Card } from "reactstrap";
import SkeletonPreloader from "../../../components/SkeletonPreloader";
import _ from "lodash";

const UserNotificationsPreloader = () => {
  return (
    <UserNotificationsPreloader.Preloaders>
      <div className="d-flex flex-column flex-sm-row justify-content-between">
        <SkeletonPreloader
          height={22}
          width={150}
          style={{ backgroundImage: "linear-gradient(90deg, #535462 0%, rgba(83, 84, 98, 0) 100%)" }}
        />
        <SkeletonPreloader
          className="mt-2 mt-sm-0"
          borderRadius="6px"
          height={40}
          width={195}
        />
      </div>
      <div className="d-flex align-items-center mt-5" style={{ background: "rgb(29, 30, 42, 0.3)", borderRadius: "4px" }}>
        <div className="d-flex flex-column align-items-center justify-content-center mt-2 mt-sm-0">
          {
            _.times(7, (e) =>
              <div
                className="d-flex pl-0 pr-2 py-4 p-sm-4"
                key={e}
                style={{ border: "1px solid var(--dark-slate)", padding: "12px" }}
              >
                <SkeletonPreloader
                  circle
                  height={24}
                  width={24}
                  className="my-auto"
                  style={{ backgroundImage: "linear-gradient(90deg, #535462 0%, rgba(83, 84, 98, 0) 100%)" }}
                />
                <SkeletonPreloader
                  height={12}
                  width={71}
                  className="ml-3 my-auto"
                  style={{ backgroundImage: "linear-gradient(90deg, #535462 0%, rgba(83, 84, 98, 0) 100%)" }}
                />
              </div>
            )
          }
        </div>
        <div className="ml-4 d-none d-md-flex flex-column align-items-center justify-content-center mt-0 mb-auto">
          {
            _.times(5, (e) =>
              <div
                className="d-flex flex-column"
                key={e}
                style={{ padding: "16px" }}
              >
                <SkeletonPreloader
                  height={10}
                  width={405}
                  className="mt-1 mb-1"
                  style={{ backgroundImage: "linear-gradient(90deg, #535462 0%, rgba(83, 84, 98, 0) 100%)" }}
                />
                <SkeletonPreloader
                  height={12}
                  width={206}
                  className="mt-1 mb-1"
                  style={{ backgroundImage: "linear-gradient(90deg, #535462 0%, rgba(83, 84, 98, 0) 100%)" }}
                />
              </div>
            )
          }
        </div>
        <div className="ml-4 d-none d-md-flex flex-column w-100 align-items-end mr-3 mt-0 mb-auto">
          {
            _.times(5, (e) =>
              <div
                className="d-flex flex-column mt-1 mb-1"
                key={e}
                style={{ padding: "22px" }}
              >
                <SkeletonPreloader
                  height={14}
                  width={26}
                  style={{ backgroundImage: "linear-gradient(90deg, #535462 0%, rgba(83, 84, 98, 0) 100%)" }}
                >
                  <SkeletonPreloader
                    height={12}
                    width={12}
                    style={{ backgroundImage: "#252738", margin: "1px 0 0 13px" }}
                  />
                </SkeletonPreloader>
              </div>
            )
          }
        </div>
      </div>
    </UserNotificationsPreloader.Preloaders>
  )
}

UserNotificationsPreloader.Preloaders = styled(Card)`
  padding: 24px;
  display: flex;
  margin: 0 14px;
`;

export default UserNotificationsPreloader;
