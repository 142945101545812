import React, {useLayoutEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Card} from "reactstrap";
import {fetchTechnicalAnalysis, fetchTechnicalAnalysisMarket} from "store/bots/actions";
import MarketSummaryHeader from "./MarketSummaryHeader";
import MarketSummaryTable from "./MarketSummaryTable";
import MarketSummaryModal from "./MarketSummaryModal";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";

const MarketSummary = ({botId, loadingPage, botData}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState();
  const wrapperRef = useRef(null);
  const handleToggleModal = () => setIsOpenModal((prev) => !prev);

  const dispatch = useDispatch();

  const analysisData = useSelector((state) => state.bots.technicalAnalysis.data);
  const analysisLoading = useSelector((state) => state.bots.technicalAnalysis.loading);

  const { handleSorting, handlePaginate, handleFilter } = useFiltersWithRequest(analysisData?.request, fetchTechnicalAnalysis, botId);

  const handleSelectMarket = (market) => {
    setIsOpenModal(true);
    setSelectedMarket(market);
    dispatch(fetchTechnicalAnalysisMarket(botId, market));
  }

  useLayoutEffect(() => {
    dispatch(fetchTechnicalAnalysis(botId, "?limit=20"));
  }, [dispatch, botId]);

  return (
    <div ref={wrapperRef}>
      <Card className="mb-4 p-0 overflow-hidden">
        <MarketSummaryHeader
          botId={botId}
          handleFilter={handleFilter}
          analysisLoading={analysisLoading}
          loadingPage={loadingPage}
          filter={analysisData?.request?.filter}
          items={analysisData?.items}
        />

        <MarketSummaryTable
          analysisData={analysisData}
          limit={analysisData?.request?.limit}
          analysisLoading={analysisLoading}
          loadingPage={loadingPage}
          handleSorting={handleSorting}
          handlePaginate={handlePaginate}
          handleSelectMarket={handleSelectMarket}
          wrapperRef={wrapperRef}
        />
      </Card>

      {
        isOpenModal &&
        <MarketSummaryModal
          isOpnModal={isOpenModal}
          handleToggleModal={handleToggleModal}
          selectedMarket={selectedMarket}
          loadingPage={loadingPage}
          botData={botData}
        />
      }
    </div>
  )
}

export default MarketSummary;
