import React from "react";
import styled from "styled-components";
import {CardBody} from "reactstrap";

import {RUNNING_DEALS_TABLE_HEAD} from "models/bot/tables";
import SortTableHead from "components/SortTableHead";
import PaginationPanel from "components/PaginationPanel";
import StyledTable from "components/StyledTable";
import CustomScrollbars from "components/CustomScrollbars";
import RunningDealsTableRow from "./RunningDealsTableRow";
import RunningEmptyState from "./RunningEmptyState";

const RunningDealsTable = ({
  items,
  request,
  page,
  total,
  handlePaginate,
  handleSorting,
  wrapperRef
}) => {

  if (!items?.length) {
    return (
      <RunningEmptyState
        title="No results for your search"
        description="Oops, we couldn't find any results for your search.
        Please try again with a different keyword or refine your search criteria."
      />
    );
  }
  return (
    <StyledCardBody className="p-0 overflow-hidden">
      <CustomScrollbars
        autoHideTimeout={500}
        autoHideDuration={500}
        autoHeight
        autoHeightMax={Number.MAX_VALUE}
      >
        <StyledTable className="mb-0" paddingsThead="1rem">
          <SortTableHead
            handleSorting={handleSorting}
            sort={request?.sort}
            theadItems={RUNNING_DEALS_TABLE_HEAD}
          />
          <tbody className="font-sm">
          {
            items?.map((item) =>
              <RunningDealsTableRow
                item={item}
                key={item.id}
                total={total}
              />
            )
          }
          </tbody>
        </StyledTable>
      </CustomScrollbars>
      <div className={total > 10 ? "mt-3" : "mt-0"}>
        <PaginationPanel
          activePage={page}
          totalItemsCount={total}
          onChange={(page) => handlePaginate(page)}
          className="m-0"
          elementRef={wrapperRef}
        />
      </div>
    </StyledCardBody>
  );
};

const StyledCardBody = styled(CardBody)`
  border-radius: 0 0 0.5rem 0.5rem;
`;

export default RunningDealsTable;
