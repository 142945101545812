import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {Card, CardBody} from "reactstrap";
import OrdersFilters from "./OrdersFilters";
import {getMarkets} from "store/markets/actions";
import {fetchCredential, fetchOpenOrders} from "store/credentials/actions";
import OpenOrdersHeader from "./OpenOrdersHeader";
import Error from "components/Error";
import PaginationPanel from "components/PaginationPanel";
import {useHistory} from "react-router-dom";
import qs from "qs";
import OrdersTable from "./OrdersTable";
import EmptyTables from "../states/EmptyTables";
import OrderModal from "./OrderModal";
import {paginationScrollUp} from "helpers/paginationScrollUp";
import Breadcrumbs from "components/Breadcrumbs";
import {setCredentialsBreadcrumbs} from "models/enum/breadcrumbs";
import TablePreloader from "components/TablePreloader";
import useRequestCancellation from "hooks/useRequestCancellation";

const OpenOrders = ({match, location}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [currentOrderNumber, setCurrentOrderNumber] = useState("");
  const [emptyFilter, setEmptyFilter] = useState(null);
  const { useAbortableEffect } = useRequestCancellation();

  const dispatch = useDispatch();
  const history = useHistory();
  const search = qs.parse(location.search, {ignoreQueryPrefix: true});

  const defaultSort = search?.sort ? "" : qs.stringify({sort: {by: "createdAt", order: "desc"}});

  const openOrders = useSelector((state) => state.credentials.openOrders.data);
  const loading = useSelector((state) => state.credentials.openOrders.loading);
  const error = useSelector((state) => state.credentials.openOrders.error);
  const credential = useSelector((state) => state.credentials.item.data);
  const markets = useSelector((state) => state.markets);

  useAbortableEffect((signal) => {
    setInitialLoading(true);
    dispatch(fetchCredential(match.params.id, signal))
      .then((res) => dispatch(getMarkets(res.exchange)))
      .catch(() => setInitialLoading(false));
  }, [match.params.id, dispatch], true);

  useAbortableEffect((signal) => {
    const sort = location.search ? `&${defaultSort}` : `?${defaultSort}`;
    const filter = (location.search && !defaultSort) ? "" : sort;
    dispatch(
      fetchOpenOrders(
        match.params.id,
        `${location.search}${filter}`,
        signal
      )
    )
    .finally(() => setInitialLoading(false));
  }, [location.search, match.params.id, dispatch, defaultSort], true);

  useEffect(() => {
    window.analytics.page("/credentials/:id/orders");
  }, []);

  const handlePaginate = (page) => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({...search, page})
    });
    paginationScrollUp();
  };

  return (
    <>
      <Breadcrumbs
        items={setCredentialsBreadcrumbs(credential.exchange, match.params.id, "Open orders")}
        loading={loading}
      />

      <OpenOrdersHeader
        exchange={credential.exchange}
        loading={initialLoading}
      />
      {error && !loading ? <Error error={error}/> : null}
      <OrdersFilters
        location={location}
        loading={initialLoading}
        credentialId={match.params.id}
        markets={markets}
        setEmptyFilter={setEmptyFilter}
      />
      {
        loading || initialLoading ?
          <Card className="mb-0 pt-3 overflow-hidden">
            <TablePreloader
              thHeight={12}
              thWidth={[44,48,68,37,37,44,54,52]}
              tdWidth={[32,72,71,134,75,134,134,95]}
              tdHeight={12}
              rowTimes={10}
            />
          </Card>
          :
          <div>
            {
              openOrders?.items?.length ?
                <>
                  <Card className="mb-3 overflow-hidden">
                    <CardBody className="p-0">
                      <OrdersTable
                        data={openOrders.items}
                        credentialId={match.params.id}
                        setIsModalOpen={setIsModalOpen}
                        setOrderId={setOrderId}
                        setCurrentOrderNumber={setCurrentOrderNumber}
                      />
                    </CardBody>
                  </Card>
                  <div>
                    <PaginationPanel
                      activePage={openOrders?.page}
                      totalItemsCount={openOrders?.total}
                      onChange={(page) => handlePaginate(page)}
                    />
                  </div>
                </>
                : <EmptyTables
                  title="There are no open orders at the moment"
                  description="Please open some orders to see them displayed here"
                  emptyFilter={emptyFilter}
                />
            }
          </div>
      }
      {
        isModalOpen &&
        <OrderModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          orderId={orderId}
          credentialId={match.params.id}
          openOrders={openOrders.items}
          currentOrderNumber={currentOrderNumber}
          exchange={credential.exchange}
        />
      }
    </>
  );
};


export default OpenOrders;
