import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { stringOrNull } from "../views/channels/channelPropTypes";
import TradingChannel from "assets/img/icons/channel-default-icon.png";
import SkeletonPreloader from "components/SkeletonPreloader";

const ChannelLogo = ({ 
  logo, 
  size,
  loading,
  className
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    setIsLoading(true);
    setKey(Math.random());
  }, [logo]);

  return (
    <>
      <SkeletonPreloader
        borderRadius="4px"
        height={size}
        width={size}
        className={`d-${logo && isLoading ? "block" : "none"} my-auto ${className}`}
      />
      <ChannelLogo.Wrap
        size={size}
        className={`d-${logo && !isLoading ? "block" : "none"} my-auto ${className}`}
      >
        <img
          key={key}
          src={logo}
          alt="logo_channel"
          className="logo-img"
          onLoad={() => setIsLoading(false)}
          onError={() => setIsLoading(false)}
        />
      </ChannelLogo.Wrap>
      <ChannelLogo.Wrap
        size={size}
        className={`d-${!logo && !loading && isLoading ? "block" : "none"} my-auto ${className}`}
      >
        <img
          src={TradingChannel}
          alt="default_logo"
          className="logo-img"
        />
      </ChannelLogo.Wrap>
    </>
  );
};

ChannelLogo.Wrap = styled.div`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  overflow: hidden;

  .logo-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    margin: 0 auto;
  }
`;

ChannelLogo.propTypes = {
  logo: stringOrNull,
  size: PropTypes.number,
};

export default ChannelLogo;
