import React from "react";
import styled from "styled-components";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader
} from "reactstrap";

import Button from "components/Button";
import SubCard from "components/SubCard";
import { ReactComponent as TelegramIcon } from "assets/img/icons/telegram.svg";
import { ReactComponent as SuccessfullyIcon } from "assets/img/icons/successfully.svg";

const TelegramDefaultForm = ({
  isSubmitting,
  isTestMsgSend,
  onDisconnect,
  onTestMessage
}) => {
  const handleDisconnect = (e) => onDisconnect(e);
  const handleTestMessage = () => onTestMessage();

  return (
    <Card
      data-testid="telegram-default-form-card"
      className="p-3"
    >
      <TelegramDefaultForm.Header
        data-testid="telegram-default-form-telegram-header"
        className="p-2 d-flex flex-row flex-md-column flex-lg-row align-items-lg-center"
      >
        <div className="d-flex align-items-center">
          <TelegramIcon
            className="mr-1"
            data-testid="telegram-default-form-telegram-icon"
          />
          <p
            data-testid="telegram-default-form-telegram-text"
            className="mb-0 mr-2 text-white font-weight-500"
          >
            Telegram
          </p>
        </div>
        <SubCard className="mt-md-2 mt-lg-0">
          <p
            data-testid="telegram-default-form-telegram-connected"
            className="mb-0 font-weight-500 text-success font-sm"
          >
            Connected
          </p>
        </SubCard>
      </TelegramDefaultForm.Header>
      <CardBody
        data-testid="telegram-default-form-telegram-body"
        className="p-2 font-sm">
        <span
          data-testid="telegram-default-form-telegram-description"
          className="text-white-75"
        >
          If you want to disable or change your telegram account, you can
        </span>
        <a
          data-testid="telegram-default-form-telegram-link"
          href="/#"
          onClick={(e) => handleDisconnect(e)}
          className="mx-1 text-info disconnect-link"
        >
          Disconnect
        </a>
        <span className="text-white-75">it.</span>
      </CardBody>
      <CardFooter
        data-testid="telegram-default-form-telegram-card-footer"
        className="px-0 py-2 px-xl-2">
        {
          isTestMsgSend ?
            <div className="send-wrapper d-flex align-items-center justify-content-center">
              <div className="animated mx-auto">
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <div className="mr-2">
                    <SuccessfullyIcon
                      width={16}
                      height={16}
                      data-testid="telegram-default-form-telegram-successfully-icon"
                    />
                  </div>
                  <span
                    data-testid="telegram-default-form-telegram-successfully-text"
                    className="font-weight-500 text-white-35 font-sm"
                  >
                    Test message sent
                  </span>
                </div>
              </div>
            </div>
            :
            <Button
              data-testid="telegram-default-form-telegram-send-test-message"
              color="gray"
              loading={isSubmitting}
              className="w-100 text-nowrap"
              onClick={handleTestMessage}
            >
              Send test message
            </Button>
        }
      </CardFooter>
    </Card>
  )
}

TelegramDefaultForm.Header = styled(CardHeader)`
  p {
    line-height: 19px;
  }
`
export default TelegramDefaultForm;
