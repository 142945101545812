import PropTypes from "prop-types";

export const SignalBlockPropTypes = {
  createdAt: PropTypes.string,
  status: PropTypes.string,
  base: PropTypes.string,
  quote: PropTypes.string,
  exchange: PropTypes.string,
};

export const SignalChannelBlockPropTypes = {
  channelId: PropTypes.string,
  name: PropTypes.string,
  avatar: PropTypes.string,
  isSubscribed: PropTypes.bool,
  totalSubscribers: PropTypes.number
};
