import React from "react";
import classnames from "classnames";
import styled from "styled-components";
import Button from "components/Button";
import SubCard from "components/SubCard";
import {CardHeader} from "reactstrap";

const DefaultEmailForm = ({confirmed, email, onResend, isSubmitting, onChangeEmail, onChangeCurrentEmail}) => {
  const handleResend = (e) => {
    onResend(e)
  }

  const handleChangeEmail = () => {
    onChangeEmail()
  }

  const handleChangeCurrentEmail = () => {
    onChangeCurrentEmail()
  }

  return (
    <>
      <DefaultEmailForm.Header className="p-0 d-flex flex-row align-items-center mb-2">
        <p className="h4 mb-0 mr-2 text-white font-weight-500 font-md">E-mail</p>
        <SubCard>
          <p
            className={classnames("mb-0 font-weight-500 font-sm", {
              "text-success": confirmed,
              "text-danger": !confirmed
            })}
          >
            {confirmed ? "Verified" : "Unverified"}
          </p>
        </SubCard>
      </DefaultEmailForm.Header>
      <div className="p-0 mb-3 font-sm">
        {
          confirmed ?
            <>
              <span className="text-white-75 mr-1">Your current email updated in your Darkbot account as</span>
              <span className="text-white font-weight-500">{email}</span>
            </>
            :
            <>
              <span className="text-white-75 mr-1">
                Just one more step! We already emailed you a verification link to
              </span>
              <span className="text-white font-weight-500">{email}</span>
              <span className="text-white-75 mr-1"> , please check your inbox and click the link in order to verify your email address. Did not get it?</span>
              <a href="/#"
                 className="link-info resend-code"
                 onClick={(e) => handleResend(e)}
              >
                Resend
              </a>
            </>
        }
      </div>
      {
        confirmed ?
          <Button
            color="gray"
            loading={isSubmitting}
            className="mt-1 w-100"
            onClick={handleChangeEmail}
          >
            Change
          </Button> :
          <Button
            color="gray"
            loading={isSubmitting}
            type="submit"
            onClick={handleChangeCurrentEmail}
            className="w-100"
          >
            Change email
          </Button>
      }
    </>
  )
}

DefaultEmailForm.Header = styled(CardHeader)`
  p {
    line-height: 19px;
  }
`

export default DefaultEmailForm;
