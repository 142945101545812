import React, {useEffect, useState, useLayoutEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Card, CardBody} from "reactstrap";
import {useHistory} from "react-router-dom";
import qs from "qs";

import {fetchCredential, fetchCredentialStatistics, resetCredentialStatistics} from "store/credentials/actions";
import {getMarkets, resetMarkets} from "store/markets/actions";
import MarketsTable from "./MarketsStatisticsTable";
import MarketStatisticsHeader from "./MarketStatisticsHeader";
import MarketStatisticsFilters from "./MarketStatisticsFilters";
import PaginationPanel from "components/PaginationPanel";
import EmptyTables from "../states/EmptyTables";
import {paginationScrollUp} from "helpers/paginationScrollUp";
import {setCredentialsBreadcrumbs} from "models/enum/breadcrumbs";
import Breadcrumbs from "components/Breadcrumbs";
import TablePreloader from "components/TablePreloader";

const MarketStatistics = ({match, location}) => {
  const [emptyFilter, setEmptyFilter] = useState(null);
  const [initialLoading, setInitialLoading] = useState(false);

  const history = useHistory();
  const search = qs.parse(location.search, {ignoreQueryPrefix: true});
  const dispatch = useDispatch();

  const marketStatistics = useSelector((state) => state.credentials.statistics.data);
  const loading = useSelector((state) => state.credentials.statistics.loading);
  const credential = useSelector((state) => state.credentials.item.data);
  const markets = useSelector((state) => state.markets);

  const handlePaginate = (page) => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({...search, page})
    });
    paginationScrollUp();
  };

  useLayoutEffect(() => {
    setInitialLoading(true);
    dispatch(fetchCredential(match.params.id))
      .then((res) => dispatch(getMarkets(res.exchange)))
      .catch(() => setInitialLoading(false));
  }, [match.params.id, dispatch]);

  useLayoutEffect(() => {
    dispatch(fetchCredentialStatistics(match.params.id, location.search))
      .finally(() => setInitialLoading(false));
    return () => dispatch(resetCredentialStatistics());
  }, [match.params.id, dispatch, location.search]);


  useEffect(() => {
    if (credential.exchange) dispatch(getMarkets(credential.exchange));
    return () => dispatch(resetMarkets());
  }, [credential, dispatch]);

  useEffect(() => {
    window.analytics.page("/credentials/:id/statistics");
  }, []);

  return (
    <>
      <Breadcrumbs
        items={setCredentialsBreadcrumbs(credential.exchange, match.params.id, "Market statistics")}
        loading={loading}
      />
      <MarketStatisticsHeader
        exchange={credential.exchange}
        loading={initialLoading}
      />
      <MarketStatisticsFilters
        history={history}
        credentialId={match.params.id}
        markets={markets}
        setEmptyFilter={setEmptyFilter}
        loading={initialLoading}
      />
      {
        loading || initialLoading ?
          <Card className="mb-0 pt-3 overflow-hidden">
            <TablePreloader
              thHeight={12}
              thWidth={[77, 48, 35, 77, 37, 44, 62, 52, 44, 56, 62]}
              tdWidth={[71, 144, 75, 144, 75, 117, 129, 105, 66, 94,66]}
              tdHeight={12}
              rowTimes={10}
            />
          </Card>
          :
          <>
            {
              marketStatistics?.items?.length ?
                <>
                  <Card className="mb-0">
                    <CardBody className="p-0">
                      <MarketsTable
                        data={marketStatistics.items}
                        credentialId={match.params.id}
                      />
                    </CardBody>
                  </Card>
                  <div className="pt-3">
                    <PaginationPanel
                      activePage={marketStatistics.page}
                      totalItemsCount={marketStatistics.total}
                      onChange={(page) => handlePaginate(page)}
                    />
                  </div>
                </>
                :
                <EmptyTables
                  title="There are no available market statistics at the moment"
                  description="It will be here once you make a trades on the exchange"
                  emptyFilter={emptyFilter}
                />
            }
          </>
      }
    </>
  );
};


export default MarketStatistics;
