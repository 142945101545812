import React from "react";
import {
  Card,
  CardBody,
  Table,
  CardHeader,
} from "reactstrap";
import classNames from "classnames";
import moment from "moment";

import {ReactComponent as Clipboardtext} from "assets/img/icons/clipboardtext.svg";
import LastTradesPreloader from "./preloaders/LastTradesPreloader";
import CustomScrollbars from "components/CustomScrollbars";
import Error from "components/Error";

const LastTrades = ({
  lastTrade = [],
  error,
  isTradesLoading,
  quantity,
  showAmount,
  market,
  withCurrenciesInHeader,
  loadingPage
}) => {
  if (isTradesLoading || loadingPage) return <LastTradesPreloader/>;

  if (error) return <Error error={error} />;

  return (
    <Card className="m-0">
      <CardHeader className="d-flex font-weight-500 pt-4 px-4 pb-0">
        <Clipboardtext/>
        <p className="text-white ml-2 my-auto">
          Public Trades
        </p>
      </CardHeader>
      <CardBody>
        <CustomScrollbars
          autoHideTimeout={500}
          autoHideDuration={500}
          autoHeight
          autoHeightMax={Number.MAX_VALUE}
        >
          <Table>
            <thead>
              <tr>
                <th className="font-weight-300 font-sm text-white-35 text-nowrap">
                  Price{withCurrenciesInHeader ? ` (${market?.quote})` : null}
                </th>
                {
                  quantity &&
                  <th className="font-weight-300 font-sm text-white-35 text-nowrap">
                    Quantity{withCurrenciesInHeader ? ` (${market?.base})` : null}
                  </th>
                }
                <th className="font-weight-300 font-sm text-white-35 text-nowrap">
                  Amount{withCurrenciesInHeader ? ` (${market?.quote})` : null}
                </th>
                <th className="font-weight-300 font-sm text-white-35">
                  Time
                </th>
              </tr>
            </thead>
            <tbody>
            {
              lastTrade.map((trade) =>
                <tr key={trade.id}>
                  <td className={quantity ? "w-50" : ""}>
                    <div className="d-flex align-items-center">
                        <span className={classNames("font-weight-500", {
                          "text-success": trade.takerSide === "buy" || trade.type?.toLowerCase() === "buy",
                          "text-danger": trade.takerSide === "sell" || trade.type?.toLowerCase() === "sell",
                        })}
                        >
                          {trade.price || trade.rate}
                        </span>
                      {
                        !withCurrenciesInHeader ?
                          <p className="mb-0 ml-1 text-white-35 font-weight-300">
                            {market?.quote}
                          </p> : null
                      }
                    </div>
                  </td>
                  {
                    quantity &&
                    <td>
                      <div className="d-flex align-items-center">
                          <span className="font-weight-500 text-white mr-1">
                            {trade.quantity}
                          </span>
                        {
                          !withCurrenciesInHeader ?
                            <span className="font-weight-300 text-white-35">
                              {market?.base}
                            </span> : null
                        }
                      </div>
                    </td>
                  }
                  <td>
                    <div className="d-flex align-items-center">
                        <span className="font-weight-500 text-white">
                          {trade.amount}
                        </span>
                      {
                        !withCurrenciesInHeader ?
                          <p className="mb-0 ml-1 text-white-35 font-weight-300">
                            {market?.quote}
                          </p> : null
                      }
                    </div>
                  </td>
                  <td>
                      <span className="text-white-75 text-nowrap">
                        {trade.date || ""}
                        {trade.createTime ? moment(trade.createTime).format("YYYY-MM-DD H:mm:ss") : ""}
                      </span>
                  </td>
                </tr>
              )
            }
            </tbody>
          </Table>
        </CustomScrollbars>
      </CardBody>
    </Card>
  );
};

export default LastTrades;
