import { get } from "lodash";

import {
  UPDATE_INVESTMENT_SETTINGS,
  RESET_INVESTMENT,
  RESET_INVESTMENTS,
  RESET_BOTS_STATISTICS,
  INVESTMENT_STATISTICS
} from "./constants";
import createAction from "store/thunkFactory";

export const getInvestment = (id, signal) => createAction("INVESTMENT", (fetch) => {
  return fetch.get(`/app/investments/${id}`, signal);
});

export const getInvestmentStatistics = (id, period) => createAction("INVESTMENT_STATISTICS", (fetch) => {
  return fetch.get(`/app/investments/${id}/statistics?period=${period}`);
});

export const getBotsStatistics = (id, total) => createAction("BOTS_STATISTICS", (fetch) => {
  return fetch.get(`/app/investments/${id}/bots/statistics?total=${total}`);
});

export const getInvestmentsList = (filters, signal) => createAction("INVESTMENTS", (fetch) => {
  return fetch.get(`/app/investments${filters || ""}`, { signal });
});

export const updateInvestmentSettings = (id, body) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.patch(`/app/investments/${id}`, body);
    dispatch({ type: UPDATE_INVESTMENT_SETTINGS, payload: { data: res.data.item, id } });
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const deleteInvestment = (id) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.delete(`/app/investments/${id}`);
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const resetInvestment = () => ({ type: RESET_INVESTMENT });

export const resetInvestments = () => ({ type: RESET_INVESTMENTS });

export const resetBotsStatistics = () => ({ type: RESET_BOTS_STATISTICS });

export const resetInvestmentStatistics = () => ({ type: INVESTMENT_STATISTICS });
