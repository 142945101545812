import React from "react";
import styled from "styled-components";
import { times } from "lodash";

import SkeletonPreloader from "components/SkeletonPreloader";
import { ReactComponent as Icon } from "assets/img/icons/indicator-preloader-icon.svg";

const IndicatorsPreloader = () => {
  return (
    <>
      {
        times(3, (e) => (
          <IndicatorsPreloader.Box
            className="d-flex"
            key={e}
          >
            <Icon />
            <SkeletonPreloader
              height={20}
              width={20}
              style={{ borderRadius: "4px" }}
              className="ml-2 mr-3 my-auto"
            />
            <SkeletonPreloader
              height={12}
              width={113}
              className="my-auto"
            />
          </IndicatorsPreloader.Box>
        ))
      }
    </>
  );
};

IndicatorsPreloader.Box = styled.div`
  background: #2A2C42;
  padding: 12px 16px;
  margin-bottom: 6px;
`;

export default IndicatorsPreloader;
