import classNames from "classnames";
import React from "react";
import styled from "styled-components";

import { firstLetterUpper } from "helpers/valuesFormatters";

const TradeRequestGoal = ({
  goal,
  className = "mr-3"
}) => {
  if (goal) {
    return (
      <TradeRequestGoal.Label className={classNames(`font-xs ${className}`, {
        "investment": goal === "investment",
        "target": goal === "target",
        "stopLoss": goal === "stop_loss",
        "expire": goal === "expire",
        "manualSell": goal === "manual_sell",
      })}>
        {firstLetterUpper(goal).replace(/_/g, " ")}
      </TradeRequestGoal.Label>
    );
  }
};

TradeRequestGoal.Label = styled.span`
  border-radius: 2px;
  padding: .25rem .375rem;
  color: var(--white);

  &.investment {
    background: rgba(47, 128, 237, 0.20);
  }

  &.target {
    background: rgba(63, 203, 176, 0.20);
  }

  &.stopLoss {
    background: rgba(237, 95, 95, 0.20);
  }

  &.expire {
    background: rgba(255, 255, 255, 0.20);
  }

  &.manualSell {
    background: rgba(219, 83, 199, 0.20);
  }
`;

export default TradeRequestGoal;