import { combineReducers } from "redux";

import withDataLoading from "store/withDataLoading";
import {
  ENABLE_BOT,
  DISABLE_BOT,
  UPDATE_BOT_IN_LIST,
  ATTACH_STRATEGY,
  EDIT_BOT,
  UPDATE_BOT_NAME,
  TOGGLE_TICKER_ITEM,
  TOGGLE_TICKER_FILTER,
  ENABLE_BOT_LOADING,
  REMOVE_CREATED_INDICATOR,
  EDIT_BOT_LOADING,
  DISABLE_BOT_LOADING, EDIT_BOT_ERROR,
  EDIT_INDICATOR,
  CREATE_BACKTEST,
  UPDATE_GENETIC_ALGORITHM,
  RESET_GENETIC,
  GET_GENETIC_INDIVIDUALS_SUCCESS,
  GET_GENETIC_INDIVIDUALS_LOADING,
  GET_GENETIC_INDIVIDUALS_FAILURE,
  SET_CURRENT_BOT,
  RESET_INDICATORS_SIGNALS,
} from "./constants";
import { UPDATE_BOT_CONFIG } from "store/deals/constants";

const initialState = { data: {}, loading: false, error: false };

// eslint-disable-next-line complexity
const list = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_BOT_IN_LIST:
      return {
        ...state,
        data: {
          ...state.data,
          items: state.data.items ? state.data.items.map((item) => {
            if (item.id === payload.id) return { ...item, ...payload };
            return item;
          }) : []
        }
      };

    default:
      return state;
  }
};

const item = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ENABLE_BOT_LOADING:
      return {
        ...state,
        loading: true
        };

    case ENABLE_BOT:
      return {
        ...state,
        loading: false,
        data: payload
      };

    case DISABLE_BOT_LOADING:
      return {
        ...state,
        loading: true
      };

    case DISABLE_BOT:
      return {
        ...state,
        loading: false,
        data: payload
      };

    case ATTACH_STRATEGY:
      return {
        ...state,
        data: {
          ...state.data,
          strategy: payload
        }
      };

    case EDIT_BOT:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...payload
        }
      };

    case EDIT_BOT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case EDIT_BOT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case EDIT_INDICATOR:
      return {
        ...state,
        data: {
          ...state.data,
          tradeSettings: {
            ...state.data.tradeSettings,
            indicators: state.data?.tradeSettings?.indicators?.map((indicator) => {
              if (indicator.id === payload.id) {
                return { ...indicator, ...payload };
              }
              return indicator;
            })
          }
        }
      };

    case REMOVE_CREATED_INDICATOR:
      return {
        ...state,
        data: {
          ...state.data,
          tradeSettings: {
            ...state.data.tradeSettings,
            indicators: [...(state?.data?.tradeSettings?.indicators || [])]?.filter((indicator) => indicator.id !== payload)
          }
        }
      };

    case UPDATE_BOT_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload
        }
      };

      case UPDATE_BOT_CONFIG:
      return {
          ...state,
          data:{
            ...state.data,
            ...payload.data.item
          }
        };

    default:
      return state;
  }
};

export const wizard = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_TICKER_FILTER:
      return {
        ...state,
        data: {
          filter: payload.state,
          assets: state.data.assets.map((item) => {
            if (payload.state === "custom") return { ...item, enabled: false };
            return { ...item, enabled: true };
          })
        }
      };

    case TOGGLE_TICKER_ITEM:
      return {
        ...state,
        data: {
          ...state.data,
          assets: state.data.assets.map((item) => {
            if (item.asset === payload.asset) {
              return { ...item, enabled: !payload.enabled };
            }
            return item;
          })
        }
      };

    default:
      return state;
  }
};

const backtests = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_BACKTEST:
      return {
        ...state,
        data: {
          ...state.data,
          items: [...state.data.items, payload]
        }
      };

    default:
      return state;
  }
};

const geneticWidget = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case RESET_GENETIC:
      return {
        ...state,
        data: {}
      };

    default:
      return state;
  }
};

const geneticAlgorithm = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_GENETIC_ALGORITHM:
      return {
        ...state,
        data: {
          ...state.data,
          bot: {
            ...state.data.bot,
            ...payload
          }
        }
      };

    default:
      return state;
  }
};

const geneticIndividuals = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_GENETIC_INDIVIDUALS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload
      };

    case GET_GENETIC_INDIVIDUALS_LOADING:
      return {
        ...initialState,
        data: {
          metadata: state?.data?.metadata
        },
        loading: true
      };

    case GET_GENETIC_INDIVIDUALS_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: payload
      };

    default:
      return state;
  }
};

const botMarkets = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENT_BOT:
      return {
        ...state,
        botId: payload
      };

    default:
      return state;
  }
};

const indicatorsSignals = (state = initialState, action) => {
  const {type} = action;

  switch (type) {
      case RESET_INDICATORS_SIGNALS:
        return {
          ...state,
          data: {}
        };

        default:
          return state

  }
};

export default combineReducers({
  list: withDataLoading(list, "BOTS"),
  item: withDataLoading(item, "BOT"),
  backtests: withDataLoading(backtests, "BACKTESTS"),
  dailyIncomeChart: withDataLoading(null, "DAILY_INCOME_CHART"),
  wizard: withDataLoading(wizard, "BOT_TICKERS"),
  geneticWidget: withDataLoading(geneticWidget, "GENETIC_STATISTIC"),
  geneticAlgorithm: withDataLoading(geneticAlgorithm, "GENETIC_ALGORITHM"),
  botMarkets: withDataLoading(botMarkets, "BOT_MARKETS"),
  tradeSettings: withDataLoading(null, "TRADE_SETTINGS"),
  geneticStrategies: withDataLoading(null, "GENETIC_STRATEGIES"),
  geneticStrategiesTable: withDataLoading(null, "GENETIC_STRATEGIES_TABLE"),
  geneticIndividuals,
  technicalAnalysis: withDataLoading(null, "TECHNICAL_ANALYSIS"),
  indicatorsSignals: withDataLoading(indicatorsSignals, "INDICATORS_SIGNALS"),
  technicalAnalysisMarket: withDataLoading(null, "TECHNICAL_ANALYSIS_MARKET"),
  botStrategies: withDataLoading(null, "BOT_STRATEGIES"),
  botStrategy: withDataLoading(null, "BOT_STRATEGY"),
});
