import React from "react";
import {Card, Col, Row} from "reactstrap";
import SkeletonPreloader from "components/SkeletonPreloader";
import _ from "lodash";

const MarketInformationPreloader = () => {
  return (
    <Card className="p-4 mb-4">
      <div className="d-flex align-items-center">
        <SkeletonPreloader
          circle
          height={24}
          width={24}
          className="mr-2"
        />
        <SkeletonPreloader
          height={16}
          width={86}
        />
      </div>

      <Row className="no-gutters mt-4">
        {
          _.times(3, (e) =>
            <Col md="4" className="d-flex flex-column" key={e}>
              <SkeletonPreloader
                height={14}
                width={32}
                className="mb-2"
              />
              <SkeletonPreloader
                height={16}
                width={86}
              />
            </Col>
          )}
      </Row>
      <Row className="no-gutters mt-4">
        {
          _.times(3, (e) =>
            <Col md="4" className="d-flex flex-column" key={e}>
              <SkeletonPreloader
                height={14}
                width={32}
                className="mb-2"
              />
              <SkeletonPreloader
                height={16}
                width={86}
              />
            </Col>
          )}
      </Row>
    </Card>
  )
}

export default MarketInformationPreloader;