import React, {useState} from "react";
import {Progress} from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { addSnackBar } from "store/snackbar/actions";
import { createBotDeal } from "store/deals/actions";
import Button from "components/Button";
import MarketBotsTooltips from "components/MarketBots/MarketBotsTooltips";
import MultiProgress from "components/MultiProgress";
import Icon from "components/Icon";
import BotsListToggle from "components/BotsListToggle";

const BotsTableRow = ({item, deals, quote, base}) => {

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleCreateDeal = async (event, id) => {
    event.stopPropagation();
    setDisabled(true);
    setLoading(true);
    const res = await dispatch(createBotDeal(id, quote, base));
    if (res?.errors) {
      dispatch(addSnackBar("error", res.message));
    } else{
      dispatch(addSnackBar("success", res.message));
    }
    setDisabled(false);
    setLoading(false);
  };

  return (
      <tr>
        <td className="pr-0">
          <BotsListToggle
            status={item?.status}
            id={item?.id}
            isList
          />
        </td>

        <td className="pl-0">
          <div className="d-flex align-items-center">
            <div className="mr-3 d-flex align-items-center">
              <span className="d-inline-block mr-2">
                <Icon
                  size="thumb"
                  code={`${item?.type}-bot`}
                  type="icons"
                />
              </span>
              <p className="mb-0 text-white">
                {item.name}
              </p>
            </div>
            <Link
              className="text-info"
              to={`/bots/${item?.id}/configure`}
            >
              Edit
            </Link>
          </div>
        </td>

        <td>
          <p className="mb-0 text-white">
            {item?.marketsCount}
          </p>
        </td>

        <td>
          {
              (item?.statistics?.profit.percent && item?.statistics?.loss.percent) ?
            <>
              <MultiProgress
                height="12px"
                id={`multi-${item.id}`}
              >
                <Progress
                  bar
                  className="text-success"
                  color="success"
                  value={+item?.statistics?.profit.percent}
                />
                <Progress
                  bar
                  className="text-danger"
                  color="danger"
                  value={+item?.statistics?.loss.percent}
                />
              </MultiProgress>
              <MarketBotsTooltips
                index={item.id}
                loss={item.statistics.loss}
                profit={item.statistics.profit}
              />
            </>
            :
            <p className="mb-0 text-white-35">
              No deals yet
            </p>
          }
        </td>

        <td>
          <div className="d-flex align-items-center">
            <p className={`mb-0 ${item?.totalReturn ? "text-white" : "text-white-35"}`}>
              {item?.totalReturn ? item?.totalReturn : 0}
            </p>
            <p className="ml-1 mb-0 text-white-35">{quote}</p>
          </div>
        </td>

        <td>
          <p className="mb-0 text-white">
            {item?.period} min
          </p>
        </td>

        <td>
          <p className="mb-0 text-white">
            {item?.runningDeals}
          </p>
        </td>

        <td>
          {
            deals?.some(deal => deal.bot.id === item.id) ? (
              deals.map(deal => {
                return deal.bot.id === item.id && (
                  <div className="d-flex align-items-center justify-content-end">
                    <Link
                      key={deal.id}
                      to={`/deals/${deal.id}`}
                      className="text-info"
                    >
                      View running deal
                    </Link>
                  </div>
                );
              })
            ) : (
              <div className="d-flex align-items-center justify-content-end">
                <Button
                  color="blue"
                  className="px-2 py-3"
                  size="big"
                  loading={loading}
                  disabled={disabled}
                  onClick={(e) => handleCreateDeal(e, item.id)}
                >
                  Force run a deal
                </Button>
              </div>
            )
          }
        </td>
    </tr>
  )
}

export default BotsTableRow;
