import { get } from "lodash";
import { GET_CONFIGURATIONS } from "./constants";

export const getIndicatorsConfiguration = () => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.get("app/indicators/configuration");
    dispatch({ type: GET_CONFIGURATIONS, payload: res.data });
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};
