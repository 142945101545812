import React from "react";
import {
  Modal,
  ModalBody
} from "reactstrap";
import styled from "styled-components";

import {ReactComponent as SetttingsIcon} from "assets/img/icons/setting-4.svg";
import GAsettingsForm from "../forms/GAsettingsForm";
import Button from "components/Button";
import CustomScrollbars from "components/CustomScrollbars";

const EditGAModal = ({ setIsModalOpen, isModalOpen, geneticId, bot, criterion }) => {
  return (
    <EditGAModal.Modal
      isOpen={isModalOpen}
      toggle={() => setIsModalOpen(!isModalOpen)}
      modalClassName="modal-black"
    >
      <CustomScrollbars
        autoHide
        autoHideTimeout={500}
        autoHideDuration={200}
        autoHeight
        autoHeightMax={690}
        trackVerticalProps={{
          className: "py-3"
        }}
      >
        <ModalBody className="p-4">
          <div className="d-flex justify-content-between mb-3">
            <div className="d-flex">
              <SetttingsIcon className="mr-2 my-auto" />
              <p className="my-auto h2 font-weight-500">
                Edit Genetic algorithm
              </p>
            </div>
            <Button
              onClick={() => setIsModalOpen(false)}
              cancel
            />
          </div>
          <GAsettingsForm
            setIsModalOpen={setIsModalOpen}
            geneticId={geneticId}
            criterion={criterion}
            bot={bot}
          />
        </ModalBody>
      </CustomScrollbars>
    </EditGAModal.Modal>
  );
};

EditGAModal.Modal = styled(Modal)`
  max-width: 840px !important;
  height: 690px !important;

  .modal-content {
    border-radius: 1rem !important;
    height: 690px !important;
  }

  @media (max-width: 576px) {
    max-width: 100% !important;
  }
`;

export default EditGAModal;
