import React from "react";

import DealOpenTradeTitle from "./ui/DealOpenTradeTitle";
import DealOpenTradeHeader from "./ui/DealOpenTradeHeader";
import DealOpenTradeBody from "./ui/DealOpenTradeBody";
import { OpenOrder } from "shared/ui";

import { DealOpenTradeWrapStyled } from "../Deal/index.styles";

const DealOpenTrade = ({ data, setIsModalOpen }) => {
  const isHaveOpenOrder = data?.openOrderRequest
  if (!data) return
  return (
    <DealOpenTradeWrapStyled
      data-testid="deal-open-trade-wrap"
      className="position-relative"
      onClick={() => setIsModalOpen(true)}
    >
      <DealOpenTradeTitle />
      <DealOpenTradeHeader data={data} />
      <DealOpenTradeBody data={data} isHaveOpenOrder={isHaveOpenOrder} />
      <OpenOrder
        className="px-0"
        openOrderRequest={data?.openOrderRequest}
      />
    </DealOpenTradeWrapStyled>
  );
};

export default DealOpenTrade;