import React from "react";

import Loading from "components/Loading";

const LoadingIndicator = () => (
  <Loading
    size={16}
    color="var(--info)"
  />
);

export default LoadingIndicator;
