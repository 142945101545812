import React from "react";
import PropTypes from "prop-types";

import useExchangeWS from "exchanges/useExchangeWS";
import TradingWithTicker from "./TradingWithTicker";

const MarketChart = ({
  exchange,
  base,
  quote,
  loadingPage
}) => {
  const {
    trades,
    ticker,
  } = useExchangeWS(exchange, ["orderBook", "ticker", "trades"], [`${base}_${quote}`]);

  return (
    <TradingWithTicker
      exchange={exchange}
      ticker={ticker[0]}
      loadingPage={loadingPage}
      isTradesLoading={trades.isLoading}
      base={base}
      quote={quote}
    />
  )
};

MarketChart.propTypes = {
  exchange: PropTypes.string,
  base: PropTypes.string,
  quote: PropTypes.string,
  loadingPage: PropTypes.bool
};

export default MarketChart;
