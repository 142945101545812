import React from "react";
import {useHistory} from "react-router-dom";
import {formatterToNull} from "helpers/valuesFormatters";
import {BACKTEST_STATUS_COLORS} from "models/bot/enums";
import styled from "styled-components";
import moment from "moment/moment";
import PercentBadge from "components/PercentBadge";

const BacktestTableRow = ({item}) => {
  const history = useHistory();

  const handleClick = (event, id) => {
    if (event.button === 0) history.push(`/backtests/${id}`);
    if (event.button === 1) window.open(`/backtests/${id}`, "_blank");
  }

  return (
    <tr onMouseDown={(e) => handleClick(e, item.id)} className="cursor-pointer">
      <td>
        <BacktestTableRow.Status color={BACKTEST_STATUS_COLORS[item.status]}>
          {item.status}
        </BacktestTableRow.Status>
      </td>

      <td>
        <div className="d-flex align-items-center text-nowrap">
          <p className="mb-0 text-white font-weight-500">
            {item.market.base}
          </p>
          <p className="mb-0 ml-1 text-white-35 font-weight-300">
            {`/ ${item.market.quote}`}
          </p>
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center">
          <p className={`mb-0 font-weight-500 ${item.balance?.initial?.amount > 0 ? "text-white" : "text-white-35"}`}>
            {formatterToNull(item.balance?.initial?.amount)}
          </p>
          <p className="mb-0 ml-1 text-white-35 font-weight-300">
            {item.balance?.initial?.asset}
          </p>
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center">
          <p className={`mb-0 font-weight-500 ${item.balance?.final?.amount > 0 ? "text-white" : "text-white-35"}`}>
            {formatterToNull(item.balance?.final?.amount)}
          </p>
          <p className="mb-0 ml-1 text-white-35 font-weight-300">
            {item.balance?.final?.asset}
          </p>
        </div>
      </td>

      <td>
        <PercentBadge value={item.balance?.final?.percent} showZeroValue/>
      </td>

      <td>
        <p className="mb-0 text-white-35 text-nowrap">
          {moment(item.history[0].start).format("DD.MM.YY HH:mm")}
        </p>
      </td>
    </tr>
  )
}

BacktestTableRow.Status = styled.p`
  width: fit-content;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 6px;
  padding: 3.5px 10px;
  text-transform: capitalize;
  color: ${(props) => props.color} !important;
  margin-bottom: 0;
`;

export default BacktestTableRow;