import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactBSAlert from "react-bootstrap-sweetalert";
import styled from "styled-components";
import _ from "lodash";

import Indicator from "./Indicator";
import SkeletonPreloader from "components/SkeletonPreloader";
import { detachIndicator } from "store/strategies/actions";

const IndicatorsList = ({
  indicators=[],
  detachIndicator,
  editable,
  canRemove,
  strategyId,
  loading,
  onSetSuccessMessage
}) => {
  const [resModal, setResModal] = useState("");

  const handleDelete = async (strategyId, indicatorId) => {
    const err = await detachIndicator(strategyId, indicatorId);
    if (err) {
      setResModal(
        <ReactBSAlert
          warning
          title={err.message}
          onConfirm={() => setResModal(null)}
          confirmBtnBsStyle="warning"
          btnSize=""
        />
      );
    } else {
      setResModal(
        <ReactBSAlert
          success
          title="Deleted!"
          onConfirm={() => setResModal(null)}
          confirmBtnBsStyle="success"
          btnSize=""
        />
      );
    }
  };

  return(
    <>
      {
        loading ?
        _.times(7, (e) =>
          <IndicatorsList.ButtonPreLoader
            key={e}
            className="d-flex p-2 mt-3 mb-3"
          >
            <SkeletonPreloader
              width={20}
              height={20}
              circle={true}
              className="my-auto"
            />
            <SkeletonPreloader
              width={37}
              height={22}
              borderRadius="4px"
              className="ml-1 mr-2 my-auto"
            />
            <SkeletonPreloader
              height={12}
              className="my-auto"
            />
          </IndicatorsList.ButtonPreLoader>
        )
        :
        indicators.map((item) =>
          <Indicator
            key={item.uuid}
            strategyId={strategyId}
            indicator={item.indicator ? item.indicator : item}
            editable={editable}
            canRemove={canRemove}
            handleDelete={handleDelete}
            onSetSuccessMessage={onSetSuccessMessage}
          />
        )
      }
      {resModal}
    </>
  );
};

IndicatorsList.ButtonPreLoader = styled.div`
  background: rgba(83, 84, 98, 0.2);
  border-radius: 4px;
`;

const mapDispatchToProps = (dispatch) => bindActionCreators({
  detachIndicator
}, dispatch);

export default connect(null, mapDispatchToProps)(IndicatorsList);
