import React from "react";
import { Card } from "reactstrap";
import TablePreloader from "components/TablePreloader";

const MarketStatisticsPreloader = () => {
  return (
    <Card className="mb-0 pt-3 overflow-hidden">
      <TablePreloader
        thHeight={12}
        thWidth={[77, 48, 35, 77, 37, 44, 62, 52, 44, 56, 62]}
        tdWidth={[71, 144, 75, 144, 75, 117, 129, 105, 66, 94,66]}
        tdHeight={12}
        rowTimes={10}
      />
    </Card>
  )
}

export default MarketStatisticsPreloader;
