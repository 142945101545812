import React from "react";
import { Link } from "react-router-dom";
import {
  CardHeader,
  FormGroup
} from "reactstrap";

import { ReactComponent as Rocket } from "assets/img/icons/rocket-outline.svg";
import SelectInput from "components/SelectInput";
import { STATUS_RUNNING_OPTIONS } from "models/bot/filters";
import BotMarketsSelect from "components/BotMarketsSelect";

const RunningDealsHeader = ({ 
  handleFilter,
  loading,
  loadingPage,
  botId,
  request,
}) => (
    <CardHeader
      className="row no-gutters flex-column flex-sm-row align-items-center justify-content-between py-3 pr-3 pl-4">

      <div className="d-flex align-items-center col-12 col-sm-6">
        <div>
          <Rocket />
        </div>
        <p className="mb-0 ml-2 text-white font-weight-500 text-nowrap">
          Running deals
        </p>
        <Link className="mb-0 ml-3 link-info text-nowrap" to="/deals">
          Show all
        </Link>
      </div>

      <div className="d-flex align-items-center flex-column flex-sm-row mt-sm-0 col-12 col-sm-6">
        <FormGroup className="w-100 pr-0 pr-sm-2 my-2 my-md-0">
          <SelectInput
            isClearable
            placeholder="Status"
            name="status"
            width="100%"
            onChange={(option) => handleFilter({
              status: option ? { eq: option?.value } : { in: ["ready", "running"] }
            })}
            options={STATUS_RUNNING_OPTIONS}
            isDisabled={loading || loadingPage}
          />
        </FormGroup>

        <FormGroup className="w-100 pr-0 mb-0">
          <BotMarketsSelect
            isWaiting
            isMulti
            isClearable
            id={botId}
            placeholder="Market"
            name="market"
            isDisabled={loading || loadingPage}
            onChange={(option) => handleFilter({
              market: { in: option ? option.map((e) => e.value) : "" }
            })}
            value={request?.filter?.market?.in?.map((item) => ({
              label: item,
              value: item,
              icon: {
                type: "cryptocurrency",
                size: "selectSize",
                code: item,
              }
            }))}
          />
        </FormGroup>
      </div>
    </CardHeader>
  );

export default RunningDealsHeader;
