import React from "react";
import styled from "styled-components";
import moment from "moment";
import { capitalize } from "lodash";
import { formatterToNull } from "helpers/valuesFormatters";

const HeaderOrderModal = ({currentOrder}) => {
  return (
    <div className="d-flex align-items-center justify-content-between">

      <HeaderOrderModal.Column>
        <HeaderOrderModal.Title>Side</HeaderOrderModal.Title>
        <HeaderOrderModal.Type
          className="font-sm"
          type={currentOrder.type}
        >
          {currentOrder.type}
        </HeaderOrderModal.Type>
      </HeaderOrderModal.Column>

      <HeaderOrderModal.Column marginX={true}>
        <HeaderOrderModal.Title>Market</HeaderOrderModal.Title>
        <div className="d-flex align-items-center text-nowrap font-sm">
          <HeaderOrderModal.Amount amount={1}>{currentOrder.market.base}</HeaderOrderModal.Amount>
          <HeaderOrderModal.Title className="mx-1">/</HeaderOrderModal.Title>
          <HeaderOrderModal.Amount amount={1}>{currentOrder.market.quote}</HeaderOrderModal.Amount>
        </div>
      </HeaderOrderModal.Column>

      <HeaderOrderModal.Column>
        <HeaderOrderModal.Title>Starting amount</HeaderOrderModal.Title>
        <div className="d-flex align-items-center text-nowrap font-sm">
          <HeaderOrderModal.Amount amount={currentOrder.amount.amount}>{currentOrder.amount.amount}</HeaderOrderModal.Amount>
          <HeaderOrderModal.Title className="ml-1">{currentOrder.amount.currency}</HeaderOrderModal.Title>
        </div>
      </HeaderOrderModal.Column>

      <HeaderOrderModal.Column marginX={true}>
        <HeaderOrderModal.Title>Price</HeaderOrderModal.Title>
        <HeaderOrderModal.Amount
          amount={currentOrder.rate}
          className="font-sm"
        >
          {currentOrder.rate}
        </HeaderOrderModal.Amount>
      </HeaderOrderModal.Column>

      <HeaderOrderModal.Column>
        <HeaderOrderModal.Title>Quantity</HeaderOrderModal.Title>
        <div className="d-flex align-items-center text-nowrap font-sm">
          <HeaderOrderModal.Amount amount={currentOrder.quantity.amount}>{currentOrder.quantity.amount}</HeaderOrderModal.Amount>
          <HeaderOrderModal.Title className="ml-1">{currentOrder.quantity.currency}</HeaderOrderModal.Title>
        </div>
      </HeaderOrderModal.Column>

      <HeaderOrderModal.Column marginX={true}>
        <HeaderOrderModal.Title>Amount</HeaderOrderModal.Title>
        <div className="d-flex align-items-center text-nowrap font-sm">
          <HeaderOrderModal.Amount amount={currentOrder.amount.amount}>{currentOrder.amount.amount}</HeaderOrderModal.Amount>
          <HeaderOrderModal.Title className="ml-1">{currentOrder.amount.currency}</HeaderOrderModal.Title>
        </div>
      </HeaderOrderModal.Column>

      <HeaderOrderModal.Column>
        <HeaderOrderModal.Title>Trades</HeaderOrderModal.Title>
        <HeaderOrderModal.Amount
          amount={currentOrder.tradesCount}
          className="font-sm"
        >
          {formatterToNull(currentOrder.tradesCount)}
        </HeaderOrderModal.Amount>
      </HeaderOrderModal.Column>

      <HeaderOrderModal.Column marginX={true}>
        <HeaderOrderModal.Title>Status</HeaderOrderModal.Title>
        <HeaderOrderModal.Status
          status={currentOrder.status}
          className="font-sm"
        >
          {capitalize(currentOrder.status)}
        </HeaderOrderModal.Status>
      </HeaderOrderModal.Column>

      <HeaderOrderModal.Column>
        <HeaderOrderModal.Title>Time</HeaderOrderModal.Title>
        <HeaderOrderModal.Amount
          className="font-sm"
          amount={1}
        >
          {moment(currentOrder.createdAt).format("DD.MM.YY HH:mm")}
        </HeaderOrderModal.Amount>
      </HeaderOrderModal.Column>

    </div>
  )
}

HeaderOrderModal.Column = styled.div`
  margin: ${(props) => props.marginX ? "0 30px" : 0 };
`;

HeaderOrderModal.Title = styled.p`
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.35) !important;
  font-weight: 300;
  white-space: nowrap;
`;

HeaderOrderModal.Type = styled.p`
  margin-bottom: 0;
  color: ${(props) => (props.type === "SELL" || props.type === "sell") ? "#EA4848" : "#3FCBB0" } !important;
  font-weight: 500;
`;

HeaderOrderModal.Amount = styled.p`
  margin-bottom: 0;
  color: ${(props) => props.amount > 0 ? "rgba(255, 255, 255)" : "rgba(255, 255, 255, 0.35)"} !important;
  font-weight: 500;
`;

HeaderOrderModal.Status = styled.p`
  margin-bottom: 0;
  font-weight: 500;
  color: ${(props) => {
    if (props.status === "completed") {return "rgba(95, 211, 188, 1)"}
    if (props.status === "open") {return "rgba(47, 128, 237, 1)"}
    return "rgba(255, 255, 255, 0.35)";
  }} !important;

`;

export default HeaderOrderModal;
