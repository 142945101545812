import React from "react";

import { formatterToNull } from "helpers/valuesFormatters";

const VolumesViewStatus = ({
  avgBuyRate,
  receivedAmount,
  receivedAsset,
  soldAmount,
  soldAsset
}) => {
  return (
    <>
      {
        +avgBuyRate ?
        <div className="d-flex flex-column flex-sm-row flex-md-column flex-lg-row align-items-lg-center justify-content-between mt-2">
          <p className="mb-0 text-white-35 font-sm">
            Average price
          </p>
          <p className="mb-0 text-white font-weight-500">
            {formatterToNull(avgBuyRate)}
          </p>
        </div> : null
      }
      {
        +receivedAmount && receivedAsset ?
        <div className="d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between mt-2 align-items-lg-center">
          <p className="font-sm mb-0 text-white">Received</p>
          <div className="d-flex align-items-center">
            <p className="mb-0 text-white font-weight-500">
              {receivedAmount}
            </p>
            <p className="mb-0 text-white-35 font-weight-300 ml-2">
              {receivedAsset}
            </p>
          </div>
        </div> : null
      }
      {
        +soldAmount && soldAsset ?
        <div className="d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between align-items-lg-center mt-2">
          <p className="font-sm mb-0 text-white-35 font-weight-300">Sold</p>
          <div className="d-flex align-items-center">
            <p className="mb-0 text-white font-weight-500">
              {formatterToNull(soldAmount)}
            </p>
            <p className="mb-0 text-white-35 font-weight-300 ml-2">
              {soldAsset}
            </p>
          </div>
        </div> : null
      }
    </>
  );
};

export default VolumesViewStatus;
