import React from "react";
import PropTypes from "prop-types";

import { 
  formatValue, 
  formatterToNull 
} from "helpers/valuesFormatters";
import StatusIndicator from "components/StatusIndicator";

const SignalTableRow = ({
  target,
  buyFrom,
  percent,
  completedAt,
  status
}) => {
  return (
    <tr>
      <td>
        <div className="d-flex align-items-center text-nowrap">
          <p className="mb-0 text-white font-weight-500">
            {target}
          </p>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center text-nowrap">
          <p className="mb-0 text-white font-weight-500">
            {formatterToNull(buyFrom)}
          </p>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center text-nowrap">
          <p className="mb-0 text-white font-weight-500">
            {formatValue(percent.toFixed(2))} %
          </p>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center text-nowrap">
          <p className="mb-0 text-white font-weight-500">
            {completedAt || "-"}
          </p>
        </div>
      </td>
      <td width={85}>
        <StatusIndicator
          status={status}
          margin="0"
        />
      </td>
    </tr>
  );
};

SignalTableRow.propTypes = {
  target: PropTypes.string,
  buyFrom: PropTypes.number,
  percent: PropTypes.number,
  completedAt: PropTypes.string,
  status: PropTypes.string
};

export default SignalTableRow;