import React, {useState} from "react";
import Button from "components/Button";
import {
  FormGroup,
  Label,
} from "reactstrap";
import {
  Form,
  Formik,
  Field
} from "formik";
import { handleResponse } from "helpers/errorsValidator";
import {ReactComponent as RequiredIcon} from "assets/img/icons/icon-required.svg";
import classNames from "classnames";
import ErrorAlert from "components/ErrorAlert";
import {addSnackBar} from "store/snackbar/actions";
import {useDispatch} from "react-redux";
import {
  PASSWORD_FIELDS,
  handlePasswordSchema,
  handleInitPasswordValues
} from "../compounds/userConfiguration";

const ChangePasswordForm = ({ onChangePassword }) => {
  const initialValues = handleInitPasswordValues()

  const [submitted, setSubmitted] = useState(false);

  const dispatch = useDispatch()

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={handlePasswordSchema}
      onSubmit={async(values, formik) => {
        const res = await dispatch(onChangePassword(values));
        formik.setSubmitting(true);
        if (res.errors) {
          await handleResponse(res, formik.setErrors);
        } else {
          formik.setStatus({ message: res.message });
          dispatch(addSnackBar("success", res.message))
        }
        formik.setSubmitting(false);
      }}
    >
      {({ errors, isSubmitting, values, setErrors }) => (
        <Form>
          <h4 className="text-white font-weight-bold mb-4">Change password</h4>
          {
            PASSWORD_FIELDS.map((input) =>
              <FormGroup className="mb-3" key={input.name}>
                <div className="d-flex flex-row align-items-center mb-2">
                  <Label className="mb-0 mr-1 font-sm text-white-75">{input.label}</Label>
                  {
                    submitted && errors[input.name] && <RequiredIcon/>
                  }
                </div>
                <Field
                  className={classNames("form-control mb-1", {
                    "has-error": submitted && errors[input.name]
                  })}
                  type="password"
                  value={values[input.name]}
                  name={input.name}
                  placeholder={input.placeholder}
                />
                {
                  submitted && errors[input.name] &&
                  <p className="mb-0 text-danger font-sm font-weight-300">
                    {errors[input.name]}
                  </p>
                }
              </FormGroup>
            )
          }
          <ErrorAlert
            fade={false}
            className="mb-4 font-sm"
            toggle={() => setErrors({ ...errors, current: "", password: "", verify: "" })}
            isOpen={submitted && (errors.current || errors.password || errors.verify)}
          >
            Please check if you filled up all the fields and try again
          </ErrorAlert>
          <Button
            color="blue"
            loading={isSubmitting}
            className="pull-right"
            onClick={() => setSubmitted(true)}
            type="submit"
          >
            Change
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
