import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Card
} from "reactstrap";
import { capitalize } from "lodash";
import { ReactComponent as MoneyReciveIcon } from "assets/img/icons/moneyrecive.svg";
import { formatValue, setColorClass } from "helpers/valuesFormatters";

const Profit = ({data}) => {
  const [selected, setSelected] = useState("all");
  const allPeriods = Object.keys(data?.periods || {}).map((item) => item);

  useEffect(() => {
    const interval = setInterval(() => {
      if (selected === allPeriods[0]) {
        setSelected(allPeriods[1])
      }
      if (selected === allPeriods[1]) {
        setSelected(allPeriods[2])
      }
      if (selected === allPeriods[2]) {
        setSelected(allPeriods[3])
      }
      if (selected === allPeriods[3]) {
        setSelected(allPeriods[4])
      }
      if (selected === allPeriods[4]) {
        setSelected(allPeriods[0])
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [selected, allPeriods]);

  const getPeriod = () => {
    if (selected === "year") return "Annual profit";
    if (selected === "all") return capitalize(selected) + " time";
    return capitalize(selected) + " profit";
  };

  return(
    <Profit.Card>
      <Profit.FixedCardBody className="d-flex flex-column justify-content-center py-4 pr-3 pl-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <MoneyReciveIcon className="mr-3" />
            <div className="d-flex flex-column">
              <Profit.Title className="font-sm">
                {
                  data?.periods && (data?.periods[selected] || data?.periods[selected] === 0)
                  ? getPeriod()
                  : "Annual profit"
                }
              </Profit.Title>
              <div className="d-flex align-items-center">
                <Profit.Amount className={`h3 m-0 ${setColorClass(data?.periods[selected], "text-white-35")}`}>
                  {formatValue(data?.periods[selected]) || "+ 0.00"}{" "}
                  <Profit.Currency className="h3 m-0">
                    {data?.currency}
                  </Profit.Currency>
                </Profit.Amount>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column">
            {
              data?.periods && Object.keys(data?.periods).map((period) =>
                <Profit.SelectPoint
                  key={period}
                  onClick={() => setSelected(period)}
                  className={selected === period ? "active" : ""}
                />
              )
            }
          </div>
        </div>
      </Profit.FixedCardBody>
    </Profit.Card>
  );
};

Profit.Card = styled(Card)`
  border-radius: 8px !important;
  margin-bottom: 24px !important;
  padding: 0 !important;
`;

Profit.FixedCardBody = styled.div`
  height: 86px;

  .text-red {
    color: #EB5757;
  }
`;

Profit.SelectPoint = styled.span`
  background-color: rgba(255, 255, 255, 0.1);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  cursor: pointer;
  margin: 3px 0;

  &.active {
    background-color: var(--info);
    height: 12px;
    border-radius: 6px;
  }
`;

Profit.Title = styled.p`
  font-weight: 300;
  color: rgba(255, 255, 255, 0.75) !important;
  margin-bottom: 0;
`;

Profit.Amount = styled.p`
  font-weight: 500;
  margin-bottom: 0;
`;

Profit.Currency = styled.span`
  font-weight: 300;
  color: rgba(255, 255, 255, 0.35) !important;
  margin: 0 0 0 4px;
`;

export default Profit;
