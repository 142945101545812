import React from "react";
import styled from "styled-components";
import { Card } from "reactstrap";

import { formatterToNull } from "helpers/valuesFormatters";

const OpenOrdersWidget = ({totalOrders, openOrders}) => {

  return (
    <>
      <OpenOrdersWidget.OrdersWidgetWrapper>
        <div>
          <OpenOrdersWidget.Value>Total orders</OpenOrdersWidget.Value>
          <OpenOrdersWidget.Amount total={totalOrders}>{formatterToNull(totalOrders)}</OpenOrdersWidget.Amount>
        </div>
      </OpenOrdersWidget.OrdersWidgetWrapper>
      <OpenOrdersWidget.OrdersWidgetWrapper className="ml-2">
        <div>
          <OpenOrdersWidget.Value>Open orders</OpenOrdersWidget.Value>
          <OpenOrdersWidget.Amount total={openOrders}>{formatterToNull(openOrders)}</OpenOrdersWidget.Amount>
        </div>
      </OpenOrdersWidget.OrdersWidgetWrapper>
    </>
  )
}

OpenOrdersWidget.OrdersWidgetWrapper = styled(Card)`
  border-radius: 8px !important;
  padding: 0.75rem 1rem;
  svg {
    path {
      stroke: var(--info);
    }
  }
`;

OpenOrdersWidget.Orders = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 7px;
  padding: 0.75rem 1rem;
  margin: 0 4px;
`;

OpenOrdersWidget.Title = styled.p`
  margin: 0 0 0 8px;
  color: #FFFFFF !important;
  font-weight: 500;
  font-size: 16px;
`;

OpenOrdersWidget.Value = styled.p`
  color: var(--white-75, rgba(255, 255, 255, 0.75)) !important;
  font-weight: 300;
  font-size: 0.875rem;
`;

OpenOrdersWidget.Amount = styled.p`
  color: ${(props) => props.total > 0 ? "#FFFFFF" : "rgba(255, 255, 255, 0.35)"} !important;
  font-weight: 700;
  font-size: 19px;
  margin-bottom: 0;
`;


export default OpenOrdersWidget;
