import React from "react";

import { formatterToNull } from "helpers/valuesFormatters";
import StyledValue from "./StyledValue";

const BotWidgetCustom = ({ data={} }) => {
  if (!data.maxTradeRequests && !data.deviation && !data.martingalePercent) return null;

  return (
    <>
      <p className="mb-0 text-white font-weight-500 mt-4">
        Average down strategy
      </p>
      {
        data.maxTradeRequests ?
        <div className="d-flex flex-md-column flex-lg-row justify-content-between mt-2 text-nowrap">
          <p className="mb-0 text-white-75 font-weight-300 font-sm">
            Maximum trade requests
          </p>
          <StyledValue amount={data.maxTradeRequests}>
            {formatterToNull(data.maxTradeRequests)}
          </StyledValue>
        </div> : null
      }
      {
        data.deviation ?
        <div className="d-flex flex-md-column flex-lg-row justify-content-between mt-2 text-nowrap">
          <p className="mb-0 text-white-75 font-weight-300 font-sm">
            Price deviation
          </p>
          <StyledValue amount={data.deviation}>
            {`${formatterToNull(data.deviation)} %`}
          </StyledValue>
        </div> : null
      }
      {
        data.martingalePercent ?
        <div className="d-flex flex-md-column flex-lg-row justify-content-between mt-2 text-nowrap">
          <p className="mb-0 text-white-75 font-weight-300 font-sm">
            Martingale percent
          </p>
          <StyledValue amount={data.martingalePercent}>
            {`${formatterToNull(data.martingalePercent)} %`}
          </StyledValue>
        </div> : null
      }
    </>
  );
};

export default BotWidgetCustom;
