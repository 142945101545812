import React from "react";
import SkeletonPreloader from "components/SkeletonPreloader";

const HeaderPreloader = () => {
  return (
    <div className="d-flex justify-content-between p-4">
      <div className="d-flex align-items-center">
        <SkeletonPreloader
          circle={true}
          width={58}
          height={58}
          className="mr-4"
        />
        <div className="d-flex flex-column">
          <SkeletonPreloader
            width={110}
            height={20}
          />
          <SkeletonPreloader
            width={273}
            height={15}
            className="mt-3"
          />
        </div>
      </div>
      <div className="d-flex flex-column">
        <SkeletonPreloader
          width={90}
          height={24}
          className="mb-1"
        />
        <SkeletonPreloader
          width={94}
          height={10}
          className="mt-3"
        />
      </div>
    </div>
  )
}

export default HeaderPreloader;
