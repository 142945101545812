import React, { useState } from "react";
import { Label } from "reactstrap";
import {
  Formik,
  Field,
  Form
} from "formik";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom";

import Button from "components/Button";
import ErrorAlert from "components/ErrorAlert";
import { createChannel } from "store/channels/actions";
import { addSnackBar } from "store/snackbar/actions";
import ChannelLogoForm from "./ui/ChannelLogoForm";
import FormGroupWithSymbols from "components/FormGroupWithSymbols";
import { SCHEMA_CREATE_CHANNEL } from "../../validSchema";
import { renderDesc } from "shared/helpers/renderDesc";
import InfoNotification from "shared/ui/InfoNotification";

const CreateChannelForm = ({ setIsModalOpen, content }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const { location } = useHistory();

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    const initialLimit = location.pathname === "/dashboard" ? 1 : 9;

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("type", "public");
    formData.append("price", 10.1);
    formData.append("currency", "USDT");
    formData.append("telegram", values.telegram);
    formData.append("file", values.file, values.file.name);

    const res = await dispatch(createChannel(formData, initialLimit));
    if (!res?.errors) {
      setIsModalOpen(false);
      dispatch(addSnackBar("success", res.message));
      return;
    }
    setErrorMessage(res.message);
    setErrors(res.message);
    setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: "",
        description: "",
        file: ""
      }}
      validationSchema={SCHEMA_CREATE_CHANNEL(content)}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        isSubmitting
      }) =>
        <Form
          className="w-100"
          autoComplete="off"
          data-testid="channel-create-form"
        >
          <div className="mb-3">
            <Label
              className="text-white-75 mb-1"
              data-testid="channel-create-label-name"
            >
              {content.fieldName.label}
            </Label>
            <Field
              data-testid="channel-create-form-field-name"
              placeholder={content.fieldName.placeholder}
              type="text"
              name="name"
              className={classnames("form-control", {
                "has-error": isSubmitted && errors.name
              })}
            />
            {
              isSubmitted && errors?.name &&
              <p className="text-danger mb-3 font-sm font-weight-300">{errors?.name}</p>
            }
          </div>
          <div className="mb-3">
            <Label
              className="text-white-75 mb-1"
              data-testid="channel-create-label-descriprion"
            >
              {content.fieldDescription.label}
            </Label>
            <Field
              data-testid="channel-create-field-descriprion"
              placeholder={content.fieldDescription.placeholder}
              name="description"
              as="textarea"
              className={classnames("form-control", {
                "has-error": isSubmitted && errors?.description
              })}
            />
            {
              isSubmitted && errors?.description &&
              <p className="text-danger mb-3 font-sm font-weight-300">{errors?.description}</p>
            }
          </div>
          <div className="mb-3">
            <Label
              className="text-white-75 mb-1"
              data-testid="channel-create-label-telegram"
            >
              {content.fieldTelegram.label}
            </Label>
            <FormGroupWithSymbols
              customPaddingLeft="5.7rem"
              symbolLeft="https://t.me/"
              className="font-sm text-white-35 font-weight-400"
            >
              <Field
                data-testid="channel-create-field-telegram"
                type="text"
                name="telegram"
                className={classnames("form-control", {
                  "has-error": isSubmitted && errors.telegram
                })}
              />
            </FormGroupWithSymbols>
            {
              isSubmitted && errors?.telegram &&
              <p className="text-danger mb-3 font-sm font-weight-300">{errors?.telegram}</p>
            }
            <InfoNotification
              text={renderDesc(content.fieldTelegram.informMsg)}
              className="mt-3"
            />
          </div>
          <ChannelLogoForm isSubmitted={isSubmitted} content={content.fieldFile} />
          <ErrorAlert
            toggle={() => setErrorMessage("")}
            isOpen={!!errorMessage}
            className="mt-3"
          >
            {errorMessage}
          </ErrorAlert>
          <div className="d-flex mt-4 w-100 justify-content-between">
            <Button
              color="gray"
              onClick={() => setIsModalOpen(false)}
              className="pull-right"
              data-testid="channel-create-cancel-button"
            >
              {content.buttons.cancel}
            </Button>
            <Button
              color="blue"
              loading={isSubmitting}
              disabled={isSubmitting}
              type="submit"
              className="pull-right"
              onClick={() => setIsSubmitted(true)}
              data-testid="channel-create-create-button"
            >
              {content.buttons.create}
            </Button>
          </div>
        </Form>
      }
    </Formik>
  );
};

CreateChannelForm.propTypes = {
  setIsModalOpen: PropTypes.func.isRequired
};

export default CreateChannelForm;
