import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Checkbox from 'components/Checkbox';
import useFilterAndSorting from 'hooks/useFilterAndSorting';
import { selectMeData } from 'store/user/selectors';

const OwnChannelFilter = ({...props}) => {
  const [isChecked, setIsChecked] = useState(false);
  const { id } = useSelector(selectMeData);
  const { handleFilter, resetFilter } = useFilterAndSorting("/channels");

  const handleIsChecked = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      handleFilter({
        owner: { eq: id }
      });
    } else {
      resetFilter();
    }
  };

  return (
    <div className="d-flex align-items-center">
      <Checkbox
        checked={isChecked}
        onChange={handleIsChecked}
        {...props}
      />
      <p className="mb-0 ml-2 font-weight-400 text-right">
        Owned by me
      </p>
    </div>
  );
};

export default OwnChannelFilter;