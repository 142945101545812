import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";

import ChannelHeader from "./ChannelHeader";
import ChannelFooter from "./ChannelFooter";
import StatisticsChannelBar from "./StatisticsChannelBar";
import ChannelStatisticsBody from "./ChannelStatisticsBody";
import ChannelsListPreload from "./ChannelsListPreload";
import ChannelSetSettings from "../../views/channels/modals/ChannelSetSettings";
import { formatterToNull } from "helpers/valuesFormatters";

const Channel = ({
  onSubscribeChannel,
  handleRefresh,
  loading,
  index,
  channel = {},
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleToggleModal = () => setIsModalOpen((prev) => !prev);

  if (loading) return <ChannelsListPreload/>;

  return (
    <>
      <Link
        to={`/channels/${channel?.id}`}
        key={channel?.id}
      >
        <Channel.StyledCard className="p-4 mb-4">
          <ChannelHeader
            name={channel?.name}
            totalSubscribers={channel?.totalSubscribers}
            owner={channel?.owner}
            logo={channel?.logos?.small}
            loading={loading}
          />

          <ChannelStatisticsBody
            average={channel?.profit?.average}
            total={channel?.profit?.total}
            month={channel?.profit?.month}
          />
          <div className="d-flex justify-content-between align-items-center mt-2">
            <p className="mt-1 font-sm font-weight-400 text-white mb-0">
              Total signals
            </p>
            <p className={`mb-0 font-sm font-weight-500 ${channel?.totalSignals ? "text-white" : "text-white-35"}`}>
              {formatterToNull(channel?.totalSignals)}
            </p>
          </div>
          <StatisticsChannelBar
            loss={channel?.statistics?.signals?.loss}
            open={channel?.statistics?.signals?.open}
            profit={channel?.statistics?.signals?.profit}
            index={index}
            lastSignalDate={channel?.lastSignalDate}
          />
          <ChannelFooter
            channelId={channel?.id}
            subscribed={channel?.subscribed}
            onSubscribeChannel={onSubscribeChannel}
            handleRefresh={handleRefresh}
            handleToggleModal={handleToggleModal}
          />
        </Channel.StyledCard>
      </Link>
      {
        isModalOpen
          ? <ChannelSetSettings
            isModalOpen={isModalOpen}
            handleToggleModal={handleToggleModal}
            channelId={channel?.id}
            handleRefresh={handleRefresh}
            onSubscribeChannel={onSubscribeChannel}
          />
          : null
      }
    </>
  )
};

Channel.StyledCard = styled(Card)`
  height: 358px;
  cursor: pointer;
  
  &:hover {
    background-color: rgba(46, 49, 72, 1) !important;

    .borderStatus {
      border: 4px solid #363A54;
    }
  }
`;

Channel.propTypes = {
  loading: PropTypes.bool,
  index: PropTypes.number,
};

export default Channel;