import { ReactComponent as Completed } from "assets/img/icons/DangerSuccess.svg";
import { ReactComponent as Cancelled } from "assets/img/icons/cancelledStatus.svg";
import { ReactComponent as Failed } from "assets/img/icons/failedStatus.svg";
import { ReactComponent as Ready } from "assets/img/icons/progressStatus.svg";

export const handleStatusData = (data) => {
  if (data) {

    return {
      completed: {
        date: data.completedAt,
        Icon: Completed
      },
      cancelled: {
        date: data.cancelledAt,
        Icon: Cancelled
      },
      failed: {
        date: data.failedAt,
        Icon: Failed
      },
      ready: {
        date: data.createdAt,
        Icon: Ready
      },
      open: {
        date: data.createdAt,
        Icon: Ready
      },
      running: {
        date: data.createdAt,
        Icon: Ready
      },
      pending_cancel: {
        date: data.cancelledAt,
        Icon: Cancelled
      }
    }
  }
};

export const pageVariants = (data) => {
  return {
    agreement: {
      data: data?.tradeRequest,
      emptyTitle: [
        "This agreement hasn’t created any trade requests at the moment",
        "Currently, there are no completed trade requests for this agreement"
      ],
      beforeDescription: [
        "As soon as this agreement opens some",
        "As soon as"
      ],
      afterDescription: [
        "requests, they will be displayed here. Or you can force run this agreement.",
        "requests become available, they will be showcased here. Alternatively, you can actively initiate trade requests for this agreement."
      ],
    },
    deals: {
      data: data,
      emptyTitle: [
        "This deal hasn’t created any trade requests at the moment",
        "Currently, there are no completed trade requests for this deal"
      ],
      beforeDescription: [
        "As soon as this deal opens some",
        "As soon as"
      ],
      afterDescription: [
        "requests, they will be displayed here. Or you can force run this deal.",
        "requests become available, they will be showcased here. Alternatively, you can actively initiate trade requests for this deal."
      ],
    },
  }
}
