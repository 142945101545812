import React from "react";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import { Card, CardHeader, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import "chartjs-adapter-moment";
import { useSelector } from "react-redux";

import chartConfig from ".";
import { ReactComponent as Orders } from "assets/img/icons/orders.svg"


const OrdersWidgetChart = ({openOrdersChart}) => {

  const id = useSelector((state) => state.credentials.item.data.id)
  const {chartData} = chartConfig({data: openOrdersChart})

  return (
    <OrdersWidgetChart.ChartCard className="card-chart">
      <OrdersWidgetChart.CardHeader className="p-4">
        <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
          <div className="d-flex align-items-center mb-2 mb-md-0">
            <Orders/>
            <h4 className="ml-2 mb-0 text-white font-weight-500">Orders</h4>
          </div>
          <Link to={`/credentials/${id}/orders`} className="link-info">
             Show all
          </Link>
        </div>
      </OrdersWidgetChart.CardHeader>
      <CardBody className="pb-4">
        <div className="chart-area" style={{height: "260px"}}>
          <Line data={chartData.config} options={chartData.options}/>
        </div>
      </CardBody>
    </OrdersWidgetChart.ChartCard>
  );
};

OrdersWidgetChart.ChartCard = styled(Card)`
  margin-bottom: 2rem !important;
  border-radius: 8px !important;
  min-height: 325px;
`;

OrdersWidgetChart.CardHeader = styled(CardHeader)`
  svg {
    width:13px;
    height:12px;
    & path {
      stroke: var(--info);
    }
  }
`;

export default OrdersWidgetChart;
