import React from "react";
import {FormGroup} from "reactstrap";
import {DelayInput} from "react-delay-input";
import {SIDE_OPTIONS, STATUS_OPTIONS} from "models/deal/enum/tables";
import SelectInput from "components/SelectInput";
import {capitalize} from "lodash";
import styled from "styled-components";
import ButtonsGroup from "components/ButtonsGroup";
import {TABLE_GROUP_BUTTONS} from "models/enum/tables";

const MarketFilters = (
  {
    tableView,
    setTableViw,
    handleFilter,
    credentials,
    handleCredential,
    loadingOrders,
    loadingTrades,
    sideFilter,
    setSideFilter,
    currentCredId,
    loadingCredentials,
    statusFilter,
    setStatusFilter,
    exchangeFilter,
    setExchangeFilter
  }) => {
  return (
    <MarketFilters.Wrapper className="d-flex flex-column flex-md-row align-items-md-center justify-content-between px-4 mb-3">
      <ButtonsGroup
        group={TABLE_GROUP_BUTTONS}
        current={tableView}
        setCurrent={setTableViw}
        disable={!currentCredId}
      />

      <div className="d-flex flex-column flex-sm-row align-items-sm-center mt-3 mt-md-0">
        <FormGroup className="mb-0 exchanges">
          <SelectInput
            placeholder="Credentials"
            name="credentials"
            isClearable
            options={
              credentials?.length ?
                credentials?.map((cred) => {
                  return {
                    icon: {
                      type: "exchanges",
                      size: "smallSize",
                      code: cred.exchange,
                    },
                    value: cred.id,
                    label: `${capitalize(cred.exchange)} ...${cred.id.slice(-4)}`
                  }
                }) : []
            }
            value={currentCredId ? {
              icon: {
                type: "exchanges",
                size: "smallSize",
                code: exchangeFilter?.icon?.code,
              },
              value: currentCredId,
              label: exchangeFilter?.label
            } : null
            }
            onChange={(option) => {
              setExchangeFilter(option);
              handleCredential(option?.value || "");
            }}
            isDisabled={loadingOrders || loadingTrades || loadingCredentials}
            isLoading={loadingCredentials}
          />
        </FormGroup>

        <FormGroup className="mb-0 my-2 my-sm-0 mx-sm-2 side">
          <SelectInput
            isClearable
            placeholder="Side"
            name="type"
            options={SIDE_OPTIONS}
            onChange={(option) => {
              setSideFilter(option?.value);
              handleFilter({type: {eq: option?.value}});
            }}
            isDisabled={loadingOrders || loadingTrades || !currentCredId}
            value={SIDE_OPTIONS?.filter((item) => item.value === sideFilter)}
          />
        </FormGroup>

        {
          tableView === "orders"
            ? <FormGroup className="mb-0 status">
              <SelectInput
                isClearable
                placeholder="Status"
                name="status"
                options={STATUS_OPTIONS}
                value={STATUS_OPTIONS?.filter((item) => item.value === statusFilter)}
                onChange={(option) => {
                  setStatusFilter(option?.value);
                  handleFilter({status: {eq: option?.value}});
                }}
                isDisabled={loadingOrders || loadingTrades || !currentCredId}
              />
            </FormGroup>
            : <FormGroup className="mb-0 orderId">
              <DelayInput
                autoComplete="off"
                delayTimeout={700}
                minLength={1}
                type="text"
                name="id"
                className="form-control"
                placeholder="Order ID"
                onChange={({target}) => handleFilter({
                  orderNumber: {like: target.value}
                })}
                disabled={loadingOrders || loadingTrades || !currentCredId}
              />
            </FormGroup>
        }
      </div>
    </MarketFilters.Wrapper>
  )
}

MarketFilters.Wrapper = styled.div`
  @media (max-width: 768px) {
    .exchanges, .orderId, .status, .side {
      width: 100% !important;
    }
  }

  .exchanges{
    width: 209px;
  }
  .orderId, .status {
    width: 145px;
  }
  .side {
    width: 88px;
  }
`;

export default MarketFilters;
