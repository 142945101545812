import React from "react";
import {Card, CardBody} from "reactstrap";
import {ReactComponent as BackBreadCrumb} from "assets/img/icons/backTo.svg";
import Icon from "components/Icon";
import {useHistory, useParams} from "react-router-dom";
import styled from "styled-components";
import HeaderLoader from "../tradeHistory/HeaderLoader";
/* eslint-disable complexity */

const OpenOrdersHeader = ({exchange = "", loading}) => {
  const history = useHistory()
  const {id} = useParams();

  const handleBackToCredentials = () => history.push(`/credentials/${id}`);

  if (loading) return <HeaderLoader/>;

  return (
    <OpenOrdersWrapper>
      <Card className="mb-3">
        <CardBody className="p-4">
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="d-flex ml-2 mb-2 mb-md-0">
              <Icon
                type="exchanges"
                size="xs"
                className="my-auto"
                code={exchange}
              />
              <div className="flex-column my-auto ml-3">
                <div className="flex-column">
                  <h1 className="text-white font-weight-500 mb-0">
                    Orders
                  </h1>
                  <p className="m-0 text-white opacity-35 font-weight-300 font-sm">
                    List of all account orders: active, cancelled, or filled.
                  </p>
                </div>
              </div>
            </div>
            <div onClick={handleBackToCredentials} className="d-flex align-items-center cursor-pointer">
              <OpenOrdersHeader.BackBreadCrumb>
                <BackBreadCrumb/>
              </OpenOrdersHeader.BackBreadCrumb>
              <OpenOrdersHeader.Back>Back to credentials</OpenOrdersHeader.Back>
            </div>
          </div>
        </CardBody>
      </Card>
    </OpenOrdersWrapper>
  );
};

const OpenOrdersWrapper = styled.div`
  .breadcrumb-item {
    margin: 0;
    color: rgba(255, 255, 255, 0.35);
    cursor: pointer;
    &:last-child {
      color: var(--white);
      cursor: default;
    }
    &:hover {
      color:  var(--white) !important;
      transition: .5s all ease;
    }
  }
`;

OpenOrdersHeader.Back = styled.p`
  color: rgba(255, 255, 255, .3) !important;
  margin-bottom: 0;
  cursor: pointer;
  margin-left: 18px;
  font-weight: 500;
  &:hover {
    color: rgba(255, 255, 255, .7) !important;
    text-decoration: underline;
    transition: .5s all ease;
  }
`;

OpenOrdersHeader.BackBreadCrumb = styled.div`
  svg {
    path {
      stroke: rgba(255, 255, 255, 0.35);
    }
  }
`;

export default OpenOrdersHeader;
