import React from "react";
import styled from "styled-components";
import IntervalTabs from "./IntervalTabs";

const Header = ({ setPaymentInterval, paymentInterval, displayIntervals }) => {
  return (
    <Header.Wrapper className="d-flex flex-column flex-md-row justify-content-between">
      <h1 className="mb-0">
        Billing
      </h1>
      {
        displayIntervals ?
          <IntervalTabs
            paymentInterval={paymentInterval}
            setPaymentInterval={setPaymentInterval}
          />
          : null
      }
    </Header.Wrapper>
  );
};

Header.Wrapper = styled.div`
  margin-bottom: 1.5rem;

  h1 {
    font-size: 2rem;
    color: #FFFFFF;
    line-height: 37.5px;
    font-weight: 600;
  }
`;

export default Header;
