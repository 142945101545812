import React from "react";
import { 
  Form, 
  useFormikContext
} from "formik";
import {
  CardBody,
  CardFooter,
  Label
} from "reactstrap";
import classNames from "classnames";
import PropTypes from "prop-types";

import NumberInput from "components/NumberInput";
import Button from "components/Button";
import ErrorAlert from "components/ErrorAlert";
import ToggleSwitch from "components/ToggleSwitch";
import { stringOrNull } from "../channelPropTypes";

const ChannelSubscriptionForm = ({
  isSubmitted,
  setErrorMessage,
  errorMessage,
  handleToggleModal,
  setIsSubmitted,
  titleSubmitButton = "Change"
}) => {
  const { setFieldValue, values, errors, isSubmitting } = useFormikContext();
  return (
    <Form>
      <CardBody className="p-0">
        <Label
          htmlFor="volume"
          className="text-white-75 font-weight-400 mb-2"
        >
          Investment per agreement
        </Label>
        <div className="position-relative">
          <NumberInput
            name="volume"
            min={1}
            max={20}
            value={values?.volume}
            onChange={(e) => setFieldValue("volume", e)}
            className={classNames("form-control", {
              "has-error": isSubmitted && errors?.volume
            }, "mb-0")}
          />
          <span className="text-white-35 font-weight-400 font-md position-absolute right-0 top-1 p-1 pr-2">
            %
          </span>
        </div>
        {isSubmitted && errors?.volume &&
          <p className="text-danger font-sm font-weight-300 m-0">
            {errors?.volume}
          </p>}
        <Label
          htmlFor="maximum"
          className="text-white-75 font-weight-400 mb-2 mt-3"
        >
          Maximum open agreements
        </Label>
        <NumberInput
          name="maximum"
          min={1}
          max={100}
          value={values?.maximum}
          onChange={(e) => setFieldValue("maximum", e)}
          className={classNames("form-control", {
            "has-error": isSubmitted && errors?.maximum
          }, "mb-0")}
        />
        {isSubmitted && errors?.maximum &&
          <p className="text-danger font-sm font-weight-300 m-0">
            {errors?.maximum}
          </p>}
        <div className="d-flex flex-row align-items-center justify-content-between mt-3">
          <Label className="text-white-75 font-weight-400 mb-0">
            Enable automatic signal acceptance
          </Label>
          <ToggleSwitch
            height={18}
            width={36}
            onColor="#26366b"
            offColor="#4b4c5e"
            onHandleColor="#00f2c3"
            offHandleColor="#FFFFFF59"
            checked={values.accept}
            onChange={(value) => {
              setFieldValue("accept", value)
            }}
          />
        </div>
      </CardBody>
      <ErrorAlert
        toggle={() => setErrorMessage("")}
        isOpen={!!errorMessage}
        className="mt-3"
      >
        {errorMessage}
      </ErrorAlert>

      <CardFooter className="p-0 pt-2 mt-5">
        <div className="d-flex justify-content-between">
          <Button
            type="button"
            onClick={handleToggleModal}
            outline
          >
            Cancel
          </Button>
          <Button
            loading={isSubmitting}
            disabled={isSubmitting}
            color="blue"
            type="submit"
            onClick={() => setIsSubmitted(true)}
          >
            {titleSubmitButton}
          </Button>
        </div>
      </CardFooter>
    </Form>
  );
};

ChannelSubscriptionForm.propTypes = {
  isSubmitted: PropTypes.bool,
  setErrorMessage: PropTypes.func,
  errorMessage: stringOrNull,
  handleToggleModal: PropTypes.func,
  setIsSubmitted: PropTypes.func,
  titleSubmitButton: PropTypes.string
};

export default ChannelSubscriptionForm;