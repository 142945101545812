export const MY_CHANNELS_HEAD = [
  {name: "name", label: "Name", isSortable: true},
  {name: "totalSubscribers", label: "Followers", isSortable: true},
  {name: "totalSignals", label: "Signals", isSortable: true},
  {name: "open", label: "Active signals", isSortable: false},
  {name: "profit", label: "Profit", isSortable: false},
];

export const LAST_ACTIVITIES_HEAD = [
  {name: "market", label: "Market", isSortable: false},
  {name: "invested", label: "Invested", isSortable: true},
  {name: "profit", label: "Return", isSortable: true},
  {name: "duration", label: "Duration", isSortable: false},
  {name: "completedAt", label: "Completed at", isSortable: true},
];