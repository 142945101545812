const getControlBackground = (provided, { menuIsOpen, isDark, isDisabled }) => {
  if (isDisabled && isDark) return "#292B3F";
  if (menuIsOpen && isDark) return "#222435";
  if (menuIsOpen && !isDark) return "#FFFFFF";
  return provided.background;
};

const getOptionBackground = (isFocused, isDark, isHover) => {
  if (isHover && isDark) return "#2A2C42";
  if (isFocused && isDark) return "#31334E";
  if (!isFocused && isDark) return "#31334E";
  return "#FFFFFF";
};

const getPlaceholderColor = ({ isFocused, isDark, isDisabled }) => {
  if (isDark && isDisabled) return "rgba(255, 255, 255, 0.35)";
  if (isDark) return "rgba(255, 255, 255, 0.35)";
  if (isFocused) return "rgba(29, 30, 42, 0.35)";
  return "BDBDBD";
};

const getBoxShadow = ({ menuIsOpen, isDark, isDisabled, hasError }) => {
  if (isDisabled) return "0 0 0 1px #2F324A";
  if (hasError) return "0 0 0 1px #ED5F5F";
  if (menuIsOpen) return "0 0 0 1px rgba(47, 128, 237, 0.5)";
  if (!isDark && !menuIsOpen) return "none";
  return "0 0 0 1px #44486A";
};

const getCursor = (isDisabled) => {
  if (isDisabled) return "default";
  return "pointer";
}

export const customStyles = {
  control: (provided, { selectProps }) => ({
    ...provided,
    boxShadow: getBoxShadow(selectProps),
    border: "none",
    lineHeight: "16.41px",
    fontSize: "14px",
    maxHeight: "38px",
    borderRadius: "6px",
    background: getControlBackground(provided, selectProps),
    ":hover": {
      boxShadow: !selectProps.menuIsOpen ? "0 0 0 1px #7F85AD" : "",
      cursor: "pointer"
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.selectProps.isDark ? "#FFFFFF" : "#333333",
    fontWeight: 400
  }),
  indicatorSeparator: () => ({
    display: "none"
  }),
  indicatorsContainer: (provided, { selectProps }) => ({
    padding: "11px 11px 11px 5px",
    display: "flex",
    "& .dropdown-indicator-icon": {
      transition: "transform .2s ease-in-out",
      transform: selectProps.menuIsOpen ? "rotate(-180deg)" : null,
      display: selectProps.isLoading ? "none" : "block",
      opacity: selectProps.isDisabled ? 0.35 : 1
    },
    "& .react-select__clear-indicator:hover": {
      opacity: 0.5
    }
  }),
  placeholder: (provided, { selectProps }) => ({
    ...provided,
    position: "absolute",
    color: getPlaceholderColor(selectProps)
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0 !important',
    borderRadius: '6px',
    color: "#FFFFFF",
    maxHeight: "192px",
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: "2px",
    background: state.selectProps.isDark ? "#242638" : "#FFFFFF",
    borderRadius: "6px",
    width: "auto",
    "min-width": "100%"
  }),
  option: (provided, { isFocused, isDisabled, selectProps }) => ({
    ...provided,
    cursor: getCursor(isDisabled),
    color: selectProps.isDark ? "#FFFFFF" : "#333333",
    padding: "11px",
    background: getOptionBackground(isFocused, selectProps.isDark),
    lineHeight: "16px",
    fontSize: "14px",
    ":active": {
      background: getOptionBackground(null, selectProps.isDark, true),
    },
    ":hover": {
      background: getOptionBackground(null, selectProps.isDark, true),
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: "nowrap",
  }),
  multiValueLabel: () => null,
  multiValue: (_, { selectProps }) => ({
    maxWidth: "100%",
    minWidth: "44%",
    "& .option-value": {
      ...(
        selectProps.menuIsOpen ?
        {}
        :
        {
          textOverflow: "ellipsis",
          width: selectProps.value?.length > 1 ? "calc(98%)" : "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          display: "initial"
        }
      )
    }
  }),
};
