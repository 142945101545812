import React, { useState, useLayoutEffect } from "react";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardFooter,
} from "reactstrap";

import {
  fetchStrategy,
  postStrategyImage,
  deleteStrategyImage,
  resetStrategy
} from "store/strategies/actions";
import Error from "components/Error";
import StrategyImageEdit from "./compounds/StrategyImageEdit";
import StrategyDetails from "components/StrategyDetails";
import SignalsCheck from "./compounds/SignalsCheck";
import StrategyHeader from "./compounds/StrategyHeader";
import StrategySettingsModal from "./compounds/StrategySettingsModal";
import DangerZone from "./compounds/DangerZone";
import StrategyTags from "./compounds/StrategyTags";
import SkeletonPreloader from "components/SkeletonPreloader";
import HeaderPreLoader from "components/HeaderPreLoader";
import AdditionalData from "./compounds/AdditionalData";

const StrategyPage = ({
  match,
  fetchStrategy,
  data,
  loading,
  error,
  postStrategyImage,
  deleteStrategyImage,
  location,
  resetStrategy
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useLayoutEffect(() => {
    fetchStrategy(match.params.id);
    return () => resetStrategy();
  }, [fetchStrategy, resetStrategy, match.params.id]);

  if (error) {
    return <Error error={error} />;
  }

  return(
    <>
      {
        loading ?
        <HeaderPreLoader />
        :
        <StrategyHeader
          name={data.name}
          uuid={match.params.id}
          createdAt={data.createdAt}
        />
      }
      <Row>
        <Col xs="4">
          <StrategyImageEdit
            strategy={data}
            postStrategyImage={postStrategyImage}
            deleteStrategyImage={deleteStrategyImage}
            loading={loading}
          />
          {
            loading ?
            <Card>
              <CardBody>
                <SkeletonPreloader
                  className="w-75 mt-2 mb-2"
                  height={12}
                  count={12}
                />
              </CardBody>
              <CardFooter>
                <StrategyPage.ButtonPreLoader
                  className="p-2 d-flex justify-content-center"
                  style={{ width: "111px" }}
                >
                  <SkeletonPreloader
                    height={12}
                    width={79}
                  />
                </StrategyPage.ButtonPreLoader>
              </CardFooter>
            </Card>
            :
            <StrategyDetails
              strategy={data}
              withEdit={true}
              onSetModalIsOpen={setModalIsOpen}
              loading={loading}
            />
          }
          {
            loading || (data.tags && data.tags.length > 0) ?
            <StrategyTags
              tags={data.tags}
              loading={loading}
            /> : null
          }
          <DangerZone
            id={match.params.id}
            name={data.name}
            loading={loading}
            botsAmount={data.botsAmount}
          />
        </Col>
        <Col xs="8">
          <AdditionalData
            strategy={data}
            loading={loading}
            id={match.params.id}
            location={location}
          />
          <SignalsCheck id={match.params.id} />
        </Col>
      </Row>
      <StrategySettingsModal
        onSetModalIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        data={data}
        id={match.params.id}
      />
    </>
  );
};

StrategyPage.ButtonPreLoader = styled.div`
  background: rgba(83, 84, 98, 0.2);
  border-radius: 4px;
`;

const mapStateToProps = (state) => ({
  data: state.strategies.item.data,
  loading: state.strategies.item.loading,
  error: state.strategies.item.error,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchStrategy,
  postStrategyImage,
  deleteStrategyImage,
  resetStrategy
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StrategyPage);
