import React, {useEffect} from "react";
import styled from "styled-components";
import {
  Modal,
  Card
} from "reactstrap";
import {
  fetchTradeRequestOrders,
  fetchTradeRequestTrades
} from "store/deals/actions";
import {
  useDispatch,
  useSelector
} from "react-redux";

import IdWithCopy from "components/IdWithCopy";
import LineWithError from "shared/ui/LineWithError";
import TradeRequestDetails from "components/TradeRequestDetailsModal/TradeRequestDetails";
import TradeRequestsDetailsTable from "components/TradeRequestDetailsModal/TradeRequestsDetailsTable";
import Button from "components/Button";
import {ReactComponent as Flag} from "assets/img/icons/flag.svg";
import {ReactComponent as Info} from "assets/img/icons/infoIcon.svg";


const TradeRequestsDetailsModal = ({setIsModalOpen, isModalOpen, idDeal, isModalOpenID, items = [], itemsRebalance = []}) => {
  const dispatch = useDispatch();

  const trade = items?.filter(item => item.id === isModalOpenID)[0];
  const tradeRebalance = itemsRebalance?.filter(item => item.tradeRequest.id === isModalOpenID)[0];

  const ordersRequests = useSelector((state) => state.deals.requestsOrders.data);
  const ordersRequestsLoading = useSelector((state) => state.deals.requestsOrders.loading);
  const tradesRequests = useSelector((state) => state.deals.requestsTrades.data);
  const tradesRequestsLoading = useSelector((state) => state.deals.requestsTrades.loading);

  const fetchData = () => {
    dispatch(fetchTradeRequestOrders(isModalOpenID, "?limit=5"));
    dispatch(fetchTradeRequestTrades(isModalOpenID, "?limit=5"));
  }

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDetails = (trade, tradeRebalance) => {
    if (trade) {
      return (
        <>
          <LineWithError radius="8px" errorMessage={trade.error}/>
          <TradeRequestDetails
            trade={trade}
            idDeal={idDeal}
            setIsModalOpen={setIsModalOpen}
          />
        </>
      )
    }
    if (tradeRebalance) {
      return (
        <>
          {tradeRebalance.tradeRequest.error && <LineWithError radius="8px" errorMessage={tradeRebalance.error}/>}
          <TradeRequestDetails
            trade={tradeRebalance.tradeRequest}
            idDeal={idDeal}
            setIsModalOpen={setIsModalOpen}
          />
        </>
      )
    }
  }

  return (
    <TradeRequestsDetailsModal.Modal
      isOpen={isModalOpen}
      toggle={() => setIsModalOpen(!isModalOpen)}
      modalClassName="modal-black"
    >
      <TradeRequestsDetailsModal.Card>
        <div className="px-4 pt-4">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <Flag/>
              <h3 className="mb-0 ml-2 text-white font-weight-500">
                Trade request details
              </h3>
            </div>
            <Button
              color="gray"
              cancel={true}
              onClick={() => setIsModalOpen(false)}
            />
          </div>
          <IdWithCopy id={isModalOpenID} className="mb-2"/>
          {
            renderDetails(trade, tradeRebalance)
          }

          {
            trade?.comment &&
            <TradeRequestsDetailsModal.Comment className="d-flex align-items-center mb-4">
              <Info/>
              <p className="mb-0 ml-3 text-white font-sm">
                {trade.comment}
              </p>
            </TradeRequestsDetailsModal.Comment>
          }
        </div>

        <TradeRequestsDetailsTable
          ordersRequests={ordersRequests}
          tradesRequests={tradesRequests}
          isModalOpenID={isModalOpenID}
          ordersRequestsLoading={ordersRequestsLoading}
          tradesRequestsLoading={tradesRequestsLoading}
          fetchData={fetchData}
        />
      </TradeRequestsDetailsModal.Card>
    </TradeRequestsDetailsModal.Modal>
  );
};

TradeRequestsDetailsModal.Modal = styled(Modal)`
  @media (max-width: 1200px) {
    width: 100% !important;
    margin: 0 auto !important;
  }

  max-width: 1150px !important;
  margin: 1rem auto !important;

  .modal-content {
    border-radius: 8px !important;
  }

  .pointer {
    cursor: pointer;
  }
`;

TradeRequestsDetailsModal.Card = styled(Card)`
  background: #2A2C42 !important;
  border-radius: 8px !important;
  margin-bottom: 0 !important;
`;

TradeRequestsDetailsModal.Comment = styled.div`
  background: rgba(47, 128, 237, 0.1);
  border-radius: 8px;
  padding: 12px 16px;

  svg {
    width: 20px;
    height: 20px;

    path {
      stroke: var(--info);
      stroke-opacity: 1;
    }
  }
`;

export default TradeRequestsDetailsModal;
