import React, { useState } from "react";
import styled from "styled-components";
import { capitalize } from "lodash";

import Copy from "components/svg/Copy";
import exchangesConfig from "exchanges/exchangesConfig";
import Hint from "components/Hint";
import {ReactComponent as InfoCircle} from "assets/img/icons/infocircle.svg";

const IpInfoBlock = ({ 
  exchange="", 
  ips
 }) => {
  const [copiedText, setCopiedText] = useState("");

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedText(text);
      setTimeout(() => {
        setCopiedText("");
      }, 1200);
    });
  };

  if (!ips?.length) return null;

  const getformattedExchangeName = () => capitalize(exchange).replace("btc", "BTC");

  return (
    <Wrapper className="mb-3">
      <div className="d-flex justify-content-between">
        <p className="font-sm font-weight-500 mb-3">
          Please use one the following IPs<br />
          to generate your {getformattedExchangeName()} API Key:
        </p>
        <a
          href={exchangesConfig[exchange]?.docsLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <InfoCircle />
        </a>
      </div>
      {
        ips?.map((ip) =>
          <div
            className="d-flex align-items-center code-text-wrapper"
            key={ip}
          >
            <p className="code-text">
              {ip}
            </p>
            <CopyButton
              className="btn btn-reset ml-2 my-0"
              onClick={() => handleCopy(ip)}
            >
              <Copy />
            </CopyButton>
            {
              copiedText === ip ?
              <Hint className="ml-2 mb-1">
                Copied!
              </Hint>
              : null
            }
          </div>
        )
      }
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-radius: 8px;
  background: rgba(47, 128, 237, 0.10);
  padding: 1rem 1.25rem;

  & .code-text-wrapper {
    line-height: 1;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;


const CopyButton = styled.button`
  & svg {
    margin-bottom: 4px;

    path {
      stroke: #9C9FBF;
    }
  }

  &:hover {
    & svg path {
      stroke: rgba(255, 255, 255, 0.75);
    }
  }
`;

export default IpInfoBlock;
