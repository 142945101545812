import React, {
  useState,
  useRef
} from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Button from "components/Button";
import Emoji from "components/Emoji";
import SkeletonPreloader from "components/SkeletonPreloader";
import useClickOutside from "hooks/useClickOutside";
import { useAvatarCache } from "./AvatarCacheContext";
import { getPlanEmoji } from "./utils";
import { USER_NAV_ITEMS } from "./navigation";
import defaultAvatar from "assets/img/defaultAvatar.svg";
import { ReactComponent as CaretIcon } from "assets/img/icons/caret.svg";
import { ReactComponent as LogOut } from "assets/img/icons/exportcurve.svg";
import { ReactComponent as EyeOff } from "assets/img/icons/eyeOff.svg";
import { ReactComponent as EyeOnBlue } from "assets/img/icons/eyeOnBlue.svg";

const UserAvatar = ({
  user,
  logout,
  avatars = {},
  loading,
  subscription,
  balanceHidden,
  setBalanceHidden
}) => {
  const { avatarCache } = useAvatarCache();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();

  useClickOutside(dropdownRef, () => setIsOpen(false), isOpen);

  return (
    <div
      className="position-relative"
      ref={dropdownRef}
    >
      <div
        className="position-relative my-auto"
        onClick={() => setIsOpen(!isOpen)}
      >
        <UserAvatar.StyledButton className="position-relative btn btn-reset rounded-circle ml-0 mt-md-1">
          {loading ? (
            <SkeletonPreloader
              circle
              height={30}
              width={30}
            />
          ) : (
            <img
              alt="..."
              className="h-100"
              height={30}
              width={30}
              src={avatarCache.original || avatars.small || defaultAvatar}
            />
          )}
        </UserAvatar.StyledButton>
        <UserAvatar.SubscriptionBadge className="d-md-none d-flex justify-content-center position-absolute">
          <Emoji
            symbol={getPlanEmoji(subscription?.type, subscription?.active)}
            size={8}
          />
        </UserAvatar.SubscriptionBadge>
        <UserAvatar.CaretIcon open={isOpen} />
      </div>
      {
        isOpen ?
          <UserAvatar.Dropdown className="mr-1 p-3 position-absolute mr-md-0" >
            <UserAvatar.TruncatedText className="text-truncate text-dark font-md font-weight-500 mb-0">
              {user?.name}
            </UserAvatar.TruncatedText>
            <UserAvatar.TruncatedText className="text-truncate text-dark-35 font-sm font-weight-400 mb-0">
              {user?.username}
            </UserAvatar.TruncatedText>
            <hr />
            <Button
              className="w-100 mt-3 justify-content-start ml-0"
              onClick={() => setBalanceHidden(!balanceHidden)}
            >
              {
                balanceHidden
                  ? <EyeOff />
                  : <EyeOnBlue />
              }
              <span className="btn-text font-weight-500 font-sm w-100 text-left">
                {
                  balanceHidden
                    ? "Show balance"
                    : "Hide balance"
                }
              </span>
            </Button>
            {USER_NAV_ITEMS.map((item) =>
              <Link
                to={item.path}
                key={item.name}
                className="d-block py-2 font-sm font-weight-500"
                onClick={() => setIsOpen(false)}
              >
                {item.icon}
                <span>{item.name}</span>
              </Link>
            )}
            <hr />
            <Button
              className="w-100 mt-3 logout-btn justify-content-start pl-2"
              onClick={logout}
            >
              <LogOut className="logout-icon"/>
              <span className="btn-text font-weight-500 font-sm w-100 text-left">
                Log out
              </span>
            </Button>
          </UserAvatar.Dropdown>
          : null}
    </div>
  );
};

UserAvatar.UserCard = styled.div`
  h3 {
    margin-bottom: 0;
    color: black;
  }
`

UserAvatar.CaretIcon = styled(CaretIcon)`
  transform: ${({ open }) => (open ? "rotate(180deg)" : "")};
`;

UserAvatar.SubscriptionBadge = styled.span`
  background: #27293c;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  bottom: -10px;
  left: 7.5px;
  margin-left: 0.625rem;

  & > span {
    line-height: 15px;
  }
`;

UserAvatar.StyledButton = styled.button`
  width: 30px;
  height: 30px;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

UserAvatar.Dropdown = styled.div`
  width: 272px;
  min-height: 152px;
  background: #fff;
  border-radius: 12px;
  right: -16px;
  top: 52px;
  color: rgba(29, 30, 42, 0.35);

  &:after {
    content: "";
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #fff;
    height: 8px;
    width: 16px;
    top: -10px;
    right: 30px;
  }

  a,button, button:focus, a:focus{
    color: rgba(29, 30, 42, 0.35) !important;
  }

  button {
      margin-left: 0 !important;

      @media (max-width: 1200px) {
        margin-left: 0 !important;
      }
  }
  a, button {
    padding: 0 0.75rem;
    border-radius: 6px;

    & svg path {
      stroke: #1d1e2a59;
      stroke-opacity: 1;
    }

    &:hover {
      background-color: rgba(29, 30, 42, 0.05) !important;
      color: #1d1e2a !important;

      & svg path {
        stroke: blue;
      }
    }

    span {
      margin-left:0.625rem;
    }
  }

    hr {
      border-top: 1px solid rgba(29, 30, 42, 0.05);
    }
  }

  button.logout-btn {

    & .logout-icon {
      transform: rotate(90deg);

      & path {
        stroke: #1D1E2A;
        stroke-opacity: 0.35;
      }
    }

    & .btn-text {
      color: rgba(29, 30, 42, 0.35);
      margin-left:0.625rem;
    }

    &:hover {
      background-color: rgba(29, 30, 42, 0.05) !important;
      color: black;
      
      & svg path {
        stroke: #ED5F5F;
        stroke-opacity: 1;
      }
      .btn-text{
        color:#1D1E2A;
      }
    }
`;

UserAvatar.TruncatedText = styled.p`
  max-width: 272px;
`;

export default UserAvatar;