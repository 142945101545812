import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import classNames from "classnames";
import moment from "moment";

import Button from "components/Button";
import PaymentIcon from "components/PaymentIcon";
import useBuySubscription from "hooks/useBuySubscription";
import { selectCurrentPlan } from "store/user/selectors";
import { ReactComponent as Calendar } from "assets/img/icons/calendar.svg";

const CurrentPlan = ({
  plans=[],
  paymentInterval
}) => {
  const currentPlan = useSelector(selectCurrentPlan);
  const { handleBuyNow } = useBuySubscription();

  const [currentPlanPrices] = plans?.reduce((acc, plan) => {
    if (plan?.id === currentPlan?.product?.id && paymentInterval) {
      acc.push(...plan.prices);
    }
    return acc;
  }, [])?.filter((price) => price.interval === paymentInterval) || [];
  
  const getBadgeText = () => {
    if (currentPlan?.status === "active") return "Active";
    if (currentPlan?.status === "paused") return "Paused";
    if (currentPlan?.status === "trialing") {
      const today = moment().add("days");
      const days = Math.round(moment.duration(moment(currentPlan?.trialEndAt) - today).asDays());
      return `Trial ends in ${days}d`;
    }
    return "Cancelled";
  };

  const showRenewDate = () => {
    if (!currentPlan?.autoRenewal) return "Your plan will be canceled without a further subscription.";
    const date = new Date(Date.parse(currentPlan?.endDate));
    return `Your plan renews on ${moment(date).format("MMM Do, YYYY")}`;
  };

  return (
    <div className="d-flex flex-column flex-md-row justify-content-between">
      <div className="d-flex flex-column">
        <div className="d-flex mb-2">
          <p className="text-white-75 mr-2 my-auto text-capitalize">
            {currentPlan?.type || ""}
          </p>
          <Badge className={classNames("py-1 px-2 font-sm", {
            success: currentPlan?.status === "active",
            info: currentPlan?.status === "trialing",
            disabled: currentPlan?.status === "past_due" || currentPlan?.status === "canceled" || currentPlan?.status === "paused",
          })}>
            {getBadgeText()}
          </Badge>
          {
            currentPlan?.cancelAt ?
            <Badge className="ml-1 py-1 px-2 font-sm disabled text-white-75">
              <Calendar className="mr-1" />
              {`Cancels ${moment(currentPlan?.cancelAt).format("DD MMM YYYY")}`}
            </Badge> : null
          }
        </div>
        <p className="h1 text-white mb-3 font-weight-500">

        </p>
        <p className="text-white-35 mb-4">
          {showRenewDate()}
        </p>
        <div className="d-flex">
          <PaymentIcon name={currentPlan?.cardBrand} />
          <p className="ml-2 text-white m-0">
            <span className="font-weight-500">
              {currentPlan?.cardBrand?.toUpperCase()}
            </span>
            {` **** ${currentPlan?.lastNumbers || "0000"}`}
          </p>
        </div>
      </div>
      <div className="d-flex flex-column">
        <StyledButton
          color="blue"
          className="mb-2"
          onClick={() => handleBuyNow(currentPlanPrices?.stripe, currentPlan?.status === "trialing")}
        >
          Manage billing
        </StyledButton>
        <StyledButton
          outline
          onClick={() => handleBuyNow(currentPlanPrices?.stripe, currentPlan?.status === "trialing")}
        >
          Cancel plan
        </StyledButton>
      </div>
    </div>
  );
};

const StyledButton = styled(Button)`
  width: 202px;
`;

const Badge = styled.div`
  border-radius: 4px;

  &.success {
    background: rgba(63, 203, 176, 0.1);
    color: #3FCBB0;
  }

  &.info {
    background: rgba(47, 128, 237, 0.1);
    color: #76A9ED;
  }

  &.disabled {
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.75);
  }
`;

export default CurrentPlan;
