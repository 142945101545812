import React, {useLayoutEffect} from "react";
import {
  Card,
  CardBody,
  Col
} from "reactstrap";
import { useDispatch, useSelector} from "react-redux";

import { selectMarketInformation } from "store/deals/selectors";
import { fetchMarketInformation } from "store/deals/actions";
import MarketInformationPreloader from "../views/deals/ui/preloaders/MarketInformationPreloader";
import { ReactComponent as Market } from "assets/img/icons/clipboardtext.svg";

import { MarketStatusStyled } from "../views/deals/pagesView/Market/MarketTab.styles";
import { LineWithError } from "shared/ui";

const MarketInformation = ({
  base,
  quote,
  loadingPage,
  exchange
}) => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(selectMarketInformation);;

  useLayoutEffect(() => {
    if (exchange) {
      dispatch(fetchMarketInformation(exchange, base, quote));
    }
  }, [exchange,base, quote, dispatch]);

  if (loadingPage || loading) return <MarketInformationPreloader />;

  return (
    <>
      <Card className="p-0 mb-4">
        <div className="d-flex justify-content-between align-items-center p-4">
          <div className="d-flex align-items-center">
            <Market stroke="red" />
            <p className="mb-0 ml-2 text-white font-weight-500">
              Market information
            </p>
          </div>
          <MarketStatusStyled className="rounded-top rounded-bottom custom-background">
            <p
              className={`font-weight-500 font-sm text-center mb-0 ${
                data?.active ? "text-success" : "text-danger"
              }`}
            >
              {data?.active ? "Active" : "Inactive"}
            </p>
          </MarketStatusStyled>
        </div>
        {!data.active &&
        <LineWithError
          errorMessage={`Market is ${data?.base}-${data?.quote} inactive. Please check back later.`}
          radius="0"
        />
      }
        <CardBody className={`d-flex flex-wrap px-4 pb-4 ${!data?.active ? "pt-4":"pt-0" } justify-content-between`}>
          <Col xs="6" md="2" className="p-0">
            <p className="mb-1 text-white-35 font-weight-500 font-sm">
              Minimum amount
            </p>
            <div className="d-flex align-items-center">
              {data?.minAmount?.toString() ? (
                <p className="mb-0 text-white font-weight-500 text-capitalize">
                  {data.minAmount}
                </p>
              ) : (
                <p className="text-white-35">-</p>
              )}
              {data?.minAmount?.toString() &&
                <p className="mb-0 ml-1 text-white-35 font-weight-300">
                  {quote}
                </p>}
            </div>
          </Col>
          <Col xs="6" md="2" className="p-0">
            <p className="mb-1 text-white-35 font-weight-300 font-sm">
              Minimum quantity
            </p>
            <div className="d-flex align-items-center">
              {data?.minQuantity?.toString() ? (
                <p className="mb-0 text-white font-weight-500 text-capitalize">
                  {data.minQuantity}
                </p>
              ) : (
                <p className="text-white-35">-</p>
              )}
              {data?.minQuantity?.toString() && (
                <p className="mb-0 ml-1 text-white-35 font-weight-300">
                  {base}
                </p>
              )}
            </div>
          </Col>
          <Col xs="6" md="2" className="p-0">
            <p className="mb-1 text-white-35 font-weight-300 font-sm">
              Price scale
            </p>
            <p className="mb-0 text-white font-weight-500 text-capitalize">
              {data?.priceScale?.toString() || "-"}
            </p>
          </Col>
          <Col xs="6" md="2" className="p-0">
            <p className="mb-1 text-white-35 font-weight-300 font-sm">
              Amount scale
            </p>
            <p className="mb-0 text-white font-weight-500 text-capitalize">
              {data?.amountScale?.toString() || "-"}
            </p>
          </Col>
          <Col xs="6" md="2" className="p-0">
            <p className="mb-1 text-white-35 font-weight-300 font-sm">
              Quantity scale
            </p>
            <p className="mb-0 text-white font-weight-500 text-capitalize">
              {data?.quantityScale?.toString() || "-"}
            </p>
          </Col>
        </CardBody>
      </Card>
    </>
  );
};

export default React.memo(MarketInformation);
