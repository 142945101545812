import React from "react";
import {Card, CardBody} from "reactstrap";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import HeaderWithType from "components/BotSettingsWidget/HeaderWithType";
import DefaultHeader from "components/BotSettingsWidget/DefaultHeader";
import GeneticSettingsPreloader from "../preloaders/GeneticSettingsPreloader";

const GeneticSettingsWidget = ({
  data={},
  loading,
  defaultHeader=true,
  onEditButtonClick,
  pathTo
}) => {
  const history = useHistory();

  const handleRedirect = () => {
    if (!pathTo) return;
    history.push(pathTo);
  };

  if (loading) return <GeneticSettingsPreloader />

  return (
    <StyledCard
      path={pathTo}
      onClick={handleRedirect}
      defaultHeader={defaultHeader}
    >
      {
        defaultHeader ?
          <DefaultHeader
            onEditButtonClick={onEditButtonClick}
            title="Settings"
          />
          :
          <HeaderWithType
            data={data?.geneticStrategy?.bot}
            onEditButtonClick={onEditButtonClick}
          />
      }
      <CardBody className="pt-3 px-4 pb-4">
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between mt-2">
            <p className="mb-0 text-white-75 font-weight-400 font-sm">
              State
            </p>
            <span className={`mb-0 font-weight-500 text-${data.disabled ? "white-35" : "white"}`}>
              {data.disabled ? "No active" : "Active"}
            </span>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <p className="mb-0 text-white-75 font-weight-400 font-sm">
              Generation size
            </p>
            <span className="mb-0 font-weight-500 text-white">
              {data?.geneticAlgorithm?.generationSize}
            </span>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <p className="mb-0 text-white-75 font-weight-400 font-sm">
              Population filter
            </p>
            <span className="mb-0 font-weight-500 text-white">
              {data?.geneticAlgorithm?.populationFilter}
            </span>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <p className="mb-0 text-white-75 font-weight-400 font-sm">
              Eternal
            </p>
            <span className={`mb-0 font-weight-500 text-${data.eternal ? "success" : "danger"}`}>
              {data.eternal ? "True" : "False"}
            </span>
          </div>
        </div>
      </CardBody>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  cursor: ${(props) => props.path ? "pointer" : "default"};
  border-radius: 8px !important;
  overflow: hidden;

  &:hover {
    background: ${(props) => props.path ? "#2E3148" : "#27293d" };

    .borderStatus {
      border: 3px solid #363A54;
    }

    & .card-header {
      background: ${(props) => props.path && !props.defaultHeader ? "#363A54" : "transparent" } !important;
    }
  }
`;

export default GeneticSettingsWidget;
