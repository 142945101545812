import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {ReactComponent as BotInfo} from "assets/img/icons/botHeadInfo.svg";
import {ReactComponent as Advice} from "assets/img/icons/Advice.svg";
import {CardHeader, Tooltip} from "reactstrap";
import Button from "../Button";

const BotsHeader = ({freeMarkets, investment}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const history = useHistory();

  return (
    <CardHeader className="pt-3 px-4 mb-2">
      <div className="d-flex flex-column flex-lg-row justify-content-between">
        <div className="d-flex align-items-center">
          <BotInfo
            width="24px"
            height="24px"
          />
          <p className="mb-0 ml-2 text-white font-weight-500">
            Market Bots
          </p>
        </div>

        <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between mt-3 mt-sm-0 flex-wrap">
          <div className="d-flex flex-row align-items-center mr-3 mb-2 mb-sm-0">
            <p className="mb-0 font-xs text-white-75 mr-1">
              {
                freeMarkets > 0
                  ? `${freeMarkets} markets are not used by bots`
                  : "Great! All markets are already used by bots"
              }
            </p>
            <span className="d-inline-block">
              <Advice id="freeMarkets" className="cursor-pointer"/>
            </span>
            <Tooltip
              placement="top"
              isOpen={tooltipOpen}
              target="freeMarkets"
              toggle={() => setTooltipOpen(!tooltipOpen)}
            >
              {
                freeMarkets > 0
                  ? "Adding bots to all avialable markets gives you a chance of getting higher profits"
                  : "You have no unused markets to to add them to the new bots in this investment"
              }
            </Tooltip>
          </div>
          <Button
            color="blue"
            disabled={!freeMarkets}
            onClick={() =>
              history.push({
                pathname: '/bots/create',
                state: {
                  investment: investment
                }
              })
            }
          >
            Create a new bot
          </Button>
        </div>
      </div>
    </CardHeader>
  )
}

export default BotsHeader;
