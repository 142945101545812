export const MARKET_BOTS_TABLE = [
  {name: "name", label: "Name", isSortable: true},
  {name: "marketsCount", label: "Markets", isSortable: true},
  {name: "dealsStatistics", label: "Statistics", isSortable: false},
  {name: "totalReturn", label: "Total return", isSortable: true},
  {name: "period", label: "Period", isSortable: true},
  {name: "countDeals", label: "Deals", isSortable: true},
  {name: "", label: "", isSortable: false},
  {name: "", label: "", isSortable: false},

];
