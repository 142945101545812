import React, { useState } from "react";
import styled from "styled-components";
import { Col } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";

import { Footer, Body } from "../compounds/Layout";
import Button from "components/Button";
import { ReactComponent as Export } from "assets/img/icons/export.svg";
import ConnectExchangeForm from "../form/ConnectExchangeForm";
import { createCredential } from "store/credentials/actions";
import { handleResponse } from "helpers/errorsValidator";
import { ReactComponent as ArrowLeft } from "assets/img/icons/arrow-left.svg";
import exchangesConfig from "exchanges/exchangesConfig";
import IPsBlock from "../compounds/IPsBlock";

const CONNECT_SCHEMA = Yup.object().shape({
  apiKey: Yup.string()
    .required("Check if the API key is correct and try again"),
  secret: Yup.string()
    .required("Check if the secret key is correct and try again"),
  exchange: Yup.string()
    .required('This field is required.'),
});

const ConnectStep = ({
  currentStep,
  setCurrentStep,
  handleScrollUp,
  setIsCurrentStepValid,
  exchange,
  formData,
  setFormData,
  websiteLink,
  exchangeName
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  const handleStepBack = () => {
    handleScrollUp();
    setCurrentStep(currentStep - 1);
  };

  async function onSubmit(values, { setSubmitting, setErrors }) {
    const reg = /\n+/g;
    const filteredValues = {
      ...values,
      apiKey: values.apiKey?.replaceAll(" ", ""),
      secret: values.secret?.replaceAll(" ", "")?.replaceAll(reg, "")
    }
    setSubmitted(false);
    setSubmitting(true);
    const res = await dispatch(createCredential(filteredValues));

    if (res?.errors) {
      setErrorMessage(res.message);
      handleResponse(res, setErrors);
    } else {
      setIsCurrentStepValid(true);
      setCurrentStep(currentStep + 1);
    }

    setSubmitted(true);
    setSubmitting(false);
  };

  const {
    errors,
    isSubmitting,
    handleSubmit,
    values,
    setFieldValue
  } = useFormik({
    initialValues: {
      apiKey: formData.apiKey || "",
      secret: formData.secret || "",
      exchange: exchange.code,
    },
    validationSchema: CONNECT_SCHEMA,
    onSubmit,
  });

  const handleSetValue = (name, value) => {
    setFieldValue(name, value);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <Body>
        <Col xs={12} md={7}>
          <h1 className="mb-3">
            Connect your {exchangeName} account to Darkbot
          </h1>
          <p style={{ marginBottom: "40px" }}>
            Almost There! Enter your API and Secret
            keys for your exchange account to complete the setup.
          </p>
          <ConnectExchangeForm
            submitted={submitted}
            errors={errors}
            values={values}
            handleSetValue={handleSetValue}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            websiteLink={websiteLink}
          />
          <IPsBlock exchange={exchange} />
        </Col>
      </Body>
      <Footer className="w-100 d-flex d-flex flex-column-reverse flex-md-row justify-content-between">
        <StyledButton onClick={() => handleStepBack()}>
          <ArrowLeft className="mr-2 d-inline-block d-md-none" />
          Back to exchanges
        </StyledButton>
        <div className="d-flex align-items-md-center">
          <a
            href={exchangesConfig[exchange]?.docsLink}
            target="_blank"
            rel="noopener noreferrer"
            className="w-100 text-nowrap d-none d-md-block text-center text-info hover-underline mr-md-4 mt-4 mt-md-0"
          >
            <Export className="mr-2 mb-1" />
            API Key Generation Guide
          </a>
          <Button
            color="blue"
            loading={isSubmitting}
            className="w-100"
            onClick={() => {
              setSubmitted(true);
              handleSubmit();
            }}
          >
            Connect
          </Button>
        </div>
      </Footer>
    </>
  );
};

const StyledButton = styled.button`
  border: 1px solid rgba(29, 30, 42, 0.10);
  background: transparent;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--dark);
  height: 40px;
  padding: 0 32px;
  border-radius: 6px;

  &:hover {
    background: rgba(29,30,42,0.10);
  }

  @media (max-width: 768px) {
    border: none;
    line-height: 1.4;
  }
`;

export default ConnectStep;
