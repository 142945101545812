import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";

import SkeletonPreloader from "components/SkeletonPreloader";

const ParametersWidgetPreloader = () => {
  return (
    <Card className="overflow-hidden">
      <CardHeader className="d-flex px-4 pt-4 pb-0">
        <SkeletonPreloader
          circle
          height={20}
          width={20}
        />
        <SkeletonPreloader
          height={12}
          width={81}
          className="my-auto ml-2"
        />
      </CardHeader>
      <CardBody className="p-4">
        <div className="d-flex justify-content-between mb-3">
          <SkeletonPreloader
            height={9}
            width={57}
          />
          <SkeletonPreloader
            height={12}
            width={73}
          />
        </div>
        <div className="d-flex justify-content-between">
          <SkeletonPreloader
            height={9}
            width={81}
          />
          <SkeletonPreloader
            height={12}
            width={73}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default ParametersWidgetPreloader;
