import React from "react";
import { Card } from "reactstrap";
import SkeletonPreloader from "components/SkeletonPreloader";

const SettingsPreloader = () => {
  return (
    <Card className="p-4">
      <div className="d-flex align-items-center">
        <SkeletonPreloader
          circle={true}
          height={16}
          width={16}
        />
        <SkeletonPreloader
          height={12}
          width={60}
          className="ml-2"
        />
      </div>
      <SkeletonPreloader
        height={12}
        width={191}
        className="mt-4"
      />
      <SkeletonPreloader
        height={12}
        width={117}
        className="mt-3"
      />
    </Card>
  )
}

export default SettingsPreloader;
