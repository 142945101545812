import React from "react";

const HeaderBalance = ({ 
  user, 
  balanceHidden
}) => {
  const renderBalance = (balanceHidden, user) => {
    if (balanceHidden) {
      return "***,***.**";
    }
    if (user?.holdings?.amount === null) {
      return 0;
    }
    return user?.holdings?.amount;
  };

  return (
    <div className="d-flex flex-column">
      <p className="mb-0 text-white-35 font-xs">Balance</p>
      <p className="mb-0 text-white font-weight-700 my-auto font-sm">
        {renderBalance(balanceHidden, user)}
        <span className="pl-1 text-white-35 font-weight-300">
          {user?.holdings?.currency}
        </span>
      </p>
    </div>
  );
};

export default HeaderBalance;
