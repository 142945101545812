import React from "react";
import styled from "styled-components";

import { ReactComponent as InfoCircle } from "assets/img/icons/infocircle.svg";

const SupportContactNotification = ({
  infoMessage,
  linkText,
  redirectTo,
}) => {
  return (
    <SupportContactNotification.Wrap
      className="d-flex flex-wrap flex-md-nowrap align-items-center py-3 px-4 mt-4"
    >
      <span className="d-none d-md-inline-block ">
        <InfoCircle />
      </span>
      <p className="mb-2 mb-md-0 pl-3 text-white font-weight-400 w-100">
        {infoMessage} {" "}
        <a
          className="font-weight-500 text-nowrap"
          href={redirectTo}
        >
          {linkText}
        </a>
      </p>
    </SupportContactNotification.Wrap>
  )
};

SupportContactNotification.Wrap = styled.div`
  background: rgba(47, 128, 237, 0.10);
  border-radius: .5rem;

  svg {
    path {
      stroke:"var(--info)");
    }
  }

  a {
    color:var(--info) !important;
    text-decoration: underline;

    &:hover, 
    &:active {
      text-decoration: none;
    }
  }
`;

export default SupportContactNotification;
