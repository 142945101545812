import React from "react";
import {CONDITION_MARKET_TABLE_HEAD} from "models/bot/tables";
import StyledTable from "components/StyledTable";
import SortTableHead from "components/SortTableHead";
import CustomScrollbars from "components/CustomScrollbars";
import ConditionsMarketTableRow from "./ConditionsMarketTableRow";
import MarketSummaryEmptyStates from "./MarketSummaryEmptyStates";

const ConditionsMarketTable = ({conditions, botConditions}) => {

  if (!conditions?.length) {
    return (
      <MarketSummaryEmptyStates
        title="This bot doesn't use any conditions for trading"
        values={conditions?.length}
        botValues={botConditions}
      />
    )
  }

  return (
    <CustomScrollbars
      autoHideTimeout={500}
      autoHideDuration={500}
      autoHeight
      autoHeightMax={Number.MAX_VALUE}
    >
      <StyledTable className="mb-0" paddingsThead="1rem">
        <SortTableHead
          handleSorting={() => {}}
          theadItems={CONDITION_MARKET_TABLE_HEAD}
        />
        <tbody className="font-sm">
        {
          conditions?.map((item) =>
            <ConditionsMarketTableRow
              item={item}
              key={item.id}
            />)
        }
        </tbody>
      </StyledTable>
    </CustomScrollbars>
  )
}

export default ConditionsMarketTable;