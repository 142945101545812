import styled from "styled-components";

export const MarketStatusStyled = styled.div`
  background-color: rgba(255, 255, 255, 0.03);
  padding: 2px 10px;
  
  p.text-success {
    color: #5fd3bc !important;
    font-size: 14px !important;
  }
  p.text-danger {
    color: #e74c3c !important;
    font-size: 14px !important;
  }
`;