import React from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import qs from "qs";
import { useFormikContext } from "formik";

import SelectInput from "components/SelectInput";
import {
  getChannels,
  resetChannels,
} from "store/channels/actions";
import {
  selectChannels,
} from "store/channels/selectors";
import { selectMe } from "store/user/selectors";
import useRequestCancellation from "hooks/useRequestCancellation";

const ChannelsSelect = ({ isSubmitted }) => {
  const dispatch = useDispatch();
  const {
    loading,
    data: {
      items,
      total
    }
  } = useSelector(selectChannels);
  const { data } = useSelector(selectMe);
  const { useAbortableEffect } = useRequestCancellation();
  const { setFieldValue, errors } = useFormikContext();

  useAbortableEffect((signal) => {
    const filter = qs.stringify({
      filter: {
        owner: {
          eq: data.id
        }
      }
    });

    dispatch(getChannels(`?${filter}`, signal))

    return () => dispatch(resetChannels());
  }, [data, dispatch]);

  const getPlaceholderText = () => (
    loading
      ? "Loading…"
      : "Please select channel"
  );

  if (total || loading) {
    return (
      <>
        <SelectInput
          isBigOption
          placeholder={getPlaceholderText()}
          width="100%"
          isLoading={loading}
          isSearchable={false}
          isError={errors?.channel}
          isDisabled={!total && !loading}
          disabledOptionIcon={loading}
          styles={{ ...customStyles }}
          onChange={({ value }) => setFieldValue("channel", value)}
          options={
            items?.length ? items.map(({ id, name, logos, totalSubscribers }) => ({
              key: id,
              label: name,
              secondLabel: `Subscribers ${totalSubscribers}`,
              value: id,
              logo: logos?.large,
              icon: {
                type: "icons",
                size: "xxs",
                code: "default-icon-channel",
              }
            })) : []
          }
        />
        {isSubmitted && errors?.channel &&
          <p className="text-danger font-sm font-weight-300 m-0 position-absolute">
            {errors?.channel}
          </p>}
      </>
    );
  }
};

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    marginTop: "2px",
    borderRadius: "8px",
  }),
  control: (provided, {selectProps}) => ({
    ...provided,
    minHeight: "65px !important",
    marginTop: "10px",
    paddingInlineEnd: "1rem",
    borderRadius: "8px",
    background: "#363A54",
    borderStyle: "none",
    borderColor: "transparent",
    border: selectProps.isError ? "1px solid #ed5f5f" : "1px solid transparent",
    boxShadow: "none",
    ":hover": {
      border: "1px solid #4A4E73",
      cursor: "pointer"
    }
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided, { selectProps }) => ({
    padding: "11px 11px 11px 5px",
    display: "flex",
    "& .dropdown-indicator-icon": {
      transition: "transform .2s ease-in-out",
      transform: selectProps.menuIsOpen ? "rotate(-180deg)" : null,
      display: selectProps.isLoading ? "none" : "block",
      opacity: selectProps.isDisabled ? 0.35 : 1
    },
    "& .react-select__clear-indicator:hover": {
      opacity: 0.5
    }
  }),
  option: (provided, state) => ({
    ...provided,
    position: "relative",
    backgroundColor: state.isSelected ? "#363A54" : "initial",
    border: "1px solid transparent",
    marginBottom: "0.5rem !important",
    borderRadius: "0 !important",
    "&:hover": {
      background: "var(--dusk-steel)",
    },

    "&:active": {
      background: "#363A54",
    },

    "&:last-child": {
      marginBottom: "0 !important",

      "&:hover": {
        borderBottomLeftRadius: "0.5rem !important",
        borderBottomRightRadius: "0.5rem !important",
      },
    },


    "&:first-child::after": {
      display: "none",
    },

    "&::after": {
      position: "absolute",
      content: "''",
      width: "100%",
      height: "1px",
      backgroundColor: "var(--dark-slate)",
      left: "0",
      top: "-2px",

    },
  }),
};

export default ChannelsSelect;
