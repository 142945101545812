import React from "react";
import styled from "styled-components";
import { Progress } from "reactstrap";

const MultiProgress = ({
  children,
  height,
  ...props
}) => {

  return (
    <MultiProgress.Progress
      height={height}
      multi
      {...props}
    >
      {children}
    </MultiProgress.Progress>
  );
};

MultiProgress.Progress = styled(Progress)`
  box-shadow: none !important;
  height: ${(props) => props.height || "14px"} !important;
`;

export default MultiProgress;
