import React from "react";
import {Card} from "reactstrap";
import SkeletonPreloader from "components/SkeletonPreloader";

const GenerationWidgetPreloader = () => {
  return (
    <Card className="p-4">
      <div className="d-flex flex-row align-items-center justify-content-between">
        <div className="d-flex flex-row align-items-center">
          <SkeletonPreloader
            circle
            width={20}
            height={20}
            className="mr-2"
          />
          <SkeletonPreloader
            height={12}
            width={79}
            className="mr-3"
          />
          <SkeletonPreloader
            height={12}
            width={23}
          />
        </div>
        <div className="d-flex flex-row align-items-center">
          <SkeletonPreloader
            height={9}
            width={65}
            className="mr-2"
          />
          <SkeletonPreloader
            height={12}
            width={89}
          />
        </div>
      </div>
    </Card>
  )
}

export default GenerationWidgetPreloader;
