import React from "react";
import SkeletonPreloader from "components/SkeletonPreloader";
import { Card } from "reactstrap";

const CredentialSettingsPreloader = () => {
  return (
    <Card className="p-4">
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center">
          <SkeletonPreloader
            circle={true}
            width={19}
            height={19}
          />
          <SkeletonPreloader
            height={14}
            width={111}
            className="ml-2"
          />
        </div>
        <SkeletonPreloader
          height={12}
          width={191}
          className="mt-3"
        />
        <SkeletonPreloader
          height={12}
          width={86}
          className="mt-3"
        />
      </div>
    </Card>
  )
}

export default CredentialSettingsPreloader;
