import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import qs from "qs";
import { DelayInput } from "react-delay-input";
import {
  CardBody,
  Card
} from "reactstrap";

import SelectInput from "components/SelectInput";
import { beforeToday } from "helpers/DatepickerValidation";
import FiltersPreloader from "../preloaders/FiltersPreloader";
import DateField from "components/DateField";
import { SIDE_OPTIONS } from "models/deal/enum/tables";

const TradeHistoryFilters = ({ 
  location, 
  id, 
  markets, 
  setEmptyFilter,
  loading
}) => {
  const history = useHistory();
  const { filter, sort } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const handleFilter = (value) => {
    setEmptyFilter({ filter: { ...filter, ...value }})
    history.push({
      pathname: `/credentials/${id}/trade-history`,
      search: qs.stringify({ filter: { ...filter, ...value }, sort: {...sort}}).replace(/[^=&]+=(?:&|$)/g, "")
    });
  };

  if (loading) return <FiltersPreloader fieldsAmount={6} />

  return(
    <Card className="mb-4" style={{borderRadius: "8px"}}>
      <CardBody className="p-4">
        <div className="d-flex flex-column flex-md-row justify-content-between">
          <SelectInput
            isClearable
            className="mb-0 mr-3 mb-2 mb-md-0 w-100"
            placeholder="Side"
            name="type"
            onChange={(option) => handleFilter({ type: { eq: option?.value } })}
            value={SIDE_OPTIONS.find((o) => o.value === filter?.type?.eq)}
            options={SIDE_OPTIONS}
          />
          <DelayInput
            className="form-control mb-0 mr-3 mb-2 mb-md-0 w-100"
            delayTimeout={700}
            minLength={1}
            type="text"
            name="id"
            placeholder="Enter order ID"
            value={filter && filter.orderNumber ? filter.orderNumber.like : ""}
            onChange={({ target }) => handleFilter({ orderNumber: { like: target.value } })}
          />
          <SelectInput
            isClearable
            placeholder="Market"
            className="mb-0 mr-3 mb-2 mb-md-0 w-100"
            name="market"
            isMulti
            value={filter?.market?.in?.map((market) => ({
                label: market,
                value: market,
                icon: {
                  type: "cryptocurrency",
                  size: "selectSize",
                  code: market.split("-")[0],
                },
              }))}
            onChange={(values) => {
              handleFilter({
                market: { in: values ? values.map((e) => e.value) : "" }
              });
            }}
            options={
              markets?.data?.length
              &&
              markets?.data?.map((market) => {
                  return {
                    label: market,
                    value: market,
                    color: "#FFFFFF",
                    icon: {
                      type: "cryptocurrency",
                      size: "smallSize",
                      code: market.split('-')[0],
                    }};
                }
            )}
          />
          <DateField
            className="mb-0 mr-2 mb-2 mb-md-0 w-100"
            onChange={(value) => {
              handleFilter({
                createdAt: {
                  gte: value,
                }
              })
            }}
            isValidDate={beforeToday}
            dateFormat="YYYY-MM-DD HH:mm:ss"
            inputProps={{
              style: {
                background: "#2E3148",
                color: "#FFFFFF",
                padding: "11px",
              },
              className: "form-control",
              readOnly: true,
              name: "createdAt",
              placeholder: "Start date",
              value: filter?.createdAt?.gte || "",
            }}
          />
          <DateField
            className="w-100"
            onChange={(value) => {
              handleFilter({
                createdAt: {
                  gte: filter?.createdAt?.gte,
                  lte: value
                }
              })
            }}
            dateFormat="YYYY-MM-DD HH:mm:ss"
            formatValue="YYYY-MM-DD HH:mm:ss"
            isValidDate={(current) =>
              current.isAfter(filter?.createdAt?.gte)
              && beforeToday(current)
            }
            inputProps={{
              style: {
                background: "#2E3148",
                color: "#FFFFFF",
                padding: "11px",
              },
              readOnly: true,
              className: "form-control",
              name: "createdAt",
              placeholder: "End date",
              value: filter?.createdAt?.lte || "",
            }}
          />
        </div>
      </CardBody>
    </Card>
  );
};

TradeHistoryFilters.Calendar = styled.div`
  position: absolute;
  top: 20%;
  right: 4%;

  svg {
    path {
      fill: #A3A3A3;
    }
  }
`;

TradeHistoryFilters.Label = styled.label`
  display: flex;
  align-items: center;
  background: #393B53;
  border-radius: 8px;
  border: none;
  color: #FFFFFF;
  position: relative;

  span {
    color: #34495E;
    padding: 0.6rem 0.6rem 0.6rem 0;
  }

  input {
    height: 16px;
    width: 16px;
    margin: 0 0 0 11px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid #4C5075;
    border-radius: 2px;
    outline: none;
    transition-duration: 0.3s;
    background-color: white;
    cursor: pointer;
  }

  input:checked {
    border: 1px solid #4C5075;
    background-color: #3FCBB0;
  }

  input:checked + span::before {
    content: '\\2713';
    display: block;
    text-align: center;
    color: #1D1E2A;
    position: absolute;
    left: 0.9rem;
  }
`;

export default TradeHistoryFilters;
