import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Remove } from "assets/img/icons/remove.svg";
import {
  Col,
  Row
} from "reactstrap";
import moment from "moment";
import Callout from "components/Callout";
import Loading from "components/Loading";

const Notification = ({
  data,
  onDeleteNotification,
  onRemoveDeletedNotification,
  deleteAllNotifications,
  deleteLoading
}) => {
  const [removedId, setRemovedId] = useState();

  const handleRemove = () => {
    if (deleteLoading && removedId === data.id) return;
    onDeleteNotification(data.id);
    setRemovedId(data.id);
  };

  if (data.deleted) return (
    <Notification.CalloutDeleted className="d-flex justify-content-between m-3">
      <div className="d-flex align-items-center">
        <p className="mb-0" style={{color: "rgba(29, 30, 42, 0.75)", fontSize: "12px", fontWeight: "300"}}>
          Notification deleted.
        </p>
        <p
          className="mb-0 ml-2 text-danger text-underline"
          style={{cursor: "pointer"}}
          onClick={() => deleteAllNotifications()}
        >
          Remove all such notifications
        </p>
      </div>
      <Notification.IconButton
        className="tim-icons icon-simple-remove text-danger"
        onClick={() => onRemoveDeletedNotification(data.id)}
      />
    </Notification.CalloutDeleted>
  );

  const str = data.message;
  const from = str.search('IP');
  const to = str.length;
  const message = str.substring(0,from);
  const IP = str.substring(from,to);

  return (
    <Callout
      type={data.type}
      className="ml-3 mt-2"
    >
      <Row>
        <Col xs="10">
          <Notification.Message className="text-dark p-0">
            {message}
            <span className="font-weight-500">
              {IP}
            </span>
          </Notification.Message>
          <div className="d-flex align-items-center">
            <p className="text-dark-35 font-xs p-0 mb-0">
              {moment(data.createdAt).fromNow()}
            </p>
            {
              !data.viewed ?
                <div className="d-flex align-items-center">
                  <Notification.Point className="d-inline-block ml-2" />
                  <Notification.New>New</Notification.New>
                </div>
               : null
            }
          </div>
        </Col>
        <Col xs="2" className="d-flex justify-content-end align-items-center pr-0">
          <Notification.IconButton
            isLoading={deleteLoading && removedId === data.id}
            className={deleteLoading && removedId === data.id ? "visible" : ""}
            onClick={handleRemove}
            deleteLoading={deleteLoading}
          >
            {
              deleteLoading && removedId === data.id ?
                <Loading
                  size={16}
                  color="var(--info)"
                />
                : <Remove />
            }
          </Notification.IconButton>
        </Col>
      </Row>
    </Callout>
  );
};

Notification.IconButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 6px;
  box-shadow: none;
  border: none;
  background: transparent;

  svg {
    path {
      stroke: rgba(29, 30, 42, 0.35);
    }
  }

  &:hover {
    svg {
      path {
        stroke: #1D1E2A !important;
      }
    }
    background: ${(props) => props.deleteLoading ? "transparent" : "rgba(29, 30, 42, 0.1)"};
    border-radius: 6px;
    width: 32px;
    height: 32px;
  }
`;

Notification.Point = styled.span`
  background-color: #ED5F5F;
  width: 4px;
  height: 4px;
  border-radius: 50%;
`;

Notification.CalloutDeleted = styled.div`
  padding: 20px;
  margin: 10px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
`;

Notification.Message = styled.p`
  font-size: 14px !important;
`;

Notification.New = styled.p`
  font-size: 12px !important;
  font-weight: 500;
  margin-bottom: 0 !important;
  padding-left: 4px !important;
  color: #ED5F5F !important;
`;

export default Notification;
