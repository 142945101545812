import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Filters from "./compounds/Filters";
import PaginationPanel from "components/PaginationPanel";
import {
  fetchCredentials,
  clearCredentialsData
} from "store/credentials/actions";
import Credential from "./compounds/Credential";
import Button from "../../components/Button";
import ListLoading from "./preloaders/ListLoading";
import EmptyFiltersListingState from "../../shared/ui/state/EmptyFiltersListingState";
import EmptyDataList from "./states/EmptyDataList";
import usePagination from "hooks/usePagination";
import useRequestCancellation from "hooks/useRequestCancellation";
import { CreateCredentialsModal } from "shared/ui";

const CredentialsList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { handlePaginate } = usePagination("/credentials", true);
  const { useAbortableEffect } = useRequestCancellation();

  const dispatch = useDispatch();
  const history = useHistory();

  const subscription = useSelector((state) => state.me.data.subscription);
  const error = useSelector((state) => state.credentials.list.error);
  const loading = useSelector((state) => state.credentials.list.loading);
  const { items, total, page } = useSelector((state) => state.credentials.list.data);

  useAbortableEffect((signal) => {
    dispatch(fetchCredentials(history.location.search, signal));
    return () => {
      dispatch(clearCredentialsData());
    };
  }, [dispatch, history.location.search], true);

  useEffect(() => {
    window.analytics.page("/credentials");
  }, []);

  const renderEmptyStates = () => {
    if (loading) return <ListLoading />;
    if (history.location.search?.includes("filter") && !items?.length) return <EmptyFiltersListingState/>;
    if (!items?.length || error) {
      return (
        <EmptyDataList
          title="No connected exchanges yet"
          description="Please connect some to see them displayed here."
          buttonText="Connect exchange"
          onHandleClick={setIsModalOpen}/>
      );
    }
  };

  return(
    <>
      <div className="list-page-header d-flex align-items-center justify-content-between mt-4 mb-3">
        <h1 className="mb-0 text-white font-weight-500">
          Credentials
        </h1>
        {
          !history.location.search?.includes("filter") ?
          <Button
            color="blue"
            onClick={() => setIsModalOpen(true)}
            className="m-0 font-weight-500"
            disabled={isModalOpen}
          >
            Connect exchange
          </Button> : null
        }
      </div>
      <Filters />
      { renderEmptyStates() }
      {
        items?.map((credential, index) =>
          <Credential
            id={credential.id}
            key={credential.id}
            data={credential}
            subscription={subscription}
            index={index}
          />
        )
      }
      <PaginationPanel
        activePage={page}
        totalItemsCount={total}
        onChange={(page) => handlePaginate(page)}
      />
      <CreateCredentialsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

export default CredentialsList;
