import React from "react";
import SkeletonPreloader from "components/SkeletonPreloader";
import _ from "lodash";

const ConditionsMarketPreloader = () => {
  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center justify-content-between px-4 py-3">
        <SkeletonPreloader width={32} height={10}/>
        <div>
          <SkeletonPreloader width={62} height={12}/>
          <SkeletonPreloader width={62} height={12} className="mx-3"/>
          <SkeletonPreloader width={62} height={12}/>
        </div>
      </div>
      {
        _.times(3, (e) =>
          <div
            key={e}
            className="d-flex align-items-center justify-content-between px-4 py-3"
            style={{borderTop: "1px solid #2E3148"}}
          >
            <SkeletonPreloader width={122} height={12}/>
            <div>
              <SkeletonPreloader width={62} height={12}/>
              <SkeletonPreloader width={62} height={12} className="mx-3"/>
              <SkeletonPreloader width={62} height={12}/>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default ConditionsMarketPreloader;