import React, { useState } from "react";
import styled from "styled-components";
import {
  Card,
  CardBody,
  Tooltip
} from "reactstrap";

import { ReactComponent as SettingsIcon } from "assets/img/icons/setting-4.svg";
import { ReactComponent as Help } from "assets/img/icons/Help.svg";
import { ReactComponent as WarningIcon } from "assets/img/icons/Warning.svg";
import SettingsModal from "../modals/SettingsModal";
import EditButton from "components/EditButton";
import DealsProgress from "components/Investment/DealsProgress";

const Settings = ({id, drawdown, maxDeals, version, runningDeals}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [maximumDrawdownTooltip, setMaximumDrawdownTooltip] = useState(false);
  const [maxDealsTooltip, setMaxDealsTooltip] = useState(false);

  return (
    <>
      <Card className="mb-4">
        <CardBody className="p-4">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <SettingsIcon/>
              <p className="mb-0 ml-2 text-white font-weight-500">Settings</p>
            </div>
            <EditButton onClick={() => setModalIsOpen(true)}/>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <p className="mb-0 text-white-75 font-weight-300 text-nowrap font-sm">
              Maximum daily drawdown{" "}
              <Help
                id="MDtooltipSettings"
                className="cursor-pointer"
              />
              <Tooltip
                target="MDtooltipSettings"
                placement="top"
                isOpen={maximumDrawdownTooltip}
                toggle={() => setMaximumDrawdownTooltip((prev) => !prev)}
              >
                Maximum daily drawdown (MDD) measures the maximum fall in the value
                of the coin during the last 24 hours, as given by the difference
                between the current value and that of the highest
                peak before the trough.
              </Tooltip>
            </p>
            <p className="mb-0 ml-2 text-white font-weight-500">
              {
                !drawdown ?
                <Settings.WarningIcon
                  width="14"
                  height="12"
                />
                : null
              }{" "}
              {
                drawdown ?
                `${drawdown}%`
                : null
              }
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <p className="mb-0 text-white-75 font-weight-300 text-nowrap font-sm">
              Maximum running deals{" "}
              <Help
                id="maxDealsTooltip"
                className="cursor-pointer"
              />
              <Tooltip
                target="maxDealsTooltip"
                placement="top"
                isOpen={maxDealsTooltip}
                toggle={() => setMaxDealsTooltip((prev) => !prev)}
              >
                It means how many deals may run at the same
                time from all attached bots for this coin.
              </Tooltip>
            </p>
            <p className="mb-0 ml-2 text-white font-weight-500">
              {
                !maxDeals ?
                <Settings.WarningIcon
                  width="14"
                  height="12"
                />
                : null
              }{" "}
              {maxDeals || null}
            </p>
          </div>
          <DealsProgress
            maxDeals={maxDeals}
            runningDeals={runningDeals}
          />
        </CardBody>
      </Card>
      <SettingsModal
        modalIsOpen={modalIsOpen}
        onSetModalIsOpen={setModalIsOpen}
        drawdown={drawdown}
        maxDeals={maxDeals}
        version={version}
        id={id}
      />
    </>
  )
}

Settings.WarningIcon = styled(WarningIcon)`
  & path {
    stroke: #FF8D72;
  }
`;

export default Settings;
