import React, {useState} from "react";
import Button from "reactstrap-button-loader";
import {
  FormGroup,
  Col,
  UncontrolledAlert,
  Label
} from "reactstrap";
import {
  Formik,
  Form,
  Field
} from "formik";
import * as Yup from "yup";

import ToggleSwitch from "components/ToggleSwitch";

const WithdrawalSchema = Yup.object().shape({
  amount: Yup.string()
    .required("This field is required."),
  address: Yup.string()
    .required("This field is required."),
});

const style = {
  color: "#ec250d",
};

const WithdrawalForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [switched, setSwitched] = useState(true);

  return (
    <Formik
      initialValues={{
        amount: "",
        address: "",
        saveAddress: "",
        savedWallets: ""
      }}
      validationSchema={WithdrawalSchema}
    >
      {({errors, isSubmitting, values}) => (
        <Form>
          {errors.message &&
          <UncontrolledAlert color="danger" fade={false}>
            {errors.message}
          </UncontrolledAlert>}
          <div className="d-flex justify-content-between">
            <Label>Your BTC wallet address</Label>
            <div className="d-flex">
              <ToggleSwitch
                onChange={() => setSwitched(!switched)}
                checked={switched}
                height={18}
                width={36}
                onColor="#26366b"
                offColor="#1c284d"
                onHandleColor="#1d8cf8"
                offHandleColor="#1b4266"
              />
              <p className="ml-2">My saved address</p>
            </div>
          </div>
          <FormGroup className={submitted && (errors.address || errors.message) ? "has-danger" : "has-success"}>
            <Field
              type="text"
              name="address"
              className="form-control"
            />
            <Col md="12">
              {submitted && errors.address ?
                <label style={style}>{errors.address}</label> : null}
            </Col>
          </FormGroup>
          <FormGroup className="mt-2" check>
            <Label check>
              <Field name="saveAddress" type="checkbox"/>
              <span className="form-check-sign"/>
              <h5 className="text-white-35">Save address for future usage</h5>
            </Label>
          </FormGroup>
          {
            values.saveAddress ?
              <FormGroup className="mb-4">
                <Field
                  type="text"
                  name="savedWallets"
                  className="form-control"
                />
              </FormGroup> : null
          }
          <Label>Amount</Label>
          <FormGroup className={submitted && (errors.amount || errors.message) ? "has-danger" : "has-success"}>
            <Field
              type="text"
              name="amount"
              className="form-control"
            />
            <Col md="12">
              {submitted && errors.amount ?
                <label style={style}>{errors.amount}</label> : null}
            </Col>
          </FormGroup>
          <div className="mt-4 mb-4">
            <p>
              <span className="text-white-35">Aviable balance: </span>
              0.2783 BTC
            </p>
            <p>
              <span className="text-white-35">Remains after withdrawal: </span>
              0.003 BTC
            </p>
            <p>
              <span className="text-white-35">Fee: </span>
              0.111 BTC
            </p>
          </div>
          <Button
            className="float-right"
            color="info"
            type="submit"
            size="md"
            disabled={isSubmitting}
            onClick={() => setSubmitted(true)}
            loading={isSubmitting}
          >
            Withdrawal balance
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default WithdrawalForm;
