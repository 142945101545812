import React from "react";
import PropTypes from "prop-types";

const VolumesWidgetBody = ({
  investedAmount,
  investedAsset,
  purchasedAmount,
  purchasedAsset
}) => (
  <>
    <div className="d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between mt-3 align-items-lg-center">
      <p className="font-sm mb-0 text-white">
        Invested
      </p>
      <div className="d-flex align-items-center">
        <p className="mb-0 text-white font-weight-500">
          {investedAmount || "0.00"}
        </p>
        <p className="mb-0 text-white-35 font-weight-300 ml-2 mb-0">
          {investedAsset}
        </p>
      </div>
    </div>
    <div className="d-flex flex-column flex-sm-row flex-md-column flex-lg-row align-items-lg-center justify-content-between mt-2">
      <p className="font-sm mb-0 mr-3 text-white-35 font-weight-300">
        Purchased
      </p>
      <div className="d-flex align-items-center overflow-hidden">
        <p className="mb-0 text-white font-weight-500 overflow-hidden rbc-ellipsis">
          {purchasedAmount || "0.00"}
        </p>
        <p className="mb-0 text-white-35 font-weight-300 ml-2 mb-0">
          {purchasedAsset}
        </p>
      </div>
    </div>
  </>
);

VolumesWidgetBody.propTypes = {
  investedAmount: PropTypes.number,
  investedAsset: PropTypes.string,
  purchasedAmount: PropTypes.number,
  purchasedAsset: PropTypes.string
};

export default VolumesWidgetBody;