import { Card, CardHeader } from "reactstrap";
import styled from "styled-components";

import { ReactComponent as Repeat } from "assets/img/icons/repeat.svg";
import{ReactComponent as EmptyPlanets} from "assets/img/planets.svg"


const RebalanceEmptyState = ({title, text}) => {

  return(
    <Card xs="12">
      <CardHeader className="d-flex flex-column flex-md-row justify-content-between pt-3 pr-3 pl-4 mb-3">
        <div className="d-flex">
          <Repeat />
          <StyledTitle className="font-weight-500 ml-2 text-white">
            Rebalance History
          </StyledTitle>
        </div>
      </CardHeader>
      <RebalanceEmptyState.Wrapper  className="d-flex align-items-center flex-column justify-content-center">
        <EmptyPlanets role="img"/>
        <RebalanceEmptyState.Block className="d-flex align-items-center flex-column justify-content-center">
          <h2 className="font-weight-500 text-white mb-2 text-center">
            {title}
          </h2>
          <RebalanceEmptyState.Text className="text-white-75 font-sm font-weight-400 text-center m-0">
            {text}
          </RebalanceEmptyState.Text>
        </RebalanceEmptyState.Block>
      </RebalanceEmptyState.Wrapper>
    </Card>
  )
};

const StyledTitle = styled.p`
  line-height: 18.75px;
`;

RebalanceEmptyState.Wrapper = styled.div`
  padding: 80px 0px;
  margin: auto;
`;

RebalanceEmptyState.Block = styled.div`
  margin: 40px 0px 32px 0px;
`;

RebalanceEmptyState.Text = styled.p`
  white-space: pre-line;
`;

export default RebalanceEmptyState;
