import React, {
  useLayoutEffect,
  useRef
} from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import {Card} from "reactstrap";
import qs from "qs";

import {fetchDealsRunning} from "store/deals/actions";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import RunningDealsHeader from "./RunningDealsHeader";
import RunningDealsTable from "./RunningDealsTable";
import RunningPreloader from "./RunningPreloader";
import RunningEmptyState from "./RunningEmptyState";
import TablePreloader from "components/TablePreloader";

const RunningDeals = ({ botId, loadingPage }) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const {items, page, total, request} = useSelector(state => state.deals.running.data);
  const loading = useSelector(state => state.deals.running.loading);

  const { handleFilter, handleSorting, handlePaginate } = useFiltersWithRequest(request, fetchDealsRunning)

  useLayoutEffect(() => {
    const filter = qs.stringify({
      filter: {bot: {eq: botId}, status: {in: ["ready", "running"]}},
      sort: {by: "status", order: "desc"}
    });
    dispatch(fetchDealsRunning(`?${filter}`));
  }, [botId, dispatch]);

  if (loadingPage) return <RunningPreloader/>;

  if (!loading && !items?.length && !request?.filter?.market && !request?.filter?.status?.eq) {
    return (
      <Card className="mb-4">
        <RunningEmptyState
          title="Looks like there are no deals yet "
          description="Hey there! It looks like there aren't any Running or Ready deals for this bot just yet.
          Don't worry though, you can either create a new deal or simply hang tight and wait for one to be executed automatically"
        />
      </Card>
    )
  }

  return (
    <div ref={wrapperRef}>
      <Card className="mb-4">
        <RunningDealsHeader
          handleFilter={handleFilter}
          loading={loading}
          loadingPage={loadingPage}
          botId={botId}
          request={request}
        />
        {
          loading ?
            <TablePreloader
              thHeight={12}
              thWidth={[44, 70, 68, 70]}
              tdWidth={[65, 90, 90, 100]}
              tdHeight={14}
              rowTimes={5}
              withCircle={20}
            />
            :
            <RunningDealsTable
              items={items}
              page={page}
              total={total}
              request={request}
              handleSorting={handleSorting}
              handlePaginate={handlePaginate}
              wrapperRef={wrapperRef}
            />
        }
      </Card>
    </div>
  )
}

export default RunningDeals;
