/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import * as Sentry from "@sentry/react";

import FallBack from "components/FallBack";
import TickersFilters from "./TickersFilters";
import TickersTable from "./TickersTable";
import Ticker from "./Ticker";
import useWindowDimensions from "hooks/useWindowDimensions";
import MovingTikerLinePreloader from "./MovingTikerLinePreloader";
import useClickOutside from "hooks/useClickOutside";
import useTickers from "exchanges/useTickers";
import MovingTikerLine from "./MovingTikerLine";
import MovingTickerEmptyState from "./MovingTickerEmptyState";

const Tickers = ({isOpen, setIsOpen, dropdownRef}) => {
  const [asset, setAsset] = useState("BTC");
  const [search, setSearch] = useState("");
  const [exchange, setExchange] = useState("poloniex");
  const { width } = useWindowDimensions();
  const timeoutInterval = useRef(null);
  useClickOutside(dropdownRef, () => width > 991 ? setIsOpen(false) : null, isOpen);
  const {
    isLoading,
    isRefreshing,
    refresh,
    assets,
    tickers,
    allTickers,
    currentTicker,
    setSort,
    sort,
    error
  } = useTickers(exchange, asset, search, isOpen);

  useEffect(() => {
    if(!isOpen) {
      clearInterval(timeoutInterval.current);
    }else {
       timeoutInterval.current = setInterval(() => {
        refresh();
      }, 10000);
    }
      return () => clearInterval(timeoutInterval.current)
  }, [isOpen, exchange])

  const renderDropDownContent = () => {
    if (width <= 1200 && isLoading) return <MovingTikerLinePreloader />;

    if (width <= 1200 && error && !tickers.length) return (
      <MovingTickerEmptyState handleRefresh={refresh} />
    );

    if (width <= 1200) return (
      <MovingTikerLine
        tickers={allTickers}
        exchange={exchange}
        onClick={() => setIsOpen(!isOpen)}
      />
    );

    return (
      <Ticker
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        exchange={exchange}
        tickers={allTickers}
        isLoading={isLoading}
        currentTicker={currentTicker}
        error={error}
        refresh={refresh}
      />
    );
  };

  return (
    <Sentry.ErrorBoundary fallback={<FallBack />}>
      <div
        className="position-relative"
        ref={dropdownRef}
      >
        {renderDropDownContent()}
        {
          isOpen &&
          <Tickers.DropdownMenu
            className="mt-2 w-full dropdown-navbar white-content pt-3 pb-0"
          >
            <TickersFilters
              setExchange={setExchange}
              exchange={exchange}
              assets={assets}
              asset={asset}
              setAsset={setAsset}
              isLoading={isLoading}
              isRefreshing={isRefreshing}
              setSearch={setSearch}
              search={search}
              setIsOpen={setIsOpen}
              refresh={refresh}
            />
            <TickersTable
              tickers={tickers}
              setSort={setSort}
              sort={sort}
              onSetIsOpen={setIsOpen}
              exchange={exchange}
              error={error}
              isLoading={isLoading}
            />
          </Tickers.DropdownMenu>
        }
      </div>
    </Sentry.ErrorBoundary>
  );
};

Tickers.DropdownMenu = styled.div`
  width: calc(90vw) !important;
  overflow: hidden;
  background-color: var(--white) !important;
  border-radius: 8px !important;
  z-index: 999;
  position: absolute;

  @media (max-width: 1199.98px) {
    width: 100% !important;
    position: fixed;
    left: 0;
    top: 90px;
  }
`;

export default Tickers;
