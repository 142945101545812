import React from "react";
import styled from "styled-components";

import OverviewTab from "./OverviewTab";
import AdminTab from "./AdminTab";
import OverviewPreloader from "../preloaders/OverviewPreloader";
import { CHANNEL_VIEW } from "../mocks";

const PagesView = ({
  loadingPage,
  data,
  pageView,
  setPagView,
}) => {
  const renderTabs = () => {
    return (
      <PagesView.Page>
        {
          CHANNEL_VIEW.map((page) =>
            <div key={page}>
              <PagesView.Tab
                current={pageView}
                tab={page}
                onClick={() => setPagView(page)}
              >
                {page}
              </PagesView.Tab>
              <span className={`${pageView === page ? "active" : ""}`} />
            </div>
          )
        }
      </PagesView.Page>
    )
  };

  if (loadingPage) return <OverviewPreloader />;

  if (data?.owner) {
    return (
      <>
        {renderTabs()}
        {
          pageView === "overview"
          &&
          <OverviewTab
            channel={data}
            loadingPage={loadingPage}
          />
        }
        {
          pageView === "admin"
          &&
          <AdminTab
            channel={data}
          />
        }
      </>
    )
  }
  return (
    <OverviewTab
      channel={data}
      loadingPage={loadingPage}
    />
  );
};

PagesView.Page = styled.div`
  display: flex;
  margin-bottom: 1.5rem;

  div {
    &:nth-child(2) {
      margin: 0 1.5rem;
    }

    span {
      &.active {
        display: block;
        border-bottom: 3px solid var(--info);
        border-radius: 2px;
      }
    }
  }
`;

PagesView.Tab = styled.p`
  cursor: pointer;
  margin-bottom: 8px;
  font-weight: 500;
  text-transform: capitalize;
  color: ${({ current, tab }) => current === tab
    ? "var(--white)"
    : "rgba(255, 255, 255, 0.35)"
  };

  &:hover {
    color: #fff;
  }
`;

export default PagesView;