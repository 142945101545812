import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";

import { replaceDurations } from "helpers/replaceDurations";
import { ReactComponent as Icon } from "assets/img/icons/clipboardtext.svg";
import ParametersWidgetPreloader from "../preloaders/ParametersWidgetPreloader";

const ParametersWidget = ({ data={}, loading }) => {
  if (loading) return <ParametersWidgetPreloader />;

  return (
    <Card className="overflow-hidden">
      <CardHeader className="d-flex px-4 pt-4 pb-0">
        <span className="d-inline-block mr-2 mb-1">
          <Icon />
        </span>
        <p className="font-weight-500 text-white my-auto">
          Parameters
        </p>
      </CardHeader>
      <CardBody className="p-4">
        <div className="d-flex justify-content-between">
          <p className="mb-0 text-white-75 font-weight-400 font-sm">
            Market
          </p>
          <p className="mb-0 font-weight-500 text-white">
            {data?.market?.base}{" "}
            <span className="text-white-35 font-weight-300">
              /
            </span>{" "}
            {data?.market?.quote}
          </p>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <p className="mb-0 text-white-75 font-weight-400 font-sm">
            Lifetime
          </p>
          <p className="mb-0 font-weight-500 text-white">
            {replaceDurations(data?.geneticAlgorithm?.lifetime)}
          </p>
        </div>
      </CardBody>
    </Card>
  );
};

export default ParametersWidget;
