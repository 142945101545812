import React from "react";
import {Card, CardBody} from "reactstrap";
import { ReactComponent as BackBreadCrumb } from "assets/img/icons/backTo.svg";
import Icon from "components/Icon";
import { useHistory, useParams } from "react-router-dom";
import styled from 'styled-components'
import HeaderLoader from "../tradeHistory/HeaderLoader";
/* eslint-disable complexity */

const MarketStatisticsHeader = ({ exchange="", loading }) => {

  const history = useHistory()
  const { id } = useParams();

  const handleBackToCredentials = () => history.push(`/credentials/${id}`);

  if (loading) return <HeaderLoader />;

  return (
    <MarketStatisticsHeader.MarketStatisticsWrapper>
      <Card className="mb-3">
        <CardBody>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="d-flex ml-3 pt-2 pb-2">
              <Icon
                type="exchanges"
                size="xs"
                className="my-auto mr-3"
                code={exchange}
              />
              <div className="flex-column my-auto">
                <div className="flex-column">
                  <h1 className="mb-0 text-white font-weight-500">
                    Market Statistics
                  </h1>
                  <p className="m-0 text-white opacity-35 font-sm">
                    Summary based on the trade history
                  </p>
                </div>
              </div>
            </div>
            <div onClick={handleBackToCredentials} className="d-flex align-items-center mr-2 cursor-pointer">
              <MarketStatisticsHeader.BackBreadCrumb>
                <BackBreadCrumb/>
              </MarketStatisticsHeader.BackBreadCrumb>
              <MarketStatisticsHeader.Back>Back to credentials</MarketStatisticsHeader.Back>
            </div>
          </div>
        </CardBody>
      </Card>
    </MarketStatisticsHeader.MarketStatisticsWrapper>
  );
};

MarketStatisticsHeader.MarketStatisticsWrapper = styled.div`
  .breadcrumb-item {
    margin: 0;
    color: rgba(255, 255, 255, 0.35);
    cursor: pointer;
    z-index: 10000;

    &:last-child {
      color: var(--white);
      cursor: default;
    }

    &:hover {
      color:  var(--white) !important;
      transition: .5s all ease;
    }
  }
`;

MarketStatisticsHeader.Back = styled.p`
  color: rgba(255, 255, 255, .3) !important;
  margin-bottom: 0;
  cursor: pointer;
  margin-left: 18px;
  font-weight: 500;

  &:hover {
    color: rgba(255, 255, 255, .7) !important;
    text-decoration: underline;
    transition: .5s all ease;
  }
`;

MarketStatisticsHeader.BackBreadCrumb = styled.div`
  svg {
    path {
      stroke: rgba(255, 255, 255, 0.35);
    }
  }
`;

export default MarketStatisticsHeader;
