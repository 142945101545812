import { combineReducers } from "redux";

import withDataLoading from "store/withDataLoading";
import {
  CANCEL_DEAL, CANCEL_DEAL_ERROR,
  CANCEL_DEAL_LOADING,
  COMPLETE_DEAL,
  COMPLETE_DEAL_ERROR,
  COMPLETE_DEAL_LOADING,
  FORCE_RUN_DEAL, FORCE_RUN_DEAL_ERROR,
  FORCE_RUN_DEAL_LOADING,
  ENABLE_BOT_MARKET,
  DISABLE_BOT_MARKET,
  UPDATE_BOT_CONFIG
} from "./constants";

const initialState = { data: {}, loading: false, forceLoading: false, error: false };

export const item = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case COMPLETE_DEAL_LOADING:
    case CANCEL_DEAL_LOADING:
    case FORCE_RUN_DEAL_LOADING:
      return {
        ...state,
        loading: true,
        forceLoading: true,
      }

    case FORCE_RUN_DEAL_ERROR:
    case CANCEL_DEAL_ERROR:
    case COMPLETE_DEAL_ERROR:
      return {
        ...state,
        loading: false,
        forceLoading: false,
      }

    case FORCE_RUN_DEAL:
    case CANCEL_DEAL:
    case COMPLETE_DEAL:
      return {
        ...state,
        error: false,
        loading: false,
        forceLoading: false,
        data: payload.item
      };

    default:
      return state;
  }
};

const runningDeals = (state = initialState, action) => {
  const {type, payload} = action;
  switch(type){
    case FORCE_RUN_DEAL:
    case CANCEL_DEAL:
    case COMPLETE_DEAL:
      return {
        ...state,
        error: false,
        loading: false,
        forceLoading: false,
        data: {
          ...state.data,
          items: state.data?.items?.map((item) => {
            return item.id === payload.item.id ? payload.item : item
          })
        }
      };

    default:
    return state;
  }
}

const activeMarkets = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_BOT_CONFIG: 
    return {
        ...state,
        data:{
          ...state.data,
          metadata:{
            allMarkets: payload.all
          }
        }
      };

    case ENABLE_BOT_MARKET:
    case DISABLE_BOT_MARKET:
      return {
        ...state,
        data: {
          ...state.data,
          items: state.data.items.map((item) => {
            if (item.market.base === payload.coin) {
              return { ...item, isExcluded: payload.isExcluded };
            }
            return item;
          }),
        },
      };

    default:
      return state;
  }
};

export default combineReducers({
  chart: withDataLoading(null, "DEAL_CHART"),
  item: withDataLoading(item, "DEAL"),
  list: withDataLoading(null, "DEALS"),
  trades: withDataLoading(null, "TRADES"),
  orders: withDataLoading(null, "ORDERS"),
  running: withDataLoading(runningDeals, "DEALS_RUNNING"),
  requests: withDataLoading(null, "TRADE_REQUESTS"),
  requestsOrders: withDataLoading(null, "TRADE_REQUEST_ORDERS"),
  requestsTrades: withDataLoading(null, "TRADE_REQUEST_TRADES"),
  technicalLogs: withDataLoading(null, "TECHNICAL_LOGS"),
  conditions: withDataLoading(null, "CONDITIONS"),
  indicators: withDataLoading(null, "INDICATORS"),
  marketInformation: withDataLoading(null, "MARKET_INFORMATION"),
  dealsMarkets: withDataLoading(null, "DEALS_MARKETS"),
  activeMarkets: withDataLoading(activeMarkets, "ACTIVE_MARKETS"),
  historyTradeRequest: withDataLoading(null, "HISTORY_TRADE_REQUESTS"),
});
