import React, { useState, useEffect, useCallback, useRef} from "react";
import InfiniteScroll from "react-infinite-scroller";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import qs from "qs";

import { setUnreadNotifications } from "store/user/actions";
import {
  deleteNotification,
  fetchNotifications,
  loadMoreNotifications,
  removeDeletedNotification,
  viewAllNotifications,
  deleteAllNotifications
} from "store/notification/actions";
import FallBack from "components/FallBack";
import Notification from "./Notification";
import NotificationsSearch from "./NotificationsSearch";
import * as Sentry from "@sentry/react";
import Error from "components/Error";
import CustomScrollbars from "components/CustomScrollbars";
import NotificationPreloader from "./NotificationPreloader";
import Loading from "components/Loading";
import { ReactComponent as NotificationIcon } from "assets/img/icons/notification.svg";
import notificationSmile from "assets/img/icons/notificationSmile.svg";
import NotificationItemPreloader from "./NotificationItemPreloader";
import { ReactComponent as BellIcon } from "assets/img/icons/notification-bell.svg";
import useClickOutside from "hooks/useClickOutside";

const Notifications = ({
  notifications: {
    items=[],
  },
  deleteNotification,
  unreadNotifications,
  fetchNotifications,
  removeDeletedNotification,
  loading,
  deleteLoading,
  error,
  loadMoreNotifications,
  viewAllNotifications,
  deleteAllNotifications,
  setUnreadNotifications,
  expanded
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [loadMore, setLoadMore] = useState(true);
  const [filtersQuery, setFiltersQuery] = useState("");
  const [search, setSearch] = useState({});
  const [createdAt, setCreatedAt] = useState({});
  const dropdownRef = useRef();
  useClickOutside(dropdownRef, () => setIsOpen(false), isOpen);

  useEffect(() => {
    setLoadMore(true);
    const filters = qs.stringify({
      filter: {
        ...search,
        createdAt: { ...createdAt }
      }
    }).replace(/[^=&]+=(?:&|$)/g, "");
    setFiltersQuery(filters);
  }, [search, createdAt, setFiltersQuery]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchNotifications(filtersQuery);
      if (res?.items) setHasMore(res.items.length === 5);
    };
    if (isOpen) {
      fetchData();
    }
  }, [isOpen, filtersQuery, fetchNotifications]);

  const handleLoadMore = useCallback(async () => {
    if (items.length >= 5) {
      const res = await loadMoreNotifications(items.length, filtersQuery);
      if (res.total === 0) {setLoadMore(false)}
      if (res.items.length < 5) { setHasMore(false); }
    }
  }, [loadMoreNotifications, filtersQuery, items]);

  const handleOpenNotifications = () => {
    setIsOpen(!isOpen);
    setLoadMore(true);
  };

  useEffect(() => {
    if (unreadNotifications !== "0" && items.length) {
      viewAllNotifications()
        .then(() => setUnreadNotifications("0"));
    }
  }, [items, unreadNotifications, viewAllNotifications, setUnreadNotifications]);

  const handleClear = () => items.length && deleteAllNotifications();

  const getLoader = () => {
    if (items.length > 5) return (
      <div className="d-flex flex-column align-items-center w-100">
        <div className="p b-4 w-100">
          <NotificationItemPreloader />
        </div>
        <div className="pb-4 pt-4">
          <Loading
            size={21}
            color="var(--info)"
          />
        </div>
      </div>
    );
  };

  return (
    <Sentry.ErrorBoundary fallback={<FallBack />}>
      <div
        className="my-auto position-relative"
        ref={dropdownRef}
      >
        <Notifications.ToggleButton
          className="mr-4"
          unreadNotifications={unreadNotifications}
        >
          <button
            className="btn btn-reset my-auto m-0"
            onClick={handleOpenNotifications}
            type="button"
          >
            <BellIcon/>
          </button>
          <span
            className={`cursor-pointer ${+unreadNotifications ? "d-block" : "d-none"}`}
            onClick={handleOpenNotifications}
          >
            {unreadNotifications}
          </span>
        </Notifications.ToggleButton>
        {
          isOpen ?
          <Notifications.DropdownMenu
            className="dropdown-navbar white-content bg-white"
            length={items.length}
            loading={loading}
            expanded={expanded}
          >
            <Notifications.HeaderWrapper items={items.length}>
              <p className="h4 mb-0 font-weight-500">Notifications</p>
              <span onClick={handleClear}>
                Clear all
              </span>
            </Notifications.HeaderWrapper>
            <NotificationsSearch
              onSetSearch={setSearch}
              onSetCreatedAt={setCreatedAt}
              createdAt={createdAt}
              search={search}
            />
            {error ?
              <Error error={error}/> :
              <CustomScrollbars.Styled
                autoHide
                autoHideTimeout={500}
                autoHideDuration={200}
              >
                {loading && !hasMore ? <NotificationPreloader/> : null}
                {isOpen &&
                  <CustomScrollbars.InfiniteScroll
                    pageStart={0}
                    loadMore={handleLoadMore}
                    hasMore={loadMore}
                    loader={getLoader()}
                    useWindow={false}
                  >
                    {
                      !items.length && !loading ?
                        <Notifications.EmptyList className="d-flex flex-column align-items-center justify-content-center">
                          <NotificationIcon />
                          <div className="d-flex align-items-center">
                            <img src={notificationSmile} alt=""/>
                            <p>You’re all caught up!</p>
                          </div>
                        </Notifications.EmptyList> :
                        items.map((n) =>
                          <Notification
                            key={n.id}
                            data={n}
                            deleteLoading={deleteLoading}
                            onDeleteNotification={deleteNotification}
                            onRemoveDeletedNotification={removeDeletedNotification}
                            deleteAllNotifications={deleteAllNotifications}
                          />
                        )
                    }
                  </CustomScrollbars.InfiniteScroll>
                }
              </CustomScrollbars.Styled>
            }
          </Notifications.DropdownMenu> : null
        }
      </div>
    </Sentry.ErrorBoundary>
  );
};

CustomScrollbars.Styled = styled(CustomScrollbars)`
  height: 420px !important;
  margin: 0;

  @media (max-width: 1200px) {
    height: calc(100vh - 20% - 255px) !important;
  }
`;

CustomScrollbars.InfiniteScroll = styled(InfiniteScroll)`
  margin: 0 !important;
  padding-right: 15px !important;
`;

Notifications.IconButton = styled.i`
  cursor: pointer;
  margin-bottom: 8px;
`;

Notifications.EmptyList = styled.div`
  margin-top: 32px;
  p {
    margin: 0 0 0 4px;
    font-weight: 500;
    font-size: 16px;
    color: #2E3148 !important;
  }
`;

Notifications.DropdownMenu = styled.div`
  width: 700px;
  height: ${(props) => !props.length && !props.loading ? "270px" : "550px"};
  border-radius: 8px !important;
  position: absolute;
  right: -29px;
  top: 42px;

  @media (max-width: 1200px) {
    height: ${({expanded}) => expanded ? "80vh" : "calc(100vh - 20%)" };
    position: fixed;
    left: 0;
    width: 100%;
    top: 100px;

    &:after {
      display: none;
    }
  }

  &:after {
    content: "";
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #fff;
    height: 8px;
    width: 16px;
    top: -10px;
    right: 53px;
  }
`;

Notifications.HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 15px 16px 15px;

  p {
    color: #333333;
  }

  span {
    font-weight: 500;
    font-size: 12px;
    color: ${(props) => props.items ? "#1D8CF8" : "rgb(29, 140, 248, 0.3)"};
    text-decoration: ${(props) => props.items ? "none" : "underline"};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

Notifications.UnreadNotifications = styled.div`
  display: flex;
  position: relative;

  .badge-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 45%;
    top: -20%;
    width: 14px;
    height: 14px;
    border: 2px solid #2E3148;
    border-radius: 100px;
  }

  i {
    color: #FFFFFF;
    &:hover {
      opacity: 0.5;
    }
  }
`;

Notifications.ToggleButton = styled.div`
  position: relative !important;

  span {
    position: absolute !important;
    top: -1px;
    right: -6px;
    background: #ED5F5F;
    color: #FFFFFF;
    border: 2px solid #1D1E2A;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    font-weight: 500;
    font-size: ${({unreadNotifications}) => +unreadNotifications > 9 ? "10px" : "13px"};
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  button {
    &:hover {
      svg {
        opacity: 1 !important;
      }
    }
  }

  &:hover {
    svg {
      opacity: 0.5;
    }
  }
`;

const mapStateToProps = (state) => ({
  notifications: state.notifications.data,
  unreadNotifications: state.me.data.unreadNotifications,
  unread: state.notifications.unread,
  loading: state.notifications.loading,
  deleteLoading: state.notifications.deleteLoading
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    deleteNotification,
    fetchNotifications,
    removeDeletedNotification,
    loadMoreNotifications,
    viewAllNotifications,
    deleteAllNotifications,
    setUnreadNotifications,
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
