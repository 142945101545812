import { cryptoSymbol } from "crypto-symbol";

export const sortOrdersDesc = (a, b) => {
  if (+a.price < +b.price) return 1;
  if (+a.price > +b.price) return -1;
  return 0;
};

export const sortOrdersAsc = (a, b) => {
  if (+a.price < +b.price) return -1;
  if (+a.price > +b.price) return 1;
  return 0;
};

//serialize from tuple to readable object
export const convertOrderBook = (orders) => {
  const maxPriceDecimalCount = Math.max(
    ...orders.map(([price]) => (price.toString().split('.')[1]?.length || 0))
  );
  const maxQuantityDecimalCount = Math.max(
    ...orders.map(([price, quantity]) => (quantity.toString().split('.')[1]?.length || 0))
  );
  return orders.map(([price, quantity]) => ({
    price: Number(price).toFixed(maxPriceDecimalCount),
    quantity: Number(quantity).toFixed(maxQuantityDecimalCount),
  }));
};

export const sortWithSumOrderBook = (orders, type) => {
  const sortFunc = type === "bids" ? sortOrdersDesc : sortOrdersAsc;
  // Calculate sum
  let sum = 0;
  const sortedOrders = orders.sort(sortFunc).map((order, index) => {
    sum += order.price * order.quantity;
    return {
      ...order,
      total: order.price * order.quantity,
      sum
    }
  });
  return sortedOrders;
};

// Update quantity for existing orders (existing prices) and add new orders (with new, unique price)
export const updateOrders = (prevOrders=[], orders, type) => {
  const updatedOrders = prevOrders.map((order) => {
    const samePriceOrder = orders.find((o) => +o.price === +order.price);
    if (samePriceOrder) return { ...order, quantity: +samePriceOrder.quantity };
    return order;
  });
  const newOrders = orders.filter((order) => updatedOrders.some((o) => +o.price !== +order.price));
  const updatedOrdersWithUniquePrices = [ ...newOrders, ...updatedOrders ].filter((order, index, arr) => {
    return arr.findIndex((o) => o.price === order.price) === index && +order.quantity;
  });
  return sortWithSumOrderBook(updatedOrdersWithUniquePrices, type);
};

const groupPrice = (value, group) => {
  if (group >= 10 && value >=10) return Math.round(value / group) * group;
  return value.toFixed(group);
};

export const updateGroupPrices = (orders, group={}) => orders.map((order) => ({
  ...order,
  price: groupPrice(+order.price, group.value),
}));

export const findDuplicatesAndSum = (inptArr) => {
  const duplicateIndex = {};
  const outputArr = [];
  for (let i = 0; i < inptArr.length; i++) {
    const item = inptArr[i];
    const collisionIndex = duplicateIndex[item.price];
    if (collisionIndex > -1) {
      outputArr[collisionIndex].quantity = +outputArr[collisionIndex].quantity + +item.quantity;
    } else {
      outputArr.push(item);
      duplicateIndex[item.price] = outputArr.length - 1;
    }
  }
  return outputArr;
};

export const getSpread = (asks=[], bids=[]) => {
  if (!asks.length || !bids.length) return 0;
  const fixToVal = asks?.[0]?.price?.toString().split(".")?.[1]?.length || 0;
  const spread = asks?.[0]?.price - bids?.[0]?.price;
  return Math.abs(spread).toFixed(fixToVal);
};

export const getSpreadPercent = (asks=[], bids=[], spread) => {
  if (!asks.length || !bids.length) return 0;
  const avgPrice = (+bids?.[0]?.price + +asks?.[0]?.price) / 2;
  return (spread / avgPrice) * 100;
};


export const sortTickers = (tickers, sortBy, sortOrder) => {
  if (!sortBy && !sortOrder) return tickers;

  const sortedTickers = [...tickers];
  sortedTickers.sort((a, b) => {
    const valueA = sortBy === "base" ? a.market.base : +a[sortBy];
    const valueB = sortBy === "base" ? b.market.base : +b[sortBy];
    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }

    return sortOrder === 'DESC' ? comparison * -1 : comparison;
  });

  return sortedTickers;
};

const { nameLookup } = cryptoSymbol({});

const customCoinsNames = {
  "PAX": "Paxos Standard",
  "REPV2": "AUGUR",
  "FCT2": "FirmaChain",
  "BCHSV": "BCHSV",
  "SSG": "Surviving Soldiers",
  "TRXBULL": "3X Long TRX Token",
  "BCHC": "BitCherry",
  "LUMI": "Lumi Credits",
  "FREN": "Fren",
  "XRPBEAR": "3x Short XRP Token",
  "DICE": "Klaydice",
  "LIVE": "TRONbetLive",
  "PRINTS": "FingerprintsDAO",
  "WLUNA": "WLUNA",
  "ETHS": "ETHPoS (IOU)",
  "ADABEAR": "3X Short Cardano Token",
  "XLMBEAR": "3X Short Stellar",
  "EOSBEAR": "3x Short EOS Token",
  "XLMBULL": "3X Long Stellar Token",
  "ERSDL": "unFederalReserve",
  "IBVOL": "Inverse Bitcoin Volatility Token",
  "MQST": "MonsterQuest",
  "ZLOT": "zLOT Finance",
  "ONEINCH": "1inch Network",
  "TRXBEAR": "3X Short TRX Token",
  "BSVBEAR": "3x Short Bitcoin SV Token",
  "COOL": "COOL",
  "XFLR": "XFLR",
  "DC": "Dogechain",
  "LINKBEAR": "3X Short Chainlink Token",
  "ETHW": "ETHPoW (IOU)",
  "BSVBULL": "3x Long Bitcoin SV Token",
  "ACH1": "ACH1",
  "PERX": "PeerEx",
}

export const getCoinName = (coin) => {
  const name = nameLookup(coin, {exact: true});
  if (name) return name;

  if (!name) {
    for (const key in customCoinsNames) {
      if (key === coin) {
        return customCoinsNames[key]
      }
    }
  }
};

export const filterUniqueByProp = (array, prop) => {
  const uniqueQuotes = array.reduce((accumulator, item) => {
    return accumulator.add(item?.market?.[prop]);
  }, new Set());

  return Array.from(uniqueQuotes);
};

export const getUpdatedTickers = (items, updated) => items.map((item) => {
  if (item.symbol === updated.symbol) return { ...item, ...updated };
  return item;
});
