import React from "react";
import { FormGroup } from "reactstrap";
import qs from "qs";
import styled from "styled-components";

import { mergeStyles } from "components/SelectInput/utils";
import SelectInput from "components/SelectInput";
import LookupCoins from "components/LookupCoins";

const marketStyles = {
  menu: () => ({
    maxWidth: "200px",
  })
};

const MarketStatisticsFilters = ({
  history,
  markets,
  setEmptyFilter
}) => {
  const { filter, sort } = qs.parse(history.location.search, { ignoreQueryPrefix: true });

  const handleFilter = (value) => {
    setEmptyFilter({ filter: { ...filter, ...value } })
    history.push({
      search: qs.stringify({ filter: { ...filter, ...value }, sort: { ...sort } }).replace(/[^=&]+=(?:&|$)/g, "")
    });
  };

  const getSingleOption = (market, size) => {
    return {
      label: market,
      value: market,
      secondLabel: <LookupCoins coin={market.split('-')[0]} />,
      icon: {
        type: "cryptocurrency",
        size: size,
        code: market.split("-")[0],
      },
    }
  };

  return (
    <MarketStatisticsFilters.FormWrap className="mb-0 p-0 ml-auto mr-0">
      <SelectInput
        isVirtualized
        isLoading={markets?.loading}
        isClearable
        placeholder="Market"
        styles={mergeStyles(marketStyles)}
        isMulti
        name="market"
        value={filter?.market?.in?.map((market) => getSingleOption(market, "selectSize"))}
        onChange={(values) => {
          handleFilter({
            market: { in: values ? values.map((e) => e.value) : "" }
          });
        }}
        options={
          markets?.data?.length
          &&
          markets?.data?.map((market) => getSingleOption(market, "smallSize"))}
      />
    </MarketStatisticsFilters.FormWrap>
  );
};

MarketStatisticsFilters.FormWrap = styled(FormGroup)`
  width: 40%;

  @media(max-width: 576px){
    width:100%;
  }
`;


export default MarketStatisticsFilters;
