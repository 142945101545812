import React from "react";
import {CardBody, Col} from "reactstrap";
import styled from "styled-components";
import classNames from "classnames";
import LookupCoins from "components/LookupCoins";
import Icon from "components/Icon";
import AllocationRebalancing from "components/AllocationRebalancing";
import AllocationInput from "./AllocationInput";

const ManageAllocationRow = ({
  investment,
  formik,
  allCredentialsInvestments,
  index,
  credentialsInvestment,
  enabled,
  initialPlannedCount
}) => {
  return (
    <ManageAllocationRow.Row className="d-flex flex-column flex-md-row align-items-center">
      <Col xs="12" md="6" className="d-flex flex-column flex-sm-row align-items-sm-center px-0">
        <div className="d-flex align-items-center text-nowrap col-sm-5 px-0">
          <Icon
            type="cryptocurrency"
            size="thumb"
            code={investment.asset}
            className="my-auto mr-2"
          />
          <div className="d-flex align-items-center">
            <p className="mb-0 text-white font-weight-500">{investment.asset}</p>
            <p className="text-white-35 ml-1 mb-0 font-weight-300">
              <LookupCoins coin={investment.asset}/>
            </p>
          </div>
        </div>

        <div className="d-flex align-items-center col-md-7 px-0">
          <div className="d-flex align-items-center ml-md-3 mr-1">
            <p className="mb-0 text-white font-sm">
              {investment?.balance?.available?.total || 0}
            </p>
            <p className="mb-0 text-white-35 ml-1 font-sm font-weight-300">
              {investment.balance.available.currency}
            </p>
          </div>
        </div>
      </Col>

      <Col xs="12" md="6" className="d-flex flex-column flex-sm-row align-items-center mt-3 mt-md-0 px-0">

        <Col xs="12" sm="8" md="7" className="pl-0 pr-2">
          <AllocationRebalancing
            values={formik.values}
            setFieldValue={formik.setFieldValue}
            index={index}
            credentialsInvestment={credentialsInvestment}
            isEdited
            counter={formik?.values?.credentials[index]?.counter}
            realPercent={credentialsInvestment?.items[index]?.realPercent}
            plannedPercent={credentialsInvestment?.items[index]?.plannedPercent}
            enabled={enabled}
            initialPlannedCount={initialPlannedCount}
          />
        </Col>

        <Col xs="12" sm="4" md="5" lg="5" className="d-flex align-items-center px-0 mt-3 mt-sm-0">
          <Col xs="3" sm="4" className="d-flex flex-column px-0 ml-2 mr-2">
            <AllocationInput
              index={index}
              realPercent={formik?.values?.credentials[index]?.realPercent}
              plannedPercent={formik?.values?.credentials[index]?.plannedPercent}
              initialPlannedCount={initialPlannedCount}
              values={formik.values}
              setFieldValue={formik.setFieldValue}
              allCredentialsInvestments={allCredentialsInvestments}
              enabled={enabled}
            />
          </Col>

          <Col sm="3">
            <div className="text-nowrap px-0 ml-2 ml-lg-4">
              {
                enabled ?
                  <p className={classNames("mb-0", {
                    "text-white-35": formik.values.credentials[index].counter === 0,
                    "text-danger": formik.values.credentials[index].counter < 0,
                    "text-success": formik.values.credentials[index].counter > 0,
                  })}>
                    {`
                    ${formik.values.credentials[index].counter >= 0 ? "+" : ""}
                    ${formik.values.credentials[index].counter.toFixed(2)}
                    %`}
                  </p>
                  : <p className="mb-0 text-white-35">+ 0.00 %</p>
              }
            </div>
          </Col>
        </Col>
      </Col>
    </ManageAllocationRow.Row>
  )
}

ManageAllocationRow.Row = styled(CardBody)`
  margin: 0 30px 6px 30px;
  padding: 12px 24px !important;
  background: #2E3148;
  border-radius: 8px;
`;

export default ManageAllocationRow;
