import React from "react";
import {CardHeader} from "reactstrap";
import {DelayInput} from "react-delay-input";
import {dynamicRgbaColors} from "charts/helpers";
import styled from "styled-components";

import {ReactComponent as TitleIcon} from "assets/img/icons/money-change.svg";
import {ReactComponent as Search} from "assets/img/icons/search.svg";
import Button from "components/Button";
import ColorfulBar from "components/ColorfulBar";
import FormGroupWithSymbols from "components/FormGroupWithSymbols";
import LookupCoins from "components/LookupCoins";

const ExchangeHeaderModal = ({
  isToggle, 
  balances,
  handleSearch,
  estimated
}) => {
  const itemsWithPercentCount = balances?.items?.reduce((acc, item) => item.percent ? acc + 1 : acc + 0, 0);

  return (
    <ExchangeHeaderModal.Header className="d-flex flex-column px-4 pb-4 pt-3">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex flex-column flex-sm-row align-items-sm-center w-100">
          <div className="d-flex align-items-center text-nowrap">
            <TitleIcon/>
            <p className="mb-0 ml-2 mr-4 text-white font-weight-500">
              Exchange balances
            </p>
          </div>
          <div className="mt-3 mt-sm-0 searchInput">
            <FormGroupWithSymbols symbolLeft={<Search className="search"/>}>
              <DelayInput
                autoComplete="off"
                delayTimeout={700}
                type="string"
                name="search"
                className="form-control w-100"
                placeholder="Search coins"
                onChange={({target}) => handleSearch(target.value)}
              />
            </FormGroupWithSymbols>
          </div>
        </div>
        <Button
          color="gray"
          cancel={true}
          onClick={isToggle}
          className="position-absolute right-3 top-3"
        />
      </div>

      <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between mt-4">
        <div className="d-flex align-items-end text-nowrap">
          <h1 className="mb-0 text-white">
            {balances?.items?.length} Coins
          </h1>
          <p className="mb-0 ml-3 text-white-35">
            {`~${estimated?.amount || 0} ${estimated?.currency} `}
          </p>
        </div>
        <div className="color-bar d-flex flex-row align-items-center mt-3 mt-sm-0">
          {
            balances?.items?.map((item, index) => {
              if (!item.percent) return null;
              return (
                <ColorfulBar
                  key={item.asset}
                  title={item.asset}
                  value={item.percent}
                  itemsLength={balances?.items?.length}
                  itemsWithValueLength={itemsWithPercentCount}
                  index={index}
                  tooltipText={<LookupCoins coin={item.asset}/>}
                  withSpacing
                  color={dynamicRgbaColors()}
                />
              )
            })
          }
        </div>
      </div>
    </ExchangeHeaderModal.Header>
  )
}

ExchangeHeaderModal.Header = styled(CardHeader)`
  @media (max-width: 576px) {
    .searchInput, .color-bar {
      width: 100% !important;
    }
  }

  .color-bar {
    width: 50%;
  }

  .search {
    path {
      stroke: rgba(255, 255, 255, 0.35);
    }
  }
`;

export default React.memo(ExchangeHeaderModal);
