import React from "react";
import SkeletonPreloader from "components/SkeletonPreloader";
import TablePreloader from "components/TablePreloader";

const GSPreloader = ({withHeader, withTable}) => {
  return (
    <div className="overflow-hidden">
      {
        withHeader &&
        <div className="d-flex align-items-center justify-content-between p-4">
          <div className="d-flex align-items-center">
            <SkeletonPreloader
              circle
              height={20}
              width={20}
            />
            <SkeletonPreloader
              height={16}
              width={113}
              className="ml-2"
            />
          </div>

          <div className="d-flex align-items-center">
            <SkeletonPreloader
              height={38}
              width={145}
              borderRadius="6px"
            />
            <SkeletonPreloader
              height={38}
              width={145}
              borderRadius="6px"
              className="ml-2"
            />
          </div>
        </div>
      }

      {
        withTable &&
        <TablePreloader
          rowTimes={10}
          tdHeight={12}
          thHeight={9}
          thWidth={[57, 57, 57, 57, 57]}
          tdWidth={[74, 56, 48, 92, 180]}
        />
      }
    </div>
  )
}

export default GSPreloader;
