import React, {
  useLayoutEffect, 
  useState
} from "react";
import {
  useDispatch, 
  useSelector
} from "react-redux";
import styled from "styled-components";
import {useFormikContext} from "formik";

import ToggleSwitch from "components/ToggleSwitch";
import MartingaleForm from "./MartingaleForm";
import MartingaleTable from "./MartingaleTable";
import {getAvailableMarkets} from "store/markets/actions";
import MartingaleTablePreloader from "./Martingale/MartingaleTablePreloader";
import AboutAverageDownStrategy from "./modals/AboutAverageDownStrategy";
import {ReactComponent as Chevron} from "assets/img/icons/dropdownIndicator.svg";

const Martingale = ({ 
  submitted, 
  botData, 
  currentStep
}) => {
  const [isMartingaleTable, setIsMartingaleTable] = useState(false);
  const [isOpenAbout, setIsOpenAbout] = useState(false);
  const {values, errors, setFieldValue} = useFormikContext();
  const dispatch = useDispatch();

  const availableItems = useSelector((state) => state.availableMarkets.data);
  const availableLoading = useSelector((state) => state.availableMarkets.loading);
  const {investment} = useSelector((state) => state.botWizard);

  useLayoutEffect(() => {
    if (isMartingaleTable && (currentStep === 2 || botData)) dispatch(getAvailableMarkets(investment.id, `?limit=500`));
  }, [botData, dispatch, isMartingaleTable, investment.id, currentStep]);

  const handleMartingale = (value, setFieldValue) => {
    setFieldValue("martingale", value);
    if (values?.martingale) {
      setFieldValue('deviation', null);
      setFieldValue('martingalePercent', null);
      setFieldValue('maxTradeRequests', null);
    } else {
      setFieldValue('deviation', botData?.tradeSettings?.deviation);
      setFieldValue('martingalePercent', botData?.tradeSettings?.martingalePercent);
      setFieldValue('maxTradeRequests', botData?.tradeSettings?.maxTradeRequests);
    }
  };

  const renderTable = () => {
    if (isMartingaleTable) {
      return availableLoading ? <MartingaleTablePreloader/> : availableItems && <MartingaleTable/>
    }
    return null
  }

  return (
    <Martingale.Wrapper>
      <Martingale.Body className="martingale d-flex-column">
        <div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between mb-1">
          <div className="d-flex align-items-center">
            <ToggleSwitch
              height={18}
              width={36}
              onColor="#26366b"
              offColor="#4b4c5e"
              onHandleColor="#00F2C3"
              offHandleColor="#FFFFFF59"
              activeBoxShadow="none"
              checked={values?.martingale}
              onChange={(value) => handleMartingale(value, setFieldValue)}
            />
            <p className="martingale font-weight-500 text-white ml-3 mb-0 mr-2">
              Average down strategy
            </p>
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                setIsOpenAbout(true);
              }}
              className="text-info font-weight-500"
            >
              What is this?
            </a>
          </div>
        </div>
        {
          values?.martingale ?
            <MartingaleForm
              submitted={submitted}
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
            />
            : null
        }
      </Martingale.Body>
      {
        values?.martingale &&
        <>
          <Martingale.Table onClick={() => setIsMartingaleTable(!isMartingaleTable)}>
            <p className="mb-0 text-white">
              {isMartingaleTable ? "Hide martingale calculation table" : "Show martingale calculation table"}
            </p>
            <Chevron className="chevron"/>
          </Martingale.Table>
          {
            renderTable()
          }
        </>
      }
      <AboutAverageDownStrategy
        isOpen={isOpenAbout}
        setIsOpen={setIsOpenAbout}
      />
    </Martingale.Wrapper>
  )
}

Martingale.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #363A54;
  border-radius: 12px;
  margin-bottom: 16px;
  .chevron {
    transition: transform .2s ease-in-out;
    transform: ${(props) => props.isMartingaleTable ? "rotate(-180deg)" : null};
  }
`;

Martingale.Body = styled.div`
  background: #2E3148;
  padding: 24px;
  border-radius: 10px 10px 0 0;
`;

Martingale.Table = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(58, 61, 90, 1);
  padding: 8px 24px;
  cursor: pointer;
`;

export default Martingale;
