import React from "react";
import {useDispatch} from "react-redux";
import {DelayInput} from "react-delay-input";
import {
  FormGroup, 
  Col,
  Row} from "reactstrap";
  import styled from "styled-components";

import {setBotName} from "store/bots/wizard/actions";

const InvestmentHeader = ({name}) => {
  const dispatch = useDispatch();

  return (
    <>
      <InvestmentHeader.Header>
        <div>
          <p className="mb-1 font-sm text-white-35">Step 1 of 3</p>
          <h3 className="mb-0 text-white font-weight-500">
            Name your bot and select a base coin from your credentials
          </h3>
        </div>
      </InvestmentHeader.Header>
        <InvestmentHeader.Name>
          <Row>
            <Col
              xs="12"
              md="6"
            >
              <h3 className="mb-2 text-white font-weight-500">
                Give your Bot a name <span className="text-danger">*</span>
              </h3>
              <p className="mb-1 text-white-75 font-sm">
                This will help you easily identify and manage your trading strategy.
              </p>
              <p className="mb-3 text-white-75 font-sm">
                Simply enter a unique name that you`ll remember and start trading with confidence.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              xs="12"
              md="3"
            >
              <div className="mt-4 mb-3">
                <p className="font-xs text-danger mb-1 mb-0">
                  {name === "" && "* Required"}
                  {name?.length === 100 && "Bot name should not exceed 100 characters"}
                </p>
                <FormGroup className="mb-0">
                  <DelayInput
                    autocomplete="off"
                    delayTimeout={700}
                    minLength={1}
                    maxLength={100}
                    type="text"
                    name="botName"
                    className={`form-control ${name === "" || name?.length === 100 ? "error" : ""}`}
                    placeholder="Bot name"
                    value={name}
                    onChange={({target}) => dispatch(setBotName(target.value))}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
        </InvestmentHeader.Name>
    </>
  )
}

InvestmentHeader.Header = styled.div`
  background: #2E3148;
  border-radius: 6px 6px 0 0;
  padding: 24px 32px;
`;

InvestmentHeader.Name = styled.div`
  padding: 32px;
  border-bottom: 1px solid #2E3148;
`;

export default InvestmentHeader;
