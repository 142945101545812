import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import moment from "moment";

import {formatterToNull} from "helpers/valuesFormatters";
import {TRADE_HISTORY_TABLE_HEAD} from "models/credentials/enum/tables";
import CustomScrollbars from "components/CustomScrollbars";
import SortTableHead from "components/SortTableHead";
import StyledTable from "components/StyledTable";
import useFilterAndSorting from "hooks/useFilterAndSorting";

const TradeHistoryTable = ({data = [], id}) => {
  const { sort, handleSorting } = useFilterAndSorting(`/credentials/${id}/trade-history`);

  return (
    <CustomScrollbars
      autoHideTimeout={500}
      autoHideDuration={500}
      autoHeight='true'
      autoHeightMax={Number.MAX_VALUE}
    >
      <StyledTable className="mb-0" paddingsThead="1rem">
        <SortTableHead
          handleSorting={handleSorting}
          sort={sort}
          theadItems={TRADE_HISTORY_TABLE_HEAD}
        />
        <tbody className="font-sm">
          {
            data.map((item) =>
              <tr key={item.orderNumber}>
                <td>
                  <p className={classNames("text-uppercase mb-0 font-weight-500", {
                    "text-success": item.type === "buy",
                    "text-danger": item.type === "sell"
                  })}>
                    {item.type}
                  </p>
                </td>
                <td>
                  <StyledAmount amount={0}>{item.orderNumber}</StyledAmount>
                </td>
                <td>
                  <StyledAmount amount={0}>{formatterToNull(item.tradeId)}</StyledAmount>
                </td>
                <td>
                  <div className="d-flex">
                    <StyledAmount amount={1}>{item.market.base}</StyledAmount>
                    <StyledAmount amount={0} className="mx-1">/</StyledAmount>
                    <StyledAmount amount={1}>{item.market.quote}</StyledAmount>
                  </div>
                </td>
                <td>
                  <div className="d-flex">
                    <StyledAmount amount={item.amount.amount}>{formatterToNull(item.amount.amount)}</StyledAmount>
                    <p className="text-white-35 ml-1 mb-0">{item.amount.currency}</p>
                  </div>
                </td>
                <td>
                  <StyledAmount amount={item.rate}>{formatterToNull(item.rate)}</StyledAmount>
                </td>
                <td>
                  <div className="d-flex">
                    <StyledAmount amount={item.quantity.amount}>{formatterToNull(item.quantity.amount)}</StyledAmount>
                    <p className="text-white-35 ml-1 mb-0">{item.quantity.currency}</p>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <StyledAmount amount={item.fee.amount}>{formatterToNull(item.fee.amount)}</StyledAmount>
                    <p className="text-white-35 ml-1 mb-0">{item.fee.currency}</p>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <StyledAmount amount={item.fee.percent}>{formatterToNull(item.fee.percent)}</StyledAmount>
                    <p className="mb-0 text-white-35 ml-1">%</p>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <p className="mb-0 text-white">
                      {moment(item.date).format("DD.MM.YY")}
                    </p>
                    <p className="text-white-35 ml-1 mb-0">
                      {moment(item.date).format("HH:mm")}
                    </p>
                  </div>
                </td>
              </tr>
            )
          }
        </tbody>
      </StyledTable>
    </CustomScrollbars>
  )
};

const StyledAmount = styled.p`
  color: ${(props) => props.amount > 0 ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.35)"} !important;
  margin-bottom: 0;
  white-space: nowrap;
`;

export default TradeHistoryTable;
