import React from "react";
import { Card } from "reactstrap";
import { times } from "lodash";

import SkeletonPreloader from "components/SkeletonPreloader";

const SignalsListPreloader = () => {
  return (
    times(10, (e) =>
        <Card className="d-flex flex-column  p-4 mb-2" key={e}>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="d-flex justify-content-center justify-content-md-normal align-items-start align-items-lg-center">
              <SkeletonPreloader
                height={40}
                width={40}
              />
              <div className="ml-3">
                <div className="d-flex align-items-center mb-2">
                  <SkeletonPreloader
                    height={20}
                    width={40}
                  />
                  <SkeletonPreloader
                    className="ml-1"
                    height={20}
                    width={45}
                  />
                  <SkeletonPreloader
                    className="ml-3"
                    height={20}
                    width={53}
                  />
                </div>
                <SkeletonPreloader
                  height={17}
                  width={180}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center justify-content-md-normal mt-4 mt-md-0">
              <div className="d-flex flex-column mr-3">
                <div className="d-flex mb-2">
                  <SkeletonPreloader
                    className=" mr-2"
                    height={18}
                    width={18}
                  />
                  <SkeletonPreloader
                    height={20}
                    width={146}
                  />
                </div>
                <SkeletonPreloader
                  className="ml-auto mr-0"
                  height={19}
                  width={109}
                />
              </div>
              <SkeletonPreloader
                borderRadius="4px"
                height={40}
                width={40}
              />
            </div>
          </div>
          <div className="mx-auto mt-5 w-100">
              <SkeletonPreloader
                className="w-100"
                height={16}
              />
            </div>
          <div className="d-flex flex-row align-items-center justify-content-between mt-4">
            <SkeletonPreloader
              circle
              height={20}
              width={20}
              className="mr-1"
            />
            <SkeletonPreloader
              height={20}
              width={120}
              className="ml-2"
            />
            <SkeletonPreloader
              height={35}
              width={80}
              className="ml-auto mr-0"
            />
          </div>
        </Card>
    )
  );
};


export default SignalsListPreloader;