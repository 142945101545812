import createAction from "../thunkFactory";
import { RESET_REBALANCE } from "./constants";

export const fetchRebalance = (id, signal) => createAction("REBALANCE", (fetch) => {
  return fetch.get(`/app/rebalances/${id}`, { signal });
});

export const fetchRebalanceTradeRequests = (id, signal) => createAction("REBALANCE_TRADE_REQUESTS", (fetch) => {
  return fetch.get(`/app/rebalances/${id}/trade-requests`, { signal });
});

export const resetRebalance = () => ({ type: RESET_REBALANCE });
