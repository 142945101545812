import React from "react";
import styled from "styled-components";
import {Card, CardBody, CardHeader} from "reactstrap";
import SkeletonPreloader from "components/SkeletonPreloader";

const TelegramPreloader = () => {
  return (
    <Card className="p-3">
      <CardHeader className="d-flex flex-row align-items-center p-2 mb-2">
        <SkeletonPreloader
          className="mr-2"
          circle={true}
          width={16}
          height={16}
        />
        <SkeletonPreloader
          className="w-50"
          height={16}
        />
      </CardHeader>
      <CardBody className="p-2">
        <SkeletonPreloader
          count={5}
          height={12}
        />
        <SkeletonPreloader
          height={12}
          className="mt-4 mb-2 w-50"
        />
        <TelegramPreloader.Input />
        <div className="mt-3 d-flex justify-content-end">
          <TelegramPreloader.Button className="p-2 w-100 d-flex justify-content-center"/>
        </div>
      </CardBody>
    </Card>
  )
}

TelegramPreloader.Input = styled.div`
  background: #32354E;
  opacity: 0.5;
  border-radius: 6px;
  height: 38px;
  width: 100%;
`

TelegramPreloader.Button = styled.div`
  background: linear-gradient(90deg, #535462 0%, rgba(83, 84, 98, 0) 100%);
  border-radius: 6px;
  height: 38px;
`;

export default TelegramPreloader;
