import React from "react";
import { CardHeader } from "reactstrap";
import PropTypes from "prop-types";

import Button from "components/Button";
import { ReactComponent as Rocket } from "assets/img/icons/rocket-outline.svg";
import SkeletonPreloader from "components/SkeletonPreloader";

const SignalsTableHeader = ({
  loading,
  loadingPage,
  title,
  withButton,
  handleCreateItem
}) => {
  return (
    <CardHeader className="p-0 px-4 d-flex justify-content-between mb-3">
      {(loadingPage || loading) ? (
        <>
          <SkeletonPreloader
            height={20}
            width={20}
          />
          <SkeletonPreloader
            height={19}
            width={136}
            className="ml-2 mb-0"
          />
        </>
      ) : (
        <>
          <div className="d-flex align-items-center p-0">
            <Rocket />
            <p className="m-0 ml-2 text-white font-weight-500">
              {title}
            </p>
          </div>
          {
            withButton
              ? <Button
                color="blue"
                loading={loading}
                disabled={loading}
                onClick={handleCreateItem}
              >
                Create
              </Button>
              : null
          }
        </>
      )
      }
    </CardHeader>
  );
};

SignalsTableHeader.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  loadingPage: PropTypes.bool,
  withButton: PropTypes.bool,
  handleCreateItem: PropTypes.func
};

export default SignalsTableHeader;
