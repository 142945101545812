import React from "react";
import {Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import SkeletonPreloader from "components/SkeletonPreloader";

const GooglePreloader = () => {
  return (
    <div>
      <Card className="p-2">
        <CardHeader>
          <SkeletonPreloader
            height={20}
            width={150}
          />
        </CardHeader>
        <CardBody>
          <SkeletonPreloader
            height={12}
            count={3}
          />
        </CardBody>
        <CardFooter className="pt-0 d-flex">
          <div className="d-flex w-100">
            <SkeletonPreloader
              circle={true}
              className="mr-2 my-auto"
              height={40}
              width={40}
            />
            <div className="d-flex flex-column w-75">
              <SkeletonPreloader
                height={12}
                className="mt-1 mb-1 w-100"
              />
              <SkeletonPreloader
                height={12}
                className="mt-1 mb-1 w-50"
              />
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  )
}

export default GooglePreloader;
