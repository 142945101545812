import React from "react";
import { useHistory } from "react-router-dom";
import { DelayInput } from "react-delay-input";
import {
  Col,
  Row,
  FormGroup
} from "reactstrap";
import qs from "qs";

import { beforeToday } from "helpers/DatepickerValidation";
import DateField from "components/DateField";

const Filters = ({ location, loading }) => {
  const history = useHistory();
  const { filter } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const handleFilter = (value) => {
    history.push({
      pathname: "/strategies",
      search: qs.stringify({ filter: { ...filter, ...value }, page: 1 }).replace(/[^=&]+=(?:&|$)/g, "")
    });
   };

  return(
    <form>
      <Row>
        <Col xs="4">
          <FormGroup>
            <DelayInput
              disabled={loading}
              delayTimeout={700}
              minLength={2}
              type="text"
              name="name"
              className="form-control"
              placeholder="Name"
              value={filter && filter.name ? filter.name.like : ""}
              onChange={({ target }) => handleFilter({ name: { like: target.value } })}
            />
          </FormGroup>
        </Col>
        <Col xs="4">
          <FormGroup>
            <DelayInput
              disabled={loading}
              delayTimeout={700}
              minLength={2}
              type="text"
              name="comment"
              className="form-control"
              placeholder="Comment"
              value={filter && filter.comment ? filter.comment.like : ""}
              onChange={({ target }) => handleFilter({ comment: { like: target.value} })}
            />
          </FormGroup>
        </Col>
        <Col xs="2">
          <DateField
            onChange={(date) => handleFilter({
              createdAt: { gte: date }
            })}
            isValidDate={beforeToday}
            inputProps={{
              className: "form-control",
              name: "createdFrom",
              placeholder: "Created from",
              value: filter && filter.createdAt ? filter.createdAt.gte : "",
              disabled: loading,
              readOnly: true
            }}
          />
        </Col>
        <Col xs="2">
          <DateField
            onChange={(date) => handleFilter({
              createdAt: { lte: date }
            })}
            isValidDate={(current) => beforeToday(current)}
            inputProps={{
              className: "form-control",
              name: "createdTo",
              placeholder: "Created to",
              value: filter && filter.createdAt ? filter.createdAt.lte : "",
              disabled: loading,
              readOnly: true,
            }}
          />
        </Col>
      </Row>
    </form>
  );
};

export default Filters;
