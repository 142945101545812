import React, { useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Filters from "./compounds/Filters";
import Bot from "./compounds/Bot";
import PaginationPanel from "components/PaginationPanel";
import Button from "components/Button";
import BotsListPreload from "./preloaders/BotsListPreload";
import EmptyFiltersListingState from "../../shared/ui/state/EmptyFiltersListingState";
import EmptyDataList from "../credentials/states/EmptyDataList";
import { resetBots, getBots } from "store/bots/actions";
import usePagination from "hooks/usePagination";
import useRequestCancellation from "hooks/useRequestCancellation";

const BotsList = ({ location }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { handlePaginate } = usePagination("/bots", true);
  const { useAbortableEffect } = useRequestCancellation();

  const {items, total, page} = useSelector((state) => state.bots.list.data);
  const loading = useSelector((state) => state.bots.list.loading);
  const error = useSelector((state) => state.bots.list.error);

  useAbortableEffect((signal) => {
    const queryString = `${location.search}${location.search ? "&" : "?"}limit=12`
    dispatch(getBots(queryString, signal));
    return () => dispatch(resetBots());
  }, [location.search, dispatch]);

  const handleToCreateBot = () => history.push({pathname: '/bots/create'});

  const renderState = () => {
    if (loading) return <BotsListPreload />;
    if (history.location.search?.includes("filter") && !items?.length) return <EmptyFiltersListingState/>;
    if (!items?.length || error) {
      return (
        <EmptyDataList
          title="You have no bots at the moment"
          description="Please create bots first to have them displayed here and have an ability to work with your investment capital"
          buttonText="Create new bot"
          onHandleClick={handleToCreateBot}
        />
      )
    }
  }

  useEffect(() => {
    window.analytics.page("/bots");
  }, []);

  return(
    <>
      <div className="list-page-header d-flex align-items-center mt-4 justify-content-between">
        <h1 className="mb-0 text-white font-weight-500">
          Bots
        </h1>
        {
          !history.location.search?.includes("filter") ?
          <Button
            color="blue"
            className="m-0"
            onClick={handleToCreateBot}
          >
            Create new bot
          </Button> : null
        }
      </div>
      <Filters
        location={location}
        items={items}
        loading={loading}
      />
      {renderState()}
      <BotsList.BotsWrapper>
        {
          items?.map((bot, index) => {
              return (
                <Link to={`/bots/${bot.id}`} key={bot.id}>
                  <Bot
                    key={bot.id}
                    bot={bot}
                    index={index}
                  />
                </Link>
              )
            }
          )
        }
      </BotsList.BotsWrapper>
      <PaginationPanel
        activePage={page}
        totalItemsCount={total}
        totalPagesView={12}
        onChange={handlePaginate}
      />
    </>
  );
};

BotsList.BotsWrapper = styled.div`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;

  @media (min-width: 578px) and (max-width: 1199.98px) {
    display: grid;
    grid-template-columns: 48% 48%;
    justify-content: space-between;
  }

  @media (max-width: 577.98px) {
    display: flex;
    flex-direction: column;
  }
`;

export default BotsList;
