import React from "react";
import styled from "styled-components";
import {
  Col,
  Row,
  FormGroup,
  Card,
  CardBody
} from "reactstrap";

import { CREDENTIALS_STATUS_OPTIONS } from "models/filters";
import { DelayInput } from "react-delay-input";
import ExchangesSelect from "components/ExchangesSelect";
import SelectInput from "components/SelectInput";
import FormGroupWithSymbols from "components/FormGroupWithSymbols";
import useFilterAndSorting from "hooks/useFilterAndSorting";

const Filters = () => {
  const { handleFilter, filter } = useFilterAndSorting("/credentials");

  return (
    <Filters.Card>
      <CardBody className="p-4">
        <form>
          <Row className="d-flex align-items-center p-0 m-0">
            <Col xs="12" md="2" className="p-0 mb-2 mb-0">
              <FormGroup className="form-input m-0">
                <ExchangesSelect
                  isClearable
                  placeholder="Exchange"
                  name="exchange"
                  isMulti
                  value={filter?.exchange?.in}
                  onChange={(values) => {
                    handleFilter({
                      exchange: {in: values ? values.map((e) => e.value) : ""}
                    });
                  }}
                  width="100%"
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="3" className="pl-0 pr-0 pl-md-3 pr-md-3 mb-2 mb-0">
              <FormGroup className="form-input m-0">
                <DelayInput
                  autoComplete="off"
                  delayTimeout={700}
                  minLength={1}
                  type="text"
                  name="comment"
                  className="form-control"
                  placeholder="Comment"
                  value={filter && filter.comment ? filter.comment.like : ""}
                  onChange={({target}) => handleFilter({comment: {like: target.value}})}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="2" className="p-0 mb-2 mb-0">
              <FormGroup className="form-input m-0">
                <DelayInput
                  autoComplete="off"
                  delayTimeout={700}
                  minLength={1}
                  type="text"
                  name="id"
                  className="form-control"
                  placeholder="ID"
                  value={filter && filter.id ? filter.id.like : ""}
                  onChange={({target}) => handleFilter({id: {like: target.value}})}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="2" className="pl-0 pr-0 pl-md-3 pr-md-3 mb-2 mb-0">
              <FormGroup className="form-input m-0">
                <SelectInput
                  isClearable
                  placeholder="Status"
                  name="status"
                  value={filter && filter.status ? CREDENTIALS_STATUS_OPTIONS.find((o) => o.value === filter.status.eq) : ""}
                  onChange={(option) => handleFilter({status: option?.value ? {eq: option.value} : ""})}
                  options={CREDENTIALS_STATUS_OPTIONS}
                />
              </FormGroup>
            </Col>
            <Col
              xs="12"
              md="3"
              className="d-flex flex-row align-items-center p-0 justify-content-around mb-2 mb-0"
            >
              <FormGroupWithSymbols
                symbolRight={<span className="text-white-35 mb-0 font-weight-300">BTC</span>}
              >
                <DelayInput
                  autoComplete="off"
                  delayTimeout={700}
                  minLength={1}
                  type="number"
                  name="btcIn"
                  className="form-control"
                  placeholder="0"
                  value={filter?.btcBalance?.gte || ""}
                  onChange={({target}) => handleFilter({
                    btcBalance: {gte: target.value, lte: filter?.btcBalance?.lte}
                  })}
                />
              </FormGroupWithSymbols>
              <span className="text-white-35 mb-0 font-weight-300 mx-2">To</span>
              <FormGroupWithSymbols
                symbolRight={<span className="text-white-35 mb-0 font-weight-300">BTC</span>}
              >
                <DelayInput
                  autoComplete="off"
                  delayTimeout={700}
                  minLength={1}
                  type="number"
                  name="btcTo"
                  className="form-control"
                  placeholder="0"
                  value={filter?.btcBalance?.lte || ""}
                  onChange={({target}) => handleFilter({
                    btcBalance: {lte: target.value, gte: filter?.btcBalance?.gte}
                  })}
                />
              </FormGroupWithSymbols>
            </Col>
          </Row>
        </form>
      </CardBody>
    </Filters.Card>
  );
};

Filters.Card = styled(Card)`
  border-radius: 8px !important;
  margin-bottom: 24px !important;
`;

export default Filters;
