import React, {useState} from "react";
import styled from "styled-components";
import {ReactComponent as EmptyIcon} from "assets/img/empty-state-planet2.svg";
import Button from "components/Button";
import { CreateCredentialsModal } from "shared/ui";

const EmptyState = ({ title, description, credential, onButtonClick, buttonText }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <EmptyState.Wrapper className="d-flex flex-column justify-content-center align-items-center">
        <EmptyIcon/>
        <p className="font-weight-500 text-white mb-1">{title}</p>
        <span className="text-white-75 mb-0 font-sm">{description}</span>
        {
          credential ?
          <Button
            color="blue"
            onClick={() => setIsModalOpen(true)}
          >
            Create credentials
          </Button> : null
        }
        {
          onButtonClick && buttonText ?
            <Button
            color="blue"
            onClick={onButtonClick}
          >
            {buttonText}
          </Button> : null
        }
      </EmptyState.Wrapper>
      <CreateCredentialsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  )
}

EmptyState.Wrapper = styled.div`
  padding: 99px;
  border-radius: 8px;
  text-align: center;

  button {
    margin-top: 40px;
  }
  svg {
    margin-bottom: 40px;
  }
`;

export default EmptyState;
