import React from "react";
import styled from "styled-components";
import { Col } from "reactstrap";
import { times } from "lodash";

import SkeletonPreloader from "components/SkeletonPreloader";

const WidgetsPreloaders = () => {
  return (
    <WidgetsPreloaders.Wrapper className="row flex-lg-nowrap ml-0">
      {
        times(3, (e) =>
          <Col
            xs="12"
            lg="4"
            key={e}
            className="px-lg-0"
          >
            <div className="d-flex align-items-center card-widget">
              <SkeletonPreloader
                circle={true}
                height={38}
                width={38}
              />
              <div>
                <div className="ml-3">
                  <SkeletonPreloader height={8} width={35}/>
                </div>
                <div className="ml-3">
                  <SkeletonPreloader height={12} width={75}/>
                </div>
              </div>
            </div>
          </Col>
        )
      }
    </WidgetsPreloaders.Wrapper>
  )
}

WidgetsPreloaders.Wrapper = styled.div`
  @media (min-width: 991.98px) {
    padding: 0 30px 0 15px;
  }
  width: 100%;
  margin-bottom: 24px;
  gap: 8px;
  
  .card-widget {
    background: var(--dark-slate);
    padding: 12px 24px;
    border-radius: 8px;
    height: 86px;
  }
`;

export default WidgetsPreloaders;
