import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import CustomScrollbars from "components/CustomScrollbars";
import Icon from "components/Icon";
import tickersBeError from "assets/img/tickers-error.png";
import { ReactComponent as Empty } from "assets/img/empty-planet-3.svg";
import SortTableHead from "components/SortTableHead";
import { TABLE_HEAD } from "./models";
import PercentBadge from "components/PercentBadge";
import { getCoinName } from "exchanges/utils";
import { ClipLoader } from "react-spinners";

const TickersTable = ({
  tickers,
  setSort,
  sort,
  error,
  onSetIsOpen,
  exchange,
  isLoading
}) => {
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    setShowLoading(isLoading);
  }, [isLoading]);

  const handleRedirect = (item) => {
    history.push({
      pathname: `/exchanges/${exchange}/markets/${item.market.quote}/${item.market.base}`,
      search: ""
    });
    onSetIsOpen(false);
  };

  const getDailyChange = (item) => {
    if (!item || item.open === 0) return 0;
    const dailyChange = ((item?.close - item?.open) / item?.open) * 100;
    return Math.round(dailyChange * 100) / 100;
  }

  if (showLoading) return (
    <StyledLoader className="d-flex flex-column justify-content-center align-items-center">
      <ClipLoader
        size={35}
        color="#2f80ed"
      />
      <p className="h3 mt-3 text-dark-75">
        Loading...
      </p>
    </StyledLoader>
  );

  if (error) return (
    <div className="d-flex flex-column justify-content-center align-items-center p-5 my-3">
      <img
        src={tickersBeError}
        alt="error illustration"
        className="mb-4"
      />
      <p className="h3 m-0 mb-2 pt-3">
        Oops, some issues on our end...
      </p>
      <p className="font-sm text-center text-dark-75">
        {"But don't worry, our team is working hard to fix it. Please try again"}<br />
        later or contact our support team for further assistance.
      </p>
    </div>
  );

  if (!tickers.length) return (
    <div className="d-flex flex-column justify-content-center align-items-center p-5 my-3">
      <Empty className="mb-4" />
      <p className="h3 m-0 mb-2 pt-3">
        No results for your search
      </p>
      <p className="font-sm text-center text-dark-75">
        {"Oops, we couldn't find any results for your"}<br />
        search. Please try again with a different keyword<br />
        or refine your search criteria.
      </p>
    </div>
  );

  return (
    <CustomScrollbars
      autoHeight
      autoHeightMin={200}
      autoHeightMax="60vh"
      thumbMinSize={63}
      isDark={false}
      autoHideTimeout={200}
      autoHideDuration={600}
    >
      <StyledTable className="w-100 mt-3">
        <SortTableHead
          handleSorting={setSort}
          sort={sort}
          theadItems={[
            ...TABLE_HEAD,
            ...(exchange !== "hitbtc" ? [{ name: "markPrice", label: "Mark price", isSortable: true }] : []),
            { name: "amount", label: "Amount", isSortable: true },
            { name: "quantity", label: "Quantity", isSortable: true },
            ...(exchange !== "hitbtc" ? [{ name: "tradeCount", label: "Trades", isSortable: true }] : []),
            { name: "dailyChange", label: "Change", isSortable: true },
          ]}
          isDark={false}
        />

          <tbody>
            {
              tickers.map((item) =>
                <StyledRow
                  key={item.symbol}
                  className="font-sm"
                  onClick={() => handleRedirect(item)}
                >
                  <td className="pl-4 px-2">
                    <div className="d-flex">
                      <span className="d-block my-auto">
                        <Icon
                          className="mr-2"
                          code={item.market.base}
                          type="cryptocurrency"
                          size="thumb"
                        />
                      </span>
                      <div className="d-flex flex-column w-75 pr-2">
                        <p className="m-0 text-dark font-weight-500">
                          {item.market.base}
                        </p>
                        <p className="opacity-35 font-xs text-truncate font-weight-light m-0">
                          {getCoinName(item.market.base)}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="font-weight-500 text-dark px-2">
                    {item.open}
                  </td>
                  <td className="font-weight-500 text-dark px-2">
                    {item.high}
                  </td>
                  <td className="font-weight-500 text-dark px-2">
                    {item.low}
                  </td>
                  <td className="font-weight-500 text-dark px-2">
                    {item.close}
                  </td>
                  {
                    exchange !== "hitbtc" ?
                      <td className="font-weight-500 text-dark text-nowrap px-2">
                        {item.markPrice || 0}{" "}
                        <StyledAsset>
                          {item.quote}
                        </StyledAsset>
                      </td> : null
                  }
                  <td className="font-weight-500 text-dark text-nowrap px-2">
                    {item.amount || 0}{" "}
                    <StyledAsset>
                      {item.quote}
                    </StyledAsset>
                  </td>
                  <td className="font-weight-500 text-dark text-nowrap px-2">
                    {item.quantity || 0}{" "}
                    <StyledAsset>
                      {item.base}
                    </StyledAsset>
                  </td>
                  {
                    exchange !== "hitbtc" ?
                      <td className="font-weight-500 text-dark px-2">
                        {item.tradeCount}
                      </td> : null
                  }
                  <td className="pr-4 pl-3">
                    <PercentBadge
                      value={getDailyChange(item)}
                      zeroDarkTheme={false}
                      showZeroValue
                    />
                  </td>
                </StyledRow>
              )
            }
          </tbody>
        </StyledTable>
    </CustomScrollbars >
    );
};

const StyledTable = styled.table`
  & th {
    font-weight: 300;
    min-width: 11.8%;
    cursor: pointer;
    padding: 0.5rem;

    &:first-child {
      padding-left: 24px;
    }

    &:last-child {
      padding-right: 24px;
    }

    & p {
      color: rgba(29, 30, 42, 0.35) !important;
      font-size: 0.875rem;

      &:hover {
        color: rgba(29, 30, 42, 0.75) !important;
      }
    }
  }
`;

const StyledRow = styled.tr`
  border-top: 1px solid #E6E6EA;
  cursor: pointer;

  &:hover {
    background: rgba(29, 30, 42, 0.06);
  }

  & td {
    padding: 1rem 0.5rem;

    &:first-child {
      max-width: 190px;
    }
  }
`;

const StyledAsset = styled.span`
  color: #1D1E2A59;
  font-weight: 300;
`;

const StyledLoader = styled.div`
  height: 60vh;
`;

export default React.memo(TickersTable);
