import React from "react";

import { replaceDurations } from "helpers/replaceDurations";
import { formatterToNull } from "helpers/valuesFormatters";

import { 
  DealAmountStyled, 
  DealCurrencyStyled, 
  DealTitleStyled 
} from "../../Deal/index.styles";

const DealOpenTradeBody = ({ data, isHaveOpenOrder }) => (
  <div className={`w-100 d-flex align-items-center flex-wrap justify-content-between pt-3 ${isHaveOpenOrder ? "mb-3" : ""}`}>
    <div className="d-flex flex-column text-nowra mb-md-0 mb-2">
      <DealTitleStyled data-testid="deal-open-trade-executed-amount">
        Executed amount
      </DealTitleStyled>
      <div className="d-flex align-items-center">
        <DealAmountStyled
          data-testid="deal-open-trade-executed-amount-value"
          className=" mr-1"
          amount={data?.executedAmount?.amount}
        >
          {formatterToNull(data?.executedAmount?.amount)}
        </DealAmountStyled>
        <DealCurrencyStyled data-testid="deal-open-trade-executed-amount-asset">
          {data?.executedAmount?.asset}
        </DealCurrencyStyled>
      </div>
    </div>
    <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2">
      <DealTitleStyled data-testid="deal-open-trade-executed-quantity">
        Executed quantity
      </DealTitleStyled>
      <div className="d-flex align-items-center">
        <DealAmountStyled
          data-testid="deal-open-trade-executed-quantity-amount"
          className=" mr-1"
          amount={data?.executedQuantity?.amount}
        >
          {formatterToNull(data?.executedQuantity?.amount)}
        </DealAmountStyled>
        <DealCurrencyStyled data-testid="deal-open-trade-executed-quantity-asset">
          {data?.executedQuantity?.asset}
        </DealCurrencyStyled>
      </div>
    </div>
    <div className="d-flex flex-column px-2 px-md-0 mb-md-0 mb-2">
      <DealTitleStyled data-testid="deal-open-trade-fee">
        Fee
      </DealTitleStyled>
      <div className="d-flex align-items-center">
        <DealAmountStyled
          data-testid="deal-open-trade-fee-amount"
          className=" mr-1"
          amount={data?.fee?.amount}
        >
          {formatterToNull(data?.fee?.amount)}
        </DealAmountStyled>
        <DealCurrencyStyled data-testid="deal-open-trade-fee-asset">
          {data?.fee?.asset}
        </DealCurrencyStyled>
      </div>
    </div>
    <div className="d-flex flex-column px-2 px-md-0 mb-md-0 mb-2">
      <DealTitleStyled data-testid="deal-open-average-price">
        Average price
      </DealTitleStyled>
      <div className="d-flex align-items-center">
        <DealAmountStyled
          data-testid="deal-open-average-rate"
          className=" mr-1"
          amount={data?.averageRate}
        >
          {formatterToNull(data?.averageRate)}
        </DealAmountStyled>
      </div>
    </div>
    <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2">
      <DealTitleStyled data-testid="deal-open-trades">
        Trades
      </DealTitleStyled>
      <DealAmountStyled
        data-testid="deal-open-trades-count"
        className=" mr-1"
        amount={data?.tradesCount}
      >
        {formatterToNull(data?.tradesCount)}
      </DealAmountStyled>
    </div>
    <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2">
      <DealTitleStyled data-testid="deal-open-orders">
        Orders
      </DealTitleStyled>
      <DealAmountStyled
        data-testid="deal-open-orders-count"
        className=" mr-1"
        amount={data?.ordersCount}
      >
        {formatterToNull(data?.ordersCount)}
      </DealAmountStyled>
    </div>
    <div className="d-flex flex-column align-items-end text-nowrap px-2 px-md-0 mb-md-0 mb-2">
      <DealTitleStyled data-testid="deal-open-duration">
        Duration
      </DealTitleStyled>
      <p
        data-testid="deal-open-lifetime"
        className={`mb-0 font-sm font-weight-500 ${data?.lifetime ? "text-white" : "text-white-35"}`}
      >
        {replaceDurations(data?.lifetime)}
      </p>
    </div>
  </div>
);

export default DealOpenTradeBody;