import React from "react";

import { formatterToNull } from "helpers/valuesFormatters";
import StyledValue from "./StyledValue";

const GeneralSettings = ({ data={} }) => {
  return (
    <>
      <div className="d-flex flex-md-column flex-lg-row justify-content-between mt-2">
        <p className="mb-0 text-white-75 font-weight-300 font-sm">
          Initial volume size
        </p>
        <StyledValue amount={data?.volume}>
          {`${formatterToNull(data?.volume)} %`}
        </StyledValue>
      </div>
      <div className="d-flex flex-md-column flex-lg-row  justify-content-between mt-2">
        <p className="mb-0 text-white-75 font-weight-300 font-sm">
          Take profit
        </p>
        <StyledValue amount={data?.profit}>
          {`${formatterToNull(data?.profit)} %`}
        </StyledValue>
      </div>
      {
        data?.stopLossPercent ?
        <div className="d-flex flex-md-column flex-lg-row justify-content-between mt-2">
          <p className="mb-0 text-white-75 font-weight-300 font-sm">
            Stop loss
          </p>
          <StyledValue amount={data?.stopLossPercent}>
            {`${formatterToNull(data?.stopLossPercent)} %`}
          </StyledValue>
        </div> : null
      }
      <div className="d-flex flex-md-column flex-lg-row justify-content-between mt-2">
        <p className="mb-0 text-white-75 font-weight-300 font-sm">
          Execution Type
        </p>
        <p className=" mb-0 font-weight-500 text-white text-nowrap">
          {data.executionType === "market" ? "Market" : null}
          {data.executionType === "aggressive" ? "Aggressive limit" : null}
          {data.executionType === "passive" ? "Passive limit" : null}
        </p>
      </div>
      <div className="d-flex flex-md-column flex-lg-row justify-content-between mt-2 text-nowrap">
        <p className="mb-0 text-white-75 font-weight-300 font-sm">
          Trailing Buy
        </p>
        <StyledValue amount={data?.trailingBuy}>
          {`${data?.trailingBuy} %`}
        </StyledValue>
      </div>
      <div className="d-flex flex-md-column flex-lg-row justify-content-between mt-2 text-nowrap">
        <p className="mb-0 text-white-75 font-weight-300 font-sm">
          Trailing Sell
        </p>
        <StyledValue amount={data?.trailingSell}>
          {`${data?.trailingSell} %`}
        </StyledValue>
      </div>
    </>
  )
}

export default GeneralSettings;
