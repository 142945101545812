import React from "react";

import { BadgeSignalStyled } from "./BadgeSignal.styles";

const signalClassMap = {
  none: "none",
  hold: "none",
  buy: "buy",
  sell: "sell",
  both: "both",
};

const BadgeSignal = ({ signal }) => {
  if (!signal) return;

  return (
    <BadgeSignalStyled
      data-testid={`badge-signal-${signal}`}
      className={signalClassMap[signal]}
    >
      <p
        data-testid={`badge-signal-${signal}-text`}
        className="mb-0 text-uppercase font-sm font-weight-500"
      >
        {signal}
      </p>
    </BadgeSignalStyled>
  )
};

export default BadgeSignal;
