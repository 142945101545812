import React from "react";
import _ from "lodash";
import {
  CardBody,
  Card,
  CardHeader
} from "reactstrap";

import SkeletonPreloader from "components/SkeletonPreloader";

const SignalDetailsWidgetPreload = () => (
  <Card className="p-4">
    <CardHeader className="p-0" id="preloader">
      <div className="d-flex flex-row align-items-center ">
        <SkeletonPreloader
          height={20}
          width={20}

        />
        <SkeletonPreloader
          height={19}
          width={153}
          className="ml-2 mb-0"
        />
      </div>
    </CardHeader>
    <CardBody className="p-0 mt-3">
      {
        _.times(3, (e) =>
          <div className="d-flex align-items-center justify-content-between mt-2" key={e}>
            <SkeletonPreloader
              height={16}
              width={175}
            />
            <SkeletonPreloader
              height={16}
              width={32}
            />
          </div>
        )
      }
    </CardBody>
  </Card>
);

export default SignalDetailsWidgetPreload;