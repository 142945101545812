import OpenOrdersWidget from "../openOrders/OpenOrdersWidget";
import TradeHistoryWidget from "../tradeHistory/TradeHistoryWidget";
import TradeHistoryOpenOrdersPreloader from "../preloaders/TradeHistoryOpenOrdersPreloader";

const TradeHistoryOpenOrdersSection = ({data, loading}) => {

  if(loading) return <TradeHistoryOpenOrdersPreloader/>

  return (
    <div className="d-flex flex-nowrap justify-content-between">
      <TradeHistoryWidget
        total={data?.tradesCountTotal}
        daily={data?.tradesCountDaily}
      />
      <OpenOrdersWidget
        totalOrders={data?.totalOrdersCount}
        openOrders={data?.openOrdersCount}
      />
    </div>
  )
};


export default TradeHistoryOpenOrdersSection;
