import React from "react";
import {Card, CardHeader, CardBody} from "reactstrap";
import {ReactComponent as Key} from "assets/img/icons/keysquare.svg";
import EditButton from "components/EditButton";
import CredentialSettingsPreloader from "../preloaders/CredentialSettingsPreloader";
import LineWithError from "shared/ui/LineWithError";
/* eslint-disable no-lone-blocks */

const CredentialSettings = ({data={}, onSetModalIsOpen, loading}) => {
  if (loading) return <CredentialSettingsPreloader />;

  return(
    <Card className="mb-4">
      <CardHeader className="px-4 py-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center text-nowrap">
            <Key />
            <p className="mb-0 text-white font-weight-500 ml-2">API Key & Secret</p>
          </div>
          <EditButton onClick={() => onSetModalIsOpen(true)} />
        </div>
      </CardHeader>
      {
        !data.connected && <LineWithError errorMessage="API Key is not valid. Please update it." radius="0"/>
      }
      <CardBody className={`px-4 pb-4 ${data.error ? "pt-4" : "pt-1 "}`}>
        <div className="d-flex flex-md-column flex-lg-row align-items-lg-center justify-content-between">
          <p className="text-white-75 font-sm mb-0">API key</p>
          <p className="mb-0 text-white">
            {data.hidden ? data.hidden.apiKey : ""}
          </p>
        </div>
        <div className="d-flex flex-md-column flex-lg-row align-items-lg-center justify-content-between mt-2">
          <p className="text-white-75 font-sm mb-0">Secret key</p>
          <p className="mb-0 text-white">
            {data.hidden ? data.hidden.secret : ""}
          </p>
        </div>
      </CardBody>
    </Card>
  );
};

export default CredentialSettings;
