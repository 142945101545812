import React, { useEffect } from "react";
import { 
  useDispatch,
  useSelector
} from "react-redux";
import {
  Row,
  Col
} from "reactstrap";
import * as Sentry from "@sentry/react";

import Wizard from "./Wizard";
import BalanceHistory from "./charts/BalanceHistory";
import AssetAllocation from "./compounds/assetAllocation/AssetAllocation";
import LastActivities from "./compounds/LatestActivities";
import LastBacktest from "./compounds/LastBacktest";
import FallBack from "components/FallBack";
import BotsStatisticsWidget from "./compounds/botsStatistics/BotsStatisticsWidget";
import MiniWidgets from "./compounds/MiniWidgets";
import MyChannels from "./compounds/channelWidget";
import useRequestCancellation from "hooks/useRequestCancellation";
import { 
  fetchDashboard,
  resetDashboard
} from "store/dashboard/actions";
import { DASHBOARD_CONTENT } from "../../models/constans/dashboard/content";

const Dashboard = () => {
  const { useAbortableEffect } = useRequestCancellation();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.dashboard.data);
  const loading = useSelector((state) => state.dashboard.loading);
  const error = useSelector((state) => state.dashboard.error);
  const me = useSelector((state) => state.me.data);

  useAbortableEffect((signal) => {
    dispatch(fetchDashboard(signal));
    return () => dispatch(resetDashboard(signal));
  }, [dispatch], true);

  useEffect(() => {
    window.analytics.page("/dashboard");
  }, []);

  return(
    <>
      <Row>
        <Col xs="12" className="px-0 px-lg-3">
          <Wizard loadingPage={loading}/>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="8">
          <Row>
            <MiniWidgets
              content={DASHBOARD_CONTENT.widgets.miniWidgets}
              me={me}
              dealsCount={data.dealsCount}
              invested={data.invested}
              profit={data.profit}
              loading={loading}
            />
            <Col xs="12">
              <Sentry.ErrorBoundary fallback={<FallBack />}>
                <BalanceHistory
                  content={DASHBOARD_CONTENT.widgets.balanceHistory}
                  balanceHistoryData={data.balanceHistory}
                  loading={loading}
                  currency={me?.currency}
                />
              </Sentry.ErrorBoundary>
              <Sentry.ErrorBoundary fallback={<FallBack />}>
                <LastActivities 
                  dashboardLoading={loading}
                  tableView="deals"
                  />
              </Sentry.ErrorBoundary>
              <Sentry.ErrorBoundary fallback={<FallBack />}>
                <LastActivities 
                  dashboardLoading={loading}
                  tableView="agreements"
                  />
              </Sentry.ErrorBoundary>
            </Col>
          </Row>
        </Col>
        <Col xs="12" md="4">
          <Sentry.ErrorBoundary fallback={<FallBack/>}>
            <AssetAllocation
              assets={data.assets}
              loading={loading}
              error={error}
              content={DASHBOARD_CONTENT.widgets.assetAllocation}
            />
          </Sentry.ErrorBoundary>
          <Sentry.ErrorBoundary fallback={<FallBack/>}>
            <BotsStatisticsWidget
              loadingPage={loading}
              content={DASHBOARD_CONTENT.widgets.botsStatistics}
            />
          </Sentry.ErrorBoundary>
          <Sentry.ErrorBoundary fallback={<FallBack />}>
            <MyChannels 
              id={me?.id}
              loadingPage={loading} 
            />
          </Sentry.ErrorBoundary>
          <Sentry.ErrorBoundary>
            <LastBacktest />
          </Sentry.ErrorBoundary>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
