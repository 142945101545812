/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import styled from "styled-components";
import { Form, Formik } from "formik";
import Button from "reactstrap-button-loader";
import {
  Row,
  Col,
  Label,
  FormGroup,
  Card,
  CardBody,
  UncontrolledAlert
} from "reactstrap";
import * as Yup from "yup";
import { useSelector } from "react-redux";

import NumberInput from "components/NumberInput";
import ToggleSwitch from "components/ToggleSwitch";
import Indicators from "./Indicators";
import { wizardIndicatorsSelector } from "store/strategies/selectors";

const settingsSchema = Yup.object().shape({
  profit: Yup.string()
    .required("This field is required."),
  volume: Yup.string()
    .required("This field is required."),
  trailingBuy: Yup.string()
    .required("This field is required."),
  trailingSell: Yup.string()
    .required("This field is required."),
  deviation: Yup.string()
    .required("This field is required."),
  martingale: Yup.string()
    .required("This field is required."),
  stopLossPercent: Yup.string()
    .required("This field is required."),
  aggressive: Yup.string()
    .required("This field is required."),
  reverse: Yup.string()
    .required("This field is required."),
  minimumSignals: Yup.string()
    .required("This field is required."),
  maxRounds: Yup.string()
    .nullable()
    .required("This field is required.")
});

const style = {
  color: "#ec250d",
};

const StrategySettings = ({
  confirm,
  goBack,
  reqErrors,
  submitting
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [indicatorError, setIndicatorError] = useState("");
  const indicatorsList = useSelector((state) => wizardIndicatorsSelector(state));

  return (
    <Card>
      <CardBody>
        <div className="pr-5 pl-5">
          {reqErrors.message &&
            <UncontrolledAlert color="danger" fade={false}>
              {reqErrors.message}
            </UncontrolledAlert>}
          {indicatorError &&
            <UncontrolledAlert color="danger" fade={false}>
              {indicatorError}
            </UncontrolledAlert>}
          <Indicators />
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            profit: "",
            volume: "",
            trailingBuy: "",
            trailingSell: "",
            deviation: "",
            martingale: "",
            stopLossPercent: "",
            aggressive: false,
            reverse: false,
            minimumSignals: "",
            maxRounds: ""
          }}
          validationSchema={settingsSchema}
          onSubmit={(values) => {
            if (indicatorsList.length) {
              confirm(values);
            } else {
              setIndicatorError("Attach indicators");
            }
          }}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <div className="pr-5 pl-5">
                <Row>
                  <Col xs="12">
                    <p className="mt-4 mb-4">
                      This feature allows our system to automatically distribute the
                      total amount of all your investments on the exchange depending
                      on your weight setting for each investment. It also allows you
                      to add new investments.
                    </p>
                  </Col>
                  <Col xs="6">
                    <Label className="text-muted">
                      First Round volume % {" "}
                      <i className="far fa-question-circle" />
                    </Label>
                    <FormGroup
                      className={(reqErrors.volume || errors.volume) && submitted ? "has-danger" : "has-success"}
                    >
                      <NumberInput
                        min={0}
                        value={values.volume}
                        name="volume"
                        onChange={(value) => setFieldValue("volume", value)}
                      />
                      <div className="w-100">
                        {(errors.volume || reqErrors.volume) && submitted ?
                          <label style={style}>{errors.volume || reqErrors.volume}</label> : null}
                      </div>
                    </FormGroup>
                    <Label className="text-muted">Minimum profit %</Label>
                    <FormGroup
                      className={(reqErrors.profit || errors.profit) && submitted ? "has-danger" : "has-success"}
                    >
                      <NumberInput
                        min={0}
                        value={values.profit}
                        name="profit"
                        onChange={(value) => setFieldValue("profit", value)}
                      />
                      <div className="w-100">
                        {(reqErrors.profit || errors.profit) && submitted ?
                          <label style={style}>{reqErrors.profit || errors.profit}</label> : null}
                      </div>
                    </FormGroup>
                    <Label className="text-muted">
                      Stop loss % {" "}
                      <i className="far fa-question-circle" />
                    </Label>
                    <FormGroup
                      className={(reqErrors.stopLossPercent || errors.stopLossPercent) && submitted ? "has-danger" : "has-success"}
                    >
                      <NumberInput
                        min={0}
                        value={values.stopLossPercent}
                        name="stopLossPercent"
                        onChange={(value) => setFieldValue("stopLossPercent", value)}
                      />
                      <div className="w-100">
                        {(reqErrors.stopLossPercent || errors.stopLossPercent) && submitted ?
                          <label style={style}>{reqErrors.stopLossPercent || errors.stopLossPercent}</label> : null}
                      </div>
                    </FormGroup>
                    <Label className="text-muted">
                      Martingale % {" "}
                      <i className="far fa-question-circle" />
                    </Label>
                    <FormGroup
                      className={(reqErrors.martingale || errors.martingale) && submitted ? "has-danger" : "has-success"}
                    >
                      <NumberInput
                        min={0}
                        value={values.martingale}
                        name="martingale"
                        onChange={(value) => setFieldValue("martingale", value)}
                      />
                      <div className="w-100">
                        {(reqErrors.martingale || errors.martingale) && submitted ?
                          <label style={style}>{reqErrors.martingale || errors.martingale}</label> : null}
                      </div>
                    </FormGroup>
                    <StrategySettings.CheckboxWrapper className="w-100 d-flex justify-content-between p-3">
                      <h5 className="text-muted my-auto">
                        Aggressive order processing{" "}
                        <i className="far fa-question-circle" />
                      </h5>
                      <ToggleSwitch
                        onChange={() => setFieldValue("aggressive", !values.aggressive)}
                        checked={values.aggressive}
                        height={18}
                        width={45}
                        onColor="#26366b"
                        offColor="#1c284d"
                        onHandleColor="#3fcbb0"
                        offHandleColor="#ff8d72"
                        uncheckedIcon={<p className="text-danger h6 text-center mb-0">OFF</p>}
                        checkedIcon={<p className="text-success h6 text-center mb-0">ON</p>}
                        className="my-auto"
                      />
                    </StrategySettings.CheckboxWrapper>
                  </Col>
                  <Col xs="6">
                    <Label className="text-muted">
                      Minimum Indicator signals{" "}
                      <i className="far fa-question-circle" />
                    </Label>
                    <FormGroup
                      className={(reqErrors.minimumSignals || errors.minimumSignals) && submitted ? "has-danger" : "has-success"}
                    >
                      <NumberInput
                        min={0}
                        max={indicatorsList.length}
                        value={values.minimumSignals}
                        name="minimumSignals"
                        onChange={(value) => setFieldValue("minimumSignals", value)}
                      />
                      <div className="w-100">
                        {(reqErrors.minimumSignals || errors.minimumSignals) && submitted ?
                          <label style={style}>{reqErrors.minimumSignals || errors.minimumSignals}</label> : null}
                      </div>
                    </FormGroup>
                    <Label className="text-muted">
                      Round deviation %{" "}
                      <i className="far fa-question-circle" />
                    </Label>
                    <FormGroup
                      className={(reqErrors.deviation || errors.deviation) && submitted ? "has-danger" : "has-success"}
                    >
                      <NumberInput
                        min={0}
                        value={values.deviation}
                        name="deviation"
                        onChange={(value) => setFieldValue("deviation", value)}
                      />
                      <div className="w-100">
                        {(reqErrors.deviation || errors.deviation) && submitted ?
                          <label style={style}>{reqErrors.deviation || errors.deviation}</label> : null}
                      </div>
                    </FormGroup>
                    <Row>
                      <Col xs="6">
                        <Label className="text-muted">
                          Trailing buy % {" "}
                          <i className="far fa-question-circle" />
                        </Label>
                        <FormGroup className={(reqErrors.trailingBuy || errors.trailingBuy) && submitted ? "has-danger" : "has-success"}>
                          <NumberInput
                            min={0}
                            value={values.trailingBuy}
                            name="trailingBuy"
                            onChange={(value) => setFieldValue("trailingBuy", value)}
                          />
                          <div className="w-100">
                            {(reqErrors.trailingBuy || errors.trailingBuy) && submitted ?
                              <label style={style}>{reqErrors.trailingBuy || errors.trailingBuy}</label> : null}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <Label className="text-muted">
                          Trailing sell % {" "}
                          <i className="far fa-question-circle" />
                        </Label>
                        <FormGroup
                          className={(reqErrors.trailingSell || errors.trailingSell) && submitted ? "has-danger" : "has-success"}
                        >
                          <NumberInput
                            min={0}
                            value={values.trailingSell}
                            name="trailingSell"
                            onChange={(value) => setFieldValue("trailingSell", value)}
                          />
                          <div className="w-100">
                            {(reqErrors.trailingSell || errors.trailingSell) && submitted ?
                              <label style={style}>{reqErrors.trailingSell || errors.trailingSell}</label> : null}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Label className="text-muted">
                      Maximum trade requests
                      <i className="far fa-question-circle" />
                    </Label>
                    <FormGroup className={(reqErrors.maxRounds || errors.maxRounds) && submitted ? "has-danger" : "has-success"}>
                      <NumberInput
                        value={values.maxRounds}
                        name="maxRounds"
                        onChange={(value) => setFieldValue("maxRounds", value)}
                      />
                      <div className="w-100">
                        {(reqErrors.maxRounds || errors.maxRounds) && submitted ?
                          <label style={style}>{reqErrors.maxRounds || errors.maxRounds}</label> : null}
                      </div>
                    </FormGroup>
                    <StrategySettings.CheckboxWrapper className="w-100 d-flex justify-content-between p-3">
                      <h5 className="text-muted my-auto">
                        Order book reverse{" "}
                        <i className="far fa-question-circle" />
                      </h5>
                      <ToggleSwitch
                        onChange={() => setFieldValue("reverse", !values.reverse)}
                        checked={values.reverse}
                        height={18}
                        width={45}
                        onColor="#26366b"
                        offColor="#1c284d"
                        onHandleColor="#3fcbb0"
                        offHandleColor="#ff8d72"
                        uncheckedIcon={<p className="text-danger h6 text-center mb-0">OFF</p>}
                        checkedIcon={<p className="text-success h6 text-center mb-0">ON</p>}
                        className="my-auto"
                      />
                    </StrategySettings.CheckboxWrapper>
                  </Col>
                </Row>
                <Col xs="12">
                  <div className="d-flex justify-content-between mt-4">
                    <Button
                      onClick={() => goBack()}
                      color="default"
                      className="pull-right"
                    >
                      Back - Strategy view
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => setSubmitted(true)}
                      loading={submitting}
                      color="info"
                      className="pull-right"
                    >
                      Confirm
                    </Button>
                  </div>
                </Col>
              </div>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

StrategySettings.CheckboxWrapper = styled.div`
  background: #1d1e2a;
  border-radius: 4px;
`;

export default StrategySettings;
