import React from "react";
import {
  ModalBody,
  ModalFooter
} from "reactstrap";

import Button from "components/Button";

import {
  ConfirmationCancelStyled,
  ConfirmationModalStyled,
  ConfirmationWrapperStyled
} from "./index.styles";

const ConfirmationModal = ({
  id,
  modalIsOpen,
  closeModal,
  load,
  handleComplete,
  modalType,
  content
}) => {
  return (
    <ConfirmationModalStyled
      data-testid="danger-zone-confirmation-wrap"
      isOpen={modalIsOpen}
      modalClassName="modal-black"
      toggle={closeModal}
    >
      <ConfirmationWrapperStyled>
        <h3
          data-testid="danger-zone-confirmation-title"
          className="modal-title text-white text-center mt-4 font-weight-500 mb-0"
        >
          {content.actions[modalType]?.title}
        </h3>
        <ModalBody className="pt-2 px-4 pb-4 m-0 font-sm">
          <p data-testid="danger-zone-confirmation-warning" className="mb-0 text-center font-weight-300 text-white-75">
            {content.actions[modalType]?.warning}
          </p>
        </ModalBody>
        <ModalFooter className="pt-0 px-4 pb-4 m-0">
          <ConfirmationCancelStyled>
            <Button
              data-testid="danger-zone-confirmation-btn-cancel"
              color="gray"
              onClick={closeModal}
              disabled={load}
            >
              Cancel
            </Button>
          </ConfirmationCancelStyled>
          <div>
            <Button
              data-testid={`danger-zone-btn-${modalType}`}
              loading={load}
              color="red"
              onClick={() => handleComplete(id, modalType)}
              className="text-nowrap"
            >
              {content.actions[modalType]?.name}
            </Button>
          </div>
        </ModalFooter>
      </ConfirmationWrapperStyled>
    </ConfirmationModalStyled>
  );
};



export default ConfirmationModal;