import React from "react";

import SkeletonPreloader from "components/SkeletonPreloader";
import TablePreloader from "components/TablePreloader";

const ActiveMarketsPreloader = ({
  withHeader,
  withTable,
  withMarkets
}) => {
  return (
    <>
      {
        withHeader &&
        <div className="row pl-4 pr-3 pt-3 pb-4">
          <div className="d-flex align-items-center col-md-4 col-lg-6 col-sm-12">
            <SkeletonPreloader
              circle
              height={20}
              width={20}
            />

            <SkeletonPreloader
              height={16}
              width={130}
              className="ml-2"
            />
          </div>

          <div className="d-flex align-items-center col-md-8 col-lg-6 col-sm-12 mt-3 mt-md-0">
            <SkeletonPreloader
              height={18}
              width={18}
              borderRadius="2px"
            />
            <SkeletonPreloader
              height={16}
              width={89}
              className="ml-2 mr-3"
            />
            <SkeletonPreloader
              height={38}
              borderRadius="6px"
            />
          </div>
        </div>
      }

      {
        withTable &&
        <TablePreloader
          thHeight={12}
          thWidth={[44,70,68,70,74,82]}
          tdWidth={[66,72,84,137,137,68]}
          tdHeight={14}
          rowTimes={11}
          withCircle={24}
        />
      }

      {
        withMarkets &&
        <div className="d-flex flex-column p-4">
          <div className="d-flex align-items-center">
            <SkeletonPreloader
              circle
              height={16}
              width={16}
            />
            <SkeletonPreloader
              height={16}
              width={160}
              className="ml-2"
            />
          </div>

          <SkeletonPreloader
            height={12}
            width={325}
            className="mt-3"
          />
          <SkeletonPreloader
            height={12}
            width={213}
            className="mt-2"
          />

          <div className="d-flex align-items-center mt-4">
            <SkeletonPreloader
              height={32}
              width={118}
              count={4}
              borderRadius="100px"
              className="mr-2"
            />
          </div>
        </div>
      }
    </>
  )
}

export default ActiveMarketsPreloader;