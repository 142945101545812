import React, { useEffect } from "react";
import styled from "styled-components";
import {
  Modal,
  Card,
  Badge
} from "reactstrap";
import {
  useDispatch,
  useSelector
} from "react-redux";

import Button from "components/Button";
import IdWithCopy from "components/IdWithCopy";
import LineWithError from "shared/ui/LineWithError";
import TradeRequestDetails from "components/TradeRequestDetailsModal/TradeRequestDetails";
import TradeRequestsDetailsTable from "components/TradeRequestDetailsModal/TradeRequestsDetailsTable";
import TradeRequestGoal from "./ui/TradeRequestGoal";
import {
  fetchTradeRequestOrders,
  fetchTradeRequestTrades
} from "store/agreements/actions";
import { ReactComponent as Info } from "assets/img/icons/infoIcon.svg";

const TradeRequestsDetailsModal = ({
  setIsModalOpen,
  isModalOpen,
  modalOpenItem,
  itemsRebalance = [],
  isManual
}) => {
  const dispatch = useDispatch();

  const tradeRebalance = itemsRebalance?.filter(item => item.tradeRequest.id === modalOpenItem.id)[0];

  const ordersRequests = useSelector((state) => state.agreements.requestsOrders.data);
  const ordersRequestsLoading = useSelector((state) => state.agreements.requestsOrders.loading);
  const tradesRequests = useSelector((state) => state.agreements.requestsTrades.data);
  const tradesRequestsLoading = useSelector((state) => state.agreements.requestsTrades.loading);

  const fetchData = () => {
    dispatch(fetchTradeRequestOrders(modalOpenItem.id, "?limit=5"));
    dispatch(fetchTradeRequestTrades(modalOpenItem.id, "?limit=5"));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDetails = (trade, tradeRebalance) => {
    if (trade) {
      return (
        <>
          <LineWithError radius="8px" errorMessage={trade.error} />
          <TradeRequestDetails
            trade={trade}
            setIsModalOpen={setIsModalOpen}
          />
        </>
      )
    }
    if (tradeRebalance) {
      return (
        <>
          {tradeRebalance.tradeRequest.error && <LineWithError radius="8px" errorMessage={tradeRebalance.error} />}
          <TradeRequestDetails
            trade={tradeRebalance.tradeRequest}
            setIsModalOpen={setIsModalOpen}
          />
        </>
      )
    }
  };

  return (
    <TradeRequestsDetailsModal.Modal
      isOpen={isModalOpen}
      toggle={() => setIsModalOpen(!isModalOpen)}
      modalClassName="modal-black"
    >
      <TradeRequestsDetailsModal.Card>
        <div className="px-4 pt-4">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <Badge
                style={{
                  background: modalOpenItem?.type === "buy" ? "#3FCBB0" : "#ED5F5F",
                  color: "#FFFFFF",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "16px",
                  display: "block"
                }}
              >
                {modalOpenItem?.type?.slice(0, 1)}
              </Badge>
              <h3 className="mb-0 ml-2 text-white font-weight-500">
                Trade request
              </h3>
              <TradeRequestGoal
                className="ml-3"
                goal={modalOpenItem?.goal}
              />

              {
                isManual
                &&
                <TradeRequestsDetailsModal.Label className="text-white font-xs font-weight-400 ml-3">
                  Manual
                </TradeRequestsDetailsModal.Label>
              }
            </div>
            <Button
              color="gray"
              cancel={true}
              onClick={() => setIsModalOpen(false)}
            />
          </div>
          <IdWithCopy id={modalOpenItem.id} className="mb-2" />

          {
            renderDetails(modalOpenItem, tradeRebalance)
          }

          {
            modalOpenItem.comment &&
            <TradeRequestsDetailsModal.Comment className="d-flex align-items-center mb-4">
              <Info />
              <p className="mb-0 ml-3 text-white font-sm">
                {modalOpenItem.comment}
              </p>
            </TradeRequestsDetailsModal.Comment>
          }
        </div>

        <TradeRequestsDetailsTable
          ordersRequests={ordersRequests}
          tradesRequests={tradesRequests}
          isModalOpenID={modalOpenItem.id}
          ordersRequestsLoading={ordersRequestsLoading}
          tradesRequestsLoading={tradesRequestsLoading}
          fetchData={fetchData}
        />
      </TradeRequestsDetailsModal.Card>
    </TradeRequestsDetailsModal.Modal>
  );
};

TradeRequestsDetailsModal.Modal = styled(Modal)`
  @media (max-width: 1200px) {
    width: 100% !important;
    margin: 0 auto !important;
  }

  max-width: 1150px !important;
  margin: 1rem auto !important;

  .modal-content {
    border-radius: 8px !important;
  }

  .pointer {
    cursor: pointer;
  }
`;

TradeRequestsDetailsModal.Card = styled(Card)`
  background: #2A2C42 !important;
  border-radius: 8px !important;
  margin-bottom: 0 !important;
`;

TradeRequestsDetailsModal.Comment = styled.div`
  background: rgba(47, 128, 237, 0.1);
  border-radius: 8px;
  padding: 12px 16px;

  svg {
    width: 20px;
    height: 20px;

    path {
      stroke: var(--info);
      stroke-opacity: 1;
    }
  }
`;

TradeRequestsDetailsModal.Label = styled.span`
  padding: 4px 6px;
  border-radius: 2px;
  background: rgba(47, 128, 237, 0.20);
`;

export default TradeRequestsDetailsModal;
