import React from "react";
import styled from "styled-components";

import { 
  formatPluralSingular,
  formatterToNull
} from "helpers/valuesFormatters";
import StyledValue from "./StyledValue";
import { ReactComponent as BuyLabelIcon } from "assets/img/icons/BuyLabel.svg";
import { ReactComponent as SellLabelIcon } from "assets/img/icons/SellLabel.svg";
import { ReactComponent as BothLabelIcon } from "assets/img/icons/BuySellLabel.svg";
import { ReactComponent as RequiredSignal } from "assets/img/icons/RequiredSignal.svg"

const BotIndicators = ({
  indicators = [], 
  indicatorName,
  minSellSignals,
  minBuySignals
}) => {
  return (
    <>
      {
        indicators?.length ?
          <p className="mb-0 text-white mt-4 mb-2 font-weight-500">
            {formatPluralSingular(indicators?.length, "Indicator", "Indicators")}
          </p> : null
      }
      {
        minBuySignals ?
          <div className="d-flex flex-md-column flex-lg-row justify-content-between mt-2 text-nowrap">
            <p className="mb-0 text-white-75 font-weight-300 font-sm">
              Minimum{" "}
              <span className="text-success">BUY</span>{" "}
              signals
            </p>
            <StyledValue amount={minBuySignals}>
              {formatterToNull(minBuySignals)}
            </StyledValue>
          </div> : null
      }
      {
        minSellSignals?
          <div className="d-flex flex-md-column flex-lg-row justify-content-between mt-2 text-nowrap">
            <p className="mb-0 text-white-75 font-weight-300 font-sm">
              Minimum{" "}
              <span className="text-danger">SELL</span>{" "}
              signals
            </p>
            <StyledValue amount={minSellSignals}>
              {formatterToNull(minSellSignals)}
            </StyledValue>
          </div> : null
      }
      {
        indicators?.map((item) => {
          return (
            <StyledIndicator
              required={item.required}
              type={item.type}
              key={item.id}
            >
              <div className="d-flex flex-row w-100 align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-between">
                  <StyledIconWrapper className="d-flex align-items-center">
                    {item.type === "sell" && <SellLabelIcon/>}
                    {item.type === "buy" && <BuyLabelIcon/>}
                    {item.type === "both" && <BothLabelIcon/>}
                  </StyledIconWrapper>
                  <p className="mr-1 mb-0 mt-0 text-white font-sm">
                    {
                      indicatorName.map((indicator) => {
                        return item.code === indicator.value ? indicator.label : null
                      })
                    }
                  </p>
                </div>
                {item.required &&
                <StyledSignalWrapper>
                  <RequiredSignal/>
                </StyledSignalWrapper>
                }
              </div>
            </StyledIndicator>
          )
        })
      }
    </>
  );
};

const StyledIconWrapper = styled.div`
  & svg {
      width: 18px;
      height: 18px;
      margin-right: 0.5rem;
  }
`;

const StyledIndicator = styled.div`
  max-height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
  background: #363A54;
  padding: ${(props) => props.required ? "12px 8px 12px 16px" : "12px 16px" }
  border-radius: 6px;
  margin-top: 8px;
`;

const StyledSignalWrapper = styled.div`
  & svg {
      width: 24px;
      height: 24px;
  }
`;

export default BotIndicators;
