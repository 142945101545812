import React, {useState, useRef, useEffect} from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Card,
  Col,
  CardBody,
  CardHeader,
  Button,
  UncontrolledAlert
} from "reactstrap";

import { getDepositAddress } from "store/payments/actions";
import HelpIcon from "components/HelpIcon";

const Deposit = ({ getDepositAddress, deposit }) => {
  const [displayHelp, setDisplayHelp] = useState(false);
  const addressInput = useRef(null);
  const [notification, setNotification] = useState(null);

  const handleGenerate = async () => {
    const res = await getDepositAddress();
    setNotification(res);
  };

  useEffect(() => {
    window.analytics.page("/deposits");
  }, []);

  return (
    <>
      <Col xs="12">
        <div className="mb-5">
          <h1>
            Deposits{" "}
            <HelpIcon
              onClick={() => setDisplayHelp(!displayHelp)}
            />
          </h1>
        </div>
      </Col>
      <Col xs="12">
        <Card>
          <CardHeader>
            <h2 className="mb-0">Bitcoin address</h2>
          </CardHeader>
          <CardBody>
            {notification &&
              <UncontrolledAlert color={notification.code > 200 ? "danger" : "success"} fade={false}>
                {notification.message}
              </UncontrolledAlert>}
            <div className="d-flex justify-content-center">
              {
                deposit.address ?
                <div className="d-flex">
                  <input
                    className="form-control mr-2"
                    value={deposit.address}
                    ref={addressInput}
                  />
                  <Button
                    size="sm"
                    color="primary"
                    onClick={() => {
                      addressInput.current.select();
                      document.execCommand("copy");
                    }}
                  >
                    Copy
                  </Button>
                </div>
                :
                <Button
                  color="primary"
                  onClick={handleGenerate}
                >
                  Generate
                </Button>
              }
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

const mapStateToProps = (state) => ({
  deposit: state.payments.deposit,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ getDepositAddress }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Deposit);
