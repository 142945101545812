import React from "react";

import ErrorAlert from "components/ErrorAlert";
import Button from "components/Button";

const IndicatorFormFooter = ({
  submitted,
  errors,
  setErrors,
  onHandleResetChart,
  indicatorType,
  isSubmitting,
  setSubmitted,
  editing,
}) => {
  
  return (
    <>
      {submitted &&
        errors?.settings &&
        Object.keys(errors?.settings).length > 0 && (
          <ErrorAlert message="Please check if you have filled up all fields correctly and try again" />
        )}
      <ErrorAlert
        fade={false}
        toggle={() => setErrors({})}
        isOpen={
          submitted && errors?.settings && Object.keys(errors?.settings).length
        }
      >
        Please check if you have filled up all fields correctly and try again
      </ErrorAlert>
      <div className="d-flex justify-content-center mt-auto">
        <Button
          color="gray"
          className="mt-4 mr-sm"
          onClick={() => onHandleResetChart()}
          type="button"
        >
          Cancel
        </Button>
        <Button
          color="blue"
          disabled={!indicatorType || isSubmitting}
          loading={isSubmitting}
          onClick={() => {
            setSubmitted(true);
          }}
          className="mt-4"
          type="submit"
        >
          {editing ? "Save" : "Attach"}
        </Button>
      </div>
    </>
  );
}

export default IndicatorFormFooter;
