import React, { useState } from "react";

import illustration from "assets/img/Crypto-investment.svg";
import Button from "components/Button";
import { CreateCredentialsModal } from "shared/ui";

const ConnectExchange = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className="d-flex flex-column flex-md-row justify-content-between">
        <div className="d-flex flex-column justify-content-center">
          <p className="h1 mb-2 text-white text-center text-md-left">
            Create credentials
          </p>
          <p className="font-sm text-white-75 mb-4 text-center text-md-left">
            Connect your trading accounts easily and effortlessly! Select your
            exchange to link to
            <br className="d-none d-lg-inline" />
            your Darkbot account for automated trading.
          </p>
          <div className="d-flex justify-content-center justify-content-md-start mb-4 mb-lg-0">
            <Button color="blue" onClick={() => setIsModalOpen(true)}>
              Connect
            </Button>
          </div>
        </div>
        <img src={illustration} alt="Crypto investment" />
      </div>
      <CreateCredentialsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

export default ConnectExchange;
