export const SUBSCRIBE_CHANNEL = "SUBSCRIBE_CHANNEL";
export const SUBSCRIBE_CHANNEL_ITEM = "SUBSCRIBE_CHANNEL_ITEM";
export const UNSUBSCRIBE_CHANNEL_ITEM = "UNSUBSCRIBE_CHANNEL_ITEM";
export const CREATE_CHANNEL = "CREATE_CHANNELS";
export const RESET_CHANNELS = "RESET_CHANNELS";
export const RESET_CHANNEL = "RESET_CHANNEL";
export const UPDATE_CHANNEL_INFORM = "UPDATE_CHANNEL_INFORM";
export const CLOSE_CHANNEL = "CLOSE_CHANNEL";
export const RESTORE_CHANNEL = "RESTORE_CHANNEL";
export const SET_CURRENT_CHANNEL = "SET_CURRENT_CHANNEL";
export const PUT_SUBSCRIPTION_SETTINGS = "PUT_SUBSCRIPTION_SETTINGS";
export const UPLOAD_CHANNEL_LOGO = "UPLOAD_CHANNEL_LOGO";
export const RESET_CHANNEL_LOGO = "RESET_CHANNEL_LOGO";

