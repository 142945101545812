import React, {
  useCallback,
  useState
} from "react";
import moment from "moment";
import classnames from "classnames";
import { capitalize } from "lodash";
import qs from "qs";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { 
  useHistory,
  useParams
} from "react-router-dom";

import Button from "components/Button";
import { replaceDurations } from "helpers/replaceDurations";
import { formatterToNull } from "helpers/valuesFormatters";
import { addSnackBar } from "store/snackbar/actions";
import { ReactComponent as Time } from "assets/img/icons/clock.svg";
import { ReactComponent as Trending } from "assets/img/icons/trendingsUp.svg";
import {
  cancelTradeRequest,
  fetchDeal,
  fetchHistoryTradeRequests,
} from "store/deals/actions";
import { 
  fetchRounds, 
  getAgreement 
} from "store/agreements/actions";

const initialFilterCompleted = qs.stringify({
  filter: {
    "tr.status": {
      eq: 'completed'
    }
  }
});

const TradeRequestDetails = ({
  trade,
  setIsModalOpen
}) => {
  const [cancelLoading, setCancelLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const isDeals = history.location.pathname.includes("deals")
  const {id} = useParams();

  const refreshAgreement = useCallback(() => {
    dispatch(getAgreement(id));
    dispatch(fetchRounds(id, `?${initialFilterCompleted}`));
  }, [id, dispatch])

  const refreshDeals = useCallback(() => {
    dispatch(fetchDeal(id));
    dispatch(fetchHistoryTradeRequests(id, `?${initialFilterCompleted}`));
  }, [id, dispatch])

  const handleCancel = async (id) => {
    setCancelLoading(true);
    const res = await dispatch(cancelTradeRequest(id));
    if (!res?.errors) {
      dispatch(addSnackBar("success", res.message));
      setIsModalOpen(false);
      if (isDeals) {
        refreshDeals();
      } else {
        refreshAgreement();
      }
    } else {
      dispatch(addSnackBar("error", res.message));
    }
    setCancelLoading(false);
  };

  return (
    <TradeRequestDetails.Trade
      className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between"
      comment={trade.comment}
    >
      <div className="d-flex flex-column flex-md-row mt-4 mt-md-5 mt-lg-0">
        <div className="d-flex justify-content-between justify-content-sm-start">
          <div className="d-flex flex-column justify-content-between">
            <div>
              <p className="mb-0 font-xs text-white-35 text-nowrap font-weight-300">
                Planned amount
              </p>
              <div className="d-flex align-items-center">
                <TradeRequestDetails.Amount amount={trade.plannedAmount.amount}>
                  {formatterToNull(trade.plannedAmount.amount)}
                </TradeRequestDetails.Amount>
                <p className="mb-0 ml-2 font-weight-300 font-sm text-white-35">
                  {trade.market.quote}
                </p>
              </div>
            </div>
            <div className="mt-3">
              <p className="mb-0 font-xs text-white-35 text-nowrap font-weight-300">
                Planned quantity
              </p>
              <div className="d-flex align-items-center">
                <TradeRequestDetails.Amount amount={trade.plannedQuantity.amount}>
                  {formatterToNull(trade.plannedQuantity.amount)}
                </TradeRequestDetails.Amount>
                <p className="mb-0 ml-2 font-weight-300 font-sm text-white-35">
                  {trade.market.base}
                </p>
              </div>
            </div>
          </div>

          <div className="d-none d-sm-flex flex-column justify-content-around mx-4">
            <Trending />
            <Trending />
          </div>

          <div className="d-flex flex-column justify-content-between w-25 mr-2 mr-sm-0">
            <div>
              <p className="mb-0 font-xs text-white-35 text-nowrap font-weight-300">
                Executed amount
              </p>
              <div className="d-flex align-items-center">
                <TradeRequestDetails.Amount amount={trade.executedAmount.amount}>
                  {formatterToNull(trade.executedAmount.amount)}
                </TradeRequestDetails.Amount>
                <p className="mb-0 ml-2 font-weight-300 font-sm text-white-35">
                  {trade.market.quote}
                </p>
              </div>
            </div>
            <div className="mt-3">
              <p className="mb-0 font-xs text-white-35 text-nowrap font-weight-300">
                Executed quantity
              </p>
              <div className="d-flex align-items-center">
                <TradeRequestDetails.Amount amount={trade.executedQuantity.amount}>
                  {formatterToNull(trade.executedQuantity.amount)}
                </TradeRequestDetails.Amount>
                <p className="mb-0 ml-2 font-weight-300 font-sm text-white-35">
                  {trade.market.base}
                </p>
              </div>
            </div>
          </div>
        </div>

        <TradeRequestDetails.Divided />

        <div className="d-flex flex-column flex-sm-row mt-3 mt-md-0">
          <div className="d-flex justify-content-between justify-content-sm-start">
            <div className="d-flex flex-column">
              <div>
                <p className="mb-0 font-xs text-white-35 text-nowrap font-weight-300">
                  Initial price
                </p>
                <TradeRequestDetails.Amount amount={trade.price.amount}>
                  {trade.price.amount || 0}
                </TradeRequestDetails.Amount>
              </div>
              <div className="mt-3">
                <p className="mb-0 font-xs text-white-35 text-nowrap font-weight-300">
                  Average price
                </p>
                <TradeRequestDetails.Amount amount={trade.price.amount}>
                  {trade.averageRate || 0}
                </TradeRequestDetails.Amount>
              </div>
            </div>
            <div className="d-flex flex-column ml-sm-1 w-25 mr-2 mr-sm-0 ml-0 ml-md-4">
              <div>
                <p className="mb-0 font-xs text-white-35 text-nowrap font-weight-300">
                  Boundary price
                </p>
                <TradeRequestDetails.Amount amount={trade.boundaryRate}>
                  {trade.boundaryRate || 0}
                </TradeRequestDetails.Amount>
              </div>
              <div className="mt-3">
                <p className="mb-0 font-xs text-white-35 text-nowrap font-weight-300">
                  Fee
                </p>
                <div className="d-flex flex-column flex-sm-row">
                  <TradeRequestDetails.Amount
                    amount={trade.fee.amount}
                    className="d-inline-block"
                  >
                    {trade.fee.amount || 0}
                  </TradeRequestDetails.Amount>
                  <p className="d-inline-block mb-0 ml-0 ml-sm-1 mr-sm-1 font-weight-300 font-sm text-white-35">
                    {trade.fee.asset || ""}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex mt-3 mt-sm-0 justify-content-between justify-content-sm-start">
            <div className="d-flex flex-column justify-content-between ml-1 mx-md-4">
              <div>
                <p className="mb-0 font-xs text-white-35 text-nowrap font-weight-300">
                  Orders
                </p>
                <TradeRequestDetails.Amount amount={trade.ordersCount}>
                  {trade.ordersCount || 0}
                </TradeRequestDetails.Amount>
              </div>
              <div className="mt-3">
                <p className="mb-0 font-xs text-white-35 text-nowrap font-weight-300">
                  Trades
                </p>
                <TradeRequestDetails.Amount amount={trade.tradesCount}>
                  {trade.tradesCount || 0}
                </TradeRequestDetails.Amount>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-between w-25 mr-2 mr-sm-0">
              <div>
                <p className="mb-0 font-xs text-white-35 text-nowrap font-weight-300">
                  Created at
                </p>
                <TradeRequestDetails.Amount amount={1}>
                  {moment(trade.createdAt).format("DD.MM.YY HH:mm")}
                </TradeRequestDetails.Amount>
              </div>
              <div className="mt-3">
                <p className="mb-0 font-xs text-white-35 text-nowrap font-weight-300">
                  {trade.status === "completed" && "Completed at"}
                  {trade.status === "cancelled" && "Cancelled at"}
                  {trade.status === "failed" && "Failed at"}
                  {trade.status === "open" && "Open at"}
                </p>
                <TradeRequestDetails.Amount amount={1}>
                  {trade.status === "completed" && moment(trade.completedAt).format("DD.MM.YY HH:mm")}
                  {trade.status === "cancelled" && moment(trade.cancelledAt).format("DD.MM.YY HH:mm")}
                  {trade.status === "failed" && moment(trade.failedAt).format("DD.MM.YY HH:mm")}
                  {trade.status === "open" && moment(trade.createdAt).format("DD.MM.YY HH:mm")}
                </TradeRequestDetails.Amount>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-flex flex-column flex-sm-row flex-lg-column flex-xl-row align-items-sm-center align-items-lg-end align-items-xl-center mt-3 mt-lg-0">
        <div
          className="d-flex flex-sm-column align-items-lg-end mb-2 mb-sm-0 mb-lg-2 mb-xl-0 mt-lg-0 mr-3 mr-lg-0 mr-xl-3">
          <TradeRequestDetails.Status className={classnames("mb-sm-2 mr-2 mr-sm-0 font-sm", {
            "text-success": trade.status === "completed",
            "text-white-35": trade.status === "cancelled",
            "text-warning": trade.status === "failed",
            "text-info": trade.status === "open",
          })}>
            {capitalize(trade.status)}
          </TradeRequestDetails.Status>
          <TradeRequestDetails.Status className="d-flex align-items-center">
            <Time className="mr-2 time" />
            <TradeRequestDetails.Amount amount={1}>
              {replaceDurations(trade.lifetime)}
            </TradeRequestDetails.Amount>
          </TradeRequestDetails.Status>
        </div>

        {
          trade.status === "open" &&
          <Button
            onClick={() => handleCancel(trade.id)}
            disabled={cancelLoading}
            loading={cancelLoading}
          >
            Cancel
          </Button>
        }
      </div>
    </TradeRequestDetails.Trade>
  )
}

TradeRequestDetails.Trade = styled.div`
  background: #242538;
  border-radius: 8px;
  padding: 16px 24px;
  margin: ${({ comment }) => comment ? "24px 0 8px 0" : "24px 0"};

  .time {
    path {
      stroke: #FFFFFF;
    }
  }
`;

TradeRequestDetails.Type = styled.span`
  background: ${(props) => props.type === "buy" ? "#3FCBB0" : "#ED5F5F"};
  color: ${(props) => props.type === "buy" ? "#1D1E2A" : "#FFFFFF"};
  border-radius: 4px;
  margin-bottom: 0;
  font-weight: 500;
  padding: 4px 11px;
  text-transform: uppercase;
  font-size: 0.875rem;
`;

TradeRequestDetails.Amount = styled.p`
  font-weight: 500;
  margin-bottom: 0;
  color: ${(props) => props.amount > 0 ? "rgba(255, 255, 255)" : "rgba(255, 255, 255, 0.35)"} !important;
  white-space: nowrap;
  font-size: 0.875rem;
`;

TradeRequestDetails.Divided = styled.div`
  display: flex;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  width: 1px;
  margin: 0 24px;
`;

TradeRequestDetails.Status = styled.div`
  background: rgba(255, 255, 255, 0.03);
  border-radius: 6px;
  padding: 3px 10px;
  white-space: nowrap;
  width: fit-content;
  font-weight: 500;
`;

export default TradeRequestDetails;
