import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  Card,
  CardBody,
  CardHeader,
  Tooltip
} from "reactstrap";

import Icon from "components/Icon";
import { ReactComponent as ForkOff } from "assets/img/icons/forkOff.svg";
import { ReactComponent as Fork } from "assets/img/icons/fork.svg";
import { ReactComponent as Message } from "assets/img/icons/message.svg";
import CredentialWidgetPreload from "views/deals/ui/preloaders/CredentialWidgetPreload";
import { formatterToNull } from "helpers/valuesFormatters";
import LineWithError from "shared/ui/LineWithError";
import PercentBadge from "components/PercentBadge";

const Credential = ({ credential={}, loading }) => {
  const [isShowingTooltip, setIsShowingTooltip] = useState(false);

  const toggleDailyRevenue = () => setIsShowingTooltip((prev) => !prev);

  if (loading) return <CredentialWidgetPreload />;

  return(
    <Link to={`/credentials/${credential.id}`}>
      <Credential.Card>
        <Credential.Header className="d-flex align-items-center px-4 py-3">
          <div className="d-flex align-items-center position-relative">
            <Icon
              type="exchanges"
              size="xxs"
              code={credential.exchange}
              className="mr-3 my-auto"
            />
            <Credential.IconWrapper className="borderStatus">
              {credential.connected ? <Fork data-testid="enabled"/> : <ForkOff data-testid="disabled"/>}
            </Credential.IconWrapper>
          </div>
          <div className="d-flex justify-content-between w-100 overflow-hidden">
            <p className="h3 mb-0 text-white font-weight-500 text-capitalize">
              {credential.exchange}
            </p>
            <PercentBadge
              showZeroValue
              isColorSpectrum={+credential?.dailyRevenueBtcPercent ? false : true}
              value={credential.dailyRevenueBtcPercent}
              id="cred-daily-revenue"
              data-testid="dailyRevenue"
            />
            {
              +credential?.dailyRevenue ?
              <Tooltip
                target="cred-daily-revenue"
                placement="top"
                isOpen={isShowingTooltip}
                toggle={toggleDailyRevenue}
                className="text-nowrap"
                role="tooltip"
              >
                Daily revenue
              </Tooltip> : null
            }
          </div>
        </Credential.Header>

        <LineWithError
          radius="0"
          errorMessage={credential.error}
        />

        <CardBody className="pt-3 px-4 pb-4">
          <div className="d-flex flex-column">
            <div className="d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between
              align-items-sm-center align-items-md-start align-items-lg-center"
            >
              <Credential.Title>Balance</Credential.Title>
              <div className="d-flex align-items-center">
                <Credential.Amount amount={credential.balance?.btc}>
                  {formatterToNull(credential.balance?.btc)}
                </Credential.Amount>
                <Credential.Currency>BTC</Credential.Currency>
              </div>
            </div>

            <div className="d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between
              align-items-sm-center align-items-md-start align-items-lg-center mt-2"
            >
              <Credential.Title>Estimated</Credential.Title>
              <div className="d-flex align-items-center">
                <Credential.Amount
                  amount={credential.estimated?.amount}
                  data-testid="amount"
                >
                  {formatterToNull(credential.estimated?.amount)}
                </Credential.Amount>
                <Credential.Currency>{credential.estimated?.currency}</Credential.Currency>
              </div>
            </div>

            <div className="d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between
              align-items-sm-center align-items-md-start align-items-lg-center mt-2"
            >
              <Credential.Title>Rebalance</Credential.Title>
              <p className={`m-0 font-weight-500 ${credential.rebalance === false ? "text-danger" : "text-success"}`}>
                {`${credential.rebalance === false ? "Disabled" : "Enabled"}`}
              </p>
            </div>
            {
              credential.comment ?
              <div className="credential-comment mt-4 position-relative">
                <div className="mr-2 position-absolute bottom-0">
                  <Message data-testid="comment"/>
                </div>
                <div className="pl-3 overflow-hidden rbc-ellipsis">
                  <span className="text-white-75 overflow-hidden rbc-ellipsis text-nowrap font-sm">
                    {credential?.comment}
                  </span>
                </div>
              </div>
              : null
            }
          </div>
        </CardBody>
      </Credential.Card>
    </Link>
  );
};

Credential.Card = styled(Card)`
  cursor: pointer;
  margin-bottom: 24px !important;

  .credential-comment {
    span {
      padding-left: 12px;
    }
  }

  &:hover {
    background-color: rgba(46, 49, 72, 1) !important;

    .borderStatus {
      svg {
        border: 3px solid #363A54;
        background: #363A54;
      }
    }

    .card-header {
      background: #363A54 !important;
    }
  }
`;

Credential.Header = styled(CardHeader)`
  background-color: #2E3148 !important;
  border-radius: 8px 8px 0 0 !important;
`;

Credential.Title = styled.p`
  margin-bottom: 0;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.75) !important;
  font-size: 0.875rem;
`;

Credential.Amount = styled.p`
  margin-bottom: 0;
  margin-right: 4px;
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.amount > 0 ? "#FFFFFF" : "rgba(255, 255, 255, 0.35)"} !important;
`;

Credential.Currency = styled.p`
  margin-bottom: 0;
  font-weight: 300;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.35) !important;
`;

Credential.IconWrapper = styled.div`
  svg {
    transform: scale(-1, 1);
    position: absolute;
    top: 60%;
    left: 45%;
    width: 21px;
    height: 21px;
    background: #2E3148;
    border: 3px solid #2E3148;
    border-radius: 50px;
  }
`;

export default Credential;
