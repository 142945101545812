import React from "react";

import Portfolio from "./Portfolio";
import PaginationPanel from "components/PaginationPanel";

const PortfolioList = ({portfolio = [], rebalance, page, total, handlePaginate}) => {

  const initialPlannedCount = portfolio?.reduce((acc, item) => acc + (item.plannedPercent || 0), 0);

  return (
    <div className="w-100 d-flex flex-column">
      {
        portfolio?.map((investment, index) =>
          <Portfolio
            key={investment.id}
            index={index}
            investment={investment}
            rebalance={rebalance}
            initialPlannedCount={initialPlannedCount}
          />
        )
      }

      <div className="mb-4">
        <PaginationPanel
          activePage={page}
          totalItemsCount={total}
          onChange={(page) => handlePaginate(page)}
        />
      </div>

    </div>
  );
};

export default PortfolioList;
