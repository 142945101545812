import React, { 
  useLayoutEffect,
  useRef
} from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import { useHistory } from "react-router-dom";
import qs from "qs";
import {
  Card,
  CardBody
} from "reactstrap";

import {
  getBots,
  resetBots
} from "store/bots/actions";
import Error from "components/Error";
import BotsPreloader from "components/BotsPreloader";
import MarketBotsEmpty from "views/credentials/states/MarketBotsEmpty";
import PaginationPanel from "../PaginationPanel";
import MarketBotsFilters from "components/MarketBots/MarketBotsFilters";
import BotsHeader from "./BotsHeader";
import BotsTable from "./BotsTable";
import EmptyDashboardWidgets from "views/dashboard/compounds/EmptyDashboadWidgets";
import {ReactComponent as Empty} from "assets/img/empty-state-planet2.svg";
import {EMPTY_TEXT} from "../EmptyState/mock";
import useFilterAndSorting from "../../hooks/useFilterAndSorting";
import usePagination from "../../hooks/usePagination";
import LineWithError from "shared/ui/LineWithError";

const BotsList = ({ 
  id, 
  loadingPage, 
  freeMarkets, 
  investment, 
  balance,
  coinName,
  coinSymbol 
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const botsRef = useRef(null);
  const {items, page, total, request} = useSelector((state) => state.bots.list.data);
  const loading = useSelector((state) => state.bots.list.loading);
  const error = useSelector((state) => state.bots.list.error);

  const { handlePaginate } = usePagination(`/investments/${id}`, true);
  const {handleFilter,handleSorting,filter, } = useFilterAndSorting(`/investments/${id}`);
  const isFilter  = history.location.search.includes("filter");

  useLayoutEffect(() => {
    const filter = qs.stringify({filter: {investment: {eq: id}}});
    const historySearch = history.location.search.replace('?',"&")
    dispatch(getBots(`?${filter}${historySearch}`));
    return () => dispatch(resetBots());
  }, [dispatch, history.location.search,id]);

  if (loadingPage && loading) return <BotsPreloader renderHeader={true}/>;

  if (error) return <Error error={error}/>;

  return (
    <div ref={botsRef}>
      <Card className="overflow-hidden">
        <BotsHeader
          freeMarkets={freeMarkets}
          investment={investment}
          balance={balance}
          coinName={coinName}
          coinSymbol={coinSymbol}
        />
        <MarketBotsFilters
          filters={filter}
          handleFilter={handleFilter}
        />
        {
          balance <= 0
            ? <LineWithError
              radius="0"
              errorMessage={`You cannot use created bots due to your empty ${coinName} balance. Please top up your ${coinSymbol} balance and try again.`}
            />
            : null
        }
        {loading && <BotsPreloader renderHeader={false} />}
        {
          items?.length ?
          <>
            <BotsTable
              items={items}
              id={id}
              sort={request?.sort}
              handleSorting={handleSorting}
            />
            <div className={`${total > 10 ? "mt-3" : ""}`}>
              <PaginationPanel
                activePage={page}
                totalItemsCount={total}
                totalPagesView={10}
                onChange={(page) => handlePaginate(page)}
                elementRef={botsRef}
              />
            </div>
          </>
          :null
        }
        {items?.length === 0 && !loading && !isFilter && <MarketBotsEmpty/>}
        {items?.length === 0 && !loading && isFilter &&
          <CardBody className="px-4 pb-4 pt-0">
            <EmptyDashboardWidgets
              Img={Empty}
              title={EMPTY_TEXT.title}
              descriptionBefore={EMPTY_TEXT.desc}
            />
          </CardBody>}
      </Card>
    </div>
  );
};

export default BotsList;
