import styled from "styled-components";

export const TableAmount = styled.p`
  font-weight: 500;
  margin-bottom: 0;
  color: ${(props) => props.amount > 0 ? "rgba(255, 255, 255)" : "rgba(255, 255, 255, 0.35)"} !important;
  white-space: nowrap;
`;

export const TableCurrency = styled.p`
  font-weight: 300;
  margin: 0 0 0 4px;
  color: rgba(255, 255, 255, 0.35) !important;
`;

export const TableStatus = styled.div`
background: rgba(255, 255, 255, 0.03);
border-radius: 6px;
padding: 0 10px;
white-space: nowrap;
font-weight: 500;
width: max-content;
`;