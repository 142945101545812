import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Button from "components/Button";
import { ReactComponent as Flag } from "assets/img/icons/flag.svg";

const TradeRequestHeader = ({
  title = "Open trade request",
  openTechnicalLogs,
  isHistoryTradeRequest,
  statistics,
  isOpenNewBuyLoading,
  openNewBuy,
  actions,
  pageView,
}) => {
  return (
    <div className="d-flex flex-column flex-sm-row align-items-md-center mb-3 justify-content-between">
      <div className="d-flex flex-column flex-lg-row align-items-lg-center mr-3">
        <div className="d-flex align-items-center">
          <span className="d-inline-block">
            <Flag />
          </span>
          <p className="mb-0 ml-2 text-white font-weight-500 text-nowrap mr-lg-4">
            {title}
          </p>
        </div>
        {
          isHistoryTradeRequest
          ? <span className="text-white-35">{statistics?.completed} Completed, {statistics?.cancelled} Cancelled, {statistics?.failed} Failed</span>
          :null
        }
      </div>
      {
        isHistoryTradeRequest && pageView === "deals"
          ?
          <div className="d-flex align-items-center flex-wrap flex-sm-nowrap">
            <TradeRequestHeader.TechnicalLogs onClick={() => openTechnicalLogs()}>
              Technical logs
            </TradeRequestHeader.TechnicalLogs>
            <Button
              color="blue"
              className="text-nowrap"
              disabled={!actions?.trade}
              loading={isOpenNewBuyLoading}
              onClick={() => openNewBuy()}
            >
              Open new BUY
            </Button>
          </div>
          : null
      }
    </div>
  )
};

TradeRequestHeader.TechnicalLogs = styled.p`
  color: rgba(47, 128, 237, 1) !important;
  margin-bottom: 0;
  margin-right: 16px;
  font-size: 16px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    color: rgba(47, 128, 237, 0.8) !important;
    text-decoration: underline;
  }
`;

TradeRequestHeader.propTypes = {
  title: PropTypes.string,
  openTechnicalLogs: PropTypes.func,
  isHistoryTradeRequest: PropTypes.bool
};

export default TradeRequestHeader;
