import React from "react";
import styled from "styled-components";

import textLogo from "assets/img/text-logo.svg";

const FormDescription = ({ 
  title="", 
  text="" 
}) => {
  return (
    <>
      <img
        src={textLogo}
        alt="Darkbot"
        className="mb-3 mb-lg-4 rounded-0"
        width={102}
        height={12}
      />
      <StyledHeading className="font-weight-700 text-white mb-2">
        {title}
      </StyledHeading>
      {
        text ?
        <p className="text-white-75 d-none d-md-block">
          {text}
        </p> : null
      }
    </>
  );
};

const StyledHeading = styled.h1`
  font-size: 2.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export default FormDescription;
