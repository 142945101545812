import React, { useState } from "react";
import { Form, Formik, Field } from "formik";
import TagsInput from "react-tagsinput";
import {
  Row,
  Col,
  Label,
  Button,
  FormGroup,
  CardBody,
  Card,
  UncontrolledAlert
} from "reactstrap";
import * as Yup from "yup";

import Images from "./Images";
import { defaultImages } from "../utils/defaultImages";

const style = {
  color: "#ec250d",
};

const viewSchema = Yup.object().shape({
  name: Yup.string()
    .required("This field is required."),
  defaultImage: Yup.number()
    .required("Choose default image")
});

const StrategyView = ({
  setGeneralInformation,
  goNextStep,
  reqErrors
}) => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <Card>
      <CardBody>
        <Formik
          enableReinitialize
          initialValues={{
            name: "",
            tags: [],
            defaultImage: ""
          }}
          validationSchema={viewSchema}
          onSubmit={(values) => {
            setGeneralInformation(values);
            goNextStep();
          }}
        >
          {({ values, errors, setFieldValue }) => (
            <Row className="justify-content-center mt-5">
              <Col xs="10">
                <Form>
                  <p className="h4 font-weight-bold">
                    Customization
                  </p>
                  <Row>
                    <Col xs="12">
                      <Label>Name</Label>
                      <FormGroup className={submitted && (errors.name || reqErrors.name) ? "has-danger" : "has-success"}>
                        <Field
                          className="form-control"
                          name="name"
                          type="text"
                        />
                        <Col md="12">
                          {submitted && (errors.name || reqErrors.name) ?
                            <label style={style}>{errors.name || reqErrors.name}</label> : null}
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col xs="12">
                      <Label>Tags</Label>
                      <FormGroup>
                        <TagsInput
                          onChange={(v) => setFieldValue("tags", v)}
                          tagProps={{ className: "react-tagsinput-tag primary" }}
                          value={values.tags}
                          onlyUnique={true}
                          maxTags={8}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <p className="h4 mt-4">
                    Strategy image
                  </p>
                  {submitted && (errors.defaultImage || reqErrors.defaultImage) ?
                    <UncontrolledAlert color="danger" fade={false}>
                      {errors.defaultImage || reqErrors.defaultImage}
                    </UncontrolledAlert> : null}
                  <Images
                    defaultImages={defaultImages}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                  <div className="mt-4">
                    <Button
                      type="submit"
                      onClick={() => setSubmitted(true)}
                      color="info"
                      className="pull-right"
                    >
                      Next - Strategy settings
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default StrategyView;
