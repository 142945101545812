export const CREATE_CHANNEL_CONTENT = {
  title: "Create new channel",
  form: {
    fieldName: {
      label: "Name",
      placeholder: "Type in...",
      errors: {
        requiredName: "This field is required",
        maxName: "Name can not be longer than 50 characters."
      },
    },
    fieldDescription: {
      label: "Description",
      placeholder: "Type in...",
      errors: {
        requiredDescription: "This field is required",
        maxDescription: "Description can not be longer than 4800 characters."
      }
    },
    fieldTelegram: {
      label: "Telegram channel link",
      informMsg: "Before linking your Telegram channel, please ensure you have added our Telegram bot @ioDarkbot to your channel. Click  <a href=\"https://t.me/ioDarkbot\" >here</a> to add channel as Administrator.",
      errors: {
        invalidTelegram: "Invalid Telegram link",
      }
    },
    fieldFile: {
      label: "Upload a logo of your channel",
      buttons: {
        change: "Change",
        select: "Select file"
      },
      informMsg: "Recommended size is above 80x80px.\nAvailable formats are JPG, JPEG, and PNG",
      errors: {
        requiredFile: "This field is required",
        maxFileSize: "File size is too large (max 1MB)",
        maxFileFormat: "Invalid file type. Available formats are JPG, JPEG, and PNG."
      },
      supportedFormats: ["image/jpeg", "image/jpg", "image/png"]
    },
    buttons: {
      cancel: "Cancel",
      create: "Create"
    },
  }
};
