export const DEPTH_OPTIONS = [
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 15, label: "15" },
  { value: 20, label: "20" },
];

export const GROUP_OPTIONS = [
  { value: "0", label: "1" },
  { value: "1", label: "0.1" },
  { value: "2", label: "0.01" },
];

export const WHOLE_GROUP_OPTIONS = [
  { value: 1000, label: "1000" },
  { value: 100, label: "100" },
  { value: 10, label: "10" },
];

export const GROUP_BUTTONS = [
  {title: "Depth", type: "depth"},
  {title: "Orderbook", type: "orderBook"}
];