import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Table } from "reactstrap";

const LoginHistoryTable = ({ data=[] }) => (
  <Table className="mb-0">
    <LoginHistoryTable.Thead>
      <tr>
        <th>Location</th>
        <th>IP address</th>
        <th>Device</th>
        <th>Date</th>
      </tr>
    </LoginHistoryTable.Thead>
    <LoginHistoryTable.Tbody className="font-sm">
      {
        data.map((item) => {
          return (
            <tr key={item.id}>
              <td>
                <p className="mb-0 text-white font-weight-500 text-nowrap">
                  {`${item.city}, ${item.country}`}
                </p>
              </td>
              <td>
                <p className="mb-0 text-white-35">
                  {item.ip}
                </p>
              </td>
              <td>
                <p className="mb-0 text-white">
                  {item.userAgent}
                </p>
              </td>
              <td>
                <LoginHistoryTable.Date>
                  {moment(item.createdAt).format("DD.MM.YY HH:mm")}
                </LoginHistoryTable.Date>
              </td>
            </tr>
          )
        })
      }
    </LoginHistoryTable.Tbody>
  </Table>
);

LoginHistoryTable.Thead = styled.thead`
  tr {
    background: #2E3148;

    th {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      color: rgba(255, 255, 255, 0.35) !important;
      font-weight: 300 !important;
      font-size: 16px !important;

      &:first-child {
        padding-left: 32px;
      }
      &:last-child {
        padding-right: 32px;
      }
    }

  }
`;

LoginHistoryTable.Tbody = styled.tbody`
  tr {
    border-top: 1px solid #1e1e2f;

    td {
      padding-top: 16px !important;
      padding-bottom: 16px !important;

      &:first-child {
        padding-left: 32px;
      }
      &:last-child {
        padding-right: 32px;
      }
    }
  }
`;

LoginHistoryTable.Date = styled.p`
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.75);
  font-weight: 500;
  white-space: nowrap;
`;

export default LoginHistoryTable;
