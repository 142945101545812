import styled from "styled-components";
import { Table } from "reactstrap";

const StyledTable = styled(Table)`
  & thead tr th {
    font-size: 0.875rem !important;
    line-height: 16.41px;
    font-weight: 300 !important;
    color: #FFFFFF !important;
    padding: ${(props) => props.paddingsThead || "0.5rem 1rem"} !important;
    background: ${(props) => props.backgroundThead || "initial"} !important;
  }

  & tbody tr {
    background: var(--dark-slate);
    box-shadow: 0 -1px #2E3148;
    &:hover {
      background: #2E3148;

      & + .additional-tr {
        background: ${(props) => props.isPreloading ? "#2E3148" : "#363A54"};
      }
    }

    &:not(:last-child) td {
      border-bottom: 1px solid var(--dark-slate);
    }
  }

  & tbody tr td {
    padding: 1rem 1rem !important;

    &.px-0 {
      padding: 1rem 0 !important;
    }
  }

  & thead tr th, & tbody tr td {
    &:first-child {
      padding-left: 1.5rem !important;
    }

    &:last-child {
      padding-right: 1.5rem !important;
    }
  }
`;

export default StyledTable;
