import React from "react";
import styled from "styled-components";
import {Progress} from "reactstrap";

const OrdersProgressBar = ({filledPercent}) => {
  return (
    <OrdersProgressBar.Progress
      value={filledPercent}
      className="mr-2"
    />
  );
};

OrdersProgressBar.Progress = styled(Progress)`
  width: 78px;
  box-shadow: none !important;
  background: #363A54 !important;
  .progress-bar {
    background-color: ${(props) => {
      if (!props.value) return "#363A54";
      if (props.value > 0 && props.value < 100) return "var(--info)";
      if (props.value >= 100) return "#3FCBB0";
    }}
`;

export default OrdersProgressBar;