import React, {useEffect} from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import {DelayInput} from "react-delay-input";
import styled from "styled-components";

import SelectedMarkets from "./SelectedMarkets";
import SortTableHead from "components/SortTableHead";
import MarketsTablePreloader from "./MarketsTablePreloader";
import CustomScrollbars from "components/CustomScrollbars";
import StyledTable from "components/StyledTable";
import FormGroupWithSymbols from "components/FormGroupWithSymbols";
import Checkbox from "components/Checkbox";
import PaginationPanel from "components/PaginationPanel";
import MarketTableRow from "./MarketTableRow";
import MarketsTableEmpty from "./MarketsTableEmpty";
import {getMarketsTableHead} from "models/bot/wizard/enum/table";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import {getAvailableMarkets} from "store/markets/actions";
import {
  setExcludedMarkets, 
  setSelectAllMarkets, 
  setSelectedMarkets
} from "store/bots/wizard/actions";
import {ReactComponent as Search} from "assets/img/icons/search.svg";

const MarketsTable = ({
  data, 
  loading, 
  searchValue, 
  setSearchValue, 
  setIsOpenModal, 
}) => {
  const dispatch = useDispatch();
  const {allCoins, excludedCoins=[], coins=[], investment} = useSelector((state) => state.botWizard);

  const {
    handleFilter,
    handleSorting,
    handlePaginate
  } = useFiltersWithRequest(data?.request, getAvailableMarkets, investment?.id);

  const handleSearch = (value) => {
    setSearchValue(value.value);
    if (!value.value?.replaceAll(" ", "")) return handleFilter({field: {}, query: {}});
    handleFilter({field: "market", query: value.value?.toUpperCase()?.replaceAll(" ", "")});
  };

  const handleExclude = (market) => {
    const findMarket = excludedCoins?.length > 0 && excludedCoins.some((item) => item === market);
    const newItem = !findMarket && market;
    dispatch(setExcludedMarkets(newItem ? [...excludedCoins, newItem] : [...excludedCoins]));
  };

  const handleInclude = (market) => {
    const newExcluded = excludedCoins.filter((elem) => elem !== market);
    dispatch(setExcludedMarkets(newExcluded));
  };

  const handleSelect = (market) => {
    const findMarket = coins?.length > 0 && coins.some((item) => item === market);
    const newItem = !findMarket && market;
    dispatch(setSelectedMarkets(newItem ? [...coins, newItem] : [...coins]));
  };

  const handleDeselect = (market) => {
    const newSelected = coins.length ? coins.filter((elem) => elem !== market):[];
    dispatch(setSelectedMarkets(newSelected));
  };

  const handleAllMarkets = (loading) => {
    if (loading) return
    dispatch(setExcludedMarkets([]));
    dispatch(setSelectedMarkets([]));
    dispatch(setSelectAllMarkets(!allCoins));
  };

  useEffect(() => {
    if (excludedCoins?.length > 1 && (excludedCoins?.length === 11 || excludedCoins?.length === data?.total)) {
      return setIsOpenModal(true);
    }
    if (coins?.length > 1 && (coins?.length === 11 || coins?.length === data?.total)) {
      return setIsOpenModal(true);
    }
  }, [coins, data?.total, excludedCoins, setIsOpenModal]);

  return (
    <MarketsTable.Wrapper excludedCoins={excludedCoins} coins={coins}>
      <div className="d-flex flex-column flex-md-row align-items-md-center mx-4 mb-3">
        <MarketsTable.MarketBadge
          disabled={loading}
          className="d-flex align-items-center text-nowrap"
          onClick={() => handleAllMarkets(loading)}
        >
          <Checkbox
            checked={allCoins}
            disabled={loading}
          />
          <p className="mb-0 font-sm text-white-75 ml-1">
            Select all markets
          </p>
        </MarketsTable.MarketBadge>
        <div className="mt-3 mt-md-0 ml-md-2 w-100">
            <FormGroupWithSymbols symbolLeft={<Search className="search"/>}>
              <DelayInput
                autoComplete="off"
                delayTimeout={700}
                type="string"
                name="from"
                className="form-control w-100"
                placeholder="Search"
                value={searchValue}
                onChange={({target}) => handleSearch({value: target.value})}
                disabled={loading}
              />
            </FormGroupWithSymbols>
        </div>
      </div>
      {
        loading ? <MarketsTablePreloader/> :
          <CustomScrollbars
            autoHideTimeout={500}
            autoHideDuration={500}
            autoHeight
            autoHeightMax={Number.MAX_VALUE}
          >
            {
              !data?.items?.length ? <MarketsTableEmpty/> :
                <StyledTable className="mb-0" paddingsThead="1rem">
                  <SortTableHead
                    handleSorting={handleSorting}
                    sort={data?.request?.sort}
                    theadItems={getMarketsTableHead(investment.credentials?.exchange)}
                  />

                  <tbody className="font-sm">
                  {
                    data?.items?.map((item) =>
                      <MarketTableRow
                        key={item.symbol}
                        item={item}
                        isBinance={investment?.credentials?.exchange==="binance"}
                        handleDeselect={handleDeselect}
                        handleInclude={handleInclude}
                        handleSelect={handleSelect}
                        handleExclude={handleExclude}
                      />
                    )
                  }
                  </tbody>
                </StyledTable>
            }

          </CustomScrollbars>
      }
      <div className="pt-3">
        <PaginationPanel
          activePage={data?.page}
          totalItemsCount={data?.total}
          onChange={handlePaginate}
        />
      </div>

      <SelectedMarkets
        allCoins={allCoins}
        excludedCoins={excludedCoins}
        coins={coins}
        handleDeselect={handleDeselect}
        handleInclude={handleInclude}
        asset={investment?.asset}
        isEdited
      />
    </MarketsTable.Wrapper>
  );
};

MarketsTable.Wrapper = styled.div`
  margin-top: 32px;
  border-bottom: ${({coins, excludedCoins}) => excludedCoins.length || coins.length ? "1px solid #2E3148" : "none"};

  .search {
    path {
      stroke: rgba(255, 255, 255, 0.35) !important;
    }
  }

  .pagination {
    margin-bottom: 0;
  }

  tbody {
    tr {
      &.excluded {
        background: var(--dusk-steel);
      }

      &:last-child {
        border-bottom: 1px solid #2E3148;
      }

      &.deselect{
        img, svg, p, span {
          opacity: 0.35;
        }
      }

      &:last-child {
        border-bottom: 1px solid #2E3148;
      }
    }
  }
`;

MarketsTable.MarketBadge = styled.div`
  background: #2E3148;
  border-radius: 6px;
  padding: 10px 12px;
  cursor: ${({ disabled }) => disabled ? "not-allowed" : "pointer"}
`;

export default MarketsTable;
