import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {CardBody, Card} from "reactstrap";
import qs from "qs";

import TradeHistoryTable from "./TradeHistoryTable";
import TradeHistoryFilters from "./TradeHistoryFilters";
import Error from "components/Error";
import {fetchCredential, fetchTradeHistory, clearTradeHistory} from "store/credentials/actions";
import {getMarkets, resetMarkets} from "store/markets/actions";
import PaginationPanel from "components/PaginationPanel";
import TradeHistoryHeader from "./TradeHistoryHeader";
import EmptyTables from "../states/EmptyTables";
import {paginationScrollUp} from "helpers/paginationScrollUp";
import {setCredentialsBreadcrumbs} from "models/enum/breadcrumbs";
import Breadcrumbs from "components/Breadcrumbs";
import TablePreloader from "components/TablePreloader";
import useRequestCancellation from "hooks/useRequestCancellation";

const TradeHistory = ({match, location}) => {
  const [emptyFilter, setEmptyFilter] = useState(null);
  const [initialLoading, setInitialLoading] = useState(false);

  const history = useHistory();
  const search = qs.parse(location.search, {ignoreQueryPrefix: true});
  const dispatch = useDispatch();

  const {items, total, page} = useSelector((state) => state.credentials.tradeHistory.data);
  const loading = useSelector((state) => state.credentials.tradeHistory.loading);
  const error = useSelector((state) => state.credentials.tradeHistory.error);
  const credential = useSelector((state) => state.credentials.item.data);
  const markets = useSelector((state) => state.markets);
  const { useAbortableEffect } = useRequestCancellation();

  useAbortableEffect((signal) => {
    setInitialLoading(true);
    dispatch(fetchCredential(match.params.id, signal))
      .catch(() => setInitialLoading(false));
  }, [match.params.id, dispatch], true);

  useAbortableEffect((signal) => {
    dispatch(fetchTradeHistory(match.params.id, location.search, signal));
    return () => dispatch(clearTradeHistory());
  }, [location.search, match.params.id, dispatch], true);

  useAbortableEffect((signal) => {
    if (credential.exchange) dispatch(getMarkets(credential.exchange, signal))
      .finally(() => setInitialLoading(false));
    return () => dispatch(resetMarkets());
  }, [credential, dispatch], true);

  const handlePaginate = (page) => {
    history.push({
      pathname: `/credentials/${match.params.id}/trade-history`,
      search: qs.stringify({...search, page})
    });
    paginationScrollUp();
  };

  useEffect(() => {
    window.analytics.page("/credentials/:id/trade-history");
  }, []);

  return (
    <>
      <Breadcrumbs
        items={setCredentialsBreadcrumbs(credential.exchange, match.params.id, "Trade history")}
        loading={loading}
      />

      <TradeHistoryHeader
        id={match.params.id}
        exchange={credential.exchange}
        loading={initialLoading}
      />
      <TradeHistoryFilters
        location={location}
        loading={initialLoading}
        id={match.params.id}
        markets={markets}
        setEmptyFilter={setEmptyFilter}
      />
      {error && <Error error={error}/>}
      {
        initialLoading || loading ?
          <Card className="mb-0 pt-3 overflow-hidden">
            <TablePreloader
              thHeight={12}
              thWidth={[35, 48, 35, 37, 37, 44, 35, 52, 44, 56]}
              tdWidth={[32, 72, 72, 72, 134, 75, 129, 105, 66, 94]}
              tdHeight={12}
              rowTimes={10}
            />
          </Card>
          :
          <>
            {
              items?.length ?
                <>
                  <Card className="mb-0 overflow-hidden">
                    <CardBody className="p-0">
                      <TradeHistoryTable
                        data={items}
                        id={match.params.id}
                      />
                    </CardBody>
                  </Card>
                  <div className="pt-3">
                    <PaginationPanel
                      activePage={page}
                      totalItemsCount={total}
                      onChange={(page) => handlePaginate(page)}
                    />
                  </div>
                </>
                :
                <EmptyTables
                  title="There are no available trade history at the moment"
                  description="Please open create some trades to see them displayed here"
                  emptyFilter={emptyFilter}
                />
            }
          </>
      }
    </>
  );
};

export default TradeHistory;
