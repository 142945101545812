import React from "react";
import PropTypes from "prop-types";

import SignalProgress from "./SignalProgress";
import {
  fetchRounds,
  forceStartAgreement,
} from "store/agreements/actions";
import { selectTradeRequest } from "store/agreements/selectors";
import { TradeRequestList } from "shared/ui";

const OverviewTab = ({
  loadingPage,
  id,
  data,
  onHandleRefresh
}) => {
  return (
    <>
      <SignalProgress
        loading={loadingPage}
        data={data}
      />
      <TradeRequestList
        id={id}
        openTradeRequest={data?.openRound}
        data={data}
        loadingPage={loadingPage}
        actionFetchHistoryTR={fetchRounds}
        selectData={selectTradeRequest}
        pageView="agreement"
        onHandleRefresh={onHandleRefresh}
        onHandleForceStart={forceStartAgreement}

      />
    </>
  )
};

OverviewTab.propTypes = {
  loadingPage: PropTypes.bool,
  id: PropTypes.string
};

export default OverviewTab;
