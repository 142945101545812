import React from "react";
import {Col, FormGroup, CardBody, Row} from "reactstrap";
import {DelayInput} from "react-delay-input";

import {mergeStyles} from "components/SelectInput/utils";
import { getUsersReferrals } from "store/user/actions";
import { SUBSCRIPTION_TYPE, CONFIRMED_ACCOUNT, TRIAL } from "models/filters";
import SelectInput from "components/SelectInput";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";

const Filters = ({referrals}) => {

  const {handleFilter, filters} = useFiltersWithRequest(referrals?.request, getUsersReferrals);

  const filterStyles = {
    control: () => ({
      minWidth: "120px"
    }),
  };

  return (
    <CardBody className="p-0">
      <form>
        <Row className="d-flex flex-sm-column flex-md-row align-items-center flex-sm-wrap flex-md-nowrap m-0 p-0 pr-2">
          <Col xs="12" md="3" className="p-0 mb-sm-2 mb-md-0">
            <FormGroup className="form-input m-0">
              <DelayInput
                autoComplete="off"
                delayTimeout={700}
                type="text"
                name="search"
                placeholder="Email"
                className="form-control"
                value={filters?.email?.like ?? ""}
                onChange={(event) => handleFilter({"email": {like: event.target.value}})}
              />
            </FormGroup>
          </Col>
          <Col xs="12" md="3" className="p-0 ml-md-2 mb-sm-2 mb-md-0">
            <FormGroup className="form-input m-0">
              <SelectInput
                isClearable
                placeholder="Subscription"
                name="subscription"
                styles={mergeStyles(filterStyles)}
                onChange={(option) => handleFilter({subscriptionType: option?.value !== null ? {eq: option?.value} : {eq: null}})}
                options={SUBSCRIPTION_TYPE}
              />
            </FormGroup>
          </Col>
          <Col xs="12" md="3" className="p-0 ml-md-2 mb-sm-2 mb-md-0">
            <FormGroup className="form-input m-0">
              <SelectInput
                isClearable
                placeholder="Confirmed"
                name="confirmed"
                styles={mergeStyles(filterStyles)}
                onChange={(option) => handleFilter({confirmed: {eq: option?.value}})}
                options={CONFIRMED_ACCOUNT}
              />
            </FormGroup>
          </Col>
          <Col xs="12" md="3" className="p-0 ml-md-2 mb-sm-2 mb-md-0">
            <FormGroup className="form-input m-0">
              <SelectInput
                isClearable
                placeholder="Trial"
                name="trial"
                styles={mergeStyles(filterStyles)}
                onChange={(option) => handleFilter({hasTrial: {eq: option?.value}})}
                options={TRIAL}
              />
            </FormGroup>
          </Col>
        </Row>
      </form>
    </CardBody>
  )
}

export default Filters;
