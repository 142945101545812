import React from "react";
import styled from "styled-components";

const Caret = ({ isOpen }) => (
  <Caret.CaretElem isOpen={isOpen} />
);

Caret.CaretElem = styled.b`
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin: 10px 5px;
  display: inline-block;
  transform: ${(props) => props.isOpen && "rotate(180deg)"};
`;

export default Caret;
