import { ReactComponent as UserIcon } from "assets/img/icons/user.svg";
import { ReactComponent as ClockIcon } from "assets/img/icons/clock-2.svg";
import { ReactComponent as Facebook } from "assets/img/icons/facebook.svg";
import { ReactComponent as Telegram } from "assets/img/icons/telegram.svg";
import { ReactComponent as Twitter } from "assets/img/icons/twitter-X.svg";
import { ReactComponent as Instagram } from "assets/img/icons/instagram.svg";

export const COLLAPSE_ITEMS = [
  { path: "/deposits", name: "Deposits", mini: "D"},
  { path: "/withdrawal", name: "Withdrawal", mini: "W"},
  { path: "/deposit-withdrawal-history", name: "History", mini: "H"},
  { path: "/transactions", name: "Transactions", mini: "T"},
];

export const SIDEBAR_NAV_ITEMS = {
  'main':[
    { path: "/dashboard", icon: "Dashboard", name: "Dashboard"},
  ],
  'Spot trading':[
    { path: "/deals", icon: "Deals", name: "Deals"},
    { path: "/bots", icon: "Bots", name: "Bots"},
  ],
  'Signal trading':[
    { path: "/channels", icon: "Channels", name: "Channels"},
    { path: "/signals", icon: "Signals", name: "Signals"},
  ],
  'Account': [
    { path: "/credentials", icon: "Credentials", name: "Credentials"},
    { path: "/billing", icon: "Billing", name: "Billing"},
  ]
};

export const USER_NAV_ITEMS = [
  { path: "/user/profile", name: "Profile", icon: <UserIcon width={20} height={20}/>},
  { path: "/login-history", name: "Login history", icon: <ClockIcon width={20} height={20}/>},
];

export const DASHBOARD_SIDEBAR = [
  { href: "https://t.me/darkbot_io", icon: <Telegram/>},
  { href: "https://twitter.com/darkbotio", icon: <Twitter/>},
  { href: "https://www.facebook.com/darkbot.io", icon: <Facebook/>},
  { href: "https://www.instagram.com/darkbot.io", icon: <Instagram/>},
];