import React from 'react';

import ConnectExchange from '../steps/ConnectExchange';
import CreateBot from '../steps/CreateBot';
import ConnectTelegram from '../steps/ConnectTelegram';
import MaximizeProfits from '../steps/MaximizeProfits';

const Steps = ({
  currentStep,
  wizard,
  updateWizard,
  setCurrentStep,
  setSkipped
}) => {
  switch (currentStep) {
    case "credentials":
      return <ConnectExchange />;

    case "bots":
      return <CreateBot />;

    case "telegram":
      return (
        <ConnectTelegram
          wizard={wizard}
          updateWizard={updateWizard}
          setCurrentStep={setCurrentStep}
        />
      );

    case "subscription":
      return <MaximizeProfits setSkipped={setSkipped} />;

    default:
      return null;
  };
};

export default Steps;
