import React from "react";
import styled from "styled-components";

import { ReactComponent as Warning } from "assets/img/icons/Warning.svg";

const LineWithError = ({
  errorMessage,
  radius,
  color,
  className,
  dataTestId="error-line"
}) => {
  if (errorMessage) {
    return (
      <LineWithError.Error
        color={color}
        radius={radius}
        className={className}
        data-testid={dataTestId}
      >
        <span className="d-inline-block">
          <Warning />
        </span>
        <p className="w-100 mb-0 px-3 font-sm font-weight-300">
          {errorMessage}
        </p>
      </LineWithError.Error>
    )
  }
}

LineWithError.Error = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 24px;
  background: rgba(249, 136, 108, 0.2);
  border-radius: ${(props) => props.radius || "0 0 8px 8px"};
  font-weight: 300;

  p {
    color: ${(props) => props.color || "#FFFFFF"}  !important;
  }
  svg {
    path {
      stroke: #FF8D72;
    }
  }
`;

export default LineWithError;
