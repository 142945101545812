export const renderDesc = (desc) => {
  const parts = desc.split(/(<a.*?<\/a>)/);
  return parts.map((part, index) => {
    if (part.startsWith('<a')) {
      const linkStartIndex = part.indexOf('>') + 1;
      const linkEndIndex = part.lastIndexOf('</a>');
      const linkContent = part.substring(linkStartIndex, linkEndIndex);
      const hrefStartIndex = part.indexOf('href="') + 6;
      const hrefEndIndex = part.indexOf('"', hrefStartIndex);
      const href = part.substring(hrefStartIndex, hrefEndIndex);
      const linkKey = `${index}-${href}`;
      return (
        <a 
          key={linkKey} 
          href={href} 
          className="link-blue"
        >
          {linkContent}
        </a>
      );
    }
    return part;
  });
};