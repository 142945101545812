import React from "react";
import styled from "styled-components";
import {
  Card,
  CardBody,
  Col,
  CardHeader
} from "reactstrap";
import _ from "lodash";

import StyledPreloader from "components/StyledPreloader";

const OrderBookPreloader = () => {
  return (
    <Card className="mb-3 mt-3">
      <CardHeader className="d-flex justify-content-between font-weight-500 pt-4 px-4 pb-0">
        <div className="d-flex">
          <StyledPreloader
            circle
            height={24}
            width={24}
            className="mr-2 my-auto"
          />
          <StyledPreloader
            height={16}
            width={80}
            className="mr-2 my-auto"
          />
        </div>
        <div className="d-flex">
          <StyledPreloader
            height={11}
            width={38}
            className="mr-2 my-auto"
          />
          <OrderBookPreloader.SelectBoxPreloader className="mr-5" />
          <StyledPreloader
            height={11}
            width={38}
            className="mr-2 my-auto"
          />
          <OrderBookPreloader.SelectBoxPreloader />
        </div>
      </CardHeader>
      <CardBody className="d-flex flex-column flex-md-row no-gutters">
        <Col xs="12" md="6">
          <div className="d-flex flex-column">
            <OrderBookPreloader.Header className="d-flex justify-content-between mb-2">
              <StyledPreloader
                height={12}
                width={79}
              />
              <StyledPreloader
                height={12}
                width={87}
                className="ml-4 my-auto"
              />
              <StyledPreloader
                height={12}
                width={79}
              />
            </OrderBookPreloader.Header>
            {
              _.times(15, (e) =>
                <OrderBookPreloader.List
                  key={e}
                  className="bids d-flex position-relative justify-content-between"
                >
                  <StyledPreloader
                    height={12}
                    width={100}
                  />
                  <StyledPreloader
                    height={12}
                    width={77}
                  />
                  <StyledPreloader
                    height={12}
                    width={62}
                  />
                  <OrderBookPreloader.ProgressBar
                    type="bids"
                    index={-(e + 1)}
                  />
                </OrderBookPreloader.List>
              )
            }
          </div>
        </Col>
        <Col xs="12" md="6">
          <div className="d-flex flex-column">
            <OrderBookPreloader.Header className="d-flex justify-content-between mb-2">
              <StyledPreloader
                height={12}
                width={79}
              />
              <StyledPreloader
                height={12}
                width={87}
                className="ml-4 my-auto"
              />
              <StyledPreloader
                height={12}
                width={79}
              />
            </OrderBookPreloader.Header>
            {
              _.times(15, (e) =>
                <OrderBookPreloader.List
                  key={e}
                  className="asks d-flex position-relative justify-content-between"
                >
                  <StyledPreloader
                    height={12}
                    width={100}
                  />
                  <StyledPreloader
                    height={12}
                    width={77}
                  />
                  <StyledPreloader
                    height={12}
                    width={62}
                  />
                  <OrderBookPreloader.ProgressBar
                    type="asks"
                    index={e + 1}
                  />
                </OrderBookPreloader.List>
              )
            }
          </div>
        </Col>
      </CardBody>
    </Card>
  );
};

OrderBookPreloader.Header = styled.div`
  padding: 0.3rem 1rem;
`;

OrderBookPreloader.SelectBoxPreloader = styled.div`
  width: 209px;
  height: 38px;
  background: #222435;
  border-radius: 6px;
`;

OrderBookPreloader.List = styled.div`
  padding: 0.3rem 1rem;
  overflow: hidden;
  cursor: pointer;
  line-height: 19px;
  background: #2A2C42;
`;

OrderBookPreloader.ProgressBar = styled.div`
  position: absolute;
  ${
    (props) => props.type === "bids" ?
    "left: 100%;"
    :
    "right: 100%"
  }
  top: 0;
  bottom: 0;
  width: 100%;
  will-change: transform;
  transform: translateX(${(props) => props.index * 5.5}%);
  background-color: #535462;
  opacity: 0.15;
`;

export default OrderBookPreloader;
