import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

import StatusIndicator from "components/StatusIndicator";
import PercentBadge from "components/PercentBadge";
import {
  formatterToNull,
  setColorClass
} from "helpers/valuesFormatters";
import { ReactComponent as BlueTickSquare } from "assets/img/icons/blue-tick-square.svg";

const MyAgreementsHeader = ({
  status,
  acceptedAt,
  isList,
  profit,
  profitPercent,
  quote
}) => (
  <div className="d-flex align-items-center flex-row justify-content-start flex-wrap">
    <BlueTickSquare className="mr-1" />
    <p className="mb-0 font-weight-500 text-white ml-2">
      My agreement
    </p>
    <StatusIndicator status={status} />
    {
      isList
        ? <p className="mt-3 mt-md-0 ml-auto mr-0 text-white-35 font-weight-300 font-sm">
          Accepted manually at {" "}
          <span className="text-white font-weight-500">
            {moment(acceptedAt).format("DD.MM.YYYY HH:mm")}
          </span>
        </p>
        : <>
          <p className="mb-0 text-white-35 ml-auto mr-0">
            Profit {" "}
          <PercentBadge
            value={profitPercent}
            showZeroValue
          />
          </p>
          <span className="text-white-35 font-weight-300 ml-2">
            {quote}
          </span>
          <span className={`${setColorClass(profit)} ml-2 mr-1`}>
            {formatterToNull(profit)}
          </span>
        </>
    }
  </div>
);

MyAgreementsHeader.propTypes = {
  status: PropTypes.string,
  acceptedAt: PropTypes.string
};

export default MyAgreementsHeader;