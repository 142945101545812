import { get } from "lodash";

import {
  CREATE_BACKTEST,
  RESET_BACKTESTING_LIST,
  RESET_BACKTESTING_ITEM,
  DELETE_BACKTEST
} from "./constants";
import createAction from "store/thunkFactory";

export const getBacktesting = (id, page) => createAction("BACKTESTING", (fetch) => {
  return fetch.get(`/app/strategies/${id}/backtests?page=${page}`);
});

export const getBacktest = (id, signal) => createAction("BACKTESTING_ITEM", (fetch) => {
  return fetch.get(`/app/backtests/${id}`, { signal });
});

export const createBacktest = (body) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/app/backtests", body);
    dispatch({ type: CREATE_BACKTEST, payload: res.data.item });
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const deleteBacktest = (id) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.delete(`/app/backtests/${id}`);
    dispatch({ type: DELETE_BACKTEST, payload: id });
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const resetBacktest = () => ({ type: RESET_BACKTESTING_ITEM });

export const resetBacktestingList = () => ({ type: RESET_BACKTESTING_LIST });
