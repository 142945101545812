import React, { useMemo } from "react";
import { v1 as uuid } from "uuid";

import {
  StyledHeader,
  StyledList,
  StyledProgressBar
} from "./StyledOrderBook";

const OrderBook = ({
  orders,
  type="bids",
  base,
  quote,
  withSum,
  width
}) => {
  const maxTotal = useMemo(() => Math.max(...orders.map(o => o.total)), [orders]);

  const calcTotalPercent = (total) => {
    const percent = (total * 100) / maxTotal;
    const mutiplier = type === "bids" || width <= 768 ? -1 : 1;
    return percent.toFixed(5) * mutiplier;
  };

  return (
    <div className="d-flex flex-column">
      <StyledHeader
        className="font-sm"
        type={type}
        withSum={withSum}
      >
        <div className="text-white-35 text-nowrap pr-1">
          {`Price (${quote})`}
        </div>
        <div className="text-center text-nowrap text-white-35 pl-1">
          {`Amount (${base})`}
        </div>
        <div className="text-center text-nowrap text-white-35 pl-1">
          {`Total (${quote})`}
        </div>
        <div className="sum text-right text-nowrap text-white-35 pl-1">
          {`Sum (${quote})`}
        </div>
      </StyledHeader>
      {
        orders.map((order) =>
          <StyledList
            key={uuid()}
            className={`position-relative ${type === "bids" ? " bids" : " asks"}`}
            withSum={withSum}
          >
            <div className={`text-nowrap price-${type} pr-1`}>
              {order.price}
            </div>
            <div className="text-center text-nowrap pl-1">
              {order.quantity}
            </div>
            <div className="text-center text-nowrap pl-1">
              {order.total.toFixed(8)}
            </div>
            <div className="sum-cell text-right text-nowrap pl-1">
              {order.sum.toFixed(8)}
            </div>
            <StyledProgressBar
              type={type}
              percent={calcTotalPercent(order.total)}
            />
          </StyledList>
        )
      }
    </div>
  );
};

export default React.memo(OrderBook);
