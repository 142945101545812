import React from "react";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";

const RequestLogTable = ({ data }) => (
  <Table>
    <thead>
      <tr>
        <th>uuid</th>
        <th>proxy</th>
        <th>url</th>
        <th>params</th>
        <th>created at</th>
      </tr>
    </thead>
    <tbody>
      {data && data.map((item) =>
        <tr key={item.uuid}>
          <td className="text-uppercase">{item.uuid}</td>
          <td className="text-uppercase">{item.proxy}</td>
          <td>
            <Link to={`/credentials/${item.uuid}/${item.url}`}>
              {item.url}
            </Link>
          </td>
          <td>{item.params}</td>
          <td>{item.createdAt}</td>
        </tr>
      )}
    </tbody>
  </Table>
);

export default RequestLogTable;
