import React, {useEffect, useState} from "react";
import {useFormikContext} from "formik";
import styled from "styled-components";
import {Tooltip} from "reactstrap";
import {useSelector} from "react-redux";

import SortTableHead from "components/SortTableHead";
import StyledTable from "components/StyledTable";
import PercentBadge from "components/PercentBadge";
import CustomScrollbars from "components/CustomScrollbars";
import EmptyState from "components/EmptyState";
import {MARTINGALE_CALCULATION_HEAD} from "models/bot/wizard/enum/table";
import {martingaleCalculate} from "./martingaleCalculate";
import {formatterToNull, toFixedToNumber} from "helpers/valuesFormatters";
import {ReactComponent as Danger} from "assets/img/icons/danger.svg";
import MartingaleHeader from "./Martingale/MartingaleHeader";

const MartingaleTable = () => {
  const [inputMarket, setInputMarket] = useState();
  const [priceMarket, setPriceMarket] = useState(1);
  const [items, setItems] = useState(1);
  const [tooltip, setTooltip] = useState(false);

  const {coins, allCoins, excludedCoins, investment} = useSelector((state) => state.botWizard);
  const availableItems = useSelector((state) => state.availableMarkets.data.items);

  const {values} = useFormikContext();

  const toggle = () => setTooltip(!tooltip);

  useEffect(() => {
    const price = availableItems?.filter((item) => item.market.base === inputMarket);
    if (price?.length) return setPriceMarket(price[0]?.close);

    if (allCoins) {
      const filteredCoin = availableItems?.filter((item) => excludedCoins.every(coin => item.market.base !== coin));
      setInputMarket(filteredCoin?.[0]?.market.base)
      return setPriceMarket(filteredCoin?.[0]?.close);
    }

    if (!allCoins) {
      const filteredCoin = availableItems?.filter((item) => item.market.base === coins[0])
      return setPriceMarket(filteredCoin[0]?.close)
    }
  }, [availableItems, inputMarket, allCoins, coins, excludedCoins]);

  useEffect(() => {
    calculate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceMarket])

  const calculate = () => {
    const result = martingaleCalculate(
      investment?.balance?.available.total > 0 ? +investment?.balance?.available.total : 0,
      +values.volume,
      +values.stopLossPercent,
      +values.martingalePercent,
      +values.deviation,
      +values.maxTradeRequests,
      priceMarket > 0 ? +priceMarket : 1
    );
    setItems(result);
  }

  return (
    <>
      <MartingaleHeader
        coins={coins}
        allCoins={allCoins}
        excludedCoins={excludedCoins}
        availableItems={availableItems}
        setInputMarket={setInputMarket}
        calculate={calculate}
        total={investment?.balance?.available.total}
      />
      {
        +investment?.balance?.available?.total ?
          items?.length &&
          <CustomScrollbars
            autoHideTimeout={500}
            autoHideDuration={500}
            autoHeight
            autoHeightMax={400}
            style={{"borderRadius": "0 0 10px 10px"}}
          >
            <StyledTable className="mb-0" paddingsThead="1rem">
              <SortTableHead
                theadItems={MARTINGALE_CALCULATION_HEAD}
              />
              <tbody className="font-sm">
              {
                items?.filter((item) => item.totalInvestedPercent <= 100)?.map((item) => {
                  return (
                    <MartingaleTable.TableRow
                      key={item.tr}
                      className={values.stopLossPercent && -values.stopLossPercent > item.result() ? "hide" : ""}
                    >
                      <td>
                        <div className="d-flex align-items-center">
                          <p className="mb-0 mr-2 font-weight-500 text-white">{item.tr + 1}</p>
                          {
                            values.stopLossPercent && -values.stopLossPercent > item.result() ?
                              <div>
                                <Danger className="cursor-pointer" id="danger"/>
                                <Tooltip target="danger" placement="top" isOpen={tooltip} toggle={toggle}>
                                  This trade request will not be processed because the stop loss percentage will be reached
                                </Tooltip>
                              </div> : null
                          }
                        </div>
                      </td>

                      <td>
                        <p className="mb-0 font-weight-500 text-white">{toFixedToNumber(+item.price)}</p>
                      </td>

                      <td>
                        <div className="d-flex align-items-center">
                          <p className="mb-0 font-weight-500 text-white">{formatterToNull(item.invested).toFixed(5)}</p>
                          <p className="mb-0 ml-1 font-weight-300 text-white-35">{investment.asset}</p>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center">
                          <p className="mb-0 font-weight-500 text-white">
                            {formatterToNull(item.purchased).toFixed(7)}
                          </p>
                          <p className="mb-0 ml-1 mr-2 font-weight-300 text-white-35">
                            {inputMarket || coins[0]}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center">
                          <p className="mb-0 font-weight-500 text-white">
                            {formatterToNull(item.totalInvested).toFixed(5)}
                          </p>
                          <p className="mb-0 ml-1 mr-2 font-weight-300 text-white-35">{investment.asset}</p>
                          <PercentBadge value={item.totalInvestedPercent || 0} isColorSpectrum showZeroValue/>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center">
                          <p className="mb-0 font-weight-500 text-white">
                            {formatterToNull(item.estimated).toFixed(7)}
                          </p>
                          <p className="mb-0 ml-1 mr-2 font-weight-300 text-white-35">
                            {investment.asset}
                          </p>
                          <PercentBadge
                            value={item.estimatedPercent || 0}
                            showZeroValue
                          />
                        </div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center">
                          <p className="mb-0 font-weight-500 text-white">
                            {formatterToNull(item.totalPurchased).toFixed(7)}
                          </p>
                          <p className="mb-0 ml-1 mr-2 font-weight-300 text-white-35">
                            {inputMarket || coins[0]}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center text-nowrap">
                          <p className="mb-0 font-weight-500 text-white">
                            {isNaN(item.result()) ? "0.00" : Number(item.result()).toFixed(2)}
                          </p>
                          <p className="mb-0 ml-1 mr-2 font-weight-300 text-white-35">%</p>
                        </div>
                      </td>
                    </MartingaleTable.TableRow>
                  )
                })
              }
              </tbody>
            </StyledTable>
          </CustomScrollbars>
          :
          <EmptyState
            title="Oops, selected coin don`t have enough balance"
            desc="Looks like your selected coin has insufficient balance.
              Please make a deposit or choose a different coin to calculate martingale."
          />
      }
    </>
  )
}

MartingaleTable.TableRow = styled.tr`
  &.hide {
    p, span {
      opacity: 0.5;
    }
  }
`;

export default MartingaleTable;
