import React from "react";
import { ReactComponent as Empty } from "assets/img/empty-state-planet2.svg";

const EmptyTableOrderModal = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center p-5">
      <Empty />
      <p className="mb-0 mt-4 text-white h4">You have no trades of this order</p>
    </div>
  )
}

export default EmptyTableOrderModal;
