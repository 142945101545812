import React, { useRef } from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import { Card } from "reactstrap";
import PropTypes from "prop-types";
import qs from "qs";

import Error from "components/Error";
import PaginationPanel from "components/PaginationPanel";
import SignalsTableHeader from "../reusable/SignalsTableHeader";
import SignalsBody from "../reusable/SignalsBody";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import { getSignalsCompleted } from "store/signals/action";
import { COMPLETED_SIGNALS_HEAD } from "models/signal/tables";
import {
  getErrorCompleted,
  getLoadingCompleted,
  signalsCompleted,
} from "store/signals/selectors";
import useRequestCancellation from "hooks/useRequestCancellation";

const CompletedSignalsTab = ({
  loadingPage,
  id,
  owner,
  subscribed
}) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef();

  const { items, page, total, request } = useSelector(signalsCompleted);
  const loading = useSelector(getLoadingCompleted);
  const error = useSelector(getErrorCompleted);
  const { handleSorting, handlePaginate } = useFiltersWithRequest(request, getSignalsCompleted);
  const { useAbortableEffect } = useRequestCancellation();

  useAbortableEffect((signal) => {
    if (id) {
      const queryString = qs.stringify({
        filter: {
          status: {
            eq: "completed"
          },
          channel: {
            eq: id
          }
        },
        limit: 4,
      });
      dispatch(getSignalsCompleted(`?${queryString}`, signal))
    }
  }, [dispatch, id, subscribed]);

  if (error) return <Error error={error} />

  return (
    <Card className="pt-4">
      <div ref={wrapperRef}>
        <SignalsTableHeader
          loadingPage={loadingPage}
          loading={loading}
          title="Completed Signals" />
        <SignalsBody
          loadingPage={loadingPage}
          loading={loading}
          items={items}
          owner={owner}
          sort={request?.sort}
          handleSorting={handleSorting}
          theadItems={COMPLETED_SIGNALS_HEAD}
          subscribed={subscribed}
        />

        {
          !loading
          && <div className={`${total < 5 ? "mt-0" : "mt-3"}`}>
            <PaginationPanel
              totalPagesView={4}
              activePage={page}
              totalItemsCount={total}
              onChange={(page) => handlePaginate(page)}
              elementRef={wrapperRef}
            />
          </div>
        }
      </div>
    </Card>
  );
};

CompletedSignalsTab.propTypes = {
  loadingPage: PropTypes.bool,
  id: PropTypes.string
}

export default CompletedSignalsTab;