import React from "react";
import { CardHeader } from "reactstrap";
import PropTypes from "prop-types";

import SkeletonPreloader from "components/SkeletonPreloader";
import { ReactComponent as Rocket } from "assets/img/icons/rocket-outline.svg";

const SignalTableHeader = ({ loading }) => (
  <CardHeader className="p-0 px-4 d-flex justify-content-between mb-3">
    {loading ? (
      <SkeletonPreloader
        height={20}
        width={20}
      />
    ) : (
      <div className="d-flex align-items-center p-0">
        <Rocket />
        <p className="m-0 ml-2 text-white font-weight-500">
          Signal progress
        </p>
      </div>
    )}
  </CardHeader>
);

SignalTableHeader.propTypes = {
  loading: PropTypes.bool,
};

export default SignalTableHeader;
