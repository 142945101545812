import React from "react";
import styled from "styled-components";
import {
  Card,
  CardBody,
  Row,
  Col
} from "reactstrap";

import SelectInput from "components/SelectInput";
import ExchangesSelect from "components/ExchangesSelect";
import { useSelector } from "react-redux";
import { selectAgreementsMarkets } from "store/agreements/selectors";

const Filters = ({
  loadingPage,
  filter,
  handleFilter,
  dataMapping
}) => {
  const { data, loading } = useSelector(selectAgreementsMarkets);

  const getMarketValue = () => {
    const selectedMarket = data?.length && data?.filter((item) => {
      const signalMarket = filter?.["signal.market"]?.in;
      return (
        signalMarket?.length && signalMarket?.some((m) => m === item?.market?.pair));
    })
    return selectedMarket?.length ? selectedMarket?.map((market) => ({
      value: market?.market?.pair,
      label: market?.market?.pair?.replace("-", " / "),
      icon: {
        type: "cryptocurrency",
        size: "selectSize",
        code: market?.market?.base,
      },
    })) : [];
  };
  return (
    <Filters.Wrapper>
      <CardBody className="px-4 pb-4 pt-0">
        <Row className="d-flex align-items-center p-0 m-0">
          <Col
            lg={3}
            className="p-0 mb-2 mb-lg-0"
          >
            <SelectInput
              isClearable
              isDisabled={loadingPage}
              placeholder="Status agreement"
              value={
                dataMapping?.filters?.status?.find((option) => option.value === filter?.status?.eq)
              }
              name="status"
              onChange={(option) => {
                handleFilter({
                  status: {
                    eq: option?.value
                  }
                });
              }}
              options={dataMapping?.filters?.status}
            />
          </Col>
          <Col
            lg={3}
            className="p-0 mb-2 mb-lg-0 mx-0 mx-lg-3"
          >
            <ExchangesSelect
              isClearable
              isDisabled={loadingPage}
              placeholder="Exchange"
              name="exchange"
              value={filter?.["signal.exchange"]?.in}
              onChange={(option) => {
                handleFilter({
                  "signal.exchange": { in: [option?.value] }
                });
              }}
              width="100%"
            />
          </Col>
          <Col
            lg={3}
            className="p-0 mb-2 mb-lg-0"
          >
            <SelectInput
              isClearable
              placeholder="Market"
              isMulti
              isDisabled={loading || loadingPage}
              value={getMarketValue()}
              onChange={(values) => {
                handleFilter({
                  "signal.market": {in: values ? values.map((e) => e.value) : ""}
                });
              }}
              options={data?.length && data?.map((market) => {
                return {
                  value: market?.market?.pair,
                  label: market?.market?.pair?.replace("-", " / "),
                  icon: {
                    type: "cryptocurrency",
                    size: "selectSize",
                    code: market?.market?.base,
                  },
                };
              })
              }
            />
          </Col>
        </Row>
      </CardBody>
    </Filters.Wrapper>
  );
};

Filters.Wrapper = styled(Card)`
  border-radius: 8px !important;
  margin-bottom: 0px !important;
  .react-select__value-container {
    padding-left: 11px;
  }
`;

export default Filters;
