import React from "react";
import { Modal } from "reactstrap";
import styled from "styled-components";

const IndicatorsModalWrapper = ({
  children,
  onSetModalIsOpen,
  modalIsOpen,
}) => {

  return(
    <IndicatorsModalWrapper.Modal
      isOpen={modalIsOpen}
      toggle={() => onSetModalIsOpen(!modalIsOpen)}
      modalClassName="modal-black"
      className="indicators-modal"
    >
      <IndicatorsModalWrapper.Wrapper className="d-flex flex-row">
        {children}
      </IndicatorsModalWrapper.Wrapper>
    </IndicatorsModalWrapper.Modal>
  );
};

IndicatorsModalWrapper.Modal = styled(Modal)`
  max-width: 100vw !important;
  margin: 1rem 2rem !important;
  @media (max-width: 1200px) {
    margin: 0 auto !important;
  }
  `;

IndicatorsModalWrapper.Wrapper = styled.div`
  border-radius: 8px;

  h3 {
    font-size: 18px;
  }
`

export default IndicatorsModalWrapper;
