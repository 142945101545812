import React, {
  useState,
  useEffect
} from "react";
import {
  useSelector,
  useDispatch
} from "react-redux";
import { 
  Formik, 
  Form
} from "formik";

import Button from "components/Button";
import StepHeader from "./StepHeader";
import WizardErrors from "../Wizard/WizardErrors";
import { wizardIndicatorsSelector } from "store/strategies/selectors";
import { resetWizardIndicators } from "store/strategies/actions";
import { handleResponse } from "helpers/errorsValidator";
import useCalculatedSignals from "hooks/useCalculatedISignals";
import { addSnackBar } from "store/snackbar/actions";
import {
  handleBotSchema,
  handleInitCreateValues,
} from "../../EditBot/editBotConfiguration";
import RenderBotTab from "./RenderBotTab";
import { selectCurrentPlan } from "store/user/selectors";
import { createBot } from "store/bots/actions";
import SelectBotType from "./SelectBotType";

const Configuration = ({
  goBack,
  setIsSubmit,
  currentStep,
  loadingWizard,
  botData
}) => {
  const [currentTab, setCurrentTab] = useState('genetic');
  const [submitted, setSubmitted] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [redirectPath, setRedirectPath] = useState("/");

  const initialValues = handleInitCreateValues(null, currentTab)?.[currentTab];
  const wizardMarkets = useSelector((state) => state.botWizard);
  const indicators = useSelector((state) => wizardIndicatorsSelector(state));
  const currentPlan = useSelector(selectCurrentPlan);
  const dispatch = useDispatch();

  const filteredIndicators = indicators.map((indicator) => ({
    code: indicator.code,
    type: indicator.type,
    settings: indicator.settings,
    required: indicator.required
  }));

  const { buySignals, sellSignals, setBuySignals, setSellSignals } = useCalculatedSignals(
    botData,
    filteredIndicators
  );

  const handleSubmit = async (values, formik) => {
    const customResErrors = (res) => {
      formik.setSubmitting(false);
      handleResponse(res, formik.setErrors);
      setIsSubmit(false);
      dispatch(addSnackBar("errors", res.message))
    };

    const initBody = {
      ...wizardMarkets,
      type: currentTab,
      minimumTrades: Number(wizardMarkets.minimumTrades) || null,
      volume: Number(wizardMarkets.volume) || null,
      spreadPercent: Number(wizardMarkets.spreadPercent) || null,
      markPriceDifference: Number(wizardMarkets.markPriceDifference) || null,
      investment: wizardMarkets.investment.id,
      strategy: values?.strategy
    };
    setIsSubmit(true);
    formik.setSubmitting(true);

    const body = {
      ...initBody,
      ...(currentTab === "custom"
        ? {
          tradeSettings: {
            ...values,
            indicators: filteredIndicators,
          }
        }
        : null)
    };

    const res = await dispatch(createBot(body));

    if (res.errors) {
      customResErrors(res);
    } else {
      setAlertOpen(true);
      setRedirectPath(`/bots/${res.item.id}`);
      formik.setStatus({ message: res.message });
    }
    resetWizardIndicators();
    formik.setSubmitting(false);
  };

  useEffect(() => {
    if (currentStep !== 2) {
      indicators.length = 0
      setSubmitted(false)
      setSellSignals(0)
      setBuySignals(0)
    }
  }, [currentStep, indicators, setBuySignals, setSellSignals]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={handleBotSchema(currentTab, buySignals, sellSignals)}
      onSubmit={handleSubmit}
    >
      {({
        isSubmitting,
        status,
        setErrors,
      }) => (
        <Form>
          <StepHeader />
          <div className="p-sm">
            <SelectBotType 
              setBotType={setCurrentTab}
              botType={currentTab}
            />
            <RenderBotTab
              currentStep={currentTab}
              redirectPath={redirectPath}
              buySignals={buySignals}
              sellSignals={sellSignals}
              botData={botData}
              loadingWizard={loadingWizard}
              submitted={submitted}
              setSubmitted={setSubmitted}
              alertOpen={alertOpen}
              onSetAlertOpen={setAlertOpen}
              type={currentTab}
              currentPlan={currentPlan}
            />
          </div>
          <div className="d-flex flex-column flex-sm-row justify-content-between p-4">
            <Button
              color="gray"
              onClick={goBack}
              outline
              type="button"
            >
              Back - Market
            </Button>
            <Button
              color="blue"
              className="mt-2 mt-sm-0"
              disabled={currentPlan?.type !== "premium" && currentTab === "genetic"}
              loading={isSubmitting}
              onClick={() => setSubmitted(true)}
              type="submit"
            >
              Finish bot creation!
            </Button>
          </div>
          <WizardErrors
            status={status}
            alertOpen={alertOpen}
            onSetAlertOpen={setAlertOpen}
            redirectPath={redirectPath}
            setErrors={setErrors}
          />
        </Form>
      )}
    </Formik>
  );
};

export default Configuration;