import React from "react";
import { CardBody } from "reactstrap";
import PropTypes from "prop-types";

import { formatterToNull } from "helpers/valuesFormatters";

const SignalWidgetBody = ({
  openFrom,
  openTo,
  stopLoss
}) => (
  <CardBody className="p-0 mt-3">
    <div className="d-flex justify-content-between align-items-center">
      <p className="font-sm font-weight-400 text-white-75 mb-0">
        Open From
      </p>
      <p className="mb-0 font-sm font-weight-500 text-white">
        {formatterToNull(openFrom)}
      </p>
    </div>
    <div className="d-flex justify-content-between align-items-center my-1">
      <p className="font-sm font-weight-400 text-white-75 mb-0">
        Open To
      </p>
      <p className="mb-0 font-sm font-weight-500 text-white">
        {formatterToNull(openTo)}
      </p>
    </div>
    <div className="d-flex justify-content-between align-items-center">
      <p className="font-sm font-weight-400 text-white-75 mb-0">
        Stop Loss
      </p>
      <p className="mb-0 font-sm font-weight-500 text-white">
        {formatterToNull(stopLoss)}
      </p>
    </div>
  </CardBody>
);

SignalWidgetBody.propTypes = {
  openFrom: PropTypes.number,
  openTo: PropTypes.number,
  stopLoss: PropTypes.number,
};

export default SignalWidgetBody;