import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { capitalize } from "lodash";
import { ReactComponent as Ellipse } from "assets/img/icons/ellipse.svg";
import { ReactComponent as Clock } from "assets/img/icons/clock.svg";
import Icon from "components/Icon";
import AllocationCommentsTooltip from "../../charts/AllocationCommentsTooltip";

const AllocationRow = ({asset, handleMouseEnter, handleMouseLeave, hoverCurrentBg, index, backgroundColors}) => {

  const renderAssetAmount = (asset) => {
    return (
      <AllocationRow.Amounts
        className="d-flex flex-column align-items-end align-items-md-start align-items-lg-end mt-md-2 mt-lg-0"
        colors={backgroundColors[asset.credentials.exchange]}
      >
        <div className="d-flex align-items-center">
          <p className="mb-0 text-white font-sm font-weight-500">
            {asset.balance.btc || "0.00000000"}
          </p>
          <p className="mb-0 text-white-35 font-sm font-weight-300 ml-1">
            BTC
          </p>
        </div>
        <p className="text-white-35 mb-0 font-sm font-weight-300">
          {`${asset.estimated.amount || "0.00"} ${asset.estimated.currency}`}
        </p>
      </AllocationRow.Amounts>
    )
  }

  return  (
    <Link to={`/credentials/${asset.credentials.id}`} key={asset.credentials.id}>
      <AllocationRow.AssetItem
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={handleMouseLeave}
        className="d-flex flex-row flex-md-column flex-lg-row justify-content-between py-2 pl-sm-3"
        hoverCurrentBg={hoverCurrentBg}
        index={index}
      >
        <div className="d-flex align-items-center w-50">
          <div className="d-flex align-items-center position-relative">
            <Icon
              type="exchanges"
              size="thumb"
              className="my-auto"
              code={asset.credentials.exchange}
            />
            <AllocationRow.IconWrapper
              className="d-flex justify-content-center align-items-center borderStatus"
              connected={asset.credentials.connected}
            >
              {
                asset.credentials.connected ?
                  <Ellipse/>
                  :
                  <Clock/>
              }
            </AllocationRow.IconWrapper>
          </div>
          <div className="d-flex ml-2 w-100">
            <p className="text-white font-weight-500 font-sm mb-0">
              {capitalize(asset.credentials.exchange)}
            </p>
            <p className="font-sm text-truncate ml-2 d-block d-lg-none">
              {asset.credentials.comment}
            </p>
            <div className="d-none d-lg-flex ml-2 font-sm">
              {
                asset?.credentials?.comment &&
                <AllocationCommentsTooltip index={index} comments={asset.credentials.comment}/>
              }
            </div>
          </div>
        </div>
        {renderAssetAmount(asset)}
      </AllocationRow.AssetItem>
    </Link>
  )
}

AllocationRow.AssetItem = styled.div`
  cursor: pointer;
  color: #FFFFFF;
  border-radius: 6px;
  margin: 0 8px;
  background: ${(props) => props.hoverCurrentBg === props.index ? "#2E3148" : "none"};
  &:hover {
    background: #2E3148;
    .borderStatus {
      border: 2px solid #2E3148;
      background: #2E3148;
    }
  }
`;

AllocationRow.IconWrapper = styled.div`
  position: absolute;
  top: 60%;
  left: 60%;
  background: var(--dark-slate);
  border: 2px solid var(--dark-slate);
  border-radius: 100%;
  & svg {
    height: 9px;
    width: 9px;
    path {
      stroke: ${(props) => props.connected ? "inherit" : "#FFFFFF" };
    }
  }
`;

AllocationRow.Amounts = styled.div`
  @media (max-width: 576px) {
    padding-right: 4px !important;
    margin-right: 0 !important;
  }

  @media (min-width: 768px) and (max-width: 992px) {

    border-right: none !important;
  }

  margin-right: 16px;
  padding-right: 12px;
  text-align: end;
  border-right: ${(props) => `2px solid ${props.colors}` }
`;

export default AllocationRow;
