import React, { useEffect } from "react";
import { 
  useDispatch,
  useSelector
} from "react-redux";

import StyledTable from "components/StyledTable";
import CustomScrollbars from "components/CustomScrollbars";
import SupportContactNotification from "components/SupportContactNotification";
import TemplateConfigurationPreloader from "./TemplateConfigurationPreloader";
import TemplateItem from "./TemplateItem";
import { getStrategies } from "store/bots/actions";
import { selectStrategies } from "store/bots/selectors";

const TemplateConfiguration = () => {
  const dispatch = useDispatch();
  const { 
    data,
    loading,
    error
  } = useSelector(selectStrategies);

  useEffect(() => {
      dispatch(getStrategies());
      /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  if (loading) return <TemplateConfigurationPreloader />;
  if (error || (!loading && !data?.items?.length)) return (
    <SupportContactNotification
      infoMessage="System error detected. Please contact support via email:"
      linkText="hello@darkbot.io"
      redirectTo="mailto:hello@darkbot.io"
    />
  );

  return (
    <>
      <p className="h1">
        {"Choose your Bot's template"}
      </p>
      <CustomScrollbars
        autoHideTimeout={500}
        autoHideDuration={500}
        autoHeight
        autoHeightMax={Number.MAX_VALUE}
      >
        <StyledTable>
          <thead>
            <tr>
              <th>
                <span className="text-white-35">
                  Bot name
                </span>
              </th>
              <th>
                <span className="text-white-35">
                  Description
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {
              data?.items?.map((strategy) =>
                <TemplateItem
                  key={strategy.id}
                  strategy={strategy}
                />
              )
            }
          </tbody>
        </StyledTable>
      </CustomScrollbars>
    </>
  );
};

export default TemplateConfiguration;
