import React from "react";

import LineWithInform from "components/LineWithInform";
import TemplateConfiguration from "./TemplateConfiguration";
import TradeSettings from "./TradeSettings";

const RenderBotTab = ({
  type,
  redirectPath,
  buySignals,
  sellSignals,
  botData,
  currentStep,
  loadingWizard,
  submitted,
  setSubmitted,
  alertOpen,
  setAlertOpen,
  isEdit,
  botIndicators,
  currentPlan
}) => {
  const renderTab = (type) => {
    switch (type) {
      case "genetic":
        return <>
          <LineWithInform
            infoMessage={`Unlock automated crypto trading with our AI bot. Powered by cutting-edge technology, it analyzes market trends and executes trades on your behalf.
            Seamlessly integrated with user-friendly controls, it's your shortcut to optimized crypto investments.`}
          />
          <LineWithInform
            infoMessage="We're excited to offer you the amazing AI bot configuration feature. However, to unlock its full 
            potential and take advantage of all its capabilities, we recommend upgrading to our premium subscription"
            currentPlan={currentPlan}
            linkText="Upgrade to premium"
            redirectTo="/billing"
            color="gold"
          />
        </>

      case "template":
        return <TemplateConfiguration />;

      case "custom":
        return (
          <TradeSettings
            isEdit={isEdit}
            redirectPath={redirectPath}
            buySignals={buySignals}
            sellSignals={sellSignals}
            botData={botData}
            currentStep={currentStep}
            loadingWizard={loadingWizard}
            submitted={submitted}
            setSubmitted={setSubmitted}
            alertOpen={alertOpen}
            onSetAlertOpen={setAlertOpen}
            botIndicators={botIndicators}
          />
        );

      default:
        return null;
    };
  };
  return renderTab(type);
};

export default RenderBotTab;