import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Card,
  CardBody
} from "reactstrap";
import { useHistory } from "react-router-dom";

import CustomScrollbars from "components/CustomScrollbars";
import LoginHistoryTable from "./compounds/LoginHistoryTable";
import {
  fetchLoginHistory,
  resetLoginHistory
} from "store/loginHistory/actions";
import PaginationPanel from "components/PaginationPanel";
import queryString from "query-string";
import LoginHistoryPreLoad from "./preloaders/LoginHistoryPreLoad";
import useRequestCancellation from "hooks/useRequestCancellation";

const LoginHistory = ({
  onFetchLoginHistory,
  loginHistoryData: {
    items,
    total,
    page
  },
  location,
  loading,
  resetLoginHistory
}) => {
  const history = useHistory();
  const { useAbortableEffect } = useRequestCancellation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto"
    });
  }, [page])

  useAbortableEffect((signal) => {
    const search = queryString.parse(location.search);
    if (search.page) {
      onFetchLoginHistory(search.page, signal);
    } else {
      onFetchLoginHistory(1, signal);
    }
    return () => resetLoginHistory();
  }, [location.search, resetLoginHistory, onFetchLoginHistory]);

  const handlePaginate = (page) => {
     history.push({
       pathname: "/login-history",
       search: `?page=${page}`
     });
   };

  useEffect(() => {
    window.analytics.page("/login-history");
  }, []);

  return (
    <>
      <h3 className="text-white font-weight-500 mb-4 mt-4 mt-md-0">
        Login history
      </h3>
      {
        loading ?
        <LoginHistoryPreLoad />
        :
        <Card className="mb-3 overflow-hidden" style={{borderRadius: "8px"}}>
          <CardBody className="p-0">
            <CustomScrollbars
              autoHideTimeout={500}
              autoHideDuration={500}
              autoHeight
              autoHeightMax={Number.MAX_VALUE}
            >
              <LoginHistoryTable data={items} />
            </CustomScrollbars>
          </CardBody>
        </Card>
      }
      <PaginationPanel
        activePage={page}
        totalItemsCount={total}
        onChange={(page) => handlePaginate(page)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  loginHistoryData: state.loginHistory.data,
  loading: state.loginHistory.loading,
  error: state.loginHistory.error
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onFetchLoginHistory: fetchLoginHistory,
  resetLoginHistory
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginHistory);
