import React, {
  useEffect, 
  useState
} from "react";
import {
  Form, 
  useFormikContext
} from "formik";
import styled from "styled-components";

import Indicators from "../../../strategies/wizard/Indicators";
import ErrorAlert from "components/ErrorAlert";
import TradeSettingsFilters from "./TradeSettingsFilters";
import Martingale from "./Martingale";
import PivotPoints from "./PivotPoints";

const TradeSettingsForm = ({
    submitted,
    botIndicators,
    botData,
    isEdit,
    currentStep,
    sellSignals,
    buySignals,
    botType,
    setSubmitted,
  }) => {

  const [errorsLength, setErrorsLength] = useState(0);

  const { values, errors, setErrors } = useFormikContext();

  useEffect(() => {
    if (!values?.martingale) {
      delete errors?.maxTradeRequests
      delete errors?.martingalePercent
      delete errors?.deviation
    }
    setErrorsLength(Object.keys(errors).length);
  }, [values, errors, setErrorsLength]);

  useEffect(() => {
    if (currentStep !== "custom" && !isEdit) {
      setErrorsLength(0);
      Object.keys(errors).forEach((item) => delete errors[item]);
    }
  }, [currentStep, isEdit, setErrorsLength, errors]);

  useEffect(() => {
    setSubmitted(false);
  }, [botType, setSubmitted]);

  return (
    <Form>
      <TradeSettingsForm.Wrapper errors={errors}>

        <TradeSettingsFilters
          botData={botData}
          submitted={submitted}
        />

        <Martingale
          submitted={submitted}
          botData={botData}
          currentStep={currentStep}
        />

        <PivotPoints
          submitted={submitted}
          botData={botData}
        />

        <Indicators
          botIndicators={botIndicators}
          isEdit={isEdit}
          submitted={submitted}
          buySignals={buySignals}
          sellSignals={sellSignals}
        />

        <div className="d-flex justify-content-center px-3">
          <ErrorAlert
            className="my-3"
            toggle={() => setErrors({})}
            isOpen={submitted && !!errorsLength}
          >
            {errors?.message || "Error. Please check if you have filled up all fields correctly and try again"}
          </ErrorAlert>
        </div>
      </TradeSettingsForm.Wrapper>
    </Form>
  );
};

TradeSettingsForm.Wrapper = styled.div`
  @media (max-width: 992px) {
    padding: 0 1rem;
  }

  .out-of {
    background: ${({errors}) => errors ? 'rgba(108, 117, 125, 0.04)' : '#2E3148'} !important;
    border-radius: 0 6px 6px 0;
  }

  .form-error {
    color: var(--danger);
    margin-bottom: 12px !important;
  }
`;

export default TradeSettingsForm;
