import React, { useState } from "react";

import chatbot from "assets/img/Chatbot.svg";
import Button from "components/Button";
import TelegramModal from "components/TelegramModal";
import Loading from "components/Loading";

const ConnectTelegram = ({ wizard, updateWizard, setCurrentStep }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSkipTelegram = async(e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await updateWizard({ ...wizard, telegram: true });
      setCurrentStep("subscription");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="d-flex flex-column flex-md-row justify-content-between">
        <div className="d-flex flex-column justify-content-center">
          <p className="h1 mb-2 text-white text-center text-md-left">
            Keep yourself updated
          </p>
          <p className="font-sm text-white-75 mb-4 text-center text-md-left">
            Begin your crypto trading journey by seamlessly connecting your<br className="d-none d-md-inline" />
            Telegram account, ensuring quick and convenient access to your<br className="d-none d-md-inline" />
            trading bots and portfolio management tools.
          </p>
          <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-start mb-4 mb-md-0">
            <Button
              color="blue"
              onClick={() => setIsModalOpen(true)}
              className="mb-3 mb-md-0"
            >
              Connect my Telegram
            </Button>
            <a
              className="d-flex ml-md-4 my-auto text-info font-sm text-center"
              href="/#"
              onClick={handleSkipTelegram}
            >
              Skip this step
              {
                isLoading ?
                <Loading
                  size={14}
                  className="ml-2"
                  color="#2f80ed"
                />
                : null
              }
            </a>
          </div>
        </div>
        <img
          src={chatbot}
          alt="Bot"
        />
      </div>
      <TelegramModal
        onSetModalIsOpen={setIsModalOpen}
        modalIsOpen={isModalOpen}
        updateWizard={updateWizard}
        wizardState={wizard}
      />
    </>
  );
};

export default ConnectTelegram;
