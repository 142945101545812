import React from "react";

import OrdersTable from "./OrdersTable";
import TradesTable from "./TradesTable";

const TradeRequestsDetailsWrapper = ({
  title, 
  ordersRequests, 
  tradesRequests, 
  ordersRequestsLoading, 
  tradesRequestsLoading, 
  handleSort
}) => {
  if (title === "orders") {
    return (
      <OrdersTable
        data={ordersRequests}
        handleSort={handleSort}
        sort={ordersRequests?.request?.sort}
        ordersRequestsLoading={ordersRequestsLoading}
      />
    )
  }

  return (
    <TradesTable
      data={tradesRequests}
      handleSort={handleSort}
      sort={tradesRequests?.request?.sort}
      tradesRequestsLoading={tradesRequestsLoading}
    />
  )
}

export default TradeRequestsDetailsWrapper;
