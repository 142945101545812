import React, {
  useEffect,
  useRef,
  useState
} from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import {
  useHistory,
  useLocation
} from "react-router-dom";
import { Formik } from "formik";

import Button from "components/Button";
import RenderBotTab from "../wizard/Configuration/RenderBotTab";
import TradeSettingsPreloader from "../wizard/Configuration/TradeSettingsPreloader";
import { handleResponse } from "helpers/errorsValidator";
import useCalculatedSignals from "hooks/useCalculatedISignals";
import {
  handleBotSchema,
  handleInitValues
} from "./editBotConfiguration";
import EditBotHeader from "./EditBotHeader";
import { wizardIndicatorsSelector } from "store/strategies/selectors";
import { getAvailableMarkets } from "store/markets/actions";
import { resetWizardIndicators } from "store/strategies/actions";
import { setInvestment } from "store/bots/wizard/actions";
import { getPeriods } from "store/exchanges/actions";
import { selectCurrentPlan } from "store/user/selectors";
import { addSnackBar } from "store/snackbar/actions";
import SelectBotType from "../wizard/Configuration/SelectBotType";
import { getBotMarkets } from "store/bots/actions";

const EditBotSettings = ({
  data,
  editBot,
  loading,
  setIsSubmit,
  isSubmit,
  wizardMarkets
}) => {
  const mounting = useRef(false);

  const [submitted, setSubmitted] = useState(false);
  const [botType, setBotType] = useState(data?.type);
  const initialValues = handleInitValues(data, botType)?.[botType];
  const indicators = useSelector((state) => wizardIndicatorsSelector(state));
  const currentPlan = useSelector(selectCurrentPlan);
  
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  
  const filteredIndicators = indicators
    .map(indicator => ({
      code: indicator.code,
      type: indicator.type,
      settings: indicator.settings,
      required: indicator.required
    }))
    
    const { buySignals, sellSignals } = useCalculatedSignals(data, filteredIndicators);
    
    const handleRedirect = () => {
      if (location?.state?.deal) return history.push(`/deals/${location?.state?.deal}`);
      if (location?.state?.investment) history.push(`/investments/${location?.state?.investment}`);
      if (location?.state?.backtest) return history.push(`/backtests/${location?.state?.backtest}`);
      if (location?.state?.genetic) return history.push(`/genetic-algorithm/${location?.state?.genetic}`);
      history.push(`/bots/${data.id}`);
  };

  useEffect(() => {
    if (wizardMarkets?.investment?.credentials) dispatch(getPeriods(wizardMarkets?.investment?.credentials?.exchange));
  }, [wizardMarkets.investment.credentials, dispatch]);
  
  useEffect(() => {
    if (data?.type) {
      if (!mounting.current) dispatch(getAvailableMarkets(data.investment.id));
      dispatch(setInvestment(data));
      setBotType(data.type);
    }
    
    return () => {
      mounting.current = true;
      dispatch(setInvestment({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data?.type]);

  const handleSubmit = async (values, formik) => {
    const customResErrors = (res) => {
      formik.setSubmitting(false);
      handleResponse(res, formik.setErrors);
      setIsSubmit(false);
      dispatch(addSnackBar("errors", res.message))
    };
    const initBody = {
      ...wizardMarkets,
      type: botType,
      minimumTrades: Number(wizardMarkets.minimumTrades) || null,
      volume: Number(wizardMarkets.volume) || null,
      spreadPercent: Number(wizardMarkets.spreadPercent) || null,
      markPriceDifference: Number(wizardMarkets.markPriceDifference) || null,
      investment: wizardMarkets.investment.id,
      strategy: values?.strategy
    };

    setIsSubmit(true);
    formik.setSubmitting(true);

    const body = {
      ...initBody,
      ...(botType === "custom"
        ? {
          tradeSettings: {
            ...values,
            indicators: [...data?.tradeSettings?.indicators || [], ...filteredIndicators],
          }
        }
        : null)
    };

    const res = await dispatch(editBot(data.id, body));

    if (res.errors) {
      customResErrors(res);
    } else {
      formik.setStatus({ message: res.message });
      dispatch(getBotMarkets(data.id));
      handleRedirect();
    }
    resetWizardIndicators();
    formik.setSubmitting(false);
  };

  if (loading && !isSubmit) return <TradeSettingsPreloader />;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={handleBotSchema(botType, buySignals, sellSignals)}
      onSubmit={(values, formikHelpers) => handleSubmit(values, formikHelpers)}
    >
      {({
        isSubmitting,
        handleSubmit,
        values
      }) => (
        <>
          <EditBotHeader />
          <div className="p-sm">
            <SelectBotType 
              setBotType={setBotType}
              botType={botType}
            />
            <RenderBotTab
              isEdit
              buySignals={buySignals}
              sellSignals={sellSignals}
              botData={data}
              loadingWizard={loading}
              submitted={submitted}
              setSubmitted={setSubmitted}
              botIndicators={data?.tradeSettings?.indicators || []}
              editSelectedInvestment={wizardMarkets?.investment}
              editPeriod={wizardMarkets?.period}
              type={botType}
              currentPlan={currentPlan}
            />
            <div className="d-flex flex-column flex-sm-row justify-content-between py-4">
              <Button
                color="gray"
                outline
                onClick={handleRedirect}
              >
                Cancel
              </Button>
              <Button
                color="blue"
                className="mt-2 mt-sm-0"
                loading={isSubmitting}
                disabled={currentPlan?.type !== "premium" && botType ==="genetic"}
                onClick={() => {
                  setSubmitted(true)
                  handleSubmit(values)
                }}
                type="submit"
              >
                Save changes
              </Button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

export default EditBotSettings;

