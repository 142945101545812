import { closeChannel, restoreChannel } from "store/channels/actions";

export const CHANNELS_CONTENT = {
  details: {
    widgets: {
      dangerZone: {
        title: "Danger zone",
        subtitle: "This is manual actions allowed for this channel. Please keep in mind that channel will close or restore.",
        actions: {
          open: {
            name: "Close",
            title: "Please confirm the channel close",
            warning: "Are you sure you want to force close this channel?",
            actionFunction: closeChannel,
          },
          closed: {
            name: "Restore",
            title: "Please confirm the channel restore",
            warning: "Are you sure you want to force restore this channel?",
            actionFunction: restoreChannel,
          },
        },
      },
    },
  },
};
