import React from "react";
import { useSelector } from "react-redux";

import ReferralSystemTablePreloader from "../preloaders/ReferralSystemTablePreloader";
import { getUsersReferrals } from "store/user/actions";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import PaginationPanel from "components/PaginationPanel";
import { REFERRAL_TABLE_HEAD } from "models/user/tableHead";
import CustomScrollbars from "components/CustomScrollbars";
import {ReactComponent as ConfirmedFalse} from "assets/img/icons/confirmedFalse.svg";
import {ReactComponent as ConfirmedTrue} from "assets/img/icons/confirmedTrue.svg";
import SortTableHead from "components/SortTableHead";
import StyledTable from "components/StyledTable";

const ReferralSystemTable = ({referrals={}}) => {

  const referralsLoading = useSelector(state => state.me.isReferralsLoading);
  const {handlePaginate, handleSorting} = useFiltersWithRequest(referrals.request, getUsersReferrals);

  if(referralsLoading) return <ReferralSystemTablePreloader/>

  return (
    <>
      <CustomScrollbars
        autoHideTimeout={500}
        autoHideDuration={500}
        autoHeight
        autoHeightMax={Number.MAX_VALUE}
      >
        <StyledTable>
          <SortTableHead
            theadItems={REFERRAL_TABLE_HEAD}
            handleSorting={handleSorting}
            sort={referrals?.request?.sort}
          />
          <tbody className="font-sm">
            {referrals?.items?.map((item) =>
              <tr key={item.id}>
                <td>
                  <p className="text-white">
                    {item.email}
                  </p>
                </td>
                <td>
                  <p className="text-white">
                    {item.name}
                  </p>
                </td>
                <td>
                  <p className="text-capitalize text-white">
                    {!item.subscription ? "Free" : item.subscription}
                  </p>
                </td>
                <td>
                  <p className="text-white">
                    {item.trial ? "Yes" : "No"}
                  </p>
                </td>
                <td>
                  <span>
                    {item.confirmed ? <ConfirmedTrue/> : <ConfirmedFalse/>}
                  </span>
                </td>
                <td>
                  <p className="text-white">
                    {item.createdAt}
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </StyledTable>
      </CustomScrollbars>
      <div className={referrals?.total > 10 ? "pt-3" : ""}>
        <PaginationPanel
          activePage={referrals?.request?.page}
          totalItemsCount={referrals?.total}
          onChange={(page) => handlePaginate(page)}
        />
      </div>
    </>
  )
}

export default ReferralSystemTable;
