import React from "react";

import ChannelSignalsTables from "views/channels/channelSignalsTables/ChannelSignalsTables";
import ChannelDetailsOverview from "views/channels/compounds/ChannelDetailsOverview";

const OverviewTab = ({ 
  channel, 
  loadingPage
}) => {
  return (
    <>
      <ChannelDetailsOverview
        channel={channel}
        loading={loadingPage}
      />
      <ChannelSignalsTables
        channel={channel}
        loading={loadingPage}
      />
    </>
  );
};

export default OverviewTab;