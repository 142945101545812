import React from "react";
import { Link } from "react-router-dom";
import { CardHeader } from "reactstrap";
import styled from "styled-components";

import SkeletonPreloader from "components/SkeletonPreloader";
import { ReactComponent as Channels } from "assets/img/icons/Channels.svg";

const MyChannelsHeader = ({ 
  id, 
  loading
}) => {
  return (
    <CardHeader className="p-0">
      <div className="d-flex justify-content-between align-items-center">
        {
        loading
          ? <>
            <div className="d-flex">
              <SkeletonPreloader
                height={24}
                width={24}
              />
              <SkeletonPreloader
                height={20}
                width={57}
                className="ml-2"
              />
            </div>
              <SkeletonPreloader
                height={17}
                width={32}
              />
          </>
          : <>
            <p className="font-weight-500 text-white">
              <MyChannelsHeader.HeaderIcon
                className="mr-2"
                width={24}
                height={24}
              />
              My channels
            </p>
            {
              id
                ? <Link
                  to={`/channels/${id}`}
                  className="text-info"
                >
                  View
                </Link>
                : null
            }
          </>}
      </div>
    </CardHeader>
  );
};

MyChannelsHeader.HeaderIcon = styled(Channels)`
  path{
    stroke: var(--info);
  }
`;

export default MyChannelsHeader;