const indicatorsConfiguration = [
  {
    label: 'Awesome Oscillator',
    value: 'ao',
    description: 'There is no specific TALib function, calculated in such a way:\n' +
      '\n' +
      'AO = SMA(High+Low)/2, 5 Periods) - SMA(High+Low/2, 34 Periods)\n' +
      '\n' +
      'It is a momentum indicator\n' +
      'This function just watches for zero-line crossover.',
  },
  {
    label: 'Average True Range',
    value: 'atr',
    description: 'The idea is to use ATR to identify breakouts, if the price goes higher than the previous close + ATR, a price breakout has occurred. The position is closed when the price goes 1 ATR below the previous close. This algorithm uses ATR as a momentum strategy, but the same signal can be used for a reversion strategy, since ATR doesn\'t indicate the price direction (like adx).',
  },
  {
    label: 'Change Momentum Oscillator',
    value: 'cmo',
    description: 'There are several ways to interpret the CMO. Values over 50 indicate overbought conditions, while values under -50 indicate oversold conditions. High CMO values indicate strong trends. When the CMO crosses above a moving average of the CMO, it is a buy signal, crossing down is a sell signal.'
  },
  {
    label: 'Commodity Channel Index',
    value: 'cci',
    description: 'The CCI is designed to detect beginning and ending market trends. The range of 100 to -100 is the normal trading range. CCI values outside this range indicate overbought or oversold conditions. You can also look for price divergence in the CCI. If the price is making new highs, and the CCI is not, then a price correction is likely.'
  },
  {
    label: 'Moving Average Crossover Divergence',
    value: 'macd',
    description: 'When the MACD signal less than 0, the price is trending down, and it\'s time to sell. When the MACD signal greater than 0, the price is trending up it\'s time to buy. Used to catch trends early and can also help us spot trend reversals. It consists of 2 moving averages (1 fast, 1 slow) and vertical lines called a histogram, which measures the distance between the 2 moving averages. Contrary to what many people think, the moving average lines are NOT moving averages of the price. They are moving averages of other moving averages. MACD’s downfall is its lag because it uses so many moving averages. One way to use MACD is to wait for the fast line to “cross over” or “cross under” the slow line and enter the trade accordingly because it signals a new trend.'
  },
  {
    label: 'MACD with controllable MA type',
    value: 'macdext',
    description: 'Moving Average Convergence/Divergence with controllable MA type let you choose the Moving average type to use in MACD calculations.'
  },
  {
    label: 'Stochastic Oscillator',
    value: 'stoch',
    description: 'The Stochastic Oscillator measures where the close is in relation to the recent trading range. The values range from zero to 100. When the Fast %D crosses above the Slow %D, it is a buy signal; when it crosses below, it is a sell signal. The Raw %K is generally considered too erratic to use for crossover signals. When the stochastic oscillator dips below 10, the pair is determined to be oversold and a long position is opened. The position is exited when the indicator rises above 90 because the pair is thought to be overbought. Used to indicate overbought and oversold conditions. When the moving average lines are above 80, it means that the market is overbought, and we should look to sell. When the moving average lines are below 20, it means that the market is oversold, and we should look to buy.'
  },
  {
    label: 'Stochastic Fast Oscillator',
    value: 'stochf',
    description: 'The Stochastic Fast is more rarely used then usual Stochastic function because its higher volatility cause often whipsaws. Conditions are the same as for usual Stochastic Oscillator: when the stochastic oscillator dips below 10, the pair is determined to be oversold and a long position is opened. The position is exited when the indicator rises above 90 because the pair is thought to be overbought.'
  },
  {
    label: 'Parabolic Stop And Reversal',
    value: 'sar',
    description: 'This indicator is made to spot trend reversals .The indicator is below prices as they\'re rising and above prices as they\'re falling. In this regard, the indicator stops and reverses when the price trend reverses and breaks above or below the indicator. Indicator only gives bullish and bearish signals. When the last three SAR points are above the candle (high) then it is a sell signal. When the last three SAR points are below the candle (low) then is a buy signal. These are best used in trending markets that consist of long rallies and downturns. acceleration = 0.02, maximum = 0.02 are trading view defaults.'
  },
  {
    label: 'Relative Strength Index',
    value: 'rsi',
    description: 'The Relative Strength Index (RSI) calculates a ratio of the recent upward price movements to the absolute price movement. The RSI ranges from 0 to 100. The RSI is interpreted as an overbought/oversold indicator when the value is over 70/below 30. You can also look for divergence with price. If the price is making new highs/lows, and the RSI is not, it indicates a reversal. RSI can also be used to confirm trend formations. If you think a trend is forming, wait for RSI to go above or below 50 (depending on if you’re looking at an uptrend or downtrend) before you enter a trade.'
  },
  {
    label: 'Average Directional Movement Index',
    value: 'adx',
    description: 'The ADX calculates the potential strength of a trend. It fluctuates from 0 to 100, with readings below 20 indicating a weak trend and readings above 50 signaling a strong trend. ADX can be used as confirmation whether the pair could possibly continue in its current trend or not. ADX can also be used to* determine when one should close a trade early. For instance, when ADX starts to slide below 50,* it indicates that the current trend is possibly losing steam.'
  },
  {
    label: 'Bollinger Bands',
    value: 'bbands',
    description: 'When the price breaks out of the upper Bollinger band, a sell or short position is opened. A long position is opened when the price dips below the lower band. Used to measure the market’s volatility. They act like mini support and resistance levels. A strategy that relies on the notion that price tends to always return to the middle of the Bollinger bands. You buy when the price hits the lower Bollinger band. You sell when the price hits the upper Bollinger band. Best used in ranging markets.'
  },
  {
    label: 'Hilbert Transform - Trendline',
    value: 'htl',
    description: 'The Hilbert Transform Indicator was authored by John Ehlers. The Hilbert Transform itself, is an all-pass filter used in digital signal processing. By using present and prior price differences, and some feedback, price values are split into their complex number components of real (inPhase) and imaginary (quadrature) parts. The user may change the input (midpoint), and period length. This indicator’s definition is further expressed in the condensed code given in the calculation below.'
  },
  {
    label: 'Hilbert Transform - Sinewave',
    value: 'hts',
    description: 'Indicator is based on the assumption that markets move in Cyclical Patterns. It is based on the idea of the Economical processes periodicity: the indicator detects this periodicity violations. It uses 2 sine plots to depict if the Market is in a Trend mode or in a Cycle mode. One line depicts the Sine of the calculated phase angle over time. The other line depicts the Sine of the phase angle advanced by 45 degrees, which is called the Lead Sine.The crossings of the Sine and Lead Sine together provide precise and advanced picture of Cycle mode turning points.'
  },
  {
    label: 'Hilbert Transform - Trendmode',
    value: 'htm',
    description: 'The minimum time period for execution of this function requires larger time horizon. Please increase the time horizon for this function. The output start index for this execution was zero with a total number of output elements of zero. The Trend vs Cycle Mode is Hilbert Transform indicator that generates in-phase and quadrature components of DOW price series in order to analyze trend vs cycle patterns of DOW.'
  },
  {
    label: 'Market Meanness Index',
    value: 'mmi',
    description: 'There is no TALib specific function. It reveals if the market is in a trend or it’s random (or chaotic). The MMI indicator is essentially a measure of business activity index. The indicator is made up of a firebrick colored line that hovers above/below two distinctly marked levels i.e. the Trend and Random signal levels. The Trend Level is represented by the region above the 74 level, while the region above the 76 level depicts the Random Level.'
  },
  {
    label: 'Money Flow Index',
    value: 'mfi',
    description: 'The Money Flow Index (MFI) is a technical oscillator that uses price and volume data for identifying overbought or oversold signals in an asset. It can also be used to spot divergences which warn of a trend change in price. The oscillator moves between 0 and 100.'
  },
  {
    label: 'On Balance Volume',
    value: 'obv',
    description: 'It is a cumulative total of the up and down volume. When the close is higher than the previous close, the volume is added to the running total, and when the close is lower than the previous close, the volume is subtracted from the running total. To interpret the OBV, look for the OBV to move with the price or precede price moves. If the price moves before the OBV, then it is a non-confirmed move. A series of rising peaks, or falling troughs, in the OBV indicates a strong trend. If the OBV is flat, then the market is not trending.'
  },
]

export { indicatorsConfiguration }
