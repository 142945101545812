import React from "react";
import classNames from "classnames";
import { HelpTooltip } from "shared/ui";


const BotMarket = ({
  data = {},
  className = ""
}) => {

  if (!data?.marketsCount) {
    return (
      <HelpTooltip
        index={1}
        opacity="1"
      >
        <span
          className="text-capitalize"
          data-testid="bot-market-info-text">
          Market config is missed
        </span>
      </HelpTooltip>
    );
  };

  if (data?.marketsCount > 1) return (
    <p
      className="mb-0 font-weight-500 text-white"
      data-testid="bot-market-selected-markets"
    >
      {`${data?.marketsCount} selected`}
    </p>
  );

  return (
    <p
      className={`mb-0 font-weight-500 text-white ${className}`}
      data-testid="bot-market-coins"
    >
      {
        data?.allCoins ?
          "ALL"
          :
          data?.coins?.[0]
          ||
          Object.values(data?.coins)?.[0]
      }
      {
        data?.excludedCoins?.length ?
          <span
            className="text-danger"
            data-testid="bot-market-excludedCoins"
          >
            {` -${data.excludedCoins.length}`}
          </span>
          :
          <>
            <span className="font-weight-300 text-white-35 pl-1">
              /
            </span>{" "}
            <span
              data-testid="bot-market-investment-asset"
              className={classNames({
                "text-white-35 font-weight-300": data?.allCoins
              })}
            >
              {data?.investment?.asset}
            </span>
          </>
      }
    </p>
  );
};

export default BotMarket;
