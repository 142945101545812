import React, {
  useRef,
  useState
} from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import MyCredentialsTable from "./MyCredentialTable/MyCredentialsTable";
import {MyCredentialBalances} from "./MyCredentialBalances/MyCredentialBalances";
import BotsWidget from "../BotsWidget/botsWidget";

const MyCredentialsBlock = ({exchangeMarketLoading}) => {

  const tableRef = useRef(null);
  const [id, setId] = useState(null);
  const { quote, base } = useParams();

  return (
  <MyCredentialsBlock.Wrapper ref={tableRef} className="mb-3">
    <MyCredentialsTable
      exchangeMarketLoading={exchangeMarketLoading}
      tableRef={tableRef}
      setId={setId}
    />
    {
      id ?
      <>
        <MyCredentialBalances
          id={id}
          quote={quote}
          base={base}
        />
        <BotsWidget
          id={id}
          quote={quote}
          base={base}
        />
      </>
       :
      null
    }
  </MyCredentialsBlock.Wrapper>);
};

MyCredentialsBlock.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export default React.memo(MyCredentialsBlock);
