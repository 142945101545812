import React from "react";
import SkeletonPreloader from "components/SkeletonPreloader";
import _ from "lodash";

const IndicatorsPreloader = () => {
  return <div className="pb-3">
    {
      _.times(6, (e) =>
        <div className="indicator d-flex align-items-center justify-content-between" style={{background: '#2E3148'}} key={e}>
          <div className="d-flex align-items-center">
            <SkeletonPreloader
              circle
              width={24}
              height={24}
              className="mr-3"
            />
            <SkeletonPreloader
              width={122}
              height={26}
              borderRadius="6px"
              className="mr-4"
            />
            <SkeletonPreloader
              width={88}
              height={15}
              className="mr-4"
            />
            <SkeletonPreloader
              width={91}
              height={9}
              className="d-none d-md-block"
            />
          </div>
          <div className="d-none d-md-flex align-items-center">
            <SkeletonPreloader
              width={24}
              height={24}
              circle
              className="mr-3"
            />
            <SkeletonPreloader
              width={24}
              height={24}
              circle
            />
          </div>
        </div>
      )
    }
  </div>
}

export default IndicatorsPreloader
