import React, { useState } from "react";
import styled from "styled-components";
import { Card, CardBody, Row, Col } from "reactstrap";

import AssetWidgetPreloader from "../AssetWidgetPreloader";
import EmptyDashboardWidgets from "../EmptyDashboadWidgets";
import DoughnutChart from "components/DoughnutChart";
import AllocationRow from "./AllocationRow";
import AllocationHeader from "./AllocationHeader";
import AllocationEmpty from "./AllocationEmpty";
import { ReactComponent as EmptyAssetAllocation} from "assets/img/EmptyAssetAllocation.svg";
import { ReactComponent as ErrorIcon} from "assets/img/Error.svg";
import { ReactComponent as Allocation } from "assets/img/AssetAllocation.svg";

const EXCHANGE_CHART_COLORS = {
  poloniex: "#066A7B",
  binance: "#F3BA2F",
  hitbtc: "#42ACDB",
};

const AssetAllocation = ({
  assets = [], 
  error, 
  loading, 
  content
}) => {
  const [hoverredElemId, setHoverredElemId] = useState(null);
  const [hideEmpty, setHideEmpty] = useState(true);
  const [hoverCurrentBg, setHoverCurrentBg] = useState(-1);

  const filteredData = assets?.reduce((total, item) => total + +item?.balance?.btc || 0, 0);

  const filteredAssets = hideEmpty ? assets
    .filter((asset) => asset?.balance?.btc !== null && (asset?.estimated?.amount !== 0 || asset?.estimated?.amount !== null)) :
    assets;

  const oneOfEmpty = assets.some(elem => !+elem.balance?.btc);

  const handleMouseEnter = (index) => setHoverredElemId(index);
  const handleMouseLeave = () => setHoverredElemId(null);

  if (loading) return <AssetWidgetPreloader/>;

  if (error) {
    return (
      <Card className="p-4">
        <div className="d-flex align-items-center">
          <Allocation />
          <p className="mb-0 ml-2 font-weight-500">{content.title}</p>
        </div>
        <EmptyDashboardWidgets
          Img={ErrorIcon}
          title={content.errorState.title}
          descriptionBefore={content.errorState.descriptionBefore}
        />
      </Card>
    );
  }

  return (
    <AssetAllocation.AssetAllocationCard>
      <AllocationHeader
        setHideEmpty={setHideEmpty}
        setHoverredElemId={setHoverredElemId}
        hoverredElemId={hoverredElemId}
        filteredAssets={filteredAssets}
        hideEmpty={hideEmpty}
        assets={assets}
        oneOfEmpty={oneOfEmpty}
        content={content}
      />
      <CardBody className="p-0">
        <Row>
          <Col xs="12">
            <div className="chart-area p-0 m-0">
              {filteredAssets.length || assets.length ?
                <div className="d-flex flex-column">
                  {
                    filteredData > 0 ?
                      <AssetAllocation.ChartWrapper>
                        <DoughnutChart
                          index={hoverredElemId}
                          key={hoverredElemId}
                          exchange={filteredAssets}
                          setHoverCurrentBg={setHoverCurrentBg}
                          backgroundColors={EXCHANGE_CHART_COLORS}
                        />
                      </AssetAllocation.ChartWrapper>
                      :
                      <AllocationEmpty
                        assets={assets}
                        filteredAssets={filteredAssets}
                        hideEmpty={hideEmpty}
                        content={content.emptyStateHideEmpty}
                      />
                  }
                  {filteredAssets.map((asset, index) =>
                    <AllocationRow
                      key={asset.credentials.id}
                      asset={asset}
                      index={index}
                      handleMouseLeave={handleMouseLeave}
                      handleMouseEnter={handleMouseEnter}
                      hoverCurrentBg={hoverCurrentBg}
                      backgroundColors={EXCHANGE_CHART_COLORS}
                    />
                  )}
                </div> : null
              }
              {!filteredAssets.length && !assets.length && !error ?
                <EmptyDashboardWidgets
                  Img={EmptyAssetAllocation}
                  title={content.emptyNoConnected.title}
                  descriptionBefore={content.emptyNoConnected.descriptionBefore}
                  link={content.emptyNoConnected.link}
                  descriptionAfter={content.emptyNoConnected.descriptionAfter}
                />
                : null}
            </div>
          </Col>
        </Row>
      </CardBody>
    </AssetAllocation.AssetAllocationCard>
  );
};

AssetAllocation.AssetAllocationCard = styled(Card)`
  padding: 0 0 8px 0;
  min-height: 340px;
  margin-bottom: 24px !important;
`;

AssetAllocation.ChartWrapper = styled.div`
  padding: 16px 0 30px 0;
  margin: 0 auto;
`;

export default AssetAllocation;
