import React from "react";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";

import * as Sentry from "@sentry/react";
import authImg from "assets/img/auth-bg.png";
import FallBack from "components/FallBack";
import { ReactComponent as BackArrow } from "assets/img/icons/arrow-back.svg";

const AuthLayout = ({ children }) => {
  const { pathname } = useLocation();
  const history = useHistory();

  return (
    <Wrapper className="d-flex wrapper wrapper-full-page">
      <StyledImageSection className="d-none d-lg-block"/>
      <StyledFormSection className="position-relative">
        <StyledAddressInfo className="text-center text-white font-sm w-100">
          <p className="m-0">
            Always check the address of{" "}
            <a
              href="https://app.darkbot.io"
              className="text-info font-weight-500"
            >
              app.darkbot.io
            </a>{" "}
            to ensure the page is authentic
          </p>
        </StyledAddressInfo>
        {
          pathname === "/forgot-password" || pathname === "/change-password" ?
          <div className="d-flex mt-4 pt-3">
            <BackButton onClick={() => history.push("/login")}>
              <BackArrow />
            </BackButton>
            <p className="text-white-75 ml-3 my-auto">
              Back to Sign in
            </p>
          </div> : null
        }
        <Sentry.ErrorBoundary fallback={<FallBack />}>
          {children}
        </Sentry.ErrorBoundary>
      </StyledFormSection>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media (min-width: 1728px) {
    border-radius: 16px;
    border: 2px solid #363A54;
    margin: 50px 124px;
  }
`;

const StyledAddressInfo = styled.div`
  padding: 0.75rem;
  background: #122E54;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 768px) {
    & p {
      font-size: 0.625rem !important;
    }

    padding: 0.5rem;
  }

  @media (min-width: 1728px) {
    border-top-right-radius: 16px;
  }
`;

const StyledImageSection = styled.div`
  min-width: 50%;
  background-image: url("${authImg}");
  background-size: 100%;
  background-position-y: center;
  background-repeat: no-repeat;

  @media (min-width: 1728px) {
    border-radius: 16px 0 0 16px;
  }
`;

const StyledFormSection = styled.div`
  min-width: 50%;
  background: var(--dark-slate);
  padding: 4rem 8.4%;

  @media (min-width: 1728px) {
    border-radius: 0 16px 16px 0;
  }

  @media (max-width: 1000px) {
    min-width: 100%;
  }
`;

const BackButton = styled.button`
  padding: 0.74rem;
  background: #2C2F44;
  border-radius: 6px;
  border: 2px solid #363A54;
`;

export default AuthLayout;
