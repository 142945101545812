import React from "react";
import { Card, CardBody } from "reactstrap";
import SkeletonPreloader from "components/SkeletonPreloader";

const HeaderPreLoader = ({
  sizeIcon = 58, 
  borderRadius
}) => (
  <Card>
    <CardBody className="p-4">
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <SkeletonPreloader
            circle={!borderRadius}
            borderRadius={borderRadius}
            height={sizeIcon}
            width={sizeIcon}
          />
          <div className="d-flex flex-column ml-4">
            <SkeletonPreloader
              height={20}
              width={110}
              className="my-auto"
            />
            <SkeletonPreloader
              height={15}
              width={273}
              className="my-auto d-none"
            />
            <SkeletonPreloader
              height={15}
              width={200}
              className="my-auto d-md-block"
            />
          </div>
        </div>
        <div className="flex-column d-none d-md-flex">
          <SkeletonPreloader
            height={24}
            width={90}
            className="my-auto"
          />
          <SkeletonPreloader
            height={12}
            width={94}
            className="my-auto"
          />
        </div>
      </div>
    </CardBody>
  </Card>
);

export default HeaderPreLoader;
