import React, { useEffect } from "react";
import { Formik } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  UncontrolledAlert
} from "reactstrap";
import * as Yup from "yup";

import {
  getSignals,
  resetSignals
} from "store/strategies/actions";
import SignalsCheckForm from "../forms/SignalsCheckForm";
import SignalsChart from "../charts/SignalsChart";

const signalsSchema = Yup.object().shape({
  exchange: Yup.string()
    .required("This field is required."),
  pair: Yup.string()
    .required("This field is required."),
  period: Yup.string()
    .required("This field is required."),
  from: Yup.string()
    .required("This field is required."),
  to: Yup.string()
    .required("This field is required.")
});

const SignalsCheck = ({
  getSignals,
  resetSignals,
  id,
  signals={},
  loading,
  error
}) => {
  useEffect(() => {
    return () => resetSignals();
  }, [resetSignals]);

  return(
    <Card>
      <CardHeader>
        <p className="h4 mb-4 mt-2">
          Signals check
        </p>
        <Formik
          validationSchema={signalsSchema}
          initialValues={{
            exchange: "",
            pair: "",
            period: "",
            from: "",
            to: ""
          }}
          onSubmit={(values) => getSignals(id, values)}
        >
          {({ values, setFieldValue, errors }) => (
            <>
              {errors.message &&
                <UncontrolledAlert color="danger" fade={false}>
                  {errors.message}
                </UncontrolledAlert>}
              <SignalsCheckForm
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
              />
            </>
          )}
        </Formik>
      </CardHeader>
      <CardBody>
        <SignalsChart
          chart={signals.chart}
          loading={loading}
          error={error}
        />
      </CardBody>
    </Card>
  );
};


const mapStateToProps = (state) => ({
  signals: state.strategies.signals.data,
  loading: state.strategies.signals.loading,
  error: state.strategies.signals.error,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getSignals,
  resetSignals
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SignalsCheck);
