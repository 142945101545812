import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import classNames from "classnames";
import { useDispatch } from "react-redux";

import useClickOutside from "hooks/useClickOutside";
import { addSnackBar } from "store/snackbar/actions";
import { truncate } from "helpers/valuesFormatters";
import InlineButton from "./InlineButton";

const NameInlineEditor = ({
  name,
  id,
  description = "",
  owner = true,
  type,
  className,
  onChangeName
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [width, setWidth] = useState("");
  const [fieldValue, setFieldValue] = useState(name || "");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const formRef = useRef();
  const fieldRef = useRef();
  const fakeRef = useRef();

  const handleSave = useCallback(async (value = "") => {
    if (isError || !isEditing || isLoading) return;
    if (name === fieldValue) return setIsEditing(false);

    setIsLoading(true);
    const payload = { name: value || fieldValue };

    if (description) {
      payload.description = description;
      payload.type = type;
    };

    const res = await dispatch(onChangeName(id, payload))
    if (!res?.errors) {
      dispatch(addSnackBar("success", res.message));
      setIsEditing(false);
      setIsLoading(false);
      fieldRef.current?.blur();
      return;
    }
    dispatch(addSnackBar("reject", res.message));
    setIsLoading(false);
  }, [dispatch, id, fieldValue, isError, isEditing, isLoading, name, onChangeName, description, type]);

  useClickOutside(formRef, handleSave, fieldValue);

  const handleCancel = (e) => {
    e.preventDefault();
    setFieldValue(name);
    setIsEditing(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave(fieldValue);
  };

  useEffect(() => {
    setWidth(fakeRef.current?.clientWidth)
  }, [fieldValue]);

  useEffect(() => {
    if (!fieldValue) setIsError(true);
    if (fieldValue && isError) setIsError(false);
  }, [fieldValue, isError]);
  return (
    <NameInlineEditor.Form
      className={`d-flex mb-1 ${className} w-100`}
      ref={formRef}
    >
      <span
        ref={fakeRef}
        className="h1 m-0 font-weight-500 invisible position-absolute"
      >
        {truncate(fieldValue, 30)}
      </span>
      <NameInlineEditor.InlineEditField
        name="name"
        className={classNames({
          "has-error": isError,
          "field-edit": isEditing
        })}
        ref={fieldRef}
        valWidth={width}
        isEditing={isEditing}
        onClick={() => setIsEditing(true)}
        value={isEditing ? fieldValue : truncate(fieldValue, 30)}
        onChange={({ target }) => setFieldValue(target.value)}
        disabled={!owner}
        owner={owner}
      />
      {isEditing
        ? <InlineButton
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          loading={isLoading}
        />
        : null
      }

    </NameInlineEditor.Form>
  );
};

NameInlineEditor.Form = styled.div`
  width: fit-content;
`;

NameInlineEditor.InlineEditField = styled.input`
  max-width: 100%;
  font-size: 1.5rem;
  background: transparent;
  border: none;
  line-height: 28.13px;
  color: #fff;
  font-weight: 500;
  border-radius: 6px;
  height:36px
  width: ${(props) => `calc(${props.valWidth}px + 2rem)`};

  @media (max-width: 576px) {
    max-width: ${(props) => props.isEditing ? "160px" : "200px"};
  }

  &:focus, &:focus-visible, &.field-edit {
    box-shadow: 0 0 0 1px var(--info);
    border: none;
    border-radius: 6px;
    outline: none;
    background: #2E3148;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  }

  &:hover {
    background: ${(props) => props.owner? "#2E3148" : "initial"};
  }

  &.has-error {
    box-shadow: 0 0 0 1px #ED5F5F;
  }
`;

NameInlineEditor.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  description: PropTypes.string,
  owner: PropTypes.bool,
  className: PropTypes.string,
  onChangeName: PropTypes.func
};

export default NameInlineEditor;