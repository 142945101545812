import React from "react";
import classNames from "classnames";
import { Col, Label } from "reactstrap";

import Accordion from "components/Accordion";
import NumberInput from "components/NumberInput";
import SelectInput from "components/SelectInput";
import { ReactComponent as HelpIcon } from "assets/img/icons/Help.svg";

const IndicatorSetting = ({
  indicatorType,
  currentConfiguration,
  values,
  setFieldValue,
  submitted,
  errors,
}) => {
  return (
    <Accordion
      className="mt-3"
      tooltipText={
        indicatorType
          ? "Selected indicator has no configurable settings."
          : "Please select an indicator to proceed."
      }
      title="Advanced settings"
      disable={!currentConfiguration.settings.length}
    >
      {currentConfiguration.settings && currentConfiguration.settings.length ? (
        <div className="d-flex flex-wrap">
          {currentConfiguration.settings.map((input) => (
            <Col
              key={input.label}
              md={6}
              lg={4}
              className="d-flex flex-column justify-content-between p-1 mb-2"
            >
              <div className="d-flex flex-row align-items-center mb-1">
                <Label className="mr-1 mb-0 text-white-35 font-weight-400">
                  {input.label}
                </Label>
                <HelpIcon />
              </div>
              {input.options ? (
                <SelectInput
                  name={`settings.${[input.code]}`}
                  defaultValue={{
                    value: values.settings[input.default],
                    label: values.settings[input.code],
                  }}
                  value={{
                    value: values.settings[input.code],
                    label: values.settings[input.code],
                  }}
                  onChange={({ value }) =>
                    setFieldValue(`settings.${input.code}`, value)
                  }
                  isSearchable={false}
                  hasError={
                    submitted && errors.settings && errors.settings[input.code]
                  }
                  options={input.options.map((i) => {
                    return {
                      value: i,
                      label: i,
                    };
                  })}
                  style={{ background: "#222435 !important" }}
                />
              ) : (
                <>
                  {input.type === "int" && (
                    <NumberInput
                      className={classNames("form-control", {
                        "has-error":
                          submitted &&
                          errors.settings &&
                          errors.settings[input.code],
                      })}
                      min={input.validation.min}
                      max={input.validation.max}
                      type={input.type}
                      value={values.settings[input.code]}
                      onChange={(value) =>
                        setFieldValue(`settings.${input.code}`, value)
                      }
                    />
                  )}
                  {input.type === "float" && (
                    <input
                      className={classNames("form-control", {
                        "has-error":
                          submitted &&
                          errors.settings &&
                          errors.settings[input.code],
                      })}
                      type={input.type}
                      pattern={"^\\d+(?:(\\.\\d+)?)$"}
                      value={values.settings[input.code]}
                      onChange={(e) =>
                        setFieldValue(`settings.${input.code}`, e.target.value)
                      }
                    />
                  )}
                </>
              )}
            </Col>
          ))}
        </div>
      ) : null}
    </Accordion>
  );
};

export default IndicatorSetting;
