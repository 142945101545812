import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import {EXCHANGE_BALANCES_TABLE_HEAD} from "models/credentials/enum/tables";
import StyledTable from "../RebalanceHistory/StyledTable";
import SortTableHead from "components/SortTableHead";
import CustomScrollbars from "components/CustomScrollbars";
import ExchangeTableRowModal from "./ExchangeTableRowModal";
import ExchangeTablePreload from "./ExchangeTablePreload";
import EmptyState from "components/EmptyState";

const ExchangeTableModal = ({items, request, handleSorting}) => {

  const loading = useSelector((state) => state.credentials.balances.loading);

  if (loading) return <ExchangeTablePreload/>

  if (!loading && !items?.length) {
    return <EmptyState
      title="No results for your search"
      desc="You may want to try adjusting your filters or checking the data you enter."
    />
  }

  return (
    <ExchangeTableModal.Wrapper>
    <CustomScrollbars
      autoHideTimeout={500}
      autoHideDuration={500}
      autoHeight
      autoHeightMax={Number.MAX_VALUE}
    >
      <StyledTable className="mb-0">
        <SortTableHead
          handleSorting={handleSorting}
          sort={request?.sort}
          theadItems={EXCHANGE_BALANCES_TABLE_HEAD}
        />
        <tbody className="font-sm">
        {
          items?.map((item) => <ExchangeTableRowModal item={item} key={item.asset}/>)
        }
        </tbody>
      </StyledTable>
    </CustomScrollbars>
    </ExchangeTableModal.Wrapper>
  )
}

ExchangeTableModal.Wrapper = styled.div`
  thead {
    background: #2A2C42 !important;
    tr {
      th {
        padding: 8px 16px !important;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 0 16px !important;
      }
    }
  }
`;

export default ExchangeTableModal;
