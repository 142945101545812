import React from "react";
import _ from "lodash";
import {
  CardBody,
  Card,
  Col,
  CardHeader
} from "reactstrap";

import SkeletonPreloader from "components/SkeletonPreloader";

const ListLoading = () => {
  return (
    <div>
      {
        _.times(2, (e) =>
          <Card key={e} style={{borderRadius: "8px", overflow: "hidden"}}>
            <CardHeader style={{background: "#2E3148", padding: "24px"}}>
              <div className="d-flex flex-column flex-md-row justify-content-between">
                <div className="d-flex">
                  <SkeletonPreloader
                    circle
                    height={57}
                    width={57}
                    className="mr-2 my-auto"
                  />
                  <div className="d-flex flex-column">
                    <SkeletonPreloader
                      height={21}
                      width={82}
                      className="mt-1 mb-1"
                    />
                    <SkeletonPreloader
                      height={14}
                      width={230}
                      className="mt-1 mb-1"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column align-items-md-end mt-3 mt-md-0">
                  <SkeletonPreloader
                    height={16}
                    width={121}
                    className="mt-1 mb-1"
                  />
                  <SkeletonPreloader
                    height={14}
                    width={101}
                    className="mt-1 mb-1"
                  />
                </div>
              </div>
            </CardHeader>
            <CardBody style={{padding: "24px"}}>
              <div className="d-flex flex-column mt-3">
                {
                  _.times(6, (e) =>
                    <div className="d-flex" key={e}>
                      <Col xs="5" md="1">
                        <SkeletonPreloader
                          height={14}
                          width={105}
                          className="mt-1 mb-1"
                        />
                      </Col>
                      <Col xs="7" md="3" className="ml-2">
                        <SkeletonPreloader
                          height={14}
                          width={155}
                          className="mt-1 mb-1"
                        />
                      </Col>
                      <Col xs="2" className="d-none d-md-block">
                        <SkeletonPreloader
                          height={14}
                          width={148}
                          className="mt-1 mb-1"
                        />
                      </Col>
                      <Col xs="2" className="d-none d-md-block">
                        <SkeletonPreloader
                          height={14}
                          width={128}
                          className="mt-1 mb-1"
                        />
                      </Col>
                      <Col xs="2" className="d-none d-md-block">
                        <SkeletonPreloader
                          height={14}
                          width={116}
                          className="mt-1 mb-1"
                        />
                      </Col>
                      <Col xs="2" className="d-none d-md-block">
                        <SkeletonPreloader
                          height={14}
                          width={116}
                          className="mt-1 mb-1"
                        />
                      </Col>
                    </div>
                  )
                }
              </div>
              <div className="d-flex justify-content-center">
                <SkeletonPreloader
                  count={5}
                  circle={true}
                  height={13}
                  width={13}
                  className="mt-3 ml-1 mr-1"
                />
              </div>
            </CardBody>
          </Card>
        )
      }
    </div>
  );
};

export default ListLoading;
