import React from "react";
import styled from "styled-components";
import _ from "lodash";
import SkeletonPreloader from "components/SkeletonPreloader";

const TechnicalLogsModalLoading = () => {
  return (
    <div className="mt-4">
      {
        _.times(13, (e) =>
          <div key={e}>
            <div className="d-flex align-items-center justify-content-between">
              <SkeletonPreloader
                height={10}
                width={93}
                className="mr-2"
              />
              <SkeletonPreloader
                circle={true}
                height={20}
                width={20}
                className="p-2"
              />
              <SkeletonPreloader
                height={12}
                className="ml-3"
              />
            </div>
            <TechnicalLogsModalLoading.Share className="last" times={e}>
              <SkeletonPreloader
                height={6}
                width={2}
              />
            </TechnicalLogsModalLoading.Share>
          </div>
        )
      }
    </div>
  )
}

TechnicalLogsModalLoading.Share = styled.div`
  margin: 0 !important;
  padding-left: 14.5%;
  display: ${({times}) => times === 12 ? "none" : "block"};
`;

export default TechnicalLogsModalLoading;
