import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  ModalBody,
  ModalHeader,
  Modal
} from "reactstrap";

import Button from "components/Button";
import { ReactComponent as Star } from "assets/img/icons/star1.svg";
import { ReactComponent as Warning } from "assets/img/icons/warning2.svg";

const PaymentResultModal = ({
  isModalOpen,
  setIsModalOpen,
  cutomerDetails={},
  status,
  amountTotal
}) => {
  const history = useHistory();

  const handleCloseModal = () => {
    setIsModalOpen(false);
    history.replace({ search: "" });
  };

  return (
    <StyledModal
      isOpen={isModalOpen}
      toggle={() => setIsModalOpen(!isModalOpen)}
      modalClassName="modal-black p-4"
    >
      <TopBadge className="d-flex justify-content-center align-items-center">
        {
          status === "complete" ?
          <Star />
          :
          <Warning />
        }
      </TopBadge>
      <StyledModalHeader className="position-relative pb-3">
        <p className="h1 text-white mb-2 text-center">
          {
            status === "complete" ?
            "Your plan is active!"
            :
            "Payment failed :("
          }
        </p>
        <p className="text-white-75 font-weight-400 text-center px-2">
          {
            status === "complete" ?
            "Your payment has been successfully processed! Get ready to experience the power of our cutting-edge crypto trading platform."
            :
            "We're sorry, but we were unable to process your payment at this time. Please ensure that your payment details are correct and try again."
          }
        </p>
      </StyledModalHeader>
      <ModalBody>
        <Invoice>
          <div className="p-3">
            <div className="d-flex justify-content-between mb-2">
              <p className="text-white-75 m-0">
                Name:
              </p>
              <p className="text-white m-0 font-weight-500">
                {cutomerDetails.name}
              </p>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <p className="text-white-75 m-0">
                Email:
              </p>
              <p className="text-white m-0 font-weight-500">
                {cutomerDetails.email}
              </p>
            </div>
          </div>
          <Separator className="position-relative" />
          <div className="p-3">
            <div className="d-flex justify-content-between mb-2">
              <p className="text-white-75 m-0">
                Total amount:
              </p>
              <p className="text-white m-0 font-weight-500">
                {`$${amountTotal / 100}`}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="text-white-75 m-0">
                Status:
              </p>
              <Badge className={`px-2 py-1 font-weight-500 ${status === "complete" ? "success" : "failed"}`}>
                {status === "complete" ? "Successfully Paid" : "Payment Failed"}
              </Badge>
            </div>
          </div>
        </Invoice>
        {
          status !== "complete" ?
          <SupportText className="font-sm">
            If you continue to experience issues, please contact our{" "}
            <a
              className="text-info font-weight-500"
              href="mailto:hello@darkbot.io"
            >
              support team
            </a>,{" "}
            {"and we'll do our best to assist you."}
          </SupportText>
          : null
        }
        <StyledButton
          color="blue"
          className="w-100"
          onClick={handleCloseModal}
        >
          {status === "complete" ? "Great!" : "Okay"}
        </StyledButton>
      </ModalBody>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  max-width: 415px !important;

  @media (max-width: 576px) {
    max-width: 100% !important;
  }
`;

const TopBadge = styled.div`
  background: #2E3148;
  border: 2.4px solid var(--dusk-steel);
  border-radius: 50%;
  width: 88px;
  height: 88px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -44px;
`;

const StyledModalHeader = styled(ModalHeader)`
  padding-top: 4rem !important;
`;

const Invoice = styled.div`
  background: #2E3148;
  border-radius: 8px;
`;

const Separator = styled.div`
  height: 2px;
  border-bottom: 1.5px dashed var(--dusk-steel);

  &:before, &:after {
    content: "";
    width: 24px;
    height: 24px;
    background: var(--dark-slate);
    border-radius: 50%;
    position: absolute;
    top: -10px;
  }

  &:before {
    left: -12px
  }

  &:after {
    right: -12px
  }
`;

const Badge = styled.div`
  line-height: 20px;
  border-radius: 6px;

  &.success {
    background: rgba(63, 203, 176, 0.05);
    color: #3FCBB0;
  }

  &.failed {
    background: rgba(255, 141, 114, 0.1);
    color: #FF8D72;
  }
`;

const SupportText = styled.p`
  color: #7D81AB !important;
  line-height: 150%;
  margin-top: 12px;
`;

const StyledButton = styled(Button)`
  margin-top: 56px;
`;

export default PaymentResultModal;
