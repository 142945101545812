import styled from "styled-components";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import CircleChart from "components/CircleChart";
import Icon from "components/Icon";
import {
  formatterToNull,
  setColorClass
} from "helpers/valuesFormatters";

const SignalsRow = ({
  signal,
  total,
  exchange
}) => {
  const history = useHistory();
  return (
    <SignalsRow.Row
      key={signal.id}
      onClick={() => history.push(`/signals/${signal.id}`)}
      onMouseDown={(e) => e.button === 1 && window.open(`/signals/${signal.id}`, "_blank")}
      total={total}
    >
      <td className="pl-0">
        <div className="d-flex align-items-center text-nowrap">
          <Icon
            type="exchanges"
            size="xxs"
            code={exchange}
            className="my-auto"
          />
          <p className="mb-0 ml-3 text-white font-weight-500">
            {signal?.market?.base}
          </p>
          <p className="mb-0 ml-1 text-white-35 font-weight-300">
            {`/ ${signal?.market?.quote}`}
          </p>
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center text-nowrap">
          <p className="mb-0 text-white font-weight-500">
            {formatterToNull(signal?.buyFrom)}
          </p>
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center text-nowrap">
          <p className="mb-0 text-white font-weight-500">
            {formatterToNull(signal?.buyTo)}
          </p>
        </div>
      </td>

      <td>
        <CircleChart steps={signal?.chart?.steps} />
      </td>

      <td>
        <div className="d-flex align-items-center text-nowrap">
          <p className="mb-0 text-white font-weight-500">
            {formatterToNull(signal?.stopLoss)}
          </p>
        </div>
      </td>
      {signal?.status === "completed" &&
        <td>
          <div className="d-flex align-items-center text-nowrap">
            <p className={`mb-0 font-weight-500 ${setColorClass(signal?.profitPercent)}`} >
              {formatterToNull(signal?.profitPercent)} %
            </p>
          </div>
        </td>
      }

      <td>
        <div className="d-flex align-items-center text-nowrap">
          <p className="mb-0 text-white font-weight-500">
            {formatterToNull(signal?.createdAt)}
          </p>
        </div>
      </td>
      {
        signal?.completedAt &&
        <td>
          <div className="d-flex align-items-center text-nowrap">
            <p className="mb-0 text-white font-weight-500">
              {formatterToNull(signal?.completedAt)}
            </p>
          </div>
        </td>
      }
    </SignalsRow.Row>
  )
}

SignalsRow.Row = styled.tr`
  cursor: pointer;

  &:hover {
    background: #363A54 !important;
  }

  &:last-child {
    border-bottom: ${({ total }) => total > 10 ? "1px solid #2E3148" : "none"};
  }
`;

SignalsRow.propType = {
  total: PropTypes.number,
  exchange: PropTypes.string
}

export default SignalsRow;