import React from "react";
import PropTypes from 'prop-types';

import UncollapseNavItem from "./UncollapseNavItem";
import CollapseNavItem from "./CollapseNavItem";
import { ReactComponent as Dashboard } from "assets/img/icons/Dashboard.svg";
import { ReactComponent as Bots } from "assets/img/icons/Bots.svg";
import { ReactComponent as Deals } from "assets/img/icons/Deals.svg";
import { ReactComponent as Credentials } from "assets/img/icons/Credentials.svg";
import { ReactComponent as DepositAndWithdrawal } from "assets/img/icons/DepositAndWithdrawal.svg";
import { ReactComponent as Billing } from "assets/img/icons/Billing.svg";
import { ReactComponent as Channels } from "assets/img/icons/Channels.svg";
import { ReactComponent as Signals } from "assets/img/icons/Signals_sidebar.svg";

const icons = {
  Dashboard,
  Credentials,
  Billing,
  Bots,
  Deals,
  DepositAndWithdrawal,
  Channels,
  Signals,
};
const NavigationItem = ({
  navItem,
  handleOnLinkClick,
  expanded,
  toggleSidebar,
  isMobile
}) => {
  const getIcon = (name) => {
    const Component = icons[name];
    if (Component) return <Component className={`${expanded ? "mr-2 ml-3" : "mx-auto"}`} />
    return null;
  };

  if (navItem.collapse) {
    return (
      <CollapseNavItem
        getIcon={getIcon}
        handleOnLinkClick={handleOnLinkClick}
        expanded={expanded}
        navItem={navItem}
        toggleSidebar={toggleSidebar}
        isMobile={isMobile}
      />
    );
  } else {
    return (
      <UncollapseNavItem getIcon={getIcon}
        handleOnLinkClick={handleOnLinkClick}
        expanded={expanded}
        navItem={navItem}
        toggleSidebar={toggleSidebar}
        isMobile={isMobile}
      />
    );
  }
};

NavigationItem.propTypes = {
  navItem:PropTypes.shape({
    name:PropTypes.string.isRequired,
    icon:PropTypes.string.isRequired,
    collapse:PropTypes.arrayOf(
      PropTypes.shape({
        name:PropTypes.string.isRequired,
        path:PropTypes.string.isRequired,
        disabled:PropTypes.bool
      })
    ),
  }).isRequired,
  handleOnLinkClick:PropTypes.func.isRequired,
  expanded:PropTypes.bool.isRequired,
}

export default NavigationItem;
