import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader
} from "reactstrap";

import { ReactComponent as ProfitableDeals } from "assets/img/icons/ProfitableDeals.svg";
import { ReactComponent as LossDeals } from "assets/img/icons/LossDeals.svg";
import StyledIconWrapper from "./StyledIconWrapper";
import { ReactComponent as DollarCircle } from "assets/img/icons/dollar-circle.svg";
import { setColorClass, formatValue } from "helpers/valuesFormatters";
import SummaryWidgetPreloader from "../preloaders/SummaryWidgetPreloader";

const SummaryWidget = ({ summary={}, loading }) => {
  if (loading) return <SummaryWidgetPreloader />;

  return (
    <Card className="w-100 mr-2 card-border">
      <CardHeader className="p-4 d-flex">
        <StyledIconWrapper>
          <DollarCircle />
        </StyledIconWrapper>
        <div className="d-flex flex-column my-auto ml-4">
          <label className="mb-0 text-white-35">
            Gross profit
          </label>
          <p className="m-0">
            <span className={`${setColorClass(summary.grossProfit)} font-weight-500 mr-1`}>
              {summary.grossProfit ? formatValue(summary.grossProfit) : 0}
            </span>
            <span className="font-weight-300 text-white-35">
              {summary.totalProfit?.asset}
            </span>
          </p>
        </div>
      </CardHeader>
      <hr className="m-0 mx-4" />
      <CardBody className="px-4 pt-4 pb-0">
        <div className="d-flex justify-content-between">
          <p className="font-sm text-white-35">
            Gross loss
          </p>
          <p className="m-0">
            {
              summary.grossLoss ?
              <>
                <span className="font-weight-500 mr-1 text-white">
                  {summary.grossLoss}
                </span>
                <span className="font-weight-300 text-white-35">
                  {summary.totalProfit?.asset}
                </span>
              </> : " - "
            }
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="font-sm text-white-35">
            Profit factor
          </p>
          <p className="m-0">
            {
              summary.profitFactor ?
              <>
                <span className="font-weight-500 mr-1 text-white">
                  {summary.profitFactor}
                </span>
                <span className="font-weight-300 text-white-35">
                  {summary.totalProfit?.asset}
                </span>
              </> : " - "
            }
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="font-sm text-white-35">
            Profit per day
          </p>
          <p className="m-0">
            {
              summary.profitPerDay ?
              <>
                <span className="font-weight-500 mr-1 text-white">
                  {summary.profitPerDay}
                </span>
                <span className="font-weight-300 text-white-35">
                  {summary.totalProfit?.asset}
                </span>
              </> : " - "
            }
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="font-sm text-white-35">
            Absolute drawdown
          </p>
          <p className="m-0">
            {
              summary.absoluteDrawdown ?
              <>
                <span className="font-weight-500 mr-1 text-white">
                  {summary.absoluteDrawdown}
                </span>
                <span className="font-weight-300 text-white-35">
                  {summary.totalProfit?.asset}
                </span>
              </> : " - "
            }
          </p>
        </div>
      </CardBody>
      <hr className="m-0 mx-4 dashed" />
      <CardFooter className="p-4 d-flex justify-content-between">
        <div className="d-flex flex-column">
          <p className="font-sm">
            <span className="text-white-35">
              Expected payoff
            </span>
          </p>
          <p className="h2 m-0">
            <span className="text-white mr-1">
              {summary.expectedPayoff ? formatValue(summary.expectedPayoff) : 0}
            </span>
            <span className="font-weight-300 text-white-35">
              {summary.totalProfit?.asset}
            </span>
          </p>
        </div>
        <div className="my-auto">
          {
            +summary.expectedPayoff > 0 ?
            <ProfitableDeals />
            :
            <LossDeals />
          }
        </div>
      </CardFooter>
    </Card>
  );
};

export default SummaryWidget;
