import React from "react";
import styled from "styled-components";

import { ReactComponent as Ellipse } from "assets/img/ellipse.svg";

const TimeLineItem = ({ position, stepName, value={}, lastItem, nextItem={} }) => {
  const getColorClassName = () => {
    if (stepName === "Preparing") return "step-green";
    if (stepName === "Analyzing") return "step-yellow";
    if (stepName === "Running") return "step-purpure";
  };

  const renderTimeWithLabel = (value, label) => {
    if (!value) return null;

    return (
      <div className="d-flex flex-column">
        <label className="font-xs mb-0">
          {label}
        </label>
        <p className="font-sm text-white m-0 step-time mb-0">
          {value}
        </p>
      </div>
    );
  };

  if (!value.start && !value.end) return null;

  if (position === "start" || position === "end") return (
    <div className="d-flex flex-column">
      <div className="d-flex">
        <StyledStepNameWrapper className={`d-flex flex-column justify-content-${position}`}>
          <p className="font-sm text-white-75 m-0">
            {stepName}
          </p>
        </StyledStepNameWrapper>
        <StyledTimeline className="d-flex flex-column">
          {position === "end" && <div className="line-dashed" />}
          <Ellipse role="img" />
          {position === "start" && <div className="line-dashed" />}
        </StyledTimeline>
        <div className={`d-flex flex-column justify-content-${position} pl-4 pl-md-2 pl-lg-4`}>
          <p className="font-sm text-white m-0 step-time">
            {value.start}
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="d-flex flex-column">
      <div className="d-flex">
        <StyledStepNameWrapper className="d-flex flex-column justify-content-center mt-3">
          <p className="font-sm text-white-75 m-0">
            {stepName}
          </p>
          {
            value.duration ?
            <StyledDuration className="font-sm font-weight-500">
              {value.duration}
            </StyledDuration> : null
          }
        </StyledStepNameWrapper>
        <StyledTimeline className={`d-flex flex-column ${getColorClassName()}`}>
          <div className="line-dashed" />
          <Ellipse />
          <div className="line" />
          {
            value.end ?
            <>
              <div className="line" />
              <Ellipse />
            </> : null
          }
          {
            lastItem || (!nextItem.start && !nextItem.end) ? null :
            <div className="line-dashed" />
          }
        </StyledTimeline>
        <div className="d-flex flex-column justify-content-around pl-4 pl-md-2 pl-lg-4">
          {renderTimeWithLabel(value.start, "Started")}
          {renderTimeWithLabel(value.end, "Ended")}
        </div>
      </div>
    </div>
  );
};

const StyledStepNameWrapper = styled.div`
  width: 35%;
`;

const StyledDuration = styled.p`
  color: #5E6392 !important;
`;

const StyledTimeline = styled.div`
  & .line {
    border-right: 2px solid rgba(58,61,90,1);
    height: 2rem;
    width: calc(50% + 1px);
  }

  & .line-dashed {
    border-right: 2px dashed rgba(58,61,90,1);
    height: 1.25rem;
    width: calc(50% + 1px);
  }

  &.step-green {
    & .line {
      border-right: 2px solid #A8F5C7;
    }

    & svg circle {
      fill: #A8F5C7;
    }
  }

  &.step-yellow {
    & .line {
      border-right: 2px solid #F6D589;
    }

    & svg circle {
      fill: #F6D589;
    }
  }

  &.step-purpure {
    & .line {
      border-right: 2px solid #AE49A4;
    }

    & svg circle {
      fill: #AE49A4;
    }
  }
`;

export default TimeLineItem;
