import React from "react";
import SkeletonPreloader from "components/SkeletonPreloader";
import { Card, CardBody, CardHeader } from "reactstrap";
import _ from "lodash";

const InvestmentWidgetPreloader = () => {
  return (
    <Card>
      <CardHeader style={{padding: "24px", background: "#2E3148"}} id="preloader">
        <div className="d-flex align-right">
          <SkeletonPreloader
            circle={true}
            height={41}
            width={41}
          />
          <div className="d-flex flex-column ml-3">
            <SkeletonPreloader
              height={20}
              width={110}
            />
            <SkeletonPreloader
              height={12}
              width={212}
              className="mt-2"
            />
          </div>
        </div>
      </CardHeader>
      <CardBody style={{padding: "24px"}}>
        {
          _.times(5, (e) =>
            <div className="d-flex align-items-center justify-content-between mt-2" key={e}>
              <SkeletonPreloader
                height={12}
                width={81}
              />
              <SkeletonPreloader
                height={12}
                width={121}
              />
            </div>
          )
        }
      </CardBody>
    </Card>
  )
}

export default InvestmentWidgetPreloader;
