import React, { useState } from "react";
import classnames from "classnames";
import moment from "moment";
import {
  Card,
  CardBody,
  Tooltip
} from "reactstrap";

import IdWithCopy from "components/IdWithCopy";
import { capitalize } from "lodash";
import LineWithError from "shared/ui/LineWithError";
import DealHeaderPreloader from "./preloaders/DealHeaderPreloader";
import DoubleIconsMarket from "components/DoubleIconsMarket";
import { ReactComponent as Clock } from "assets/img/icons/clock.svg";

const DealHeader = ({
  id,
  market,
  status,
  errorDeal,
  createdAt,
  loading
}) => {

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipOpenReady, setTooltipOpenReady] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const toggleReady = () => setTooltipOpenReady(!tooltipOpenReady);

  if (loading) return <DealHeaderPreloader/>;

  return (
    <Card>
      <CardBody className="p-4">
        <div className="d-flex flex-column flex-md-row justify-content-between w-100">
          <div className="d-flex flex-column flex-md-row">
            <DoubleIconsMarket
              base={market?.base}
              quote={market?.quote}
            />
            <div className="d-flex flex-column justify-content-center mb-3 mb-md-0 mt-2 mt-md-0">
              <div className="d-flex align-items-center">
                <h1 className="mb-0 text-white font-weight-500">{market?.base}</h1>
                <h1 className="mb-0 text-white-35 ml-1 font-weight-300">{`/ ${market?.quote}`}</h1>
              </div>
              <IdWithCopy id={id}/>
            </div>
          </div>
          <div className="d-flex flex-column align-items-start align-items-md-end">
              <span
                className={classnames("m-0 font-weight-500 font-sm", {
                  "text-success": status === "completed",
                  "text-white-35": status === "cancelled",
                  "text-primary": status === "running",
                  "text-info": status === "ready",
                })}
                style={{
                  background: "rgba(255, 255, 255, 0.05)",
                  padding: "3.5px 10px",
                  borderRadius: "6px",
                  cursor: status === "ready" ? "pointer" : "inherit"
                }}
                id="ready"
              >
                {capitalize(status)}
              </span>
            {
              status === "ready" ?
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpenReady}
                  target="ready"
                  toggle={toggleReady}
                >
                  Keep calm, this deal in waiting for signals from indicators
                </Tooltip>
                : null
            }
            <div className="d-flex align-items-center">
             <span className="cursor-pointer" id="dateTimeToolTip">
              <Clock
                className="icon-stroke-gray mr-1"
                aria-hidden="true"
              />
              </span>
              <p className="mt-2 text-white-35 font-xs">
                {moment(createdAt).format("DD.MM.YY HH:mm")}
              </p>
            </div>
            <Tooltip
              placement="top"
              isOpen={tooltipOpen}
              target="dateTimeToolTip"
              toggle={toggle}
            >
              This is the date when this deal was created by a bot
            </Tooltip>
          </div>
        </div>
      </CardBody>
      <LineWithError errorMessage={errorDeal}/>
    </Card>
  );
};

export default DealHeader;
