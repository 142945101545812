import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import {capitalize} from "lodash";

import InvestmentTableTooltips from "./InvestmentTableTooltips";
import Icon from "components/Icon";
import Checkbox from "components/Checkbox";
import PlannedRange from "components/AllocationRebalancing/PlannedRange";
import LookupCoins from "components/LookupCoins";
import {ReactComponent as BotsWhite} from "assets/img/icons/BotsWhite.svg";

const InvestmentItem = ({
  investment, 
  active, 
  onClickHandler,
  index
}) => {
  return (
    <InvestmentItem.Row
      onClick={() => onClickHandler(investment)}
      id={`row${index}`}
      key={investment.id}
      className={classNames({"active": active === investment.id})}
    >
      <td>
        <div className="d-flex align-items-center">
          <Checkbox type="radio" name="credential" checked={active === investment.id}/>
          <InvestmentItem.Exchange>
            <Icon
              type="exchanges"
              size="thumb"
              code={investment.credentials.exchange}
            />
            <p className="mb-0 ml-2 text-white font-weight-500 font-md">{capitalize(investment.credentials.exchange)}</p>
          </InvestmentItem.Exchange>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center text-nowrap">
          <Icon
            type="cryptocurrency"
            size="thumb"
            code={investment.asset}
            className="mr-2"
          />
          <p className="mr-1 text-white font-weight-500 mb-0 font-md">{investment.asset}</p>
          <p className="text-white-35 font-weight-300 mb-0 font-md">
            <LookupCoins coin={investment.asset}/>
          </p>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <p className="mb-0 mr-2 text-white font-weight-500 font-md">{investment.balance.amount}</p>
          <p className="mb-0 text-white-35 font-weight-300 font-md">{investment.asset}</p>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <p className="mb-0 mr-2 text-white font-weight-500 font-md">{investment.balance.estimated.total}</p>
          <p className="mb-0 text-white-35 font-weight-300 font-md">{investment.balance.estimated.currency}</p>
        </div>
      </td>
      <td>
        <div style={{minWidth: "150px"}}>
          <PlannedRange
            index={index}
            plannedPercent={investment?.plannedPercent}
            realPercent={investment?.realPercent}
            enabled={investment?.credentials?.rebalance}
          />
        </div>
      </td>
      <td>
        <InvestmentItem.Badge
          id={`bots-${index}`}
          className="d-flex justify-content-center align-items-center position-relative"
          botsCount={investment.botsCount}
        >
          <BotsWhite
            height={14}
            width={14}
          />
          <p className="m-0 ml-1 font-sm font-weight-400">{investment.botsCount}</p>
        </InvestmentItem.Badge>
      </td>
      <InvestmentTableTooltips index={index}/>
    </InvestmentItem.Row>
  )
}

InvestmentItem.Row = styled.tr`
  &:hover {
    cursor: pointer;
    transition: .3s all ease-in-out;
  }
  &.active {
    background: rgba(47, 128, 237, 0.06) !important;
    &:hover {
      background: rgba(47, 128, 237, 0.1) !important;
      transition: .3s all ease-in-out;
    }
  }
`;

InvestmentItem.Exchange = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  background: #363A54;
  border-radius: 100px;
  padding: 4px 8px 4px 4px;
`;

InvestmentItem.Badge = styled.div`
  background: #363A54;
  border-radius: 24px;
  padding: 3.5px 7px;
  max-width: ${props => props.botsCount >= 10 ? "53px" : "43px"};
  height: 23px;
`;
export default InvestmentItem;
