export const RUNNING_DEALS_TABLE_HEAD = [
  {name: "market", label: "Market", isSortable: true},
  {name: "invested", label: "Invested", isSortable: true},
  {name: "currentReturn", label: "Estimated return", isSortable: true},
  {name: "duration", label: "Duration", isSortable: true},
  {name: "action", label: "Action", isSortable: false}
];

export const GS_TABLE_HEAD = [
  {name: "market", label: "Market", isSortable: true},
  {name: "ga.disabled", label: "State", isSortable: true},
  {name: "ga.generations", label: "Generation", isSortable: true},
  {name: "ga.status", label: "Status", isSortable: true},
  {name: "statistics", label: "Statistics", isSortable: false},
];

export const BACKTEST_TABLE_HEAD = [
  {name: "status", label: "Status", isSortable: true},
  {name: "market", label: "Market", isSortable: true},
  {name: "initialBalance", label: "Initial balance", isSortable: true},
  {name: "finalBalance", label: "Final balance", isSortable: true},
  {name: "finalBalancePercent", label: "Percent", isSortable: true},
  {name: "createdAt", label: "Created at", isSortable: true},
];

export const ACTIVE_MARKETS_HEAD = [
  {name: "market", label: "Market", isSortable: true},
  {name: "successDeals", label: "Profit deals", isSortable: true},
  {name: "losingDeals", label: "Loss deals", isSortable: true},
  {name: "totalProfit", label: "Total profit", isSortable: true},
  {name: "dailyReturn", label: "Daily return", isSortable: true},
  {name: "runningDeal", label: "Running deal", isSortable: false},
];

export const MARKET_SUMMARY_TABLE_HEAD = [
  {name: "market", label: "Market", isSortable: true},
  {name: "conditions", label: "Conditions", isSortable: true},
  {name: "indicators", label: "Indicators", isSortable: true},
  {name: "pivotPoint", label: "Pivot point", isSortable: true},
  {name: "candle", label: "Candles", isSortable: true},
];

export const CONDITION_MARKET_TABLE_HEAD = [
  {name: "Name", label: "Name", isSortable: false},
  {name: "Expected", label: "Expected", isSortable: false},
  {name: "Current", label: "Current", isSortable: false},
  {name: "State", label: "State", isSortable: false},
];

export const EXCHANGE_BOTS_HEADER = [
  {name: "", label: "", isSortable: false},
  {name: "Name", label: "Name", isSortable: false},
  {name: "Markets", label: "Markets", isSortable: false},
  {name: "Statistics", label: "Statistics", isSortable: false},
  {name: "Total return", label: "Total return", isSortable: false},
  {name: "Period", label: "Period", isSortable: false},
  {name: "Deals", label: "Deals", isSortable: false}
];
