import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import {
  Card,
  FormGroup,
  Modal
} from "reactstrap";
import Button from "components/Button";
import { ReactComponent as CommentIcon } from "assets/img/icons/comment.svg";
import { DelayInput } from "react-delay-input";
import { useFormik } from "formik";
import { updateComment } from "store/credentials/actions";
import Loading from "components/Loading";
import * as yup from "yup";
import { addSnackBar } from "store/snackbar/actions";
import classnames from "classnames";

const schemaValidateComment = yup.object({
  comment: yup.string().max(140, "Credentials comment should not exceed 140 characters"),
})

const CommentModal = ({comment, id, isToggle, setIsModalOpen, isModalOpen}) => {
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();

  const {
    values, setFieldValue, errors, touched, handleChange, handleSubmit, isSubmitting, setSubmitting, isValid
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      comment: comment ?? '',
    },
    validationSchema: schemaValidateComment,
    onSubmit: (val) => {
      setLoad(true);
      setSubmitting(true);
      dispatch(updateComment(id, val.comment)).then((res) => {
        isToggle();
        setLoad(false);
        setSubmitting(false);
        setIsModalOpen(false);
        dispatch(addSnackBar(res?.errors ? "error" : "success", res.message));
      });
    },
  });

  return (
    <CommentModal.Modal isOpen={isModalOpen} toggle={isToggle} modalClassName="modal-black">
      <CommentModal.Card>
        <div className="d-flex justify-content-end">
          <Button
            color="gray"
            cancel={true}
            onClick={isToggle}
          />
        </div>
        <div className="d-flex align-items-center">
          <CommentIcon className="mr-4"/>
          <div className="ml-4">
            <CommentModal.Title>Credentials comment</CommentModal.Title>
            <CommentModal.Description>
              Specify your credentials by commenting them in order to distinguish from the others.
            </CommentModal.Description>
          </div>
        </div>

        <div className="mt-4">
          <form onSubmit={handleSubmit}>
              <FormGroup className="form-input m-0">
                <DelayInput
                  autoComplete="off"
                  delayTimeout={700}
                  type="text"
                  name="comment"
                  className={classnames("form-control", {
                    "has-error": errors.comment
                  })}
                  placeholder="Comment"
                  value={values.comment}
                  onChange={(event) => {
                    touched.comment = true;
                    handleChange(event);
                  }}
                />
            </FormGroup>
          </form>
        </div>

        <CommentModal.Error>
          {errors.comment}
        </CommentModal.Error>

        <div className="d-flex align-items-center justify-content-between mt-4">
          {
            comment?.length ?
              <Button
                color="red"
                className="m-0"
                onClick={() => setFieldValue("comment", "")}
              >
                Clear
              </Button>
            : <div/>
          }
          <div className="d-flex align-items-center">
            <Button
              color="gray"
              className="my-0 mr-2"
              onClick={isToggle}
            >
              Cancel
            </Button>
            {
              load ?
                <CommentModal.Loading>
                  <Loading size={20}/>
                </CommentModal.Loading>
                :
                <Button
                  color="blue"
                  disabled={isSubmitting || !isValid}
                  className="m-0"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
            }
          </div>
        </div>
      </CommentModal.Card>
    </CommentModal.Modal>
  );
};

CommentModal.Modal = styled(Modal)`
  @media (min-width: 576px) {
    max-width: fit-content !important;
    margin: 1rem auto !important;
  }

  .modal-content {
    background: #2E3148 !important;
    border-radius: 8px;
    width: 534px;
  }

  @media (max-width: 576px) {
    .modal-content {
      width: 100%;
    }
  }
`;

CommentModal.Card = styled(Card)`
  background: rgba(39, 41, 60, 1) !important;
  margin: 0 !important;
  padding: 24px !important;
`;

CommentModal.Title = styled.p`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0;
  color: #FFFFFF !important;
`;

CommentModal.Description = styled.p`
  margin: 8px 0 0 0;
  color: rgba(255, 255, 255, 0.75) !important;
  font-size: 0.875rem;
`;

CommentModal.Error = styled.p`
  margin: 4px 0 0 0;
  color: rgba(237, 95, 95, 1) !important;
`;

CommentModal.Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #19519B;
  border-radius: 6px;
  width: 110.578px;
  height: 40.891px;
`;

export default CommentModal;
