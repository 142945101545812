import { get } from "lodash";
import {
  GET_BALANCE_HISTORY,
  GET_BALANCE_HISTORY_LOADING,
  RESET_DASHBOARD
} from "./constants";

export const fetchDashboard = (signal) => async(dispatch, getState, fetch) => {
  try {
    dispatch({ type: "GET_DASHBOARD_LOADING" });
    const res = await fetch.get("/app/dashboard", { signal });
    const { lastDeals, lastAgreements,...rest } = res.data;
    dispatch({ type: "GET_DEALS_SUCCESS", payload: lastDeals });
    dispatch({ type: "GET_AGREEMENTS_SUCCESS", payload: lastAgreements });
    dispatch({ type: "GET_DASHBOARD_SUCCESS", payload: rest });
    return res.data;
  } catch (e) {
    dispatch({ type: "GET_DASHBOARD_FAILURE", payload: e.message });
  }
};

export const fetchBalanceHistory = (period = "day") => async(dispatch, getState, fetch) => {
  try {
    dispatch({ type: GET_BALANCE_HISTORY_LOADING });
    const res = await fetch.get(`/app/chart/user/balance-history?period=${period}`);
    dispatch({ type: GET_BALANCE_HISTORY, payload: res.data });
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const resetDashboard = () => ({ type: RESET_DASHBOARD });
