import {
  GET_MARKETS,
  GET_PERIODS,
  GET_ORDER_BOOK,
  GET_MARKET_TICKER,
  GET_ALL_EXCHANGES,
  GET_USER_EXCHANGES,
} from "./constants";

const initialState = {
  allExchanges: null,
  userExchanges: null
}

export default function exchanges(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_EXCHANGES:
      return {
        ...state,
        userExchanges: payload
      };

    case GET_ALL_EXCHANGES:
      return {
        ...state,
        allExchanges: payload
      };

    case GET_MARKETS:
      return {
        ...state,
        markets: payload
      };

    case GET_PERIODS:
      return {
        ...state,
        periods: payload
      };

    case GET_MARKET_TICKER:
      return {
        ...state,
        ticker: payload
      };

    case GET_ORDER_BOOK:
      return {
        ...state,
        orderBook: payload
      };

    default:
      return state;
  }
}
