import React from "react";
import {
  Col,
  Row,
  FormGroup
} from "reactstrap";
import {
  Formik,
  Form,
  Field
} from "formik";

import DateField from "components/DateField";

const OrdersFilters = () => {
  return(
    <Formik
      initialValues={{
        type: "",
        date: "",
        status: "",
      }}
    >
      {() => (
        <Form>
          <Row>
            <Col xs="12" md="4">
              <FormGroup>
                <Field
                  name="type"
                  type="text"
                  placeholder="Type"
                  className="form-control"
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <DateField
                inputProps={{
                  className: "form-control",
                  name: "date",
                  placeholder: "Date",
                  readOnly: true,
                }}
              />
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Field
                  name="status"
                  type="text"
                  placeholder="Status"
                  className="form-control"
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default OrdersFilters;
