import React from "react";
import { 
  Card,
  CardBody
} from "reactstrap";
import _ from "lodash";

import SkeletonPreloader from "components/SkeletonPreloader";
import { ReactComponent as BalanceChartPreload } from "assets/img/BalanceChartPreload.svg";

const ExchangeBalancePreLoad = () => {
  return (
    <Card data-testid="exchange-balance-preLoad">
      <CardBody className="p-4">
        <div>
          <div className="d-flex align-items-center mb-4">
            <SkeletonPreloader
              circle={true}
              height={19}
              width={19}
              className="mr-2"
            />
            <SkeletonPreloader
              height={14}
              width={111}
            />
          </div>
          <div className="d-flex flex-column w-100">
            <div className="d-flex justify-content-center">
              <BalanceChartPreload/>
            </div>
            {
              _.times(8, (e) =>
                <div key={e} className="d-flex justify-content-between mt-3">
                  <div className="d-flex align-items-center">
                    <SkeletonPreloader
                      circle={true}
                      height={8}
                      width={8}
                    />
                    <SkeletonPreloader
                      height={10}
                      width={88}
                      className="ml-2"
                    />
                  </div>
                  <SkeletonPreloader
                    height={10}
                    width={56}
                    className="ml-2"
                  />
                </div>
              )
            }
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default ExchangeBalancePreLoad;
