import { ReactComponent as AI } from "assets/img/botConfigurations/ai.svg";
import { ReactComponent as Template } from "assets/img/botConfigurations/template.svg";
import { ReactComponent as Manual } from "assets/img/botConfigurations/manual.svg";

export const BOT_TYPES = [
  {
    title: "Artificial Intelligence",
    name: "genetic",
    icon: <AI />
  },
  {
    title: "Use template",
    name: "template",
    icon: <Template className="template-icon" />
  },
  {
    title: "Manual setup",
    name: "custom",
    icon: <Manual />
  }
];