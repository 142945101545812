import React from "react";
import { useHistory } from "react-router-dom";

import Button from "components/Button";
import investmets from "assets/img/Investment-in-stocks.svg";

const CreateBot = () => {
  const history = useHistory();

  return (
    <div className="d-flex flex-column flex-md-row justify-content-between">
      <div className="d-flex flex-column justify-content-center">
        <p className="h1 mb-2 text-white text-center text-md-left">
          Configure automated trading
        </p>
        <p className="font-sm text-white-75 mb-4 text-center text-md-left">
          {"With your credentials in place, it's time to take control of your cryptocurrency"}<br className="d-none d-md-inline" />
          trading journey. Configure your bots to match your strategy and preferences.
        </p>
        <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-start mb-4 mb-md-0">
          <Button
            color="blue"
            onClick={() => history.push("/bots/create")}
            className="mb-3 mb-md-0"
          >
            Create a bot
          </Button>
        </div>
      </div>
      <img
        src={investmets}
        alt="Investment in stocks"
      />
    </div>
  );
};

export default CreateBot;
