import React, {
  useEffect,
  useState
} from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import qs from "qs";
import { Card } from "reactstrap";

import DashboardChannel from "components/ChannelWidget/DashboardChannel";
import MyChannelsHeader from "./MyChannelsHeader";
import EmptyDataList from "views/credentials/states/EmptyDataList";
import { CreateChannelModal } from "shared/ui";
import { getChannels } from "store/channels/actions";
import { selectChannels } from "store/channels/selectors";
import useRequestCancellation from "hooks/useRequestCancellation";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import { ReactComponent as Empty } from "assets/img/greenPlanet-2.svg";

const MyChannels = ({
  id,
  loadingPage
}) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    loading,
    data: {
      items,
      total,
      page,
      request,
    }
  } = useSelector(selectChannels);
  const handleToCreateChannels = () => {
    setIsModalOpen(true)
  };
  const channel = items?.[0];
  const isLoading = loading || initialLoading || loadingPage;

  const dispatch = useDispatch();
  const { useAbortableEffect } = useRequestCancellation();

  const { handlePaginate } = useFiltersWithRequest(request, getChannels);

  useEffect(() => {
    if (loading) {
      setInitialLoading(false)
    }
  }, [loading, setInitialLoading]);

  useAbortableEffect((signal) => {
    const filter = qs.stringify({ filter: { owner: { eq: id } } });
    if (id) dispatch(getChannels(`?${filter}&limit=1`, signal));
  }, [dispatch, id], true);

  return (
    <Card className="p-4 mb-4">
      <MyChannelsHeader
        id={channel?.id}
        loading={initialLoading || loadingPage}
      />
      {
        items?.length || isLoading
          ? <DashboardChannel
            loading={isLoading}
            channel={channel}
            page={page}
            total={total}
            handlePaginate={handlePaginate}
            isDashboard
          />
          : <EmptyDataList
            Img={Empty}
            title="It seems you don't have any channels yet."
            description="Please create your own channel first so it can appear here."
            buttonText="Create my channel"
            className="p-0 pt-2 m-0 mt-sm"
            onHandleClick={handleToCreateChannels}
          />
      }
      <CreateChannelModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </Card>
  );
};

export default MyChannels;