import styled from "styled-components";
import {Card,CardBody} from "reactstrap";

import Icon from "components/Icon";
import {MarketInfoItem, MarketInfoShare} from "./StyledCardBody"
import HeaderPreloader from "components/OrderBook/preloaders/HeaderPreloader";
import {setColorClass} from "helpers/valuesFormatters";
import {getCoinName} from "exchanges/utils";

const ExchangeMarketHeader = ({exchange, ticker, isWebsocketOpen, base, quote,}) => {
  if (!isWebsocketOpen || !ticker) return <HeaderPreloader/>
  // eslint-disable-next-line react-hooks/rules-of-hooks

  return (<Card className="mb-3">
    <CardBody className="p-4">
      <ExchangeMarketHeader.Wrapper className="d-flex flex-column align-items-start  flex-lg-row justify-content-lg-between">
          <div className="d-flex">
            <Icon
              type="cryptocurrency"
              size="xs"
              code={base}
            />

            <div className="d-flex flex-column my-auto ml-3">
              <ExchangeMarketHeader.Market className="mb-1 text-white font-weight-bold text-nowrap">
                {`${base} / ${quote}`}
              </ExchangeMarketHeader.Market>
              <ExchangeMarketHeader.Coin className="mb-0 opacity-75 font-weight-light ">
                {getCoinName(base)}
              </ExchangeMarketHeader.Coin>
            </div>

            <MarketInfoShare/>

            <div className="d-flex flex-column mr-5">
              <ExchangeMarketHeader.Price className="value">
                {ticker.close || 0}
              </ExchangeMarketHeader.Price>
              <p style={{whiteSpace: 'nowrap'}}
                className={`${setColorClass(ticker.dailyChange, "text-success")} value font-weight-bold `}>
                {ticker.dailyChange > 0 && '+'}{(ticker.dailyChange && exchange === "poloniex") ? ticker.dailyChange * 100 : ticker.dailyChange} %
              </p>
            </div>
          </div>

          <ExchangeMarketHeader.Wrapper className="h-100 d-flex flex-wrap flex-lg-nowrap mb-2 mb-lg-0">
            <ExchangeMarketHeader.Wrapper className="d-flex ">
              <MarketInfoItem className="d-flex flex-column my-auto">
                <p className="font-weight-light title opacity-35">24h Open</p>
                <p className="value text-white font-weight-bold">{ticker.open || 0}</p>
              </MarketInfoItem>

              <MarketInfoItem className="d-flex flex-column my-auto">
                <p className="font-weight-light title opacity-35">24h High</p>
                <p className="value text-white font-weight-bold">{ticker.high || 0}</p>
              </MarketInfoItem>

              <MarketInfoItem className="d-flex flex-column my-auto">
                <p className="font-weight-light title opacity-35">24h Low</p>
                <p className="value text-white font-weight-bold">{ticker.low || 0}</p>
              </MarketInfoItem>
            </ExchangeMarketHeader.Wrapper>

            <ExchangeMarketHeader.Wrapper className="d-flex ">
              <MarketInfoItem className="d-flex flex-column my-auto">
                <p className="font-weight-light title opacity-35">{`24h Volume (${base})`}</p>
                <p className="value text-white font-weight-bold">{ticker.quantity || 0}</p>
              </MarketInfoItem>

              <MarketInfoItem className="d-flex flex-column my-auto ">
                <p className="font-weight-light title opacity-35">{`24h Volume (${quote})`}</p>
                <p className="value text-white font-weight-bold">{ticker.amount || 0}</p>
              </MarketInfoItem>
            </ExchangeMarketHeader.Wrapper>
          </ExchangeMarketHeader.Wrapper>
        </ExchangeMarketHeader.Wrapper>
    </CardBody>
  </Card>);
};

ExchangeMarketHeader.Price = styled.div`
  color: #FFF;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

ExchangeMarketHeader.Market = styled.p`
  font-size: 1.375rem;
  line-height: 19px;
`;

ExchangeMarketHeader.Coin = styled.p`
 font-size: 1rem;
 line-height: 19px;
`;

ExchangeMarketHeader.Wrapper = styled.div`
 gap: 32px;
`;

export default ExchangeMarketHeader;
