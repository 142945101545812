import { useState, useEffect, useCallback } from "react";

const svgCache = {};

const useDynamicSVGImport = (name, type, options = {}) => {
  const [importedIcon, setImportedIcon] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { onCompleted, onError } = options;

  const saveIcon = useCallback((svg) => {
    svgCache[name] = svg.default;
    setImportedIcon(svg.default);
  }, [name]);

  const handleError = useCallback((err) => {
    if (err.message.includes("Cannot find module")) {
      onCompleted?.(name);
    } else {
        console.error("IMPORT ERROR", err.message);
        onError?.(err);
        setError(err);
    }
    onError?.(err);
    setError(err);
  }, [name, onCompleted, onError]);

  useEffect(() => {
    if (importedIcon) setImportedIcon(null);
    if (error) setError(null);
    if (type === "icons") {
      import(`assets/img/icons/${name}.svg`)
        .then(saveIcon)
        .catch(handleError);
    }
    if (type === "exchanges") {
      import(`assets/img/exchanges/${name.replace("us", "")}.svg`)
        .then(saveIcon)
        .catch(handleError);
    } else {
      import(`cryptocurrency-icons/svg/color/${name}.svg`)
        .then(saveIcon)
        .catch(() => import(`assets/img/coins/${name}.svg`)
          .then(saveIcon)
          .catch(handleError)
        )
        .finally(() => setLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, handleError, saveIcon, type, setImportedIcon]);

  return { error, loading, SvgIcon: importedIcon };
};

export default useDynamicSVGImport;
