import React from "react";
import classNames from "classnames";
import styled from "styled-components";
import {Button} from "reactstrap";

const IntervalTabs = ({paymentInterval, setPaymentInterval, currentPlan}) => {
  return (
    <div className="d-flex">
      <IntervalTabs.Button
        className={classNames("mr-1 font-weight-400 font-md d-flex align-items-center justify-content-center", {
          current: paymentInterval === "month"
        })}
        onClick={() => setPaymentInterval("month")}
        currentPlan={currentPlan}
      >
        Monthly
      </IntervalTabs.Button>
      <IntervalTabs.Button
        className={classNames("mr-1 font-weight-400 font-md d-flex align-items-center justify-content-center", {
          current: paymentInterval === "year"
        })}
        onClick={() => setPaymentInterval("year")}
        currentPlan={currentPlan}
      >
        Annually
      </IntervalTabs.Button>
    </div>
  )
}

IntervalTabs.Button = styled(Button)`
  background: #203455 !important;
  height: 40px;
  width: ${({currentPlan}) => currentPlan ? "50px" : "100%"};

  &.btn:hover, &.btn:focus, &.btn:active {
    background: #203455 !important;
  }

  &.btn.current {
    background: #183C6D !important;
    box-shadow: 0 0 0 2px var(--info) !important;
  }

  &.btn.current:hover, &.btn.current:focus, &.btn.current:active {
    background: #183C6D !important;
  }
`;

export default IntervalTabs;