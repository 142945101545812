import { combineReducers } from "redux";

import {
  GET_DEPOSIT_ADDRESS,
  GET_DEPOSIT_AND_WITHDRAWAL_HISTORY
} from "./constants";

export const deposit = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DEPOSIT_ADDRESS:
      return {
        ...payload
      };

    default:
      return state;
  }
};

export const withdrawalHistory = (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DEPOSIT_AND_WITHDRAWAL_HISTORY:
      return [
        ...payload
      ];

    default:
      return state;
  }
};

export default combineReducers({
  deposit,
  withdrawalHistory
});
