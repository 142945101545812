import React from "react";
import {useSelector} from "react-redux";
import {Card, CardBody, CardHeader, Modal} from "reactstrap";
import styled from "styled-components";
import ConditionsMarket from "./ConditionsMarket";
import IndicatorsMarket from "./IndicatorsMarket";
import PivotPointMarket from "./PivotPointMarket";
import Button from "components/Button";
import LineWithError from "shared/ui/LineWithError";
import Icon from "components/Icon";

const MarketSummaryModal = (
  {
    isOpnModal,
    handleToggleModal,
    selectedMarket,
    loadingPage,
    botData
  }) => {

  const analysisMarketData = useSelector((state) => state.bots.technicalAnalysisMarket.data);
  const analysisMarketLoading = useSelector((state) => state.bots.technicalAnalysisMarket.loading);

  return (
    <MarketSummaryModal.Modal
      isOpen={isOpnModal}
      toggle={handleToggleModal}
      modalClassName="modal-black"
    >
      <Card className="m-0">
        <CardHeader className="d-flex align-items-center justify-content-between p-4">
          <div className="d-flex align-items-center">
            <Icon size="botSize" code={selectedMarket?.split("-")[0]}/>
            <p className="mb-0 ml-2 font-weight-500 text-white" style={{fontSize: "22px"}}>
              {selectedMarket?.split("-")[0]}
            </p>
            <p className="mb-0 ml-1 font-weight-300 text-white-35" style={{fontSize: "22px"}}>
              {`/ ${selectedMarket?.split("-")[1]}`}
            </p>
            <MarketSummaryModal.Share/>
            <p className="mb-0 text-white-75">
              Market Technical analysis
            </p>
          </div>
          <Button
            onClick={handleToggleModal}
            cancel
          />
        </CardHeader>

        {
          analysisMarketData.error &&
          <div className="px-4 mb-3">
            <LineWithError errorMessage={analysisMarketData.error} radius="8px"/>
          </div>
        }

        <CardBody className="p-0">
          <ConditionsMarket
            conditions={analysisMarketData.conditions}
            dates={analysisMarketData?.dates?.conditions}
            analysisMarketLoading={analysisMarketLoading}
            loadingPage={loadingPage}
            botData={botData}
          />

          <IndicatorsMarket
            indicators={analysisMarketData.indicators}
            dates={analysisMarketData?.dates?.indicators}
            analysisMarketLoading={analysisMarketLoading}
            loadingPage={loadingPage}
            botData={botData}
          />

          <PivotPointMarket
            pivotPoint={analysisMarketData.pivotPoint}
            pivotPointStateResponse={analysisMarketData?.pivotPointStateResponse}
            analysisMarketLoading={analysisMarketLoading}
            loadingPage={loadingPage}
            botData={botData}
          />
        </CardBody>
      </Card>
    </MarketSummaryModal.Modal>
  )
}

MarketSummaryModal.Modal = styled(Modal)`
  @media (min-width: 992px) {
    max-width: fit-content !important;
    .modal-content {
      width: 900px;
    }
  }

  max-width: 100% !important;
  margin: 1rem auto !important;
  
  .modal-content {
    border-radius: 16px !important;
    overflow: hidden !important;
  }
`;

MarketSummaryModal.Share = styled.div`
  display: block;
  width: 2px;
  height: 32px; 
  background: #363A54;
  margin: 0 24px;
`;

export default MarketSummaryModal;