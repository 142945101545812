import React, {useState} from "react";
import FormGroupWithSymbols from "components/FormGroupWithSymbols";
import {DelayInput} from "react-delay-input";
import {Tooltip} from "reactstrap";

const AllocationInput = (
  {
    realPercent,
    plannedPercent,
    index,
    setFieldValue,
    values,
    initialPlannedCount,
    allCredentialsInvestments,
    enabled
  }) => {
  const [plannedTooltipOpen, setPlannedTooltipOpen] = useState(false);
  const togglePlanned = () => setPlannedTooltipOpen(!plannedTooltipOpen);

  const setPercent = (value, type) => {
    const percent = (percent) => {
      if (percent < 0) return 0;
      if (percent > 100) return 100;
      return percent
    }
    if (type === "planned") {
      return setFieldValue(`credentials[${index}]`,
        {
          ...values.credentials[index],
          plannedPercent: percent(value),
          counter: percent(value) - allCredentialsInvestments[index].plannedPercent
        },
      )
    }

    setFieldValue(`credentials[${index}]`,
      {
        ...values.credentials[index],
        realPercent: percent(value),
        counter: percent(value) - allCredentialsInvestments[index].realPercent
      },
    )

  }

  if (initialPlannedCount) {
    return (
      <div className="d-flex">
        <FormGroupWithSymbols
          symbolRight={
            <span className="text-white-35 mb-0 font-weight-300 cursor-pointer" id="percentInput">%</span>
          }
        >
          <DelayInput
            autoComplete="off"
            delayTimeout={700}
            type="number"
            name="planed"
            className="form-control cursor-pointer"
            style={{width: "80px", height: "29px"}}
            value={String(plannedPercent)}
            onChange={(e) => setPercent(e.target.value, "planned")}
            disabled={!enabled}
          />
        </FormGroupWithSymbols>

        <Tooltip
          placement="top"
          isOpen={plannedTooltipOpen}
          target="percentInput"
          toggle={togglePlanned}
        >
          Planned percent
        </Tooltip>
      </div>
    )
  }

  return (
    <div className="d-flex">
      <FormGroupWithSymbols
        symbolRight={<span className="text-white-35 mb-0 font-weight-300 cursor-pointer" id="percentInput">%</span>}
      >
        <DelayInput
          autoComplete="off"
          delayTimeout={700}
          type="number"
          name="real"
          className="form-control cursor-pointer"
          style={{width: "80px", height: "29px"}}
          value={String(realPercent)}
          onChange={(e) => setPercent(e.target.value)}
          disabled={!enabled}
        />
      </FormGroupWithSymbols>

      <Tooltip
        placement="top"
        isOpen={plannedTooltipOpen}
        target="percentInput"
        toggle={togglePlanned}
      >
        Real percent
      </Tooltip>
    </div>
  )
}

export default AllocationInput;