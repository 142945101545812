import React, {useState} from "react";
import {DelayInput} from "react-delay-input";
import {CardHeader, Tooltip} from "reactstrap";
import styled from "styled-components";
import FormGroupWithSymbols from "components/FormGroupWithSymbols";
import ToggleSwitch from "components/ToggleSwitch";
import Checkbox from "components/Checkbox";

const ManageAllocationHeader = ({
  enabled,
  formik,
  handleToggle,
  hideEmptyAssets,
  setHideEmptyAssets,
  autoRebalanceLoading
}) => {
  const [rebalancingTooltipOpen, setRebalancingTooltipOpen] = useState(false);

  const toggleRebalancing = () => setRebalancingTooltipOpen(!rebalancingTooltipOpen);

  const setPercent = (value) => {
    if (!value) return formik.setFieldValue("rebalancing", null);
    if (value < 1) return formik.setFieldValue("rebalancing", 1);
    if (value > 100) return formik.setFieldValue("rebalancing", 100);
    formik.setFieldValue("rebalancing", value)
  }

  return (
    <ManageAllocationHeader.Header className="p-4 mb-4">
      <div className="d-flex flex-wrap align-items-center justify-content-between px-4">

        <div className="d-flex align-items-center flex-wrap">
          <h2 className="mb-0 mr-3 text-white text-nowrap">
            Manage allocation
          </h2>

          <Checkbox
            name="hideEmpty"
            value={hideEmptyAssets}
            checked={hideEmptyAssets}
            onChange={() => setHideEmptyAssets(!hideEmptyAssets)}
            labelRight={
              <p className="mb-0 ml-1 font-xs text-white-75 text-nowrap">
                Hide empty assets
              </p>
            }
          />
        </div>

        <div className="d-flex align-items-center flex-wrap">
          <p className="mb-0 text-white-75 font-weight-300 mr-2 text-nowrap">
            Auto-rebalancing
          </p>

          <ToggleSwitch
            onChange={(value) => handleToggle(value)}
            checked={enabled}
            height={18}
            width={36}
            onColor="#0AAF95"
            offColor="var(--dusk-steel)"
            onHandleColor="#FFFFFF"
            offHandleColor="#5E6392"
            activeBoxShadow="none"
            className="mr-4"
            disabled={autoRebalanceLoading}
          />

          <div className="d-flex flex-column">
            <FormGroupWithSymbols
              symbolRight={
                <span className="text-white-35 mb-0 ml-2 font-weight-300 cursor-pointer" id="autoRebalancing">%</span>
              }
            >
              <DelayInput
                autoComplete="off"
                delayTimeout={700}
                type="number"
                name="planed"
                className={`form-control ${!formik.values.rebalancing ? "error" : ""}`}
                style={{width: "80px", height: "29px"}}
                value={formik.values.rebalancing}
                onChange={(e) => setPercent(e.target.value)}
                disabled={!enabled}
              />
            </FormGroupWithSymbols>

            {
              !formik.values.rebalancing &&
              <p className="mb-0 font-xs text-danger position-absolute mt-4">
                * Required
              </p>
            }

            <Tooltip
              placement="top"
              isOpen={rebalancingTooltipOpen}
              target="autoRebalancing"
              toggle={toggleRebalancing}
            >
              Deviation parameter at which the automatic rebalancing mechanism is activated
            </Tooltip>
          </div>
        </div>
      </div>
    </ManageAllocationHeader.Header>
  )
}

ManageAllocationHeader.Header = styled(CardHeader)`
  background: #2E3148;
  line-height: 32px;
`;

export default ManageAllocationHeader;
