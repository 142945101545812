import { Modal } from "reactstrap";
import styled from "styled-components";

export const ConfirmationModalStyled = styled(Modal)`
  border-radius: 8px !important;
  overflow: hidden;
  margin: 15% auto !important;
`;

export const ConfirmationWrapperStyled = styled.div`
  background: var(--dark-slate);
`;

export const ConfirmationCancelStyled = styled.div`
  .btn:disabled {
    color: #FFFFFF !important;
  }
`;