import React from "react";
import styled from "styled-components";
import { Alert } from 'reactstrap';

import {ReactComponent as WarningIcon} from "assets/img/icons/Warning.svg";

const ErrorAlert = ({ isOpen, toggle, className, children, isDark=true }) => {
  if (!isOpen) return null;

  return (
    <StyledError
      color="danger"
      className={`p-3 d-flex flex-row align-items-center w-100 ${className}`}
      isOpen={isOpen}
      toggle={toggle}
      isDark={isDark}
    >
      <div  className="mr-4">
        <WarningIcon />
      </div>
      <span className={`${isDark ? "text-white" : "text-dark"} font-sm mr-5`}>
        {children}
      </span>
    </StyledError>
  );
};

const StyledError = styled(Alert)`
  background: rgba(237, 95, 95, 0.1) !important;
  border: 1px solid var(--danger) !important;
  border-radius: 8px;
  word-wrap: anywhere;

  svg path {
    stroke: var(--danger) !important;
  }

  button {
    color: ${(props) => props.isDark ? "#fff" : "#1D1E2A"} !important;
  }
`;

export default ErrorAlert;
