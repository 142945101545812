import React from "react";
import moment from "moment/moment";
import styled from "styled-components";
import {formatterToNull} from "helpers/valuesFormatters";
import { ReactComponent as Buy } from "assets/img/property/Buy.svg";
import { ReactComponent as Sell } from "assets/img/property/Sell.svg";

const TradesTableItem = ({ trade, exchange }) => {
  return (
    <tr className="cursor-pointer">
      {/*Side*/}
      <td>
        {trade.type === "buy"? <Buy
          width="18px"
          height="18px"
        />: <Sell
          width="18px"
          height="18px"
        />}
      </td>
      {/*Order ID*/}
      <td>
        <p className="mb-0 text-white">
          {trade.order?.orderNumber || "-"}
        </p>
      </td>
      {/*Price*/}
      <td>
        <TradesTableItem.Amount amount={trade.rate}>
          {formatterToNull(trade.rate)}
        </TradesTableItem.Amount>
      </td>
      {/*Amount*/}
      <td>
        <div className="d-flex align-trades-center">
          <TradesTableItem.Amount amount={trade.amount?.amount}>
            {formatterToNull(trade.amount?.amount)}
          </TradesTableItem.Amount>
          <span className="text-white opacity-35 ml-1 font-weight-300">
            {trade.amount?.currency}
          </span>
        </div>
      </td>
      {/*Quantity*/}
      <td>
        <div className="d-flex flex-row align-trades-center">
          <p className="mb-0 text-white font-weight-500 mr-1">{trade.quantity?.amount || ""}</p>
          <p className="mb-0 text-white opacity-35 font-weight-300">{trade.quantity?.currency || ""}</p>
        </div>
      </td>
      {/*Fee*/}
      <td>
        <div className="d-flex flex-row align-trades-center">
          <p className="mb-0 text-white mr-1">{formatterToNull(trade.fee?.amount)}</p>
          <span className="text-white opacity-35 font-weight-300">{trade.fee?.currency}</span>
        </div>
      </td>
      {
        exchange !== "binance" &&
          <>
            {/*TradeType*/}
            <td>
              <p className="mb-0 text-white">
                {trade.tradeType || "-"}
              </p>
            </td>
            {/*MatchRole*/}
            <td>
              <p className="mb-0 text-white">
                {trade.matchRole || "-"}
              </p>
            </td>
          </>
      }
      {/*Time*/}
      <td>
        <div className="d-flex align-trades-center">
          <span className="text-white font-weight-400">
            {moment(trade.createdAt).format("DD.MM.YY")}
          </span>
          <TradesTableItem.Amount amount={1} className="ml-1">
            {moment(trade.createdAt).format("HH:mm")}
          </TradesTableItem.Amount>
        </div>
      </td>
      {/*ID*/}
      <td>
        <p className="mb-0 text-white font-weight-300">
          {trade.tradeId}
        </p>
      </td>
    </tr>
  )
}

TradesTableItem.Amount = styled.p`
  font-weight: 500;
  margin-bottom: 0;
  color: ${(props) => props.amount > 0 ? "rgba(255, 255, 255)" : "rgba(255, 255, 255, 0.35)"} !important;
  white-space: nowrap;
`;

export default TradesTableItem;


