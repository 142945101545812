import React, {useLayoutEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {Card, CardBody, CardHeader} from "reactstrap";
import {ReactComponent as TradeStatistics} from "assets/img/icons/tradeStatistics.svg";
import {Bar} from "react-chartjs-2";
import {getDailyIncome, resetDailyIncome} from "store/bots/actions";
import {drawBotDailyIncome} from "charts";
import TradeStatisticsChartPreload from "../../investments/preloaders/TradeStatisticsChartPreload";
import Error from "components/Error";
import EmptyDealsStatisticsChart from "../compounds/EmptyDealsStatisticsChart";
import {CHART_GROUP_BUTTONS} from "charts/enum";
import ButtonsGroup from "components/ButtonsGroup";

const TradeStatisticChart = ({loadingPage, id}) => {
  const [period, setPeriod] = useState("day");

  const dispatch = useDispatch();

  const dailyIncome = useSelector((state) => state.bots.dailyIncomeChart.data);
  const loading = useSelector((state) => state.bots.dailyIncomeChart.loading);
  const error = useSelector((state) => state.bots.dailyIncomeChart.error);

  useLayoutEffect(() => {
    dispatch(getDailyIncome(id, "day"));
    return () => dispatch(resetDailyIncome());
  }, [id, dispatch]);

  const totalDataChart = dailyIncome?.data?.reduce((acc, item) => acc + +item, 0);

  if (loading || loadingPage) return <TradeStatisticsChartPreload/>;

  return (
    <TradeStatisticChart.Card>
      <CardHeader className="p-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <TradeStatistics alt=""/>
            <p className="mb-0 text-white ml-2 font-weight-500">
              Trade statistic
            </p>
          </div>
          <ButtonsGroup
            group={CHART_GROUP_BUTTONS}
            current={period}
            setCurrent={setPeriod}
            callFunc={getDailyIncome}
            params={id}
            disable={loading}
          />
        </div>
      </CardHeader>
      <CardBody className="p-4">
        {totalDataChart === 0 ? <EmptyDealsStatisticsChart/> :
          <div className="chart-area h-100 d-flex flex-column justify-content-center text-center">
            {
              !error && !loading ?
                <Bar
                  data={drawBotDailyIncome(dailyIncome).data}
                  options={drawBotDailyIncome(dailyIncome).options}
                /> : null
            }
            {error ? <Error error={error}/> : null}
          </div>}
      </CardBody>
    </TradeStatisticChart.Card>
  );
};

TradeStatisticChart.Card = styled(Card)`
  height: 420px;
  margin-bottom: 24px !important;
`;

export default React.memo(TradeStatisticChart);
