import React, {useEffect, useCallback} from "react";
import { Switch, useHistory} from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { RECAPTCHA_API_KEY } from "env-create-react-app";
import qs from "qs";

import AuthLayout from "./layouts/AuthLayout";
import LoginPage from "./auth/LoginPage";
import RegisterPage from "./auth/RegisterPage";
import ForgotPassword from "./auth/ForgotPassword";
import ChangePassword from "./auth/ChangePassword";
import Success from "./auth/Success";
import Route from "./Route";

const AuthRoute = () => {

  const history = useHistory();
  const getRefCode = useCallback(() => {
    const {ref} = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    if(ref){
      localStorage.setItem("registerReferral", ref);
      return
    }
  },[history.location.search])

  useEffect(() => {
    getRefCode()
  }, [getRefCode]);

  if (localStorage.getItem("token")) {
    history.push("/dashboard");
    return null;
  }

  return (
    <AuthLayout>
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_API_KEY}>
        <Switch>
          <Route exact path={["", "/login"]} component={LoginPage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/change-password" component={ChangePassword} />
          <Route exact path="/success" component={Success} />
        </Switch>
      </GoogleReCaptchaProvider>
    </AuthLayout>
  );
};

export default AuthRoute;
