import React, { useState } from "react";

import styled from "styled-components";
import Copy from "components/svg/Copy";
import Hint from "components/Hint";

const IdWithCopy = ({ 
  id, 
  dataTestId="id-with-copy", 
  ...props 
}) => {
  const [isCopied, setIsCopied] = useState("");

  const handleCopy = (e) => {
    e.stopPropagation();
    e.preventDefault();
    navigator.clipboard.writeText(id).then(() => {
      setIsCopied("Copied!");
      setTimeout(() => {
        setIsCopied("");
      }, 1000);
    });
  };

  return (
    <IdWithCopy.Wrapper 
      data-testid={`${dataTestId}-wrapper`}
      {...props} 
    >
      <p data-testid={`${dataTestId}-id`}>{id}</p>
      <div className="position-relative">
        <div className="d-flex align-items-center">
          <button 
            className="btn btn-reset mt-0 mb-2 pr-2 ml-2 text-warning" 
            data-testid={`${dataTestId}-button`}
            onClick={handleCopy}>
            <Copy/>
          </button>
          {
            isCopied &&
            <Hint data-testid={`${dataTestId}-hint`}>
              {isCopied}
            </Hint>
          }
        </div>
      </div>
    </IdWithCopy.Wrapper>
  );
};

IdWithCopy.Wrapper = styled.div`
  display: flex;
  position: relative;

  svg {
    &:hover {
      path {
        stroke: #FFFFFF !important;
      }
    }
  }

  .btn {
    margin-bottom: 0 !important;
  }

  .btn:focus, .btn:active, .btn:active:focus {
    background: none !important;
  }

  p {
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    color: rgba(255, 255, 255, 0.35) !important;
    text-transform: uppercase;
    margin-bottom: 0;
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default IdWithCopy;
