import React, {
  useRef,
  useState
} from "react";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";

import ChannelLogo from "components/ChannelLogo";
import EditButton from "components/EditButton";
import { uploadChannelLogo } from "store/channels/actions";
import { addSnackBar } from "store/snackbar/actions";

const MAX_FILE_SIZE = 1024 * 1024;

const ChangeLogoChannel = ({
  channel,
  loading
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const inputEl = useRef(null);
  const dispatch = useDispatch()

  const resetFileInput = () => {
    inputEl.current.value = null;
  };

  const handleUploadAvatar = async (e) => {
    const file = e.target.files[0];
    if (file.size > MAX_FILE_SIZE) {
      dispatch(addSnackBar("error", "File size is too large."));
      resetFileInput();
      return;
    };
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file, file.name);
    const res = await dispatch(uploadChannelLogo(channel?.id, formData));
    if (!res.errors) {
      dispatch(addSnackBar("success", res.message));
    } else {
      dispatch(addSnackBar("error", res.message));
    }
    resetFileInput();
    setIsLoading(false);
  };
  return (
    <ChangeLogoChannel.WrapEditIcon
      className="position-relative"
      isOwner={channel?.owner}
      isLoading={isLoading}
      onClick={() => !isLoading
        ? inputEl.current.click()
        : null
      }
    >
      <ChannelLogo
        logo={channel?.logos?.small}
        size={80}
        loading={loading}
        className="channel-logo"
      />
      <div className="channel-loader position-absolute">
        <ClipLoader color="var(--info)" size={40} loading={isLoading} />
      </div>
      {
        channel?.owner
          ? <>
            <input
              type="file"
              ref={inputEl}
              onChange={handleUploadAvatar}
              className="d-none"
            />
            {
              !isLoading ?
                <EditButton
                  type="pencil"
                  className="channel-edit position-absolute"
                  bgColor="var(--dark-slate)"
                  hoverColor="var(--dark-slate)"
                />
                : null
            }
          </>
          : null
      }
    </ChangeLogoChannel.WrapEditIcon>
  );
};

ChangeLogoChannel.WrapEditIcon = styled.div`
  .channel-edit{
    display:none;
  }

  .channel-edit,
  .channel-loader{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${(props) =>
    props.isLoading &&
    `
    .channel-logo img {
        cursor: pointer;
        opacity: 0.5;
      }`
  }

  ${(props) =>
    props.isOwner &&
    `
    &:hover {
      .channel-logo img {
        cursor: pointer;
        opacity: 0.5;
      }

      .channel-edit {
        display: block;
      }
    }`
  }
`;

export default ChangeLogoChannel;