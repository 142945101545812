import React from "react";
import {
  Card,
  CardBody
} from "reactstrap";
import SkeletonPreloader from "components/SkeletonPreloader";
import StyledPreloader from "components/StyledPreloader";

const HeaderPreloader = () => (
  <Card>
    <CardBody>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <SkeletonPreloader
            circle={true}
            height={58}
            width={58}
          />
          <div className="d-flex flex-column ml-4">
            <SkeletonPreloader
              height={20}
              width={110}
              className="my-auto"
            />
            <SkeletonPreloader
              height={15}
              width={200}
              className="my-auto d-md-none"
            />
            <SkeletonPreloader
              height={15}
              width={273}
              className="my-auto d-none d-md-block"
            />
          </div>
        </div>
        <div className="d-none d-md-flex">
          <div className="d-flex flex-column mr-4">
            <SkeletonPreloader
              height={24}
              width={90}
              className="my-auto"
            />
            <SkeletonPreloader
              height={10}
              width={94}
              className="my-auto"
            />
          </div>
          <StyledPreloader style={{ width: "48px", height: "100%", borderRadius: "6px" }} />
        </div>
      </div>
    </CardBody>
  </Card>
);

export default HeaderPreloader;
