import React, { useState, useCallback } from "react";
import styled from "styled-components";
import classNames from "classnames";
/* eslint-disable complexity */

const Wizard = ({ steps, finishButtonClick }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [highestStep, setHighestStep] = useState(0);

  const handleGoNextStep = useCallback(() => {
    const key = currentStep + 1;
    if (key !== steps.length) {
      setCurrentStep(key);
    }
    const currentHighestStep = key > highestStep ? key : highestStep;
    setHighestStep(currentHighestStep);
  }, [steps, currentStep, setCurrentStep, setHighestStep, highestStep]);

  const handleGoBack = useCallback(() => {
    const key = currentStep - 1;
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1);
    }
    const currentHighestStep = key > highestStep ? key : highestStep;
    setHighestStep(currentHighestStep);
  }, [currentStep, setCurrentStep, setHighestStep, highestStep]);

  return(
    <>
      {
        steps.map((step, index) =>
          <div className="d-flex flex-column" key={step.name}>
            <div className="d-flex">
              <Wizard.StepItem
                onClick={() => {
                  if (index <= highestStep) {
                    setCurrentStep(index);
                  }
                }}
                className={`
                  d-flex flex-column justify-content-center text-center
                  ${
                    currentStep !== index && index < highestStep  ?
                    "success-step" : currentStep === index ?
                    "info-step" : "muted-step"
                  }`
                }
              >
                {
                  currentStep !== index && index < highestStep ?
                  <i className="tim-icons my-auto icon-check-2" />
                  :
                  index + 1
                }
              </Wizard.StepItem>
              <h2 className="ml-4 my-auto">
                {step.name}
              </h2>
            </div>
            <Wizard.StepWrapper
              className={classNames("info-step-border d-none", {
                "d-block": currentStep === index
              })}
            >
              <step.component
                confirm={currentStep + 1 === steps.length ? finishButtonClick : () => {}}
                goNextStep={handleGoNextStep}
                goBack={currentStep === 0 ? () => {} : handleGoBack}
                {...step.props}
              />
            </Wizard.StepWrapper>
            {
              currentStep !== index && index >= highestStep ?
              <Wizard.EmptyStep /> : null
            }
            {
              currentStep !== index && index < highestStep ?
              <Wizard.StepWrapper className="success-step-border">
                {
                  step.finishedText ?
                  <p className="text-white-35">
                    {step.finishedText}
                  </p>
                  :
                  <p className="text-white-35">
                    Success!
                  </p>
                }
              </Wizard.StepWrapper>
              : null
            }
          </div>
        )
      }
    </>
  );
};

Wizard.StepWrapper = styled.div`
  margin: 20px 23px;
  padding-left: 50px;

  &.success-step-border {
    border-left: 1px solid #00f2c3;
  }

  &.info-step-border {
    border-left: 1px solid #1d8cf8;
  }
`;

Wizard.EmptyStep = styled.div`
  margin: 20px 23px;
  padding-left: 50px;
  border-left: 1px solid #6c757d;
  height: 72px;
`;


Wizard.StepItem = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  cursor: pointer;

  &.success-step {
    background: rgba(63, 203, 176, 0.1);
    border: 1px solid #00f2c3;
    color: #00f2c3;
  }

  &.info-step {
    background: rgba(29, 140, 248, 0.1);
    border: 1px solid #1d8cf8;
    color: #1d8cf8;
  }

  &.muted-step {
    background: rgba(242, 242, 242, 0.1);
    border: 1px solid #6c757d;
    color: #6c757d;
  }
`;

export default Wizard;
