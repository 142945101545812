import React from "react";
import styled from "styled-components";
import SkeletonPreloader from "components/SkeletonPreloader";
import _ from "lodash";

const TableOrderModalLoader = () => {
  return (
    <>
      <TableOrderModalLoader.Header>
        {
          _.times(6, (e) => {
            return <SkeletonPreloader
              height={12}
              width={100}
              key={e}
            />
          })
        }
      </TableOrderModalLoader.Header>
      {
        _.times(10, (e) => {
          return <TableOrderModalLoader.Body key={e}>
            {
              _.times(6, (e) => {
                return <SkeletonPreloader
                  height={12}
                  width={100}
                  key={e}
                />
              })
            }
          </TableOrderModalLoader.Body>
        })
      }
    </>
  )
}

TableOrderModalLoader.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #2A2C42;
  padding: 8px 24px;
`;

TableOrderModalLoader.Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--dark-slate);
  padding: 16px 24px;
`;

export default TableOrderModalLoader;
