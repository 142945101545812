import React from "react";
import * as Sentry from "@sentry/react";

import OrdersHistory from "./OrdersHistory";
import TradesHistory from "./TradesHistory";
import FallBack from "components/FallBack";
import Conditions from "../Overview/Conditions";

const HistoryTab = ({ id, credId, market, loadingPage }) => {
  return (
    <>
      <Sentry.ErrorBoundary fallback={<FallBack title="Conditions" />}>
        <Conditions
          id={id}
        />
      </Sentry.ErrorBoundary>
      <OrdersHistory
        id={id}
        credId={credId}
        market={market}
        loadingPage={loadingPage}
      />

      <TradesHistory
        id={id}
        credId={credId}
        market={market}
        loadingPage={loadingPage}
      />
    </>
  )
};

export default HistoryTab;