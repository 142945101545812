import React from "react";
import {formatterToNull} from "helpers/valuesFormatters";
import styled from "styled-components";
import Icon from "components/Icon";

const ExchangeTableRowModal = ({item}) => {
  return (
    <ExchangeTableRowModal.Row>
      <td>
        <div className="d-flex align-items-center">
          <Icon code={item.asset} size="botSize"/>
          <p className="mb-0 ml-2 text-uppercase text-white font-weight-500">{item.asset}</p>
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center">
          <p className="mb-0 text-white font-weight-500">
            {formatterToNull(item.available.amount)}
          </p>
          <p className="mb-0 ml-1 text-white-35 font-weight-300">
            {item.available.currency}
          </p>
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center">
          <p className="mb-0 text-white font-weight-500">
            {formatterToNull(item.hold.amount)}
          </p>
          <p className="mb-0 ml-1 text-white-35 font-weight-300">
            {item.hold.currency}
          </p>
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center">
          <p className="mb-0 text-white font-weight-500">
            {formatterToNull(item.estimated.amount)}
          </p>
          <p className="mb-0 ml-1 text-white-35 font-weight-300">
            {item.estimated.currency}
          </p>
        </div>
      </td>
    </ExchangeTableRowModal.Row>
  )
}

ExchangeTableRowModal.Row = styled.tr`
  background: var(--dark-slate) !important;
  cursor: pointer;
  height: 56px;
  box-shadow: 0 -1px #2E3148;
  
  
  &:hover {
    background: #2E3148 !important;
  }
`;

export default ExchangeTableRowModal;