import React, {
  useCallback,
  useLayoutEffect
} from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import {Card} from "reactstrap";
import {DelayInput} from "react-delay-input";
import styled from "styled-components";
import qs from "qs";

import LookupCoins from "components/LookupCoins";
import PaginationPanel from "components/PaginationPanel";
import FormGroupWithSymbols from "components/FormGroupWithSymbols";
import {ReactComponent as SpotWalletIcon} from "assets/img/icons/SpotWalletIcon.svg";
import {ReactComponent as Search} from "assets/img/icons/search.svg";
import ColorfulBar from "components/ColorfulBar";
import {dynamicRgbaColors} from "charts/helpers";
import SpotWalletWidget from "./spotWalletWidget";
import ExchangeTableModal from "../compounds/exchangeBalances/ExchangeTableModal";
import {fetchBalances} from "store/credentials/actions";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";

const SpotWalletPagesView = ({
  balances, 
  estimated, 
  id, 
  request, 
  data, 
  loadingPage
}) => {
  const dispatch = useDispatch();
  const itemsWithPercentCount = balances?.items?.reduce((acc, item) => item.percent ? acc + 1 : acc + 0, 0);
  const {handlePaginate, handleSorting} = useFiltersWithRequest(balances?.request, fetchBalances, id);
  const loadingBalances = useSelector((state) => state.credentials.balances.loading);

  const handleSearch = useCallback((value) => {
    const filter = qs.stringify({
      sort: request?.sort || [],
      filter: {asset: {like: value}},
      limit: 100
    });

    dispatch(fetchBalances(id, `?${filter}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    const filter = qs.stringify({
      sort: {by:"percent", order: "DESC"},
      limit: 10,
    });
    dispatch(fetchBalances(id, `?${filter}`));
  }, [dispatch, id]);


  return (
    <SpotWalletPagesView.Card className="d-flex flex-column pt-0">
      <SpotWalletPagesView.Header className="px-4 pt-3 mb-0">
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-sm-row align-items-sm-center w-100">
            <div className="d-flex align-items-center text-nowrap">
              <SpotWalletIcon/>
              <p className="mb-0 ml-2 mr-4 text-white font-weight-500">
                Spot wallet
              </p>
            </div>
          </div>
          <div className="mt-3 mt-sm-0 searchInput">
            <FormGroupWithSymbols symbolLeft={<Search className="search"/>}>
              <DelayInput
                autoComplete="off"
                delayTimeout={700}
                type="string"
                name="search"
                className="form-control w-100"
                placeholder="Search coins"
                onChange={({target}) => handleSearch(target.value)}
              />
            </FormGroupWithSymbols>
          </div>
        </div>
        <SpotWalletPagesView.Wrapper className="color-bar mt-4 mb-3">
          {
           !loadingBalances && balances?.items?.map((item, index) => {
              if(item.asset.includes("1000")) return ("A" + item.asset);
              if (!item.percent) return null;
              return (
                <ColorfulBar
                  key={item?.asset}
                  title={item?.asset}
                  value={item?.percent}
                  itemsLength={balances?.items?.length}
                  itemsWithValueLength={itemsWithPercentCount}
                  index={index}
                  tooltipText={<LookupCoins coin={item?.asset}/>}
                  withSpacing
                  color={dynamicRgbaColors()}
                />
              )
            })
          }
        </SpotWalletPagesView.Wrapper>
          <SpotWalletWidget
            balances={balances?.items?.length}
            amount={`${estimated?.amount || 0}`}
            currency={estimated?.currency}
            btc={data?.balance?.btc}
            loadingPage={loadingPage}
          />
        </SpotWalletPagesView.Header>
        <ExchangeTableModal
          items={balances?.items}
          request={request}
          handleSorting={handleSorting}
        />
        <div>
          <PaginationPanel
            activePage={balances?.page}
            totalItemsCount={balances?.total}
            onChange={(page) => handlePaginate(page)}
          />
        </div>
    </SpotWalletPagesView.Card>
  )
};

SpotWalletPagesView.Header = styled(Card)`
  background: #2A2C42 !important;
`;

SpotWalletPagesView.Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items:center;
`;

SpotWalletPagesView.Card = styled(Card)`
  .search {
    path {
      stroke: rgba(255, 255, 255, 0.35);
    }
}
`;

export default SpotWalletPagesView;
