import React, { useState } from "react";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Button from "components/Button";

import ToggleCard from "components/ToggleCard";
import { deleteStrategy } from "store/strategies/actions";
import { ReactComponent as Warning } from "assets/img/icons/Warning.svg";
import DangerZonePreloader from "components/DangerZonePreloader";
import { ReactComponent as Paired } from "assets/img/icons/Paired.svg";

const DangerZone = ({
  deleteStrategy,
  id,
  name,
  loading,
  botsAmount
}) => {
  const history = useHistory();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleDeleteStrategy = async () => {
    const errors = await deleteStrategy(id);
    if (!errors) { history.push("/strategies"); }
  };

  if (loading) {
    return (<DangerZonePreloader />);
  }

  return(
    <>
      <ToggleCard
        header={
          <div className="d-flex">
            <img
              src={Warning}
              alt="..."
              className="mr-2"
            />
            <p className="h4 m-0">Danger zone</p>
          </div>
        }
      >
        <p className="text-white-35">
          To delete a strategy, first you need to
          detach it from all the bots to which it was attached
        </p>
        <p className="text-white-35">
          In order to detach the strategy on the bot page in the
          strategy widget, you need to click the `change`
          button and select another strategy
        </p>
        {
          botsAmount ?
          <>
            <DangerZone.Warning className="p-2 d-flex justify-content-center mt-4 mb-2">
              <Paired className="my-auto mr-2" />
              <p className="my-auto text-white-35">
                Strategy attached to:{" "}
                <span className="text-white">
                  {botsAmount} bots
                </span>
              </p>
            </DangerZone.Warning>
            <p className="text-danger font-sm">
              To delete this strategy please detach it from bots
            </p>
          </>
          :
          <div className="d-flex mt-3">
            <Button
              color="red"
              onClick={() => setModalIsOpen(!modalIsOpen)}
            >
              Delete
            </Button>
          </div>
        }

      </ToggleCard>
      {
        modalIsOpen &&
        <ReactBSAlert
          warning
          title="Delete strategy"
          onConfirm={handleDeleteStrategy}
          onCancel={() => setModalIsOpen(!modalIsOpen)}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Delete"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          Are you sure want to manually delete {name}?
        </ReactBSAlert>
      }
    </>
  );
};

DangerZone.Warning = styled.div`
  border-radius: 4px;
  background: rgba(253, 93, 147, 0.1);
  border: 1px solid rgba(253, 93, 147, 0.2);
`;

const mapDispatchToProps = (dispatch) => bindActionCreators({ deleteStrategy }, dispatch);

export default connect(null, mapDispatchToProps)(DangerZone);
