import { get } from "lodash";
import {
  GET_ME_LOADING,
  GET_ME_SUCCESS,
  GET_ME_FAILURE,
  RESET_ME,
  GET_EMAIL,
  DISCONNECT_TELEGRAM,
  CONNECT_TELEGRAM,
  SET_UNREAD_NOTIFICATIONS,
  CONNECT_GOOGLE_ACCOUNT,
  SET_BALANCE,
  UPDATE_BETA_SETTINGS,
  DISCONNECT_GOOGLE_ACCOUNT,
  GET_AVATAR_LOADING,
  GET_AVATAR_SUCCESS,
  GET_AVATAR,
  GET_AVATAR_FAILURE,
  DELETE_AVATAR_SUCCESS,
  DELETE_AVATAR_LOADING,
  SKIP_WIZARD,
  RECORD_CREDENTIALS_TO_WIZARD,
  RECORD_BOTS_TO_WIZARD,
  SET_EMAIL,
  UPDATE_WIZARD,
  GENERATE_REF_CODE,
  GET_USERS_REFERRALS_LOADING,
  GET_USERS_REFERRALS,
  GET_REFERRALS_STATISTICS,
  GET_REFERRALS_STATISTICS_LOADING
} from "./constants";

export const fetchMe = () => async(dispatch, getState, fetch) => {
  try {
    dispatch({ type: GET_ME_LOADING });
    const res = await fetch.get("/app/me");
    dispatch({ type: GET_AVATAR, payload: res?.data?.avatarLoading });
    dispatch({ type: GET_ME_SUCCESS, payload: res.data });
  } catch (e) {
    dispatch({ type: GET_ME_FAILURE, payload: e.message });
  }
};

export const resetMe =  () => ({ type: RESET_ME });

export const editUser = (body) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.patch("/app/user/profile", body);
    dispatch({ type: GET_ME_SUCCESS, payload: res.data.item });
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const checkEmail = () => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.get("/app/user/email/check");
    if (res.data) { dispatch({ type: GET_EMAIL, payload: res.data }); }
    return res;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const changeEmail = (body) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/app/user/email/change", body);
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const resendEmail = () => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.post(`/app/change-email-requests/resend`);
    return res.data
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const resendConfirmEmail = () => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.post(`/app/users/resend-confirmation-email`);
    return res.data
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const cancelEmail = () => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.delete(`/app/user/change-email-requests`);
    dispatch({type: GET_EMAIL, payload: null})
    return res.data
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const confirmEmail = (secret) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/app/user/email/confirm", secret);
    dispatch({type: SET_EMAIL, payload: res.data.refreshToken.username})
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const changePassword = (body) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/app/user/password/change", body);
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const uploadAvatar = (body) => async(dispatch, getState, fetch) => {
  try {
    dispatch({ type: GET_AVATAR_LOADING});
    const res = await fetch.post("/app/avatar", body);
    dispatch({ type: GET_AVATAR_SUCCESS, payload: res.data.avatars });
    return res.data;
  } catch (e) {
    dispatch({ type: GET_AVATAR_FAILURE});
    return get(e, "response.data", { errors: [], message: e.response.status || "Internal server error" });
  }
};

export const deleteAvatar = () => async(dispatch, getState, fetch) => {
  try {
    dispatch({ type: DELETE_AVATAR_LOADING });
    const res = await fetch.delete("/app/avatar");
    dispatch({ type: DELETE_AVATAR_SUCCESS });
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

export const connectTelegram = (body) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/app/telegram", body);
    dispatch({ type: CONNECT_TELEGRAM });
    return res.data;
  } catch (e) {
    return get(e, "response", { errors: [], status: e.status, message: e.message });
  }
};

export const disconnectTelegram = () => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.delete("/app/telegram");
    dispatch({ type: DISCONNECT_TELEGRAM });
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const sendTestMessageTelegram = () => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/app/telegram/test");
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const connectGoogleAccount = (body) => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/app/users/google", body);
    await dispatch({ type: CONNECT_GOOGLE_ACCOUNT });
    return res.data;
  } catch (e) {
    return get(e, "response", { errors: [], message: e.response.data.message, status: e.response.status });
  }
};

export const disconnectGoogleAccount = () => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.delete("/app/users/google");
    await dispatch({ type: DISCONNECT_GOOGLE_ACCOUNT, payload: null });
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

export const generateRefCode = () => async(dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/app/referral");
    await dispatch ({type: GENERATE_REF_CODE});
    return res.data;
  } catch (e) {
    return get(e, "response", { errors: [], message: e.response.data.message, status: e.response.status });
  }
};

export const getReferralStatistics = () => async(dispatch, getState, fetch) => {
  try {
    dispatch({type: GET_REFERRALS_STATISTICS_LOADING});
    const res= await fetch.get("/app/referrals/statistics");
    if(res.data) {dispatch({type: GET_REFERRALS_STATISTICS})};
    return res.data
  }
  catch(e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
}

export const getUsersReferrals = (filters) => async(dispatch, getState, fetch) => {
  try {
    dispatch({type: GET_USERS_REFERRALS_LOADING});
    const res = await fetch.get(`app/referrals${filters || ""}`);
    if (res.data) { dispatch({ type: GET_USERS_REFERRALS, payload: res.data}); }
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const updateBetaSettings = (body) => async(dispatch, getState, fetch) => {
  try {
    dispatch({ type: UPDATE_BETA_SETTINGS, payload: body.isBetaEnabled});
    const res = await fetch.patch("/app/user/beta", body);
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

export const updateWizard = (data) => async(dispatch, getState, fetch) => {
  try {
    await fetch.put('/app/wizard', data);
    dispatch({ type: UPDATE_WIZARD, payload: data });
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const setUnreadNotifications = (payload) => ({ type: SET_UNREAD_NOTIFICATIONS, payload });

export const setBalance = (payload) => ({ type: SET_BALANCE, payload });

export const skipWizard = () => async(dispatch, getState, fetch) => {
  try {
    await fetch.post('/app/skip-wizard');
    dispatch({ type: SKIP_WIZARD });
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const recordWizardCredentials = () => ({ type: RECORD_CREDENTIALS_TO_WIZARD });
export const recordWizardBots = () => ({ type: RECORD_BOTS_TO_WIZARD });
