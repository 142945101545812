import React from "react";
import {useSelector} from "react-redux";
import Icon from "components/Icon";
import styled from "styled-components";

const MarketLabelStep = () => {

  const {investment, allCoins, excludedCoins, coins} = useSelector((state) => state.botWizard);

  if (!allCoins && coins.length === 1) {
    return (
      <>
        <p className="mb-0 text-white font-weight-500">{coins}</p>
        <p className="mb-0 mx-1 text-white-35 font-weight-300 text-nowrap">
          {`/ ${investment?.asset}`}
        </p>
        <Icon
          type="cryptocurrency"
          size="thumb"
          code={investment?.asset}
          className="ml-1"
        />
      </>
    )
  }

  if (!allCoins && coins.length > 1) {
    return (
      <MarketLabelStep.Badge>
        <p className="mb-0 text-white font-weight-600">{coins.length} selected</p>
      </MarketLabelStep.Badge>
    )
  }
  if (allCoins && !excludedCoins.length) {
    return (
      <MarketLabelStep.Badge>
        <p className="font-weight-600 mb-0 text-white">ALL</p>
      </MarketLabelStep.Badge>
    )
  }
  if (allCoins && excludedCoins.length) {
    return (
      <div className="d-flex align-items-center">
        <MarketLabelStep.Badge>
          <p className="font-weight-600 mb-0 text-white">
            ALL
          </p>
        </MarketLabelStep.Badge>
        <MarketLabelStep.Badge bgc="red" className="ml-1">
          <p className="font-weight-600 mb-0 text-white">
            -{excludedCoins.length} excluded
          </p>
        </MarketLabelStep.Badge>
      </div>
    )
  }
}

MarketLabelStep.Badge = styled.div`
  background: ${(props) => props.bgc === "red" ? "rgba(237, 95, 95, 0.1)" : "rgba(255, 255, 255, 0.03)"};
  border-radius: 6px;
  padding: 4px 8px;
`;

export default MarketLabelStep;
