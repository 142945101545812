import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";

import Wizard from "components/Wizard";
import StrategyView from "./StrategyView";
import StrategySettings from "./StrategySettings";
import {
  createStratedy,
  resetWizardIndicators
} from "store/strategies/actions";
import WizardErrors from "./WizardErrors";
import { wizardIndicatorsSelector } from "store/strategies/selectors";
import { handleResponse } from "helpers/errorsValidator";

const CreateStrategy = ({ createStratedy, resetWizardIndicators }) => {
  const indicatorsList = useSelector((state) => wizardIndicatorsSelector(state));
  const [generalInformation, setGeneralInformation] = useState(null);
  const [reqErrors, setReqErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [redirectPath, setRedirectPath] = useState("/");

  useEffect(() => {
    return () => resetWizardIndicators();
  }, [resetWizardIndicators]);

  const handleSubmit = async (settingsData) => {
    setSubmitting(true);
    const body = {
      indicators: indicatorsList.map(({id, details, ...indicatorProps}) => indicatorProps),
      ...generalInformation,
      ...settingsData
    }
    const res = await createStratedy(body);
    if (res.errors) {
      handleResponse(res, setReqErrors);
    } else {
      resetWizardIndicators();
      setSuccessMessage({ message: res.message });
      setRedirectPath(`/strategies/${res.item.uuid}`);
    }
    setAlertOpen(true);
    setSubmitting(false);
  };

  return (
    <>
      <WizardErrors
        errors={reqErrors}
        status={successMessage}
        alertOpen={alertOpen}
        onSetAlertOpen={setAlertOpen}
        redirectPath={redirectPath}
      />
      <Wizard
        steps={[
          {
            component: StrategyView,
            name: "Strategy view",
            finishedText: true,
            props: {
              setGeneralInformation,
              reqErrors
            }
          },
          {
            component: StrategySettings,
            name: "Strategy settings",
            finishedText: true,
            props: {
              submitting,
              reqErrors
            }
          }
        ]}
        finishButtonClick={handleSubmit}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createStratedy,
  resetWizardIndicators
}, dispatch);

export default connect(null, mapDispatchToProps)(CreateStrategy);
