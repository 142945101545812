import React, { useState } from "react";

import PropTypes from "prop-types";

import TradeRequestHeader from "../ui/TradeRequestHeader";
import TradeRequestPreloader from "views/agreements/preloaders/TradeRequestPreloader";
import TradeRequestCard from "../ui/TradeRequestCard";
import TradeRequestsDetailsModal from "../TradeRequestsDetailsModal";

const OpenTradeRequest = ({
  data,
  loadingPage
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpenItem, setModalOpenItem] = useState(null);

  const handleOpenModal = (item) => {
    setModalOpenItem(item);
    setIsModalOpen(true);
  };

  if (loadingPage) return <TradeRequestPreloader count={1} className="mb-4" />;

  return (
    <>
      <TradeRequestHeader
        title="Open trade request"
      />
      <TradeRequestCard
        key={data?.id}
        data={data}
        goal={data?.goal}
        className="mb-sm"
        handleOpenModal={handleOpenModal}
      />
      {
        isModalOpen &&
        <TradeRequestsDetailsModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          modalOpenItem={modalOpenItem}
          idAgreement={data?.id}
        />
      }
    </>
  );
};

OpenTradeRequest.propTypes = {
  id: PropTypes.string,
};

export default OpenTradeRequest;