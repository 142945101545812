import React from "react";
import styled from "styled-components";
import { times } from "lodash";
import {
  CardBody,
  Card
} from "reactstrap";

const FiltersPreloader = ({ fieldsAmount=1, preloadersClassName="" }) => {
  return (
    <Card className="mb-4" style={{borderRadius: "8px"}}>
      <CardBody className="p-4">
        <div className="d-flex justify-content-between">
          {
            times(fieldsAmount, (e) =>
              <FieldPreload
                key={e}
                className={preloadersClassName}
              />
            )
          }
        </div>
      </CardBody>
    </Card>
  );
};

const FieldPreload = styled.div`
  background-color: #303245;
  border-radius: 6px;
  width: 100%;
  height: 38px;
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

export default FiltersPreloader;
