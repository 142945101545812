import styled from "styled-components";

import LookupCoins from "components/LookupCoins";
import Icon from "components/Icon";

export const SideColumn = ({asset, side}) => {
  return (side === "left" ?
    <ColumnFlex gap="16px" items="center">
      <ColumnFlex direction="column" content="center" items="end" className="two">
        <Header>{asset}</Header>
        <Footer><LookupCoins coin={asset}/></Footer>
      </ColumnFlex>
      <Icon
        type="cryptocurrency"
        size="xxs"
        code={asset}
        className="one"
      />
    </ColumnFlex> :
    <ColumnFlex gap="16px" items="center">
      <Icon
        type="cryptocurrency"
        size="xxs"
        code={asset}
        className="one"
      />
      <ColumnFlex direction="column" content="center" className="two">
        <Header>{asset}</Header>
        <Footer>
          <LookupCoins coin={asset}/>
        </Footer>
      </ColumnFlex>
    </ColumnFlex>);
};

export const ColumnFlex = styled.div`
  display: flex;
  flex-direction: ${({direction}) => direction};
  align-items: ${({items}) => items};
  gap: ${({gap}) => gap};
  justify-content: ${({content}) => content};

  @media (max-width: 768px) {
    &.one {
      order: 1;
    }

    &.two {
      order: 2;
      align-items: flex-start;
    }
  }
`;

export const Footer = styled.span`
  color: rgba(255, 255, 255, 0.35);
  font-feature-settings: 'liga' off;
  font-style: normal;
  line-height: normal;
`;

export const Header = styled.span`
  color: #FFF;
  font-feature-settings: 'liga' off;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
