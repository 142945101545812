import React from "react";
import { Col } from "reactstrap";

import Icon from "components/Icon";
import DealIcons from "./DealIcons";
import {
  formatterToNull,
  formatValue,
  setColorClass
} from "helpers/valuesFormatters";
import { investedPercentColors } from "helpers/investedPercentColors";
import DealsListCommentState from "../states/DealsListCommentState";
import { ReactComponent as Ellipse } from "assets/img/icons/ellipse.svg";

import {
  DealAmountStyled,
  DealBodyStyled,
  DealCurrencyStyled,
  DealPercentStyled,
  DealTitleStyled
} from "./index.styles";

const DealBody = ({
  data,
  index,
  content
}) => {
  return (
    <DealBodyStyled className="d-flex align-items-center flex-wrap justify-content-between">
      <Col
        xs="auto"
        md="2"
        className="pl-0"
      >
        <div className="d-flex align-items-lg-center align-items-start flex-wrap flex-lg-nowrap flex-column flex-lg-row">
          <div className="position-relative">
            <Icon
              type="exchanges"
              size="botSize"
              code={data.bot.investment.credentials.exchange}
              className="my-auto"
              data-testid={`deal-card-icon-exchanges-${index}`}
            />
            <div className="status">
              <Ellipse data-testid="deal-card-ellipse-icon" />
            </div>
          </div>
          <div className="d-flex align-items-center ml-lg-2 ml-0">
            <DealAmountStyled
              amount={1}
              data-testid="deal-card-icon-base"
            >
              {data.market.base}
            </DealAmountStyled>
            <DealCurrencyStyled
              className="ml-1"
              data-testid="deal-card-icon-quote"
            >
              {`/ ${data.market.quote}`}
            </DealCurrencyStyled>
          </div>
        </div>
      </Col>
      {
        (
          (data.status === "ready" && !data?.invested?.amount)
          || data.status === "cancelled")
          && data.comment
          ? <DealsListCommentState
            status={data.status}
            comment={data.comment}
          />
          :
          <>
            <Col
              xs="auto"
              md="2"
              className="mt-3 mt-md-0 pl-0 px-md-1"
            >
              <div className="d-flex flex-column my-auto ml-md-3 ml-lg-0">
                <div className="d-flex align-items-start flex-row">
                  <DealTitleStyled
                    className="mr-1"
                    data-testid="deal-card-invested"
                  >
                    {content.invested}
                  </DealTitleStyled>
                  <DealPercentStyled
                    className={`${investedPercentColors(data.invested.percent)} ml-lg-2 ml-0 mb-0 font-weight-300 font-sm text-nowrap`}
                    data-testid="deal-card-invested-percent"
                  >
                    {`${formatterToNull(data.invested.percent)} %`}
                  </DealPercentStyled>
                </div>
                <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center flex-nowrap">
                  <DealAmountStyled
                    amount={data.invested.amount}
                    data-testid="deal-card-invested-amount"
                  >
                    {formatterToNull(data.invested.amount)}
                  </DealAmountStyled>
                  <DealCurrencyStyled
                    className="ml-0 ml-lg-1"
                    data-testid="deal-card-invested-asset"
                  >
                    {data.invested.asset}
                  </DealCurrencyStyled>
                </div>
              </div>
            </Col>

            <Col
              xs="auto"
              md="2"
              className="mt-3 mt-md-0 pl-0 px-md-1"
            >
              <div className="d-flex flex-column my-auto ml-md-3 ml-lg-0">
                <div className="d-flex align-items-start">
                  <DealTitleStyled
                    data-testid="deal-card-status"
                    className="mr-1">
                    {
                      data.status === "running"
                        ? "Estimated return"
                        : "Return"}
                  </DealTitleStyled>
                  <DealPercentStyled
                    data-testid="deal-card-status-percent"
                    className={`${setColorClass(
                      data.status === "running"
                        ? data.currentState.percent
                        : data.profit.percent,
                      "text-white-35")} mb-0 ml-lg-2 ml-0 text-nowrap font-sm`}
                  >
                    {
                      `${data.status === "running"
                        ? formatValue(data.currentState.percent)
                        : formatValue(data.profit.percent)} %`
                    }
                  </DealPercentStyled>
                </div>
                <div className="d-flex align-items-start align-items-lg-center flex-column flex-lg-row flex-nowrap">
                  {
                    data.status === "running" ?
                      <DealAmountStyled
                        data-testid="deal-card-amount-running"
                        amount={1}>
                        {formatValue(data.currentState.amount)}
                      </DealAmountStyled>
                      :
                      <p
                        className={`${setColorClass(data.profit.amount, "text-white-35")} mb-0 font-weight-500 text-nowrap font-md`}
                        data-testid="deal-card-amount-no-running"
                      >
                        {formatValue(data.profit.amount)}
                      </p>
                  }
                  <DealCurrencyStyled className="ml-0 ml-lg-1">
                    {
                      data.status === "running"
                        ? data.currentState.currency
                        : data.profit.asset
                    }
                  </DealCurrencyStyled>
                </div>
              </div>
            </Col>
            <Col
              xs="auto"
              md="2"
              className="pl-0 mt-3 mt-md-0"
            >
              <div className="d-flex flex-column">
                <DealTitleStyled
                  data-testid="deal-card-purchased"
                  className="d-flex flex-nowrap text-nowrap"
                >
                  {content.purchased}
                </DealTitleStyled>
                <div className="d-flex align-items-start align-items-lg-center flex-column flex-lg-row">
                  <DealAmountStyled
                    data-testid="deal-card-purchased-amount"
                    amount={data.purchased.amount}
                  >
                    {formatterToNull(data.purchased.amount)}
                  </DealAmountStyled>
                  <DealCurrencyStyled
                    data-testid="deal-card-purchased-asset"
                    className="ml-0 ml-lg-1">
                    {data.purchased.asset}
                  </DealCurrencyStyled>
                </div>
              </div>
            </Col>
          </>
      }
      <Col
        xs="auto"
        md="2"
        className="mt-3 mt-md-0 pl-0 pr-0"
      >
        <DealIcons data={data} />
      </Col>
    </DealBodyStyled>
  );
};

export default DealBody;