import moment from "moment";
import React from "react";

import OrdersProgressBar from "components/OrdersProgressBar";
import { formatterToNull } from "helpers/valuesFormatters";

import {
  TradeRequestAmount,
  TradeRequestCurrency,
  TradeRequestTitle
} from "components/StyledTradeRequestComponents";

const OpenOrderBody = ({
  createdAt,
  tradesCount,
  amount,
  quantity,
  filledPercent,
  price,
  className="px-4"
}) => {
  return (
    <div className={`d-flex align-items-center flex-wrap justify-content-between pb-3 ${className}`}>
      <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2">
        <TradeRequestTitle>
          Price
        </TradeRequestTitle>
        <div className="d-flex align-items-center">
          <TradeRequestAmount
            amount={price}
          >
            {formatterToNull(price)}
          </TradeRequestAmount>
        </div>
      </div>
      <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2">
        <TradeRequestTitle>
          Filled
        </TradeRequestTitle>
        <div className="d-flex align-items-center">
          <OrdersProgressBar filledPercent={filledPercent} />
          <p className="mb-0 font-sm font-weight-500 text-white-35">
            {`${filledPercent || 0}%`}
          </p>
        </div>
      </div>
      <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2">
        <TradeRequestTitle>
          Quantity
        </TradeRequestTitle>
        <div className="d-flex align-items-center">
          <TradeRequestAmount
            amount={quantity?.amount}
          >
            {formatterToNull(quantity?.amount)}
          </TradeRequestAmount>
          <TradeRequestCurrency>
            {quantity?.currency}
          </TradeRequestCurrency>
        </div>
      </div>
      <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2">
        <TradeRequestTitle>
          Amount
        </TradeRequestTitle>
        <div className="d-flex align-items-center">
          <TradeRequestAmount
            amount={amount?.amount}
          >
            {formatterToNull(amount?.amount)}
          </TradeRequestAmount>
          <TradeRequestCurrency>
            {amount?.currency}
          </TradeRequestCurrency>
        </div>
      </div>
      <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2">
        <TradeRequestTitle>
          Trades
        </TradeRequestTitle>
        <div className="d-flex align-items-center">
          <TradeRequestAmount
            amount={tradesCount}
          >
            {formatterToNull(tradesCount)}
          </TradeRequestAmount>
        </div>
      </div>
      <div className="d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2">
        <TradeRequestTitle>
          Time
        </TradeRequestTitle>
        <p className="mb-0 text-white font-weight-500 mr-3 font-sm">
          {moment(createdAt).format("DD.MM.YY HH:mm")}
        </p>
      </div>
    </div>
  );
};

export default OpenOrderBody;