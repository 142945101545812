import React from "react";
import { CardHeader } from "reactstrap";
import styled from "styled-components";
import PropTypes from 'prop-types';

import ChannelLogo from "../ChannelLogo";
import { formatPluralSingular } from "helpers/valuesFormatters";
import { ReactComponent as UserAccount } from "assets/img/icons/user-account.svg";

const ChannelHeader = ({
  name,
  totalSubscribers,
  owner,
  logo,
  loading,
  isDashboard
}) => (
  <ChannelHeader.CardHeader className="p-0 mb-4">
    <div className="d-flex justify-content-between">
      <ChannelHeader.Container className="d-flex align-items-center">
        <span className="d-inline-block">
          <ChannelLogo 
            logo={logo} 
            size={56}
            loading={loading}
          />
        </span>
        <div className="d-flex flex-column ml-3 w-75">
          <ChannelHeader.Name className="font-weight-500 h3 mb-1 text-white">
            {name}
          </ChannelHeader.Name>
          <span className="mb-0 font-weight-300 text-white-75 font-sm">
            {formatPluralSingular(totalSubscribers, "Subscriber", "Subscribers")}
          </span>
        </div>
      </ChannelHeader.Container>
      <span className="d-inline-block mt-3">
        {owner && !isDashboard? <UserAccount /> : null}
      </span>
    </div>
  </ChannelHeader.CardHeader>
);

ChannelHeader.CardHeader = styled(CardHeader)`
  border-radius: 8px 8px 0 0  !important;
  border-bottom: ${(props) => props.error ? "2px solid #FF8D72" : "2px solid transparent"} !important;
`;

ChannelHeader.Container = styled.div`
  width: 82%
`;

ChannelHeader.Name = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

ChannelHeader.propTypes = {
  name: PropTypes.string,
  totalSubscribers: PropTypes.number,
  owner: PropTypes.bool
};

export default ChannelHeader;