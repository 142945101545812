import { createSelector } from "reselect";

export const selectBot = (state) => state.bots.item;
export const bots = (state) => state.bots;
export const selectBotMarkets = (state) => state.bots.botMarkets;
export const geneticStrategiesTable = (state) => state.bots.geneticStrategiesTable;
export const geneticStrategies = (state) => state.bots.geneticStrategies;
export const selectGA = (state) => state.bots.geneticAlgorithm.data
const wizardTikers = (state) => state.bots.wizard.data;
export const selectBacktests = (state) => state.bots.backtests;
export const selectStrategies = (state) => state.bots.botStrategies;
export const selectindicatorsSignals = (state) => state.bots.indicatorsSignals;
export const tikersSelector = (exchange, coin) => createSelector(
  [wizardTikers],
  (wizardTikers={}) => {
    if (exchange && wizardTikers[exchange] && coin) {
      return wizardTikers[exchange][coin];
    }
  }
);
