import React from "react";
import styled from "styled-components";

import { ReactComponent as EditIcon } from "assets/img/icons/edit-button.svg";
import { ReactComponent as Switch } from "assets/img/icons/switch.svg";
import { ReactComponent as EditPencil } from "assets/img/icons/edit-pencil.svg";

const EditButton = ({
  type = "default",
  onClick,
  switchIcon,
  bgColor,
  dataTestId="edit-button",
  hoverColor,
  ...props
}) => {
  if (type === "pencil") {
    return <EditButton.Styled
      data-testid={`${dataTestId}-pencil`}
      onClick={onClick}
      type={type}
      bgColor={bgColor}
      hoverColor={hoverColor}
      {...props}
    >
      <EditPencil width={24} height={24}/>
    </EditButton.Styled>
  }

  return <EditButton.Styled
      data-testid={dataTestId}
      onClick={onClick}
      type={type}
      {...props}
    >
    {switchIcon ? <Switch/> : <EditIcon/> }
    </EditButton.Styled>
}

EditButton.Styled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  outline: none;
  height: 28px;
  width: 28px;
  font-weight: 500;
  line-height: 16px;
  border-radius: 6px;
  border: ${props => props.type === "pencil" ? "1px solid rgba(255, 255, 255, 0.1)" : "none"};
  background-color: ${props => props.type === "pencil" ? props.bgColor || "inherit" : "var(--info)"};

  :hover {
    background-color: ${props => props.type === "pencil" ? props.hoverColor || "rgba(255, 255, 255, 0.1)" : "#1A6EDF"};
    border: ${props => props.type === "pencil" && "1px solid transparent"};
    transition: 0.2s all ease-out;
    opacity: ${props => props.type === "pencil" ? "1" : "0.7"};
  }

  &:active {
    background-color: ${props => props.type !== "pencil" && "#19519B"};
    transition: 0.2s all ease-out;
  }
`

export default EditButton;
