import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  Modal
} from "reactstrap";
import styled from "styled-components";

import CreateChannelSetSettings from "../forms/CreateChannelSetSettings";

const ChannelSetSettings = ({
  isModalOpen,
  handleToggleModal,
  channelId,
  handleRefresh,
  isList,
  onSubscribeChannel
}) => {
  return (
    <ChannelSetSettings.Modal
      isOpen={isModalOpen}
      modalClassName="modal-black"
    >
      <Card className="mb-0">
        <CardHeader className="p-0 mb-4">
          <h3 className="h1 modal-title text-white mb-0">
            Subscribe to channel
          </h3>
        </CardHeader>
        <CreateChannelSetSettings
          channelId={channelId}
          handleToggleModal={handleToggleModal}
          handleRefresh={handleRefresh}
          isList={isList}
          onSubscribeChannel={onSubscribeChannel}
        />
      </Card>
    </ChannelSetSettings.Modal>
  );
};

ChannelSetSettings.Modal = styled(Modal)`
  
  .modal-content {
    padding:2rem;
  }

  .modal-content, .card {
    border-radius: 1rem !important;
  }
`;

ChannelSetSettings.propTypes = {
  isModalOpen: PropTypes.bool,
  handleToggleModal: PropTypes.func,
  channelId: PropTypes.string,
  handleRefresh: PropTypes.func,
};

export default ChannelSetSettings;