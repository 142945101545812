import React from "react";
import PropTypes from "prop-types";

import Icon from "components/Icon";
import PercentBadge from "components/PercentBadge";

const MarketChartTicker = ({
  exchange,
  ticker,
  quote,
  base
}) => (
  <>
    <div className="d-flex align-items-center justify-content-between flex-wrap p-4">
      <div className="d-flex align-items-center mb-2 mb-md-0">
        <Icon
          code={exchange}
          size="xxs"
          type="exchanges"
        />
        <h2 className="mb-0 text-white font-weight-500 ml-3 mr-2">
          {ticker?.close}
        </h2>
        <PercentBadge
          value={ticker?.dailyChange}
          showZeroValue
        />
      </div>
      <div className="d-flex">
        <div className="mr-4">
          <p className="mb-1 text-white-35 font-sm font-weight-300">
            24h High
          </p>
          <p className="mb-0 text-white font-weight-500">
            {ticker?.high}
          </p>
        </div>
        <div className="ml-3">
          <div>
            <p className="mb-1 text-white-35 font-sm font-weight-300">
              24h Low
            </p>
            <p className="mb-0 text-white font-weight-500">
              {ticker?.low}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="px-4">
      <div style={{ height: "1px", width: "100%", background: "rgba(255, 255, 255, 0.03)" }} />
    </div>

    <div className="d-flex align-items-center justify-content-start justify-content-md-between flex-wrap px-4 pb-3 pt-4">
      <div className="px-md-0 px-2 mb-2 mb-md-0">
        <p className="mb-1 text-white-35 font-sm font-weight-300">
          Open
        </p>
        <p className="mb-0 text-white font-weight-500">
          {ticker?.open}
        </p>
      </div>

      <div className="px-md-0 px-2 mb-2 mb-md-0">
        <p className="mb-1 text-white-35 font-sm font-weight-300">
          24h Volume ({quote})
        </p>
        <p className="mb-0 text-white font-weight-500">
          {ticker?.amount}
        </p>
      </div>

      <div className="px-md-0 px-2 mb-2 mb-md-0">
        <p className="mb-1 text-white-35 font-sm font-weight-300">
          24h Volume ({base})
        </p>
        <p className="mb-0 text-white font-weight-500">
          {ticker?.quantity || "-"}
        </p>
      </div>

      <div className="px-md-0 px-2 mb-2 mb-md-0">
        <p className="mb-1 text-white-35 font-sm font-weight-300">
          Mark price
        </p>
        <p className="mb-0 text-white font-weight-500">
          {ticker?.markPrice || "-"}
        </p>
      </div>

      <div className="px-md-0 px-2 mb-2 mb-md-0">
        <p className="mb-1 text-white-35 font-sm font-weight-300">
          Trades count
        </p>
        <p className="mb-0 text-white font-weight-500">
          {ticker?.tradeCount || "-"}
        </p>
      </div>
    </div>
  </>
);

MarketChartTicker.propTypes = {
  exchange: PropTypes.string,
  quote: PropTypes.string,
  base: PropTypes.string
};

export default MarketChartTicker;