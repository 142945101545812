import React, { 
  useState,
  useEffect } from "react";
import { 
  Row, 
  Col 
} from "reactstrap";
import { 
  useDispatch, 
  useSelector
} from "react-redux";
import { useLocation } from "react-router-dom";
import qs from "qs";

import Header from "./compounds/Header";
import PlansCards from "./compounds/PlansCards";
import { selectProducts } from "store/billing/selectors";
import {
  fetchProducts,
  resetProducts,
  getSession
} from "store/billing/actions";
import FeaturesTable from "./compounds/FeaturesTable";
import BillingPagePreloader from "./compounds/BillingPagePreloader";
import { selectCurrentPlan } from "store/user/selectors";
import PaymentResultModal from "./modals/PaymentResultModal";
import useRequestCancellation from "hooks/useRequestCancellation";

const BillingPage = () => {
  const [paymentInterval, setPaymentInterval] = useState("month");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [session, setSession] = useState(null);
  const { data, loading } = useSelector(selectProducts);

  const isMeLoading = useSelector((state) => state.me.loading);
  const me = useSelector((state) => state.me.data);
  const currentPlan = useSelector(selectCurrentPlan);

  const dispatch = useDispatch();
  const location = useLocation();
  
  const { session_id } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { useAbortableEffect } = useRequestCancellation();

  const handleGetSession = async() => {
    const res = await dispatch(getSession(session_id));
    if (!res.errors) setSession(res)
  };

  useEffect(() => {
    if (session_id) handleGetSession();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session_id]);

  useEffect(() => {
    if (session_id && session) setIsModalOpen(true);
  }, [session_id, session]);

  useAbortableEffect((signal) => {
    dispatch(fetchProducts(), signal);
    return () => resetProducts();
  }, [dispatch], true);

  if (loading || isMeLoading) return <BillingPagePreloader />

  return (
    <div>
      <Row>
        <Col md="12">
          <Header
            paymentInterval={paymentInterval}
            setPaymentInterval={setPaymentInterval}
            displayIntervals={!currentPlan}
          />
        </Col>
      </Row>
      {
        !currentPlan ?
        <PlansCards
          plans={data?.items}
          paymentInterval={paymentInterval}
          currentPlan={currentPlan}
          hasTrial={me?.hasTrial}
        /> : null
      }
      <FeaturesTable
        plans={data?.items}
        currentPlan={currentPlan}
        paymentInterval={paymentInterval}
        setPaymentInterval={setPaymentInterval}
        hasTrial={me?.hasTrial}
      />
      <PaymentResultModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        cutomerDetails={session?.customer_details}
        status={session?.status}
        amountTotal={session?.amount_total}
      />
    </div>
  );
};

export default BillingPage;
