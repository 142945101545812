import React, {
  useEffect,
  useState
} from "react";
import styled from "styled-components";
import {
  Col,
  Row
} from "reactstrap";
import classNames from "classnames";
import {
  useDispatch,
  useSelector
} from "react-redux";

import HeaderPreLoader from "components/HeaderPreLoader";
import BotHeader from "../compounds/BotDetailsHeader";
import EditBotSettings from "./EditBotSettings";
import { setBotPageBreadcrumbs } from "models/enum/breadcrumbs";
import Breadcrumbs from "components/Breadcrumbs";
import { resetWizardIndicators } from "store/strategies/actions";
import {
  getBot,
  editBot,
  resetBot
} from "store/bots/actions";
import {
  setBidAskPercent,
  setBotName,
  setExcludedMarkets,
  setInvestment,
  setMinimumMarketsTrades,
  setMinimumMarketsVolume,
  setMinMaxChange,
  setPeriod,
  setPriceDifference,
  setResetBotWizard,
  setSelectAllMarkets,
  setSelectedMarkets,
  setTrend
} from "store/bots/wizard/actions";
import EditBotMarkets from "./EditBotMarkets";

const EditBot = ({ match }) => {
  const [isSubmit, setIsSubmit] = useState();

  const botData = useSelector(state => state.bots.item.data);
  const loading = useSelector(state => state.bots.item.loading);
  const wizardMarkets = useSelector((state) => state.botWizard);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBot(match.params.id));
    return () => resetBot();
  }, [match.params.id, dispatch]);
  
  useEffect(() => {
    if (botData?.investment) {
      dispatch(setBotName(botData.name));
      dispatch(setSelectAllMarkets(botData.allCoins));
      dispatch(setMinimumMarketsTrades(botData.minimumTrades));
      dispatch(setMinimumMarketsVolume(botData.volume));
      dispatch(setBidAskPercent(botData.spreadPercent));
      dispatch(setTrend(botData.marketDirection));
      dispatch(setPeriod(botData.period));
      dispatch(setMinMaxChange([botData.minimumDailyChange, botData.maximumDailyChange]));
      dispatch(setPriceDifference(botData.markPriceDifference || 0));
      dispatch(setExcludedMarkets(botData.excludedCoins));
      dispatch(setSelectedMarkets(botData.coins));
      dispatch(setInvestment(botData.investment));
    }
    return () => {
      dispatch(setBotName(""));
      dispatch(setResetBotWizard());
      dispatch(setInvestment({}));
      dispatch(resetWizardIndicators());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, botData?.investment]);
  
  useEffect(() => {
    window.analytics.page("/bots/:id/configure");
  }, []);
  
  return (
    <div className={classNames({ 'opacity-5': isSubmit })}>
      <Breadcrumbs
        className="d-none d-md-block"
        items={[
          ...setBotPageBreadcrumbs(
            botData?.investment?.credentials?.exchange,
            botData?.investment?.credentials?.id,
            botData?.investment?.id,
            botData?.investment?.asset,
            botData?.name,
            match.params.id,
            true
          ),
          { label: "Configure", path: "", isClickable: false }
        ]}
        loading={(loading && !isSubmit)}
      />
      <Row>
        <Col xs="12">
          {
            loading && !isSubmit ?
              <HeaderPreLoader />
              :
              <BotHeader
                isEdit
                data={botData}
                id={match.params.id}
              />
          }
        </Col>
      </Row>
      <EditBot.Wrapper className="mb-3">
        <EditBotMarkets 
          credentialId={botData?.investment?.credentials?.id} 
          loadingBotData={loading}
          period={wizardMarkets?.period}
          isEdit
          />
        <EditBotSettings
          data={botData}
          editBot={editBot}
          loading={loading}
          isSubmit={isSubmit}
          setIsSubmit={setIsSubmit}
          wizardMarkets={wizardMarkets}
        />
      </EditBot.Wrapper>
    </div>
  );
};

EditBot.Wrapper = styled.div`
  background: var(--dark-slate);
  border-radius: 16px;
  overflow: hidden;
`;

export default EditBot;
