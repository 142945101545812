import React, {useState} from "react";
import styled from "styled-components";
import {Card, CardBody, FormGroup, Tooltip} from "reactstrap";

import ExchangesSelect from "components/ExchangesSelect";
import SelectInput from "components/SelectInput";
import InvestmentsSelect from "./InvestmentsSelect";
import {ReactComponent as SmallToBig} from "assets/img/icons/smallToBig.svg";
import {BOT_SORT_OPTIONS, BOT_STATUS_OPTIONS, BOT_TYPE_OPTIONS} from "models/filters";
import useFilterAndSorting from "hooks/useFilterAndSorting";

const Filters = ({ loading }) => {
  const [tooltip, setTooltip] = useState(false);
  const { handleFilter, handleSorting, filter, sort } = useFilterAndSorting("/bots");
  const toggle = () => setTooltip(!tooltip);

  const sortingButton = () => {
    if (sort?.by) {
      handleSorting({
        order: sort?.order === "ASC" || !sort?.order ? "DESC" : "ASC",
        by: sort?.by
      });
    }
  };

  return (
    <Card className="mt-3 mb-4 mx-0">
      <CardBody className="p-4 d-flex flex-column flex-md-row justify-content-between">
        <FormGroup className="mb-0 w-100 mr-3 mb-2 mb-md-0">
          <ExchangesSelect
            isClearable
            placeholder="Exchange"
            name="exchange"
            value={filter?.["credentials.exchange"]?.in}
            isMulti
            onChange={(values) => {
              handleFilter({
                "credentials.exchange": {in: values ? values.map((e) => e.value) : ""}
              });
            }}
            width="100%"
          />
        </FormGroup>
        <FormGroup className="mb-0 w-100 mr-3 mb-2 mb-md-0">
          <InvestmentsSelect
            filter={filter}
            handleFilter={handleFilter}
          />
        </FormGroup>
        <FormGroup className="mb-0 w-100 mr-3 mb-2 mb-md-0">
          <SelectInput
            isClearable
            placeholder="Bot type"
            name="botType"
            width="100%"
            onChange={(option) => handleFilter({
              type: {eq: option?.value}
            })}
            options={BOT_TYPE_OPTIONS}
          />
        </FormGroup>
        <FormGroup className="mb-0 w-100 mr-3 mb-2 mb-md-0">
          <SelectInput
            isClearable
            placeholder="Status"
            name="status"
            width="100%"
            onChange={(option) => handleFilter({
              status: {eq: option?.value}
            })}
            options={BOT_STATUS_OPTIONS}
          />
        </FormGroup>
        <div className="d-flex w-100">
          <FormGroup className="mb-0 w-100 mr-3 mb-2 mb-md-0">
            <SelectInput
              isClearable
              placeholder="Sort by"
              name="sortBy"
              width="100%"
              onChange={(option) => handleSorting({order: sort?.order || "DESC", by: option?.value})}
              options={BOT_SORT_OPTIONS}
            />
          </FormGroup>
          <Filters.Sorting
            onClick={sortingButton}
            loading={loading}
            by={sort?.by}
            id="sort"
          >
            <Filters.SortingOrderIcon order={sort?.order || "DESC"}/>
            {
              !sort?.by &&
              <Tooltip target="sort" placement="top" isOpen={tooltip} toggle={toggle}>
                Select sort by
              </Tooltip>
            }
          </Filters.Sorting>
        </div>
      </CardBody>
    </Card>
  );
};

Filters.Sorting = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    path {
      fill-opacity: ${(props) => props.loading || !props?.by ? ".2" : "0.35"};
    }
  }

  &:hover {
    svg {
      path {
        fill-opacity: ${(props) => props.loading || !props?.by ? ".2" : "1"};
      }
    }
  }
`;

Filters.SortingOrderIcon = styled(SmallToBig)`
  transition: transform .2s ease-in-out;
  transform: ${(props) => props.order === "DESC" ? "rotate(180deg)" : ""};
`;

export default Filters;
