import React, { useState } from "react";
import { Card, CardBody, Tooltip } from "reactstrap";

import { ReactComponent as Volumes } from "assets/img/icons/Balances.svg";
import VolumesWidgetPreloader from "./preloaders/VolumesWidgetPreloader";
import VolumesViewStatus from "./VolumesViewStatus";
import PercentBadge from "components/PercentBadge";
import VolumesWidgetFooter from "./VolumesWidgetFooter";

const WidgetVolumes = ({
  data: {
    invested,
    purchased,
    avgBuyRate,
    sold,
    received,
    profit,
    currentState
  },
  loading,
  status
}) => {
  const [tooltip, setTooltip] = useState(false);
  const toggle = () => setTooltip(!tooltip);

  if (loading) return <VolumesWidgetPreloader />;

  return (
    <Card className="mb-4">
      <CardBody className="p-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <Volumes/>
            <p className="mb-0 ml-2 font-weight-500 text-white">Volumes</p>
          </div>
          <div id="percent" className="cursor-pointer">
            <PercentBadge
              value={invested?.percent}
              isColorSpectrum={true}
              isDefault={false}
            />
          </div>
          <Tooltip target="percent" placement="top" isOpen={tooltip} toggle={toggle}>
            Invested percent
          </Tooltip>
        </div>

        <div className="d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between mt-3 align-items-lg-center">
          <p className="font-sm mb-0 text-white">Invested</p>
          <div className="d-flex align-items-center">
            <p className="mb-0 text-white font-weight-500">{invested?.amount || "0.00"}</p>
            <p className="mb-0 text-white-35 font-weight-300 ml-2 mb-0">{invested?.asset}</p>
          </div>
        </div>

        <div className="d-flex flex-column flex-sm-row flex-md-column flex-lg-row align-items-lg-center justify-content-between mt-2">
          <p className="font-sm mb-0 mr-3 text-white-35 font-weight-300">
            Purchased
          </p>
          <div className="d-flex align-items-center overflow-hidden">
            <p className="mb-0 text-white font-weight-500 overflow-hidden rbc-ellipsis">
              {purchased?.amount || "0.00"}
            </p>
            <p className="mb-0 text-white-35 font-weight-300 ml-2 mb-0">
              {purchased?.asset}
            </p>
          </div>
        </div>
        <VolumesViewStatus
          receivedAmount={received?.amount}
          receivedAsset={received?.asset}
          soldAmount={sold?.amount}
          soldAsset={sold?.asset}
          avgBuyRate={avgBuyRate}
        />
      </CardBody>
      {
        status === "running" || status === "completed" ?
        <VolumesWidgetFooter
           status={status}
           amount={status === "running" ? currentState.amount : profit.amount}
           currency={status === "running" ? currentState.currency : profit.asset}
           percent={status === "running" ? currentState.percent : profit.percent}
        /> : null
      }
    </Card>
  );
};

export default WidgetVolumes;
