import React from "react";
import styled from "styled-components";
import { Card } from "reactstrap";

import { formatterToNull } from "helpers/valuesFormatters";


const TradeHistoryWidget = ({total, daily}) => {

  return (
      <>
        <TradeHistoryWidget.Wrapper>
          <div className="d-flex justify-content-start">
            <div className="d-flex flex-column align-items-start">
              <TradeHistoryWidget.Value>
                  Total trades
              </TradeHistoryWidget.Value>
              <TradeHistoryWidget.Total total={total}>{formatterToNull(total)}</TradeHistoryWidget.Total>
            </div>
          </div>
        </TradeHistoryWidget.Wrapper>
        <TradeHistoryWidget.Wrapper>
          <div>
            <div className="d-flex align-items-start flex-column">
              <TradeHistoryWidget.Value>
                  24h trades
              </TradeHistoryWidget.Value>
              <TradeHistoryWidget.Total total={daily}>{formatterToNull(daily)}</TradeHistoryWidget.Total>
            </div>
          </div>
        </TradeHistoryWidget.Wrapper>
        </>
  );
}

TradeHistoryWidget.Wrapper = styled(Card)`
  border-radius: 8px !important;
  margin-right: 8px;
  padding: 0.75rem 1rem;
  svg {
    path {
      stroke: var(--info);
    }
  }
`;

TradeHistoryWidget.Value = styled.p`
  color: var(--white-75, rgba(255, 255, 255, 0.75)) !important;
  margin-bottom: 4px;
  font-weight: 300;
  font-size: 0.875rem;
`;

TradeHistoryWidget.Up = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  svg {
    path {
      stroke: #5FD3BC;
    }
  }
`;

TradeHistoryWidget.Total = styled.p`
  font-weight: 700;
  font-size: 19px;
  color: ${(props) => props.total > 0 ? "#FFFFFF" : "rgba(255, 255, 255, 0.35)"} !important;
`;

export default TradeHistoryWidget;
