import React from "react";
import {
  CardBody,
  CardFooter,
  CardHeader
} from "reactstrap";
import { times } from "lodash";

import { ReactComponent as CheckMarkPreload } from "assets/img/icons/CheckMarkPreload.svg";
import StyledPlanCard from "./StyledPlanCard";
import SkeletonPreloader from "components/SkeletonPreloader";

const PlanCardPreloader = () => {
  return (
    <StyledPlanCard>
      <CardHeader>
        <div className="d-flex flex-column">
          <SkeletonPreloader
            width={66}
            height={17}
            className="mb-2"
          />
          <SkeletonPreloader
            width={139}
            height={9}
            style={{ marginBottom: "2rem" }}
          />
        </div>
        <div className="d-flex flex-column">
          <SkeletonPreloader
            width={66}
            height={17}
            className="sq-preload mb-2"
          />
          <SkeletonPreloader
            width={54}
            height={9}
            style={{ marginBottom: "50px" }}
          />
        </div>
      </CardHeader>
      <CardBody className="pt-0">
        <div className="d-flex flex-column pr-5">
          <SkeletonPreloader
            width={200}
            height={51}
            className="sq-preload mb-3"
          />
          <SkeletonPreloader
            width={94}
            height={9}
          />
        </div>
        <hr className="mb-0" />
      </CardBody>
      <CardFooter>
        <ul className="m-0 p-0">
          {
            times(3, (e) =>
              <li key={e}>
                <CheckMarkPreload className="mb-1" />{" "}
                <SkeletonPreloader
                  width={94}
                  height={9}
                  className="ml-2"
                />
              </li>
            )
          }
        </ul>
      </CardFooter>
    </StyledPlanCard>
  );
};

export default PlanCardPreloader;
