import React from "react";
import styled from "styled-components";
import { ReactComponent as Repeat } from "assets/img/icons/repeat.svg";
import { Card } from "reactstrap";
import IdWithCopy from "components/IdWithCopy";
import classnames from "classnames";
import moment from "moment";
import LineWithError from "shared/ui/LineWithError";
import HeaderPreloader from "../preloaders/HeaderPreloader";
import { ReactComponent as Clock } from "assets/img/icons/clock.svg";

const Header = ({data, loading}) => {
  const {id, status, createdAt, error} = data;

  if (loading) {
    return (
      <Header.Card>
        <HeaderPreloader/>
      </Header.Card>
    )
  }

  return (
    <Header.Card>
      <div className="w-100 d-flex flex-column flex-md-row align-items-center justify-content-between p-4 mx-2">
        <div className="d-flex align-items-center w-100">
          <span className="d-inline-block">
            <Repeat height="48px" width="48px"/>
          </span>
          <div className="ml-3 w-75">
            <Header.Title>Rebalance</Header.Title>
            <IdWithCopy id={id}/>
          </div>
        </div>

        <div className="d-flex flex-row flex-md-column align-items-start align-items-md-end w-100 mt-2 mt-md-0">
          <Header.Status className={classnames("mr-3 mr-md-0", {
            "text-success": status === "completed",
            "text-info": status === "open",
            "text-warning": status === "failed",
          })}>
            {status}
          </Header.Status>
          <div className="d-flex align-items-center my-auto mt-md-2">
            <Clock
              className="icon-stroke-gray mr-1 cursor-pointer"
              aria-hidden="true"
            />
            <p className="d-flex mb-0 text-white-35 font-sm">{moment(createdAt).format("DD.MM.YYYY HH:mm")}</p>
          </div>
        </div>
      </div>
      <LineWithError errorMessage={error}/>
    </Header.Card>
  )
}

Header.Card = styled(Card)`
  margin-bottom: 32px !important;
  border-radius: 8px !important;
  padding: 0 !important;
`;

Header.Title = styled.p`
  margin-bottom: 0;
  font-weight: 500;
  font-size: 24px;
  color: #FFFFFF !important;
`;

Header.Status = styled.p`
  width: fit-content;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  padding: 3.5px 10px;
  text-transform: capitalize;
`;

export default Header;
