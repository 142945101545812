import React from "react";
import styled from "styled-components";
import {CardHeader} from "reactstrap";

import {ReactComponent as BotsIcon} from "assets/img/icons/receiptsearch.svg";
import RefreshButton from "components/RefreshButton";

const BotsWidgetHeader = ({loading, onHandleRefresh}) => {
  return (
      <BotsWidgetHeader.CardHeader className="px-4 pt-4 mb-4 d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <BotsIcon/>
          <p className="text-white font-weight-500 ml-2 mb-0">
            Bots
          </p>
        </div>
        <div className="d-none d-md-block">
          <RefreshButton
            loading={loading}
            onHandleRefresh={onHandleRefresh}
          />
        </div>
      </BotsWidgetHeader.CardHeader>
  )
}

BotsWidgetHeader.CardHeader = styled(CardHeader)`
  border-radius: 0 !important;
`;

export default BotsWidgetHeader;
