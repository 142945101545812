import React from "react";

import { MARKET_SUMMARY_TABLE_HEAD } from "models/bot/tables";
import CustomScrollbars from "components/CustomScrollbars";
import StyledTable from "components/StyledTable";
import SortTableHead from "components/SortTableHead";
import PaginationPanel from "components/PaginationPanel";
import EmptyState from "components/EmptyState";
import MarketSummaryTableRow from "./MarketSummaryTableRow";
import MarketTablePreloader from "./MarketTablePreloader";

const MarketSummaryTable = ({
  analysisData,
  limit,
  analysisLoading,
  loadingPage,
  handleSorting,
  handlePaginate,
  handleSelectMarket,
  wrapperRef
}) => {
  if (loadingPage || analysisLoading) return (
    <StyledTable>
      <MarketTablePreloader withHeader/>
    </StyledTable>
  );

  if (!analysisData?.items?.length && !analysisData?.request?.filter?.length) return (
    <EmptyState
      title="No markets to display"
      desc="We're sorry, it looks like there are no active markets at the moment. Please stay patient and keep an eye out for any updates."
    />
  );

  return (
    <>
      {
        analysisData?.items?.length ?
          <CustomScrollbars
            autoHideTimeout={500}
            autoHideDuration={500}
            autoHeight
            autoHeightMax={Number.MAX_VALUE}
          >
            <StyledTable className="mb-0" paddingsThead="1rem">
              <SortTableHead
                handleSorting={handleSorting}
                sort={analysisData?.request?.sort}
                theadItems={MARKET_SUMMARY_TABLE_HEAD}
              />
              {
                analysisLoading ? <MarketTablePreloader/> :
                  <tbody className="font-sm">
                  {
                    analysisData?.items?.map((item) =>
                      <MarketSummaryTableRow
                        item={item}
                        key={item.market.pair}
                        handleSelectMarket={handleSelectMarket}
                        totalItems={analysisData?.total}
                      />)
                  }
                  </tbody>
              }
            </StyledTable>
          </CustomScrollbars>
          :
          <EmptyState
            title="No results for your search"
            desc="Oops, we couldn't find any results for your search. Please try again with a different keyword or refine your search criteria."
          />
      }
      <div className={analysisData?.total > 10 ? "mt-3" : "mt-0"}>
        <PaginationPanel
          activePage={analysisData?.page}
          totalItemsCount={analysisData?.total}
          totalPagesView={limit}
          onChange={(page) => handlePaginate(page)}
          className="m-0"
          elementRef={wrapperRef}
        />
      </div>
    </>
  )
}

export default MarketSummaryTable;
