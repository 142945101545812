import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Card,
  CardBody
} from "reactstrap";

import Button from "components/Button";
import MyAgreementsBody from "./MyAgreementsBody";
import MyAgreementsHeader from "./MyAgreementsHeader";
import MyAgreementsPreloader from "../preloaders/MyAgreementsPreloader";
import { ReactComponent as TickSquare } from "assets/img/icons/tick-square.svg";
import { useDispatch } from "react-redux";
import { acceptSignal } from "store/signals/action";
import { addSnackBar } from "store/snackbar/actions";


const MyAgreementsDetails = ({
  agreements,
  loading,
  signalId,
  status
}) => {

  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleAcceptSignal = async (signalId) => {
    setIsSubmitted(true);
    const res = await dispatch(acceptSignal(signalId, signalId));
    if (!res.errors) {
      setIsSubmitted(false);
      return dispatch(addSnackBar("success", res.message));
    }
    setIsSubmitted(false);
    return dispatch(addSnackBar("error", res.message));
  };

  if (loading) {
    return (
      <Card className="flex-row align-items-center justify-content-between p-3 pl-4">
        <MyAgreementsPreloader />
      </Card>
    )
  };

  if (!agreements?.length) {
    return (
      <Card className="flex-row align-items-center justify-content-between p-3 pl-4">
        <TickSquare className="mr-1" />
        <p className="mb-0 font-weight-500 text-white-35 ml-2 mr-auto">
          No agreements
        </p>
        {
          status !== "completed"
            ? <Button
              color="blue"
              loading={isSubmitted}
              disabled={isSubmitted}
              className="px-3 py-2 ml-auto mr-0"
              onClick={() => handleAcceptSignal(signalId)}
            >
              Accept
            </Button>
            : null
        }

      </Card>
    );
  }

  return (
    <Card>
      {
        agreements?.map((agreement, index) => (
          <MyAgreementsDetails.Link
            to={`/agreements/${agreement.id}`}
            key={agreement.id}
            className="p-4"
          >
            <CardBody className={`p-0 ${index ? "mt-3" : ""}`}>
              <MyAgreementsHeader
                acceptedAt={agreement.createdAt}
                status={agreement.status}
                profit={agreement.profit}
                profitPercent={agreement.profitPercent}
                quote={agreement.market?.quote}
              />
              <MyAgreementsBody
                base={agreement.market?.base}
                quote={agreement.market?.quote}
                invested={agreement.invested}
                purchased={agreement.purchased}
                received={agreement.received}
                sold={agreement.sold}
                unsold={agreement.unsold}
              />
            </CardBody>
          </MyAgreementsDetails.Link>
        ))
      }
    </Card>
  )
};

MyAgreementsDetails.Link = styled(Link)`
  border-radius:0.5rem;
  
  &:hover {
    background: #2E3148;
  }
`;

MyAgreementsDetails.propTypes = {
  loading: PropTypes.bool,
  signalId: PropTypes.string,
  status: PropTypes.string,
};

export default MyAgreementsDetails;
