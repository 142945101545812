import React from "react";
import styled from "styled-components";
import {
  Row,
  Col
} from "reactstrap";
import _ from "lodash";
import SkeletonPreloader from "components/SkeletonPreloader";

const ListLoading = () => {
  return (
    <>
      {
        _.times(8, (e) =>
          <ListLoading.Card
            className="card d-block p-3 mb-2"
            key={e}
          >
            <Row className="justify-content-between align-items-center">
              <Col xs="12" md="1">
                <div className="d-flex justify-content-between h-100">
                  <span className="d-flex align-items-center">
                    <SkeletonPreloader
                      circle={true}
                      height={24}
                      width={24}
                      className="mt-1 mb-1 mr-1"
                    />
                  </span>
                  <span className="d-flex align-items-center">
                    <SkeletonPreloader
                      className="mt-1 mb-1"
                      height={14}
                      width={70}
                    />
                  </span>
                </div>
              </Col>
              <Col xs="12" md="8" className="d-none d-md-flex">
                <div className="d-flex justify-content-around">
                  {
                    _.times(3, (e) => {
                      return (
                        <div className="d-flex flex-column" key={e}>
                          <SkeletonPreloader
                            height={9}
                            width={65}
                            className="mb-3 mt-2"
                          />
                          <div className="d-flex align-items-center">
                            <SkeletonPreloader
                              height={14}
                              width={138}
                            />
                            <SkeletonPreloader
                              height={20}
                              width={28}
                              borderRadius="8px"
                              className={`ml-2 percent-${e}`}
                            />
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </Col>
              <Col xs="1" md="1" className="d-none d-md-flex pl-0">
                <div className="d-flex flex-column align-items-start">
                  <div className="d-flex">
                    <SkeletonPreloader
                      height={12}
                      width={36}
                      className="mt-1 mb-1"
                    />
                    <SkeletonPreloader
                      circle={true}
                      height={16}
                      width={16}
                      className="mt-1"
                    />
                  </div>
                  <SkeletonPreloader
                    height={12}
                    width={45}
                    className="mt-1 mb-1"
                  />
                </div>
              </Col>
            </Row>
            <div className="d-flex mt-3">
            <SkeletonPreloader
              circle
              height={20}
              width={20}
              className="mr-1"
            />
            <SkeletonPreloader
              height={20}
              width={220}
              className="ml-2"
            />
            </div>
          </ListLoading.Card>
        )
      }
    </>
  );
};

ListLoading.Card = styled.div`
  border-left: 8px solid #686976 !important;

  .percent-2 {
    display: none;
  }
`;

export default ListLoading;
