import React, {useLayoutEffect} from "react";
import { useDispatch, useSelector } from "react-redux";

import {getInvestmentsList, resetInvestments} from "store/investment/actions";
import LookupCoins from "components/LookupCoins";
import SelectInput from "components/SelectInput";
import { selectInvestments } from "store/investment/seletors";
import qs from "qs";

const InvestmentsSelect = ({ filter, handleFilter }) => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(selectInvestments);

  useLayoutEffect(() => {
    const queryString = qs.stringify({filter: { botsCount: {gte: 1} }})
    dispatch(getInvestmentsList(`?${queryString}`))

    return () => dispatch(resetInvestments())
  }, [dispatch]);

  const getFilteredInvestments = () => {
    return data?.items?.filter((item, index, arr) => index === arr.findIndex((other) => item.asset === other.asset));
  };

  return (
    <SelectInput
      isClearable
      isLoading={loading}
      placeholder="Coin"
      name="investments"
      width="100%"
      isMulti
      value={filter?.["investment.asset"]?.in?.map((investment) => ({
        label: investment,
        value: investment,
        icon: {
          type: "cryptocurrency",
          size: "selectSize",
          code: investment,
        }
      }))}
      onChange={(values) => {
        handleFilter({
          "investment.asset": { in: values ? values.map((e) => e.value) : "" }
        });
      }}
      options={
        getFilteredInvestments()?.map((i) => {
          return {
            label: i.asset,
            value: i.asset,
            secondLabel: <LookupCoins coin={i.asset}/>,
            icon: {
              type: "cryptocurrency",
              size: "smallSize",
              code: i.asset,
            },
          };
        })
      }
    />
  );
};

export default InvestmentsSelect;
