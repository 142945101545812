import React from "react";
import { CardBody } from "reactstrap";

import { formatterToNull } from "helpers/valuesFormatters";
import { numberOrNull } from "views/channels/channelPropTypes";

const ChannelSubscrBody = ({
  maxximun, 
  volume,
  accept
}) => {
  const acceptStatus = accept === "automatic" ? "Enabled" : "Disabled";
  return (
    <CardBody className="pt-3 pb-4 px-4">
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center justify-content-between">
          <p className="font-sm font-weight-400 text-white-75 mb-0">
            Maximum open agreements
          </p>
          <p className="mb-0 font-weight-500 text-white">
            {formatterToNull(maxximun)}
          </p>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <p className="font-sm font-weight-400 text-white-75 mb-0">
            Investment per agreement
          </p>
          <p className="mb-0 font-weight-500 text-white">
            {formatterToNull(volume)} %
          </p>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <p className="font-sm font-weight-400 text-white-75 mb-0">
            Auto acceptance
          </p>
          <p className={`mb-0 font-weight-500 text-${accept === "automatic" ? "success":"danger"}`}>
            {acceptStatus}
          </p>
        </div>         
      </div>
    </CardBody>
  );
};

ChannelSubscrBody.propTypes = {
  maxximun: numberOrNull,
  volume: numberOrNull,
};

export default ChannelSubscrBody;