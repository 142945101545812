import React from "react";
import {Button} from "reactstrap";
import {useDispatch} from "react-redux";
import StyledFilterButtons from "../StyledFilterButtons";

const ButtonsGroup = ({ group, current, setCurrent, callFunc, params, disable, className="" }) => {
  const dispatch = useDispatch();

  const handleClick = (type) => {
    if (type === current) return;
    setCurrent(type);
    if (callFunc && params) return dispatch(callFunc(params, type));
    if (callFunc && !params) return dispatch(callFunc(type));
  };

  return (
    <StyledFilterButtons className={className}>
      {
        group.map((item) => {
          return (
            <Button
              key={item.type}
              className={current === item.type ? "bg-info text-white" : "inherit"}
              onClick={() => handleClick(item.type)}
              disabled={disable}
            >
              {item.title}
            </Button>
          )
        })
      }
    </StyledFilterButtons>
  )
}

export default ButtonsGroup;
