import styled from "styled-components";
import { Col } from "reactstrap";

import { ReactComponent as CrownIcon } from "assets/img/icons/crown.svg";

export const IndicatorFormWrapper = styled.div`
  span {
    cursor: pointer;
  }

  .line {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
    width: 1px;
  }

  i {
    color: #FFFFFFBF !important;
    cursor: pointer;
  }

  .form-danger {
    position: relative;
    border-radius: 6px;
    font-size: 14px;
    background: rgba(108, 117, 125, 0.04) !important;
    border: 1px solid #ED5F5F;
  }

  .form-danger.form-control {
    background: #222435;
    :focus {
      background: #222435;
    }
  }

  .form-success {
    position: relative;
    font-size: 14px;
    background: #2E3148 !important;
    color: var(--white);
    border-radius: 6px;
  }

  .form-success.form-control {
    background: #222435;
    :focus {
      background: #222435;
    }
  }
`

export const IndicatorFormBadge = styled(Col)`
  background: #2E3148;
  border-radius: 4px;
  height: 43px;
  max-width: 107px !important;
  cursor: pointer;

  &.active {
    max-width: 107px;
    border: 2px solid var(--info);
  }

  :hover {
    background: #2A2C42;
  }
`;

export const CrownIconStyled = styled(CrownIcon)`
  margin-left: -8px;
  path {
    stroke: rgba(255, 196, 102, 0.7) !important;
  }
`