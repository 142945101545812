import { useContext } from "react";
import { useDispatch } from "react-redux";

import { 
  createSession,
  managePaymets
} from "store/billing/actions";
import { Context } from "components/OverlayContext";
import { addSnackBar } from "store/snackbar/actions";

const useBuySubscription = () => {
  const dispatch = useDispatch();
  const { setDisplayOverlay } = useContext(Context);

  const handleResponse  = (res) => {
    if (res.errors) {
      setDisplayOverlay(false);
      return dispatch(addSnackBar("danger", res.message));
    }
    if (res.url) window.location.href = res.url;
  };

  const handleBuyNow = async(id, trial) => {
    setDisplayOverlay(true);
    const path = "/billing?session_id={CHECKOUT_SESSION_ID}";
    const redirectUrl = `https://${window.location.hostname}${path}`;
    const returnUrl = `https://${window.location.hostname}`;

    const reqBody = {
      prices: [
        {
          quantity: 1,
          id
        }
      ],
      successUrl: redirectUrl,
      cancelUrl: redirectUrl,
      returnUrl: returnUrl,
      trial
    };

    const res = await dispatch(createSession(reqBody));
    handleResponse(res);
  };

  const handleAddCard = async() => {
    setDisplayOverlay(true);
    
    const res = await dispatch(managePaymets());
    handleResponse(res);
  };

  return { 
    handleBuyNow,
    handleAddCard 
  };
};

export default useBuySubscription;
