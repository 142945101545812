export const formatValueWithAsset = (value, asset = "") => {
  if (value > 0) return `+ ${value} ${asset}`;
  if (value < 0) return `- ${value.substring(1)} ${asset}`;
  return `${value} ${asset}`;
};

export const formatValue = (value=0) => {
  if (value > 0)  return "+ " + value;
  if (value < 0) return "- " + value.toString().substring(1);
  return +value;
};

export const setColorClass = (value, zeroClass) => {
  switch (true) {
    case value > 0:
      return "text-success";

    case value < 0:
      return "text-danger";

    default:
      return zeroClass || "text-white";
  }
};

export const truncate = (value, toChar) => {
  if (value?.length > toChar) return value.slice(0, toChar).concat('...');
  return value;
};

export const formatterToNull = (value) => value || 0;

export const getAssetFromPair = (pair, asset="base", separator="-") => {
  if (!pair || !pair.split) return "";
  return pair.split(separator)[asset === "base" ? 0 : 1];
};

export const formatWithReplacing = (pair, base="-", quote=" / ") => {
  return pair.replace(base, quote);
};

export const setOrdersProgressColor = (value) => {
  if (!value) return "#363A54";
  if (value > 0 && value < 100) return "var(--info)";
  if (value === 100) return "#3FCBB0";
}

export const formatBooleanToText = (value) => {
  return value ? "Yes" : "No"
}

export const toFixedToNumber = (x) => {
  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += (new Array(e + 1)).join('0');
    }
  }
  return x;
}

export const getDaysDifference = (specifiedDate) => {
  if (!specifiedDate) {
    return 0;
  } else {
    const timeDifference = new Date() - new Date(specifiedDate);
    return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  }
}

export const firstLetterUpper = (str) => {
  if (!str) {
    return "";
  } else {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}

export const formatPluralSingular = (total, singularLabel, pluralLabel) => {
  if (total ===1 ) {
    return `${total} ${singularLabel}`
  } else {
    return `${total} ${pluralLabel}`
  }
};