import React, {useState} from "react";
import styled from "styled-components";
import {Card, CardBody, Tooltip} from "reactstrap";
import {ReactComponent as BalancesIcon} from "assets/img/icons/Balances.svg";
import {ReactComponent as Alert} from "assets/img/icons/alert.svg";
import {formatterToNull, formatValue, setColorClass} from "helpers/valuesFormatters";
import {investedPercentColors} from "helpers/investedPercentColors";

const Balances = ({balance, dailyRevenuePercent}) => {
  const [tooltip, setTooltip] = useState(false);
  const toggle = () => setTooltip(!tooltip);

  return (
    <Card className="mb-4">
      <CardBody className="p-4">
        <div className="d-flex align-items-center">
          <BalancesIcon/>
          <p className="mb-0 ml-2 font-weight-500 text-white">Balances</p>
        </div>
        <div className="d-flex flex-column mt-4">
          <div className="mt-2 d-flex flex-column flex-sm-row flex-md-column flex-lg-row align-items-sm-center
            align-items-md-start align-items-lg-center justify-content-between"
          >
            <p className="mb-0 text-white-75 font-weight-300 font-sm">
              Available
            </p>
            <div className="d-flex align-items-center">
              <Balances.Amount amount={balance.available.total}>
                {formatterToNull(balance.available.total)}
              </Balances.Amount>
              <p className="mb-0 ml-1 font-weight-300 text-white-35">
                {balance.available.currency}
              </p>
            </div>
          </div>

          <div className="mt-2 d-flex flex-column flex-sm-row flex-md-column flex-lg-row align-items-sm-center
            align-items-md-start align-items-lg-center justify-content-between"
          >
            <p className="mb-0 text-white-75 font-weight-300 font-sm">
              Invested
            </p>
            <div className="d-flex align-items-center">
              {
                balance.invested.percent > 0 &&
                <p className={`${investedPercentColors(balance.invested.percent)} mb-0 mr-2 text-nowrap font-sm`}>
                  {`${balance.invested.percent || 0} %`}
                </p>
              }
              <Balances.Amount amount={balance.invested.amount}>
                {formatterToNull(balance.invested.amount)}
              </Balances.Amount>
              <p className="mb-0 ml-1 font-weight-300 text-white-35">
                {balance.invested.currency}
              </p>
            </div>
          </div>
          {
            balance?.onOrders?.total > 0 &&
            <div className="mt-2 d-flex flex-column flex-sm-row flex-md-column flex-lg-row align-items-sm-center
              align-items-md-start align-items-lg-center justify-content-between"
            >
              <p className="mb-0 text-white-75 font-weight-300 font-sm">
                On orders
              </p>
              <div className="d-flex align-items-center">
                <Balances.Amount amount={balance.onOrders.total}>{balance.onOrders.total}</Balances.Amount>
                <p className="mb-0 ml-1 font-weight-300 text-white-35">
                  {balance.onOrders.currency}
                </p>
              </div>
            </div>
          }
          {
            balance?.hold?.total > 0 &&
            <div className="mt-2 d-flex flex-column flex-sm-row flex-md-column flex-lg-row align-items-sm-center
              align-items-md-start align-items-lg-center justify-content-between"
            >
              <div className="d-flex align-items-center">
                <p className="mb-0 text-white-75 font-weight-300 font-sm">
                  Hold
                </p>
                <Balances.Hold id="hold">
                  <Alert/>
                </Balances.Hold>
                <Tooltip placement="top" isOpen={tooltip} target="hold" toggle={toggle}>
                  Frozen amount for the currency
                </Tooltip>
              </div>
              <div className="d-flex align-items-center">
                <Balances.Amount amount={balance.hold.total}>{balance.hold.total}</Balances.Amount>
                <p className="mb-0 ml-1 font-weight-300 text-white-35">
                  {balance.hold.currency}
                </p>
              </div>
            </div>
          }
          <div className="mt-2 d-flex flex-column flex-sm-row flex-md-column flex-lg-row align-items-sm-center
            align-items-md-start align-items-lg-center justify-content-between"
          >
            <p className="mb-0 text-white-75 font-weight-300 font-sm">
              Estimated
            </p>
            <div className="d-flex align-items-center">
              <Balances.Amount amount={balance.estimated.total}>
                {formatterToNull(balance.estimated.total)}
              </Balances.Amount>
              <p className="mb-0 ml-1 font-weight-300 text-white-35">
                {balance.estimated.currency}
              </p>
            </div>
          </div>

          <div className="mt-2 d-flex flex-column flex-sm-row flex-md-column flex-lg-row align-items-sm-center
            align-items-md-start align-items-lg-center justify-content-between"
          >
            <p className="mb-0 text-white-75 font-weight-300 font-sm">
              Daily revenue
            </p>
            <p
              className={`${setColorClass(dailyRevenuePercent, "text-white-35")} mb-0 font-weight-500 text-nowrap`}>
              {`${formatValue(dailyRevenuePercent) || "0.00"} %`}
            </p>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

Balances.Amount = styled.p`
  margin-bottom: 0;
  font-weight: 500;
  color: ${(props) => props.amount > 0 ? "#FFFFFF" : "rgba(255, 255, 255, 0.35)"} !important;
`;

Balances.Hold = styled.div`
  cursor: pointer;
  svg {
    path {
      stroke: rgba(255, 255, 255, 0.75);
    }
    rect {
      stroke: rgba(255, 255, 255, 0.35);
    }
  }
`;

export default Balances;
