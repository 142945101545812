export const RETURN_OPTIONS = [
  {value: "gt", label: "Profit", color: "#3FCBB0"},
  {value: "lt", label: "Loss", color: "#EA4848"},
];

export const STATUS_OPTIONS = [
  { value: "completed", label: "Completed", color: "#5FD3BC" },
  { value: "cancelled", label: "Cancelled", color: "rgba(255, 255, 255, 0.35)" },
];
export const CONDITIONS_OPTIONS = [
  { value: "fulfilled", label: "Fulfilled", color: "#5FD3BC" },
  { value: "not_fulfilled", label: "Not satisfied", color: "rgba(255, 255, 255, 0.35)" },
];
export const INDICATORS_OPTIONS = [
  { value: "buy", label: "Buy", color: 'success' },
  { value: "sell", label: "Sell", color: 'danger' },
  { value: "both", label: "Both", color: 'warning' },
];
