import React, { createContext } from "react";

export const Context = createContext({});

export const OverlayContext = ({ children, ...props }) => {
  return (
    <Context.Provider {...props}>
      {children}
    </Context.Provider>
  );
};
