import React, { useState } from "react";
import styled from "styled-components";
import Icon from "components/Icon";
import { ReactComponent as ForkOff } from "assets/img/icons/forkOff.svg";
import { ReactComponent as Fork } from "assets/img/icons/fork.svg";
import { capitalize } from "lodash";
import IdWithCopy from "components/IdWithCopy";
import { setColorClass } from "helpers/valuesFormatters";
import { CardHeader, Tooltip } from "reactstrap";
import { ReactComponent as DailyUp } from "assets/img/icons/dailyUp.svg";
import { ReactComponent as DailyDown } from "assets/img/icons/dailyDown.svg";

const CredentialListHeader = ({data, index, isActive}) => {

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipOpenBalance, setTooltipOpenBalance] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const toggleBalance = () => setTooltipOpenBalance(!tooltipOpenBalance);

  const renderDailyIcon = (daily) => {
    if (daily > 0) return <DailyUp className="mr-2" />;
    if (daily < 0) return <DailyDown className="mr-2" />;
    return null;
  }

  return (
    <CredentialListHeader.CardHeader isActive={isActive} >
      <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
        <div className="d-flex overflow-hidden mr-4">
          <div className="d-flex position-relative">
            <Icon
              type="exchanges"
              size="xs"
              code={data.exchange}
              className="mr-3 my-auto"
            />
            <CredentialListHeader.IconWrapper className="connect-icon">
              {
                data.connected && data.status !== "pending_delete"
                  ? <Fork width="100%" height="100%"/>
                  : <ForkOff width="100%" height="100%"/>
              }
            </CredentialListHeader.IconWrapper>
          </div>
          <CredentialListHeader.Header>
            <div className="d-flex align-items-baseline text-center pt-1 pb-1">
              <h2 className="mb-0 text-white font-weight-500">
                {capitalize(data.exchange)}
              </h2>
              {data.status === "pending_delete" && <CredentialListHeader.StatusWrapper className="d-none d-md-flex ml-2">
                <CredentialListHeader.StatusText>
                  Pending delete
                </CredentialListHeader.StatusText>
              </CredentialListHeader.StatusWrapper>}
              <p className="d-none font-sm d-md-block ml-2 mb-0 text-white-35">
                {data.comment}
              </p>
            </div>
            <IdWithCopy
              className="d-none d-md-flex"
              id={data.id}
            />
          </CredentialListHeader.Header>
        </div>
        <p className="d-flex d-md-none mt-2 mb-3 font-sm">
          {data.status === "pending_delete" && <CredentialListHeader.StatusWrapper className="mr-2">
            <CredentialListHeader.StatusText>
              Pending delete
            </CredentialListHeader.StatusText>
          </CredentialListHeader.StatusWrapper>}
          {data.comment}
        </p>
        <CredentialListHeader.Amount isTransparent={data.status === "pending_delete"} className="d-flex align-items-start align-items-md-end flex-column text-nowrap">
          <div className="d-flex align-items-center">
            <CredentialListHeader.Daily id={`dailyRevenue-${index}`} daily={data.dailyRevenueBtcPercent}>
              {renderDailyIcon(data.dailyRevenueBtcPercent)}
              <p className={`mb-0 ${setColorClass(data.dailyRevenueBtcPercent, "text-white-35")}`}>
                {`${data?.dailyRevenueBtcPercent?.toString()?.replace("-", "") || "0.00"} %`}
              </p>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target={`dailyRevenue-${index}`}
                toggle={toggle}
              >
                24h change
              </Tooltip>
            </CredentialListHeader.Daily>
            <p className="font-weight-500 text-white mb-0 font-md" id={`balance-${index}`}>
              {`${data?.balance?.btc || "0"} BTC`}
            </p>
            {!data.connected && <Tooltip
              placement="top"
              isOpen={tooltipOpenBalance}
              target={`balance-${index}`}
              toggle={toggleBalance}
            >
              Total balance can be outdated
            </Tooltip>}
          </div>
          <p className="text-white-35 mb-0 font-sm mt-2 mt-md-0">
            {`${data.estimated.amount || "0.00000000"} ${data.estimated.currency}`}
          </p>
        </CredentialListHeader.Amount>
      </div>
    </CredentialListHeader.CardHeader>);
};

CredentialListHeader.CardHeader = styled(CardHeader)`
  cursor: pointer;
  padding: 24px !important;
  background: ${({isActive})=> isActive ?
          "linear-gradient(0deg, rgba(47, 128, 237, 0.07) 0%, rgba(47, 128, 237, 0.07) 100%), #2E3148 !important":
          "#2E3148 !important"
  } ;
  border:${({isActive})=> isActive ?
          "2px solid var(--info) !important":
          "2px solid var(--BG-2nd-block, #2E3148) !important"
  } ;

  &:hover {
    background: #363A54 !important;
    .connect-icon {
      background: #363A54 !important;
    }
  }
`;

CredentialListHeader.Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

CredentialListHeader.IconWrapper = styled.div`
  position: absolute;
  transform: scale(-1, 1);
  padding: 2px;
  width: 28px;
  height: 28px;
  right: 10%;
  bottom: -10%;
  background: #2E3148 !important;
  border-radius: 50px;
`;

CredentialListHeader.Amount = styled.div`
  opacity: ${({isTransparent}) =>(isTransparent ? 0.2 : 1)}
`

CredentialListHeader.Daily = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  background: rgba(63, 203, 176, 0.1);
  border-radius: 6px;
  padding: 5px 6px;
  background: ${(props) => {
  if (props.daily > 0) {return "rgba(63, 203, 176, 0.1)"}
  if (props.daily < 0) {return "rgba(237, 95, 95, 0.1)"}
  return "rgba(255, 255, 255, 0.1)";
} };

  p {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
  }
`;

CredentialListHeader.StatusWrapper = styled.div`
  display: flex;
  height: 23px;
  padding: 0 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--white-3, rgba(255, 255, 255, 0.03));
`;

CredentialListHeader.StatusText = styled.span`
  color: #ED5F5F;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export default CredentialListHeader;
