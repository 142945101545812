import React from "react";
import { CardHeader } from "reactstrap";

import SkeletonPreloader from "components/SkeletonPreloader";

const DealsFiltersPreloader = () => {
  return (
    <CardHeader className="d-flex justify-content-between align-center flex-column flex-lg-row px-4 pb-3 pt-4">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <SkeletonPreloader
            circle={true}
            height={20}
            width={20}
          />
          <SkeletonPreloader
            height={13}
            width={100}
            className="ml-2"
          />
        </div>
      </div>
      <div className="d-flex flex-column flex-lg-row align-items-center justify-content-end mt-3 mt-lg-0 w-75">
        <SkeletonPreloader
          height={38}
          borderRadius="6px"
          className="w-100 ml-0 mr-0 mr-lg-2 mb-2 mb-lg-0"
        />
        <SkeletonPreloader
          height={38}
          borderRadius="6px"
          className="w-100 ml-0 mr-0 mr-lg-2 mb-2 mb-lg-0"
        />
        <SkeletonPreloader
          height={38}
          borderRadius="6px"
          className="w-100 ml-0 mr-0 mr-lg-2 mb-2 mb-lg-0"
        />
      </div>
    </CardHeader>
  );
};

export default DealsFiltersPreloader;
