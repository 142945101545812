import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  Card,
  CardBody
} from "reactstrap";
import { findKey } from "lodash";

import StepsHeader from "./components/StepsHeader";
import Steps from "./components/Steps";
import usePersistedState from "hooks/usePersistedState";
import SubscriptionExpired from "./components/SubscriptionExpired";
import {
  selectWizardSteps,
  selectCurrentPlan,
  selectMeLoading
} from "store/user/selectors";
import { updateWizard } from 'store/user/actions';

const Wizard = ({loadingPage}) => {
  const [currentStep, setCurrentStep] = useState("credentials");
  const wizard = useSelector(selectWizardSteps);
  const currentPlan = useSelector(selectCurrentPlan);
  const isLoading = useSelector(selectMeLoading);
  const dispatch = useDispatch();
  const [skipped, setSkipped] = usePersistedState("skipped", false);

  useEffect(() => {
    if (wizard) {
      const firstFalseItem = findKey(wizard, (value) => !value);
      setCurrentStep(firstFalseItem);
    }
  }, [wizard, setCurrentStep]);

  const handleUpdateWizard = (data) => dispatch(updateWizard(data));

  if (isLoading || skipped || !currentStep || loadingPage) return null;

  return (
    <>
      <Card className='mb-2'>
        <CardBody className="p-0">
          <StepsHeader
            wizard={wizard}
            currentStep={currentStep}
          />
        </CardBody>
      </Card>
      <Card>
        <CardBody className="p-0">
          <StyledStepsWrapper>
            <Steps
              currentStep={currentStep}
              wizard={wizard}
              updateWizard={handleUpdateWizard}
              setCurrentStep={setCurrentStep}
              setSkipped={setSkipped}
            />
          </StyledStepsWrapper>
        </CardBody>
      </Card>
      {
        currentPlan?.status === "paused" ?
        <SubscriptionExpired />
        : null
      }
    </>
  );
};

const StyledStepsWrapper = styled.div`
  padding: 1.25rem 3.5rem 1.25rem 3rem;
`;

export default Wizard;
