import { useState, useEffect } from "react";

const useField = (formValues, onChange) => {
  const [errors, setErrors] = useState({});

  const fieldProps = {
    onChange: ({ target }) => onChange({ [target.name]: target.value })
  };

  useEffect(() => {
    const errors = {};
    for (let key in formValues) {
      if (Object.prototype.hasOwnProperty.call(formValues, key) && !formValues[key]) {
        errors[key] = "This field is required.";
      }
      setErrors(errors);
    }
  }, [formValues]);

  return [fieldProps, errors];
};

export default useField;
