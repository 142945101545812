import React from "react";
import { CardBody } from "reactstrap";

import { formatterToNull } from "helpers/valuesFormatters";
import { ProfitStatistics } from "../../views/channels/channelPropTypes";
import styled from "styled-components";

const ChannelStatisticsBody = ({
  average,
  total,
  month,
}) => {
  const isProfit = (value) => +value ? "text-white" : "text-white-35";

  return (
    <CardBodyWrap className="p-0">
      <div className="d-flex justify-content-between align-items-center">
        <p className="font-sm font-weight-400 text-white-75 mb-0">
          Average profit
        </p>
        <p className={`mb-0 font-sm font-weight-500 ${isProfit(average)}`}>
          {`${formatterToNull(average)} %`}
        </p>
      </div>
      <div className="d-flex justify-content-between align-items-center my-1">
        <p className="font-sm font-weight-400 text-white-75 mb-0">
          Total profit
        </p>
        <p className={`mb-0 font-sm font-weight-500 ${isProfit(total)}`}>
          {`${formatterToNull(total)} %`}
        </p>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <p className="font-sm font-weight-400 text-white-75 mb-0">
          Month profit
        </p>
        <p className={`mb-0 font-sm font-weight-500 ${isProfit(month)}`}>
          {`${formatterToNull(month)} %`}
        </p>
      </div>
    </CardBodyWrap>
  );
};

const CardBodyWrap = styled(CardBody)`
  flex: initial !important;
`

ChannelStatisticsBody.propType = ProfitStatistics;

export default ChannelStatisticsBody;