import React, { useState } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import {
  Nav,
  NavItem,
  Collapse
} from "reactstrap";
import PropTypes from "prop-types";

import Caret from "components/Caret";

const CollapseNavItem = ({ 
  navItem, 
  handleOnLinkClick, 
  expanded, 
  getIcon,
  toggleSidebar,
  isMobile
}) => {
  const [collapseOpen, setCollapseOpen] = useState(false);

  return (
    <NavItem
      key={navItem.name}
      className={classNames({
        active: navItem.collapse.findIndex((item) => item.path === window.location.pathname) !== -1
      })}
    >
      <CollapseNavItem.MenuItem
        href=""
        data-toggle="collapse"
        className="nav-link text-white d-inline-flex"
        onClick={(e) => {
          e.preventDefault();
          setCollapseOpen(!collapseOpen);
          handleOnLinkClick()
        }}
      >
        {getIcon(navItem.icon)}{" "}
        {expanded && navItem.name}
        {expanded && <Caret isOpen={collapseOpen} />}
      </CollapseNavItem.MenuItem>
      {<Collapse navbar isOpen={collapseOpen}>
        <Nav vertical>
          {navItem.collapse.map((item) =>
            <CollapseNavItem.StyledNavItem key={item.name} activeclassname="active">
              <CollapseNavItem.NavLink
                expanded={expanded}
                disabled={item.disabled}
                onClick={()=> {
                  handleOnLinkClick()
                  if(isMobile) return toggleSidebar(expanded)
                }}
                to={item.path}
                className={`nav-link ${expanded ? "ml-5" : "ml-4"}`}
                exact
              >
                {expanded ? item.name : item.mini}
              </CollapseNavItem.NavLink>
            </CollapseNavItem.StyledNavItem>
          )}
        </Nav>
      </Collapse>}
    </NavItem>
  );
};

CollapseNavItem.StyledNavItem = styled(NavItem)`
  .active {
    opacity: 1;
  }
`;
CollapseNavItem.NavText = styled.span`
  line-height: 12px;
`;
CollapseNavItem.MenuItem = styled.a`
  font-size: 14px !important;
  font-weight: 400 !important;
`;
CollapseNavItem.NavLink = styled(NavLink)`
  font-weight: 500 !important;
  font-size: 14px !important;
  margin-bottom: 12px;
  text-transform: initial !important;
  opacity: 0.7;

  ${(props) => props.disabled &&
    `pointer-events: none;
     color: rgb(255, 255, 255, 0.5) !important;
     i {
       color: rgb(255, 255, 255, 0.5) !important;
     }
  `}
`;

CollapseNavItem.propTypes = {
  navItem:PropTypes.shape({
    name:PropTypes.string.isRequired,
    icon:PropTypes.string.isRequired,
    collapse:PropTypes.arrayOf(
      PropTypes.shape({
        name:PropTypes.string.isRequired,
        path:PropTypes.string.isRequired,
        disabled:PropTypes.bool
      })
    ),
  }).isRequired,
  handleOnLinkClick:PropTypes.func.isRequired,
  expanded:PropTypes.bool.isRequired,
  getIcon:PropTypes.func.isRequired
}

export default CollapseNavItem;
