import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {Card} from "reactstrap";

import PortfolioTable from "./PortfolioTable";
import LineWithError from "shared/ui/LineWithError";
import CredentialListHeader from "./CredentialListHeader";
import CustomScrollbars from "components/CustomScrollbars";

const Credential = ({data, subscription, id, index}) => {
  return (
    <Credential.Card className="mb-3">
      <Link to={`/credentials/${id}`}>
        <CredentialListHeader data={data} index={index}/>
      </Link>
      <LineWithError errorMessage={data?.error} radius="0"/>
      {!data?.error ?
        <CustomScrollbars
          autoHideTimeout={500}
          autoHideDuration={500}
          autoHeight
          autoHeightMax={Number.MAX_VALUE}
        >
          <PortfolioTable
            credentialId={id}
            portfolio={data.portfolio}
            currency={data.currency}
            balance={data.balance}
            connected={data.connected}
            id={data.id}
            subscription={subscription}
            exchangeIndex={index}
            autoRebalancing={data?.rebalance}
            status={data.status}
          />
        </CustomScrollbars>
        :
        <PortfolioTable
            credentialId={id}
            portfolio={data.portfolio}
            currency={data.currency}
            balance={data.balance}
            connected={data.connected}
            id={data.id}
            subscription={subscription}
            exchangeIndex={index}
            autoRebalancing={data?.rebalance}
            status={data.status}
          />
      }
    </Credential.Card>
  );
};

Credential.Card = styled(Card)`
  cursor: pointer;
  border-radius: 8px !important;
  overflow: hidden;
`;

export default Credential;
