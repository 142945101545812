import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import ChannelLogo from "components/ChannelLogo";
import { stringOrNull } from "../channelPropTypes";
import { formatPluralSingular } from "helpers/valuesFormatters";

const ChannelLabel = ({
  logo,
  name,
  totalSubscribers
}) => {
  return (
    <ChannelLabel.Wrap className="d-flex">
      <ChannelLogo
        logo={logo}
        size={40}
      />
      <div className="ml-3">
        <h3 className="mb-1">
          {name}
        </h3>
        <p className="text-white-75 font-weigth-400 font-sm mb-0">
          {formatPluralSingular(totalSubscribers, "Subscriber", "Subscribers")}
        </p>
      </div>
    </ChannelLabel.Wrap>
  );
};

ChannelLabel.Wrap = styled.div`
padding: 12px 16px 12px 14px;
align-items: flex-start;
border-radius: 8px;
border: 1px solid var(--Backgrounds-and-shades-4, #4A4E73);
background: var(--dusk-steel);
`;

ChannelLabel.propTypes = {
  logo: stringOrNull,
  name: PropTypes.string,
  totalSubscribers: PropTypes.number
};

export default ChannelLabel;