import styled from "styled-components";

import { ReactComponent as IndicatorIcon } from "assets/img/icons/dropdownIndicator.svg";

export const AccordionWrapperStyled = styled.div`
  border-radius: 6px;
`;

export const AccordionHeaderStyled = styled.div`
  background: #2e3148;
  padding: .5rem;
  cursor: pointer;

  ${({ disable }) =>
    disable
      ? `p{
    color: rgba(255, 255, 255, 0.35) !important
    }
    svg{
      opacity:.35;
    }
  `
      : `&:hover {
    background: #31334E;
  }`}
`;

export const AccordionContentStyled = styled.div`
  padding-top:.75rem;
  background: var(--dark-slate);
`;

export const AccordionIndicatorIcon = styled(IndicatorIcon)`
  transition: all 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
`;
