import React, { useState } from "react";
import { 
  useDispatch, 
  useSelector 
} from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import qs from "qs";

import {
  CardFooter,
  CardBody,
  FormGroup
} from "reactstrap";

import BotMarketsSelect from "components/BotMarketsSelect";
import NumberInput from "components/NumberInput";
import FormGroupWithSymbols from "components/FormGroupWithSymbols";
import ErrorAlert from "components/ErrorAlert";
import Button from "components/Button";
import { InfoNotification } from "shared/ui";
import { getAssetFromPair } from "helpers/valuesFormatters";
import { addSnackBar } from "store/snackbar/actions";
import { 
  createBacktest,
  getBacktests
} from "store/bots/actions";

const BACKTEST_SCHEMA = Yup.object().shape({
  market: Yup.string()
    .required("This field is required."),
});

const BacktestForm = ({ 
  handleToggleModal,
  botId
}) => {
  const dispatch = useDispatch();
  const bot = useSelector((state) => state.bots.item.data);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    values,
    setFieldValue,
    errors,
    handleSubmit,
    isSubmitting,
    setErrors
  } = useFormik({
    initialValues: {
      market: bot?.market,
      initialBalance: 100
    },
    validationSchema: BACKTEST_SCHEMA,
    onSubmit: async({ start, end, market, initialBalance }, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      const reqBody = {
        start,
        end,
        initialBalance,
        market: {
          base: getAssetFromPair(market),
          quote: getAssetFromPair(market, "quote")
        }
      };

      const res = await dispatch(createBacktest(bot.id, reqBody));
      if (!res.errors) {
        const queryString = qs.stringify({filter: {"backtest.bot": {eq: botId}}, limit: 10});
        handleToggleModal();
        dispatch(getBacktests(`?${queryString}`));
        return dispatch(addSnackBar("success", res.message));
      }
      setErrors({message:res.message});
      setSubmitting(false);
      setIsSubmitted(false);
    },
  });

  const showValidationError = (field) => {
    if (errors[field] && isSubmitted) return (
      <p className="text-danger font-md m-0 pt-3">
        {errors[field]}
      </p>
    );
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    handleSubmit();
  };

  const handleCancel = (e) => {
    e.preventDefault();
    handleToggleModal();
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <CardBody className="px-4 py-0">
        <InfoNotification
          className="mb-4"
          text="Please note: backtest will be conducted using the latest 500 candles from the stock exchange."
        />
        <FormGroup className="mb-3">
          <BotMarketsSelect
            id={botId}
            hasError={isSubmitted && errors.market}
            name="market"
            placeholder="Market"
            value={values.market ? {
              label: values.market?.replace("-", " / "),
              value: values.market,
              icon: {
                type: "cryptocurrency",
                size: "selectSize",
                code: getAssetFromPair(values.market),
              }
            } : null}
            onChange={({ value }) => setFieldValue("market", value)}
          />
          {showValidationError("market")}
        </FormGroup>
        <label
          htmlFor="initialBalance"
          className="text-white-75"
        >
          Initial balance
        </label>
        <FormGroupWithSymbols symbolRight={getAssetFromPair(values.market, "quote")}>
          <NumberInput
            id="initialBalance"
            name="initialBalance"
            min={0}
            onChange={(value) => setFieldValue("initialBalance", value)}
            placeholder=""
            className={`form-control mb-3 ${isSubmitted && errors.initialBalance ? " has-error" : ""}`}
            value={values.initialBalance}
          />
        </FormGroupWithSymbols>
        {(values.initialBalance < 1 || values.initialBalance > 1000000) && !errors.message &&
        <div className="text-danger">{'This value should be between "1" and "1000000"'}</div>}
        <ErrorAlert
          fade={false}
          className="m-0"
          toggle={() => setErrors({ ...errors, message: "" })}
          isOpen={!!errors?.message}
        >
          {errors?.message}
        </ErrorAlert>
      </CardBody>
      <CardFooter className="p-4">
        <div className="d-flex">
          <Button
            type="button"
            onClick={handleCancel}
            className="w-100 mr-2"
            outline
          >
            Cancel
          </Button>
          <Button
            loading={isSubmitting}
            disabled={isSubmitting}
            color="blue"
            className="w-100"
            type="submit"
          >
            Run
          </Button>
        </div>
      </CardFooter>
    </form>
  );
};

export default BacktestForm;
