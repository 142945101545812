/* eslint-disable no-unused-vars */
import React from "react";
import classNames from "classnames";
import { useFormikContext } from "formik";

import TradeSettingsForm from "./TradeSettingsForm";
import TradeSettingsPreloader from "./TradeSettingsPreloader";

const TradeSettings = ({
  redirectPath,
  buySignals,
  sellSignals,
  botData,
  loading,
  currentStep,
  loadingWizard,
  submitted,
  setSubmitted,
  alertOpen,
  setAlertOpen,
  isEdit,
  botIndicators,
}) => {
  const { isSubmitting, status } = useFormikContext();

  if (loading || loadingWizard) return <TradeSettingsPreloader />;

  return (
    <div className={classNames({ "opacity-5": isSubmitting })}>
      <TradeSettingsForm
        isEdit={isEdit}
        botData={botData}
        submitted={submitted}
        setSubmitted={setSubmitted}
        status={status}
        botIndicators={botIndicators}
        alertOpen={alertOpen}
        onSetAlertOpen={setAlertOpen}
        redirectPath={redirectPath}
        currentStep={currentStep}
        buySignals={buySignals}
        sellSignals={sellSignals}
      />
    </div>
  );
};

export default TradeSettings;
