import React from "react";
import styled from "styled-components";
import {minMaxRange} from "models/bot/enums";
import {getPP} from "helpers/pivotPoint/pivotPoint";
import {ReactComponent as Bar} from "assets/img/icons/barChart.svg";
import BadgeSignal from "components/BadgeSignal";
import PivotPointsRange from "components/PivotPointsRange";
import SkeletonPreloader from "components/SkeletonPreloader";
import MarketSummaryEmptyStates from "./MarketSummaryEmptyStates";
import PivotPointMarketPreloader from "./PivotPointMarketPreloader";

const PivotPointMarket = ({pivotPoint, analysisMarketLoading, loadingPage, pivotPointStateResponse, botData}) => {
  return (
    <>
      <PivotPointMarket.Header className="d-flex align-items-center justify-content-between py-2 px-4">
        {
          loadingPage || analysisMarketLoading ?
            <div className="d-flex align-items-center justify-content-between py-2 w-25">
              <SkeletonPreloader width={247} height={12}/>
              <SkeletonPreloader width={105} height={9}/>
            </div>
            :
            <>
              <PivotPointMarket.Title>
                Pivot point
              </PivotPointMarket.Title>

              {
                pivotPoint?.enabled &&
                <p className="mb-0 text-white-35 font-sm">
                  {`Checked ${pivotPointStateResponse?.updatedAt}`}
                </p>
              }
            </>
        }
      </PivotPointMarket.Header>

      {
        loadingPage || analysisMarketLoading ?
          <PivotPointMarketPreloader/> : null
      }

      {
        !loadingPage && !analysisMarketLoading && pivotPoint?.enabled ?
          <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between p-4">
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <Bar/>
              <div className="d-flex flex-column ml-3">
                <p className="mb-0 text-white font-weight-500">
                  Pivot Point
                </p>
                <p className="mb-0 text-white-75 font-sm text-capitalize">
                  {`${pivotPoint?.calculation}, ${pivotPoint?.timeframe}`}
                </p>
              </div>
            </div>

            <PivotPointMarket.Pivot className="d-flex flex-column">
              <PivotPointsRange
                minMaxRange={minMaxRange[pivotPoint?.calculation]}
                checkedSupport={pivotPoint?.hasSupport}
                checkedResistance={pivotPoint?.hasResistance}
                isEditable={false}
                displayMark
                values={{
                  pivotPoint: getPP({
                    resistanceBottom: pivotPoint?.resistanceBottom,
                    resistanceTop: pivotPoint?.resistanceTop,
                    supportBottom: pivotPoint?.supportBottom,
                    supportTop: pivotPoint?.supportTop
                  })
                }}
                handlePPRanges={() => null}
                currentLevel={pivotPointStateResponse?.level}
                currentSignal={pivotPointStateResponse?.signal}
              />
            </PivotPointMarket.Pivot>

            <PivotPointMarket.Signal>
              <BadgeSignal signal={pivotPointStateResponse?.signal}/>
            </PivotPointMarket.Signal>
          </div>
          : null
      }
      {
        !loadingPage && !analysisMarketLoading && !pivotPoint?.enabled ?
          <MarketSummaryEmptyStates
            title="This bot doesn't use pivot point for trading"
            values={pivotPoint?.enabled}
            botValues={botData?.tradeSettings?.pivotPoint?.enabled}
          /> : null
      }
    </>
  )
}

PivotPointMarket.Pivot = styled.div`
  @media (max-width: 767.98px) {
    width: 100% !important;
  }
  width: 50%;
`;

PivotPointMarket.Signal = styled.div`
  @media (max-width: 767.98px) {
    position: absolute;
    text-align: right;
    right: 24px;
  }
`;

PivotPointMarket.Title = styled.p`
  color: rgba(125, 129, 171, 1) !important;
  font-weight: 500;
  margin-bottom: 0;
  text-transform: uppercase;
`;

PivotPointMarket.Header = styled.div`
  background: rgba(46, 49, 72, 1);
`;

export default PivotPointMarket;