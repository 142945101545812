import { sortWithSumOrderBook, convertOrderBook, updateOrders } from "../utils";

export const serializeTicker = ([symbol, data]) => ({
  symbol,
  open: data.o,
  high: data.h,
  low: data.l,
  close: data.c,
  dailyChange: data.P,
  amount: data.q
});

const serializeTrades = (data) => ({
  type: data.s,
  quantity: data.q,
  amount: data.q * data.p,
  price: data.p,
  createTime: data.t
});

const getConvertedOrderbook = (message, type="snapshot") => {
  const market = Object.keys(message[type])[0];
  const bids = message[type]?.[market]?.b.length ? convertOrderBook(message[type]?.[market]?.b) : [];
  const asks = message[type]?.[market]?.a.length ? convertOrderBook(message[type]?.[market]?.a) : [];
  return { asks, bids };
}

export const handleHitBtcMessage = (message, setTicker, setOrderBook, setTrades, handleLastIdCheck, depth) => {
  if (message.ch === "ticker/1s") {
    const dataEntry = Object.entries(message.data);
    setTicker([serializeTicker(dataEntry[0])]);
  }

  if (message.ch === "orderbook/full" && message.snapshot) {
    const { asks, bids } = getConvertedOrderbook(message, "snapshot");

    setOrderBook({
      asks: sortWithSumOrderBook(asks, "asks").splice(0, depth),
      bids: sortWithSumOrderBook(bids, "bids").splice(0, depth),
    });
  }

  if (message.ch === "orderbook/full" && message.update) {
    const { asks, bids } = getConvertedOrderbook(message, "update");

    setOrderBook((prev) => ({
      asks: updateOrders(prev.asks, asks, "asks").splice(0, depth),
      bids: updateOrders(prev.bids, bids, "bids").splice(0, depth),
    }));
  }

  if (message.ch === "trades" && message.snapshot) {
    const market = Object.keys(message.snapshot)[0];
    const snapshot = message.snapshot[market].map((item) => serializeTrades(item));
    setTrades((prev) => ({ ...prev, data: snapshot.splice(0, 10) }));
  }

  if (message.ch === "trades" && message.update) {
    const market = Object.keys(message.update)[0];
    const update = message.update[market].map((item) => serializeTrades(item));
    setTrades((prev) => ({ ...prev, data: [...update, ...prev.data].splice(0, 10) }));
  }
};
