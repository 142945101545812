import React, { 
  useEffect, 
  useState
} from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import styled from "styled-components";
import { Label } from "reactstrap";
import { useFormikContext } from "formik";

import { ReactComponent as BarChart } from "assets/img/icons/barChart.svg";
import { ReactComponent as PlusIcon } from "assets/img/icons/plus.svg";
import Indicator from "./Indicator";
import IndicatorsModal from "../../indicators/IndicatorsModal";
import { wizardIndicatorsSelector } from "store/strategies/selectors";
import { getIndicatorsConfiguration } from "store/indicatorsConfiguration/actions";
import NumberInput from "components/NumberInput";
import FormGroupWithSymbols from "components/FormGroupWithSymbols";

const Indicators = ({
  botIndicators,
  isEdit,
  buySignals,
  sellSignals,
  submitted
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const indicatorsList = useSelector((state) => wizardIndicatorsSelector(state));
  const {values, errors, setFieldValue} = useFormikContext();
  const dispatch = useDispatch();

  const indicators = [...botIndicators || [], ...indicatorsList];

  useEffect(() => {
    dispatch(getIndicatorsConfiguration());
  }, [dispatch])

  return (
    <div className="pt-3">
      <div className="d-flex justify-content-between flex-column flex-md-row mb-3">
        <div className="d-flex flex-column justify-content-end">
          <div className="d-flex align-items-center">
            <BarChart className="my-auto mr-2" />
            <p className="mb-0 font-weight-500 text-white">
              Indicators
            </p>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row ml-auto mr-0">
          <div className="d-flex flex-column mr-2">
            <Label className="mb-1 text-white-75 text-nowrap">
              Minimum <span className="text-success font-weight-400">BUY</span> signals
            </Label>
            <FormGroupWithSymbols
              symbolRight={
                <div className="d-flex flex-row align-items-center pr-3">
                  <p className="text-white-35 mb-0 text-nowrap">Out of</p>
                  <span className="text-white ml-1">{buySignals}</span>
                </div>
              }
            >
              <NumberInput
                value={values?.minimumBuySignals}
                type="int"
                name="minimumBuySignals"
                className={`form-control text-white ${submitted && errors?.minimumBuySignals ? "has-error" : ""}`}
                onChange={(value) => {
                  if (!value) {
                    setFieldValue("minimumBuySignals", null)
                  } else {
                    setFieldValue("minimumBuySignals", value)
                  }
                }
                }
                autoComplete="off"
              />
            </FormGroupWithSymbols>
            {
              errors.minimumBuySignals &&
              <p className="form-error font-sm mb-0">
                {`Amount of attached indicators (${buySignals}) is less then entered amount.`}
              </p>
            }
          </div>
          <div className="d-flex flex-column">
            <Label className="mb-1 text-white-75 text-nowrap">
              Minimum <span className="text-danger font-weight-400">SELL</span> signals
            </Label>
            <FormGroupWithSymbols
              symbolRight={
                <div className="d-flex flex-row align-items-center pr-3">
                  <p className="text-white-35 mb-0 text-nowrap">Out of</p>
                  <span className="text-white ml-1">{sellSignals}</span>
                </div>
              }
            >
              <NumberInput
                value={values?.minimumSellSignals}
                type="int"
                name="minimumSellSignals"
                className={`form-control text-white ${submitted && errors?.minimumSellSignals ? "has-error" : ""}`}
                onChange={(value) => {
                  if (!value) {
                    setFieldValue('minimumSellSignals', null)
                  } else {
                    setFieldValue("minimumSellSignals", value)
                  }
                }}
                autoComplete="off"
              />
            </FormGroupWithSymbols>
            {
              errors.minimumSellSignals &&
              <p className="form-error font-sm mb-0">
                {`Amount of attached indicators (${sellSignals}) is less then entered amount.`}
              </p>
            }
          </div>
        </div>
      </div>
      {
        indicators.map(indicator =>
          <Indicator
            isEdit={isEdit}
            key={indicator.id}
            indicator={indicator}
          />
        )
      }
      <Indicators.AddButton
        className="d-flex flex-row justify-content-center align-items-center cursor-pointer"
        onClick={() => setModalIsOpen(true)}
      >
        <PlusIcon />
        <span className="ml-2 font-sm">Add new indicator</span>
      </Indicators.AddButton>
      {
        modalIsOpen &&
        <IndicatorsModal
          isEdit={isEdit}
          indicators={indicators}
          modalIsOpen={modalIsOpen}
          onSetModalIsOpen={setModalIsOpen}
          getIndicatorsConfiguration={getIndicatorsConfiguration}
          draft
        />
      }
    </div>
  );
};

Indicators.AddButton = styled.div`
  background: #242638;
  color: #5E6392;
  height: 50px;
  border: 1px dashed var(--dusk-steel);
  border-radius: 8px;
  transition: all .2s ease-in-out;

  svg {
    path {
      fill: #5E6392;
    }
  }
  :hover {
    background: var(--dark-slate);
    border: 1px solid #4A4E73;
    color: #7D81AB;
  }
`

export default Indicators;
