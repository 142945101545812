import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody
} from "reactstrap";

import HeaderPreLoader from "components/HeaderPreLoader";
import NewTabLinkButton from "components/NewTabLinkButton";
import NameInlineEditor from "components/NameInlineEditor";
import IdWithCopy from "components/IdWithCopy";
import LineWithError from "shared/ui/LineWithError";
import ChangeLogoChannel from "./ChangeLogoChannel";
import ChannelSubscribe from "./ChannelSubscribe";
import { updateInfo } from "store/channels/actions";
import { ReactComponent as TelegramIcon } from "assets/img/icons/telegram.svg";

const ChannelDetailsHeader = ({
  loading,
  handleRefresh,
  channel
}) => {

  if (loading) return <HeaderPreLoader sizeIcon={80} borderRadius="4px" />

  return (
    <Card >
      <CardBody className="p-4 d-flex flex-column flex-md-row justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <ChangeLogoChannel
            channel={channel}
            loading={loading}
            data-testid="change-logo-channel"
          />
          <div className="d-flex flex-column ml-2 ml-md-4 my-auto ">
            <NameInlineEditor
              id={channel?.id}
              name={channel?.name}
              owner={channel?.owner}
              onChangeName={updateInfo}
              description={channel?.description}
              type={channel?.type}
              data-testid="name-inline-editor"
            />
            <IdWithCopy
              id={channel?.id}
              className="d-none d-md-flex"
              data-testid="id-with-copy"
            />
            {
              channel?.telegram
                ? <NewTabLinkButton
                  url={`https://t.me/${channel?.telegram}`}
                  className="py-1 ml-0 mr-auto"
                >
                  <TelegramIcon
                    className="mr-2"
                    width={20}
                    height={20} />
                  <span className="text-white font-sm font-weight-300">
                    Join telegram channel
                  </span>
                </NewTabLinkButton>
                : null
            }

          </div>
        </div>
        <div className="d-flex flex-row flex-md-column my-auto position-relative">
          <ChannelSubscribe
            totalSubscribers={channel?.totalSubscribers}
            subscribed={channel?.subscribed}
            id={channel?.id}
            handleRefresh={handleRefresh}
            maxximun={channel?.subscriberResponse?.maxximun}
            volume={channel?.subscriberResponse?.volume}
            accept={channel?.subscriberResponse?.accept}
            data-testid="channel-subscribe"
          />
        </div>
      </CardBody>
      <LineWithError errorMessage={channel?.error} />
    </Card>
  );
};

ChannelDetailsHeader.propTypes = {
  loading: PropTypes.bool,
  handleRefresh: PropTypes.func,
};

export default ChannelDetailsHeader;