import { Badge } from "reactstrap";
import styled from "styled-components";

import { STATUS_COLORS } from "./colors";

export const StyledBadgeStyled = styled(Badge)`
  color: ${(props) => STATUS_COLORS[props.status]} !important;
  background-color: rgba(255, 255, 255, 0.05) !important;
  border-radius: 6px !important;
  text-transform: capitalize !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
`;