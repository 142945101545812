import React, { useState } from "react";
import styled from "styled-components";
import { useFormikContext } from "formik";

import Button from "components/Button";
import Checkbox from "components/Checkbox";
import TemplateDetailsModal from "./modals/TemplateDetailsModal";

const TemplateItem = ({ strategy }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      <StyledRow onClick={() => setFieldValue("strategy", strategy.id)}>
        <td>
          <div className="d-flex align-items-center">
            <Checkbox
              type="radio"
              checked={values?.strategy === strategy.id}
              name={strategy.id}
              onChange={() => setFieldValue("strategy", strategy.id)}
            />
            <p className="font-weight-500 ml-2 my-auto text-white">
              {strategy.name}
            </p>
          </div>
        </td>
        <td>
          <p className="text-truncate">
            {strategy.description}
          </p>
        </td>
        <td className="pr-2">
          <Button 
            type="button"
            className="mr-0 ml-auto"
            onClick={() => setModalIsOpen(true)}
          >
            Details
          </Button>
        </td>
      </StyledRow>
      <TemplateDetailsModal
        onSetModalIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        tradeSettings={strategy.tradeSettings}
        name={strategy.name}
        description={strategy.description}
      />
    </>
  );
};

const StyledRow = styled.tr`
  p {
    color: rgba(255, 255, 255, 0.35);
  }

  &:hover {
    p {
      color: #fff;
    }
  }
`;

export default TemplateItem;
