import React from "react";
import { Card } from "reactstrap";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import BotSettingsBody from "./BotSettingsBody";
import HeaderWithType from "./HeaderWithType";
import DefaultHeader from "./DefaultHeader";
import BotSettingsWidgetPreloader from "./preloaders/BotSettingsWidgetPreloader";
import LineWithError from "shared/ui/LineWithError";

const BotSettingsWidget = ({
  data,
  loading,
  defaultHeader=true,
  onEditButtonClick,
  pathTo,
  withCriterias=true
}) => {
  const history = useHistory();

  if (loading) return <BotSettingsWidgetPreloader defaultHeader={defaultHeader} />;

  const handleRedirect = () => {
    if (!pathTo) return;
    history.push(pathTo);
  };

  return (
    <StyledCard
      path={pathTo}
      onClick={handleRedirect}
      defaultHeader={defaultHeader}
    >
      {
        defaultHeader ?
        <DefaultHeader 
          isWithEditButton
          onEditButtonClick={onEditButtonClick}
        />
        :
        <HeaderWithType
          data={data}
          onEditButtonClick={onEditButtonClick}
        />
      }
      {
        !defaultHeader && data?.error && <LineWithError radius="none" errorMessage={data?.error}/>
      }
      <BotSettingsBody
        data={data}
        loading={loading}
        withCriterias={withCriterias}
      />
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  cursor: ${(props) => props.path ? "pointer" : "default"};
  border-radius: 8px !important;
  overflow: hidden;

  &:hover {
    background: ${(props) => props.path ? "#2E3148" : "#27293d" };

    .borderStatus {
      border: 3px solid #363A54;
    }

    & .card-header {
      background: ${(props) => props.path && !props.defaultHeader ? "#363A54" : "transparent" } !important;
    }
  }
`;

export default BotSettingsWidget;
