import React from "react";
import styled from "styled-components";
import {
  ModalBody,
  ModalHeader,
  Modal
} from "reactstrap";

import CreateCredentialForm from "./ui/CreateCredentialsForm";

const CreateCredentialsModal = ({
  data, 
  setIsModalOpen, 
  isModalOpen
}) => {
  return(
    <CreateCredentialsModal.Wrapper>
      <Modal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(!isModalOpen)}
        modalClassName="modal-black"
      >
        <div className="p-4">
          <StyledModalHeader className="modal-header px-2 py-1">
            <h3 className="modal-title text-white font-weight-500">
              Create credentials
            </h3>
          </StyledModalHeader>
          <ModalBody className="px-2 pb-2">
            <CreateCredentialForm
              credential={data}
              setIsModalOpen={setIsModalOpen}
            />
          </ModalBody>
        </div>
      </Modal>
    </CreateCredentialsModal.Wrapper>
  );
};

CreateCredentialsModal.Wrapper = styled.div`
  width: 455px !important;
`

const StyledModalHeader = styled(ModalHeader)`
  h3 {
    font-size: 24px;
  }
`

export default CreateCredentialsModal;
