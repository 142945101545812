import {
  createStore,
  applyMiddleware
} from "redux";
import thunk from "redux-thunk";
// import logger from "redux-logger";
import { composeWithDevTools } from 'redux-devtools-extension';
import fetchWithHeaders from "./middlewares/fetchWithHeaders";
import rootReducer from "./rootReducer";

const composeEnhancers = composeWithDevTools({});
const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(fetchWithHeaders, thunk)
  )
);

export default store;
