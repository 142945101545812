import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "components/Button";
import { useDispatch } from "react-redux";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import {
  ModalBody,
  Modal,
  Label,
  Tooltip,
} from "reactstrap";

import { ReactComponent as Help } from "assets/img/icons/Help.svg";
import {ReactComponent as WarningIcon} from "assets/img/icons/Warning.svg";
import { updateInvestmentSettings } from "store/investment/actions";
import {addSnackBar} from "store/snackbar/actions";
import ErrorAlert from "components/ErrorAlert";
import Loading from "components/Loading";

const SETTINGS_SCHEMA = Yup.object().shape({
  maximumDrawdown: Yup.number().max(20, "This value should be less than or equal to 20."),
  maxDeals: Yup.number().min(1, "This value should be positive.")
});

const SettingsModal = ({
  onSetModalIsOpen,
  modalIsOpen,
  id,
  drawdown,
  maxDeals,
  version
}) => {
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [maximumDrawdownTooltip, setMaximumDrawdownTooltip] = useState(false);
  const [maxDealsTooltip, setMaxDealsTooltip] = useState(false);
  const dispatch = useDispatch();

  const handleUpdate = async (values, setIsOpen) => {
    const response = await dispatch(updateInvestmentSettings(id, values));
    if (response.errors) {
      dispatch(addSnackBar("error", response.message));
      setError(response.message);
      setTimeout(() => {
        setError(null);
      }, 5000);
      return;
    }
    dispatch(addSnackBar("success", response.message));
    setIsOpen(false);
  };

  const handleClearErrors = (setErrors) => {
    setErrors("");
    if (error) setError("");
  };

  useEffect(() => {
    if (!modalIsOpen) setIsSubmitted(false);
  }, [modalIsOpen]);

  return (
    <SettingsModal.Modal
      isOpen={modalIsOpen}
      toggle={() => onSetModalIsOpen(!modalIsOpen)}
      modalClassName="modal-black p-3"
    >
      <ModalBody>
        <SettingsModal.ModalTitle className="font-weight-500 text-white mb-4">
          Change investment settings
        </SettingsModal.ModalTitle>
        <Formik
          enableReinitialize
          initialValues={{
            maximumDrawdown: drawdown || "",
            maxDeals: maxDeals || "",
            version: version
          }}
          validationSchema={SETTINGS_SCHEMA}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            const body = {
              ...values,
              maximumDrawdown: values.maximumDrawdown.toString() ? values.maximumDrawdown : null,
              maxDeals: values.maxDeals.toString() ? values.maxDeals : null
            };
            await handleUpdate(body, onSetModalIsOpen);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            isSubmitting,
            setErrors
          }) =>
            <Form className="d-flex flex-column p-0 m-0">
              <div className="d-flex flex-column mb-2">
                <div className="d-flex justify-content-between">
                  <Label
                    htmlFor="maximumDrawdown"
                    className="text-white-35 m-0"
                  >
                    Maximum daily drawdown{" "}
                    <Help
                      id="MDtooltip"
                      className="cursor-pointer"
                    />
                    <Tooltip
                      target="MDtooltip"
                      placement="top"
                      isOpen={maximumDrawdownTooltip}
                      toggle={() => setMaximumDrawdownTooltip((prev) => !prev)}
                    >
                      Maximum daily drawdown (MDD) measures the maximum fall in the value
                      of the coin during the last 24 hours, as given by the difference
                      between the current value and that of the highest
                      peak before the trough.
                    </Tooltip>
                  </Label>
                  {
                    !values.maximumDrawdown.toString() ?
                    <p className="mb-0 font-sm text-warning">
                      <SettingsModal.WarningIcon />
                      Unlimited
                    </p> : null
                  }
                </div>
                <Field
                  id="maximumDrawdown"
                  name="maximumDrawdown"
                  type="number"
                  placeholder="Type in..."
                  value={values.maximumDrawdown}
                  className={classNames("form-control", {
                    error : (errors.maximumDrawdown || error) && isSubmitted,
                  })}
                />
                {
                  isSubmitted && errors.maximumDrawdown &&
                  <label className="text-danger m-0 font-sm font-weight-300">
                    {errors.maximumDrawdown}
                  </label>
                }
              </div>
              <div className="d-flex flex-column mb-3">
                <div className="d-flex justify-content-between">
                  <Label
                    htmlFor="maxDeals"
                    className="text-white-35 m-0"
                  >
                    Maximum running deals{" "}
                    <Help
                      id="maxDealsTooltip"
                      className="cursor-pointer"
                    />
                    <Tooltip
                      target="maxDealsTooltip"
                      placement="top"
                      isOpen={maxDealsTooltip}
                      toggle={() => setMaxDealsTooltip((prev) => !prev)}
                    >
                      It means how many deals may run at the same
                      time from all attached bots for this coin.
                    </Tooltip>
                  </Label>
                  {
                    !values.maxDeals.toString() ?
                    <p className="mb-0 font-sm text-warning">
                      <SettingsModal.WarningIcon />
                      Unlimited
                    </p> : null
                  }
                </div>
                <Field
                  id="maxDeals"
                  name="maxDeals"
                  type="number"
                  placeholder="Type in..."
                  value={values.maxDeals}
                  className={classNames("form-control", {
                    error : (errors.maxDeals || error) && isSubmitted,
                  })}
                />
                {
                  isSubmitted && errors.maxDeals &&
                  <label className="text-danger m-0 font-sm font-weight-300">
                    {errors.maxDeals}
                  </label>
                }
              </div>
              <ErrorAlert
                fade={false}
                className="mb-4"
                toggle={() => handleClearErrors(setErrors)}
                isOpen={(Object.keys(errors).length || error) && isSubmitted}
              >
                {error || "Make sure you entered the numbers from the valid range"}
              </ErrorAlert>
              <div className="d-flex justify-content-between">
                <Button
                  color="gray"
                  onClick={() => onSetModalIsOpen(false)}
                  className="w-25"
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  color="blue"
                  disabled={isSubmitting}
                  type="submit"
                  onClick={() => setIsSubmitted(true)}
                  size="middle"
                >
                  {
                    isSubmitting
                      ? <Loading size={20}/>
                      : "Save"
                  }
                </Button>
              </div>
            </Form>
          }
        </Formik>
      </ModalBody>
    </SettingsModal.Modal>
  );
};

SettingsModal.Modal = styled(Modal)`
  max-width: 342px !important;

  .modal-content {
    border-radius: 1rem !important;
  }
`;

SettingsModal.ModalTitle = styled.p`
  font-size: 1.125rem;
  line-height: 21px;
`;

SettingsModal.WarningIcon = styled(WarningIcon)`
  width: 14px;
  height: 13px;
  margin-bottom: 4px;
  margin-right: 5px;

  & path {
    stroke: #FF8D72;
  }
`;

export default SettingsModal;
