import React, {useEffect} from "react";
import { Route as ReactRouterRoute } from "react-router-dom";

const Route = ({ location, ...props }) => {
  useEffect(() => {
    if (location.pathname === "/") {
      document.title = "Darkbot - Login";
    } else {
      const pathWithSeparator = location.pathname.replace(/\//g, " - ");
      const title = pathWithSeparator.split(" - ").map((i) => {
        if (i.match(/^[A-Za-z]+$/)) return i.charAt(0).toUpperCase() + i.slice(1);
        return i;
      });
      document.title = `Darkbot${title.join(" - ")}`;
    }
  }, [location.pathname]);

  return(
    <ReactRouterRoute {...props} />
  );
};

export default Route;
