import React, {
  useEffect,
  useState
} from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import {
  Row,
  Col,
  UncontrolledAlert
} from "reactstrap";
import {useHistory} from "react-router-dom";
import qs from "qs";
import * as Sentry from "@sentry/react";

import Error from "components/Error";
import Investment from "components/Investment";
import Credential from "components/Credential";
import FallBack from "components/FallBack";
import RefreshButton from "components/RefreshButton";
import BotSettingsWidget from "components/BotSettingsWidget";
import VolumesWidget from "./ui/VolumesWidget";
import DangerZone from "components/DangerZone";
import DealHeader from "./ui/DealHeader";
import DealCommentWidget from "./ui/DealCommentWidget";
import StatisticWidget from "./ui/StatisticWidget";
import AvailableBalance from "./ui/AvailableBalance";
import CredentialWidgetPreload from "./ui/preloaders/CredentialWidgetPreload";
import PagesView from "./pagesView/PagesView";
import TradeSettings from "components/TradeSettingsWidget";
import Breadcrumbs from "components/Breadcrumbs";
import {setDealPageBreadcrumbs} from "models/enum/breadcrumbs";
import useRequestCancellation from "hooks/useRequestCancellation";
import {
  fetchConditions,
  fetchDeal,
  fetchHistoryTradeRequests,
  fetchIndicators,
  fetchMarketInformation,
  getOrders,
  getTrades,
  resetDeal
} from "store/deals/actions";
import { DEALS_CONTENT } from "models/constans/deals/content";

const DealPage = ({match}) => {
  const [isNotMarketLoaded, setIsNotMarketLoaded] = useState(true);
  const [pageView, setPagView] = useState("overview");
  const { useAbortableEffect } = useRequestCancellation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {data, loading, error} = useSelector((state) => state.deals.item);

  useEffect(() => {
    window.analytics.page("/deals/:id");
  }, []);

  useAbortableEffect((signal) => {
    dispatch(fetchDeal(match.params.id, signal));
    return () => dispatch(resetDeal());
  }, [dispatch, match.params.id], true);

  useAbortableEffect((signal) => {
    if (data?.bot?.investment?.credentials?.exchange) {
      dispatch(fetchMarketInformation(
        data?.bot?.investment?.credentials?.exchange, 
        data?.market?.base, 
        data?.market?.quote, 
        signal
      )).then(() => setIsNotMarketLoaded(false));
    }
  }, [
    data?.bot?.investment?.credentials?.exchange,
    data?.market?.base, 
    data?.market?.quote, 
    dispatch
  ]);

  const redirectToBotConfigure = (event) => {
    event.stopPropagation();
    event.preventDefault();
    history.push(`/bots/${data?.bot?.id}/configure`, {deal: match.params.id});
  };

  const onHandleRefresh = () => {
    dispatch(fetchDeal(match.params.id));

    if (pageView === "overview") {
      const initialFilterCompleted = qs.stringify({
        filter: {
          "tr.status": {
            eq: 'completed'
          }
        }
      });
      dispatch(fetchHistoryTradeRequests(match?.params?.id, `?${initialFilterCompleted}`));
      dispatch(fetchConditions(match.params.id));
      return dispatch(fetchIndicators(match.params.id));
    }

    if (pageView === "history") {
      const filters = qs.stringify({
        filter: {status: {in: ["open", "completed"]}},
        sort: {by: "createdAt", order: "DESC"}
      });
      dispatch(getOrders(match.params.id, `?${filters}`));
      return dispatch(getTrades(match.params.id));
    }
  };

  if (error) return <Error error={error}/>;

  return (
    <>
      <div className="d-none d-md-flex justify-content-between align-items-center">
        <Breadcrumbs
          items={setDealPageBreadcrumbs(
            data?.bot?.investment?.credentials?.exchange,
            data?.bot?.investment?.credentials?.id,
            data?.bot?.investment?.id,
            data?.bot?.investment?.asset,
            data.bot?.name,
            data?.bot?.id
          )}
          loading={loading}
        />

        <RefreshButton
          loading={loading}
          onHandleRefresh={onHandleRefresh}
        />
      </div>
      <Sentry.ErrorBoundary fallback={<FallBack/>}>
        <DealHeader
          id={match.params.id}
          status={data.status}
          completedAt={data.completedAt}
          createdAt={data.createdAt}
          market={data.market}
          errorDeal={data.error}
          loading={loading}
        />
      </Sentry.ErrorBoundary>
      {data?.message?.text ?
        <UncontrolledAlert color={data.message.type} fade={true}>
          {data.message.text}
        </UncontrolledAlert> : null}
      <Row>
        <Col xs="12" md="4">
          <DealCommentWidget
            comment={data?.comment}
            loading={loading}
          />
          <Sentry.ErrorBoundary fallback={<FallBack/>}>
            <VolumesWidget
              data={data}
              status={data.status}
              loading={loading}
            />
          </Sentry.ErrorBoundary>
          <Sentry.ErrorBoundary fallback={<FallBack/>}>
            <AvailableBalance balances={data?.balances}/>
          </Sentry.ErrorBoundary>
          {
            data?.status !== "ready" ?
              <Sentry.ErrorBoundary fallback={<FallBack/>}>
                <StatisticWidget
                  data={data}
                  loading={loading}
                />
              </Sentry.ErrorBoundary> : null
          }
          <Sentry.ErrorBoundary fallback={<FallBack/>}>
            <BotSettingsWidget
              dealId={data?.id}
              data={data?.bot}
              loading={loading}
              defaultHeader={false}
              pathTo={`/bots/${data?.bot?.id}`}
              onEditButtonClick={redirectToBotConfigure}
            />
          </Sentry.ErrorBoundary>
          <Sentry.ErrorBoundary fallback={<FallBack/>}>
            <TradeSettings
              isGenetic={data?.bot?.type === "genetic"}
              isAllCoins={data?.allCoins}
              bot={data}
              isBotLoading={loading}
              displayMarketsSelect={false}
              tradeSettings={data?.tradeSettings}
            />
          </Sentry.ErrorBoundary>
          <Sentry.ErrorBoundary fallback={<FallBack/>}>
            <Investment
              investment={data?.bot?.investment}
              loading={loading}
              runningDeals={data?.bot?.investment?.statistics?.deals?.running}
              showDealsCount
            />
          </Sentry.ErrorBoundary>
          <Sentry.ErrorBoundary fallback={<FallBack/>}>
            {loading && <CredentialWidgetPreload/>}
            {
              data?.bot?.investment?.credentials &&
              <Credential
                credential={data?.bot?.investment?.credentials}
                loading={loading}
              />
            }
          </Sentry.ErrorBoundary>
          <Sentry.ErrorBoundary fallback={<FallBack/>}>
            <DangerZone
              id={match.params.id}
              loading={loading}
              actions={data?.actions}
              onHandleRefresh={onHandleRefresh}
              content={DEALS_CONTENT?.details?.widgets?.dangerZone}
            />
          </Sentry.ErrorBoundary>
        </Col>
        <Col xs="12" md="8">
          <PagesView
            data={data}
            loadingPage={loading || isNotMarketLoaded}
            id={match.params.id}
            pageView={pageView}
            setPagView={setPagView}
            onHandleRefresh={onHandleRefresh}
          />
        </Col>
      </Row>
    </>
  );
};

export default DealPage;
