import React from "react";
import classNames from "classnames";
import { Col, Label } from "reactstrap";

import SelectInput from "components/SelectInput";
import ToggleSwitch from "components/ToggleSwitch";
import { INDICATORS_OPTIONS } from "views/bots/models/options";
import { ReactComponent as HelpIcon } from "assets/img/icons/Help.svg";

import { CrownIconStyled, IndicatorFormBadge } from "./Indicator.styles";

const IndicatorFormRightBlock = ({
  values,
  setFieldValue,
  indicatorsConfiguration,
  submitted,
  configurations,
  subscription,
  onSetIndicatorType,
  setSubmitted,
  errors,
  indicatorType,
  editing,
}) => {
  const handleChooseIndicator = ({ value }) => {
    setFieldValue("code", value);
    onSetIndicatorType(value);
    setSubmitted(false);
  };
  return (
    <Col className="d-flex flex-column p-0">
      <div className="d-flex flex-row justify-content-between mb-1">
        <div className="d-flex flex-row align-items-center">
          <Label className="mr-1 mb-0 text-white-35 font-weight-400">
            Choose an indicator
          </Label>
          <HelpIcon />
        </div>
      </div>

      <SelectInput
        isSearchable
        withDisabledOptions
        placeholder="Type in..."
        name="code"
        onChange={handleChooseIndicator}
        options={configurations.map(({ code, free }) => ({
          value: code || "",
          label:
            indicatorsConfiguration.find((prop) => prop.value === code).label ||
            "",
          isDisabled:
            subscription?.type === "premium" ||
              subscription?.type === "standard"
              ? false
              : !free,
        }))}
        defaultValue={{
          value: indicatorType || values.code,
          label:
            (editing &&
              indicatorsConfiguration?.find(
                ({ value }) => indicatorType === value
              )?.label) ||
            "",
        }}
        isOptionDisabled={(option) => option.isDisabled}
        disabledOptionIcon={<CrownIconStyled className="crown-icon" />}
      />
      <div className="w-100">
        {submitted && errors.code ? (
          <Label className="text-danger">{errors.code}</Label>
        ) : null}
      </div>
      <Label className="mt-3 mb-1 text-white-35 font-weight-400">
        Signal type
      </Label>
      <div className="d-flex justify-content-between mb-3">
        {INDICATORS_OPTIONS.map(({ value, label, color }) => (
          <IndicatorFormBadge
            key={value}
            className={classNames(
              "d-flex align-items-center justify-content-center my-auto mx-1 mx-lg-0",
              {
                active: values.type === value,
              }
            )}
            onClick={() => setFieldValue("type", value)}
          >
            <p className={`h5 my-auto font-md text-${color}`}>
              {label.toUpperCase()}
            </p>
          </IndicatorFormBadge>
        ))}
      </div>
      <div className="d-flex flex-row align-items-center justify-content-between my-3">
        <h5 className="text-white mb-0 font-md">
          Necessary signals
        </h5>
        <ToggleSwitch
          height={18}
          width={36}
          onColor="#26366b"
          offColor="#4b4c5e"
          onHandleColor="#00f2c3"
          offHandleColor="#FFFFFF59"
          checked={values.required}
          onChange={(value) => {
            setFieldValue("required", value)
          }}
        />
      </div>
    </Col>
  );
};

export default IndicatorFormRightBlock;
