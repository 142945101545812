import React from "react";
import { capitalize } from "lodash";

import exchangesConfig from "exchanges/exchangesConfig";
import IdWithCopy from "components/IdWithCopy";
import { ReactComponent as InfoCircle } from "assets/img/icons/infocircle.svg";
import IPsAddressesPreloader from "../../preloaders/IPsAddressesPreloader";
import { WrapperIPsAddresses } from "./IPsAddresses.styles";

const IPsAddresses = ({
  exchange,
  loading
}) => {

  if (loading) return <IPsAddressesPreloader />;

  if (exchange?.ips?.length) {

    const getformattedExchangeName = () => capitalize(exchange?.code).replace("btc", "BTC");

    return (
      <WrapperIPsAddresses 
        className="mb-4"
        data-testid="ips-addresses-wrapper"
        >
        <div className="d-flex justify-content-between">
          <p 
          className="font-sm text-white font-weight-500 mb-3"
          data-testid="ips-addresses-description"
          >
            Use one of the following IP addresses to generate your {getformattedExchangeName()} API Key:
          </p>
          <a
            data-testid="ips-addresses-link"
            href={exchangesConfig?.[exchange?.code]?.docsLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <InfoCircle data-testid="ips-addresses-info-circle"/>
          </a>
        </div>
        {
          exchange?.ips?.map((ip, index) =>
            <IdWithCopy
              data-testid={`ips-addresses-${index}`}
              key={ip}
              className="code-text"
              id={ip}
            />
          )
        }
      </WrapperIPsAddresses>
    );
  }
};

export default IPsAddresses;