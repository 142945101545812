import React, {useEffect, useState} from "react";
import {
  Card,
  Col,
  CardBody
} from "reactstrap";

import WithdrawalForm from "./WithdrawalForm";
import HelpIcon from "components/HelpIcon";

const Withdrawal = () => {
  const [displayHelp, setDisplayHelp] = useState(false);

  useEffect(() => {
    window.analytics.page("/withdrawal");
  }, []);

  return (
    <>
      <Col xs="12">
        <h1>
          Withdrawal{" "}
          <HelpIcon
            onClick={() => setDisplayHelp(!displayHelp)}
          />
        </h1>
      </Col>
      <Col xs="6">
        <Card>
          <CardBody>
            <WithdrawalForm />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Withdrawal;
