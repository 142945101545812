import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Modal,
  ModalBody
} from "reactstrap";

import { updateStrategy } from "store/strategies/actions";
import SettingsForm from "../forms/SettingsForm";

const StrategySettingsModal = ({
  id,
  updateStrategy,
  data,
  onSetModalIsOpen,
  modalIsOpen
}) => {
  return(
    <Modal
      isOpen={modalIsOpen}
      toggle={() => onSetModalIsOpen(!modalIsOpen)}
      modalClassName="modal-black"
      size="lg"
    >
      <ModalBody>
        <SettingsForm
          data={data}
          period={data.period}
          name={data.name}
          onEdit={updateStrategy}
          onSetModalIsOpen={onSetModalIsOpen}
          id={id}
        />
      </ModalBody>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateStrategy
}, dispatch);

export default connect(null, mapDispatchToProps)(StrategySettingsModal);
