import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import {
  CardBody,
  CardHeader
} from "reactstrap";

import StyledButton from "./Button";
import StyledPlanCard from "./StyledPlanCard";
import { getCurrentPrice } from "../utils";
import FeaturesList from "./FeaturesList";
import useBuySubscription from "hooks/useBuySubscription";

const PlanCard = ({ 
  plan={}, 
  paymentInterval, 
  currentPlan, 
  hasTrial
}) => {
  const [monthPrice] = plan?.prices;

  const { handleBuyNow, handleAddCard } = useBuySubscription();

  const getButtonText = () => {
    if ((!currentPlan && plan.name === "Free") || plan.code === currentPlan) return "Manage billing";
    if (plan.name === "Free") return "Connect Free plan";
    return "Try 7 days for free";
  };

  const handleBuy = (e, trial) => {
    e.preventDefault();
    const id = plan.prices.find((price) => price.interval === paymentInterval)?.stripe;
    handleBuyNow(id, trial);
  };

  return (
    <StyledPlanCard>
      <CardHeader>
        <PlanCard.Title className="text-white font-weight-500 mb-1">
          <span>
            {plan.name}
          </span>
          {
            plan.code === "premium_subscription" ?
              <PlanCard.Badge className="font-sm ml-2 py-1 px-2">
                {"Trader's choice"}
              </PlanCard.Badge>
              : null
          }
        </PlanCard.Title>
        <p className="text-white-75 mb-0 font-weight-500">
          {plan.description}
        </p>
        <div className="d-flex">
          <PlanCard.Price className="text-white d-block mb-1">
            {getCurrentPrice(plan, paymentInterval)}
          </PlanCard.Price>
          {
            paymentInterval === "year" && plan.name !== "Free"
              ? <PlanCard.MonthPrice className="text-white-35">
                {monthPrice?.amount}
              </PlanCard.MonthPrice>
              : null
          }
        </div>
        <PlanCard.IntervalDesc className="text-white-35 mb-4">
          {
            plan.name === "Free"
              ? "forever"
              : "per month"
          }
        </PlanCard.IntervalDesc>
      </CardHeader>
      <CardBody className="pt-0">
        <StyledButton
          disabled={hasTrial}
          className={classNames("btn btn-info w-100 mb-3", {
            current: false,
            "btn-simple": (!currentPlan && plan.name === "Free") || plan.code === currentPlan,
          })}
          onClick={(e) => plan.name === "Free"
            ? handleAddCard()
            : handleBuy(e, true)
          }
        >
          {getButtonText()}
        </StyledButton>
        <div className="d-flex justify-content-center w-100">
          <span className={classNames("font-sm font-weight-500", {
            "text-white-35": plan.name === "Free",
            "text-white": plan.name !== "Free"
          })}>
            {
              plan.name === "Free" ?
                "Free for a lifetime"
                :
                <>
                  Skip and{" "}
                  <a
                    href="/#"
                    className="text-info text-underline font-weight-500"
                    onClick={handleBuy}
                  >
                    buy now
                  </a>
                </>
            }
          </span>
        </div>
        <PlanCard.Separator className="mb-0" />
      </CardBody>
      <FeaturesList
        features={plan.features}
        isPremium={plan.code === "premium_subscription"}
      />
    </StyledPlanCard>
  );
};

PlanCard.Title = styled.h2`
  font-size: 1.25rem;
  line-height: 23.44px;
`;

PlanCard.Price = styled.span`
  font-size: 2rem;
  font-weight: 700;
  line-height: 37.5px;
  margin-top: 2rem;
`;

PlanCard.MonthPrice = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 37.5px;
  margin-top: 1rem;
  margin-left: .5rem;
  text-decoration: line-through;
`;

PlanCard.IntervalDesc = styled.p`
  line-height: 18.75px;
`;

PlanCard.Separator = styled.hr`
  margin-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

PlanCard.Badge = styled.span`
  padding: 0.25rem 0.5rem;
  background: rgba(63, 203, 176, 0.05);
  border-radius: 6px;
  color: #3FCBB0;
  line-height: 16.41px;
  font-weight: 500;
`;

export default PlanCard;
