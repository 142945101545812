import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import styled from "styled-components";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader, CardBody } from "reactstrap";
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-moment";

import { selectStatisticsBalance } from "store/credentials/selectors";
import { ReactComponent as Balance } from "assets/img/icons/orders.svg"
import { ReactComponent as CredentialChartPreload } from "assets/img/CredentialChartPreload.svg"
import { fetchStatisticsBalance } from "store/credentials/actions";
import {balanceHistory} from "charts";
import EmptyChart from "assets/img/EmptyChart.png";
import SkeletonPreloader from "components/SkeletonPreloader";
import {CHART_GROUP_BUTTONS} from "charts/enum";
import ButtonsGroup from "components/ButtonsGroup";
import useRequestCancellation from "hooks/useRequestCancellation";

Chart.register(...registerables);

const StatisticsBalance = ({id, currency, loadingPage}) => {
  const [period, setPeriod] = useState("day");
  const dispatch = useDispatch();
  const { useAbortableEffect } = useRequestCancellation();
  const { data, loading, error } = useSelector(selectStatisticsBalance);
  const loadingBalances = useSelector((state) => state.credentials.balances.loading);

  useAbortableEffect((signal) => {
    if(!loadingBalances) dispatch(fetchStatisticsBalance(id, "day", signal));
  }, [id, dispatch]);

  if (loading || loadingPage) {
    return (
      <StatisticsBalance.ChartCard>
        <CardBody className="p-4">
          <div className="d-flex align-items-center mb-4">
            <SkeletonPreloader
              circle={true}
              height={19}
              width={19}
            />
            <SkeletonPreloader
              height={14}
              width={140}
              className="ml-2"
            />
          </div>
          <CredentialChartPreload className="h-100" />
        </CardBody>
      </StatisticsBalance.ChartCard>
    );
  }

  if (error) {
    return (
      <Card>
        <CardBody>
          <div
            className="chart-area d-flex flex-column justify-content-center text-center w-100"
            style={{ height: "325px" }}
          >
            <p className="text-danger">{error}</p>
          </div>
        </CardBody>
      </Card>
    );
  }

  const renderChart = () => {
    if (loading || loadingBalances) return (
      <div className="p-4">
        <CredentialChartPreload className="h-100"/>
      </div>
    );
    if (data && Object.keys(data).length) return (
      <div className="chart-area">
        <Line
          data={balanceHistory(data, period, currency).data}
          options={balanceHistory(data, period, currency).options}
        />
      </div>
    );
    if (!(data && Object.keys(data).length) && !loading) {
      return (
        <div className="d-flex flex-column justify-content-center text-center h-75">
          <h3>There are no data to show</h3>
        </div>
      )
    }
  };

  return(
    <StatisticsBalance.ChartCard
      className={classNames("card-chart", {
        empty: !(data && Object.keys(data).length) && !loading
      })}
    >
      <StatisticsBalance.CardHeader className="p-4">
        <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
          <div className="d-flex align-items-center mb-2 mb-md-0">
            <Balance />
            <h4 className="ml-2 mb-0 text-white">Balance history</h4>
          </div>
          <ButtonsGroup
            group={CHART_GROUP_BUTTONS}
            current={period}
            setCurrent={setPeriod}
            callFunc={fetchStatisticsBalance}
            params={id}
            disable={loading}
          />
        </div>
      </StatisticsBalance.CardHeader>
      <CardBody>
        {renderChart()}
      </CardBody>
    </StatisticsBalance.ChartCard>
  );
};

StatisticsBalance.ChartCard = styled(Card)`
  margin-bottom: 32px !important;
  border-radius: 8px !important;
  &.empty {
    background-image: ${(props) => !props.data ? `url(${EmptyChart}) !important` : "none"};
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
  min-height: 325px;
`;

StatisticsBalance.CardHeader = styled(CardHeader)`
  svg {
    width: 12px;
    height: 10px;
    & path {
      stroke: var(--info);
    }
  }
`;

export default StatisticsBalance;
