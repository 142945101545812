import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import {
  NavItem,
} from "reactstrap";
import PropTypes from 'prop-types';

const UncollapseNavItem = ({ 
  navItem, 
  handleOnLinkClick,
  expanded, 
  getIcon, 
  toggleSidebar,
  isMobile
 }) => {
  return (
    <UncollapseNavItem.StyledNavItem key={navItem.name} activeclassname="active">
      <UncollapseNavItem.NavLink
        expanded={expanded}
        disabled={navItem.disabled}
        to={navItem.path}
        onClick={()=> {
          handleOnLinkClick()
          if(isMobile) return toggleSidebar(expanded)
        }}
        className="nav-link d-inline-flex w-100 h-100 py-2 px-0"
        isActive={() => {
          return (
            navItem.path === "/" ?
              navItem.path === window.location.pathname :
              window.location.pathname.includes(navItem.path)
          );
        }}
        exact
      >
        {getIcon(navItem.icon)}{" "}
        {
          expanded ?
            <UncollapseNavItem.NavText className="my-auto">
              {navItem.name}
            </UncollapseNavItem.NavText>
            : null
        }
      </UncollapseNavItem.NavLink>
    </UncollapseNavItem.StyledNavItem>
  );
};

UncollapseNavItem.StyledNavItem = styled(NavItem)`
  .active {
    opacity: 1;
  }
`;
UncollapseNavItem.NavText = styled.span`
  line-height: 12px;
`;
UncollapseNavItem.NavLink = styled(NavLink)`
  font-weight: 500 !important;
  font-size: 14px !important;
  margin-bottom: 12px;
  text-transform: initial !important;
  opacity: 0.7;

  ${(props) => props.disabled &&
    `pointer-events: none;
     color: rgb(255, 255, 255, 0.5) !important;
     i {
       color: rgb(255, 255, 255, 0.5) !important;
     }
  `}
`;

UncollapseNavItem.propTypes = {
  navItem:PropTypes.shape({
    name:PropTypes.string.isRequired,
    icon:PropTypes.string.isRequired,
    path:PropTypes.string.isRequired,
    disabled:PropTypes.bool,
  }).isRequired,
  handleOnLinkClick:PropTypes.func.isRequired,
  expanded:PropTypes.bool.isRequired,
  getIcon:PropTypes.func.isRequired
}

export default UncollapseNavItem;