import React from "react";
import {capitalize} from "lodash";
import classnames from "classnames";

const ConditionsMarketTableRow = ({item}) => {
  return (
    <tr>
      <td>
        <p className="mb-0 text-white font-weight-500 text-nowrap">
          {capitalize(item.code?.replaceAll("_", " "))}
        </p>
      </td>

      <td>
        <p className={classnames("mb-0 font-weight-500 text-capitalize", {
          "text-success": item.metadata.required === "bull",
          "text-danger": item.metadata.required === "bear",
        })}>
          {item.metadata.required}
        </p>
      </td>

      <td>
        <p className={classnames("mb-0 font-weight-500 text-nowrap text-capitalize", {
          "text-success": item.actual === "bull",
          "text-danger": item.actual === "bear",
        })}>
          {item.actual}
        </p>
      </td>

      <td>
        <p className={`mb-0 font-weight-500 ${item.status === "met" ? "text-success" : "text-white-35"}`}>
          {item.status === "met" ? "Fulfilled" : "Not satisfied"}
        </p>
      </td>
    </tr>
  )
}

export default ConditionsMarketTableRow;