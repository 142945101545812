import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { Col } from "reactstrap";

import SkeletonPreloader from "components/SkeletonPreloader";

const BacktestHistoryTablePreLoad = () => {
  return(
    <>
      <BacktestHistoryTablePreLoad.Header className="p-3">
        <div className="d-flex">
          <Col xs="3">
            <SkeletonPreloader
              height={13}
            />
          </Col>
          <Col xs="3">
            <SkeletonPreloader
              height={13}
            />
          </Col>
          <Col xs="3">
            <SkeletonPreloader
              height={13}
            />
          </Col>
          <Col xs="3">
            <SkeletonPreloader
              height={13}
            />
          </Col>
        </div>
      </BacktestHistoryTablePreLoad.Header>
      {
        _.times(6, (e) =>
          <BacktestHistoryTablePreLoad.Item
            className="p-3"
            key={e}
          >
            <div className="d-flex">
              <Col xs="1">
                <SkeletonPreloader
                  height={18}
                  width={18}
                  circle
                />
              </Col>
              <Col xs="2">
                <SkeletonPreloader
                  height={13}
                />
              </Col>
              <Col xs="3">
                <SkeletonPreloader
                  height={13}
                />
              </Col>
              <Col xs="3">
                <SkeletonPreloader
                  height={13}
                />
              </Col>
              <Col xs="3">
                <SkeletonPreloader
                  height={13}
                />
              </Col>
            </div>
          </BacktestHistoryTablePreLoad.Item>
        )
      }
    </>
  );
};

BacktestHistoryTablePreLoad.Header = styled.div`
  background: #2E3148;
  border-bottom: 1px solid #1D1E2A !important;
`;

BacktestHistoryTablePreLoad.Item = styled.div`
  background: var(--dark-slate);
  border-bottom: 1px solid #1D1E2A !important;
`;

export default BacktestHistoryTablePreLoad;
