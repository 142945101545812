import React from "react";
import _ from "lodash";
import { 
  Card, 
  CardBody 
} from "reactstrap";

import SkeletonPreloader from "components/SkeletonPreloader";
import { ReactComponent as Chart } from "assets/img/Frame 327.svg";

const TradingWithTickerPreloader = () => {
  return (
    <Card className="mb-4 overflow-hidden">
      <div className="d-flex align-items-center justify-content-between p-4">
        <div className="d-flex align-items-center">
          <SkeletonPreloader width={40} height={40} circle />
          <SkeletonPreloader width={113} height={18} className="ml-3" />
        </div>
        <div className="d-flex align-items-center">
          <div className="mr-4 d-flex flex-column">
            <SkeletonPreloader width={57} height={8} />
            <SkeletonPreloader width={97} height={14} className="mt-2" />
          </div>
          <div className="d-flex flex-column">
            <SkeletonPreloader width={57} height={8} />
            <SkeletonPreloader width={97} height={14} className="mt-2" />
          </div>
        </div>
      </div>
      <div className="px-4">
        <div style={{ height: "1px", width: "100%", background: "rgba(255, 255, 255, 0.03)" }} />
      </div>
      <div className="d-flex align-items-center justify-content-between p-4 mt-1">
        {_.times(5, (e) =>
          <div className="d-flex flex-column w-100" key={e}>
            <SkeletonPreloader height={8} className="w-25" />
            <SkeletonPreloader height={14} className="mt-2 w-75" />
          </div>
        )}
      </div>
      <CardBody className="d-flex flex-column p-4" style={{ background: "#242638" }}>
        <SkeletonPreloader width={81} height={14} className="mb-4 pb-3" />
        <Chart />
      </CardBody>

    </Card>
  )
}

export default TradingWithTickerPreloader;
