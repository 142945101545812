import React from "react";
import { Col } from "reactstrap";

import { ReactComponent as Alert } from "assets/img/icons/alert.svg";

const DealsListCommentState = ({ comment }) => {
  return (
    <Col
      xs="12"
      md="8"
      className="mt-3 mt-md-0"
    >
      <div
        data-testid="deals-list-comment-state"
        className="d-flex align-items-center ml-md-3 ml-lg-0"
        style={{
          background: "rgba(47, 128, 237, 0.1)",
          borderRadius: "8px",
          padding: "11px 17px"
        }}
      >
        <span className="d-inline-block">
          <Alert data-testid="deals-list-comment-state-alert" />
        </span>
        <p
          className="mb-0 text-white ml-2 font-sm"
          data-testid="deals-list-comment-state-comment"
        >
          {comment}
        </p>
      </div>
    </Col>
  );
};

export default DealsListCommentState;
