import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";

const WizardErrors = ({ errors={}, status={}, alertOpen, onSetAlertOpen, redirectPath="/" }) => {
  const history = useHistory();

  return (
    <>
      {
        (alertOpen && errors.message) &&
          <ReactBSAlert
            warning
            title={errors.message}
            onConfirm={() => onSetAlertOpen(false)}
            confirmBtnBsStyle="success"
            confirmBtnText="OK"
            btnSize=""
          />
      }
      {
        (alertOpen && status.message) &&
          <ReactBSAlert
            success
            title={status.message}
            onConfirm={() => history.push(redirectPath)}
            confirmBtnBsStyle="success"
            confirmBtnText="OK"
            btnSize=""
          />
      }
    </>
  );
};

export default WizardErrors;
