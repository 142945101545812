import React, { useState } from "react";
import styled from "styled-components";

import { hideEmptyAssetsSelector } from "store/credentials/selectors";
import { useSelector } from "react-redux";
import { ReactComponent as Refresh } from "assets/img/icons/refresh-2.svg";

const RefreshButton = ({
  onHandleRefresh,
  loading,
  withHoverBg = true,
  width = 52,
  height = 36,
  testId = "refresh-button",
  ...props
}) => {
  const [refreshLoading, setRefreshLoading] = useState(false);
  const hideEmptyAssets = useSelector(hideEmptyAssetsSelector);

  const handleRefresh = () => {
    if (loading) return;
    setRefreshLoading(true);
    const filter = hideEmptyAssets ? {realPercent: {gte: 0}, balance: {gte: 0}} : {realPercent: {gte: {}}, balance: {gte: {}}};
    onHandleRefresh(filter)?.finally(() => setRefreshLoading(false));
  };

  if (loading && !refreshLoading) return null;

  if (loading && refreshLoading) return (
    <StyledIconRefresh
      withHoverBg={withHoverBg}
      loading={loading}
      width={width}
      height={height}
      refreshLoading={refreshLoading}
      data-testid={`${testId}-loading`}
    >
      <Refresh className="image" width={20} height={20}/>
    </StyledIconRefresh>
  );

  return (
    <StyledIconRefresh
      onClick={handleRefresh}
      loading={loading}
      withHoverBg={withHoverBg}
      width={width}
      height={height}
      data-testid={testId}
      {...props}
    >
      <Refresh width={20} height={20}/>
    </StyledIconRefresh>
  );
};

const StyledIconRefresh = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width}px;
  height: ${props => props.height}px;


  svg {
    path {
      stroke: ${(props) => props.loading ? "rgb(29, 140, 248, 0.3)" : "rgb(29, 140, 248)"};
    }
  }
  &:hover {
    background: ${(props) => props.loading || !props.withHoverBg ? "transparent" : "rgba(255, 255, 255, 0.03)"};
    border-radius: 8px;

    svg {
      path {
        stroke: ${(props) => !props.withHoverBg || props.refreshLoading ? "rgba(29, 140, 248, 0.3)" : "rgb(29, 140, 248)"};
      }
    }
  }

  .image {
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
  }
  @-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform:rotate(360deg);
    }
  }
`;

export default RefreshButton;
