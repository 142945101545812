import React from "react";
import styled from "styled-components";

import CounterInput from "components/CounterInput";
import Checkbox from "components/Checkbox";
import { CRITERIA_DESCRIPTIONS } from "../../bots/models/criteriaConfiguration";
import CriterionTooltips from "components/BotSettingsWidget/CriterionTooltips";

const CriteriaSettingCard = ({
  criteria={},
  handleToggleCriteria,
  handleChangePower
}) => {
  return (
    <CriteriaSettingCard.Card className="d-flex justify-content-between pl-3 pr-2 py-2 mb-1">
      <div className="d-flex">
        <Checkbox
          name="active"
          className="my-auto"
          checked={criteria.enabled}
          onChange={() => handleToggleCriteria(criteria.code)}
        />
        <p className="ml-2 my-auto font-sm font-weight-500">
          {CRITERIA_DESCRIPTIONS[criteria.code].name}
        </p>
        <CriterionTooltips
          code={criteria.code}
          idPrefix="ga-modal"
          className="my-auto"
        />
      </div>
      {
        criteria.enabled ?
        <CounterInput
          onChange={(value) => handleChangePower(criteria.code, value)}
          value={criteria.power}
          data-testid="counter-input"
        /> : null
      }
    </CriteriaSettingCard.Card>
  );
};

CriteriaSettingCard.Card = styled.div`
  background: #2e3148 !important;
  border-radius: 0.5rem;
  height: 51px;
`;

export default CriteriaSettingCard;
