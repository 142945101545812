import React from "react";
import moment from "moment";
import {capitalize, isEmpty} from "lodash";
import {beforeToday} from "helpers/DatepickerValidation";

import {STATUS_OPTIONS} from "views/bots/models/options";
import DealsFiltersPreloader from "views/bots/preloaders/DealsFiltersPreloader";
import DateField from "components/DateField";
import SelectInput from "components/SelectInput";
import BotMarketsSelect from "components/BotMarketsSelect";

const DealsFilters = ({loadingPage, onHandleFilter, id, loading, filter = {}}) => {
  if (loadingPage) return <DealsFiltersPreloader/>

  const getDateKey = () =>
    !filter?.status?.eq || filter?.status?.eq === "completed" ? "completedAt" : "cancelledAt";

  return (
    <div className="d-flex flex-column flex-sm-row justify-content-end w-75">
      <div className="w-100 pr-0 pr-sm-2 mb-2 mb-sm-0">
        <SelectInput
          className="mb-0 w-100"
          placeholder="Status"
          value={STATUS_OPTIONS.find((o) => o.value === filter?.status?.eq)}
          name="status"
          onChange={(options) => onHandleFilter({
            status: {eq: options?.value},
          })}
          options={STATUS_OPTIONS}
          isDisabled={loading || loadingPage}
        />
      </div>
      <div className="w-100 pr-0 pr-sm-2 mb-2 mb-sm-0">
        <BotMarketsSelect
          isWaiting
          isMulti
          isClearable
          id={id}
          placeholder="Market"
          name="market"
          isDisabled={loading || loadingPage}
          onChange={(option) => onHandleFilter({
            market: {in: option ? option.map((e) => e.value) : {}}
          })}
          value={filter?.market?.in?.map((item) => ({
            label: item,
            value: item,
            icon: {
              type: "cryptocurrency",
              size: "selectSize",
              code: item,
            }
          }))}
        />
      </div>
      <div className="w-100 d-flex flex-column flex-md-row mb-2 mb-sm-0">
        <DateField
          className="w-100"
          onChange={(value) => {
            onHandleFilter({
              [getDateKey()]: {
                gte: value || {},
                lte: value ? moment(value).format("YYYY-MM-DD 23:59:59") : {},
              }
            });
          }}
          isValidDate={beforeToday}
          dateFormat="YYYY-MM-DD 00:00:00"
          inputProps={{
            disabled: loading || loadingPage,
            className: "form-control overflow-hidden rbc-ellipsis",
            readOnly: true,
            placeholder: capitalize(getDateKey())?.replace("at", " at"),
            value: !isEmpty(filter?.[getDateKey()]?.gte) ?
              moment(filter?.[getDateKey()]?.gte).format("YYYY-MM-DD").replaceAll("-", "/") : ""
          }}
        />
      </div>
    </div>
  );
};

export default DealsFilters;
