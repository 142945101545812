import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import {
  CardBody,
  Card,
  CardHeader
} from "reactstrap";

import SkeletonPreloader from "components/SkeletonPreloader";

const ChannelWidgetPreload = ({
  count = 1,
  isEditButton,
  isStatisticsBar,
  isTarget
}) => (
  <Card>
    <CardHeader
      className="p-0"
      id="preloader"
    >
      <div className="d-flex flex-row justify-content-between align-items-center flex-wrap p-4">
        <div className="d-flex flex-row align-items-center ">
          <SkeletonPreloader
            height={20}
            width={20}

          />
          <SkeletonPreloader
            height={19}
            width={153}
            className="ml-2 mb-0"
          />
        </div>
        {isEditButton
          ? <SkeletonPreloader
            height={20}
            width={22}
          />
          : null}
        {isStatisticsBar
          ?
          <SkeletonPreloader
            height={16}
            className="mt-3"
          />
          : null}
      </div>
    </CardHeader>
    <CardBody className="pt-3 pb-4 px-4">
      {
        _.times(count, (e) =>
          <div className="d-flex align-items-center justify-content-between mt-2" key={e}>
            {isTarget
              ? <>
                <SkeletonPreloader
                  height={16}
                  width={32}
                />
                <SkeletonPreloader
                  height={16}
                  className="mx-2"
                />
                <SkeletonPreloader
                  height={16}
                  width={32}
                />
              </>
              : <>
                <SkeletonPreloader
                  height={16}
                  width={175}
                />
                <SkeletonPreloader
                  height={16}
                  width={32}
                />
              </>
            }
          </div>
        )
      }
    </CardBody>
  </Card>
)

SkeletonPreloader.propType = {
  count: PropTypes.number,
  isEditButton: PropTypes.bool,
  isStatisticsBar: PropTypes.bool,
  isTarget: PropTypes.bool
};

export default ChannelWidgetPreload;