import React from "react";
import { Card } from "reactstrap";
import TradingViewWidget, { Themes } from "react-tradingview-widget";

import { Trading } from "../../styled/StyledDealPage";
import TradingWithTickerPreloader from "../../ui/preloaders/TradingWithTickerPreloader";
import MarketTicker from "./MarketTicker";

const TradingWithTicker = ({
  data,
  ticker,
  loadingPage,
  isTradesLoading,
  quote,
  base,
  marketInformation
}) => {
  const {
    loading,
    data: {
      active
    }
  } = marketInformation;

  if (!active && !loading) return;
  if (loadingPage || isTradesLoading || !ticker || loading) return <TradingWithTickerPreloader withChart />;
  
    return (
      <Card>
        <MarketTicker
          exchange={data?.investment?.credentials?.exchange}
          ticker={ticker}
          quote={quote}
          base={base}
        />

        <Trading>
          {
            data?.bot?.investment?.credentials?.exchange &&
            <TradingViewWidget
              autosize
              symbol={`${data?.bot?.investment?.credentials?.exchange.toUpperCase()}:${data?.market?.base}${data?.market?.quote}`}
              hide_side_toolbar={false}
              theme={Themes.DARK}
              locale="en"
              showSymbolLogo
              interval={data?.bot?.period}
            />
          }
        </Trading>
      </Card>
    );
  }

export default TradingWithTicker;
