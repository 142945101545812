import React from "react";
import {Card, CardBody, CardHeader} from "reactstrap";
import _ from "lodash";
import styled from "styled-components";

import DefaultHeaderPreloader from "components/BotSettingsWidget/preloaders/DefaultHeaderPreloader";
import SkeletonPreloader from "components/SkeletonPreloader";

const GeneticSettingsPreloader = () => {
  return (
    <GeneticSettingsPreloader.Card className="p-0 pb-4">
      <CardHeader className="p-0 mb-4">
        <DefaultHeaderPreloader />
      </CardHeader>
      <CardBody className="p-0 px-4">
        <div className="mb-2">
          {
            _.times(2, (e) =>
              <div className="d-flex flex-column" key={e}>
                <div className="d-flex flex-row align-items-center justify-content-between mb-3">
                  <SkeletonPreloader
                    height={9}
                    width={57}
                  />
                  <SkeletonPreloader
                    height={12}
                    width={73}
                  />
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between mb-3">
                  <SkeletonPreloader
                    height={9}
                    width={81}
                  />
                  <SkeletonPreloader
                    height={12}
                    width={73}
                  />
                </div>
              </div>
            )
          }
        </div>
        <div className="d-flex w-100 mb-3">
          <SkeletonPreloader
            height={12}
            width={73}
          />
        </div>
        {
          _.times(3, (e) =>
            <GeneticSettingsPreloader.Box className="d-flex w-100 flex-row justify-content-between" key={e}>
              <div className="d-flex flex-column w-100">
                <div className="d-flex flex-row align-items-center justify-content-between mb-2">
                  <SkeletonPreloader
                    height={9}
                    width={57}
                  />
                  <div className="d-flex flex-row align-items-center">
                    {
                      _.times(10, (e) => <GeneticSettingsPreloader.Criterion key={e}/>)
                    }
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <SkeletonPreloader
                    height={9}
                    width={81}
                  />
                  <div className="d-flex flex-row align-items-center">
                    {
                      _.times(10, (e) => <GeneticSettingsPreloader.Criterion key={e}/>)
                    }
                  </div>
                </div>
              </div>
            </GeneticSettingsPreloader.Box>
          )
        }
      </CardBody>
    </GeneticSettingsPreloader.Card>
  )
}

GeneticSettingsPreloader.Card = styled(Card)`
  border-radius: 8px;
`

GeneticSettingsPreloader.Criterion = styled.div`
  width: 9px;
  height: 14px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 1px;
  margin-right: 2px;
  :last-child {
    margin-right: 0;
  }
`

GeneticSettingsPreloader.Box = styled.div`
  margin-bottom: 10px;
  :last-child {
    margin-bottom: 0;
  }
`;

export default GeneticSettingsPreloader;
