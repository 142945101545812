export const candlestickChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: "time",
      time: {
        unit: "minute",
        tooltipFormat: "YYYY-MM-DD HH:mm:ss",
      },
      ticks: {
        source: "auto"
      }
    },
    y: {
      position: "right",
    }
  },
  plugins: {
    tooltip: {
      mode: "index",
      intersect: false
    },
    legend: {
      display: false
    },
    zoom: {
      pan: {
        enabled: true,
        mode: "xy"
      },
      zoom: {
        wheel: {
          enabled: true
        },
        pinch: {
          enabled: true
        },
        mode: "xy"
      }
    }
  }
};