import React, {useEffect, useState} from "react";
import {
  Card,
  CardBody,
  CardHeader
} from "reactstrap";
import styled from "styled-components";
import ToggleSwitch from "components/ToggleSwitch";
import {updateBetaSettings} from "store/user/actions";
import {useDispatch} from "react-redux";
import {addSnackBar} from "store/snackbar/actions";
import SubCard from "components/SubCard";
import BetaFeaturesPreloader from "../preloaders/BetaFeaturesPreloader";

const BetaFeatures = ({isBetaEnabled, loading}) => {
  const [switched, setSwitched] = useState(false);
  const [isSwitched, setIsSwitched] = useState(false);

  const dispatch = useDispatch()

  useEffect(() => {
    if (isBetaEnabled !== undefined) {
      setSwitched(isBetaEnabled)
      setTimeout(() => {
        setIsSwitched(true)
      }, 0)
    }
  }, [isBetaEnabled])

  const handleBetaUpdate = async (value) => {
    setSwitched(value)
    const res = await dispatch(updateBetaSettings({isBetaEnabled: value}))
    dispatch(addSnackBar("success", res.message))
  }

  if (loading && isBetaEnabled === undefined) {
    return <BetaFeaturesPreloader/>
  }

  if (isBetaEnabled !== undefined && isSwitched) {
    return (
      <Card className="p-3">
        <BetaFeatures.Header className="d-flex justify-content-between p-2">
          <div className="d-flex flex-md-column flex-lg-row align-items-lg-center">
            <p className="my-auto h4 mr-2 font-weight-500 text-white text-nowrap">Beta features</p>
            <SubCard className="mt-md-2 mt-lg-0">
              <p className={`h5 mb-0 font-sm font-weight-500 text-${switched ? "success" : "text-white-35"}`}>
                {switched ? "Enabled" : "Disabled"}
              </p>
            </SubCard>
          </div>

          <ToggleSwitch
            onChange={(value) => handleBetaUpdate(value)}
            checked={switched}
            height={18}
            width={36}
            onColor="#26366b"
            offColor="#1c284d"
            onHandleColor="#00f2c3"
            offHandleColor="#FFFFFFBF"
            className="my-auto ml-auto"
          />
        </BetaFeatures.Header>

        <CardBody className="p-2">
          <p className="text-white-75 font-sm">
            When enabled you will be able to use BETA features of Darkbot
          </p>
        </CardBody>
      </Card>
    )
  }
};

BetaFeatures.Header = styled(CardHeader)`
  p {
    line-height: 19px;
  }
`
export default BetaFeatures;
