import React from "react";
import styled from "styled-components"
import {ReactComponent as Info} from "assets/img/icons/infoIcon.svg";
import {ReactComponent as Clock} from "assets/img/icons/clock.svg";

const MarketSummaryEmptyStates = ({title, values, botValues}) => {
  return (
    <MarketSummaryEmptyStates.Wrapper className="d-flex align-items-center px-4 py-3">
      {
        !values && !botValues ?
          <>
            <Info/>
            <p className="mb-0 ml-3 text-white-75">
              {title}
            </p>
          </>
          :
          <>
            <Clock/>
            <p className="mb-0 ml-3 text-white-75">
              Waiting for technical analysis data...
            </p>
          </>
      }
    </MarketSummaryEmptyStates.Wrapper>
  )
}

MarketSummaryEmptyStates.Wrapper = styled.div`
  svg {
    width: 20px;
    height: 20px;

    path {
      stroke: rgba(47, 128, 237, 1);
      stroke-opacity: 1;
    }
  }
`;

export default MarketSummaryEmptyStates;