import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import CustomScrollbars from "components/CustomScrollbars";
import InvestmentItem from "./InvestmentItem";
import EmptyState from "./EmptyState";
import { userExchangesSelector } from "store/exchanges/selectors";
import SortTableHead from "components/SortTableHead";
import StyledTable from "components/StyledTable";
import { BOT_WIZARD_HEAD } from "models/bot/wizard/enum/table";
import { setInvestment } from "store/bots/wizard/actions";

const InvestmentTable = ({
  items,
  active,
  loading,
  request,
  filters,
  selectedBots,
  setCredentialId,
  credentialsList,
  handleSorting,
  handleEmptyHide
}) => {
  const dispatch = useDispatch();
  const userExchanges = useSelector(userExchangesSelector);

  const handleClickInvestment = (investment) => {
    if (investment.id === active) return dispatch(setInvestment(false));
    dispatch(setInvestment(investment));
    setCredentialId(investment.id);
  };

  const getEmptyStateProps = () => {
    if (!items.length && (filters?.asset?.like || filters?.["credentials.exchange"]?.like || selectedBots)) return {
      title: "No results for your search",
      description: "Please adjust your filters, we have not found anything by your request."
    };

    if (!userExchanges?.length) return {
      title: "You do not have any available credentials yet",
      description: "Please connect at least one of your exchanges in order to have ability to create automatic trading bots for them.",
      credential: true
    };
    return {
      title: "Congrats!",
      description: "Your exchange is connected, and now it's time to start building your portfolio and exploring the exciting world of cryptocurrency trading",
      onButtonClick: handleEmptyHide,
      buttonText: "Show empty portfolio coins"
    };
  };

  if (!credentialsList?.items?.length) return;

  if (!items.length && !loading) return <EmptyState {...getEmptyStateProps()}/>

  return (
    <InvestmentTable.Wrapper>
      <CustomScrollbars
        autoHideTimeout={500}
        autoHideDuration={500}
        autoHeight
        autoHeightMax={Number.MAX_VALUE}
      >
        <StyledTable className="mb-0" paddingsThead="1rem">
          <SortTableHead
            handleSorting={handleSorting}
            sort={request?.sort}
            theadItems={BOT_WIZARD_HEAD}
          />
          {
            items.length ?
            <tbody className="font-sm">
              {items.map((investment, index) =>
                <InvestmentItem
                  investment={investment}
                  index={index}
                  active={active}
                  onClickHandler={handleClickInvestment}
                  key={investment?.id}
                />
              )}
            </tbody> : null
          }
        </StyledTable>
      </CustomScrollbars>
    </InvestmentTable.Wrapper>
  )
}

InvestmentTable.Wrapper = styled.div`
  tbody {
    tr {
      &:last-child {
        border-bottom: 1px solid #2E3148;
      }
    }
  }
`;

export default InvestmentTable;
