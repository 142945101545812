import React from "react";
import styled from "styled-components";
import noResult from "assets/img/portfolioResult.svg";

const EmptyPortfolio = () => {
  return (
    <>
      <EmptyPortfolio.EmptyBox />
      <EmptyPortfolio.EmptyBox />
      <EmptyPortfolio.EmptyBox />
      <EmptyPortfolio.EmptyBox />
      <EmptyPortfolio.Result>
        <img src={noResult} alt="" style={{width: "270px"}}/>
        <h3 className="mb-2 font-weight-500 text-white">No results for your search</h3>
        <EmptyPortfolio.Description>We cannot find your assets with the available balances. You may try adjusting your filters.</EmptyPortfolio.Description>
      </EmptyPortfolio.Result>
    </>
  )
}

EmptyPortfolio.EmptyBox = styled.div`
  background: rgb(39, 41, 60, .2);
  border-radius: 8px;
  height: 141px;
  margin-top: 16px;
`;

EmptyPortfolio.Result = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  left: 0;
  top: 30%;
`;

EmptyPortfolio.Description = styled.p`
  color: rgba(255,255,255, .75) !important;
  font-size: 14px;
`;

export default EmptyPortfolio;
