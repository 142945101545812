import React from "react";
import styled from "styled-components";
import {
  Modal,
  Card,
  CardHeader
} from "reactstrap";
import PropTypes from "prop-types";

import CreateSignalForm from "../forms/CreateSignalForm";
import { stringOrNull } from "../channelPropTypes";

const CreateSignalModal = ({
  handleToggleModal,
  isModalOpen,
  isActiveTable,
  channelId,
  logo,
  name,
  totalSubscribers
}) => {
  return (
    <CreateSignalModal.Modal
      isOpen={isModalOpen}
      toggle={handleToggleModal}
      modalClassName="modal-black"
    >
      <Card className="m-0">
        <CardHeader className="p-0 mb-2">
          <h3 className="h1 modal-title text-white mb-0">
            Create new signal
          </h3>
        </CardHeader>
        <CreateSignalForm
          channelId={channelId}
          handleToggleModal={handleToggleModal}
          isActiveTable={isActiveTable}
          logo={logo}
          name={name}
          totalSubscribers={totalSubscribers}
        />
      </Card>
    </CreateSignalModal.Modal>
  );
};

CreateSignalModal.Modal = styled(Modal)`
    max-width: fit-content !important;
    
    .modal-content {
      border-radius: 1rem;
    }

    .card {
      width: 669px;
      border-radius: 1rem !important;
      padding: 2rem;
      gap: 2rem;

      @media (max-width: 768px){
        width: 100%;
        padding: 1rem;
      }
    }
`;

CreateSignalModal.propTypes = {
  handleToggleModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  isActiveTable: PropTypes.bool,
  channelId: PropTypes.string,
  logo: stringOrNull,
  name: PropTypes.string,
  totalSubscribers: PropTypes.number
};

export default CreateSignalModal;
