import styled from "styled-components";

export const FormGroupWrapperStyled = styled.div`
  & input.form-control {
    padding-right: ${({ rightSymbolWidth, customPaddingRight }) =>
      customPaddingRight
      ||
      (rightSymbolWidth ? `${rightSymbolWidth}px` : "")} 
        !important;
    padding-left: ${({ leftSymbolWidth, customPaddingLeft }) =>
      customPaddingLeft
      ||
      (leftSymbolWidth ? `${leftSymbolWidth}px` : "")} 
        !important;
  }
`;

export const FormGroupSymbolStyled = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  padding: 11px;
  display: flex;
  align-items: center;
  font-size: 0.875rem;

  &.symbol-right {
    right: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    color: ${(props) => props.color || "rgba(255, 255, 255, 0.35)"};
  }

  &.symbol-left {
    left: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    color: ${(props) => props.color || "rgba(255, 255, 255, 0.35)"};
  }

  .tim-icons.icon-email-85 {
    padding-top: 2px;
  }
`;