import React from "react";
import {GS_TABLE_HEAD} from "models/bot/tables";

import SortTableHead from "components/SortTableHead";
import StyledTable from "components/StyledTable";
import CustomScrollbars from "components/CustomScrollbars";
import GSTableRow from "./GSTableRow";
import GSEmpty from "./GSEmpty";
import {CardBody} from "reactstrap";

const GSTable = ({items, request, handleSort}) => {

  if (!items?.length) {
    return (
      <GSEmpty
        title="No results for your search"
        description="Oops, we couldn't find any results for your search.
          Please try again with a different keyword or refine your search criteria."
      />
    )
  }

  return (
    <CardBody className="p-0 overflow-hidden" style={{borderRadius: "0 0 0.5rem 0.5rem"}}>
      <CustomScrollbars
        autoHideTimeout={500}
        autoHideDuration={500}
        autoHeight
        autoHeightMax={Number.MAX_VALUE}
      >
        <StyledTable className="mb-0" paddingsThead="0.5rem 1rem">
          <SortTableHead
            handleSorting={handleSort}
            sort={request?.sort}
            theadItems={GS_TABLE_HEAD}
          />
          <tbody className="font-sm">
            {
              items?.length && items?.map((item, idxRow) =>
                <GSTableRow
                  item={item}
                  key={item.id}
                  idxRow={idxRow}
                />)
            }
          </tbody>
        </StyledTable>
      </CustomScrollbars>
    </CardBody>
  )
}

export default GSTable;
