import React, {
  useLayoutEffect,
  useState
} from "react";
import {
  useHistory,
  useLocation
} from "react-router-dom";
import { useDispatch } from "react-redux";
import qs from "qs";
import styled from "styled-components";

import Button from "components/Button";
import Loading from "components/Loading";
import FormDescription from "views/auth/compounds/FormDescription";
import AuthLayout from "views/layouts/AuthLayout";
import { confirmEmail } from "store/auth/actions";
import illustration from "assets/img/supermen.svg"

const EmailConfirm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { secret } = qs.parse(location.search, { ignoreQueryPrefix: true });

  useLayoutEffect(() => {
    setIsLoading(true);
    dispatch(confirmEmail(secret))
      .then(() => setIsLoading(false))
      .catch((error) => setError(error.message));
  }, [dispatch, secret]);

  if (isLoading) {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <Loading
          color="var(--info)"
          size={64}
        />
      </div>
    )
  }

  return (
    <AuthLayout>
      <div className="content h-100 d-flex flex-column justify-content-center my-auto">
        <FormDescription
          title={
            error
              ? "Something went wrong!"
              : "Congratulations! Your email address was successfully verified."
          }
        />
        {
          error
            ? <p className="mb-4 text-danger ">
              {error}
            </p>
            : <StyledImg
              src={illustration}
              alt="create bot"
            />
        }
        <Button
          className="w-100"
          type="submit"
          onClick={() => history.push("/")}
          color="blue"
        >
          Go to the application
        </Button>
      </div>
    </AuthLayout>
  );
};

const StyledImg = styled.img`
  margin: 3rem 0 4rem;

  @media (min-width: 1140px) {
    margin: 1.5rem 0 2.5rem;
  }
  
  @media (max-width: 576px) {
    margin: 1rem 0 2rem;
  }
`;

export default EmailConfirm;
