import React, { 
  useEffect, 
  useState
} from "react";
import { Formik } from "formik";
import {
  useDispatch,
  useSelector
} from "react-redux";
import * as Yup from "yup";
import {mapValues} from "lodash";

import {
  validateIndicator,
  addDraftIndicator,
  editDraftIndicator
} from "store/strategies/actions";
import IndicatorsModalWrapper from "./IndicatorsModalWrapper";
import IndicatorForm from "./IndicatorForm";
import { currentConfigurationSelector } from "store/indicatorsConfiguration/selectors";
import {handleResponse} from "helpers/errorsValidator";
import { resetIndicatorsSignals } from "store/bots/actions";

const IndicatorsModal = ({
  onSetModalIsOpen,
  modalIsOpen,
  getIndicatorsConfiguration,
  editingIndicator={},
  editing,
  draft,
  setCurrentType,
  setCurrentRequired
}) => {
  const [indicatorType, setIndicatorType] = useState("");
  const [currentSettings, setCurrentSettings] = useState({});

  const dispatch = useDispatch()

  const configurations = useSelector((state) => state.indicatorsConfiguration);
  const currentConfiguration = useSelector((state) => currentConfigurationSelector(indicatorType)(state));
  const subscription = useSelector((state) => state.me.data.subscription);

  useEffect(() => {
    if (modalIsOpen) {
      setIndicatorType(currentConfiguration?.code);
    } else {
      setIndicatorType("");
    }
  }, [getIndicatorsConfiguration, setIndicatorType, modalIsOpen, currentConfiguration?.code]);

  useEffect(() => {
    if (editing) { setIndicatorType(editingIndicator.code) }
  }, [editingIndicator, editing, setIndicatorType]);

  useEffect(() => {
    if (indicatorType) {
      const fields = currentConfiguration.settings.reduce((accumulator, currentValue) => {
        return {
          [currentValue.code]: currentValue.default || "",
          ...accumulator
        };
      }, {});
      setCurrentSettings(fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicatorType, setCurrentSettings]);

  const onHandleResetChart = () => {
    dispatch(resetIndicatorsSignals());
    onSetModalIsOpen(false)
  };

  const validationSchema = Yup.object().shape({
    settings: Yup.lazy(obj =>
      Yup.object(
        mapValues(obj, (value) => {
          if (!value) return Yup.string().required();
          return Yup.mixed().notRequired();
        })
      )
    )
  })

  return(
    <IndicatorsModalWrapper
      onSetModalIsOpen={onHandleResetChart}
      modalIsOpen={modalIsOpen}
    >
      <Formik
        enableReinitialize
        initialValues={{
          code: currentConfiguration.code || "",
          type: editingIndicator.type || "both",
          required: editingIndicator.required || false,
          settings: editing && editingIndicator.code === indicatorType ?
                    editingIndicator.settings : currentSettings
        }}
        validationSchema={Object.keys(currentSettings).length ? validationSchema : null}
        onSubmit={async(values, formik) => {
          formik.setSubmitting(true);
          const res = await dispatch(validateIndicator(values));
          if (res.errors) {
            handleResponse(res, formik.setErrors);
          } else {
            if (draft && !editing) {
              await dispatch(addDraftIndicator({
                code: currentConfiguration.code,
                ...values
              }));
            }
            if (draft && editing) {
              await dispatch(editDraftIndicator({
                id: editingIndicator.id,
                code: currentConfiguration.code,
                ...values
              }));
              setCurrentRequired(values.required)
              setCurrentType(values.type)
            }

            if (!res.errors) {
              onHandleResetChart();
            }
          }
          onHandleResetChart()
          formik.setSubmitting(false);
        }}
      >
        {({ errors, isSubmitting, values, setFieldValue, setErrors }) => (
          <IndicatorForm
            indicatorType={indicatorType}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            onHandleResetChart={onHandleResetChart}
            modalIsOpen={modalIsOpen}
            currentConfiguration={currentConfiguration}
            onSetIndicatorType={setIndicatorType}
            configurations={configurations}
            isSubmitting={isSubmitting}
            editing={editing}
            setErrors={setErrors}
            subscription={subscription}
          />
        )}
      </Formik>
    </IndicatorsModalWrapper>
  );
};

export default IndicatorsModal;
