import React from "react";
import * as Sentry from "@sentry/react";

import FallBack from "components/FallBack";
import Indicators from "./Indicators";
import { TradeRequestList } from "shared/ui";
import {
  fetchHistoryTradeRequests,
  fetchOpenNewBuy,
  forceRunDeal,
} from "store/deals/actions";
import { selectHistoryTradeRequest } from "store/deals/selectors";

const OverviewTab = ({
  id,
  data,
  loadingPage,
  onHandleRefresh
}) => {
  return (
    <>
      <Sentry.ErrorBoundary fallback={<FallBack />}>
        <Indicators
          id={id}
          status={data?.status}
        />
      </Sentry.ErrorBoundary>

      <Sentry.ErrorBoundary fallback={<FallBack />}>
        <TradeRequestList
          id={id}
          data={data}
          openTradeRequest={data?.openTradeRequest}
          loadingPage={loadingPage}
          onHandleRefresh={onHandleRefresh}
          selectData={selectHistoryTradeRequest}
          pageView="deals"
          onHandleForceStart={forceRunDeal}
          onHandleOpenNewBuy={fetchOpenNewBuy}
          actionFetchHistoryTR={fetchHistoryTradeRequests}
        />
      </Sentry.ErrorBoundary>
    </>
  )
};

export default OverviewTab;
