import React, { useState } from "react";
import Button from "reactstrap-button-loader";
import {
  UncontrolledAlert,
  CardHeader,
  Card,
  CardBody,
} from "reactstrap";

import IndicatorsList from "../../indicators/IndicatorsList";
import { ReactComponent as BarChart } from "assets/img/icons/barChart.svg";
import IndicatorsModal from "../../indicators/IndicatorsModal";

const AttachedIndicators = ({
  indicators=[],
  detachIndicator,
  id,
  loading
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  return(
    <>
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <BarChart className="my-auto mr-2" />
            <p className="h4 my-auto font-weight-bold m-0">
                Indicators
              </p>
            </div>
            {
              !loading ?
              <Button
                size="sm"
                onClick={() => {
                  setModalIsOpen(true);
                }}
              >
                Add new
              </Button> : null
            }
          </div>
          {successMessage &&
            <UncontrolledAlert color="success" fade={false}>
              {successMessage}
            </UncontrolledAlert>}
        </CardHeader>
        <CardBody>
          <IndicatorsList
            indicators={indicators}
            onDetachIndicator={detachIndicator}
            onSetModalIsOpen={setModalIsOpen}
            editable={true}
            canRemove={true}
            strategyId={id}
            loading={loading}
            onSetSuccessMessage={setSuccessMessage}
          />
        </CardBody>
      </Card>
      <IndicatorsModal
        modalIsOpen={modalIsOpen}
        onSetModalIsOpen={setModalIsOpen}
        strategyId={id}
        onSetSuccessMessage={setSuccessMessage}
      />
    </>
  );
};

export default AttachedIndicators;
