export const DEALS_HISTORY_TABLE_HEAD = [
  { name: "market", label: "Market", isSortable: true },
  { name: "invested", label: "Invested", isSortable: true },
  { name: "profit", label: "Return", isSortable: true },
  { name: "profitPercent", label: "ROI", isSortable: true },
  { name: "duration", label: "Duration", isSortable: true },
];

export const TABLE_GROUP_BUTTONS = [
  {title: "Trades", type: "trades"},
  {title: "Orders", type: "orders"}
]
