/* eslint-disable complexity */
/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import styled from "styled-components";
import {
  Formik,
  Form
} from "formik";
import Button from "reactstrap-button-loader";
import {
  Row,
  Col,
  Label,
  FormGroup,
  UncontrolledAlert
} from "reactstrap";
import * as Yup from "yup";
import NumberInput from "components/NumberInput";
import ToggleSwitch from "components/ToggleSwitch";
import { handleResponse } from "helpers/errorsValidator";

const settingsSchema = Yup.object().shape({
  profit: Yup.string()
    .nullable()
    .required("This field is required."),
  volume: Yup.string()
    .nullable()
    .required("This field is required."),
  trailingBuy: Yup.string()
    .nullable()
    .required("This field is required."),
  trailingSell: Yup.string()
    .nullable()
    .required("This field is required."),
  deviation: Yup.string()
    .nullable()
    .required("This field is required."),
  martingale: Yup.string()
    .nullable()
    .required("This field is required."),
  stopLossPercent: Yup.string()
    .nullable()
    .required("This field is required."),
  aggressive: Yup.string()
    .nullable()
    .required("This field is required."),
  reverse: Yup.string()
    .nullable()
    .required("This field is required."),
  minimumSignals: Yup.string()
    .nullable()
    .required("This field is required."),
  maxTradeRequests: Yup.string()
    .nullable()
    .required("This field is required."),
});

const style = {
  color: "#ec250d",
};

const SettingsForm = ({
  data={},
  onEdit,
  id,
  onSetModalIsOpen
}) => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        profit: data.profit || "",
        volume: data.volume || "",
        trailingBuy: data.trailingBuy || "",
        trailingSell: data.trailingSell || "",
        deviation: data.deviation || "",
        martingale: data.martingale || "",
        stopLossPercent: data.stopLossPercent || "",
        aggressive: data.aggressive || false,
        reverse: data.reverse || false,
        minimumSignals: data.minimumSignals || 0,
        maxTradeRequests: data.maxTradeRequests || "",
      }}
      validationSchema={settingsSchema}
      onSubmit={async(values, formik) => {
        formik.setSubmitting(true);
        const res = await onEdit(id, values);
        if (res.errors) {
          handleResponse(res, formik.setErrors);
        } else {
          formik.setStatus({ message: res.message });
        }
        formik.setSubmitting(false);
      }}
    >
      {({ values, errors, isSubmitting, status, setFieldValue }) => (
        <Form>
          {errors.message &&
            <UncontrolledAlert color="danger" fade={false}>
              {errors.message}
            </UncontrolledAlert>}
          {status &&
            <UncontrolledAlert color="success" fade={false}>
              {status.message}
            </UncontrolledAlert>}
          <Col xs="12">
            <h3>Strategy settings</h3>
          </Col>
          <Form>
            <Row>
              <Col xs="6">
                <Col xs="12">
                  <Label className="text-muted">
                    First Round volume % {" "}
                    <i className="far fa-question-circle" />
                  </Label>
                  <FormGroup className={errors.volume && submitted ? "has-danger" : "has-success"}>
                    <NumberInput
                      type="float"
                      value={values.volume}
                      name="volume"
                      onChange={(value) => setFieldValue("volume", value)}
                    />
                    <div className="w-100">
                      {errors.volume && submitted ?
                        <label style={style}>{errors.volume}</label> : null}
                    </div>
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <Label className="text-muted">Minimum profit %</Label>
                  <FormGroup className={errors.profit && submitted ? "has-danger" : "has-success"}>
                    <NumberInput
                      value={values.profit}
                      name="profit"
                      onChange={(value) => setFieldValue("profit", value)}
                    />
                    <div className="w-100">
                      {errors.profit && submitted ?
                        <label style={style}>{errors.profit}</label> : null}
                    </div>
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <Label className="text-muted">
                    Stop loss % {" "}
                    <i className="far fa-question-circle" />
                  </Label>
                  <FormGroup className={errors.stopLossPercent && submitted ? "has-danger" : "has-success"}>
                    <NumberInput
                      value={values.stopLossPercent}
                      name="stopLossPercent"
                      onChange={(value) => setFieldValue("stopLossPercent", value)}
                    />
                    <div className="w-100">
                      {errors.stopLossPercent && submitted ?
                        <label style={style}>{errors.stopLossPercent}</label> : null}
                    </div>
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <Label className="text-muted">
                    Martingale % {" "}
                    <i className="far fa-question-circle" />
                  </Label>
                  <FormGroup className={errors.martingale && submitted ? "has-danger" : "has-success"}>
                    <NumberInput
                      value={values.martingale}
                      name="martingale"
                      onChange={(value) => setFieldValue("martingale", value)}
                    />
                    <div className="w-100">
                      {errors.martingale && submitted ?
                        <label style={style}>{errors.martingale}</label> : null}
                    </div>
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <SettingsForm.CheckboxWrapper className="w-100 d-flex justify-content-between p-3">
                    <h5 className="text-muted my-auto">
                      Aggressive order processing{" "}
                      <i className="far fa-question-circle" />
                    </h5>
                    <ToggleSwitch
                      onChange={() => setFieldValue("aggressive", !values.aggressive)}
                      checked={values.aggressive}
                      height={18}
                      width={45}
                      onColor="#26366b"
                      offColor="#1c284d"
                      onHandleColor="#3fcbb0"
                      offHandleColor="#ff8d72"
                      uncheckedIcon={<p className="text-danger h6 text-center mb-0">OFF</p>}
                      checkedIcon={<p className="text-success h6 text-center mb-0">ON</p>}
                      className="my-auto"
                    />
                  </SettingsForm.CheckboxWrapper>
                </Col>
              </Col>
              <Col xs="6">
                <Col xs="12">
                  <Label className="text-muted">
                    Minimum Indicator signals{" "}
                    <i className="far fa-question-circle" />
                  </Label>
                  <FormGroup className={errors.minimumSignals && submitted ? "has-danger" : "has-success"}>
                    <NumberInput
                      type="int"
                      min={0}
                      max={data.indicators ? data.indicators.length : 0}
                      name="minimumSignals"
                      value={values.minimumSignals}
                      onChange={(value) => setFieldValue("minimumSignals", value)}
                    />
                    <div className="w-100">
                      {errors.minimumSignals && submitted ?
                        <label style={style}>{errors.minimumSignals}</label> : null}
                    </div>
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <Label className="text-muted">
                    Round deviation %{" "}
                    <i className="far fa-question-circle" />
                  </Label>
                  <FormGroup className={errors.deviation && submitted ? "has-danger" : "has-success"}>
                    <NumberInput
                      value={values.deviation}
                      name="deviation"
                      onChange={(value) => setFieldValue("deviation", value)}
                    />
                    <div className="w-100">
                      {errors.deviation && submitted ?
                        <label style={style}>{errors.deviation}</label> : null}
                    </div>
                  </FormGroup>
                </Col>
                <div className="d-flex">
                  <Col xs="6">
                    <Label className="text-muted">
                      Trailing buy % {" "}
                      <i className="far fa-question-circle" />
                    </Label>
                    <FormGroup className={errors.trailingBuy && submitted ? "has-danger" : "has-success"}>
                      <NumberInput
                        value={values.trailingBuy}
                        name="trailingBuy"
                        onChange={(value) => setFieldValue("trailingBuy", value)}
                      />
                      <div className="w-100">
                        {errors.trailingBuy && submitted ?
                          <label style={style}>{errors.trailingBuy}</label> : null}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <Label className="text-muted">
                      Trailing sell % {" "}
                      <i className="far fa-question-circle" />
                    </Label>
                    <FormGroup className={errors.trailingSell && submitted ? "has-danger" : "has-success"}>
                      <NumberInput
                        value={values.trailingSell}
                        name="trailingSell"
                        onChange={(value) => setFieldValue("trailingSell", value)}
                      />
                      <div className="w-100">
                        {errors.trailingSell && submitted ?
                          <label style={style}>{errors.trailingSell}</label> : null}
                      </div>
                    </FormGroup>
                  </Col>
                </div>
                <Col xs="12">
                  <Label className="text-muted">
                    Maximum trade requests
                    <i className="far fa-question-circle" />
                  </Label>
                  <FormGroup className={errors.maxTradeRequests && submitted ? "has-danger" : "has-success"}>
                    <NumberInput
                      value={values.maxTradeRequests}
                      name="maxTradeRequests"
                      onChange={(value) => setFieldValue("maxTradeRequests", value)}
                    />
                    <div className="w-100">
                      {errors.maxTradeRequests && submitted ?
                        <label style={style}>{errors.maxTradeRequests}</label> : null}
                    </div>
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <SettingsForm.CheckboxWrapper className="w-100 d-flex justify-content-between p-3">
                    <h5 className="text-muted my-auto">
                      Order book reverse{" "}
                      <i className="far fa-question-circle" />
                    </h5>
                    <ToggleSwitch
                      onChange={() => setFieldValue("reverse", !values.reverse)}
                      checked={values.reverse}
                      height={18}
                      width={45}
                      onColor="#26366b"
                      offColor="#1c284d"
                      onHandleColor="#3fcbb0"
                      offHandleColor="#ff8d72"
                      uncheckedIcon={<p className="text-danger h6 text-center mb-0">OFF</p>}
                      checkedIcon={<p className="text-success h6 text-center mb-0">ON</p>}
                      className="my-auto"
                    />
                  </SettingsForm.CheckboxWrapper>
                </Col>
              </Col>
              <Col xs="12">
                <div className="d-flex justify-content-between mt-4">
                  <Button
                    color="default"
                    size="lg"
                    onClick={() => onSetModalIsOpen(false)}
                  >
                    Close
                  </Button>
                  <Button
                    color="info"
                    type="submit"
                    size="lg"
                    disabled={isSubmitting}
                    onClick={() => setSubmitted(true)}
                    loading={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Form>
      )}
    </Formik>
  );
};

SettingsForm.CheckboxWrapper = styled.div`
  background: #1d1e2a;
  border-radius: 4px;
`;

export default SettingsForm;
