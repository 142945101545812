import React from "react";

import _ from "lodash";
import NotificationItemPreloader from "./NotificationItemPreloader";

const NotificationPreloader = () => {
  return (
    _.times(5, (e) =>
      <NotificationItemPreloader key={e} />
    )
  )
};

export default NotificationPreloader;
