import { combineReducers } from "redux";

import withDataLoading from "store/withDataLoading";
import {
  CREATE_CREDENTIAL,
  DELETE_INVESTMENT,
  CREATE_INVESTMENT,
  UPDATE_INVESTMENT_ALLOCATION,
  VERIFY_CREDENTIALS,
  GET_CREDENTIAL_REQUEST_LOG,
  GET_CREDENTIAL_STATISTICS,
  GET_CREDENTIAL_STATISTICS_LOADING,
  SELL_ALT_COIN,
  CANCEL_SELL_ALT_COIN,
  CANCEL_SELL_ALT_COINS,
  TOGGLE_INVESTMENT,
  UPDATE_INVESTMENTS,
  UPDATE_CREDENTIAL_COMMENT,
  GET_BALANCES_FAILURE,
  GET_BALANCES_LOADING,
  GET_BALANCES_SUCCESS,
  UPDATE_ACCESS,
  SET_HIDE_EMPTY_ASSETS,
  LOAD_MORE_ALLOCATION_REBALANCING,
  GET_CREDENTIAL_BOTS,
  GET_CREDENTIAL_BOTS_LOADING
} from "./constants";
import {
  REBALANCE_TOGGLE_ERROR,
  REBALANCE_TOGGLE_LOADING,
  REBALANCE_TOGGLE_SUCCESS,
} from "../investment/constants";

const initialState = { data: {}, loading: false, error: false };
const listInitialState = { data: [], loading: false, error: false };
// eslint-disable-next-line complexity
export const list = (state = listInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
      case CREATE_CREDENTIAL:
        return {
          ...state,
          data: [...state.data, payload]
        };

      default:
        return state;
    }
};

export const item = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case UPDATE_ACCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload
        }
      };

    case DELETE_INVESTMENT:
      return {
        ...state,
        data: {
          ...state.data,
          investments: state.data.investments.filter((i) => payload !== i.id)
        }
      };

    case CREATE_INVESTMENT:
      return {
        ...state,
        data: {
          ...state.data,
          investments: payload
        }
      };

    case UPDATE_INVESTMENT_ALLOCATION:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload
        }
      };

    case UPDATE_CREDENTIAL_COMMENT:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.item
        }
      };

    default:
      return state;
  }
}

export const verified = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case VERIFY_CREDENTIALS:
      return {
        ...payload,
        portfolio: payload.portfolio.map((credential) => {
          if (credential.amount) {
            return { ...credential, enabled: true };
          }
          return { ...credential, enabled: false };
        })
      };

    case TOGGLE_INVESTMENT:
      return {
        ...state,
        portfolio: state.portfolio.map((credential) => {
          if (credential.coin.code === payload.coin) {
            return { ...credential, enabled: payload.value };
          }
          return credential;
        })
      };

    case UPDATE_INVESTMENTS:
      return {
        ...state,
        portfolio: state.portfolio.map((credential) => {
          if (credential.coin.code === payload.coin) {
            return { ...credential, allocation: payload.allocation };
          }
          return credential;
        })
      };

    case SELL_ALT_COIN:
      return {
        ...state,
        balances: [
          ...state.balances.map((coin) => {
            if (coin.coin.code === payload.altCoin) {
              return {
                ...coin,
                profit: payload.profit,
                estimatedProfit: payload.estimatedProfit,
                soldFor: payload.soldFor
              };
            }
            return coin;
          })
        ]
      };

    case CANCEL_SELL_ALT_COIN:
      return {
        ...state,
        balances: [
          ...state.balances.map((c) => {
            if (c.coin.code === payload.altCoin) {
              delete c.profit;
              delete c.estimatedProfit;
              delete c.soldFor;

              return c;
            }
            return c;
          })
        ]
      };

    case CANCEL_SELL_ALT_COINS:
      return {
        ...state,
        balances: [
          ...state.balances.map((c) => {
            if (c.soldFor === payload.stableCoin) {
              delete c.profit;
              delete c.estimatedProfit;
              delete c.soldFor;

              return c;
            }
            return c;
          })
        ]
      };

    default:
      return state;
  }
};

export const requestLog = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CREDENTIAL_REQUEST_LOG:
      return {
        ...payload
      };

    default:
      return state;
  }
};

export const statistics = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CREDENTIAL_STATISTICS_LOADING:
      return {
        loading: true,
      };

    case GET_CREDENTIAL_STATISTICS:
      return {
        loading: false,
        data: payload
      };

    default:
      return state;
  }
};

const balances = (state = { data: [], loading: false, error: false }, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_BALANCES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload.items
      };

    case GET_BALANCES_LOADING:
      return {
        loading: true,
        data: [],
        error: false
      };

    case GET_BALANCES_FAILURE:
      return {
        data: [],
        loading: false,
        error: payload
      };

    default:
      return state;
  }
};

const rebalances = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REBALANCE_TOGGLE_LOADING:
      return {
        ...state,
        loading: true
      };

    case REBALANCE_TOGGLE_SUCCESS:
      return {
        data: payload.credentials.rebalance,
        loading: false
      };

    case REBALANCE_TOGGLE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload
      };

    default:
      return state;
  }
};

const allocationRebalancing = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_MORE_ALLOCATION_REBALANCING:
      return {
        ...state,
        loading: false,
        data: {
          ...payload,
          items: [...state.data.items, ...payload.items]
        }
      };

    default:
      return state;
  }
};

const investmentsFilters = (state = { hideEmptyAssets: true }, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_HIDE_EMPTY_ASSETS:
      return {
        ...state,
        hideEmptyAssets: payload
      };

    default:
      return state;
  }
};

const bots = (state = initialState, action) => {
  const {type, payload} = action;

  switch(type){
    case GET_CREDENTIAL_BOTS_LOADING:
      return {
        ...state,
        loading: true
      }
    case GET_CREDENTIAL_BOTS :
      return {
        ...state,
        loading: false,
        data: payload
      }
    default:
      return state;
  }
}

export default combineReducers({
  list: withDataLoading(null, "CREDENTIALS"),
  item: withDataLoading(item, "CREDENTIAL"),
  verified,
  openOrders: withDataLoading(null, "OPEN_ORDERS"),
  requestLog,
  statistics: withDataLoading(statistics, "CREDENTIAL_STATISTICS"),
  tradeHistory: withDataLoading(null, "TRADE_HISTORY"),
  statisticsBalance: withDataLoading(null, "STATISTICS_BALANCE"),
  investments: withDataLoading(null, "CREDENTIAL_INVESTMENTS", []),
  investmentsFilters,
  balances: withDataLoading(balances, "BALANCES", { ...initialState, data: [] }),
  rebalances,
  rebalanceHistory: withDataLoading(null, "REBALANCE_HISTORY"),
  allocationRebalancing: withDataLoading(allocationRebalancing, "ALLOCATION_REBALANCING"),
  balance: withDataLoading(null, "BALANCE"),
  bots
});
