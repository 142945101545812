import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  FormGroup,
  Tooltip
} from "reactstrap";
import { DelayInput } from "react-delay-input";
import { useSelector } from "react-redux";

import SelectInput from "components/SelectInput";
import { ReactComponent as SmallToBig } from "assets/img/icons/smallToBig.svg";
import useFilterAndSorting from "hooks/useFilterAndSorting";
import { CHANNELS_SORT_OPTIONS, CHANNELS_STATUS_OPTIONS } from "models/filters";
import { selectMeData } from "store/user/selectors";

const Filters = ({ loading }) => {
  const [tooltip, setTooltip] = useState(false);
  const { id } = useSelector(selectMeData);
  const { handleFilter, handleSorting, filter, sort } = useFilterAndSorting("/channels");
  const toggle = () => setTooltip(!tooltip);
  const sortingButton = () => {
    if (sort?.by) {
      handleSorting({
        order: sort?.order === "ASC" || !sort?.order ? "DESC" : "ASC",
        by: sort?.by
      });
    }
  };

  const handleSubscriptionFilter = (option) => {
    if (option === "subscribed") {
      handleFilter({"subscribers.user": { eq: id }});
    } else {
      handleFilter({"subscribers.user": { neq: id }});
    }
  };
  
  return (
    <Card className="mt-3 mb-4 mx-0">
      <CardBody className="p-4 d-flex flex-column flex-md-row justify-content-between">
        <FormGroup className="mb-0 w-100 mr-3 mb-2 mb-md-0">
          <DelayInput
            autoComplete="off"
            delayTimeout={700}
            minLength={1}
            type="text"
            name="name"
            className="form-control"
            placeholder="Name"
            value={filter?.name?.like ?? ""}
            onChange={({ target }) => handleFilter({ name: { like: target.value } })}
          />
        </FormGroup>
        <FormGroup className="mb-0 w-50 mr-3 mb-2 mb-md-0">
          <SelectInput
            isClearable
            placeholder="Subscription status"
            name="subscriptionStatus"
            width="100%"
            onChange={(option) => handleSubscriptionFilter(option?.value)}
            options={CHANNELS_STATUS_OPTIONS}
          />
        </FormGroup>
        <Filters.WrapSortBy>
          <FormGroup className="mb-0 w-100 mr-3 mb-2 mb-md-0">
            <SelectInput
              isClearable
              placeholder="Sort"
              name="sort"
              width="100%"
              onChange={(option) => handleSorting({ order: sort?.order || "DESC", by: option?.value })}
              options={CHANNELS_SORT_OPTIONS}
            />
          </FormGroup>
          <Filters.Sorting
            onClick={sortingButton}
            loading={loading}
            by={sort?.by}
            id="sort"
          >
            <Filters.SortingOrderIcon order={sort?.order || "DESC"} />
            {
              !sort?.by &&
              <Tooltip target="sort" placement="top" isOpen={tooltip} toggle={toggle}>
                Select sort by
              </Tooltip>
            }
          </Filters.Sorting>
        </Filters.WrapSortBy>
      </CardBody>
    </Card>
  );
};

Filters.Sorting = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    path {
      fill-opacity: ${(props) => props.loading || !props?.by ? ".2" : "0.35"};
    }
  }

  &:hover {
    svg {
      path {
        fill-opacity: ${(props) => props.loading || !props?.by ? ".2" : "1"};
      }
    }
  }
`;

Filters.SortingOrderIcon = styled(SmallToBig)`
  transition: transform .2s ease-in-out;
  transform: ${(props) => props.order === "DESC" ? "rotate(180deg)" : ""};
`;

Filters.WrapSortBy = styled.div`
  display: flex;
  width:25%;
  
  @media (max-width: 768px) {
    width:100%;
  }
`
Filters.propTypes = {
  loading: PropTypes.bool.isRequired
}

export default Filters;
