import React from "react";
import SkeletonPreloader from "components/SkeletonPreloader";
import {
  CardHeader,
  CardBody
} from "reactstrap";

import StyledCard from "./StyledCard";
import TablePreload from "./TablePreload";

const RebalanceHistoryPreloader = () => {
  return (
    <StyledCard className="overflow-hidden">
      <CardHeader className="pt-4 px-4 pb-3">
        <div className="d-flex">
          <SkeletonPreloader
            circle
            height={19}
            width={19}
            className="mr-2 my-auto"
          />
          <SkeletonPreloader
            height={14}
            width={111}
            className="my-auto"
          />
        </div>
      </CardHeader>
      <CardBody className="p-0">
        <TablePreload />
      </CardBody>
    </StyledCard>
  );
};

export default RebalanceHistoryPreloader;
