import React from "react";
import { CardBody } from "reactstrap";

import { formatterToNull } from "helpers/valuesFormatters";
import { numberOrNull } from "views/channels/channelPropTypes";

const ChannelSignalsWidgetBody = ({
  loss,
  open,
  profit,
  accepted
}) => {
  return (
    <CardBody className="p-0 mt-2">
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center justify-content-between">
          <p className="font-sm font-weight-400 text-white-75 mb-0">
            Successful
          </p>
          <p className="mb-0 font-weight-500 text-white">
            {formatterToNull(profit)}
          </p>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <p className="font-sm font-weight-400 text-white-75 mb-0">
            Failed
          </p>
          <p className="mb-0 font-weight-500 text-white">
            {formatterToNull(loss)}
          </p>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <p className="font-sm font-weight-400 text-white-75 mb-0">
            Active
          </p>
          <p className="mb-0 font-weight-500 text-white">
            {formatterToNull(open)}
          </p>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <p className="font-sm font-weight-400 text-white-75 mb-0">
            Accepted agreements
          </p>
          <p className="mb-0 font-weight-500 text-white">
            {formatterToNull(accepted)}
          </p>
        </div>
      </div>
    </CardBody>
  );
};

ChannelSignalsWidgetBody.propTypes = {
  loss: numberOrNull,
  open: numberOrNull,
  profit: numberOrNull
}

export default ChannelSignalsWidgetBody;