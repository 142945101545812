import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import styled from "styled-components";
import classNames from "classnames";
import { Card, CardBody, CardHeader } from "reactstrap";
import { selectBalanceStatistics } from "store/investment/seletors";
import { drawInvestmentStatisticsChart } from "charts";
import { getInvestmentStatistics, resetInvestmentStatistics } from "store/investment/actions";
import EmptyChart from "assets/img/EmptyChart.png";
import BalanceStatisticsChartPreloader from "../preloaders/BalanceStatisticsChartPreloader";
import { ReactComponent as Chart } from "assets/img/BalanceStatisticsChartPreload.svg";
import {CHART_GROUP_BUTTONS} from "charts/enum";
import ButtonsGroup from "components/ButtonsGroup";

const BalanceStatisticsChart = ({id, charts, pageLoading}) => {
  const [period, setPeriod] = useState("day");
  const { data, loading, error } = useSelector(selectBalanceStatistics);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(getInvestmentStatistics(id, "day"));
    return () => dispatch(resetInvestmentStatistics());
  }, [dispatch, id]);

  if (pageLoading) return <BalanceStatisticsChartPreloader />;

  if (error) {
    return (
      <Card className="mb-4">
        <CardBody>
          <div
            className="chart-area d-flex flex-column justify-content-center text-center w-100"
            style={{ height: "260px" }}
          >
            <p className="text-danger">{error}</p>
          </div>
        </CardBody>
      </Card>
    );
  }

  const renderChart = () => {
    if (loading) return <Chart className="w-100 h-100" />;
    if (data && Object.keys(data).length) return (
      <div className="chart-area" style={{height: "406px"}}>
        <Line
          data={drawInvestmentStatisticsChart(data, period).data}
          options={drawInvestmentStatisticsChart(data, period).options}
        />
      </div>
    );
    if (!Object.keys(data || {}).length && !loading) {
      return (
        <div className="d-flex flex-column justify-content-center text-center h-100">
          <p className="h4">
            There are no data to show
          </p>
        </div>
      )
    }
  };

  return (
    <Card className="mb-4">
      <CardHeader className="px-4 pt-3 pb-0">
        <div className="d-flex flex-column flex-lg-row justify-content-between">
          <div className="d-flex align-items-center mb-2 mb-lg-0">
            <img src={charts} alt="" style={{ width: "15px" }}/>
            <p className="my-auto m-0 ml-2 text-white font-weight-500">
              Balance statistics
            </p>
          </div>
          <ButtonsGroup
            group={CHART_GROUP_BUTTONS}
            current={period}
            setCurrent={setPeriod}
            callFunc={getInvestmentStatistics}
            params={id}
            disable={loading}
          />
        </div>
      </CardHeader>
      <BalanceStatisticsChart.ChartCard
        className={classNames("card-chart pt-4 pb-3 px-4", {
          empty: !Object.keys(data || {}).length && !loading
        })}
      >
        <CardBody className="p-0">
          {renderChart()}
        </CardBody>
      </BalanceStatisticsChart.ChartCard>
    </Card>
  );
};

BalanceStatisticsChart.ChartCard = styled(CardBody)`
  &.empty {
    background-image: url(${EmptyChart}) !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: 300px;
  }
  min-height: 325px;
`;

export default BalanceStatisticsChart;
