import React, { useEffect } from "react";
import styled from "styled-components";
import { Col } from "reactstrap";

import cardsIllustration from "assets/img/mainWizard/cards-illustration.png";
import Button from "components/Button";
import { ReactComponent as RocketEmoji } from "assets/img/icons/rocketEmoji.svg";
import createBotIllustration from "assets/img/mainWizard/create-bot-illustration.png";

const WelcomeStep = ({ setIsCurrentStepValid, handleMoveToNextStep }) => {
  useEffect(() => {
    setIsCurrentStepValid(true);
    return () => setIsCurrentStepValid(false);
  }, [setIsCurrentStepValid]);

  return (
    <Wrapper>
      <Col
        xs="12"
        md="7"
        className="p-0"
      >
        <div className="d-flex flex-column">
          <div className="d-flex flex-column-reverse flex-md-row">
            <h1 className="mb-4 font-weight-600 mr-3">
              Welcome to Darkbot
            </h1>
            <RocketEmoji className="mt-1 mb-3 mb-md-0"/>
          </div>
          <p className="mb-4">
            {"Get ready to explore the world of automated crypto trading with ease. Whether you're a seasoned trader or new to the game, we're here to help you make the most of your crypto journey."}
          </p>
          <p>
            {"Let's dive in together and discover the potential of automated trading in the crypto market!"}
          </p>
          <div className="d-block d-md-none mt-4 position-relative">
            <BlurBall />
            <Illustration
              src={createBotIllustration}
              alt="create bot"
            />
          </div>
          <Footer>
            <Button
              color="blue"
              onClick={handleMoveToNextStep}
            >
              Get started with Darkbot!
            </Button>
          </Footer>
        </div>
      </Col>
      <BlurBall className="d-none d-md-block"/>
      <Illustration
        src={cardsIllustration}
        alt="illustration"
        className="d-none d-md-block"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 5.281rem 5.938rem;

  h1 {
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const Illustration = styled.img`
  width: 45%;
  position: absolute;
  bottom: 0;
  right: 0;

  @media (max-width: 768px) {
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
  }
`;

const BlurBall = styled.div`
  border-radius: 666px;
  opacity: 0.3;
  background: #240A96;
  -webkit-filter: blur(47.25px);
  -webkit-filter: blur(47.25px);
  filter: blur(47.25px);
  width: 75%;
  height: 455px;
  position: absolute;
  bottom: -22%;
  right: -32%;

  @media (max-width: 768px) {
    height: 278px;
    width: 100%;
    position: relative;
    top: 0;
    right: 0;
  }
`;

const Footer = styled.div`
  padding-top: 4rem;

  @media (max-width: 768px) {
    padding: 0;

    & button {
      width: 100%;
    }
  }
`;

export default WelcomeStep;
