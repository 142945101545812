import React from "react";

import { BOT_TYPES } from "views/bots/mocks/botTypes";
import { StyledBotTypes } from "views/bots/styledComponents";

const SelectBotType = ({ 
  setBotType, 
  botType
}) => (
  <StyledBotTypes className="d-flex flex-wrap flex-md-nowrap mb-4">
    {
      BOT_TYPES.map((item) =>
        <div
          key={item.name}
          className={`type-item w-100 position-relative d-flex align-items-end${item.name === botType ? " active" : ""}`}
          onClick={() => setBotType(item.name)}
        >
          {item.icon}
          <p className="h2 m-0 title">
            {item.title}
          </p>
        </div>
      )
    }
  </StyledBotTypes>
);

export default SelectBotType;