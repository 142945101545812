import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { formatterToNull, formatValue } from "helpers/valuesFormatters";
import PercentBadge from "components/PercentBadge";
import { replaceDurations } from "helpers/replaceDurations";

const DealRow = ({item, total}) => {
  const history = useHistory();

  const handleClick = (event, id) => {
    if (event.button === 0) history.push(`/deals/${id}`);
    if (event.button === 1) window.open(`/deals/${id}`, "_blank");
  };

  return (
    <DealRow.TableRow
      onMouseDown={(e) => handleClick(e, item.id)} className="cursor-pointer"
      total={total}
    >
      <DealRow.StyledTableCell profit={+item.profit?.percent}>
        <div className="d-flex align-items-center text-nowrap">
          <p className="text-white mb-0">
            {item?.market?.base}
          </p>
          <p className="mb-0 ml-1 text-white-35">
            {`/ ${item?.market?.quote}`}
          </p>
        </div>
      </DealRow.StyledTableCell>
      <td>
        <div className="d-flex align-items-center">
          <p
            className={`mb-0 font-weight-500 ${item.invested.amount > 0 ? "text-white" : "text-white-35"}`}>
            {formatterToNull(item.invested.amount)}
          </p>
          <p className="mb-0 text-white-35 ml-1 font-weight-300">{item.invested.asset}</p>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center text-nowrap">
          <p className={classNames("mb-0 font-weight-500", {
            "text-white-35": !item.profit?.amount,
            "text-success": item.profit?.amount > 0,
            "text-danger": item.profit?.amount < 0,
          })}
          >
            {formatValue(item.profit?.amount)}
          </p>
          <p className="mb-0 ml-1 text-white-35 font-weight-300 mr-2">
            {item.profit?.asset || item.market?.quote}
          </p>
        </div>
      </td>
      <td>
        <PercentBadge
          value={item.profit?.percent}
          showZeroValue
        />
      </td>
      <td>
        <p className="mb-0 text-white font-weight-500 text-nowrap">
          {replaceDurations(item.duration) || "0m"}
        </p>
      </td>
      <td>
        {
          item.status === "completed" && item.completedAt ?
          <p className="mb-0 text-white font-weight-500 text-nowrap">
            {moment(item.completedAt).format("DD.MM.YYYY HH:mm")}{" "}
          </p> : null
        }
        {
          item.status === "cancelled" && item.cancelledAt ?
          <p className="mb-0 text-white font-weight-500 text-nowrap">
            {moment(item.cancelledAt).format("DD.MM.YYYY HH:mm")}{" "}
          </p> : null
        }
      </td>
    </DealRow.TableRow>
  );
};

DealRow.TableRow = styled.tr`
  &:last-child {
    border-bottom: ${({total}) => total > 10 ? "1px solid #2E3148" : "none"}
  }
`;

DealRow.StyledTableCell = styled.td`
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    background-color: ${({ profit }) => {
      if (!profit) return "rgba(255, 255, 255, 0.10)";
      if (profit > 0) return "#3FCBB0";
      return "#ED5F5F";
    }}
  }
`;

export default DealRow;
