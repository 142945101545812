import React from "react";
import { Card } from "reactstrap";
import styled from "styled-components";

import ChannelDefaultWidgetHeader from "../widgets/reusable/ChannelDefaultWidgetHeader";
import ChannelDescriptionPreload from "../preloaders/ChannelDescriptionPreload";

const ChannelDetailsOverview = ({
  loading,
  channel,
  title = "Overview"
}) => {
  if (loading) return <ChannelDescriptionPreload />
  return (
    <Card className="p-4">
      <ChannelDefaultWidgetHeader
        title={title}
      />
      <ChannelDetailsOverview.InlineEditField>
        {channel?.description}
      </ChannelDetailsOverview.InlineEditField>
    </Card>
  );
};

ChannelDetailsOverview.InlineEditField = styled.p`
  border-radius: 6px !important;
  padding: 0.6875rem 0rem;
  min-height: 189px;
  margin-bottom:0px;
`;

export default ChannelDetailsOverview;