import styled from "styled-components";
import {Row} from "reactstrap";

export const HeaderRow = styled(Row)`
  padding: 8px 24px;
  p {
    margin-bottom: 0;
    font-weight: 300 !important;
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.35) !important;
  }
`;

export const BodyRow = styled(Row)`
  padding: 16px 24px;
  border-top: 1px solid #2E3148;
  p, span {
    margin-bottom: 0;
    font-weight: 500 !important;
    font-size: 0.875rem;
  }
  p {
    color: rgba(255, 255, 255) !important;
  }
  span {
    color: ${(props) => props.status === "met" ? "#3FCBB0" : "#ED5F5F"} !important;
  }
`;
