import React from "react";
import Icon from "components/Icon";
import PercentBadge from "components/PercentBadge";
import styled from "styled-components";

const MarketTicker = ({exchange, ticker, quote, base}) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap p-4">
        <div className="d-flex align-items-center mb-2 mb-md-0">
          <Icon code={exchange} size="xxs" type="exchanges"/>
          <h2 className="mb-0 text-white font-weight-500 ml-3 mr-2">{ticker?.close}</h2>
          <PercentBadge value={ticker?.dailyChange} showZeroValue/>
        </div>
        <div className="d-flex">
          <div className="mr-4">
            <MarketTicker.Label>24h High</MarketTicker.Label>
            <MarketTicker.Value>{ticker?.high}</MarketTicker.Value>
          </div>
          <div className="ml-3">
            <div>
              <MarketTicker.Label>24h Low</MarketTicker.Label>
              <MarketTicker.Value>{ticker?.low}</MarketTicker.Value>
            </div>
          </div>
        </div>
      </div>

      <div className="px-4">
        <div style={{height: "1px", width: "100%", background: "rgba(255, 255, 255, 0.03)"}}/>
      </div>

      <div className="d-flex align-items-center justify-content-start justify-content-md-between flex-wrap p-4">
        <div className="px-md-0 px-2 mb-2 mb-md-0">
          <MarketTicker.Label>Open</MarketTicker.Label>
          <MarketTicker.Value>{ticker?.open}</MarketTicker.Value>
        </div>

        <div className="px-md-0 px-2 mb-2 mb-md-0">
          <MarketTicker.Label>24h Volume ({quote})</MarketTicker.Label>
          <MarketTicker.Value>{ticker?.amount}</MarketTicker.Value>
        </div>

        <div className="px-md-0 px-2 mb-2 mb-md-0">
          <MarketTicker.Label>24h Volume ({base})</MarketTicker.Label>
          <MarketTicker.Value>{ticker?.quantity}</MarketTicker.Value>
        </div>

        <div className="px-md-0 px-2 mb-2 mb-md-0">
          <MarketTicker.Label>Mark price</MarketTicker.Label>
          <MarketTicker.Value>{ticker?.markPrice || "-"}</MarketTicker.Value>
        </div>

        <div className="px-md-0 px-2 mb-2 mb-md-0">
          <MarketTicker.Label>Trades count</MarketTicker.Label>
          <MarketTicker.Value>{ticker?.tradeCount}</MarketTicker.Value>
        </div>
      </div>
    </>
  )
}

MarketTicker.Label = styled.p`
  margin-bottom: 4px;
  font-weight: 300;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.35) !important;
`;

MarketTicker.Value = styled.p`
  margin-bottom: 0;
  font-weight: 500;
  color: rgba(255, 255, 255) !important;
`;

export default MarketTicker;
