import { chartOptions } from "charts/options";

const chartConfig = ({data}) => {
  const maxAmount = (info) => {
    if(!info){
      return null
    }else{
      return Math.max(...info.slice(-20).map(item => item?.amount))
    }
  }

  const chartData = {
    config:{
      labels: data?.map(item => item.day).slice(0,10),
      datasets: [
      {
        label: true,
        data: data?.length && data?.map((item) => item.amount).reverse(),
        radius: 0,
        fill: false,
        tension: 0.4,
        borderColor: "#1D8CF8",
      }
    ],
    },
    options:{
      ...chartOptions,
      interaction: {
        intersect: false,
        mode: 'index',
      },
      plugins: {
        legend: {
          display: false,
        },
      tooltip: {
        backgroundColor: '#f5f5f5',
        fontColor: 'rgba(0, 0, 0, 0.5)',
        titleColor: 'rgba(0, 0, 0, 0.5)',
        bodyColor: 'rgba(0, 0, 0, 1)',
        labelColor: 'rgba(0, 0, 0, 1)',
        color: 'rgba(0, 0, 0, 0.5)',
        displayColors: false,
        callbacks: {
          label: function (context) {
            return context.datasetIndex === 0 ? `${context.label}: ${context.formattedValue}` : ""
          },
          title: function () {
            return null
          }
        },
      },
    },
    layout: {
      padding: {
        top: 10
      }
    },
    scales: {
      y: {
        max: maxAmount(data) + 1,
        ticks: {
          display: true,
        },
        grid: {
          display: true,
        },
      },
      x: {
        type: "time",
        time: {
          unit: "day",
          displayFormats: {
            day: "DD.MM"
          }
        },
        ticks: {
          source: "auto"
        },
        grid: {
          display: true,
          color: "#27334F",
          borderColor: "transparent",
        }
      }
    }
  }
};
  return {chartData}
}

export default chartConfig;
