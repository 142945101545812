import React from "react";
import styled from "styled-components";
import SkeletonPreloader from "components/SkeletonPreloader";
import _ from "lodash";

import { ReactComponent as BreadRight } from "assets/img/icons/breadRight.svg";

const BreadcrumbsPreLoader = ({ repeat=5 }) => {
  return (
    <div 
      data-testid="breadcrumbs-preloader"
      className="d-none d-md-flex mb-3"
    >
      {
        _.times(repeat, (e) =>
          <BreadcrumbsPreLoader.Load key={e}>
            <SkeletonPreloader
              height={12}
              width={71}
            />
            <BreadRight className="my-auto" />
          </BreadcrumbsPreLoader.Load>
        )
      }
    </div>
  )
}

BreadcrumbsPreLoader.Load = styled.div`
  svg {
    margin: 0 16px;
  }

  &:last-child {
    svg {
      display: none;
    }
  }
`;

export default BreadcrumbsPreLoader;
