import React, {useState} from "react";
import styled from "styled-components";
import {Tooltip} from "reactstrap";
import {capitalize} from "lodash";
import {INDIVIDUALS_STATUS_OPTIONS} from "models/filters";

const IndividualsItem = (
  {
    individualsLength,
    individualsCount,
    title,
    value,
    idxRow,
    height,
    withSpacing=false,
    ...props
  }) => {
  const [isShow, setIsShow] = useState(false);
  const toggleTooltip = () => setIsShow(prevState => !prevState);

  const getColorClassName = () => INDIVIDUALS_STATUS_OPTIONS.find(({value}) => value === title).color;

  const width = (value / individualsCount) * 100;

  return (
    <IndividualsItem.Wrapper
      id={`${title}-${idxRow}`}
      width={width}
      height={height}
      individualsLength={individualsLength}
      color={getColorClassName()}
      className={`${withSpacing ? "" : "mr-1"} cursor-pointer ${getColorClassName()}`}
      {...props}
    >
      <Tooltip
        placement={"top"}
        target={`${title}-${idxRow}`}
        isOpen={isShow}
        toggle={toggleTooltip}
      >
        <div className="d-flex flex-row align-items-center">
          <p className="font-xs font-weight-500 mb-0 text-dark mr-1">{value} {capitalize(title)}</p>
          <span className="font-xs font-weight-400 text-dark">individuals</span>
        </div>
      </Tooltip>
    </IndividualsItem.Wrapper>
  )
}

IndividualsItem.Wrapper = styled.div`
  height: ${props => props.height ? props.height : "16px"};
  width: ${props => props.width + "%"};
  border-radius: ${props => props.individualsLength <= 1 && "5px"} !important;
  background: ${props => props.color};

  :first-child {
    border-radius: 5px 0 0 5px;
  }
  :last-child {
    border-radius: 0 5px 5px 0;
    margin-right: 0;
  }
`

export default IndividualsItem;
