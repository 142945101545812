import React from "react";
import { Col, Label } from "reactstrap";

import SelectInput from "components/SelectInput";
import { ReactComponent as AddIcon } from "assets/img/icons/AddIcon.svg";

const IndicatorFormLeftBlock = ({
  editing,
  indicatorType,
  indicatorsConfiguration,
  setSelectedMarket,
  wizardMarkets
}) => {
  return (
    <Col className="d-flex flex-column p-0">
      <div className="d-flex flex-row align-items-center mb-4">
        <div>
          <AddIcon />
        </div>
        <h3 className="m-0 font-weight-bold ml-2 ">
          {editing ? "Edit" : "Attach new"}
          indicator{" "}
        </h3>
      </div>
      {indicatorType ? (
        <div className="d-flex flex-column mb-sm">
          <Label className="mb-2 text-white-35 font-weight-300">
            Indicator description
          </Label>
          <p className="font-weight-300 font-sm mb-0">
            {
              indicatorsConfiguration?.find(
                ({ value }) => indicatorType === value
              )?.description
            }
          </p>
        </div>
      ) : null}
      <Col className="p-0 mb-2 mb-lg-0">
        <label className="mb-2 text-white-35 font-weight-300">
          Select market
        </label>
        <SelectInput
          isClearable
          className="w-100"
          placeholder="Market"
          onChange={(values) => {
            setSelectedMarket(values?.icon?.base);
          }}
          options={
            wizardMarkets?.coins?.length &&
            wizardMarkets?.coins?.map((coin) => {
              return {
                value: `${coin} - ${wizardMarkets?.investment?.asset}`,
                label: `${coin} / ${wizardMarkets?.investment?.asset}`,
                icon: {
                  type: "cryptocurrency",
                  size: "selectSize",
                  code: wizardMarkets?.investment?.asset,
                  base: coin,
                },
              };
            })
          }
        />
      </Col>
    </Col>
  );
};

export default IndicatorFormLeftBlock;
